import { Bleed, Box, Card, InlineGrid, BlockStack, InlineStack, Text } from "@shopify/polaris";

export default function WidgetCardWrapper({
  image,
  imageAlt,
  title,
  action = null,
  description,
  children
}) {
  return (
    <Card>
      <InlineGrid gap="400" columns={{ sm: 1, md: '120px auto' }}>
        <Bleed marginBlockStart="400" marginInline="400" marginBlockEnd="400">
          <Box padding="400" background="bg-surface-secondary" minHeight="100%">
            <img src={image} alt={imageAlt} width="110px" className="d-block mx-auto" />
          </Box>
        </Bleed>
        <Box paddingInlineStart={{ sm: '0', md: '400' }} paddingBlockStart={{ xs: '400', md: '0' }}>
          <BlockStack gap="300">
            <InlineStack align="space-between" blockAlign="center">
              <Text variant="headingSm">{title}</Text>
              {action}
            </InlineStack>
            <Text as="p" tone="subdued">{description}</Text>
            {children}
          </BlockStack>
        </Box>
      </InlineGrid>
    </Card>
  );
}
