import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner, BlockStack, Button, Checkbox, Link, Modal, RadioButton, RangeSlider, Text } from "@shopify/polaris";
import WidgetCardWrapper from "./WidgetCardWrapper";
import { saveDisplaySettings } from "redux/displaySettings";

import productReviewsWidget from 'assets/images/widgets/product-reviews-widget.svg';
import UpgradeTrigger from "components/UpgradeTrigger";

export default function ProductReviewsWidgetWrapper({ children }) {
  const displaySettings = useSelector((state) => state.displaySettings);
  const planFeatureSets = useSelector((state) => state.planFeatureSets);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (displaySettings?.data && modalOpen) {
      setInputs({
        review_feed_layout: displaySettings?.data?.review_feed_layout,
        onsite_authoring_enabled: displaySettings?.data?.onsite_authoring_enabled,
        media_gallery_enabled: displaySettings?.data?.media_gallery_enabled,
        smart_display_enabled: displaySettings?.data?.smart_display_enabled,
        review_page_size: displaySettings?.data?.review_page_size,
        prioritize_featured_reviews: displaySettings?.data?.prioritize_featured_reviews,
      });
    }
  }, [modalOpen, displaySettings?.data]);

  const handleInputChange = (field) => {
    return (value) => {
      setInputs(inputs => ({ ...inputs, [field]: value }));
    };
  };

  const saveSettings = async () => {
    setSaving(true);
    // Save the display settings, I believe we can actually use await here because of how saveDisplaySettings is implemented
    await dispatch(saveDisplaySettings(displaySettings?.data?.id, inputs));
    setSaving(false);
    setModalOpen(false);
  };

  const isDirty = () => {
    return Object.keys(inputs).some((key) => inputs[key] !== displaySettings?.data[key]);
  };

  const storeUrl = () => {
    let url = displaySettings?.data?.store?.url;
    if (url && url.indexOf('http') === -1) {
      url = `https://${url}`;
    }
    return `${url}?junip-break-cache=true`;
  };

  return (
    <>
      <WidgetCardWrapper
        image={productReviewsWidget}
        imageAlt="Product Reviews Widget"
        title="Product reviews"
        action={
          <Button
            variant="plain"
            onClick={() => setModalOpen(true)}
          >
            Customize
          </Button>
        }
        description="Display a feed of reviews on product pages."
        children={children}
      />
      <Modal
        open={modalOpen}
        onClose={!saving ? () => setModalOpen(false) : undefined}
        title="Customize product reviews widget"
        primaryAction={{
          content: "Save",
          onAction: saveSettings,
          loading: saving,
          disabled: saving || !isDirty(),
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setModalOpen(false);
            },
            disabled: saving,
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap="400">
            <Banner tone="info">
              To edit colors and styling, visit your <Link url="/settings/branding">branding settings</Link>.
            </Banner>
            <BlockStack gap="200">
              <Text>Layout</Text>
              <RadioButton
                label="List"
                checked={inputs?.review_feed_layout === 'list'}
                id="list"
                name="layout"
                onChange={() => handleInputChange('review_feed_layout')('list')}
                disabled={saving}
              />
              <RadioButton
                label="Grid"
                checked={inputs?.review_feed_layout === 'grid'}
                id="grid"
                name="layout"
                onChange={() => handleInputChange('review_feed_layout')('grid')}
                disabled={saving}
              />
              <Checkbox
                label="Show media gallery"
                checked={inputs.media_gallery_enabled}
                onChange={handleInputChange('media_gallery_enabled')}
                disabled={planFeatureSets?.data?.['pf_media_gallery'] === false || saving}
                helpText={
                  planFeatureSets?.data?.['pf_media_gallery'] === false ?
                    <>You need to <Link url="/settings/billing">upgrade your plan</Link> to enable media galleries</>
                    :
                    <p>
                      Displays image or video submissions at the top of your review feed. The gallery will be hidden if product has fewer than 3 media submissions.
                    </p>
                }
              />
            </BlockStack>
          </BlockStack>
        </Modal.Section>
        <Modal.Section>
          <UpgradeTrigger
            feature={'pf_advanced_display_settings'}
            title={'Upgrade to use advanced settings'}
            description={'You need to upgrade your plan to use advanced settings'}
          >
            <BlockStack gap="400">
              <Text variant="bodyMd" fontWeight="medium">Advanced settings</Text>
              <Checkbox
                label="Show “Write a review” button"
                checked={inputs?.onsite_authoring_enabled}
                onChange={handleInputChange('onsite_authoring_enabled')}
                helpText="Allows customers to leave a review on your site."
                disabled={saving}
              />
              <RangeSlider
                label="Number of reviews to display"
                value={inputs.review_page_size || 0}
                onChange={handleInputChange('review_page_size')}
                output
                min={3}
                max={20}
                helpText={'This is the number of reviews that is shown by default. Your customers can always use the “See More” button to load additional reviews.'}
                suffix={<p style={{ minWidth: '24px', textAlign: 'right' }}>{inputs.review_page_size}</p>}
                disabled={saving}
              />
              <Checkbox
                label="Hide displays with no reviews"
                checked={inputs.smart_display_enabled}
                onChange={handleInputChange('smart_display_enabled')}
                helpText="This functionality hides all Junip content on products that don't have any reviews yet"
                disabled={saving}
              />
              <Checkbox
                label="Show featured reviews first"
                checked={inputs.prioritize_featured_reviews}
                onChange={handleInputChange('prioritize_featured_reviews')}
                disabled={saving || planFeatureSets?.data?.['pf_featured_reviews'] === false}
                helpText={
                  planFeatureSets?.data?.['pf_featured_reviews'] === false ?
                    <>You need to <Link url="/settings/billing">upgrade your plan</Link> to use featured reviews</>
                    :
                    <>Enabling this will show featured reviews before other reviews</>
                }
              />
            </BlockStack>
          </UpgradeTrigger>
        </Modal.Section>
        <Modal.Section>
          <Text variant="bodySm" tone="subdued">
            To optimize performance, Junip caches your settings across a global CDN. This means it may take a few minutes to see changes.{' '}
            To view changes right away, visit your store using this{' '}
            <Link
              url={storeUrl()}
              target="_blank"
            >
              link
            </Link>.
          </Text>
        </Modal.Section>
      </Modal>
    </>
  );
}
