import React from 'react';
import { PolarisVizProvider } from '@shopify/polaris-viz';

export default function PolarisVizProviderWrapper({ children }) {
  return (
    <PolarisVizProvider
      themes={{
        Default: {
          all: {
            all: [
              '#50DDB9',
              '#29CBE1',
              '#637DDB',
              '#F5AF73',
              '#F6857E',
            ]
          },
          seriesColors: {
            single: '#637DDB',
            upToEight: [
              '#50DDB9',
              '#29CBE1',
              '#637DDB',
              '#F5AF73',
              '#F6857E',
            ],
            comparison: '#8C9196'
          },
          line: {
            width: 2,
            pointStroke: 'var(--p-color-bg-surface)',
          },
          chartContainer: {
            minHeight: '300px',
            backgroundColor: 'var(--p-color-bg-surface)',
            borderRadius: 'var(--p-border-radius-200)',
          },
          grid: {
            verticalOverflow: false,
            horizontalOverflow: false,
            color: 'var(--p-color-border)',
          },
          xAxis: {
            labelColor: 'var(--p-color-text)',
          },
          yAxis: {
            labelColor: 'var(--p-color-text)',
          },
          legend: {
            backgroundColor: 'var(--p-color-bg)',
            labelColor: 'var(--p-color-text)',
            valueColor: 'var(--p-color-text)',
          }
        },
      }}
    >
      {children}
    </PolarisVizProvider>
  );
}
