import React, { useState } from 'react';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import axios from 'axios';
import { LegacyCard } from '@shopify/polaris';
import Invitation from 'components/Invitation';

function Invitations({invitations, fetchInvitations, showToast, disabled = false}) {
  const [invites, setInvites] = useState(invitations || []);

  const accept = async (id) => {
    try {
      await axios.post(`/api/v1/invitations/${id}/accept`);
      showToast('Invitation accepted');
      if (fetchInvitations) {
        fetchInvitations({ state: 'pending' });
      } else {
        const newInvites = invites.filter(i => i.id !== id);
        setInvites(newInvites);
      }
    } catch(e) {
      showToast('Error accepting invitation, please try again.', true);
      throw(e);
    }
  };

  const decline = async (id) => {
    try {
      await axios.post(`/api/v1/invitations/${id}/decline`);
      showToast('Invitation declined');
      if (fetchInvitations) {
        fetchInvitations({ state: 'pending' });
      } else {
        const newInvites = invites.filter(i => i.id !== id);
        setInvites(newInvites);
      }
    } catch(e) {
      showToast('Error declining invitation, please try again.', true);
      throw(e);
    }
  };

  return (
    <LegacyCard sectioned={invites?.length === 0}>
      { invites?.length ? invites.map((invite, index) =>
        <Invitation disabled={disabled} key={index} invitation={invite} accept={accept} decline={decline} />
      ) :
        <>No pending invitations</>
      }
    </LegacyCard>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(Invitations);
