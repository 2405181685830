import React from 'react';
import styles from 'styles/components/ColorSchemeWrapper.module.scss';

export default function ColorSchemeWrapper({ className = '', children, colorScheme }) {
  return (
    <div className={`${styles[colorScheme]} ${className}`}>
      { children }
    </div>
  );
}

export function setRootColorScheme(colorScheme) {
  if (colorScheme === 'dark') {
    document.body.classList.remove(styles.light);
    document.body.classList.add(styles.dark);
  } else {
    document.body.classList.remove(styles.dark);
    document.body.classList.add(styles.light);
  }
}
