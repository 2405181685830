import React from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { LastLocationProvider } from 'utils/ReactRouterLastLocation/index';

// Normalize all paths to not have trailing slashes even if they
// matched <Route> with one:
const Router = ({ children }) => (
  <BrowserRouter>
    <LastLocationProvider>
      <Route
        render={({ location: { pathname, search, hash } }) =>
          pathname !== '/' && pathname.slice(-1) === '/' ? (
            <Redirect to={`${pathname.slice(0, -1)}${search}${hash}`} />
          ) : (
            children
          )
        }
      />
    </LastLocationProvider>
  </BrowserRouter>
);

export default Router;
