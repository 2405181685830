import React, { useEffect, useRef, useState, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { fetchDisplaySettings } from 'redux/displaySettings';
import {
  Modal,
  TextContainer,
  Select,
  Button,
  Collapsible,
  FormLayout,
  TextField,
  LegacyStack
} from '@shopify/polaris';
import useWindowResize from 'utils/useWindowResize';
import LoadingContentWrapper from 'components/LoadingContentWrapper';
import StarRating from 'components/StarRating';
import ColorPicker from 'components/ColorPicker';

import { SaveIcon, ShareIcon } from "@shopify/polaris-icons";
import reviewedOnJunip from 'assets/images/reviewedOnJunip.png';
import styles from 'styles/components/ReviewShareModal.module.scss';

function ReviewShareModal({ open, close, review, displaySettings, fetchDisplaySettings, showToast }) {
  const [format, setFormat] = useState('twitter');
  const [image, setImage] = useState(review?.photo_urls?.[0]?.large || null);

  const windowSize = useWindowResize();
  const scalableRef = useRef();
  const reviewRef = useRef();
  const [scale, setScale] = useState(1);
  const [customizeOpen, setCustomizeOpen] = useState(false);
  const [colors, setColors] = useState({
    starColor: '#eec200',
    backgroundColor: '#009E5C',
    backgroundOpacity: 20,
  });
  const [sharing, setSharing] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const handleSelectChange = useCallback((value) => setFormat(value), []);
  const handleImageSelectChange = useCallback((value) => setImage(value), []);
  const toggleCustomizeOpen = useCallback(() => setCustomizeOpen(!customizeOpen), [customizeOpen]);
  const handleInputChange = (field) => {
    return (value) => {
      setColors(inputs => ({ ...inputs, [field]: value }));
    };
  };

  useEffect(() => {
    if (open) {
      if (window?.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: true
        });
      }
    }

    return () => {
      if (window?.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: false
        });
      }
    }
  }, [open]);

  useEffect(() => {
    if (!displaySettings.data) {
      fetchDisplaySettings();
    } else {
      setColors({
        starColor: displaySettings.data?.star_color,
        backgroundColor: displaySettings.data?.primary_color,
        backgroundOpacity: 20,
      });
    }
  }, [fetchDisplaySettings, displaySettings.data]);

  useEffect(() => {
    if (open) {
      const reviewNode = reviewRef.current;
      const scalableNode = scalableRef.current;
      const reviewWidth = reviewNode?.offsetWidth;
      const reviewHeight = reviewNode?.offsetHeight;
      const scalableWidth = scalableNode?.offsetWidth;
      const scalableHeight = scalableNode?.offsetHeight;
      const scale = Math.min(scalableWidth / reviewWidth, scalableHeight / reviewHeight);

      setScale(scale);
    }
  }, [windowSize, scalableRef, reviewRef, open, format]);

  const renderCustomer = () => {
    const { customer } = review;
    const firstName = customer.first_name || '';
    const lastName = customer.last_name?.charAt(0) || '';
    const fullName = `${firstName} ${lastName}`;

    if (review?.verified_buyer) {
      return `${fullName}, verified customer`;
    }

    return fullName;
  };

  const generateCanvas = async () => {
    return html2canvas(reviewRef.current, {
      scale: 1,
      useCORS: image ? true : false,
      onclone: (element) => {
        element.getElementById('reviewDownloadContainer').style.transform = 'none';
        return element;
      },
      backgroundColor: '#fff',
    });
  };

  const downloadImage = async () => {
    setDownloading(true);

    try {
      const canvas = await generateCanvas();
      canvas.toBlob((blob) => {
        const link = document.createElement('a');
        link.download = `junip-${format}-${review.id}.png`;
        link.href = URL.createObjectURL(blob);
        link.click();
      }, 'image/png');
      trackEvent('Review share modal - Download image');
    } catch(e) {
      showToast('There was an error downloading the image, please try again', true);
      trackEvent('Error: Review share modal - Download image');

      if (window?.Rollbar) {
        window.Rollbar.error('Error downloading image', e);
      }
    } finally {
      setDownloading(false);
    }
  };

  const shareImage = async () => {
    if (!window?.navigator?.share) {
      downloadImage();
      return;
    }

    setSharing(true);

    try {
      const canvas = await generateCanvas();

      canvas.toBlob((blob) => {
        const files = [
          new File(
            [blob],
            `junip-${format}-${review.id}.png`,
            {
              type: blob.type,
              lastModified: new Date().getTime()
            }
          )
        ];

        if (window?.navigator?.canShare?.({ files })) {
          window.navigator.share({
            files
          });

          trackEvent('Review share modal - Share image');
        } else {
          showToast('It looks like your browser does not support sharing image files, try downloading the image and sharing it manually');
          trackEvent('Review share modal - Share image not supported');
        }

        setSharing(false);
      }, 'image/png');
    } catch(e) {
      setSharing(false);
      showToast('There was an error sharing the image, please try again', true);
      trackEvent('Error: Review share modal - Share image');

      if (window?.Rollbar) {
        window.Rollbar.error('Error sharing image', e);
      }
    }
  };

  const formatMap = {
    twitter: {
      name: 'Twitter (16:9)',
      aspectRatio: '16/9',
      height: '1080px',
      width: '1920px',
    },
    instagram: {
      name: 'Instagram Story (9:16)',
      aspectRatio: '9/16',
      height: '1920px',
      width: '1080px',
    },
    post: {
      name: 'Social Post (4:3)',
      aspectRatio: '4:3',
      height: '1080px',
      width: '1440px',
    },
    square: {
      name: 'Square (1:1)',
      aspectRatio: '1:1',
      height: '1080px',
      width: '1080px',
    },
  };

  const options = Object.entries(formatMap).map(([key, value]) => ({
    label: value.name,
    value: key,
  }));

  const imageOptions = review?.photo_urls?.length > 0 ? [
    {
      label: 'None',
      value: '',
    },
    ...review?.photo_urls?.map((photo, index) => ({
      label: `Image ${index + 1}`,
      value: photo?.large,
    }))
  ] : null;

  const renderOpacity = () => {
    return Math.floor(colors.backgroundOpacity * 2.55).toString(16).padStart(2, '0');
  };

  const renderFontSize = () => {
    const length = review?.body?.length || 0;

    switch (format) {
      case 'twitter':
      case 'instagram':
        if (image && length > 250) {
          return '2em';
        } else if (image) {
          return '2.5em';
        }

        return '3em';
      case 'post':
      case 'square':
        if (image && length > 250) {
          return '1.5em';
        } else if (image) {
          return '2em';
        }
        return length > 500 ? '2em' : '2.5em';
      default:
        return '3em';
    };
  };

  const renderBody = () => {
    const { body } = review;
    let max = 500;

    if (image) {
      max = 350;
    }

    if (!body) {
      return '';
    }

    if (body.length > max) {
      return `${body.substring(0, max)}...`;
    }

    return body;
  };

  const renderTitle = () => {
    const { title } = review;

    if (!title) {
      return '';
    }

    if (title.length > 100) {
      return `${title.substring(0, 100)}...`;
    }

    return title;
  };

  const supportNativeShare = window?.navigator?.share ?? false;

  return (
    <Modal
      open={open}
      onClose={close}
      title="Share this review"
      primaryAction={ supportNativeShare ? {
        content: 'Share',
        onAction: shareImage,
        icon: ShareIcon,
        loading: sharing,
      } : {
        content: 'Save',
        onAction: downloadImage,
        icon: SaveIcon,
        loading: downloading,
      }}
      secondaryActions={ supportNativeShare ? [{
        content: 'Save',
        onAction: downloadImage,
        icon: SaveIcon,
        loading: downloading,
      }] : [] }
    >
      <Modal.Section>
        <LoadingContentWrapper loading={displaySettings.loading}>
          <TextContainer>
            <Select
              label="Image size"
              options={options}
              onChange={handleSelectChange}
              value={format}
            />
            <p>Preview:</p>
            <div className={styles.container} ref={scalableRef} style={{aspectRatio: formatMap[format].aspectRatio}}>
              <div
                className={`${styles.reviewContainer} ${styles?.[format]} ${image ? styles.withImage : ''}`}
                id="reviewDownloadContainer"
                ref={reviewRef}
                style={{
                  fontSize: renderFontSize(),
                  width: formatMap[format].width,
                  height: formatMap[format].height,
                  transform: `scale(${scale})`,
                  backgroundColor: `${colors.backgroundColor}${renderOpacity()}`,
                }}
              >
                <div className={styles.reviewCard}>
                  {image && (
                    <div
                      className={styles.reviewImage}
                      style={{
                        background: `linear-gradient(0deg, ${colors.backgroundColor}15, ${colors.backgroundColor}15), #212121`
                      }}
                    >
                      <img src={image} className={styles.image} alt="Review" crossOrigin="anonymous" />
                    </div>
                  )}
                  <div className={styles.review}>
                    <img src={reviewedOnJunip} alt="Review on Junip" className={styles.branding} />
                    <StarRating rating={review.rating} color={colors.starColor}  />
                    <div className={styles.title}>{renderTitle()}</div>
                    <div className={styles.body}>{renderBody()}</div>
                    <div className={styles.author}>
                      {renderCustomer()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <TextContainer>
                <Button


                  disclosure={customizeOpen ? 'up' : 'down'}
                  onClick={toggleCustomizeOpen}
                  ariaExpanded={customizeOpen}
                  ariaControls="customize-collapsible"
                  variant="monochromePlain">
                  Customize
                </Button>
                <Collapsible
                  id="customize-collapsible"
                  open={customizeOpen}
                >
                  <FormLayout>
                    {imageOptions && (
                      <FormLayout.Group>
                        <Select
                          label="Review image"
                          options={imageOptions}
                          onChange={handleImageSelectChange}
                          value={image}
                        />
                      </FormLayout.Group>
                    )}
                    <FormLayout.Group>
                      <div>
                        <LegacyStack spacing="tight">
                          <div>
                            <p className="mb-1">Background color</p>
                            <ColorPicker colorKey="backgroundColor" colorValue={colors.backgroundColor} handleInputChange={handleInputChange} />
                          </div>
                          <TextField
                            value={colors.backgroundOpacity}
                            label="Background opacity"
                            type="number"
                            pattern="[0-9]"
                            onChange={handleInputChange('backgroundOpacity')}
                            max={100}
                            min={0}
                            suffix="%"
                          />
                        </LegacyStack>
                      </div>
                      <div>
                        <p className="mb-1">Star color</p>
                        <ColorPicker colorKey="starColor" colorValue={colors.starColor} handleInputChange={handleInputChange} />
                      </div>
                    </FormLayout.Group>
                  </FormLayout>
                </Collapsible>
              </TextContainer>
            </div>
          </TextContainer>
        </LoadingContentWrapper>
      </Modal.Section>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  displaySettings: state.displaySettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewShareModal);
