import React, { useState, useEffect, useCallback } from 'react';
import {
  Banner,
  TextContainer,
  Button,
  List,
  Collapsible,
  ProgressBar,
  Tooltip,
  Icon,
  Text,
} from "@shopify/polaris";
import axios from 'axios';

import { InfoIcon } from "@shopify/polaris-icons";

import useInterval from 'utils/useInterval';

const DEFAULT_POLLING_DELAY = 5000;
const TIME_RANGE = 20 * 60 * 1000; // 20 minutes

export default function ImportReviewStatusBanner(props) {
  const { importStarted } = props;
  const [pendingImport, setPendingImport] = useState(null);
  const [delay, setDelay] = useState(null);
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen((open) => !open), []);

  let status = 'info';
  let title = 'Importing';
  let text = 'Your reviews are importing';

  if (pendingImport) {
    switch(pendingImport.state) {
      case 'waiting':
        status = 'default';
        title = 'Waiting to import reviews';
        text = 'Junip is currently syncing your store information. Your review import will start as soon as the sync is complete. Note this may take a couple of hours depending on the size of your store';
        break;
      case 'completed':
        status = 'success';
        title = 'Imported';
        text = 'Your reviews have been successfully imported';
        if (pendingImport?.duplicate_count || pendingImport?.invalid_count || pendingImport?.target_dne_count) {
          text = 'Your reviews imported but there were a few problems. Click below to see details';
        }
        break;
      case 'failed':
        status = 'critical';
        title = 'Import failed';
        text = 'There was an error importing your reviews, please check your csv file and try again';
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    return () => {
      setDelay(null);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/api/v1/review_imports');
        const imports = response?.data?.review_imports;
        const firstPendingImport = imports?.find(i => i.state === 'processing' || i.state === 'pending' || i.state === 'waiting');
        if (firstPendingImport) {
          setPendingImport(firstPendingImport);
        } else {
          const recentlyActive = imports?.[0];
          if ((Date.now() - Date.parse(recentlyActive?.updated_at) < TIME_RANGE)) {
            setPendingImport(recentlyActive);
          }
        }
      } catch (e) {
        // No-op
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/api/v1/review_imports');
        const firstPendingImport = response?.data?.review_imports.find(i => i.state === 'processing' || i.state === 'pending' || i.state === 'waiting');
        setPendingImport(firstPendingImport);
      } catch (e) {} // No-op
    }
    if (importStarted === true) {
      fetchData();
    }
  }, [importStarted]);

  useEffect(() => {
    if (pendingImport) {
      if (pendingImport.state === 'processing' || pendingImport.state === 'pending' || pendingImport.state === 'waiting') {
        setDelay(DEFAULT_POLLING_DELAY);
      } else {
        setDelay(null);
      }
    }
  }, [pendingImport]);

  useInterval(() => {
    async function fetchData(id) {
      const response = await axios.get(`/api/v1/review_imports/${id}`);
      setPendingImport(response?.data?.review_import);
    }
    if (pendingImport) {
      fetchData(pendingImport.id);
    }
  }, delay);

  return <>
    {pendingImport &&
      <div className="mb-4">
        <Banner
          title={title}
          tone={status}
          onDismiss={() => setPendingImport(null)}
        >
          <TextContainer spacing="tight">
            <p>{text}</p>
            {pendingImport?.total_count && (pendingImport.state === 'pending' || pendingImport.state === 'processing') &&
              <ProgressBar
                size="small"
                progress={
                  ((pendingImport?.duplicate_count || 0 + pendingImport?.invalid_count || 0 + pendingImport?.target_dne_count || 0 + pendingImport?.imported_count || 0) /
                    pendingImport?.total_count) * 100
                }
              />
            }
            <Button
              disclosure={open ? 'up' : 'down'}
              onClick={handleToggle}
              ariaExpanded={open}
              ariaControls="details-collapsible"
              variant="monochromePlain">
              View details
            </Button>
            <Collapsible
              open={open}
              id="details-collapsible"
              expandOnPrint
            >
              <TextContainer spacing="tight">
                <p>{pendingImport?.imported_count || 0} reviews <Text variant="bodyMd" as="span" tone="success">successfully</Text> imported:</p>
                <List>
                  <List.Item>Product reviews: {pendingImport?.product_review_count || 0}</List.Item>
                  <List.Item>Store reviews: {pendingImport?.store_review_count || 0}</List.Item>
                </List>
                <p className="mt-4">
                  {pendingImport?.duplicate_count || 0 + pendingImport?.invalid_count || 0 + pendingImport?.target_dne_count || 0}{' '}
                  reviews <Text variant="bodyMd" as="span" tone="critical">skipped</Text>:
                </p>
                <List>
                  <List.Item>Duplicates: {pendingImport?.duplicate_count || 0}</List.Item>
                  <List.Item>Invalid: {pendingImport?.invalid_count || 0}</List.Item>
                  <List.Item>
                    <div className="d-flex">
                      <div className="mr-1">
                        Product IDs submitted do not match those in your account: {pendingImport?.target_dne_count || 0}
                      </div>
                      {pendingImport?.target_dne_count > 0 &&
                        <Tooltip content="Some spreadsheet tools automatically convert these long strings to more simple numbers when opened. If you re-export from your previous provider & import directly it should work fine! Otherwise double check the Product ID column to ensure it hasn't been changed.">
                          <div style={{ cursor: 'pointer' }}>
                            <Icon source={InfoIcon} tone="subdued" />
                          </div>
                        </Tooltip>
                      }
                    </div>
                  </List.Item>
                </List>
                <p className="mt-4">
                  Note: Junip only supports importing reviews gathered directly from customers on your store. We do not support generic .csv uploads at this time.
                </p>
              </TextContainer>
            </Collapsible>
          </TextContainer>
        </Banner>
      </div>
    }
  </>;
}
