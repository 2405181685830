import axios from 'axios';

// Actions
const FETCH_STRIPE_RECORDS_BEGIN = 'FETCH_STRIPE_RECORDS_BEGIN';
const FETCH_STRIPE_RECORDS_SUCCESS = 'FETCH_STRIPE_RECORDS_SUCCESS';
const FETCH_STRIPE_RECORDS_ERROR = 'FETCH_STRIPE_RECORDS_ERROR';

// Action Creators
export function fetchStripeRecords() {
  return async dispatch => {
    dispatch(fetchStripeRecordsBegin());
    try {
      const response = await axios.get('/api/v1/stripe_records');
      dispatch(fetchStripeRecordsSuccess(response.data.stripe_records));
    } catch (e) {
      dispatch(fetchStripeRecordsError(e));
    }
  }
}

export const fetchStripeRecordsBegin = () => ({
  type: FETCH_STRIPE_RECORDS_BEGIN
});

export const fetchStripeRecordsSuccess = (data) => ({
  type: FETCH_STRIPE_RECORDS_SUCCESS,
  data
});

export const fetchStripeRecordsError = (error) => ({
  type: FETCH_STRIPE_RECORDS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_STRIPE_RECORDS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_STRIPE_RECORDS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_STRIPE_RECORDS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
