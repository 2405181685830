import React, { useState, useEffect, useCallback } from 'react';
import useLastLocation from 'utils/useLastLocation';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { setFormDirty } from 'redux/settings';
import {
  fetchModerationSettings,
  saveModerationSettings
} from 'redux/moderationSettings';
import { savingChanges } from 'redux/settings';
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import {
  Page,
  PageActions,
  Layout,
  FormLayout,
  Checkbox,
  LegacyCard,
  Banner,
  Select,
  BlockStack
} from '@shopify/polaris';

const ModerationSettings = (props) => {
  const lastLocation = useLastLocation();

  const [checkStarRating, setCheckStarRating] = useState(false);
  const [inputs, setInputs] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { fetchModerationSettings, setFormDirty, saveModerationSettings } = props;
  const data = props.moderationSettings.data || null;
  const settings = props.settings;

  useEffect(() => {
    return () => {
      setFormDirty(false);
    }
  }, [setFormDirty]);

  useEffect(() => {
    if (!props.moderationSettings.data) {
      fetchModerationSettings();
    }
  }, [fetchModerationSettings, props.moderationSettings.data]);

  useEffect(() => {
    if (data) {
      setInputs({
        auto_approve: data.auto_approve,
        auto_approve_media: data.auto_approve_media,
        auto_approve_min_rating: data.auto_approve_min_rating,
        check_content_moderation: data.check_content_moderation
      });

      setCheckStarRating(data.auto_approve_min_rating !== 1);
    }
  }, [data]);

  useEffect(() => {
    if (settings.discardChanges) {
      if (data) {
        setInputs({
          auto_approve: data.auto_approve,
          auto_approve_media: data.auto_approve_media,
          auto_approve_min_rating: data.auto_approve_min_rating,
          check_content_moderation: data.check_content_moderation
        });
        setCheckStarRating(data.auto_approve_min_rating !== 1);
        setIsDirty(false);
        trackEvent('Moderation settings - Discard changes');
      }
    }
  }, [data, settings.discardChanges]);

  useEffect(() => {
    if (settings.saving) {
      if (!isSaving) {
        saveModerationSettings(data.id, inputs);
        setIsSaving(true);
        trackEvent('Moderation settings - Save changes');
      }
    } else {
      if (isSaving) {
        setIsSaving(false);
      }
    }
  }, [data, inputs, isSaving, saveModerationSettings, settings.saving]);

  const checkIsDirty = useCallback(() => {
    if (!data || typeof(data) !== 'object') {
      return;
    }
    const propertyNames = Object.getOwnPropertyNames(inputs);
    let dirty = false;
    for (const name of propertyNames) {
      if (inputs[name] !== data[name]) {
        dirty = true;
        break;
      }
    }

    if (dirty && !isDirty) {
      setIsDirty(true);
      if (!settings.isDirty) {
        setFormDirty(true);
      }
    } else if (!dirty && isDirty) {
      setIsDirty(false);
      if (settings.isDirty) {
        setFormDirty(false);
      }
    }
  }, [data, inputs, isDirty, setFormDirty, settings.isDirty]);

  useEffect(() => {
    checkIsDirty()
  }, [checkIsDirty]);

  const handleInputChange = (field) => {
    return (value) => {
      if (field === 'auto_approve_min_rating') {
        value = parseInt(value);
      }
      setInputs(inputs => ({...inputs, [field]: value}));
    };
  };

  const toggleCheckStarRating = () => {
    setCheckStarRating(!checkStarRating);
    if (!checkStarRating) {
      setInputs(inputs => ({...inputs, auto_approve_min_rating: 1}));
    }
  };

  const options = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
  ];

  const primaryAction = {
    content: 'Save',
    disabled: !isDirty,
    onAction: props.savingChanges,
    loading: settings.saving
  };

  return (
    <Page
      title="Review management settings"
      backAction={{content: 'Settings', url: lastLocation || '/settings'}}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Pending reviews"
          description="Hold certain reviews in a “pending” state for up to 14 days to check for inappropriate, spam, or fraudulent content."
        >
          <LoadingCardWrapper loading={props.moderationSettings.loading} sectioned={false}>
            {data?.self_moderation_enabled === true ? (
              <>
                <LegacyCard.Section>
                <FormLayout>
                  <Banner tone="info">
                    <p>Pending is a temporary state. Any pending review that is more than 14 days old will be automatically published.</p>
                  </Banner>
                  <BlockStack gap="100">
                    <p>Hold reviews in pending if:</p>
                    <Checkbox
                      label="There is a photo or video attachment"
                      checked={inputs.auto_approve_media === false}
                      onChange={() => handleInputChange('auto_approve_media')(!inputs.auto_approve_media)}
                      disabled={!inputs.auto_approve || settings.saving}
                    />
                    <Checkbox
                      label="The text contains profanity"
                      checked={inputs.check_content_moderation}
                      onChange={handleInputChange('check_content_moderation')}
                      disabled={!inputs.auto_approve || settings.saving}
                    />
                    <Checkbox
                      label="The star rating is below a set minimum"
                      checked={checkStarRating}
                      onChange={toggleCheckStarRating}
                      helpText={
                        <>
                          {checkStarRating &&
                            <div className="mt-2">
                              <Select
                                label="Minimum star rating"
                                options={options}
                                value={inputs.auto_approve_min_rating}
                                onChange={handleInputChange('auto_approve_min_rating')}
                                disabled={!inputs.auto_approve || settings.saving}
                              />
                            </div>
                          }
                        </>
                      }
                      disabled={!inputs.auto_approve || settings.saving}
                    />
                  </BlockStack>
                </FormLayout>
                </LegacyCard.Section>
                <LegacyCard.Section>
                  <Checkbox
                    label="Hold all new reviews in pending state"
                    checked={inputs.auto_approve === false}
                    onChange={() => handleInputChange('auto_approve')(!inputs.auto_approve)}
                    disabled={settings.saving}
                  />
                </LegacyCard.Section>
              </>
            ) : (
              <LegacyCard.Section>
                <Banner tone="info"
                  title="Junip moderation is enabled"
                >
                  <p>Junip will moderate your reviews for you so you don't have to. If you have any questions, please contact support</p>
                </Banner>
              </LegacyCard.Section>
            )}
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
        <Layout.Section>
          <PageActions
            primaryAction={primaryAction}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  moderationSettings: state.moderationSettings
});

const mapDispatchToProps = (dispatch) => ({
  setFormDirty: (dirty) => dispatch(setFormDirty(dirty)),
  fetchModerationSettings: () => dispatch(fetchModerationSettings()),
  saveModerationSettings: (id, settings) => dispatch(saveModerationSettings(id, settings)),
  savingChanges: () => dispatch(savingChanges())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModerationSettings);
