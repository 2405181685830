import React, { useState, useEffect } from 'react';
import { Spinner } from '@shopify/polaris';

import 'styles/components/LoadingCover.scss';

export default function LoadingCover({ loading, showSpinner = false }) {
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (loading === false) {
      setTimeout(() => {
        setDone(true);
      }, 550);
    }
  }, [loading]);

  return (
    <>
      { !done &&
        <div className={`loading-cover ${loading ? '' : 'fade-out'}`}>
          <div className="loading-image"></div>
          { showSpinner &&
            <Spinner accessibilityLabel="Junip authenticating..." size="large" color="primary" />
          }
        </div>
      }
    </>
  );
};
