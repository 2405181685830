import React, { useEffect, useState } from 'react';
import {
  Page,
  Badge,
  Card,
  List,
  Link,
  FooterHelp,
  BlockStack,
  TextField,
  Banner,
  Divider
} from '@shopify/polaris';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGoogleReviewSettings, saveGoogleReviewSettings } from 'redux/googleReviewSettings';
import UpgradeTrigger from 'components/UpgradeTrigger';

export default function GoogleShopping() {
  const dispatch = useDispatch();
  const googleReviewSettings = useSelector(state => state.googleReviewSettings);
  const planFeatureSets = useSelector(state => state.planFeatureSets);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch(fetchGoogleReviewSettings());
  }, [dispatch]);

  const toggleGoogleReviewSettings = async (product_feed_enabled) => {
    setSaving(true);
    await dispatch(saveGoogleReviewSettings(googleReviewSettings.data.id, { product_feed_enabled: !product_feed_enabled }));
    setSaving(false);
  };

  const { product_feed_enabled, store_path, product_feed_generated_at } = googleReviewSettings.data || {};

  return (
    <LoadingPageWrapper loading={googleReviewSettings.loading}>
      <Page
        title="Google Shopping syndication"
        titleMetadata={product_feed_enabled ? <Badge tone="success">On</Badge> : <Badge tone="default">Off</Badge>}
        subtitle="Send reviews to Google"
        backAction={{ content: 'Back', url: '/syndication' }}
        primaryAction={{
          content: product_feed_enabled ? 'Turn off' : 'Turn on',
          destructive: product_feed_enabled,
          primary: !product_feed_enabled,
          loading: saving,
          disabled: planFeatureSets?.data?.pf_google_shopping === false,
          onAction: () => toggleGoogleReviewSettings(product_feed_enabled)
        }}
        secondaryActions={[{
          content: 'Contact Google support',
          external: true,
          url: 'https://support.google.com/merchants/gethelp'
        }]}
      >
        <UpgradeTrigger
          feature={'pf_google_shopping'}
          title={'Upgrade to use Google Shopping'}
          description={'You need to upgrade your plan to use Google Shopping'}
        >
          <BlockStack gap="300">
            {product_feed_enabled && product_feed_generated_at && (
              <>
                <Banner
                  tone="success"
                  title="Successfully sending reviews to Google."
                >
                  <p>
                    Reviews may take 2-3 weeks to appear on your Google Shopping feed. Last update sent {new Date(product_feed_generated_at).toLocaleString('en-US', { timeZone: 'UTC' })}
                  </p>
                </Banner>
                <Divider />
              </>
            )}
            <Card>
              <List>
                <List.Item>
                  You must have at least 50 total verified product reviews in order to participate.
                </List.Item>
                <List.Item>
                  Junip is obligated to send all reviews to Google Shopping, including rejected reviews.
                </List.Item>
                <List.Item>
                  Your <Link url="/products/catalog-health">product catalog</Link> in Junip and Google Merchant Center must use the same product identifiers.
                </List.Item>
                <List.Item>
                  For best results, ensure each product variant in your catalog has either a GTIN or Brand + MPN pairing that matches the GTIN or Brand + MPN pairing in your product feed.
                  To learn more about these product identifiers, <Link url="https://help.junip.co/en/articles/8370160-product-identifiers-upis">visit this help doc</Link>.
                </List.Item>
                <List.Item>
                  Your Shop URL in Junip must be the same as the URL connected to your Google Merchant Center account.
                </List.Item>
              </List>
            </Card>
            <Card>
              <TextField
                label="Shop URL"
                readOnly
                value={store_path}
              />
            </Card>
          </BlockStack>
        </UpgradeTrigger>
        <FooterHelp>
          <p>
            Learn more about <Link url="https://help.junip.co/en/articles/4658170-getting-started-product-reviews-ratings-in-google-shopping">Google Shopping syndication</Link>.
          </p>
        </FooterHelp>
      </Page>
    </LoadingPageWrapper>
  )
}
