import React from 'react';
import { LegacyCard, Text } from "@shopify/polaris";
import TeamMember from 'components/TeamMember';
import TeamMemberInvitation from 'components/TeamMemberInvitation';

import 'styles/components/Team.scss';

export default function Team({invitations, memberships, user, isOwner}) {
  return (
    <LegacyCard>
      <div className="team-grid-container">
        <div className="team-grid-title">
          <Text variant="bodyMd" as="span" fontWeight="semibold">User</Text>
        </div>
        <div className="team-grid-title status">
          <Text variant="bodyMd" as="span" fontWeight="semibold">Status</Text>
        </div>
        <div className="team-grid-title">
          <Text variant="bodyMd" as="span" fontWeight="semibold">Action</Text>
        </div>
        { memberships?.map((membership, index) =>
          <TeamMember key={index} membership={membership} isOwner={isOwner} currentUser={user} />
        )}
        { invitations?.sort((a) => a.state === 'pending').map((invitation, index) =>
          <TeamMemberInvitation key={index} invitation={invitation} isOwner={isOwner} />
        )}
      </div>
    </LegacyCard>
  );
};
