import React from 'react';
import {
  Modal
} from '@shopify/polaris';

import questionPreview from 'assets/images/questionPreview.png';

export default function QuestionPreviewModal({open, close}) {
  return(
    <Modal
      open={open}
      onClose={close}
      title="On-site display"
      secondaryActions={[{
        content: 'Close',
        onAction: close
      }]}
      >
      <Modal.Section subdued>
        <img src={questionPreview} alt="question preview" className="w-100" />
      </Modal.Section>
      <Modal.Section>
        <p>
          Responses to custom questions are displayed alongside reviews (excludes private questions). The styling will vary depending on your theme and/or CSS customizations. 
        </p>
      </Modal.Section>
    </Modal>
  )
}
