import axios from 'axios';

// Actions
const FETCH_STORE_THEMES_BEGIN = 'FETCH_STORE_THEMES_BEGIN';
const FETCH_STORE_THEMES_SUCCESS = 'FETCH_STORE_THEMES_SUCCESS';
const FETCH_STORE_THEMES_ERROR = 'FETCH_STORE_THEMES_ERROR';

// Action Creators
export function fetchStoreThemes(data = {}) {
  return async dispatch => {
    dispatch(fetchStoreThemesBegin());
    try {
      const response = await axios.get('/api/v1/store_themes');
      dispatch(fetchStoreThemesSuccess(response.data.store_themes));
    } catch (e) {
      dispatch(fetchStoreThemesError(e));
    }
  }
}

export const fetchStoreThemesBegin = () => ({
  type: FETCH_STORE_THEMES_BEGIN
});

export const fetchStoreThemesSuccess = data => ({
  type: FETCH_STORE_THEMES_SUCCESS,
  data
});

export const fetchStoreThemesError = error => ({
  type: FETCH_STORE_THEMES_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_STORE_THEMES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_STORE_THEMES_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_STORE_THEMES_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
