import React from 'react';
import { LegacyCard, Layout, LegacyStack, Icon, Text, InlineCode } from "@shopify/polaris";
import { DiscountIcon } from "@shopify/polaris-icons";

export default function OfferCard({ offer }) {
  const giftKey = offer?.gift_key?.split('_');
  const discount = giftKey?.[0];

  const period = giftKey?.[2];

  return (
    <Layout.Section variant="fullWidth">
      <LegacyCard
        title={
          <LegacyStack alignment="center" spacing="tight">
            <Icon source={DiscountIcon} tone="primary" />
            <Text variant="headingMd" as="h2">Get{discount}% off your first{period}months</Text>
          </LegacyStack>
        }
        sectioned>
        <p>You're receiving this discount for signing up with referral code <Text variant="bodyMd" as="span"><InlineCode>{offer.code}</InlineCode></Text> from {offer.owner_name}. The discount will automatically be applied to your bill after you select a plan.</p>
      </LegacyCard>
    </Layout.Section>
  );
};
