import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { fetchSolicitationCampaigns } from 'redux/solicitationCampaigns';
import { showToast } from 'redux/toast';
import axios from 'axios';
import moment from 'moment';
import { Modal, FormLayout, TextField, Text } from "@shopify/polaris";

function SolicitationCampaignModal(props) {
  const {
    showSolicitationCampaignModal,
    setShowSolicitationCampaignModal,
    fetchSolicitationCampaigns,
    callback,
    showToast
  } = props;

  const [currentDate] = useState(() => {
    let current = new Date();
    current.setMonth(current.getMonth() - 6);
    return current;
  });
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [startDate, setStartDate] = useState(moment(currentDate).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const handleStartChange = useCallback((newValue) => setStartDate(newValue), []);
  const handleEndChange = useCallback((newValue) => setEndDate(newValue), []);

  const createSolicitationCampaign = async () => {
    setLoading(true);
    const purchasedSince = (new Date(startDate)).toISOString();
    let purchasedBeforeDate = new Date(endDate);
    purchasedBeforeDate.setDate(purchasedBeforeDate.getDate() + 1);
    const purchasedBefore = (purchasedBeforeDate).toISOString();
    try {
      await axios.post('/api/v1/solicitation_campaigns', {
        solicitation_campaign: {
          purchased_since: purchasedSince,
          purchased_before: purchasedBefore,
        }
      });

      fetchSolicitationCampaigns();
      showToast('Campaign created');
      setLoading(false);
      setShowSolicitationCampaignModal(false);
      setConfirm(false);
      if (callback) {
        callback();
      }
    } catch(e) {
      showToast('Error creating campaign, please try again', true);
      setLoading(false);
      trackEvent('Error: Solicitation campaign modal - Create campaign')
    }
  };

  const isValid = () => {
    const start = Date.parse(startDate);
    const end = Date.parse(endDate);
    if (!start || !end) {
      return false;
    } else if (start >= end ) {
      return false;
    }

    return true;
  };

  return <>
  { showSolicitationCampaignModal && !confirm &&
    <Modal
      open={showSolicitationCampaignModal}
      title="Create campaign"
      onClose={() => !loading && setShowSolicitationCampaignModal(false)}
      primaryAction={{
        content: 'Launch',
        disabled: loading || !isValid(),
        loading: loading,
        onAction: () => { setConfirm(true); setShowSolicitationCampaignModal(false); trackEvent('Solicitation campaign modal - Create campaign') }
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: loading,
          onAction: () => { setShowSolicitationCampaignModal(false); trackEvent('Solicitation campaign modal - Cancel') } ,
        },
      ]}
    >
      <Modal.Section>
        <p className="mb-4">
          Select the date range for your campaign.
          Junip will target customers that placed an order between this date range asking them to leave
          a review for products they have purchased:
        </p>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              type="date"
              placeholder="yyyy-mm-dd"
              label="Start date"
              value={startDate}
              onChange={handleStartChange}
            />
            <TextField
              type="date"
              placeholder="yyyy-mm-dd"
              label="End date"
              value={endDate}
              onChange={handleEndChange}
            />
          </FormLayout.Group>
        </FormLayout>
        <div className="mt-2">
          <Text variant="bodyMd" as="span" tone="subdued">We recommend requesting reviews for orders placed within the last 6 months.</Text>
        </div>
      </Modal.Section>
    </Modal>
  }
  { confirm && !showSolicitationCampaignModal &&
    <Modal
      open={confirm}
      title="Are you sure?"
      onClose={() => !loading && setConfirm(false)}
      primaryAction={{
        content: 'Yes, launch campaign',
        disabled: loading,
        loading: loading,
        onAction: () => { createSolicitationCampaign(); trackEvent('Solicitation campaign modal confirmation - Yes, email my customers') }
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: loading,
          onAction: () => { setConfirm(false); setShowSolicitationCampaignModal(true); trackEvent('Solicitation campaign modal confirmation - Cancel')},
        },
      ]}
    >
      <Modal.Section>
        <p className="mb-4">
          This will target all customers who've made a purchase between
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            {' '}{ moment(startDate).format('MMMM Do, YYYY') }{' '}
          </Text>
          and
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            {' '}{ moment(endDate).format('MMMM Do, YYYY') }{' '}
          </Text>
          and ask them to leave a review.
        </p>
      </Modal.Section>
    </Modal>
  }
  </>;
}

const mapDispatchToProps = (dispatch) => ({
  fetchSolicitationCampaigns: (newCampaign) => dispatch(fetchSolicitationCampaigns(newCampaign)),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(SolicitationCampaignModal);
