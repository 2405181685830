import React, {Fragment, useState, useCallback} from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { fetchMemberships } from 'redux/memberships';
import axios from 'axios';
import { Avatar, Badge, Button, Tooltip, Modal, Popover, ActionList, Text } from "@shopify/polaris";

function TeamMember({membership, currentUser, isOwner, showToast, fetchMemberships}) {
  const [loading, setLoading] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const user = membership.user;
  const isCurrentUser = currentUser?.id === user?.id;

  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), []);

  const transferOwnership = async () => {
    setLoading(true);
    try {
      await axios.post(`/api/v1/memberships/${membership.id}/transfer_ownership`);
      showToast('Ownership transferred successfully');
      setLoading(false);
      fetchMemberships();
      trackEvent('Team settings - Transfer ownership');
    } catch (e) {
      setLoading(false);
      showToast('Error transferring ownership, please try again', true);
      trackEvent('Error: Team settings - Transfer ownership');
    }
  };

  const removeUser = async () => {
    setLoading(true);
    try {
      await axios.delete(`/api/v1/memberships/${membership.id}`);
      showToast('User removed successfully');
      setLoading(false);
      fetchMemberships();
      trackEvent('Team settings - Remove user');
    } catch (e) {
      setLoading(false);
      showToast('Error removing user, please try again', true);
      trackEvent('Error: Team settings - Remove user');
    }
  };

  return (
    <Fragment key={membership.id}>
      <div className="team-grid-item">
        <div className="d-flex align-items-center">
          <Avatar size="md" customer={false} name={user?.email} initials={`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`} />
          <div className="ml-2">
            <div>{user?.first_name} {user?.last_name}{isCurrentUser ? ' (You)' : ''}</div>
            <div>
              <Text variant="bodyMd" as="span" tone="subdued">{user?.email}</Text>
            </div>
          </div>
        </div>
      </div>
      <div className="team-grid-item status">
        { membership.owner ?
          <Badge
            tone="success"
          >
            Owner
          </Badge>
        :
          <Badge
            tone="default"
          >
            Accepted
          </Badge>
        }
      </div>
      <div className="team-grid-item">
        { isOwner ?
          <>
            { isCurrentUser ?
              <>-</>
            :
              <Popover
                active={popoverActive}
                activator={
                  <Button onClick={togglePopoverActive} disclosure>
                    Actions
                  </Button>
                }
                onClose={togglePopoverActive}
              >
                <ActionList
                  items={[
                    { content: 'Remove user', destructive: true, disabled: loading, onAction: () => setShowRemoveModal(true) },
                    { content: 'Transfer ownership', disabled: loading, onAction: () => setShowTransferModal(true) },
                  ]}
                />
              </Popover>
            }
          </>
        :
          <>
            { isCurrentUser ?
              <Button


                onClick={() =>  setShowLeaveModal(true)}
                variant="plain"
                tone="critical">Leave organization</Button>
            :
              <Tooltip content="Only owners can perform these actions">
                <Popover
                  active={false}
                  activator={
                    <Button disclosure disabled>
                      Actions
                    </Button>
                  }
                  onClose={togglePopoverActive}
                >
                </Popover>
              </Tooltip>

            }
          </>
        }
      </div>
      <Modal
        open={showTransferModal}
        title="Transfer ownership"
        onClose={() => !loading && setShowTransferModal(false)}
        primaryAction={{
          content: 'Transfer ownership',
          disabled: loading,
          loading: loading,
          onAction: transferOwnership
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            disabled: loading,
            onAction: () => { setShowTransferModal(false); },
          }
        ]}
      >
        <Modal.Section>
          <p>Transfer ownership to <Text variant="bodyMd" as="span" fontWeight="semibold">{membership.user.first_name} {membership.user.last_name}</Text> ({membership.user.email})?</p>
        </Modal.Section>
      </Modal>
      <Modal
        open={showRemoveModal}
        title="Remove user"
        onClose={() => !loading && setShowRemoveModal(false)}
        primaryAction={{
          content: 'Remove user',
          destructive: true,
          disabled: loading,
          loading: loading,
          onAction: removeUser
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            disabled: loading,
            onAction: () => { setShowRemoveModal(false); },
          }
        ]}
      >
        <Modal.Section>
          <p>Remove <Text variant="bodyMd" as="span" fontWeight="semibold">{membership.user.first_name} {membership.user.last_name}</Text> ({membership.user.email}) from this organization?</p>
        </Modal.Section>
      </Modal>
      <Modal
        open={showLeaveModal}
        title="Leave organization?"
        onClose={() => !loading && setShowLeaveModal(false)}
        primaryAction={{
          content: 'Leave organization',
          destructive: true,
          disabled: loading,
          loading: loading,
          onAction: removeUser
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            disabled: loading,
            onAction: () => { setShowLeaveModal(false); },
          }
        ]}
      >
        <Modal.Section>
          <p>Are you sure you want to leave this organization?</p>
        </Modal.Section>
      </Modal>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchMemberships: () => dispatch(fetchMemberships())
});

export default connect(
  null,
  mapDispatchToProps
)(TeamMember);
