import React from 'react';
import { Link } from 'react-router-dom';

const AdapterLink = ({children, url = '', external, ref, ...rest}) => {
  if (external || url?.indexOf('http') !== -1) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    rest.target = '_blank';
    rest.rel = 'noopener noreferrer';
    return (
      <a href={url} {...rest} >
        {children}
      </a>
    );
  } else {
    return (
      <Link to={url} {...rest}>
        {children}
      </Link>
    );
  }
};

export default AdapterLink;
