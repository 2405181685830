import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { fetchModerationSettings } from 'redux/moderationSettings';
import { withRouter } from 'react-router-dom';
import { Navigation } from '@shopify/polaris';
import {
  AppsIcon,
  HomeIcon,
  ChartVerticalIcon,
  FormsIcon,
  DiscountIcon,
  StarIcon,
  ProductIcon,
  EmailIcon,
  SettingsIcon,
  DesktopIcon,
  GlobeIcon,
} from "@shopify/polaris-icons";

const NavigationMenu = ({
  closeMobileMenu,
  location,
  moderationSettings,
  fetchModerationSettings
}) => {
  const  [pendingReviews, setPendingReviews] = useState(null);

  useEffect(() => {
    if (!moderationSettings.data) {
      fetchModerationSettings();
    }
  }, [fetchModerationSettings, moderationSettings.data]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/api/v1/reviews', {
          params: {
            'filter[state]': 'pending'
          }
        });
        const length = response.data?.reviews?.length || null;
        if (length) {
          if (response.data?.meta?.page?.after) {
            setPendingReviews(`${length}+`);
          } else {
            setPendingReviews(`${length}`);
          }
        }
      } catch (e) {
        setPendingReviews(null);
      }
    }

    if (moderationSettings?.data) {
      // Don't fetch pending reviews if self moderation is disabled
      if (moderationSettings?.data?.self_moderation_enabled === true) {
        fetchData();
      }
    }
  }, [moderationSettings]);

  const isSelected = (path, allowFuzzy) => {
    if (allowFuzzy) {
      return location.pathname.includes(path);
    }
    return location.pathname === path;
  };

  // eslint-disable-next-line no-unused-vars
  const checkVisited = (path) => {
    try {
      const visited = JSON.parse(window?.localStorage?.getItem(`${path}_visited`));
      if (visited === true) {
        return true;
      }
      return false;
    } catch(e) {
      return true;
    }
  };

  return (
    <Navigation location={location?.pathname || ''}>
      <Navigation.Section
        items={[
          {
            url: '/home',
            onClick: closeMobileMenu,
            label: 'Home',
            icon: HomeIcon,
            selected: isSelected('/home'),
          },
          {
            url: '/reviews',
            onClick: closeMobileMenu,
            label: 'Manage reviews',
            icon: StarIcon,
            selected: isSelected('/reviews', true),
            badge: pendingReviews ? pendingReviews : undefined
          },
          {
            url: '/products',
            label: 'Products',
            icon: ProductIcon,
            onClick: closeMobileMenu,
            selected: isSelected('/products', true),
            subNavigationItems: [
              {
                url: '/products/groups',
                onClick: closeMobileMenu,
                label: 'Groups',
                selected: isSelected('/products/groups', true)
              },
              {
                url: '/products/catalog-health',
                onClick: closeMobileMenu,
                label: 'Catalog health',
                selected: isSelected('/products/catalog-health')
              },
            ]
          },
          {
            url: '/analytics',
            onClick: closeMobileMenu,
            label: 'Analytics',
            icon: ChartVerticalIcon,
            selected: isSelected('/analytics'),
            subNavigationItems: [
              {
                url: '/analytics/reports',
                onClick: closeMobileMenu,
                label: 'Reports',
                selected: isSelected('/analytics/reports', true)
              },
            ]
          },
        ]}
      />
      <Navigation.Section
        title="Gather reviews"
        items={[
          {
            url: '/flows',
            onClick: closeMobileMenu,
            label: 'Flows',
            icon: EmailIcon,
            selected: isSelected('/flows', true),
            subNavigationItems: [
              {
                url: '/flows/templates',
                onClick: closeMobileMenu,
                label: 'Templates',
                selected: isSelected('/flows/templates', true),
              },
              {
                url: '/flows/outbox',
                onClick: closeMobileMenu,
                label: 'Outbox',
                selected: isSelected('/flows/outbox')
              }
            ]
          },
          {
            url: '/forms',
            label: 'Forms',
            icon: FormsIcon,
            onClick: closeMobileMenu,
            selected: isSelected('/forms'),
            subNavigationItems: [
              {
                url: '/forms/questions',
                onClick: closeMobileMenu,
                label: 'Custom questions',
                selected: isSelected('/forms/questions', true),
              },
              {
                url: '/forms/preferences',
                onClick: closeMobileMenu,
                label: 'Preferences',
                selected: isSelected('/forms/preferences'),
              },
            ]
          },
          {
            url: '/incentives',
            onClick: closeMobileMenu,
            label: 'Incentives',
            icon: DiscountIcon,
            selected: isSelected('/incentives'),
          },
        ]}
      />
      <Navigation.Section
        fill={true}
        title="Display"
        items={[
          {
            url: '/on_site',
            onClick: closeMobileMenu,
            label: 'On-site',
            icon: DesktopIcon,
            selected: isSelected('/on_site'),
          },
          {
            url: '/syndication',
            onClick: closeMobileMenu,
            label: 'Syndication',
            icon: GlobeIcon,
            selected: isSelected('/syndication', true),
          }
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: '/integrations',
            onClick: closeMobileMenu,
            label: 'Integrations',
            icon: AppsIcon,
            selected: isSelected('/integrations', true),
          },
          {
            url: '/settings',
            onClick: closeMobileMenu,
            label: 'Settings',
            icon: SettingsIcon,
            selected: isSelected('/settings', true),
          }
        ]}
      />
    </Navigation>
  );
};

const mapStateToProps = (state) => ({
  moderationSettings: state.moderationSettings
});

const mapDispatchToProps = (dispatch) => ({
  fetchModerationSettings: () => dispatch(fetchModerationSettings())
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationMenu));
