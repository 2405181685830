import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';
import AuthorizedRoute from 'utils/AuthorizedRoute';
import Intro from 'routes/onboarding/Intro';
import OnboardingBranding from 'routes/onboarding/Branding';
import FlowSolicitation from 'routes/onboarding/FlowSolicitation';
import Campaign from 'routes/onboarding/Campaign';
import Preview from 'routes/onboarding/Preview';
import AddJunip1 from 'routes/onboarding/AddJunip_1';
import AddJunip2 from 'routes/onboarding/AddJunip_2';
import SelectPlan from 'routes/onboarding/SelectPlan';
import Thanks from 'routes/onboarding/Thanks';
import OnboardingWrapper from 'components/OnboardingWrapper';
import LoadingCover from 'components/LoadingCover';
import { trackEvent } from 'utils/Mixpanel';
import { ProgressBar, LegacyStack, Text } from "@shopify/polaris";

const STEPS = [
  '/onboarding/branding',
  '/onboarding/preview',
  '/onboarding/solicitation',
  '/onboarding/campaign',
  '/onboarding/select_plan',
];

const STEPS_2 = [
  '/onboarding/branding',
  '/onboarding/add-junip-1',
  '/onboarding/add-junip-2',
  '/onboarding/solicitation',
  '/onboarding/campaign',
  '/onboarding/select_plan',
];

export default function FullOnboarding({ history }) {
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState(STEPS);
  const [refreshedStore, setRefreshedStore] = useState(null);
  const [stores, setStores] = useState(null);
  const match = useRouteMatch();
  const { location } = history;

  useEffect(() => {
    if (refreshedStore?.supports_shopify_theme_extensions) {
      setSteps(STEPS_2);
    }
  }, [refreshedStore]);

  useEffect(() => {
    if (stores?.length > 1) {
      setSteps((prevSteps) => {
        return prevSteps.filter((step) => step !== '/onboarding/select_plan');
      });
    }
  }, [stores]);

  useEffect(() => {
    async function refreshStore() {
      setLoading(true);
      try {
        const storesResponse = await axios.get('/api/v1/stores', {
          params: { include: 'app_install' }
        });
        const stores = storesResponse.data?.stores;
        const scopedStore = stores?.find(s => s.scoped === true);
        const response = await axios.put(`/api/v1/stores/${scopedStore.id}/theme_refresh`);
        const responseStore = response.data?.store;
        responseStore.app_install = scopedStore.app_install;
        setRefreshedStore(responseStore);
        setStores(stores);
      } catch(e) {
        trackEvent('Error: Onboarding - Fetch store via theme refresh', {statusCode: e?.response?.status});
      } finally {
        setLoading(false);
      }
    }

    refreshStore();
  }, []);

  const renderStep = () => {
    return (steps.findIndex(i => i === location.pathname)) + 1;
  };

  const renderProgress = () => {
    return (renderStep() / steps.length) * 100;
  };

  const progress = (
    <div className="onboarding-progress-container">
      <LegacyStack vertical spacing="tight">
        <ProgressBar size="small" tone="primary" progress={renderProgress()} animated={false} />
        <Text variant="headingSm" as="h3">Step {renderStep()} / {steps.length}</Text>
      </LegacyStack>
    </div>
  );

  return (
    <>
      <LoadingCover showSpinner loading={loading} />
      <OnboardingWrapper fullWidth={location.pathname === '/onboarding/select_plan'}>
        <Switch>
          <AuthorizedRoute exact path={`${match.path}/intro`} render={(props) => <Intro store={refreshedStore} {...props} />} />
          <AuthorizedRoute exact path={`${match.path}/branding`} render={(props) => <OnboardingBranding store={refreshedStore} progress={progress} {...props} />} />
          <AuthorizedRoute exact path={`${match.path}/solicitation`} render={(props) => <FlowSolicitation progress={progress} {...props} />} />
          <AuthorizedRoute exact path={`${match.path}/campaign`} render={(props) => <Campaign progress={progress} {...props} stores={stores} />} />
          <AuthorizedRoute exact path={`${match.path}/preview`} render={(props) => <Preview progress={progress} {...props} />} />
          <AuthorizedRoute exact path={`${match.path}/add-junip-1`} render={(props) => <AddJunip1 store={refreshedStore} progress={progress} {...props} />} />
          <AuthorizedRoute exact path={`${match.path}/add-junip-2`} render={(props) => <AddJunip2 store={refreshedStore} progress={progress} {...props} />} />
          <AuthorizedRoute exact path={`${match.path}/select_plan`} render={(props) => <SelectPlan progress={progress} {...props} />} />
          <AuthorizedRoute exact path={`${match.path}/thanks`} component={Thanks} />
          <Redirect to={`${match.path}/intro`} />
        </Switch>
      </OnboardingWrapper>
    </>
  );
}
