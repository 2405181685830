import axios from 'axios';

// Actions
const FETCH_SLICE_BEGIN = 'FETCH_SLICE_BEGIN';
const FETCH_SLICE_SUCCESS = 'FETCH_SLICE_SUCCESS';
const FETCH_SLICE_ERROR = 'FETCH_SLICE_ERROR';

// Action Creators
export function fetchSlice() {
  return fetchDb('/api/v1/access/presigned_url')
}

export function fetchSliceDemo() {
  return fetchDb('/api/v1/access/demo')
}

export function fetchDb(url) {
  return async dispatch => {
    dispatch(fetchSliceBegin());
    try {
      // Fetches presigned url from the store's S3 bucket
      const response = await axios.get(url);
      dispatch(fetchSliceSuccess(response.data.url));
    } catch (e) {
      dispatch(fetchSliceError(e));
    }
  }
}

export const fetchSliceBegin = () => ({
  type: FETCH_SLICE_BEGIN
});

export const fetchSliceSuccess = data => ({
  type: FETCH_SLICE_SUCCESS,
  data: data
});

export const fetchSliceError = error => ({
  type: FETCH_SLICE_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_SLICE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SLICE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_SLICE_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
