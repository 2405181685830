import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUser, setLoggedOut } from 'redux/user';
import axios from 'axios';

import LoadingCover from 'components/LoadingCover';

import 'styles/components/LoadingCover.scss';

function UserLoadingCover(props) {
  const { setUser, setLoggedOut } = props;
  const [loading, setLoading] = useState(true);

  const replace = props.history.replace;

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await axios.get('/api/v1/users/me');
        const user = response.data.user;
        setLoading(false);
        if (user.skeleton) {
          setLoggedOut();
          replace(`/login/create/${user.id}`);
        } else {
          setUser(response.data.user);
          let pushToOnboarding = null;
          try {
            pushToOnboarding = JSON.parse(window?.localStorage?.getItem('redirectToOnboarding'))
          } catch (e) {
            pushToOnboarding = false;
          }
          if (pushToOnboarding) {
            replace('/onboarding/intro');
          }
        }
      } catch (e) {
        setLoggedOut();
        setLoading(false);
      }
    }

    fetchUser();
  }, [replace, setUser, setLoggedOut]);

  return (
    <LoadingCover loading={loading} showSpinner={false} />
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoggedOut: () => dispatch(setLoggedOut())
});

export default withRouter(connect(
  null,
  mapDispatchToProps
)(UserLoadingCover));
