import React from 'react';
import { trackEvent } from 'utils/Mixpanel';
import {
  LegacyCard,
  EmptyState
} from '@shopify/polaris';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import upgrade from 'assets/images/upgrade.svg';

export default function UpgradePlan({
  title,
  body
}) {
  const history = useHistory();

  return (
    <LegacyCard>
      <EmptyState
        heading={title}
        image={upgrade}
        action={{ content: 'View plans', url: '/settings/billing', onAction: () => trackEvent('Oauth upgrade trigger - View plans') }}
        secondaryAction={{ content: 'Go back', onAction: () => { history.goBack(); trackEvent('Oauth upgrade trigger - Go back'); } }}
        imageContained={true}
      >
        <p>
          {body}
        </p>
      </EmptyState>
    </LegacyCard>
  );
}
