import React, { useState, useCallback, useEffect } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import moment from 'moment';
import axios from 'axios';
import { LegacyCard, ProgressBar, Collapsible, Button, ButtonGroup, LegacyStack, Text } from "@shopify/polaris";
import OnboardingStep from 'components/OnboardingStep';

export default function GatherReviewsGuide({
  store,
  total_reviews,
  solicitationCampaigns
}) {
  const [importCreated, setImportCreated] = useState(false);

  useEffect(() => {
    async function checkImportCreated() {
      const response = await axios.get('/api/v1/review_imports');
      if (response?.data?.review_imports?.length > 0) {
        setImportCreated(true);
      }
    }
    checkImportCreated();
  } , []);

  let steps = [
    {
      type: 'import',
      completed: importCreated,
      expanded: false,
      title: 'Import reviews from another provider',
      description: 'Switching over from another review provider? Import your reviews by visiting the Manage Reviews section and clicking "Import".',
      action: (
        <ButtonGroup>
          <Button

            url="/reviews?showImportModal=true"
            onClick={() => trackEvent('Gather reviews - Import reviews')}
            variant="primary">Import reviews</Button>
          <Button
            url="https://help.junip.co/en/articles/4607108-importing-your-reviews-to-junip"
            external

            onClick={() => trackEvent('Gather reviews - Learn More')}
            variant="plain">
            Learn more
          </Button>
        </ButtonGroup>
      )
    },
    {
      type: 'campaign',
      completed: solicitationCampaigns?.data?.length > 0,
      expanded: false,
      title: 'Run a campaign to collect reviews from previous customers',
      description: 'Requesting reviews from customers who have previously made a purchase by creating a campaign.',
      action: (
        <ButtonGroup>
          <Button
            url="/flows/campaigns"
            onClick={() => trackEvent('Gather reviews - Visit campaigns')}
            variant="primary">Visit Campaigns</Button>
          <Button
            url="https://help.junip.co/en/articles/4607216-how-to-create-a-campaign"
            external

            onClick={() => trackEvent('Gather reviews - Learn More')}
            variant="plain">
            Learn more
          </Button>
        </ButtonGroup>
      )
    }
  ];

  const completed = (steps.filter(step => step.completed === true)).length;
  const progress = (completed / steps.length) * 100;

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (progress < 100) {
      setActive(true);
    }
  } , [progress]);

  const handleToggle = useCallback(() => setActive((active) => !active), []);

  if (total_reviews > 0) {
    const storeCreated = moment(store?.created_at);
    const now = moment();
    const diff = now.diff(storeCreated, 'days');
    if (diff > 14) {
      return (null);
    }
  }

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <LegacyStack>
          <LegacyStack.Item fill>
            <Text variant="headingMd" as="h2">Gather your first reviews</Text>
          </LegacyStack.Item>
          <Button  onClick={handleToggle} variant="plain">
            {`${active ? 'Hide' : 'Show'} steps`}
          </Button>
        </LegacyStack>
        <div className="my-3">
          <ProgressBar progress={progress} size="small" tone="success"></ProgressBar>
        </div>
      </LegacyCard.Section>
      <Collapsible id={`start-guide-collapsible`} open={active}>
        { steps.map((step, index) => <OnboardingStep key={step.type} index={index} step={step} /> ) }
      </Collapsible>
    </LegacyCard>
  );
}
