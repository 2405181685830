import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  Modal,
  ResourceList,
  ResourceItem,
  Thumbnail,
  Pagination,
  Filters,
  Button,
  Text,
} from "@shopify/polaris";
import useDebounce from 'utils/useDebounce';

import { SearchIcon } from "@shopify/polaris-icons";

import noImage from 'assets/images/noImage.jpg';

function ProductPickerModal({ open, close, showToast, filter, preSelected = [], submit, preSelectedProduct = null }) {
  const [loading, setLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const [data, setData] = useState({});
  const [selected, setSelected] = useState([]);
  const [filters] = useState(filter || {});
  const [queryValue, setQueryValue] = useState('');
  const debouncedQueryValue = useDebounce(queryValue, 400);

  const generateId = useCallback(({ id, title, created_at }) => {
    return JSON.stringify({ id, title, created_at });
  }, []);

  const fetchProducts = useCallback(async ({ page = {}, queryValue }) => {
    try {
      setLoading(true);
      const response = await axios.get('/api/v1/products', {
        params: {
          include: 'image',
          'filter[search]': queryValue || null,
          'page[after]': page.after,
          'page[before]': page.before,
          ...filters
        }
      });
      setData(response.data);
    } catch (e) {
      showToast('Error fetching products, please try again', true);
    } finally {
      setLoading(false);
    }
  }, [showToast, filters]);

  useEffect(() => {
    if (open) {
      fetchProducts({ queryValue: debouncedQueryValue });
    }
  }, [fetchProducts, open, debouncedQueryValue]);

  useEffect(() => {
    if (open && preSelected.length) {
      setSelected(preSelected.map((item) => generateId(item)));
    }
  }, [preSelected, open, generateId]);

  const select = async () => {
    try {
      setWorking(true);
      const parsedItems = selected.map((item) => {
        return JSON.parse(item);
      });

      await submit(parsedItems);
    } catch (e) {
      showToast('Error selecting products, please try again', true);
    } finally {
      setWorking(false);
      closeModal();
    }
  };

  const closeModal = () => {
    if (working) {
      return;
    }

    setSelected([]);
    setQueryValue('');
    close();
  };

  const handleSelectionChange = (selectedItems) => {
    if (preSelectedProduct) {
      selectedItems = selectedItems.filter((item) => item !== generateId(preSelectedProduct));
    }

    setSelected(selectedItems);
  };

  const renderItem = (item) => {
    const { title, image } = item;
    const itemId = generateId(item);
    const media = <Thumbnail source={image?.url_200x200 || noImage} alt={title} size="small" />;
    return (
      <ResourceItem
        key={itemId}
        id={itemId}
        media={media}
        verticalAlignment="center"
        onClick={() => {
          if (preSelectedProduct && preSelectedProduct.id === item.id) {
            return;
          }

          if (selected.includes(itemId)) {
            setSelected((prev) => prev.filter((i) => i !== itemId));
          } else {
            setSelected((prev) => [...prev, itemId]);
          }
        }}
      >
        <h3>
          <Text variant="bodyMd" as="span" fontWeight="semibold">{title}</Text>
        </h3>
        {preSelectedProduct && preSelectedProduct.id === item.id && (
          <Text variant="bodyMd" as="span" tone="success">This product is the Bundle owner</Text>
        )}
      </ResourceItem>
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title="Select products"
      primaryAction={{
        content: 'Select',
        onAction: select,
        loading: working
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: closeModal,
          disabled: working
        },
      ]}
    >
      <ResourceList
        resourceName={{ singular: 'product', plural: 'products' }}
        items={data?.products || []}
        renderItem={renderItem}
        loading={loading}
        hasMoreItems={false}
        onSelectionChange={handleSelectionChange}
        selectedItems={[...selected, ...(preSelectedProduct ? [generateId(preSelectedProduct)] : [])]}
        selectable={true}
        resolveItemId={(item) => generateId(item)}
        idForItem={(item) => generateId(item)}
        emptyState={
          <Modal.Section>
            <h3>No products available to add</h3>
          </Modal.Section>
        }
        filterControl={
          <Filters
            queryValue={queryValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={setQueryValue}
            onQueryClear={() => { setQueryValue(''); }}
            onClearAll={() => { setQueryValue(''); }}
          >
            <Button
              onClick={() => fetchProducts({ queryValue })}
              loading={loading}
              icon={SearchIcon}
            />
          </Filters>
        }
      />
      {data?.products?.length > 0 && (
        <Modal.Section>
          <Pagination
            hasPrevious={data?.meta?.page?.before || false}
            onPrevious={() => {
              fetchProducts({
                page: { before: data?.meta?.page?.before },
                queryValue
              });
            }}
            hasNext={data?.meta?.page?.after || false}
            onNext={() => {
              fetchProducts({
                page: { after: data?.meta?.page?.after },
                queryValue
              });
            }}
          />
        </Modal.Section>
      )}
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(ProductPickerModal);
