import React, { useState } from 'react';
import axios from 'axios';
import { LegacyCard, TextContainer } from '@shopify/polaris';

export default function NoStores({ replace }) {
  const [loading, setLoading] = useState(false);

  const home = async () => {
    setLoading(true);
    try {
      await axios.post('/api/v1/users/sign_out');
    } catch (e) {
      // No-op
    } finally {
      setLoading(false);
      replace('/login');
    }
  };

  return (
    <LegacyCard
      sectioned
      title="No stores found"
      primaryFooterAction={{
        content: 'Contact support',
        url: 'mailto:support@juniphq.com',
        external: true
      }}
      secondaryFooterActions={[{
        content: 'Take me home',
        onAction: home,
        loading: loading
      }]}
      footerActionAlignment="left">
      <TextContainer>
        <p>
          There are no stores connected to this organization. Please connect a store and try again or contact support@juniphq.com
        </p>
      </TextContainer>
    </LegacyCard>
  );
}
