import React, { useState, useCallback } from 'react';
import { Modal, FormLayout, Select, TextField, ChoiceList, Banner, Text } from "@shopify/polaris";

function IncentiveModal({
  title,
  store,
  showModal,
  incentiveTypes,
  newIncentive,
  setNewIncentive,
  primaryAction,
  secondaryActions,
  defaultBuyers,
  disableFields,
  close
}) {
  const [selected, setSelected] = useState(defaultBuyers);

  const renderIncentiveTypeOptions = () => {
    let defaultOptions = [{ label: '--Select an incentive type--', value: '', disabled: true }]
    let options = incentiveTypes?.map(i => {
      return { label: i.name, value: i.slug };
    });
    if (options?.length === 1) {
      return options;
    } else if (options?.length) {
      return [...defaultOptions, ...options];
    }
    return defaultOptions;
  };

  const handleNewIncentiveTypeChange = useCallback((value) => {
    const newType = incentiveTypes.find(i => i.slug === value);
    setNewIncentive({ ...newType, verified_buyers_only: true, active: true });
  }, [incentiveTypes, setNewIncentive]);

  const handleNewIncentiveTypeValueChange = (field) => {
    return (value) => {
      if (field === 'value') {
        value = value.replace(/[^0-9.]/g, '');
      } else {
        setSelected(value);
        value = (value[0] === 'verified') ? true : false;
      }
      setNewIncentive(newIncentiveType => ({ ...newIncentiveType, [field]: value }));
    };
  };
  return (
    <Modal
      open={showModal}
      title={title}
      onClose={close}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select
              label='Incentive type'
              options={renderIncentiveTypeOptions(incentiveTypes)}
              onChange={handleNewIncentiveTypeChange}
              value={newIncentive?.slug || ''}
              disabled={disableFields}
            />
            {newIncentive.requires_value &&
              <TextField
                label='Amount'
                type='number'
                min='0'
                max={newIncentive.slug.includes('fixed') ? undefined : '100'}
                value={newIncentive.value || ''}
                disabled={disableFields}
                onChange={handleNewIncentiveTypeValueChange('value')}
                prefix={newIncentive.slug.includes('fixed') ? `${store?.currency_symbol || '$'}` : null}
                suffix={newIncentive.slug.includes('percent') ? '%' : null}
              />
            }
          </FormLayout.Group>
          <ChoiceList
            title='Reward this to:'
            choices={[
              { label: <>Verified buyers only <Text variant="bodyMd" as="span" tone="subdued">(Recommended)</Text></>, value: 'verified' },
              { label: 'Anyone who leaves a review, including unverified buyers', value: 'anyone' },
            ]}
            selected={selected}
            onChange={(value) => handleNewIncentiveTypeValueChange('verified_buyers_only')(value)}
          />
          <Banner
            tone='info'
          >
            <p>Junip generates single use, unique discount codes to offer to customers when an eligible review is submitted.</p>
          </Banner>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

export default IncentiveModal;
