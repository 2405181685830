import React from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { Button, Page, LegacyStack, LegacyCard, TextContainer, Text } from "@shopify/polaris";
import JunipLogo from 'components/JunipLogo';
import SkipOnboarding from 'components/SkipOnboarding';

import { ExternalIcon } from "@shopify/polaris-icons";
import addBlocks from 'assets/images/onboarding/addBlocks.gif';

const NEXT_STEP = '/onboarding/solicitation';

export default function AddJunip2({ store, progress }) {
  const renderProductStarsUrl = () => {
    return `https://${store?.app_install?.uid}/admin/themes/current/editor?template=product&addAppBlockId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/junip-product-summary&target=mainSection`;
  };

  const renderProductReviewsUrl = () => {
    return `https://${store?.app_install?.uid}/admin/themes/current/editor?template=product&addAppBlockId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/junip-product-review&target=newAppsSection`;
  };

  return (
    <Page>
      <LegacyCard flush>
        <div className="onboarding-step o-divider">
          <div className="o-step o-step-subdued o-divider-right">
            <LegacyStack vertical spacing="extraLoose">
              <JunipLogo height={'24px'} />
              <Text variant="heading2xl" as="p">Add Junip to your Shopify theme</Text>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">It looks like your Shopify theme supports sections everywhere! We'll walk you through the steps on how to add Junip to your theme:</Text>
              </p>
              <LegacyCard
                title="Add the Junip review stars and reviews widget to your theme"
                sectioned>
                <TextContainer spacing="loose">
                  <Text variant="bodyMd" as="span" tone="subdued">Visit your Shopify theme editor and add the Junip Product Summary Stars and the Product Reviews blocks to your theme</Text>
                  <div>
                    <Button
                      external
                      size="large"

                      url={renderProductStarsUrl()}
                      icon={ExternalIcon}
                      onClick={() => trackEvent('Onboarding add junip 2 - Add Product Summary Stars')}
                      variant="primary">
                      Add Product Summary Stars
                    </Button>
                  </div>
                  <div>
                    <Button
                      external
                      size="large"

                      url={renderProductReviewsUrl()}
                      icon={ExternalIcon}
                      onClick={() => trackEvent('Onboarding add junip 2 - Add Product Reviews')}
                      variant="primary">
                      Add Product Reviews
                    </Button>
                  </div>
                </TextContainer>
              </LegacyCard>
            </LegacyStack>
          </div>
          <div className="o-step o-step-highlight">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <img src={addBlocks} alt="Add Junip blocks" className="w-100" />
            </div>
          </div>
        </div>
        <LegacyCard.Section subdued>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              {progress}
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Button
                size="large"
                url={NEXT_STEP}
                onClick={() => trackEvent('Onboarding add junip 2 - Continue')}
              >
                Continue
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <SkipOnboarding disabled={false} />
    </Page>
  );
}
