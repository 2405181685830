import React from 'react';
import { Layout, LegacyCard, Spinner, SkeletonDisplayText, LegacyStack } from '@shopify/polaris';

export default function AnalyticsLoadingWrapper({ loading, children }) {
  const spinnerContainer = (
    <div style={{
      background: 'var(--p-color-bg-surface)',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Spinner accessibilityLabel='Content loading' />
    </div>
  );

  const skeletonContainer = (
    <div style={{ height: '300px' }}>
      <div style={{
        background: 'var(--p-color-bg-subdued)',
        width: '100%',
        height: '100%',
      }}/>
    </div>
  );

  const SkeletonChart = () => (
    <Layout.Section variant="oneHalf">
      <LegacyCard>
        <LegacyCard.Section>
          <SkeletonDisplayText size='small' />
        </LegacyCard.Section>
        <LegacyCard.Section>
          {skeletonContainer}
        </LegacyCard.Section>
      </LegacyCard>
    </Layout.Section>
  );

  if (loading) {
    return <>
      <Layout.Section>
        <LegacyStack distribution='equalSpacing'>
          {[...Array(2)].map((e, i) =>
            <LegacyStack.Item fill key={i}>
              <LegacyCard title={<SkeletonDisplayText size='small' />}>
                <LegacyCard.Section>
                  <SkeletonDisplayText size='medium' />
                </LegacyCard.Section>
                <LegacyCard.Section subdued>
                </LegacyCard.Section>
              </LegacyCard>
            </LegacyStack.Item>
          )}
        </LegacyStack>
      </Layout.Section>
      <Layout.Section variant="fullWidth">
        <LegacyCard>
          <LegacyCard.Section>
            <SkeletonDisplayText size='small'/>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <div style={{ height: '500px' }}>
              {spinnerContainer}
            </div>
          </LegacyCard.Section>
        </LegacyCard>
      </Layout.Section>
      {[...Array(4)].map((e, i) =>
        <SkeletonChart key={i}/>
      )}
    </>;
  } else {
    return (
      <>
        {children}
      </>
    );
  }
}
