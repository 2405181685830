import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import Oauth from 'utils/oauth';
import { showToast } from 'redux/toast';
import { fetchIncentiveTypes } from 'redux/incentiveTypes';
import { fetchIncentives } from 'redux/incentives';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import LoadingContentWrapper from 'components/LoadingContentWrapper';
import UpgradeTrigger from 'components/UpgradeTrigger';
import Incentive from 'components/Incentive';
import IncentiveModal from 'components/IncentiveModal';
import {
  Page,
  LegacyCard,
  TextContainer,
  Banner,
  FooterHelp,
  Link,
  EmptyState,
  Layout,
  Text,
} from "@shopify/polaris";
import mediaIncentive from 'assets/images/mediaIncentive.svg';
import standardIncentive from 'assets/images/standardIncentive.svg';

function Incentives({ incentives, incentiveTypes, stores, fetchIncentives, fetchIncentiveTypes, showToast }) {
  const [connecting, setConnecting] = useState(false);
  const [newIncentiveType, setNewIncentiveType] = useState({});
  const [addingIncentive, setAddingIncentive] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showAddIncentiveModal, setShowAddIncentiveModal] = useState(false);
  const store = stores?.data?.find?.(s => s.scoped === true) || {};

  useEffect(() => {
    fetchIncentives();
  }, [fetchIncentives]);

  useEffect(() => {
    fetchIncentiveTypes({ 'filter[active]': true });
  }, [fetchIncentiveTypes]);

  useEffect(() => {
    if (showAddIncentiveModal === false) {
      setNewIncentiveType({});
    }
  }, [showAddIncentiveModal]);

  const connectStore = () => {
    setConnecting(true);
    const appInstall = store?.app_install;
    Oauth({ action: '/api/v1/oauth/shopify', body: { shop: appInstall?.uid } });
    trackEvent('Incentives - Re-authenticate Junip');
  };

  const addIncentive = async (mediaIncentive) => {
    setAddingIncentive(true);
    try {
      await axios.post('/api/v1/incentives', {
        incentive: {
          active: true,
          incentive_type_id: newIncentiveType.id,
          value: newIncentiveType.value,
          verified_buyers_only: newIncentiveType.verified_buyers_only,
          requires_media: mediaIncentive
        }
      });
      fetchIncentives();
      showToast('Incentive added');
      setNewIncentiveType({});
      setShowAddIncentiveModal(false);
      trackEvent('Incentives - Add incentive');
    } catch (e) {
      if (e?.response?.status === 422) {
        showToast('Invalid incentive value, please update and try again', true);
      } else {
        showToast('Error adding incentive, please try again', true);
      }
      trackEvent('Error: Incentives - Add incentive', { statusCode: e?.response?.status });
    } finally {
      setAddingIncentive(false);
    }
  };

  const updateIncentive = async (newIncentive) => {
    setUpdating(true);
    try {
      await axios.put(`/api/v1/incentives/${newIncentive.id}`, {
        incentive: {
          active: true,
          verified_buyers_only: newIncentive.verified_buyers_only
        }
      });
      fetchIncentives();
      showToast('Incentive updated');
      trackEvent('Incentives - Update incentive');
    } catch (e) {
      if (e?.response?.status === 422) {
        showToast('Invalid incentive value, please update and try again', true);
      } else {
        showToast('Error adding incentive, please try again', true);
      }
      trackEvent('Error: Incentives - Update incentive', { statusCode: e?.response?.status });

      throw e;
    } finally {
      setUpdating(false);
    }
  };

  const deleteIncentive = async ({ id }) => {
    setUpdating(true);
    try {
      await axios.delete(`/api/v1/incentives/${id}`);
      fetchIncentives();
      showToast('Incentive deleted');
      trackEvent('Incentives - Delete incentive');
    } catch (e) {
      showToast('Error deleting incentive, please try again', true);
      trackEvent('Error: Incentives - Delete incentive', { statusCode: e?.response?.status });

      throw e;
    } finally {
      setUpdating(false);
    }
  };

  const activeIncentive = incentives?.data?.find(i => i.active && i.requires_media === false);
  const activeMediaIncentive = incentives?.data?.find(i => i.active && i.requires_media === true);

  return (
    <LoadingPageWrapper loading={incentiveTypes?.loading || incentives?.loading}>
      <Page
        title="Incentives"
        subtitle="Offer incentives, such as discounts and free shipping, in exchange for reviews."
      >
        <Layout>
          {!store?.app_install?.has_full_scope &&
            <Layout.Section>
              <Banner
                title="Missing permissions"
                tone="warning"
                action={{ content: 'Re-authenticate Junip', onAction: connectStore, loading: connecting }}
              >
                <p>Shopify has recently allowed apps to create discounts that also work on Subscription orders, but we require new permissions to enable this. Please re-authenticate to allow discounts to work on all purchases.</p>
              </Banner>
            </Layout.Section>
          }
          <Layout.Section>
            <UpgradeTrigger
              feature={'pf_review_incentives'}
              title={'Upgrade to use incentives'}
              description={'Incentives are available on the Essentials plan & above.'}
            >
              <LoadingContentWrapper loading={incentives.loading}>
                <Layout>
                  <Layout.Section>
                    {activeIncentive?.id ?
                      <LegacyCard title="Standard incentive">
                        <LegacyCard.Section>
                          <Incentive
                            key={activeIncentive.id}
                            incentive={activeIncentive}
                            updateIncentive={updateIncentive}
                            deleteIncentive={deleteIncentive}
                            updating={updating}
                          />
                        </LegacyCard.Section>
                        <LegacyCard.Section subdued>
                          <Text variant="bodyMd" as="span" tone="subdued">Standard incentives are given to any customer who leaves a review, regardless of content.</Text>
                        </LegacyCard.Section>
                      </LegacyCard>
                      :
                      <LegacyCard>
                        <EmptyState
                          heading={"Add a standard incentive"}
                          image={standardIncentive}
                          action={{ content: 'Add standard incentive', onAction: () => setShowAddIncentiveModal({ mediaIncentive: false }), disabled: !store?.app_install?.has_full_scope }}
                        >
                          <p>Standard incentives are given to any customer who leaves a review, regardless of content.</p>
                        </EmptyState>
                      </LegacyCard>
                    }
                  </Layout.Section>
                  <Layout.Section>
                    <LegacyCard>
                      <UpgradeTrigger
                        feature={'pf_advanced_incentives'}
                        title={'Upgrade to add media incentives'}
                        description={'Upgrade your plan to offer special incentives for reviews that include media.'}
                      >
                        {activeMediaIncentive?.id ?
                          <LegacyCard title="Media incentive">
                            <LegacyCard.Section>
                              <Incentive
                                key={activeMediaIncentive.id}
                                incentive={activeMediaIncentive}
                                updateIncentive={updateIncentive}
                                deleteIncentive={deleteIncentive}
                                updating={updating}
                              />
                            </LegacyCard.Section>
                            <LegacyCard.Section subdued>
                              <Text variant="bodyMd" as="span" tone="subdued">
                                Media incentives are given to customers who include media in their reviews. Eligible customers will receive this <i>instead</i> of the standard incentive.
                              </Text>
                            </LegacyCard.Section>
                          </LegacyCard>
                          :
                          <LegacyCard>
                            <EmptyState
                              heading={"Add a media incentive"}
                              image={mediaIncentive}
                              action={{ content: 'Add media incentive', onAction: () => setShowAddIncentiveModal({ mediaIncentive: true }), disabled: !store?.app_install?.has_full_scope }}
                            >
                              <p>
                                Media incentives are given to customers who include media in their reviews.
                                Eligible customers will receive this <i>instead</i> of the standard incentive.
                              </p>
                            </EmptyState>
                          </LegacyCard>
                        }
                      </UpgradeTrigger>
                    </LegacyCard>
                  </Layout.Section>
                  <Layout.Section variant="oneHalf">
                    <LegacyCard sectioned title="How incentives work">
                      <TextContainer>
                        <p>
                          Junip generates single use, unique discount codes to offer to customers when an eligible review is submitted. Incentives are shown at the end of a review submission form. In addition, you can also choose to send incentives via email.
                        </p>
                        <p>
                          We recommend mentioning the incentives in your {' '}
                          <Link url="/flows/templates">
                            review request message
                          </Link>
                          {' '} to encourage reviews.
                        </p>
                      </TextContainer>
                    </LegacyCard>
                  </Layout.Section>
                  <Layout.Section variant="oneHalf">
                    <LegacyCard title="Sending incentives by email">
                      <LegacyCard.Section>
                        <TextContainer spacing="title">
                          <p>
                            Send incentives to your customers using your {' '}
                            <Link url="/flows">
                              <b>Review Submitted Actions flow</b>
                            </Link>
                            .
                          </p>
                          <p>
                            To customize your message, edit the {''}
                            <Link url="/flows/templates">
                              <b>Incentive email template</b>
                            </Link>
                            .
                          </p>
                        </TextContainer>
                      </LegacyCard.Section>
                      <LegacyCard.Section title="Sending with integrations">
                        <Link external url="https://help.junip.co/en/articles/5907170-adding-integrations-to-your-flows">
                          <p>
                            Learn how to send messages using integrations.
                          </p>
                        </Link>
                      </LegacyCard.Section>
                    </LegacyCard>
                  </Layout.Section>
                </Layout>
              </LoadingContentWrapper>
            </UpgradeTrigger>
          </Layout.Section>
          <IncentiveModal
            title={showAddIncentiveModal?.mediaIncentive === true ? 'Add media incentive' : 'Add standard incentive'}
            store={store}
            showModal={showAddIncentiveModal}
            incentiveTypes={incentiveTypes?.data}
            newIncentive={newIncentiveType}
            setNewIncentive={setNewIncentiveType}
            primaryAction={{
              content: 'Add incentive',
              loading: addingIncentive,
              disabled: !newIncentiveType.slug || (newIncentiveType.requires_value && (!newIncentiveType.value || newIncentiveType.value === '0')),
              onAction: () => addIncentive(showAddIncentiveModal?.mediaIncentive ? true : false)
            }}
            secondaryActions={[{
              content: 'Cancel',
              disabled: addingIncentive,
              onAction: () => { setShowAddIncentiveModal(false); trackEvent('Add incentive modal - Cancel') },
            }]}
            defaultBuyers={'verified'}
            close={() => !addingIncentive && setShowAddIncentiveModal(false)}
            disableFields={false}
          />
        </Layout>
        <FooterHelp>
          Learn more about {' '}
          <Link external url="https://help.junip.co/en/articles/4607172-how-to-incentivize-reviews">
            incentivizing reviews.
          </Link>
        </FooterHelp>
      </Page>
    </LoadingPageWrapper>
  );
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  incentives: state.incentives,
  incentiveTypes: state.incentiveTypes
});

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchIncentives: (params) => dispatch(fetchIncentives(params)),
  fetchIncentiveTypes: () => dispatch(fetchIncentiveTypes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Incentives);
