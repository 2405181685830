import React, { useState, useCallback } from 'react';

import {
  LegacyCard,
  Form,
  FormLayout,
  TextField,
  ButtonGroup,
  Button,
  Banner,
  Collapsible
} from '@shopify/polaris';

import LoginWrapper from 'components/LoginWrapper';

import axios from 'axios';

export default function ForgotPassword ({ history }) {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const queryParams = history.location.search;

  const handleEmailChange = useCallback((newEmail) => setEmail(newEmail), []);

  const handleSubmit = async() => {
    if (!isValid()) {
      return;
    }

    setIsLoading(true);

    try {
      await axios.post(
        '/api/v1/users/forgot_password',
        {
          user: {
            email
          }
        }
      );

      setIsLoading(false);
      setIsSubmitted(true);
    } catch(e) {
      // TODO Show error message if network error
      setIsLoading(false);
    }
  };

  const handleDismiss = () => {
    setIsSubmitted(false);
    setEmail('');
  };

  const isValid = () => {
    if (!email) {
      return false;
    }
    if (!email.includes('@')) {
      return false;
    }

    return true;
  };

  return (
    <LoginWrapper>
      <LegacyCard sectioned title="Forgot your password?">
        <p className="mb-4">We'll email you a link to reset it.</p>
        <Collapsible open={isSubmitted}>
          <div className="pb-4">
            <Banner tone="success" onDismiss={handleDismiss}>
              A password reset link has been sent to your email.
            </Banner>
          </div>
        </Collapsible>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              autoFocus={true}
              value={email}
              name="email"
              onChange={handleEmailChange}
              type="email"
              disabled={isSubmitted}
              placeholder="Email"
              autoComplete="email"
            />
            <div>
              <ButtonGroup>
                <Button
                  size="large"
                  submit
                  disabled={isSubmitted || !isValid()}
                  loading={isLoading}
                  variant="primary">Reset password</Button>
                <Button url={"/login" + queryParams} variant="plain">Return to login</Button>
              </ButtonGroup>
            </div>
          </FormLayout>
        </Form>
      </LegacyCard>
    </LoginWrapper>
  );
}
