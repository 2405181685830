import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setUser } from 'redux/user';
import { showToast } from 'redux/toast';

import { LegacyCard, Form, FormLayout, TextField, ButtonGroup, Button, Link, Text, InlineStack } from "@shopify/polaris";

import ReCAPTCHA from 'react-google-recaptcha';

import LoginWrapper from 'components/LoginWrapper';

import axios from 'axios';

const CreateAccountLayout = (props) => {
  const queryParams = props.history.location.search;
  const params = new URLSearchParams(queryParams);
  const token = params.get('token');
  const email = params.get('email') || '';
  const userId = props.match.params.id;

  const [inputs, setInputs] = useState({ email });
  const [isLoading, setIsLoading] = useState(false);
  const [invalidPasswordError, setInvalidPasswordError] = useState(null);
  const [emailTakenError, setEmailTakeError] = useState(null);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const googleCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const fetchInvitations = async (user, skipOrg = false) => {
    try {
      // Need to verify email before accepting invitations
      if (token) {
        try {
          await axios.put(`/api/v1/users/${user.id}/verify_email`, { token });
          user.verified_email = true;
        } catch(e) {
          props.history.push('/login/create/organization' + queryParams, { user });
          return;
        }
      } else if (!user.verified_email && !skipOrg) {
        // Can't accept invitations if user email is not verified
        props.history.push('/login/create/organization' + queryParams, { user });
        return;
      }

      const response = await axios.get('/api/v1/invitations', {
        params: {
          include: 'organization',
          'filter[email]': user.email,
          'filter[state]': 'pending'
        }
      });

      if (response?.data?.invitations?.length > 0) {
        props.history.push('/login/invitations' + queryParams, { user, invitations: response.data.invitations });
      } else {
        if (skipOrg) {
          props.setUser(user);
          props.history.push('/onboarding/intro');
        } else {
          props.history.push('/login/create/organization' + queryParams, { user });
        }
      }
    } catch(e) {
      setIsLoading(false);
      props.history.push('/login/create/organization' + queryParams, { user });
    }
  };

  const handleUserUpdate = async () => {
    setIsLoading(true);

    try {
      const response = await axios.put(
        `/api/v1/users/${userId}`,
        {
          user: {
            first_name: inputs.firstName,
            last_name: inputs.lastName,
            email: inputs.email,
            password: inputs.password,
            password_confirmation: inputs.password,
            accepts_marketing: true,
          }
        }
      );

      fetchInvitations(response.data.user, true);
    } catch(e) {
      props.showToast('Error creating account, please check your email and password and try again', true);
      setIsLoading(false);
    }
  };

  const isValid = () => {
    if (!inputs.firstName || !inputs.email || !inputs.password) {
      return false;
    }

    if (!inputs.email.includes('@')) {
      return false;
    }

    if (!captchaCompleted) {
      return false;
    }

    if (invalidPasswordError && (inputs.password.length >= 8 && inputs.password.length <= 72)) {
      setInvalidPasswordError(null);
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!isValid()) {
      return;
    }

    if (inputs.password.length < 8 || inputs.password.length > 72) {
      setInvalidPasswordError('Invalid password length');
      return;
    }

    if (userId) {
      handleUserUpdate();
      return;
    }
    setIsLoading(true);

    try {
      const response = await axios.post(
        '/api/v1/users',
        {
          user: {
            first_name: inputs.firstName,
            last_name: inputs.lastName,
            email: inputs.email,
            password: inputs.password,
            password_confirmation: inputs.password,
            accepts_marketing: true
          },
          recaptcha_token: captchaToken
        }
      );
      fetchInvitations(response.data.user, false);
    } catch(e) {
      let message = 'Error creating account, please check your email and password and try again';
      if (e?.response?.status === 400 && e.response?.data?.errors) {
        message = e.response.data.errors?.[0]?.detail || message;
        if (message === 'This email has already been taken') {
          setEmailTakeError(true);
        }
        props.showToast(message, true);
      } else if (e.response.status === 403) {
        message = 'Error creating account, failed captcha verification. Please try again.';
        props.showToast(message, true)
      } else {
        props.showToast(message, true);
      }
      setIsLoading(false);
    }
  };

  const handleInputChange = (field) => {
    return (value) => setInputs(inputs => ({...inputs, [field]: value}));;
  };

  const redirectToLogin = () => {
    props.history.push(`/login${queryParams}`, { email: inputs.email });
  };

  const onCaptchaChange = (value) => {
    if (value === null) {
      setCaptchaCompleted(false);
      return;
    }

    setCaptchaToken(value);
    setCaptchaCompleted(true);
  }

  const emailAlreadyTakenError = emailTakenError ? <p>
    An account already exists with this email. <Link onClick={redirectToLogin}>Sign in</Link> with that account
  </p>
  : null;

  return (
    <LoginWrapper showExplainer={true}>
      <LegacyCard sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              value={inputs.firstName}
              name="firstName"
              onChange={handleInputChange('firstName')}
              label="First name"
              type="text"
              placeholder="First name"
              autoFocus={true}
              autoComplete="given-name"
              disabled={isLoading}
            />
            <TextField
              value={inputs.lastName}
              name="lastName"
              onChange={handleInputChange('lastName')}
              label="Last name"
              type="text"
              placeholder="Last name"
              autoComplete="family-name"
              disabled={isLoading}
            />
            <TextField
              value={inputs.email}
              name="email"
              onChange={(value) => { handleInputChange('email')(value); if (emailTakenError) { setEmailTakeError(false); } }}
              label="Email"
              type="email"
              placeholder="Email"
              autoComplete="email"
              error={emailAlreadyTakenError}
              disabled={isLoading}
            />
            <TextField
              value={inputs.password}
              name="password"
              onChange={handleInputChange('password')}
              label="Password"
              type="password"
              placeholder="Password"
              helpText="Password must be at least 8 characters long"
              error={invalidPasswordError}
              minLength="8"
              maxLength="72"
              autoComplete="new-password"
              disabled={isLoading}
            />
            {/* <input type="hidden" id="gclid_field" name="gclid_field" value="" /> */}
            <ReCAPTCHA
              sitekey={googleCaptchaSiteKey}
              onChange={onCaptchaChange}
              theme={window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'}
            />
            <ButtonGroup>
              <Button
                disabled={!isValid()}
                size="large"
                submit
                loading={isLoading}
                variant="primary">
                  Create account
              </Button>
            </ButtonGroup>
            <Text variant="bodyMd" as="span" tone="subdued">Try Junip for free, no credit card required. By entering your email, you agree to receive marketing emails from Junip.</Text>
          </FormLayout>
        </Form>
      </LegacyCard>
      { !userId && (
        <LegacyCard sectioned>
          <InlineStack gap="200" align="space-between" blockAlign="center">
            <Text>Already have an account?</Text>
            <Button url={"/login" + queryParams}>Log in</Button>
          </InlineStack>
        </LegacyCard>
      )}
    </LoginWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateAccountLayout);
