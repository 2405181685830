export default async function Oauth({ action, body = {} }) {
  // Submit using form to avoid CORS issues
  const form = document.createElement('form');
  form.style.display = 'none';
  form.method = 'POST';
  form.action = action;
  Object.keys(body).forEach((key) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = body[key];
    form.appendChild(input);
  });
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
