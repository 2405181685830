export default function billingPlanDetails(billingPlan, twoPointOh = false) {
  const planName = billingPlan?.name?.toLowerCase();

  switch (planName) {
    case 'free':
      if (twoPointOh) {
        return {
          name: billingPlan.name,
          description: 'Basic tooling for new stores. Ad supported.',
          recommended: false,
          price: formatCurrency(billingPlan.base_price) || '$0',
          // everything in 1.0 free plan
          features: [
            true,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            `${billingPlan?.plan_feature_set?.pf_user_limit}`,
            false,
            true,
            'Limited',
            false
          ],
        }
      }
      return {
        name: billingPlan.name,
        description: 'Basic tooling for new stores, up to 50 orders a month',
        altDescription: 'Must-have features for entreprenuers who want a professionally built reviews solution.',
        recommended: false,
        price: formatCurrency(billingPlan.base_price) || '$0',
        features: [
          true,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          false,
          true,
          'Limited',
          false
        ]
      };
    case 'essentials':
      return {
        name: billingPlan.name,
        description: 'The core tools for businesses that are getting started',
        altDescription: 'Core features for small businesses on track to grow.',
        recommended: false,
        price: formatCurrency(billingPlan.base_price),
        features: [
          true,
          true,
          true,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          false,
          true,
          true,
          false
        ]
      };
    case 'standard':
      return {
        name: billingPlan.name,
        description: 'Everything you need for a growing business',
        altDescription: 'For mid-size businesses who want a reviews solution that will drive engagement and growth.',
        recommended: true,
        price: formatCurrency(billingPlan.base_price),
        features: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          false,
          false,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          false,
          true,
          true,
          false
        ]
      };
    case 'pro':
      return {
        name: billingPlan.name,
        description: 'Advanced customization & support for scaling businesses',
        altDescription: 'Advanced features for medium to large businesses that need the utmost customizability.',
        recommended: false,
        price: formatCurrency(billingPlan.base_price),
        features: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          `Up to ${billingPlan?.plan_feature_set?.pf_store_limit} stores`,
          true,
          true,
          true
        ]
      };
    case 'growth':
      if (twoPointOh) {
        return {
          name: billingPlan.name,
          description: 'Everything you need for a growing business',
          recommended: false,
          price: formatCurrency(billingPlan.base_price),
          // everything in 1.0 standard plan
          features: [
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            false,
            false,
            `${billingPlan?.plan_feature_set?.pf_user_limit}`,
            false,
            true,
            true,
            false
          ],
          summary: [
            '1 storefront + $69/month for each additional store',
            '2 external channels + $25/month for each additional channel',
            'Marketing integrations (Klaviyo, Drip, Omnisend, Postscript)',
            'All Core features',
          ]
        }
      }
      return {
        name: billingPlan.name,
        description: `Per ${billingPlan.bucket_size} orders. Must have features for all businesses who want a review solution that will drive engagement and growth.`,
        recommended: false,
        price: formatCurrency(billingPlan.bucket_price),
      };
    case 'credit':
    case 'trial':
      return {
        name: billingPlan.name,
        description: 'Try out Junip risk-free.',
        recommend: false,
        price: formatCurrency(billingPlan.base_price)
      }
    case 'premium':
      return {
        name: billingPlan.name,
        description: 'Advanced customization & support for scaling businesses',
        recommended: false,
        price: formatCurrency(billingPlan.base_price),
        // everything in 1.0 pro plan
        features: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          `Up to ${billingPlan?.plan_feature_set?.pf_store_limit} stores`,
          true,
          true,
          true
        ],
        summary: [
          '3 storefronts + $99/month per additional store',
          '4 external channels + $25/month per additional channel',
          'Review tagging',
          'Display API',
          'Custom apps',
          'All Growth features',
        ]
      }
    case 'core':
      return {
        name: billingPlan.name,
        description: 'Tools to start leveraging reviews',
        recommended: false,
        price: formatCurrency(billingPlan.base_price),
        // everything in 1.0 essentials plan
        // + grouping, media incentives, and media galleries
        features: [
          true,
          true,
          true,
          true,
          true, // media incentives
          false,
          false,
          false,
          false,
          true, // product grouping
          false,
          true, // media galleries
          false,
          false,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          false,
          true,
          true,
          false
        ],
        summary: [
          'Photo & video reviews',
          'Google review snippets',
          'General & media incentives',
          'Product grouping',
          'Media galleries',
        ]
      }
    default:
      return { features: [] };
  }
}

const formatCurrency = ({ cents, currency_iso }) => {
  if (cents) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency_iso,
      minimumFractionDigits: 0
    }).format(cents / 100);
  }
};
