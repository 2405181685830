import { showToast } from 'redux/toast';

// Actions
const SET_FORM_DIRTY = 'SET_FORM_DIRTY';
const DISCARD_CHANGES = 'DISCARD_CHANGES';
const SET_SAVING_CHANGES = 'SET_SAVING_CHANGES';
const CHANGES_SAVED = 'CHANGES_SAVED';

// Action Creators
export const setFormDirty = isDirty => ({
  type: SET_FORM_DIRTY,
  isDirty
});

export const discardChanges = () => ({
  type: DISCARD_CHANGES
});

export const savingChanges = (data = true) => ({
  type: SET_SAVING_CHANGES,
  data
});

export const setChangesSaved = () => ({
  type: CHANGES_SAVED,
});

export function changesSaved(hasError) {
  return dispatch => {
    dispatch(setChangesSaved());
    if (hasError) {
      dispatch(showToast('Error saving settings :( please try again later', true));
    } else {
      dispatch(showToast('Settings saved'));
    }
  }
}

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case SET_FORM_DIRTY:
      return {
        ...state,
        isDirty: action.isDirty,
        discardChanges: false
      };
    case DISCARD_CHANGES:
      return {
        ...state,
        discardChanges: true,
        isDirty: false,
        saving: false
      };
    case SET_SAVING_CHANGES:
      return {
        ...state,
        discardChanges: false,
        saving: action.data
      };
    case CHANGES_SAVED:
      return {
        ...state,
        isDirty: false,
        discardChanges: false,
        saving: false
      };
    default:
      return state;
  }
}
