import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  Modal,
  TextField,
  TextContainer,
  Button,
  Icon,
  Text,
} from "@shopify/polaris";
import {
  ClockIcon
} from '@shopify/polaris-icons';

export const MAX_STAR_RATING = 5;

function SubmittedConditions({ action, flow, editing, setEditing, updateActionConditionList }) {
  const [conditions, setConditions] = useState(action?.condition_list);
  const [inputs, setInputs] = useState({});
  const [saving, setSaving] = useState(false);

  const resetInputs = useCallback(() => {
    if (conditions) {
      setInputs({
        max_star_rating: conditions?.max_star_rating
      });
    } else {
      setInputs({});
    }
  }, [conditions]);

  useEffect(() => {
    if (conditions) {
      resetInputs();
    }
  }, [conditions, resetInputs]);

  const handleInputChange = (field) => {
    return (value) => {
      if (field === 'max_star_rating') {
        value = parseInt(value);
      }
      setInputs(inputs => ({ ...inputs, [field]: value }));
    };
  };

  const save = async () => {
    try {
      setSaving(true);
      const response = await axios.put(`/api/v1/flow_condition_lists/${conditions.id}`, {
        flow_condition_list: inputs
      });
      const newConditions = response.data.flow_condition_list;
      setConditions(newConditions);
      showToast('Conditions updated');
      trackEvent('Flows - Save conditions');
      setEditing(false);
      updateActionConditionList(action.id, newConditions);
    } catch (e) {
      showToast('Error updating conditons, please try again', true);
      trackEvent('Error: Flows - Save conditions', { statusCode: e?.response?.status });
    } finally {
      setSaving(false);
    }
  };

  return <>
    <TextContainer spacing="loose">
      <div className="d-flex">
        <div className="mr-3">
          <Icon source={ClockIcon} color="primary" />
        </div>
        <TextContainer spacing="tight">
          <>
            <p>
              <Button
                plain
                monochrome
                removeUnderline
                onClick={() => setEditing(true)}
              >
                <Text variant="bodyMd" as="span" fontWeight="semibold">IF star rating &le; {conditions?.max_star_rating}</Text>
              </Button>
            </p>
          </>
        </TextContainer>
      </div>
    </TextContainer>
    <Modal
      open={editing}
      onClose={() => setEditing(false)}
      title="Conditions"
      primaryAction={{
        content: 'Save',
        onAction: () => save(),
        loading: saving
      }}
      secondaryActions={[{
        content: 'Cancel',
        onAction: () => setEditing(false),
        disabled: saving,
      }]}
    >
      <Modal.Section>
        <TextField
          label="Star rating"
          type="number"
          max={MAX_STAR_RATING}
          min={1}
          pattern="[0-9]"
          value={`${inputs.max_star_rating || 0}`}
          onChange={handleInputChange('max_star_rating')}
          helpText="Only send events for reviews which have a star rating less than or equal to this value."
          suffix="stars"
          disabled={saving}
        />
      </Modal.Section>
    </Modal>
  </>;
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(SubmittedConditions);
