import React, { useState } from 'react';
import axios from 'axios';
import { LegacyCard, Button, Text } from "@shopify/polaris";

import LoginWrapper from 'components/LoginWrapper';
import Invitations from 'components/Invitations';

export default function UserInvitations(props) {
  if (!props.location?.state?.user) {
    props.history.replace('/login');
  }

  const [loading, setLoading] = useState(false);
  const { user, invitations } = props.location.state || {};
  const queryParams = props.history.location.search;

  const handleContinue = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/v1/organizations');
      const organizations = response?.data?.organizations;
      if (organizations?.length > 0) {
        props.history.push('/login/select/organization' + queryParams, { user, organizations });
      } else {
        props.history.push('/login/create/organization' + queryParams, { user });
      }
    } catch(e) {
      props.history.push('/login/create/organization' + queryParams, { user });
    }
  };

  return (
    <LoginWrapper>
      <div className="mb-4">
        <Text variant="headingMd" as="h2">Invitations</Text>
      </div>
      <LegacyCard>
        <Invitations invitations={invitations} />
      </LegacyCard>
      <div className="mt-6">
        <Button

          size="large"
          onClick={handleContinue}
          loading={loading}
          variant="primary">Continue</Button>
      </div>
    </LoginWrapper>
  );
}
