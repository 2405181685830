import axios from 'axios';

// Actions
const FETCH_PLAN_FEATURE_SETS_BEGIN = 'FETCH_PLAN_FEATURE_SETS_BEGIN';
const FETCH_PLAN_FEATURE_SETS_SUCCESS = 'FETCH_PLAN_FEATURE_SETS_SUCCESS';
const FETCH_PLAN_FEATURE_SETS_ERROR = 'FETCH_PLAN_FEATURE_SETS_ERROR';

// Action Creators
export function fetchPlanFeatureSets() {
  return async dispatch => {
    dispatch(fetchPlanFeatureSetsBegin());
    try {
      const response = await axios.get('/api/v1/plan_feature_sets');
      dispatch(fetchPlanFeatureSetsSuccess(response.data.plan_feature_sets[0]));
    } catch (e) {
      dispatch(fetchPlanFeatureSetsError(e));
    }
  }
}

export const fetchPlanFeatureSetsBegin = () => ({
  type: FETCH_PLAN_FEATURE_SETS_BEGIN
});

export const fetchPlanFeatureSetsSuccess = data => ({
  type: FETCH_PLAN_FEATURE_SETS_SUCCESS,
  data
});

export const fetchPlanFeatureSetsError = error => ({
  type: FETCH_PLAN_FEATURE_SETS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_PLAN_FEATURE_SETS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PLAN_FEATURE_SETS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_PLAN_FEATURE_SETS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
