import React, { useState } from 'react';
import { TextContainer, Button, Tooltip, Text } from "@shopify/polaris";
import { EditIcon } from "@shopify/polaris-icons";
import RequestTrigger from 'components/RequestTrigger';
import CampaignTrigger from 'components/CampaignTrigger';
import styles from 'styles/components/Flow.module.scss';

export default function Trigger({ flow, setFlow, children }) {
  const [editing, setEditing] = useState(false);

  const renderTrigger = () => {
    const name = ((flow) => {
      return `${flow?.event_topic?.subject}/${flow?.event_topic?.action}`;
    })(flow);

    let component = <p>No trigger</p>;
    let showEdit = false;

    switch (name) {
      case 'order/fulfilled':
        component =  (
          <RequestTrigger flow={flow} setFlow={setFlow} editing={editing} setEditing={setEditing} />
        );
        showEdit = true;
        break;
      case 'review/request':
        component =  (
          <RequestTrigger flow={flow} setFlow={setFlow} editing={editing} setEditing={setEditing} />
        );
        showEdit = true;
        break;
      case 'order/targeted-by-campaign':
      case 'review/request-by-campaign':
        component = (
          <CampaignTrigger />
        );
        break;
      case 'incentive/rewarded':
        component = (
          <p>
            <Text variant="bodyMd" as="span" fontWeight="semibold">When a customer submits a review eligible for a reward</Text>
          </p>
        );
        break;
      case 'review/created':
      case 'review/submitted':
        component = (
          <p>
            <Text variant="bodyMd" as="span" fontWeight="semibold">When a customer submits a review</Text>
          </p>
        );
        break;
      case 'review/confirmation-required':
        component = (
          <p>
            <Text variant="bodyMd" as="span" fontWeight="semibold">When a customer submits a review that needs to be verified</Text>
          </p>
        );
        break;
      case 'response/created':
        component = (
          <p>
            <Text variant="bodyMd" as="span" fontWeight="semibold">When a review is publicly responded to via the Junip admin</Text>
          </p>
        );
        break;
      default:
        break;
    }

    return { component, showEdit };
  };

  const { component, showEdit } = renderTrigger();

  return (
    <div>
      <div className={`${styles.flowCard} ${styles.trigger}`}>
        <div className={styles.flowAddIcon}>
          {children}
        </div>
        <div className={styles.flowContent}>
          <TextContainer>
            <Text variant="headingSm" as="h3">
              <Text variant="bodyMd" as="span" tone="subdued">Trigger</Text>
            </Text>
            {component}
          </TextContainer>
        </div>
        {showEdit &&
          <Tooltip content="Edit">
            <Button onClick={() => setEditing(true)}  icon={EditIcon} variant="plain" />
          </Tooltip>
        }
      </div>
    </div>
  );
}
