import axios from 'axios';

// Actions
const FETCH_SURVEY_COMPONENTS_BEGIN = 'FETCH_SURVEY_COMPONENTS_BEGIN';
const FETCH_SURVEY_COMPONENTS_SUCCESS = 'FETCH_SURVEY_COMPONENTS_SUCCESS';
const FETCH_SURVEY_COMPONENTS_ERROR = 'FETCH_SURVEY_COMPONENTS_ERROR';

// Action Creators
export function fetchSurveyComponents(params = {}) {
  return async dispatch => {
    dispatch(fetchSurveyComponentsBegin());
    try {
      const response = await axios.get('/api/v1/survey_components', {
        params: {
          'page[after]': params?.page?.after,
          'page[before]': params?.page?.before,
          'filter[product_id]': params?.product_id,
          'include': params?.include
        }
      });
      dispatch(fetchSurveyComponentsSuccess(response.data.survey_components, response.data?.meta));
    } catch (e) {
      dispatch(fetchSurveyComponentsError(e));
    }
  }
}

export const fetchSurveyComponentsBegin = () => ({
  type: FETCH_SURVEY_COMPONENTS_BEGIN
});

export const fetchSurveyComponentsSuccess = (data, meta) => ({
  type: FETCH_SURVEY_COMPONENTS_SUCCESS,
  data,
  meta
});

export const fetchSurveyComponentsError = error => ({
  type: FETCH_SURVEY_COMPONENTS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SURVEY_COMPONENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SURVEY_COMPONENTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        loading: false,
        error: null
      };
    case FETCH_SURVEY_COMPONENTS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
