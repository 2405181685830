import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import axios from 'axios';
import {
  Modal,
  FormLayout,
  Select,
  LegacyStack,
  Spinner,
  DropZone,
  Banner,
  List,
  Link
} from '@shopify/polaris';

import fileChecksum from 'utils/fileChecksum';
import { trackEvent } from 'utils/Mixpanel';

const FILE_SIZE_LIMIT = 100000000;

function ImportReviewModal({ showToast, active, success, close }) {
  const providers = [
    { label: '--Select a provider--', value: '', disabled: true },
    {label: 'Judge.me', value: 'judge_me'},
    {label: 'Loox', value: 'loox'},
    {label: 'Okendo', value: 'okendo'},
    {label: 'Power Reviews', value: 'power_reviews'},
    {label: 'Reviews.io', value: 'reviews_io'},
    {label: 'Shopify (Product Reviews)', value: 'shopify'},
    {label: 'Stamped', value: 'stamped'},
    {label: 'Yotpo', value: 'yotpo'},
    {label: 'Nexus', value: 'generic'}
  ];

  const [provider, setProvider] = useState('');
  const [loading, setLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [csv, setCsv] = useState(null);
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;

  const handleSelectChange = useCallback((value) => setProvider(value), []);

  const uploadFile = useCallback(async (file) => {
    try {
      const { checksum } = await fileChecksum(file);
      const response = await axios.post('/api/active_storage/direct_uploads', {
        blob: {
          'filename': file.name,
          'content_type': file.type,
          'byte_size': file.size,
          'checksum': checksum
        }
      });

      await axios.put(response.data.direct_upload.url, file.slice(), {
        headers: response.data.direct_upload.headers,
        withCredentials: false
      });

      setCsv(response.data.signed_id);
      setLoading(false);
    } catch(e) {
      trackEvent('Error: Import reviews modal - Upload file');
      showToast('Error uploading file, please try again', true);
      setFiles([]);
      setCsv(null);
      setRejectedFiles([]);
      setLoading(false);
    }
  }, [showToast]);

  const handleAcceptedDrop = useCallback(
    (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];
      if (acceptedFile.size > FILE_SIZE_LIMIT) {
        setRejectedFiles([acceptedFile]);
      } else {
        setFiles([acceptedFile]);
        setRejectedFiles([]);
        setLoading(true);
        uploadFile(acceptedFile);
      }
    },
    [uploadFile]
  );

  const submitCsv = async () => {
    setImportLoading(true);
    try {
      await axios.post('/api/v1/review_imports', {
        review_import: {
          csv,
          provider
        }
      });
      setImportLoading(false);
      success();
      setFiles([]);
      setCsv(null);
      close();
    } catch(e) {
      showToast('Error importing reviews, please try again', true);
      setImportLoading(false);
      trackEvent('Error: Import reviews modal - Import');

    }
  };

  const handleRejectedDrop = useCallback(
    (rejectedFiles) => {
      setRejectedFiles([rejectedFiles[0]]);
      trackEvent('Import reviews modal - File rejected');
    },
    []
  );

  const uploadedFiles = files.length > 0 && (
    <div>{files[0].name}</div>
  );

  const fileUpload = files.length === 0 && <DropZone.FileUpload />;

  const errorMessage = hasError && (
    <Banner
      title="The following files couldn’t be uploaded:"
      tone="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .csv (was ${file.type}) and less than 100MB.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  const importSupport = () => {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      window.open('https://help.junip.co/en/articles/4607108-importing-your-reviews-to-junip', '_blank');
    }
    trackEvent('Import reviews modal - Contact support');
  };

  return (
    <Modal
      open={active}
      onClose={close}
      title="Import reviews"
      primaryAction={{
        content: 'Import',
        disabled: !csv || importLoading || !provider,
        onAction: () => { submitCsv(); trackEvent('Import review modal - Import') },
        loading: importLoading
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => { close(); trackEvent('Import review modal - Cancel') },
          disabled: importLoading
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <Select
            label="Provider"
            options={providers}
            value={provider}
            onChange={handleSelectChange}
            helpText={
              <span>
                Select your review provider. Don't see your provider listed? <Link onClick={importSupport}>Contact support</Link>.
              </span>
            }
          />
          <div>
            <p className="mb-1">Upload CSV file</p>
            <LegacyStack vertical>
              {errorMessage}
              { loading &&
                <div className="dropzone-spinner">
                  <Spinner accessibilityLabel="Uploading csv" size="large" color="primary" />
                </div>
              }
              { !loading &&
                <DropZone
                  accept=".csv, text/csv"
                  type="file"
                  onDropAccepted={handleAcceptedDrop}
                  onDropRejected={handleRejectedDrop}
                  allowMultiple={false}
                >
                  {uploadedFiles}
                  {fileUpload}
                </DropZone>
              }
            </LegacyStack>
          </div>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}


const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(ImportReviewModal);
