import React, { useEffect, useState } from 'react';
import moment from 'moment';
import StarRating from 'components/StarRating';
import { Badge, LegacyStack, Thumbnail, Icon, Tooltip, Text, TextContainer, BlockStack, Tag } from "@shopify/polaris";
import 'styles/components/ReviewItem.scss';
import { PlayIcon, NoteIcon, ChatIcon, DiscountIcon, FlagIcon, ChannelsIcon } from "@shopify/polaris-icons";
import RenderedTraits from 'components/RenderedTraits';

import tiktok from 'assets/images/tiktok.svg';

export default function ReviewItem(props) {
  const { customer, review } = props;
  const {
    body,
    rating,
    title,
    created_at,
    content_moderation_flagged,
    photo_urls,
    video_urls,
    tik_tok_urls,
    verified_buyer,
    featured,
    origin,
    has_notes,
    reward,
    response,
    store,
    target_title,
    tags
  } = review || {};
  const shouldShowAnswers = props.showAnswers || false;

  const syndicated = ['global', 'local'].includes(origin);
  const traitAnswers = review?.survey_answers?.filter(a => a.value_type === 'rating');
  const answers = review?.survey_answers?.filter(a => a.value_type !== 'rating');

  const { first_name, last_name, email } = customer || {};

  let createdAtFormatted = moment(created_at).format('MMMM DD, Y');

  const thumbnailClick = (url, index, type, data) => {
    if (props.thumbnailClick) {
      props.thumbnailClick(url, index, type, data);
    }
  };

  const thumbnailMarkup = photo_urls?.length || video_urls?.length || tik_tok_urls?.length ?
    <div className="thumbnail-container mt-3">
      <div className="mb-2">
        <Text variant="bodyMd" as="span" tone="subdued">Attachments:</Text>
      </div>
      <LegacyStack spacing={props.thumbnailSpacing || 'tight'}>
        {
          video_urls.map((video, index) =>
            <div key={index} className="thumbnail" onClick={() => { thumbnailClick(video.original, index, 'video') }}>
              <div className="video-icon">
                <Icon
                  source={PlayIcon}
                  tone="base"
                />
              </div>
              <Thumbnail
                source={video.thumbnail}
                size={props.thumbnailSize || 'small'}
                alt="review-image-thumbnail"
              />
            </div>
          )
        }
        {
          photo_urls.map((photo, index) =>
            <div key={index} className="thumbnail" onClick={() => {thumbnailClick(photo.large, index, 'image')}}>
              <Thumbnail
                source={photo.thumbnail}
                size={props.thumbnailSize || 'small'}
                alt="review-image-thumbnail"
              />
            </div>
          )
        }
        {
          tik_tok_urls?.map((tik_tok, index) =>
            <div key={index} className="thumbnail" onClick={() => {thumbnailClick(tik_tok.embed_url, index, 'tik_tok', tik_tok)}}>
              <div className={`video-icon tiktok-icon`}>
                <img src={tiktok} alt="tiktok" />
              </div>
              <Thumbnail
                source={tik_tok.cover_photo_thumbnail}
                size={props.thumbnailSize || 'small'}
                alt="review-image-thumbnail"
              />
            </div>
          )
        }
      </LegacyStack>
    </div>
  : null;

  const [tagsToDisplay, setTagsToDisplay] = useState([]);

  useEffect(() => {
    if (!tags) {
      setTagsToDisplay([]);
    } else if (tags.length > 4) {
      setTagsToDisplay(tags.slice(0, 4));
    } else {
      setTagsToDisplay(tags);
    }
  }, [tags]);

  return (
    <div className="review-item-container">
      <StarRating rating={rating} />
      {props.showTitle !== false &&
        <div className="mb-4">
          <BlockStack gap="050">
            <p>
              <Text variant="bodyMd" as="span">{`${first_name || 'No'} ${last_name || 'Name'}`}</Text>
              {email &&
                <Text variant="bodyMd" as="span" tone="subdued">{' '}({email})</Text>
              }
              <Text variant="bodyMd" as="span" tone="subdued">{' '}{verified_buyer ? 'Verified Buyer' : 'Unverified buyer'}</Text>
            </p>
            <Text variant="bodyMd" as="p" tone="subdued">Reviewed {target_title}</Text>
          </BlockStack>
        </div>
      }
      <div className="mb-1">
        <Text variation="strong" variant="headingMd" as="h2">{title || 'Untitled'}</Text>
      </div>
      <div className="review-item-body" >{body || 'No content'}</div>
      <div className="review-tags-container">
        <LegacyStack spacing="tight">
          {
            tagsToDisplay.map((tag, index) => {
              return <Tag key={index}>{tag}</Tag>
            })
          }
          {
            tags &&
            tags.length > 4 &&
            <Text tone="subdued">{`+ ${tags.length - 4}`} more tag{tags.length - 4 > 1 ? 's' : ''}</Text>
          }
        </LegacyStack>
      </div>
      {thumbnailMarkup}
      <div className="review-status-container">
        {props.showTitle !== false &&
          <>
            { syndicated &&
              <Tooltip content={
                  <>
                    <p>
                      This review was syndicated from <Text variant="bodyMd" as="span" fontWeight="semibold">{store?.name}</Text>{' '}
                      <Text variant="bodyMd" as="span" tone="subdued">({store?.url})</Text>
                    </p>
                  </>
                }
              >
                <div className="review-status-icon mr-2">
                  <Icon tone="base" source={ChannelsIcon} />
                </div>
              </Tooltip>
            }
            { content_moderation_flagged &&
              <Tooltip content="This review may contain explicit content">
                <div className="review-status-icon mr-2">
                  <Icon tone="critical" source={FlagIcon} />
                </div>
              </Tooltip>
            }
            { !syndicated && has_notes &&
              <Tooltip content="This review has internal notes">
                <div className="review-status-icon mr-2">
                  <Icon tone="base" source={NoteIcon} />
                </div>
              </Tooltip>
            }
            { response &&
              <Tooltip content="This review has been replied to">
                <div className="review-status-icon mr-2">
                  <Icon tone="base" source={ChatIcon} />
                </div>
              </Tooltip>
            }
            { reward?.id &&
              <Tooltip content="This review was rewarded">
                <div className="review-status-icon mr-2">
              <Icon tone="base" source={DiscountIcon} />
                </div>
              </Tooltip>
            }
            { featured &&
              <Badge tone="info">Featured</Badge>
            }
          </>
        }
        <Text variant="bodyMd" as="div" tone="subdued">{createdAtFormatted}</Text>
      </div>
      {shouldShowAnswers && (
        <>
          {traitAnswers?.length > 0 &&
            <div className="mt-4 mb-4">
              <RenderedTraits traits={traitAnswers} labelAccessor={(t) => t.meta}
              />
            </div>
          }
          {answers?.length > 0 &&
            <div className="mt-4">
              <TextContainer spacing="tight">
                {answers.map((a) =>
                  <p key={a.id}>
                    <Text variant="bodyMd" as="span" fontWeight="semibold">{a.name}</Text>: {a.value}
                  </p>
                )}
              </TextContainer>
            </div>
          }
        </>
      )}
    </div>
  );
}

