import React from 'react';
import ReviewItem from 'components/ReviewItem';

import 'styles/components/ReviewListItem.scss';

export default function ReviewListItem ({ customer, product, review, showAnswers = false}) {
  return (
    <div className="review-list-item">
      <ReviewItem
        customer={customer}
        product={product}
        review={review}
        showAnswers={showAnswers}
      />
    </div>
  );
}
