import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import {
  Page,
  Layout,
  LegacyStack,
  LegacyCard,
  Popover,
  OptionList,
  Button,
  Tabs,
  Badge,
  BlockStack,
  Text,
  InlineStack,
} from "@shopify/polaris";
import {
  StackedAreaChart,
  SimpleBarChart,
  TooltipContent,
  LineChart,
} from '@shopify/polaris-viz';

import PolarisVizProviderWrapper from 'components/PolarisVizProviderWrapper';
import AnalyticsLoadingWrapper from 'components/AnalyticsLoadingWrapper';
import useAnalytics from 'utils/useAnalytics';
import AnalyticsDatePicker from 'components/AnalyticsDatePicker';
import SummaryCard from 'components/SummaryCard';
import ProductFilter from 'components/ProductFilter';

import styles from 'styles/routes/Analytics.module.scss';
import '@shopify/polaris-viz/build/esm/styles.css';
import darkFilledStar from 'assets/images/darkFilledStar.svg';
import filledStar from 'assets/images/filledStar.svg';

export default function Analytics() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('30days');
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(30, 'day').toDate(),
    end: moment().subtract(1, 'day').toDate()
  });
  const handleSelectedDates = useCallback((value) => {
    setLoading(true);
    setSelectedDates(value);
  }, []);
  const [comparisonActive, setComparisonActive] = useState(false);
  const [comparison, setComparison] = useState('previous');
  const [activeTab, setActiveTab] = useState(0);
  const [reviewActive, setReviewActive] = useState(false);
  const [reviewType, setReviewType] = useState('all');
  const [showProductReviewsChart, setshowProductReviewsChart] = useState(true);
  const [showSingleProductChart, setshowSingleProductChart] = useState(true);
  const [productFilter, setProductFilter] = useState(null);

  useEffect(() => {
    if (productFilter) {
      setReviewType('all');
      setshowSingleProductChart(true);
      setshowProductReviewsChart(false);
    } else {
      // reset chart views when product is unselected
      setshowSingleProductChart(true);
      setshowProductReviewsChart(true);
    }
  }, [productFilter]);

  let {
    areaChartData,
    areaChartTooltipData,
    pdReviewSubs,
    pdReviewSubTrend,
    reviewSubs,
    pdMediaReviews,
    pdMediaReviewTrend,
    mediaReviews,
    pdAvgRating,
    pdAvgRatingTrend,
    avgRating,
    barChartData,
    mostReviewedProducts,
    avgRatingChartData,
    orders,
    ordersTrend,
    allTimeOrders,
    subRate,
    submissionRateTrend,
    allTimeSubmissionRate,
  } = useAnalytics({
    setLoading,
    selectedDates,
    activeTab,
    comparison,
    reviewType,
    productFilter,
  });

  const comparisonOptions = [
    { label: 'Compare: Previous period', value: 'previous' },
    { label: 'No comparison', value: 'none' }
  ];

  const toggleComparisonActive = useCallback(
    () => setComparisonActive((comparisonActive) => !comparisonActive),
    []);

  const comparisonActivator = (
    <Button
      onClick={toggleComparisonActive}
      disclosure='down'
      disabled={loading}
    >
      {comparison === 'previous' ? 'Compare: Previous period' : 'No comparison'}
    </Button>
  );

  const reviewOptions = [
    { label: 'All reviews', value: 'all' },
    { label: 'All products', value: 'product' },
    { label: 'Store reviews', value: 'store' }
  ];

  const toggleReviewActive = useCallback(
    () => setReviewActive((reviewActive) => !reviewActive),
    []);

  const reviewActivator = (
    <Button
      onClick={toggleReviewActive}
      disclosure='down'
      disabled={loading || productFilter}
    >
      {reviewType === 'all' ? 'All reviews' : reviewType === 'product' ? 'All products' : 'Store reviews'}
    </Button>
  );

  const handleReviewTypeChange = (value) => {
    setReviewType(value);
    switch (value) {
      case 'all':
        setshowProductReviewsChart(true);
        setshowSingleProductChart(true);
        break;
      case 'product':
        setshowProductReviewsChart(true);
        setshowSingleProductChart(false);
        break;
      default:
        setshowProductReviewsChart(false);
        setshowSingleProductChart(false);
        break;
    }
    toggleReviewActive();
  };

  const tabs = [
    {
      id: 'this-period',
      content: 'This period',
    },
    {
      id: 'all-time',
      content: 'All time',
    }
  ];

  const handleTabChange = useCallback((selectedTabIndex) => {
    setActiveTab(selectedTabIndex);
  }, []);

  const buildAreaChartTooltipData = (value) => {
    let tooltipData = [{
      shape: 'Bar',
      data: value.data[0].data.map((datum) => {
        return {
          key: datum.key,
          value: `(${datum.value})`,
          color: datum.color
        };
      }),
    }];

    tooltipData[0].data.push({
      key: 'Total:',
      value: `${areaChartTooltipData[value.activeIndex].value}`
    });
    return tooltipData;
  };

  const buildDailyAvgTooltipData = (value) => {
    let tooltipData = [{
      shape: 'Bar',
      data: [{
        key: value.dataSeries[0].data[value.activeIndex].key,
        value: value.dataSeries[0].data[value.activeIndex].value.toPrecision(3),
        color: '#637DDB'
      }]
    }];

    if (value.dataSeries[1]) {
      tooltipData[0].data.push({
        key: value.dataSeries[1].data[value.activeIndex].key,
        value: value.dataSeries[1].data[value.activeIndex].value.toPrecision(3),
        color: '#8C9196',
        isComparison: true,
      });
    }

    return tooltipData;
  };

  return (
    <div className={styles.analyticsContainer}>
      <Page title='Analytics' fullWidth>
        <PolarisVizProviderWrapper>
          <Layout>
            <AnalyticsLoadingWrapper loading={loading}>
              <Layout.Section>
                <LegacyStack spacing="tight">
                  <LegacyStack.Item>
                    <AnalyticsDatePicker
                      selectedDates={selectedDates}
                      setSelectedDates={handleSelectedDates}
                      selected={selected}
                      setSelected={setSelected}
                      loading={loading}
                    />
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <Popover
                      active={comparisonActive}
                      activator={comparisonActivator}
                      onClose={toggleComparisonActive}
                      fluidContent
                      preferredAlignment='left'
                    >
                      <OptionList
                        options={comparisonOptions}
                        onChange={(value) => {
                          setComparison(value[0]);
                          toggleComparisonActive();
                        }}
                        selected={comparison}
                      />
                    </Popover>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <Popover
                      active={reviewActive}
                      activator={reviewActivator}
                      onClose={toggleReviewActive}
                      fluidContent
                      preferredAlignment='left'
                    >
                      <OptionList
                        options={reviewOptions}
                        onChange={(value) => {
                          handleReviewTypeChange(value[0]);
                        }}
                        selected={reviewType}
                      />
                    </Popover>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <ProductFilter productFilter={productFilter} setProductFilter={setProductFilter} isFilter={false} />
                  </LegacyStack.Item>
                </LegacyStack>
              </Layout.Section>
              <Layout.Section>
                <LegacyStack distribution='fillEvenly' alignment='fill' spacing="tight">
                  <LegacyStack.Item>
                    <SummaryCard
                      title="Total reviews"
                      currentValue=
                      {
                        <LegacyStack alignment="center">
                          <LegacyStack.Item>
                            <Text variant="headingLg" as="p">
                              {pdReviewSubs}
                            </Text>
                          </LegacyStack.Item>
                        </LegacyStack>
                      }
                      allTime={reviewSubs}
                      trendValue={comparison === 'previous' ? pdReviewSubTrend : 0}
                      emptyState={!pdReviewSubs}
                      tooltipContent='Reviews collected within the selected date range (including imported reviews).'
                    />
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    {(showProductReviewsChart || showSingleProductChart) &&
                      <SummaryCard
                        title="Photo & video reviews"
                        currentValue={
                          <Text variant="headingLg" as="p">
                            {pdMediaReviews}
                          </Text>
                        }
                        allTime={mediaReviews}
                        trendValue={comparison === 'previous' ? pdMediaReviewTrend : 0}
                      />}
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <SummaryCard
                      title="Average star rating"
                      currentValue={
                        <LegacyStack alignment="center">
                          <LegacyStack.Item>
                            <Text variant="headingLg" as="p">
                              {pdAvgRating}
                              <Text variant="bodyMd" as="span" fontWeight="semibold">
                                <span className={styles.starIcon}>
                                  <img src={darkFilledStar} alt="Star icon" height={16} />
                                </span>
                              </Text>
                            </Text>
                          </LegacyStack.Item>
                        </LegacyStack>
                      }
                      allTime={avgRating}
                      trendValue={comparison === 'previous' ? pdAvgRatingTrend : 0}
                      emptyState={!pdAvgRating}
                    />
                  </LegacyStack.Item>
                  {(!showSingleProductChart || showProductReviewsChart) &&
                    <LegacyStack.Item>
                      <SummaryCard
                        title="Orders requested"
                        currentValue={
                          <Text variant="headingLg" as="p">
                            {orders}
                          </Text>
                        }
                        allTime={allTimeOrders}
                        trendValue={comparison === 'previous' ? ordersTrend : 0}
                        tooltipContent='The number of orders for which a review was requested.'
                      />
                    </LegacyStack.Item>}
                  {(!showSingleProductChart || showProductReviewsChart) &&
                    <LegacyStack.Item>
                      <SummaryCard
                        title="Submission rate"
                        currentValue={
                          <Text variant="headingLg" as="p">
                            {subRate}
                            <Text variant="bodyMd" as="span" fontWeight="semibold">
                              {' '}%
                            </Text>
                          </Text>
                        }
                        allTime={allTimeSubmissionRate}
                        trendValue={comparison === 'previous' ? submissionRateTrend : 0}
                        showPercentage={true}
                        tooltipContent="An approximation based on your “orders requested” metric vs reviews submitted on Junip."
                      />
                    </LegacyStack.Item>}
                </LegacyStack>
              </Layout.Section>
              {areaChartData &&
                <Layout.Section variant="fullWidth">
                  <LegacyCard
                    title={<LegacyStack>
                      <LegacyStack.Item fill>
                        <Text variant="headingMd" as="h2">Total reviews</Text>
                      </LegacyStack.Item>
                    </LegacyStack>}>
                    <LegacyCard.Section>
                      <LegacyStack alignment='baseline' spacing='baseTight'>
                        <LegacyStack.Item>
                          <Text variant="headingLg" as="p">{pdReviewSubs}</Text>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                          <Text variant="bodyMd" tone="subdued">
                            Submitted between {moment(selectedDates.start).format('MMM D YYYY')} and {moment(selectedDates.end).format('MMM D YYYY')}
                          </Text>
                        </LegacyStack.Item>
                      </LegacyStack>
                    </LegacyCard.Section>
                    <LegacyCard.Section>
                      <div style={{
                        height: '400px',
                      }}>
                        <StackedAreaChart
                          data={areaChartData}
                          tooltipOptions={{
                            renderTooltipContent: (value) => {
                              return (
                                <TooltipContent
                                  title={value.title}
                                  data={buildAreaChartTooltipData(value)}
                                />
                              );
                            }
                          }}
                          state={loading ? 'Loading' : 'Success'}
                        />
                      </div>
                    </LegacyCard.Section>
                  </LegacyCard>
                </Layout.Section>
              }
              {barChartData &&
                <Layout.Section variant="oneHalf">
                  <LegacyCard title='Star rating breakdown' sectioned>
                    {barChartData.length === 0 ?
                      <div>
                        <div className={styles.emptyCard} style={{ height: '300px' }}>
                          <Text variant="bodyMd" as="span" tone="subdued">No data to display for selected timeframe</Text>
                        </div>
                      </div>
                      :
                      <div style={{ height: '300px' }}>
                        <SimpleBarChart
                          data={barChartData}
                          state={loading ? 'Loading' : 'Success'}
                          showLegend={false}
                          xAxisOptions={{
                            labelFormatter: (value) => {
                              return `${(value).toPrecision(3)}%`;
                            }
                          }}
                        />
                      </div>
                    }
                  </LegacyCard>
                </Layout.Section>
              }
              {showProductReviewsChart &&
                mostReviewedProducts &&
                <Layout.Section variant="oneHalf">
                  <LegacyCard title='Most reviewed products'>
                    <div style={{ height: '324px' }}>
                      <Tabs
                        tabs={tabs}
                        selected={activeTab}
                        onSelect={handleTabChange}
                      >
                        <div className={styles.mostReviewedProducts}>
                          {(activeTab === 0 && mostReviewedProducts?.length !== 0) &&
                            (<LegacyCard.Section>
                              <Text variant="bodyMd" as="span" tone="subdued">
                                Star ratings are based on the reviews collected within the selected timeframe
                              </Text>
                            </LegacyCard.Section>)}
                        </div>
                        {mostReviewedProducts?.length === 0 ?
                          <LegacyCard.Section>
                            <div className={styles.emptyCard} style={{ height: '300px' }}>
                              <Text variant="bodyMd" as="span" tone="subdued">No data to display for selected timeframe</Text>
                            </div>
                          </LegacyCard.Section>
                          :
                          <BlockStack gap='200' align='start'>
                            <LegacyCard.Section subdued>
                              <div style={{ height: '300px' }}>
                                <BlockStack gap='600'>
                                  {mostReviewedProducts.map((product, index) => {
                                    return (
                                      <LegacyStack>
                                        <LegacyStack.Item>
                                          <p className={styles.mediumText}>{`${index + 1}.`}</p>
                                        </LegacyStack.Item>
                                        <LegacyStack.Item fill>
                                          <LegacyStack spacing='tight'>
                                            <Text tone={product.deletedAt ? 'subdued' : undefined}>
                                              {product.name}
                                            </Text>
                                            {product.deletedAt && <Badge tone="critical">Deleted</Badge>}
                                          </LegacyStack>
                                        </LegacyStack.Item>
                                        <LegacyStack.Item>
                                          <InlineStack gap='500' blockAlign='baseline'>
                                            <Text variant="bodyMd" as="span" fontWeight="semibold">{product.reviews}</Text>
                                            <InlineStack gap='200' blockAlign='baseline'>
                                              <Text variant="bodyMd" as="span" tone="subdued">{product.ratingAvg.toPrecision(3)}</Text>
                                              <img src={filledStar} alt="Star icon" height={12} />
                                            </InlineStack>
                                          </InlineStack>
                                        </LegacyStack.Item>
                                      </LegacyStack>
                                    )
                                  })}
                                </BlockStack>
                              </div>
                            </LegacyCard.Section>
                          </BlockStack>
                        }
                      </Tabs>
                    </div>
                  </LegacyCard>
                </Layout.Section>
              }
              {avgRatingChartData &&
                <Layout.Section>
                  <LegacyCard
                    title={<LegacyStack>
                      <LegacyStack.Item fill>
                        <Text variant="headingMd" as="h2">Average star rating</Text>
                      </LegacyStack.Item>
                    </LegacyStack>}
                  >
                    <LegacyCard.Section>
                      <div style={{ height: showProductReviewsChart ? '366px' : '417px' }} className="mt-3">
                        <LineChart
                          data={avgRatingChartData}
                          state={loading ? 'Loading' : 'Success'}
                          tooltipOptions={{
                            renderTooltipContent: (value) => {
                              return (
                                <TooltipContent
                                  data={buildDailyAvgTooltipData(value)}
                                />
                              );
                            }
                          }}
                          xAxisOptions={{
                            labelFormatter: (value) => {
                              const period = moment(selectedDates.end).utc().diff(moment(selectedDates.start).utc(), 'day') + 1;
                              return period > 90 ? value : moment(value).format('MMM D');
                            },
                            allowLineWrap: false,
                          }}
                          yAxisOptions={{
                            integersOnly: true,
                          }}
                        />
                      </div>
                    </LegacyCard.Section>
                  </LegacyCard>
                </Layout.Section>
              }
            </AnalyticsLoadingWrapper>
          </Layout>
        </PolarisVizProviderWrapper>
      </Page>
    </div>
  );
}
