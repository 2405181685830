import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner, BlockStack, Button, Checkbox, Link, Modal, Text } from "@shopify/polaris";
import { saveDisplaySettings } from "redux/displaySettings";
import WidgetCardWrapper from "./WidgetCardWrapper";

import starRatingWidget from 'assets/images/widgets/star-rating-widget.svg';

export default function StarRatingWidgetWrapper({ children }) {
  const displaySettings = useSelector((state) => state.displaySettings);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [reviewCountEnabled, setReviewCountEnabled] = useState(false);

  useEffect(() => {
    if (displaySettings?.data && modalOpen) {
      setReviewCountEnabled(displaySettings?.data?.review_count_enabled);
    }
  }, [modalOpen, displaySettings?.data]);

  const saveSettings = async () => {
    setSaving(true);
    // Save the display settings, I believe we can actually use await here because of how saveDisplaySettings is implemented
    await dispatch(saveDisplaySettings(displaySettings?.data?.id, { review_count_enabled: reviewCountEnabled }));
    setSaving(false);
    setModalOpen(false);
  };

  const storeUrl = () => {
    let url = displaySettings?.data?.store?.url;
    if (url && url.indexOf('http') === -1) {
      url = `https://${url}`;
    }
    return `${url}?junip-break-cache=true`;
  };

  return (
    <>
      <WidgetCardWrapper
        image={starRatingWidget}
        imageAlt="Star ratings widget"
        title="Star ratings"
        action={
          <Button
            variant="plain"
            onClick={() => setModalOpen(true)}
          >
            Customize
          </Button>
        }
        description="Display a product's average star rating."
        children={children}
      />
      <Modal
        open={modalOpen}
        onClose={!saving ? () => setModalOpen(false) : undefined}
        title="Customize star ratings"
        primaryAction={{
          content: "Save",
          onAction: saveSettings,
          loading: saving,
          disabled: saving || reviewCountEnabled === displaySettings?.data?.review_count_enabled,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setModalOpen(false);
            },
            disabled: saving,
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap="400">
            <Banner tone="info">
              To edit colors and styling, visit your <Link url="/settings/branding">branding settings</Link>.
            </Banner>
            <Checkbox
              label="Show number of ratings"
              checked={reviewCountEnabled}
              onChange={setReviewCountEnabled}
              helpText="Displays number of ratings next to the stars, like this: “⭐ ⭐ ⭐ ⭐ ⭐ (124)”️"
            />
          </BlockStack>
        </Modal.Section>
        <Modal.Section>
          <Text variant="bodySm" tone="subdued">
            To optimize performance, Junip caches your settings across a global CDN. This means it may take a few minutes to see changes.{' '}
            To view changes right away, visit your store using this{' '}
            <Link
              url={storeUrl()}
              target="_blank"
            >
              link
            </Link>.
          </Text>
        </Modal.Section>
      </Modal>
    </>
  );
}
