import React, { useState, useCallback } from 'react';
import {
  LegacyCard,
  Select,
  Form,
  FormLayout,
  Button,
} from '@shopify/polaris';

export default function SelectStore({ working, selectStore, stores }) {
  const options = stores.map(store => {
    return { label: store.name, value: store.id };
  });

  const [selected, setSelected] = useState(options[0].value);
  const handleSelectChange = useCallback((value) => setSelected(parseInt(value)), []);

  const handleSubmit = () => {
    selectStore(selected);
  };

  return (
    <LegacyCard sectioned title="Select a store">
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
          <Button size="large" submit loading={working} variant="primary">Select</Button>
        </FormLayout>
      </Form>
    </LegacyCard>
  );
}
