import React, { useState } from 'react';
import axios from 'axios';

import { LegacyCard, Form, FormLayout, TextField, ButtonGroup, Button, Link, Text } from "@shopify/polaris";

import ReCAPTCHA from 'react-google-recaptcha';

export default function CreateAccount({ working, selectUser, showToast, push }) {
  const queryParams = window.location.search || '';
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [invalidPasswordError, setInvalidPasswordError] = useState(null);
  const [emailTakenError, setEmailTakeError] = useState(null);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const googleCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

  const isValid = () => {
    if (!inputs.firstName || !inputs.email || !inputs.password) {
      return false;
    }

    if (!inputs.email.includes('@')) {
      return false;
    }

    if (invalidPasswordError && (inputs.password.length >= 8 && inputs.password.length <= 72)) {
      setInvalidPasswordError(null);
    }

    if (!captchaCompleted) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!isValid()) {
      return;
    }

    if (inputs.password.length < 8 || inputs.password.length > 72) {
      setInvalidPasswordError('Invalid password length');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        '/api/v1/users',
        {
          user: {
            first_name: inputs.firstName,
            last_name: inputs.lastName,
            email: inputs.email,
            password: inputs.password,
            password_confirmation: inputs.password,
            accepts_marketing: true
          },
          recaptcha_token: captchaToken
        }
      );
      selectUser(response.data.user);
    } catch (e) {
      let message = 'Error creating account, please check your email and password and try again';
      if (e?.response?.status === 400 && e.response?.data?.errors) {
        message = e.response.data.errors?.[0]?.detail || message;
        if (message === 'This email has already been taken') {
          setEmailTakeError(true);
        }
        showToast(message, true);
      } else if (e?.response?.status === 403) {
        message = 'Error creating account, failed captcha verification. Please try again';
        showToast(message, true)
      } else {
        showToast(message, true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field) => {
    return (value) => setInputs(inputs => ({ ...inputs, [field]: value }));;
  };

  const redirectToLogin = () => {
    push(`/oauth/sign_in${queryParams}`, { email: inputs.email });
  };

  const onCaptchaChange = (value) => {
    if (value === null) {
      setCaptchaCompleted(false);
      return;
    }

    setCaptchaToken(value);
    setCaptchaCompleted(true);
  }

  const emailAlreadyTakenError = emailTakenError ? <p>
    An account already exists with this email. <Link onClick={redirectToLogin}>Sign in</Link> with that account
  </p>
    : null;

  return (
    <LegacyCard sectioned>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <TextField
            value={inputs.firstName}
            name="firstName"
            onChange={handleInputChange('firstName')}
            label="First name"
            type="text"
            placeholder="First name"
            autoFocus={true}
            autoComplete="given-name"
            disabled={isLoading}
          />
          <TextField
            value={inputs.lastName}
            name="lastName"
            onChange={handleInputChange('lastName')}
            label="Last name"
            type="text"
            placeholder="Last name"
            autoComplete="family-name"
            disabled={isLoading}
          />
          <TextField
            value={inputs.email}
            name="email"
            onChange={(value) => { handleInputChange('email')(value); if (emailTakenError) { setEmailTakeError(false); } }}
            label="Email"
            type="email"
            placeholder="Email"
            autoComplete="email"
            error={emailAlreadyTakenError}
            disabled={isLoading}
          />
          <TextField
            value={inputs.password}
            name="password"
            onChange={handleInputChange('password')}
            label="Password"
            type="password"
            placeholder="Password"
            helpText="Password must be at least 8 characters long"
            error={invalidPasswordError}
            minLength="8"
            maxLength="72"
            autoComplete="new-password"
            disabled={isLoading}
          />
          <ReCAPTCHA
            sitekey={googleCaptchaSiteKey}
            onChange={onCaptchaChange}
            theme={window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'}
          />
          <ButtonGroup>
            <Button
              disabled={!isValid()}
              size="large"
              submit
              loading={isLoading || working}
              variant="primary">Create account</Button>
            <Button
              disabled={isLoading || working}
              url={`/oauth/sign_in${queryParams}`}
              variant="plain">Log in</Button>
          </ButtonGroup>
          <Text variant="bodyMd" as="span" tone="subdued">Try Junip for free, no credit card required. By entering your email, you agree to receive marketing emails from Junip.</Text>
        </FormLayout>
      </Form>
    </LegacyCard>
  );
}
