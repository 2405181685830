import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { fetchStoreSyncs, updateStoreSync } from 'redux/storeSyncs';
import {
  Banner,
  TextContainer,
  ProgressBar,
  Button,
  Collapsible,
  List
} from '@shopify/polaris';
import useInterval from 'utils/useInterval';

const DEFAULT_POLLING_DELAY = 5000;
const TIME_RANGE = 5 * 60 * 1000; // 5 minutes

function StoreSyncBanner({ storeSyncs, fetchStoreSyncs, updateStoreSync }) {
  const [pendingSync, setPendingSync] = useState(null);
  const [syncing, setSyncing] = useState(false);
  const [delay, setDelay] = useState(null);
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen((open) => !open), []);

  useEffect(() => {
    return () => {
      setDelay(null);
    };
  }, []);

  useEffect(() => {
    if (!storeSyncs.data) {
      // Delay this until the last homepage call
      setTimeout(() => {
        fetchStoreSyncs();
      }, 0);
    } else {
      if (storeSyncs.data?.length > 1) {
        return;
      }
      const firstPendingSync = storeSyncs.data.find(i => !i.completed_at);
      if (firstPendingSync) {
        setPendingSync(firstPendingSync);
      } else {
        const recentlyActive = storeSyncs.data.find(i => (Date.now() - Date.parse(i.updated_at)) < TIME_RANGE);
        if (recentlyActive) {
          setPendingSync(recentlyActive);
        }
      }
    }
  }, [storeSyncs.data, fetchStoreSyncs]);

  useEffect(() => {
    if (pendingSync) {
      if (!pendingSync.completed_at) {
        setDelay(DEFAULT_POLLING_DELAY);
        setSyncing(true);
      } else {
        setDelay(null);
        setSyncing(false);
      }
    }
  }, [pendingSync]);

  useInterval(() => {
    if (pendingSync) {
      fetchData(pendingSync.id);
    }
  }, delay);

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`/api/v1/store_syncs/${id}`, {
        params: {
          include: 'progress_tracker'
        }
      });
      const storeSync = response.data.store_sync;
      setPendingSync(storeSync);
      updateStoreSync(storeSync);
    } catch(e) {
      // No-op
    }
  };

  return <>
    { pendingSync &&
      <div className="mb-4">
        <Banner
          title={ syncing ? pendingSync?.progress_tracker?.name || 'Syncing store...' : 'Store sync successful' }
          tone={ syncing ? 'info' : 'default' }
          onDismiss={() => setPendingSync(null)}
        >
          <TextContainer spacing="tight">
            {pendingSync?.progress_tracker?.current_step_description ?
              <p>{pendingSync?.progress_tracker.current_step_description}</p>
              :
              <p>
                {syncing ?
                  'Junip is currently syncing your store information. Some functionality such as campaigns or product grouping may not work as expected while this is happening.'
                :
                  'Thanks for connecting your store! Junip has successfully synced your store information'
                }
              </p>
            }
            {syncing &&
              <div>
                <ProgressBar
                  size="small"
                  progress={
                    ((pendingSync?.progress_tracker?.steps_completed / pendingSync?.progress_tracker?.total_steps) * 100) || 0
                  }
                />
              </div>
            }
            <Button


              disclosure={ open ? 'up' : 'down' }
              onClick={handleToggle}
              ariaExpanded={open}
              ariaControls="details-collapsible"
              variant="monochromePlain">
              View details
            </Button>
            <Collapsible
              open={open}
              id="details-collapsible"
              expandOnPrint
            >
              <List>
                <List.Item>{pendingSync?.orders_pulled || 0} orders synced</List.Item>
                <List.Item>{pendingSync?.products_pulled || 0} products synced</List.Item>
                <List.Item>{pendingSync?.customers_pulled || 0} customers synced</List.Item>
              </List>
            </Collapsible>
          </TextContainer>
        </Banner>
      </div>
    }
  </>;
}

const mapStateToProps = (state) => ({
  storeSyncs: state.storeSyncs,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStoreSyncs: () => dispatch(fetchStoreSyncs()),
  updateStoreSync: (storeSync) => dispatch(updateStoreSync(storeSync))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreSyncBanner);
