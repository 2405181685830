import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import Router from 'utils/Router';
import AuthorizedRoute from 'utils/AuthorizedRoute';
import Frame from 'components/Frame';
import ScrollToTop from 'utils/ScrollToTop';
import Mantle from 'utils/Mantle';
import Mixpanel from 'utils/Mixpanel';
import CheckVersion from 'utils/CheckVersion';

import AcceptInvitation from 'routes/accept_invitation/AcceptInvitation';
import Oauth from 'routes/oauth/Oauth';
import Create from 'routes/login/Create';
import UserInvitations from 'routes/login/UserInvitations';
import CreateOrganization from 'routes/login/CreateOrganization';
import ConnectStore from 'routes/login/ConnectStore';
import ForgotPassword from 'routes/login/ForgotPassword';
import Login from 'routes/login/Login';
import Reset from 'routes/login/Reset';
import SelectOrganization from 'routes/login/SelectOrganization';
import SelectStore from 'routes/login/SelectStore';
import FullOnboarding from 'routes/onboarding/FullOnboarding';
import Home from 'routes/home/Home';
import Reviews from 'routes/reviews/Reviews';
import Review from 'routes/reviews/Review';
import Products from 'routes/products/Products';
import ProductCatalogHealth from 'routes/products/ProductCatalogHealth';
import Product from 'routes/products/Product';
import Groups from 'routes/products/groups/Groups';
import CreateGroup from 'routes/products/groups/Create';
import EditGroup from 'routes/products/groups/Group';
import Flows from 'routes/messages/Flows';
import Flow from 'routes/messages/Flow';
import Templates from 'routes/messages/Templates';
import Template from 'routes/messages/Template';
import Campaigns from 'routes/messages/Campaigns';
import Outbox from 'routes/messages/Outbox';
import Incentives from 'routes/incentives/Incentives';
import Questions from 'routes/forms/Questions';
import Question from 'routes/forms/questions/Question';
import NewQuestion from 'routes/forms/questions/New';
import CreateQuestion from 'routes/forms/questions/Create';
import Preferences from 'routes/forms/Preferences';
import ReviewLinks from 'routes/forms/ReviewLinks';
import OnSiteV2 from 'routes/on_site/OnSiteV2';
import OnSiteSettings from 'routes/settings/OnSiteSettings';
import BrandingSettings from 'routes/settings/BrandingSettings';
import Integrations from 'routes/integrations/Integrations';
import Integration from 'routes/integrations/Integration';
import Custom from 'routes/integrations/Custom';
import CustomApp from 'routes/integrations/custom/CustomApp';
import Settings from 'routes/settings/Settings';
import UserSettings from 'routes/settings/UserSettings';
import TeamSettings from 'routes/settings/TeamSettings';
import ModerationSettings from 'routes/settings/ModerationSettings';
import BillingSettings from 'routes/settings/BillingSettings';
import StoreSettings from 'routes/settings/StoreSettings';
import NotificationSettings from 'routes/settings/NotificationSettings';
import ReferralSettings from 'routes/settings/ReferralSettings';
import TransactionalFlowSettings from 'routes/settings/TransactionalFlowSettings';
import Syndication from 'routes/syndication/Syndication';
import GoogleShopping from 'routes/syndication/GoogleShopping';
import Meta from 'routes/syndication/Meta';
import MultiStore from 'routes/syndication/MultiStore';
import Analytics from 'routes/analytics/Analytics';
import Import from 'routes/imports/Import';
import Confirm from 'routes/imports/Confirm';
import Transfers from 'routes/imports/Transfers';
import Reports from 'routes/slice/Reports';
import ReportsRatingsCollectedOverTime from 'routes/slice/ReportsRatingsCollectedOverTime';
import ReportsRatingByProduct from 'routes/slice/ratingsByProduct/ReportsRatingsByProduct';
import ReportsSubmissionRateOverTime from 'routes/slice/ReportsSubmissionRateOverTime';
import TagManager from 'components/TagManager';
import TwoPointOh from 'routes/two_point_oh/TwoPointOh';

// TODO use React lazy and suspense to lazy load routes
// https://reactjs.org/docs/code-splitting.html

function App({ toggleColorScheme, colorScheme }) {
  return (
    <Router>
      <CheckVersion />
      <ScrollToTop />
      <Mixpanel />
      <Mantle />
      <TagManager />
      <Frame toggleColorScheme={toggleColorScheme} colorScheme={colorScheme}>
        <Switch>
          <Route path="/accept_invitation" component={AcceptInvitation} />
          <AuthorizedRoute path="/oauth" component={Oauth} />
          <AuthorizedRoute path="/onboarding" component={FullOnboarding} />
          <AuthorizedRoute exact path="/login" component={Login} />
          <AuthorizedRoute exact path="/login/create" component={Create} />
          <AuthorizedRoute exact path="/login/invitations" component={UserInvitations} />
          <AuthorizedRoute exact path="/login/create/organization" component={CreateOrganization} />
          <AuthorizedRoute exact path="/login/create/:id" component={Create} />
          <AuthorizedRoute exact path="/login/forgot_password" component={ForgotPassword} />
          <AuthorizedRoute exact path="/login/reset" component={Reset} />
          <AuthorizedRoute exact path="/login/connect_store" component={ConnectStore} />
          <AuthorizedRoute exact path="/login/select/organization" component={SelectOrganization} />
          <AuthorizedRoute exact path="/login/select/store" component={SelectStore} />
          <AuthorizedRoute exact path="/home" component={Home} />
          <AuthorizedRoute exact path="/analytics" component={Analytics} />
          <AuthorizedRoute exact path="/analytics/reports" component={Reports} />
          <AuthorizedRoute exact path="/analytics/reports/ratings-collected-over-time" component={ReportsRatingsCollectedOverTime} />
          <AuthorizedRoute exact path="/analytics/reports/ratings-by-product" component={ReportsRatingByProduct} />
          <AuthorizedRoute exact path="/analytics/reports/submission-rate-over-time" component={ReportsSubmissionRateOverTime} />
          <AuthorizedRoute exact path="/imports" component={Import} />
          <AuthorizedRoute exact path="/imports/confirm" component={Confirm} />
          <AuthorizedRoute exact path="/imports/transfers" component={Transfers} />
          <AuthorizedRoute exact path="/reviews" component={Reviews} />
          <AuthorizedRoute exact path="/reviews/:id" component={Review} />
          <AuthorizedRoute exact path="/products" component={Products} />
          <AuthorizedRoute exact path="/products/catalog-health" component={ProductCatalogHealth} />
          <AuthorizedRoute exact path="/products/groups" component={Groups} />
          <AuthorizedRoute exact path="/products/groups/create" component={CreateGroup} />
          <AuthorizedRoute exact path="/products/groups/:id" component={EditGroup} />
          <AuthorizedRoute exact path="/products/:id" component={Product} />
          <AuthorizedRoute exact path="/flows" component={Flows} />
          <AuthorizedRoute exact path="/flows/campaigns" component={Campaigns} />
          <AuthorizedRoute exact path="/flows/outbox" component={Outbox} />
          <AuthorizedRoute exact path="/flows/templates" component={Templates} />
          <AuthorizedRoute exact path="/flows/templates/:id" component={Template} />
          <AuthorizedRoute exact path="/flows/:id" component={Flow} />
          <AuthorizedRoute exact path="/incentives" component={Incentives} />
          <AuthorizedRoute exact path="/forms" component={ReviewLinks} />
          <AuthorizedRoute exact path="/forms/questions" component={Questions} />
          <AuthorizedRoute exact path="/forms/questions/new" component={NewQuestion} />
          <AuthorizedRoute exact path="/forms/questions/create" component={CreateQuestion} />
          <AuthorizedRoute exact path="/forms/questions/:id" component={Question} />
          <AuthorizedRoute exact path="/forms/preferences" component={Preferences} />
          <AuthorizedRoute exact path="/on_site" component={OnSiteV2} />
          <AuthorizedRoute exact path="/integrations" component={Integrations} />
          <AuthorizedRoute exact path="/integrations/custom" component={Custom} />
          <AuthorizedRoute exact path="/integrations/custom/:slug" component={CustomApp} />
          <AuthorizedRoute exact path="/integrations/:slug" component={Integration} />
          <AuthorizedRoute exact path="/settings" component={Settings} />
          <AuthorizedRoute exact path="/settings/branding" component={BrandingSettings} />
          <AuthorizedRoute exact path="/settings/user" component={UserSettings} />
          <AuthorizedRoute exact path="/settings/notifications" component={NotificationSettings} />
          <AuthorizedRoute exact path="/settings/team" component={TeamSettings} />
          <AuthorizedRoute exact path="/settings/transactional-flows" component={TransactionalFlowSettings} />
          <AuthorizedRoute exact path="/settings/transactional-flows/:id" component={Flow} />
          <AuthorizedRoute exact path="/settings/moderation" component={ModerationSettings} />
          <AuthorizedRoute exact path="/settings/billing" component={BillingSettings} />
          <AuthorizedRoute exact path="/settings/billing/:id" component={BillingSettings} />
          <AuthorizedRoute exact path="/settings/store" component={StoreSettings} />
          <AuthorizedRoute exact path="/settings/referral" component={ReferralSettings} />
          <AuthorizedRoute exact path="/settings/on_site" component={OnSiteSettings} />
          <AuthorizedRoute exact path="/syndication" component={Syndication} />
          <AuthorizedRoute exact path="/syndication/google-shopping" component={GoogleShopping} />
          <AuthorizedRoute exact path="/syndication/meta" component={Meta} />
          <AuthorizedRoute exact path="/syndication/multi-store" component={MultiStore} />
          <AuthorizedRoute exact path="/2.0" component={TwoPointOh} />

          {/* Redirects */}
          <AuthorizedRoute exact path="/branding">
            <Redirect to="/settings/branding" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/settings/google_shopping">
            <Redirect to="/syndication/google-shopping" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/messages/flows">
            <Redirect to="/flows" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/messages/flows/campaigns">
            <Redirect to="/flows/campaigns" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/messages/flows/:id">
            <Redirect to="/flows/:id" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/messages/templates">
            <Redirect to="/flows/templates" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/messages/templates/:id">
            <Redirect to="/flows/templates/:id" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/messages/outbox">
            <Redirect to="/flows/outbox" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/settings/product_catalog">
            <Redirect to="/products/catalog-health" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/settings/syndication">
            <Redirect to="/syndication" />
          </AuthorizedRoute>
          <AuthorizedRoute exact path="/forms/review_links">
            <Redirect to="/forms" />
          </AuthorizedRoute>
          <Redirect to="/home" />
        </Switch>
      </Frame>
    </Router>
  );
}

export default App;
