import axios from 'axios';

// Actions
const FETCH_STORE_SYNCS_BEGIN = 'FETCH_STORE_SYNCS_BEGIN';
const FETCH_STORE_SYNCS_SUCCESS = 'FETCH_STORE_SYNCS_SUCCESS';
const FETCH_STORE_SYNCS_ERROR = 'FETCH_STORE_SYNCS_ERROR';
const UPDATE_STORE_SYNC = 'UPDATE_STORE_SYNC';

// Action Creators
export function fetchStoreSyncs() {
  return async dispatch => {
    dispatch(fetchStoreSyncsBegin());
    try {
      const response = await axios.get('/api/v1/store_syncs?include=progress_tracker');
      dispatch(fetchStoreSyncsSuccess(response.data.store_syncs));
    } catch (e) {
      dispatch(fetchStoreSyncsError(e));
    }
  }
}

export const updateStoreSync = (data) => ({
  type: UPDATE_STORE_SYNC,
  data,
});

export const fetchStoreSyncsBegin = () => ({
  type: FETCH_STORE_SYNCS_BEGIN
});

export const fetchStoreSyncsSuccess = (data) => ({
  type: FETCH_STORE_SYNCS_SUCCESS,
  data
});

export const fetchStoreSyncsError = (error) => ({
  type: FETCH_STORE_SYNCS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_STORE_SYNCS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_STORE_SYNCS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case UPDATE_STORE_SYNC:
      const index = state.data.findIndex(b => b.id === action.data.id);
      state.data[index] = action.data;
      return {
        ...state,
        loading: false,
        error: null
      };
    case FETCH_STORE_SYNCS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
