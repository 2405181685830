import React from 'react';
import {
  TextContainer,
  SkeletonDisplayText,
  SkeletonBodyText,
  LegacyCard
} from '@shopify/polaris';

export default function LoadingWrapper({ loading, children, sections = 2, cardSectionWrapper = false }) {
  if (loading) {
    const elements = (
      <TextContainer>
        {
          [...Array(sections)].map((e, i) =>
            <React.Fragment key={i}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </React.Fragment>
          )
        }
      </TextContainer>
    );
    if (cardSectionWrapper) {
      return (
        <LegacyCard.Section>
          {elements}
        </LegacyCard.Section>
      );
    }

    return (elements);
  } else {
    return (
      <>
        {children}
      </>
    );
  }
}
