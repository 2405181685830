import React, { useState, useEffect } from 'react';
import useInterval from 'utils/useInterval';
import moment from 'moment';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  Button,
  Layout,
  Page,
  TextContainer,
  Modal,
  Banner,
  LegacyCard,
  LegacyStack,
  Icon,
  Link,
  Collapsible,
  List,
  Text,
  FooterHelp,
} from "@shopify/polaris";
import { fetchProductVariantsAggregate } from 'redux/productVariants';
import { fetchProductIdentifierImports } from 'redux/productIdentifierImports';
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import ProductIdentifierImportDropZone from 'components/ProductIdentifierImportDropZone';
import { XCircleIcon, InfoIcon } from "@shopify/polaris-icons";

const DEFAULT_POLLING_DELAY = 3000;
const TIME_RANGE = 10 * 60 * 1000; // 10 min

function ProductCatalogHealth({
  showToast,
  fetchProductVariantsAggregate,
  productVariantsAggregate,
  fetchProductIdentifierImports,
  productIdentifierImports
}) {
  const [pendingExport, setPendingExport] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [startingExport, setStartingExport] = useState(false);
  const [exportDelay, setExportDelay] = useState(null);
  const [exportsLoading, setExportsLoading] = useState(true);
  const [pendingImport, setPendingImport] = useState(null);
  const [importing, setImporting] = useState(false);
  const [importDelay, setImportDelay] = useState(null);
  const [importsLoading, setImportsLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const [showDownloadBanner, setShowDownloadBanner] = useState(false);

  useEffect(() => {
    return () => {
      setExportDelay(null);
      setImportDelay(null);
    };
  }, []);

  useEffect(() => {
    fetchExportData();
    fetchImportData();
  }, []);

  useEffect(() => {
    fetchProductVariantsAggregate();
    fetchProductIdentifierImports();
  }, [fetchProductVariantsAggregate, fetchProductIdentifierImports]);

  useEffect(() => {
    const latestImport = productIdentifierImports.data?.product_identifier_imports?.[0] || null;
    if (latestImport) {
      const latestImportDate = latestImport.updated_at;
      const parsedDate = moment(latestImportDate).format('MM/D/YY [at] h:mma');
      setLastUpdated(parsedDate);
    }
  }, [productIdentifierImports]);

  useEffect(() => {
    if (pendingExport) {
      if (!pendingExport.download_url) {
        setExportDelay(DEFAULT_POLLING_DELAY);
        setExporting(true);
      } else {
        setExportDelay(null);
        setExporting(false);
      }
    }
  }, [pendingExport]);

  useEffect(() => {
    if (pendingImport) {
      if (pendingImport.state === 'pending') {
        setImportDelay(DEFAULT_POLLING_DELAY);
        setImporting(true);
      } else {
        setImportDelay(null);
        setImporting(false);
      }
    }
  }, [pendingImport]);

  useInterval(() => {
    if (pendingExport) {
      fetchExportData(pendingExport.id);
    }
  }, exportDelay);

  useInterval(() => {
    if (pendingImport) {
      fetchImportData(pendingImport.id);
    }
  }, importDelay);

  const fetchExportData = async (id) => {
    let url = '/api/v1/product_identifier_exports';
    if (id) {
      url = `${url}/${id}`;
    } else {
      setExportsLoading(true);
    }
    try {
      const response = await axios.get(url);
      if (id) {
        setPendingExport(response.data.product_identifier_export);
      } else {
        const firstPendingExport = response.data?.product_identifier_exports?.[0];
        if (firstPendingExport) {
          if (firstPendingExport.download_url) {
            if ((Date.now() - Date.parse(firstPendingExport.updated_at) < TIME_RANGE)) {
              setPendingExport(firstPendingExport);
            }
          } else {
            setPendingExport(firstPendingExport);
          }
        }
      }
    } catch (e) {
      // TODO
    } finally {
      setExportsLoading(false);
    }
  };

  const fetchImportData = async (id) => {
    let url = '/api/v1/product_identifier_imports';
    if (id) {
      url = `${url}/${id}`;
    } else {
      setImportsLoading(true);
    }
    try {
      const response = await axios.get(url);
      if (id) {
        setPendingImport(response.data.product_identifier_import);
      } else {
        const firstPendingImport = response.data?.product_identifier_imports?.[0];
        if (firstPendingImport) {
          if (firstPendingImport.state === 'completed') {
            if ((Date.now() - Date.parse(firstPendingImport.updated_at) < TIME_RANGE)) {
              setPendingImport(firstPendingImport);
            }
          } else {
            setPendingImport(firstPendingImport);
          }
        }
      }
    } catch (e) {
      // TODO
    } finally {
      setImportsLoading(false);
    }
  };

  const startExport = async () => {
    setStartingExport(true);
    trackEvent('Product catalog settings - Generate export');
    try {
      const response = await axios.post('/api/v1/product_identifier_exports');
      setPendingExport(response.data.product_identifier_export);
      showToast('Generating product catalog...');
    } catch (e) {
      showToast('Error generating product catalog, please try again', true);
      trackEvent('Error: Product catalog settings - Generate export', { statusCode: e?.response?.status });
    } finally {
      setStartingExport(false);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onImportFinished = () => {
    closeModal();
    setShowDownloadBanner(false);
    setPendingExport(null);
    window.scrollTo(0, 0);
  };

  const handleBannerDownload = async () => {
    await startExport();
    setShowDownloadBanner(true);
  };

  const getImportBanner = () => {
    const actions = (
      <>
        <LegacyStack.Item>
          <Link onClick={handleBannerDownload}> Download CSV </Link>
        </LegacyStack.Item>
      </>
    );
    if (pendingImport !== null) {
      if (importing) {
        return <Banner tone="info" onDismiss={() => setPendingImport(null)}> Importing product catalog CSV.. </Banner>;
      }
      if (pendingImport.state === 'failed') {
        return (
          <Banner tone="critical">
            <LegacyStack vertical={true}>
              <LegacyStack.Item>
                <b>File could not be imported</b>
                <p>Invalid CSV format </p>
              </LegacyStack.Item>
              { actions }
            </LegacyStack>
          </Banner>
        );
      }
      if (pendingImport.invalid_gtin_count > 0) {
        return (
          <Banner tone="warning" onDismiss={() => setPendingImport(null)} >
            <LegacyStack vertical={true}>
              <LegacyStack.Item>
                <b>Some issues were detected during import</b>
                <p> {pendingImport.imported_count} catalog properties were updated successfully</p>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <b> Issues detected: </b>
                <List>
                  <List.Item><b>Invalid GTIN ({pendingImport.invalid_gtin_count}):</b> make sure GTIN is correctly formatted and contains the correct number of characters</List.Item>
                </List>
              </LegacyStack.Item>
              { actions }
            </LegacyStack>
          </Banner>
        );
      }
      return (
        <Banner tone="success" onDismiss={() => setPendingImport(null)}>
          <LegacyStack vertical={true}>
            <LegacyStack.Item>
              <b>CSV imported successfully</b>
              <p> {pendingImport.imported_count} properties in your catalog were updated successfully</p>
            </LegacyStack.Item>
            { actions }
          </LegacyStack>
        </Banner>
      );
    }

    return null;
  };

  const getDownloadBanner = () => {
    if (!showDownloadBanner) {
      return null;
    }

    return (
      (<Banner
        tone={exporting ? 'info' : 'success'}
        onDismiss={() => {setShowDownloadBanner(false); setPendingExport(null)}}
        action={exporting ? null : { content: 'Download', url: pendingExport?.download_url }}
      >
        <p>{exporting ? 'Generating CSV...' : 'CSV Generated'}</p>
      </Banner>)
    );
  };

  const getDownloadCard = () => {
    if (pendingExport && !showDownloadBanner) {
      return (
        (<Banner
          tone={exporting ? 'info' : 'success'}
          onDismiss={() => setPendingExport(null)}
          action={exporting ? null : { content: 'Download', url: pendingExport.download_url }}
        >
          <p>{exporting ? 'Generating CSV...' : 'CSV Generated'}</p>
        </Banner>)
      );
    }
  };

  const detailsClicked = () => {
    setShowDetails(!showDetails);
  };

  const catalogDetails = [
    {
      title: 'Total products',
      value: productVariantsAggregate.data?.product_variants_aggregate.total_products
    }
  ];

  const additionalCatalogDetails = [
    {
      title: 'GTIN',
      value: productVariantsAggregate.data?.product_variants_aggregate.gtin_count
    },
    {
      title: 'MPN',
      value: productVariantsAggregate.data?.product_variants_aggregate.mpn_count
    }
  ];

  return (
    <Page
      title="Catalog health"
      subtitle="Configure your product catalog to successfully syndicate reviews"
    >
      <Layout>
        <Layout.Section>
          { getImportBanner() }
          { getDownloadBanner() }
        </Layout.Section>
        <Layout.Section
          title="Product catalog"
        >
          {
            (catalogDetails.filter(detail => detail.value !== undefined).length > 0 &&
              additionalCatalogDetails.filter(detail => detail.value !== undefined).length > 0) &&
            <LoadingCardWrapper
              title="Current Catalog"
              actions={
                [
                  {
                    content: showDetails ? 'Hide Details' : 'Details',
                    onAction: detailsClicked,
                  }
                ]
              }
              loading={exportsLoading || importsLoading}
              sectioned={false}
            >
              <LegacyCard.Section>
                <TextContainer>
                  {
                    lastUpdated &&
                    <Text variant="bodyMd" as="span" tone="subdued">Last updated {lastUpdated}</Text>
                  }
                </TextContainer>
              </LegacyCard.Section>
              {
                catalogDetails.map((detail, index) => {
                  return (
                    <LegacyCard.Section subdued key={index}>
                      <LegacyStack distribution="equalSpacing">
                        <LegacyStack.Item>
                          <b>{detail.title}</b>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                          <b>{detail.value}</b>
                        </LegacyStack.Item>
                      </LegacyStack>
                    </LegacyCard.Section>
                  );
                })
              }
              <Collapsible
                open={showDetails}
              >
                {
                  additionalCatalogDetails.map((detail, index) => {
                    return (
                      <LegacyCard.Section subdued key={index}>
                        <LegacyStack distribution="equalSpacing">
                          <LegacyStack.Item>
                            <b>{detail.title}</b>
                          </LegacyStack.Item>
                          <LegacyStack.Item>
                            <b>{detail.value}</b>
                          </LegacyStack.Item>
                        </LegacyStack>
                      </LegacyCard.Section>
                    );
                  })
                }
              </Collapsible>
            </LoadingCardWrapper>
          }
          <LoadingCardWrapper
            title="Edit your catalog"
            loading={exportsLoading || importsLoading}
          >
            <LegacyStack vertical={true}>
              <LegacyStack.Item>
                <TextContainer> To make changes to your catalog, export your catalog as a CSV and edit the file.  </TextContainer>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <b> When editing the CSV file, </b>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <TextContainer>
                  <div className="d-flex">
                    <div className="mr-2">
                      <Icon source={XCircleIcon} tone="subdued" />
                    </div>
                    <p> Do not edit the remote_id field.</p>
                  </div>
                  <div className="d-flex">
                    <div className="mr-2">
                      <Icon source={XCircleIcon} tone="subdued" />
                    </div>
                    <p> Do not edit the GTIN field. To ensure consistency, this information can only be edited in your Shopify Account.</p>
                  </div>
                  <div className="d-flex">
                    <div className="mr-2">
                      <Icon source={InfoIcon} tone="subdued" />
                    </div>
                    <p> Ensure each product has either a <b>GTIN</b> or <b>Brand & MPN pairing.</b> </p>
                  </div>
                  {
                    getDownloadCard()
                  }
                  <Button
                    disabled={exporting || pendingExport}
                    loading={startingExport}
                    onClick={startExport}
                  >
                    Generate CSV Export
                  </Button>
                </TextContainer>
              </LegacyStack.Item>
            </LegacyStack>
          </LoadingCardWrapper>
          <LoadingCardWrapper
            title="Update your catalog"
            primaryFooterAction={{
              content: 'Upload CSV',
              onAction: openModal
            }}
            loading={exportsLoading || importsLoading}
          >
            <TextContainer>
              Once you’ve updated your catalog to meet requirements, <b>upload your edited CSV</b> file to sync your changes with Junip.
            </TextContainer>
          </LoadingCardWrapper>
        </Layout.Section>
      </Layout>
      <Modal
        open={showModal}
        title="Import edited CSV"
        onClose={closeModal}
      >
        <Modal.Section>
          <LegacyStack vertical>
            <TextContainer>
              <p>Upload your revised catalog in CSV format. Existing data will be overwritten. </p>
            </TextContainer>
          </LegacyStack>
        </Modal.Section>
        <ProductIdentifierImportDropZone
          setPendingImport={setPendingImport}
          importing={importing}
          cancelClicked={closeModal}
          importFinished={onImportFinished}
        />
      </Modal>
      <FooterHelp>
        Check out our <Link url="https://help.junip.co/en/articles/8160833-editing-your-product-catalog-in-junip">help article</Link> on editing your product catalog in Junip.
      </FooterHelp>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  productVariantsAggregate: state.productVariantsAggregate,
  productIdentifierImports: state.productIdentifierImports
});

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchProductVariantsAggregate: () => dispatch(fetchProductVariantsAggregate()),
  fetchProductIdentifierImports: () => dispatch(fetchProductIdentifierImports())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCatalogHealth);
