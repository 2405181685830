import axios from 'axios';

// Actions
const FETCH_PRODUCT_GROUPS = 'FETCH_PRODUCT_GROUPS';
const FETCH_PRODUCT_GROUPS_SUCCESS = 'FETCH_PRODUCT_GROUPS_SUCCESS';
const FETCH_PRODUCT_GROUPS_ERROR = 'FETCH_PRODUCT_GROUPS_ERROR';

// Action Creators
export function fetchProductGroups({ include = '', page = {} }) {
  return async dispatch => {
    dispatch(fetchProductGroupsBegin());
    try {
      const response = await axios.get('/api/v1/product_groups', {
        params: {
          include: include,
          'page[after]': page.after,
          'page[before]': page.before
        }
      });
      dispatch(fetchProductGroupsSuccess(response.data.product_groups, response.data.meta));
    } catch (e) {
      dispatch(fetchProductGroupsError(e));
    }
  }
}

export const fetchProductGroupsBegin = () => ({
  type: FETCH_PRODUCT_GROUPS
});

export const fetchProductGroupsSuccess = (data, meta) => ({
  type: FETCH_PRODUCT_GROUPS_SUCCESS,
  data,
  meta
});

export const fetchProductGroupsError = (error) => ({
  type: FETCH_PRODUCT_GROUPS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_PRODUCT_GROUPS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PRODUCT_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        loading: false,
        error: null
      };
    case FETCH_PRODUCT_GROUPS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
