import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { fetchReferralRecords } from 'redux/referralRecords';
import { showToast } from 'redux/toast';
import { TextField, TextContainer, Spinner, Icon, Button, Modal, Text, InlineCode } from "@shopify/polaris";
import { CheckIcon, XSmallIcon } from "@shopify/polaris-icons";
import LoadingWrapper from 'components/LoadingWrapper';
import useDebounce from 'utils/useDebounce';

function ReferralUrl({ referralRecords, fetchReferralRecords, showToast }) {
  const [creatingCode, setCreatingCode] = useState(false);
  const [codeState, setCodeState] = useState(null);
  const [newCode, setNewCode] = useState('');
  const debouncedNewCode = useDebounce(newCode, 500);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!referralRecords.data) {
      fetchReferralRecords();
    }
  }, [fetchReferralRecords, referralRecords.data]);

  useEffect(() => {
    async function checkCode() {
      setCodeState('loading');
      try {
        await axios.get(`/api/v1/referral_records/${debouncedNewCode}`);
        setCodeState('invalid');
      } catch (e) {
        setCodeState('valid');
      }
    }
    if (debouncedNewCode) {
      checkCode();
    } else {
      setCodeState(null);
    }
  }, [debouncedNewCode]);

  const createCode = async () => {
    setCreatingCode(true);
    try {
      await axios.post('/api/v1/referral_records', {
        referral_record: {
          code: debouncedNewCode
        }
      });
      showToast('Referral url created!');
      setShowModal(false);
      fetchReferralRecords();
      trackEvent('Referral settings - Create referral url');
    } catch (e) {
      setCreatingCode(false);
      setShowModal(false);
      showToast('Error creating referral URL, please try again', true);
      trackEvent('Error: Referral settings - Create referral url', { statusCode: e?.response?.status });
    }
  };

  const copyReferralUrl = async () => {
    try {
      await navigator.clipboard.writeText(`${process.env.REACT_APP_WEBSITE_URL}/m/${referralRecords.data?.code}`);
      showToast('Referral url copied');
      trackEvent('Referral settings - Copy referral url');
    } catch (e) {
      // error
    }
  };

  const handleChange = useCallback((value) => setNewCode(value.replace(/[^A-Za-z0-9\-_]/g, '')), []);

  return <>
    <LoadingWrapper sections={1} loading={referralRecords.loading}>
      {!referralRecords.data ?
        <TextContainer spacing="tight">
          <TextField
            type="text"
            label="Create your unique referral URL"
            maxLength={40}
            value={newCode}
            onChange={handleChange}
            disabled={creatingCode}
            prefix={`${process.env.REACT_APP_WEBSITE_URL}/m/`}
            connectedRight={
              <div style={{ width: '32px' }} className="h-100 d-flex align-items-center pl-3">
                {codeState === 'valid' &&
                  <Icon source={CheckIcon} accessibilityLabel="Valid input" tone="success" />
                }
                {codeState === 'invalid' &&
                  <Icon source={XSmallIcon} accessibilityLabel="Invalid input" tone="critical" />
                }
                {codeState === 'loading' &&
                  <Spinner size="small" accessibilityLabel="Checking input" />
                }
              </div>
            }
          />
          <Button

            disabled={!newCode || codeState !== 'valid' || creatingCode}
            loading={creatingCode}
            onClick={() => { setShowModal(true) }}
            variant="primary">
            Create
          </Button>
        </TextContainer>
        :
        <TextField
          label="Your referral URL"
          readOnly
          type="text"
          value={`${process.env.REACT_APP_WEBSITE_URL}/m/${referralRecords.data?.code}`}
          connectedRight={
            <Button
              onClick={copyReferralUrl}
            >
              Copy
            </Button>
          }
        />
      }
    </LoadingWrapper>
    <Modal
      open={showModal}
      title="Are your sure?"
      onClose={() => !creatingCode && setShowModal(false)}
      primaryAction={{
        content: 'Create URL',
        disabled: creatingCode,
        loading: creatingCode,
        onAction: () => { createCode(); trackEvent('Referral settings confirm modal - Create URL') }
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: creatingCode,
        onAction: () => { setShowModal(false); trackEvent('Referral settings confirm modal - Cancel') },
      }]}
    >
      <Modal.Section>
        <p>
          Are you sure you want to set{' '}
          <Text variant="bodyMd" as="span"><InlineCode>
              {`${process.env.REACT_APP_WEBSITE_URL}/m/${debouncedNewCode}`}
            </InlineCode></Text>{' '}
          as your referral URL? Note this cannot be changed once it's created.
        </p>
      </Modal.Section>
    </Modal>
  </>;
}

const mapStateToProps = (state) => ({
  referralRecords: state.referralRecords
});

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchReferralRecords: () => dispatch(fetchReferralRecords()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralUrl);
