import axios from 'axios';

// Actions
const FETCH_INCENTIVES_BEGIN = 'FETCH_INCENTIVES_BEGIN';
const FETCH_INCENTIVES_SUCCESS = 'FETCH_INCENTIVES_SUCCESS';
const FETCH_INCENTIVES_ERROR = 'FETCH_INCENTIVES_ERROR';

// Action Creators
export function fetchIncentives(params = {}) {
  return async dispatch => {
    dispatch(fetchIncentivesBegin());
    try {
      const response = await axios.get('/api/v1/incentives', {
        params: {
          include: 'incentive_type',
          ...params
        }
      });
      dispatch(fetchIncentivesSuccess(response.data.incentives));
    } catch (e) {
      dispatch(fetchIncentivesError(e));
    }
  }
}

export const fetchIncentivesBegin = () => ({
  type: FETCH_INCENTIVES_BEGIN
});

export const fetchIncentivesSuccess = data => ({
  type: FETCH_INCENTIVES_SUCCESS,
  data
});

export const fetchIncentivesError = error => ({
  type: FETCH_INCENTIVES_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_INCENTIVES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_INCENTIVES_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_INCENTIVES_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
