import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { ExportIcon } from "@shopify/polaris-icons";
import {
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  Badge,
  LegacyStack,
  Popover,
  ChoiceList,
  IndexFilters,
  DatePicker,
  Text,
  Button,
  OptionList,
  Page,
  Pagination,
  EmptyState,
} from "@shopify/polaris";
import AnalyticsDatePicker from 'components/AnalyticsDatePicker';
import styles from 'styles/components/ReportsRatingsByProduct.module.scss';
import useRatingsByProduct from 'utils/useRatingsByProduct';
import { isEmpty } from 'utils/AnalyticsUtils';

export default function ReportsRatingByProduct() {
  const [loading, setLoading] = useState(true);

  // data filters
  const [selectedDateRange, setSelectedDateRange] = useState('30days');
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(30, 'day').toDate(),
    end: moment().subtract(1, 'day').toDate()
  });
  const handleSelectedDates = useCallback((value) => {
    setSelectedDates(value);
  }, []);

  const [numericalTypeActive, setNumericalTypeActive] = useState(false);
  const [numericalType, setNumericalType] = useState('total');

  const [ratingTypeActive, setRatingTypeActive] = useState(false);
  const [ratingType, setRatingType] = useState('new');

  // views
  const [itemStrings, setItemStrings] = useState(['All products']);
  const [viewFilters, setViewFilters] = useState([[]]);
  const [selected, setSelected] = useState(0);

  // sorting
  const [sortSelected, setSortSelected] = useState(['total desc']);
  const { mode, setMode } = useSetIndexFiltersMode();

  // table filters
  const [productCategoryFilter, setProductCategoryFilter] = useState(undefined);

  const [dateFilterActive, setDateFilterActive] = useState(false);
  const [{ month, year }, setDate] = useState({
    month: moment().month(),
    year: moment().year()
  });
  const [filterSelectedDates, setFilterSelectedDates] = useState({
    start: moment().toDate(),
    end: moment().toDate(),
  });
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  );

  let { tableData, allProducts, productCategories, hasNext, hasPrevious, handleNextPage, handlePreviousPage, handleExport } =
    useRatingsByProduct({
      setLoading,
      selectedDates,
      numericalType,
      ratingType,
      sortSelected,
      productCategoryFilter,
      filterSelectedDates,
      dateFilterActive,
    });

  const resourceName = {
    singular: 'rating',
    plural: 'ratings',
  };

  // review filters
  const numericalOptions = [
    { label: 'Totals', value: 'total' },
    { label: 'Percentages', value: 'percent' }
  ];
  const ratingTypeOptions = [
    { label: 'New ratings only', value: 'new' },
    { label: 'Cumulative rating', value: 'cumulative' }
  ];

  const toggleNumericalTypeActive = useCallback(() => setNumericalTypeActive((numericalTypeActive) => !numericalTypeActive), []);
  const toggleRatingTypeActive = useCallback(() => setRatingTypeActive((ratingTypeActive) => !ratingTypeActive), []);

  const numericalTypeActivator = (
    <Button
      onClick={toggleNumericalTypeActive}
      disclosure='down'
      disabled={loading}
    >
      {numericalType === 'total' ? 'Totals' : 'Percentages'}
    </Button>
  );
  const ratingTypeActivator = (
    <Button
      onClick={toggleRatingTypeActive}
      disclosure='down'
      disabled={loading}
    >
      {ratingType === 'new' ? 'New ratings only' : 'Cumulative rating'}
    </Button>
  );

  const reviewFilters = (
    <div className={styles.reviewFilters}>
      <LegacyStack spacing='tight'>
        <LegacyStack.Item>
          <AnalyticsDatePicker
            selectedDates={selectedDates}
            setSelectedDates={handleSelectedDates}
            selected={selectedDateRange}
            setSelected={setSelectedDateRange}
            loading={loading || ratingType === 'cumulative'}
          />
        </LegacyStack.Item>
        <LegacyStack.Item>
          <Popover
            active={numericalTypeActive}
            activator={numericalTypeActivator}
            onClose={toggleNumericalTypeActive}
            fluidContent
            preferredAlignment='left'
          >
            <OptionList
              options={numericalOptions}
              onChange={(value) => {
                setNumericalType(value[0]);
                toggleNumericalTypeActive();
              }}
              selected={numericalType}
            />
          </Popover>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <Popover
            active={ratingTypeActive}
            activator={ratingTypeActivator}
            onClose={toggleRatingTypeActive}
            fluidContent
            preferredAlignment='left'
          >
            <OptionList
              options={ratingTypeOptions}
              onChange={(value) => {
                setRatingType(value[0]);
                toggleRatingTypeActive();
              }}
              selected={numericalType}
            />
          </Popover>
        </LegacyStack.Item>
      </LegacyStack>
    </div>
  );

  // views
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    const newViewFilters = [...viewFilters];
    newViewFilters.splice(index, 1);
    setViewFilters(newViewFilters);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setViewFilters([...viewFilters, viewFilters.slice(-1)]);
    setSelected(itemStrings.length);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => { },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
          {
            type: 'rename',
            onAction: () => { },
            onPrimaryAction: async (value) => {
              const newItemsStrings = tabs.map((item, idx) => {
                if (idx === index) {
                  return value;
                }
                return item.content;
              });
              setItemStrings(newItemsStrings);
              return true;
            },
          },
          {
            type: 'duplicate',
            onPrimaryAction: async (value) => {
              duplicateView(value);
              return true;
            },
          },
          {
            type: 'delete',
            onPrimaryAction: async () => {
              deleteView(index);
              return true;
            },
          },
        ],
  }));

  const onCreateNewView = async (value, type) => {
    setItemStrings([...itemStrings, value]);
    if (type === 'save-as') {
      setViewFilters([...viewFilters, appliedFilters]);
    } else {
      setViewFilters([...viewFilters, []]);
      handleFiltersClearAll();
    }
    setSelected(itemStrings.length);
    return true;
  };

  const onHandleCancel = () => {
    handleFiltersClearAll();
  };

  const onHandleSave = async () => {
    if (viewFilters[selected]) {
      const newViewFilters = [...viewFilters];
      newViewFilters[selected] = appliedFilters;
      setViewFilters(newViewFilters);
    } else {
      setViewFilters([...viewFilters, appliedFilters]);
    }
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
        type: 'save-as',
        onAction: (value) => onCreateNewView(value, 'save-as'),
        disabled: false,
        loading: false,
      }
      : {
        type: 'save',
        onAction: onHandleSave,
        disabled: false,
        loading: false,
      };

  const sortOptions = [
    { label: 'Review count', value: 'total asc', directionLabel: 'Ascending' },
    { label: 'Review count', value: 'total desc', directionLabel: 'Descending' },
    { label: '5-star review count', value: '5-star asc', directionLabel: 'Ascending' },
    { label: '5-star review count', value: '5-star desc', directionLabel: 'Descending' },
  ];

  const handleDateSelected = useCallback((dateSelected) => {
    setFilterSelectedDates({ start: dateSelected.start, end: dateSelected.end });
    setDateFilterActive(true);
  }, []);

  const handleProductCategoryRemove = useCallback(() => setProductCategoryFilter(undefined), []);
  const handleDateFilterRemove = useCallback(() => setDateFilterActive(false), []);

  const handleFiltersClearAll = useCallback(() => {
    handleProductCategoryRemove();
    handleDateFilterRemove();
  }, [handleDateFilterRemove, handleProductCategoryRemove]);

  // table filters
  const filters = [
    !isEmpty(productCategories) &&
    {
      key: 'productCategory',
      label: 'Product Category',
      filter: (
        <ChoiceList
          title='Product Category'
          titleHidden
          choices={productCategories.map(category => { return ({ label: category[1], value: category[0] }) })}
          selected={productCategoryFilter || []}
          onChange={setProductCategoryFilter}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'date',
      label: 'Date created',
      filter: (
        <div className={styles.datePicker}>
          <DatePicker
            month={month}
            year={year}
            onChange={handleDateSelected}
            onMonthChange={handleMonthChange}
            selected={filterSelectedDates}
            disableDatesAfter={moment().toDate()}
            allowRange
          />
        </div>
      )
    },
  ];

  const appliedFilters = [];

  if (productCategoryFilter && !isEmpty(productCategoryFilter)) {
    const key = 'productCategory';
    const val = productCategories.filter(item => productCategoryFilter.includes(item[0])).map(item => item[1]);
    appliedFilters.push({
      key,
      id: productCategoryFilter,
      label: getLabelForKey(key, val),
      onRemove: handleProductCategoryRemove,
    });
  }

  if (dateFilterActive) {
    const key = 'date';
    appliedFilters.push({
      key,
      date: filterSelectedDates,
      label: getLabelForKey(key, filterSelectedDates),
      onRemove: handleDateFilterRemove,
    });
  }

  // get views from local storage
  useEffect(() => {
    try {
      const itemStrings = JSON.parse(window.localStorage.getItem('itemStrings'));
      const viewFilters = JSON.parse(window.localStorage.getItem('viewFilters'));
      viewFilters.forEach((filters) => {
        if (filters) {
          filters.forEach(filter => {
            if (filter.key === 'date') {
              filter.date.start = new Date(filter.date.start);
              filter.date.end = new Date(filter.date.end);
            }
          });
        }
      });

      if (itemStrings.length > 0 && viewFilters) {
        setItemStrings(itemStrings);
        setViewFilters(viewFilters);
      }
    } catch (e) {
      // error
    }
  }, []);

  // store views in local storage
  useEffect(() => {
    try {
      window.localStorage.setItem('itemStrings', JSON.stringify(itemStrings));
      window.localStorage.setItem('viewFilters', JSON.stringify(viewFilters));
    } catch (e) {
      // error
    }
  }, [itemStrings, viewFilters]);

  // apply view filters
  useEffect(() => {
    setLoading(true);
    handleFiltersClearAll();
    const filters = viewFilters[selected];
    if (filters) {
      filters.forEach(filter => {
        switch (filter.key) {
          case 'productCategory':
            setProductCategoryFilter(filter.id);
            break;
          case 'date':
            setFilterSelectedDates(filter.date);
            setDateFilterActive(true);
            break;
          default:
            break;
        }
      });
    }
    setLoading(false);
  }, [handleFiltersClearAll, selected, viewFilters]);

  const formatData = (data, isTotal) => {
    const formatting = (numericalType === 'percent' && !isTotal) ? '%' : '';
    return (data && data !== 0) ? `${data}${formatting}` : '-';
  };

  const formatAvgRating = (avgRating) => {
    if (avgRating) {
      return +avgRating.toFixed(2);
    }
    return '-';
  };

  const emptyStateMarkup = () => {
    return (
      <EmptyState heading={'No product data available'} >
        <p>{'When you add products to your Shopify store, they’ll automatically appear in Junip.'}</p>
      </EmptyState >
    )
  };

  const allProductsRow = (
    <IndexTable.Row
      id={0}
      key={0}
      position={0}
    >
      <IndexTable.Cell>
        <Text fontWeight='semibold'>All products</Text>
      </IndexTable.Cell>
      <IndexTable.Cell>{formatAvgRating(allProducts.avgRating, true)}</IndexTable.Cell>
      <IndexTable.Cell>{formatData(allProducts.oneStar, false)}</IndexTable.Cell>
      <IndexTable.Cell>{formatData(allProducts.twoStar, false)}</IndexTable.Cell>
      <IndexTable.Cell>{formatData(allProducts.threeStar, false)}</IndexTable.Cell>
      <IndexTable.Cell>{formatData(allProducts.fourStar, false)}</IndexTable.Cell>
      <IndexTable.Cell>{formatData(allProducts.fiveStar, false)}</IndexTable.Cell>
      <IndexTable.Cell>
        <Text as='span' alignment='end' numeric>
          {formatData(allProducts.total, true)}
        </Text>
      </IndexTable.Cell>
    </IndexTable.Row>
  );

  const rowMarkup = tableData.map(
    (
      { productId, productTitle, deletedAt, avgRating, oneStar, twoStar, threeStar, fourStar, fiveStar, total },
      index,
    ) => {
      return (
        <IndexTable.Row
          id={productId}
          key={productId}
          position={index}
        >
          <IndexTable.Cell>
            <LegacyStack spacing='tight'>
              <Text>{productTitle}</Text>
              {deletedAt && <Badge tone='critical'>Deleted</Badge>}
            </LegacyStack>
          </IndexTable.Cell>
          <IndexTable.Cell>{formatAvgRating(avgRating, true)}</IndexTable.Cell>
          <IndexTable.Cell>{formatData(oneStar, false)}</IndexTable.Cell>
          <IndexTable.Cell>{formatData(twoStar, false)}</IndexTable.Cell>
          <IndexTable.Cell>{formatData(threeStar, false)}</IndexTable.Cell>
          <IndexTable.Cell>{formatData(fourStar, false)}</IndexTable.Cell>
          <IndexTable.Cell>{formatData(fiveStar, false)}</IndexTable.Cell>
          <IndexTable.Cell>
            <Text as='span' alignment='end' numeric>
              {formatData(total, true)}
            </Text>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    },
  );

  return (
    <Page
      title='Ratings by product'
      fullWidth
      backAction={{ content: 'Back', url: '/analytics/reports' }}
      secondaryActions={[
        {
          content: 'Export as CSV',
          icon: ExportIcon,
          onAction: handleExport
        }
      ]}>
      {reviewFilters}
      <LegacyCard>
        <IndexFilters
          sortOptions={sortOptions}
          sortSelected={sortSelected}
          onSort={setSortSelected}
          primaryAction={primaryAction}
          cancelAction={{
            onAction: onHandleCancel,
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selected}
          onSelect={setSelected}
          canCreateNewView
          onCreateNewView={onCreateNewView}
          filters={filters}
          appliedFilters={appliedFilters}
          onClearAll={handleFiltersClearAll}
          mode={mode}
          setMode={setMode}
          hideQueryField
        />
        <IndexTable
          resourceName={resourceName}
          selectable={false}
          itemCount={tableData.length}
          emptyState={emptyStateMarkup}
          headings={[
            { title: 'Product' },
            { title: 'Avg. rating' },
            { title: '1-star' },
            { title: '2-star' },
            { title: '3-star' },
            { title: '4-star' },
            { title: '5-star' },
            { title: 'Total', alignment: 'end' },
          ]}
        >
          {allProductsRow}
          {rowMarkup}
        </IndexTable>
        <div className={styles.paginationContainer}>
          <Pagination
            hasPrevious={hasPrevious}
            onPrevious={handlePreviousPage}
            hasNext={hasNext}
            onNext={handleNextPage}
          />
        </div>
      </LegacyCard>
    </Page>
  );
}

const getLabelForKey = (key, value) => {
  switch (key) {
    case 'productCategory':
      return (value).map((val) => `${val} `).join(', ');
    case 'date':
      const start = value.start.toLocaleDateString();
      const end = value.end.toLocaleDateString();
      if (start === end) {
        return `Created on ${start}`;
      } else {
        return `Created between ${start} and ${end}`;
      }
    default:
      return value;
  }
}
