import axios from 'axios';

// Actions
const FETCH_ORGANIZATIONS_BEGIN = 'FETCH_ORGANIZATIONS_BEGIN';
const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
const FETCH_ORGANIZATIONS_ERROR = 'FETCH_ORGANIZATIONS_ERROR';

// Action Creators
export function fetchOrganizations(params = {}) {
  return async dispatch => {
    dispatch(fetchOrganizationsBegin());
    try {
      const response = await axios.get('/api/v1/organizations', {
        params: {
          include: 'referral_record',
          'page[size]': 100,
          ...params
        }
      });
      dispatch(fetchOrganizationsSuccess(response.data.organizations));
    } catch (e) {
      dispatch(fetchOrganizationsError(e));
    }
  }
}

export const fetchOrganizationsBegin = () => ({
  type: FETCH_ORGANIZATIONS_BEGIN
});

export const fetchOrganizationsSuccess = (data) => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  data
});

export const fetchOrganizationsError = (error) => ({
  type: FETCH_ORGANIZATIONS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
