import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { changesSaved } from 'redux/settings';

// Actions
const FETCH_GOOGLE_REVIEW_SETTINGS_BEGIN = 'FETCH_GOOGLE_REVIEW_SETTINGS_BEGIN';
const FETCH_GOOGLE_REVIEW_SETTINGS_SUCCESS = 'FETCH_GOOGLE_REVIEW_SETTINGS_SUCCESS';
const FETCH_GOOGLE_REVIEW_SETTINGS_ERROR = 'FETCH_GOOGLE_REVIEW_SETTINGS_ERROR';

// Action Creators
export function fetchGoogleReviewSettings() {
  return async dispatch => {
    dispatch(fetchGoogleReviewSettingsBegin());
    try {
      const response = await axios.get('/api/v1/google_review_settings', {
        params: {
          include: 'store'
        }
      });
      dispatch(fetchGoogleReviewSettingsSuccess(response.data.google_review_settings[0]));
    } catch (e) {
      dispatch(fetchGoogleReviewSettingsError(e));
    }
  }
}

export function saveGoogleReviewSettings(id, settings) {
  return async dispatch => {
    try {
      const response = await axios.put(
        `/api/v1/google_review_settings/${id}?include=store`,
        {
          google_review_setting: settings
        }
      );
      dispatch(fetchGoogleReviewSettingsSuccess(response.data.google_review_setting));
      dispatch(changesSaved());
      trackEvent('Google Shopping settings - Save changes');
    } catch (e) {
      dispatch(fetchGoogleReviewSettingsError(e));
      dispatch(changesSaved(true));
      trackEvent('Error: Google Shopping settings - Save changes', { statusCode: e?.response?.status });
    }
  }
}

export const fetchGoogleReviewSettingsBegin = () => ({
  type: FETCH_GOOGLE_REVIEW_SETTINGS_BEGIN
});

export const fetchGoogleReviewSettingsSuccess = settings => ({
  type: FETCH_GOOGLE_REVIEW_SETTINGS_SUCCESS,
  data: { settings }
});

export const fetchGoogleReviewSettingsError = error => ({
  type: FETCH_GOOGLE_REVIEW_SETTINGS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_GOOGLE_REVIEW_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_GOOGLE_REVIEW_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.data.settings,
        loading: false,
        error: null
      };
    case FETCH_GOOGLE_REVIEW_SETTINGS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
