import React from 'react';
import { trackEvent } from 'utils/Mixpanel';
import {
  Modal,
  List,
  Link,
  TextContainer
} from '@shopify/polaris';

export default function GorgiasModal({showModal, setShowModal, appDetails}) {
  return (
    <>
      <Modal
        open={showModal}
        title={`Install ${appDetails?.name}?`}
        onClose={() => setShowModal(false)}
        secondaryActions={[{
          content: 'Close',
          onAction: () => { setShowModal(false) }
        }]}
      >
        <Modal.Section subdued>
          <TextContainer>
            <p>In order to install Gorgias you must visit the <Link url={appDetails.installUrl} external>Gorgias App Store</Link></p>
            <p> Steps on how to install the Gorgias integration: </p>
            <List type="number">
              <List.Item key={1}>On your main Gorgias page click 'Tickets' at the top of the sidebar</List.Item>
              <List.Item key={2}>Click 'Settings'</List.Item>
              <List.Item key={3}>Click 'All apps' in the sidebar under the 'App Store' label</List.Item>
              <List.Item key={4}>Search for and click on Junip</List.Item>
              <List.Item key={5}>Click the 'Connect' button</List.Item>
              <List.Item key={6}>Accept the authorization prompt</List.Item>

            </List>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
}
