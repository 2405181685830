import axios from 'axios';

// Actions
const FETCH_REVIEWS_BEGIN = 'FETCH_REVIEWS_BEGIN';
const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
const FETCH_REVIEWS_ERROR = 'FETCH_REVIEWS_ERROR';
const UPDATE_REVIEWS = 'UPDATE_REVIEWS';

const DEFAULT_PAGE_SIZE = 20;

// Action Creators
export function fetchReviews(data = {}) {
  let stateFilter = data.stateFilter;
  let ratingFilter = data.ratingFilter;
  let productFilter = data.productFilter;
  let typeFilter = data.typeFilter;
  let tagFilter = data.tagFilter;
  let responseFilter = data.responseFilter === 'all' ? null : data.responseFilter === 'true';

  const sortValue = data.sortValue || 'mostRecent';
  let sortField = 'created_at';
  let sortOrder = 'desc';
  if (stateFilter === 'all') {
    stateFilter = null;
  }
  if (ratingFilter === 'all') {
    ratingFilter = null;
  }
  if (typeFilter === 'all') {
    typeFilter = null;
  }
  if (tagFilter === 'all') {
    tagFilter = null;
  }

  switch (sortValue) {
    case 'mostRecent':
      sortField = 'created_at';
      sortOrder = 'desc';
      break;
    case 'featuredFirst':
      sortField = 'featured';
      sortOrder = 'desc';
      break;
    case 'oldest':
      sortField = 'created_at';
      sortOrder = 'asc';
      break;
    case 'highestRated':
      sortField = 'rating';
      sortOrder = 'desc';
      break;
    case 'lowestRated':
      sortField = 'rating';
      sortOrder = 'asc';
      break;
    default:
      break;
  }

  return async dispatch => {
    dispatch(fetchReviewsBegin());
    try {
      const response = await axios.get('/api/v1/reviews', {
        params: {
          include: 'customer,product,store,survey_answers,reward,tags,tik_tok_urls',
          'page[size]': DEFAULT_PAGE_SIZE,
          'filter[state]': stateFilter,
          'filter[rating]': ratingFilter,
          'filter[product_ids]': productFilter?.id || null,
          'filter[search]': data.queryValue || null,
          'filter[type]': typeFilter,
          'filter[has_unprocessed_media]': false,
          'filter[media_only]': data.mediaFilter || null,
          'filter[with_response]': responseFilter,
          'filter[tag]': tagFilter,
          'sort[field]': sortField,
          'sort[order]': sortOrder,
          'page[after]': data.page?.after,
          'page[before]': data.page?.before
        }
      });
      dispatch(fetchReviewsSuccess(response.data.reviews, response.data.meta));
    } catch (e) {
      dispatch(fetchReviewsError(e));
    }
  }
}

export const updateReviews = (data) => ({
  type: UPDATE_REVIEWS,
  data,
});

export const fetchReviewsBegin = () => ({
  type: FETCH_REVIEWS_BEGIN
});

export const fetchReviewsSuccess = (data, meta) => ({
  type: FETCH_REVIEWS_SUCCESS,
  data,
  meta
});

export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  data: { error }
});

// Reducer
const initialState = {
  data: null,
  loading: false,
  error: null
};

export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case FETCH_REVIEWS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        customers: action.data.customers,
        products: action.data.products,
        loading: false,
        error: null
      };
    case UPDATE_REVIEWS:
      let updatedReviews = [];

      for (let i = 0; i < state.data.length; i++) {
        updatedReviews.push({
         ...state.data[i],
         ...(action.data.find((itmInner) => itmInner.id === state.data[i].id))}
        );
      }
      return {
        ...state,
        data: updatedReviews
      };
    case FETCH_REVIEWS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
