import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

function HelpBeacon({ user }) {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/dgua8ibv';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
    document.body.appendChild(script);
    setHasLoaded(true);
  }, []);

  useEffect(() => {
    if (hasLoaded && user?.data?.email) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name: `${user?.data?.first_name} ${user.data?.last_name}`,
        email: user?.data?.email,
        created_at: user?.data?.created_at,
        user_id: user?.data?.id,
        user_hash: user?.data?.intercom_digest
      });
    } else if (hasLoaded && !user?.data?.email) {
      window.Intercom('shutdown');
    }
  }, [hasLoaded, user.data]);

  return (null);
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(
  mapStateToProps
)(HelpBeacon);
