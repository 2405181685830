import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import useLoadAnalyticsDb from 'utils/useLoadAnalyticsDb';

export default function ReportsRatingsCollectedOverTimeData({
  setLoading,
  selectedDates,
  period,
  showPercentages,
  selectedFilters
}) {
  const { db } = useLoadAnalyticsDb(setLoading);

  const [areaChartData, setAreaChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const select = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date, rating FROM analytics_reviews WHERE date >= '${moment(selectedDates.start).utc().format('YYYY-MM-DD')}' AND date <= '${moment(selectedDates.end).utc().format('YYYY-MM-DD')}'`;
  let ratings = select;

  if (selectedFilters?.selectedProduct?.length > 0) {
    ratings += ` AND product_id = ${selectedFilters?.selectedProduct[0]}`;
  }

  if (selectedFilters?.selectedReviewMedia?.length > 0) {
    ratings += ` AND has_media = TRUE`;
  }

  if (selectedFilters?.selectedReviewType?.length > 0) {
    if (selectedFilters?.selectedReviewType[0] === 'store') {
      ratings += ` AND type = 'StoreReview'`;
    } else if (selectedFilters?.selectedReviewType[0] === 'product') {
      ratings += ` AND type = 'ProductReview'`;
    }
  }

  if (selectedFilters?.selectedVariant?.length > 0) {
    selectedFilters?.selectedVariant.forEach((variant, i) => {
      if (i === 0) {
        ratings += ` AND product_variant_id = ${variant}`;
      } else {
        ratings += ` OR product_variant_id = ${variant}`;
      }
    });
  }

  ratings += ` GROUP BY date, rating;`;

  const groupByWeek = useCallback((chartData) => {
    return chartData.map((chart) => {
      const start = moment(selectedDates.start)
      const end = moment(selectedDates.end)
      const groupedData = [];

      for (let m = moment(start); m.diff(end, 'weeks') <= 0; m.add(1, 'weeks')) {
        let weekDate = m.format('YYYY-MM-DD');
        let sum = 0;

        chart.data.forEach((value) => {
          const isSameWeek = moment(value.key).isSame(weekDate, 'week');

          if (isSameWeek) {
            sum += value.value
          }
        });

        groupedData.push({
          key: weekDate,
          value: sum
        })
      }

      chart.data = groupedData;

      return chart;
    });
  }, [selectedDates]);

  const groupByMonth = useCallback((chartData) => {
    return chartData.map((chart) => {
      const start = moment(selectedDates.start)
      const end = moment(selectedDates.end)
      const groupedData = [];

      for (let m = moment(start); m.diff(end, 'months') <= 1; m.add(1, 'months')) {
        let monthDate = m.format('YYYY-MM-DD');
        let sum = 0;

        chart.data.forEach((value) => {
          const isSameMonth = moment(value.key).isSame(monthDate, 'month');

          if (isSameMonth) {
            sum += value.value
          }
        });

        groupedData.push({
          key: monthDate,
          value: sum
        })
      }

      chart.data = groupedData;

      return chart;
    });
  }, [selectedDates]);

  const formatAreaChartData = useCallback((values) => {
    // data is an internal object that associates a rating with an array of objects
    // representing a date and the quantity of reviews left on that date
    const data = {};
    // chartData is an array of objects in the format Polaris Viz expects, each object is a
    // rating (the label and an individual line in the chart) and a data array (which contains
    // objects representing an individual date and quantity for that date)
    let chartData = [];

    // Extract the values from their raw SQL.js response into the data object
    if (values) {
      values.forEach((value) => {
        const quantity = value[0];
        const date = value[1];
        let rating = value[2].toString();

        if (rating === '1') {
          rating = `${rating} star`;
        } else {
          rating = `${rating} stars`;
        }

        if (data[rating] === undefined) {
          data[rating] = [];
          data[rating].push({
            key: date,
            value: quantity
          });
        } else {
          data[rating].push({
            key: date,
            value: quantity
          });
        }
      });
    }

    if (Object.values(data).length === 0) {
      data['1 star'] = [];
      data['2 stars'] = [];
      data['3 stars'] = [];
      data['4 stars'] = [];
      data['5 stars'] = [];
    }

    // Populate all dates with 0 reviews collected with an appropriate object
    Object.values(data).forEach((valueArray) => {
      const dates = valueArray.map((obj) => obj.key);
      const start = moment(selectedDates.start)
      const end = moment(selectedDates.end)

      for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
        let checkDate = m.format('YYYY-MM-DD');

        if (dates.includes(checkDate)) {
          continue;
        } else {
          valueArray.push({
            key: checkDate,
            value: 0
          });
        }
      }
    });

    // Sort the date value objects by date
    Object.values(data).forEach((valueArray) => {
      valueArray = valueArray.sort((a, b) => {
        return new moment(a.key).format('YYYYMMDD') - new moment(b.key).format('YYYYMMDD')
      });
    });

    // Convert the data object to the chartData array
    Object.keys(data).forEach((key) => {
      chartData.push({
        name: key,
        data: data[key]
      });
    });

    switch(period) {
      case 'daily':
        // no-op, data is already grouped by day
        break;
      case 'weekly':
        chartData = groupByWeek(chartData);
        break;
      case 'monthly':
        chartData = groupByMonth(chartData);
        break;
      default:
        break;
    }

    return chartData;
  }, [selectedDates, groupByMonth, groupByWeek, period]);

  const groupBarChartByWeek = useCallback((chartData) => {
    return chartData.map((chart) => {
      const start = moment(selectedDates.start)
      const end = moment(selectedDates.end)
      const groupedData = [];

      for (let m = moment(start); m.diff(end, 'weeks') <= 0; m.add(1, 'weeks')) {
        let weekDate = m.format('YYYY-MM-DD');
        let sum = 0;
        let period = 0;
        let average = 0;

        chart.data.forEach((value) => {
          const isSameWeek = moment(value.key).isSame(weekDate, 'week');

          if (isSameWeek) {
            sum += value.value;
            if (value.value !== 0) {
              period++;
            }
          }
        });

        if (period !== 0) {
          average = Math.round(sum / period);
        }

        groupedData.push({
          key: weekDate,
          value: period !== 0 ? average : sum
        })
      }

      chart.data = groupedData;

      return chart;
    });
  }, [selectedDates]);

  const groupBarChartByMonth = useCallback((chartData) => {
    return chartData.map((chart) => {
      const start = moment(selectedDates.start)
      const end = moment(selectedDates.end)
      const groupedData = [];

      for (let m = moment(start); m.diff(end, 'months') <= 1; m.add(1, 'months')) {
        let monthDate = m.format('YYYY-MM-DD');
        let sum = 0;
        let period = 0;
        let average = 0;

        chart.data.forEach((value) => {
          const isSameMonth = moment(value.key).isSame(monthDate, 'month');

          if (isSameMonth) {
            sum += value.value
            if (value.value !== 0) {
              period++;
            }
          }
        });

        if (period !== 0) {
          average = Math.round(sum / period);
        }

        groupedData.push({
          key: monthDate,
          value: period !== 0 ? average : sum
        })
      }

      chart.data = groupedData;

      return chart;
    });
  }, [selectedDates]);

  const formatBarChartData = useCallback((values) => {
    // data is an internal object that associates a date with an array of objects
    // representing a rating and the quantity of reviews left on that date
    const data = {};
    // chartData is an array of objects in the format Polaris Viz expects, each object is a
    // is a rating and a data array (which contains objects representing an individual date
    // and quantity for that date)
    let chartData = [];

    // Extract the values from their raw SQL.js response into the data object
    if (values) {
      values.forEach((value) => {
        const quantity = value[0];
        const date = value[1];
        let rating = value[2].toString();

        if (data[date] === undefined) {
          data[date] = [];
          data[date].push({
            key: rating,
            value: quantity
          });
        } else {
          data[date].push({
            key: rating,
            value: quantity
          });
        }
      });
    }

    const dates = Object.keys(data);
    const start = moment(selectedDates.start)
    const end = moment(selectedDates.end)

    // Iterate through all dates in the selected range and populate any dates with no data
    // with a placeholder object
    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
      let checkDate = m.format('YYYY-MM-DD');

      if (dates.includes(checkDate)) {
        continue;
      } else {
        data[checkDate] = [
          {
            key: 1,
            value: 0
          },
          {
            key: 2,
            value: 0
          },
          {
            key: 3,
            value: 0
          },
          {
            key: 4,
            value: 0
          },
          {
            key: 5,
            value: 0
          },
        ];
      }
    }

    // ratingsData is an object that associates a rating with an array of objects (those objects being
    // a date and the percentage of reviews left on that date with that rating)
    const ratingsData = {};

    // Populate the ratingsData object with a rating as a key and an array of objects which are
    // a date and the percentage of reviews left on that date with that rating
    Object.keys(data).forEach((date) => {
      let ratings = data[date];
      let total_ratings = ratings.map((rating) => rating.value).reduce((a, b) => a + b);

      if (ratings.length !== 5) {
        if (!ratings.find((rating) => rating.key === '5')) {
          ratings.push({key: '5', value: 0});
        }
        if (!ratings.find((rating) => rating.key === '4')) {
          ratings.push({key: '4', value: 0});
        }
        if (!ratings.find((rating) => rating.key === '3')) {
          ratings.push({key: '3', value: 0});
        }
        if (!ratings.find((rating) => rating.key === '2')) {
          ratings.push({key: '2', value: 0});
        }
        if (!ratings.find((rating) => rating.key === '1')) {
          ratings.push({key: '1', value: 0});
        }
      }

      ratings.forEach((rating) => {
        if (ratingsData[rating.key] === undefined) {
          ratingsData[rating.key] = [];
          ratingsData[rating.key].push({
            key: date,
            value: total_ratings !== 0 ? Math.round((rating.value/total_ratings)*100) : 0
          });
        } else {
          ratingsData[rating.key].push({
            key: date,
            value: total_ratings !== 0 ? Math.round((rating.value/total_ratings)*100) : 0
          })
        }
      })
    });

    // Convert the ratingsData object to the chartData array
    Object.keys(ratingsData).forEach((rating) => {
      let label;
      if (rating === 1) {
        label = `${rating} star`;
      } else {
        label = `${rating} stars`;
      }

      chartData.push({
        name: label,
        data: ratingsData[rating]
      });
    });

    // Group the data by week or month if necessary
    switch(period) {
      case 'daily':
        // no-op, data is already grouped by day
        break;
      case 'weekly':
        chartData = groupBarChartByWeek(chartData);
        break;
      case 'monthly':
        chartData = groupBarChartByMonth(chartData);
        break;
      default:
        break;
    }

    let sortedData = [];

    chartData.forEach((rating) => {
      const sorted = {
        name: rating.name,
        data: rating.data.sort((a, b) => a.key > b.key ? 1 : -1)
      }

      sortedData.push(sorted);
    });

    return sortedData;
  }, [selectedDates, period, groupBarChartByMonth, groupBarChartByWeek]);

  const groupTableDataByWeek = useCallback((tableData) => {
    const start = moment(selectedDates.start)
    const end = moment(selectedDates.end)
    const groupedData = {};

    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'weeks')) {
      let weekDate = m.format('YYYY-MM-DD');

      Object.keys(tableData).forEach((date) => {
        const isSameWeek = moment(date).isSame(weekDate, 'week');

        if (isSameWeek) {
          tableData[date].forEach((rating) => {
            if (groupedData[weekDate] === undefined) {
              groupedData[weekDate] = {};
            }

            if (groupedData[weekDate][rating.rating] === undefined) {
              groupedData[weekDate][rating.rating] = 0;
            }

            groupedData[weekDate][rating.rating] += rating.value;
          });
        }
      });
    }

    let newTableData = {};

    Object.keys(groupedData).forEach((date) => {
      newTableData[date] = [];

      Object.keys(groupedData[date]).forEach((rating) => {
        newTableData[date].push({
          rating: rating,
          value: groupedData[date][rating]
        });
      });
    });

    return newTableData;
  }, [selectedDates]);

  const groupTableDataByMonth = useCallback((tableData) => {
    const start = moment(selectedDates.start)
    const end = moment(selectedDates.end)
    const groupedData = {};

    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'months')) {
      let monthDate = m.format('YYYY-MM-DD');

      Object.keys(tableData).forEach((date) => {
        const isSameMonth = moment(date).isSame(monthDate, 'month');

        if (isSameMonth) {
          tableData[date].forEach((rating) => {
            if (groupedData[monthDate] === undefined) {
              groupedData[monthDate] = {};
            }

            if (groupedData[monthDate][rating.rating] === undefined) {
              groupedData[monthDate][rating.rating] = 0;
            }

            groupedData[monthDate][rating.rating] += rating.value;
          });
        }
      });
    }

    let newTableData = {};

    Object.keys(groupedData).forEach((date) => {
      newTableData[date] = [];

      Object.keys(groupedData[date]).forEach((rating) => {
        newTableData[date].push({
          rating: rating,
          value: groupedData[date][rating]
        });
      });
    });

    return newTableData;
  }, [selectedDates]);

  const formatTableData = useCallback((values) => {
    let data = {};
    let rows = [];

    if (values) {
      values.forEach((value) => {
        const quantity = value[0];
        const date = value[1];
        let rating = value[2].toString();

        if (data[date] === undefined) {
          data[date] = [];
          data[date].push({
            rating: rating,
            value: quantity
          });
        } else {
          data[date].push({
            rating: rating,
            value: quantity
          });
        }
      });
    }

    switch(period) {
      case 'daily':
        // no-op, data is already grouped by day
        break;
      case 'weekly':
        data = groupTableDataByWeek(data);
        break;
      case 'monthly':
        data = groupTableDataByMonth(data);
        break;
      default:
        break;
    }

    Object.keys(data).forEach((date) => {
      const ratings = data[date];
      let oneStar = ratings.find((obj) => obj.rating === '1')?.value || 0;
      let twoStar = ratings.find((obj) => obj.rating === '2')?.value || 0;
      let threeStar = ratings.find((obj) => obj.rating === '3')?.value || 0;
      let fourStar = ratings.find((obj) => obj.rating === '4')?.value || 0;
      let fiveStar = ratings.find((obj) => obj.rating === '5')?.value || 0;
      const total = oneStar + twoStar + threeStar + fourStar + fiveStar;

      const totalRating = (
        (1 * oneStar) +
        (2 * twoStar) +
        (3 * threeStar) +
        (4 * fourStar) +
        (5 * fiveStar)
      )

      const averageRating = totalRating / (oneStar + twoStar + threeStar + fourStar + fiveStar);

      if (showPercentages) {
        oneStar = `${Math.round(oneStar / total * 100)}%`;
        twoStar = `${Math.round(twoStar / total * 100)}%`;
        threeStar = `${Math.round(threeStar / total * 100)}%`;
        fourStar = `${Math.round(fourStar / total * 100)}%`;
        fiveStar = `${Math.round(fiveStar / total * 100)}%`;
      }

      rows.push([
        date,
        parseFloat(averageRating).toFixed(1),
        oneStar,
        twoStar,
        threeStar,
        fourStar,
        fiveStar,
        showPercentages ? null : total
      ])
    });

    return rows;
  }, [period, showPercentages, groupTableDataByWeek, groupTableDataByMonth]);

  useEffect(() => {
    if (db) {
      const ratingsData = db.exec(ratings);
      const chartData = formatAreaChartData(ratingsData[0]?.values)
      const barChartData = formatBarChartData(ratingsData[0]?.values)
      const tableData = formatTableData(ratingsData[0]?.values)

      setAreaChartData(chartData);
      setBarChartData(barChartData);
      setTableData(tableData);
    }
  }, [db, ratings, setLoading, selectedDates, period, showPercentages, selectedFilters, formatAreaChartData, formatBarChartData, formatTableData]);

  return {
    areaChartData,
    barChartData,
    tableData
  }
}
