import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { Button, LegacyCard, Form, Select, LegacyStack, TextField, Text } from "@shopify/polaris";
import ProductFilter from 'components/ProductFilter';

export default function NewSurveyRule({ addRule, readOnly = false, action, rule }) {
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState(readOnly ? rule.property : 'id');
  const [operator, setOperator] = useState(readOnly ? `${rule.negate ? '!' : ''}${rule.operator}` : 'eq');
  const [value, setValue] = useState(readOnly ? rule.value : '');
  const [productFilter, setProductFilter] = useState(readOnly ? rule.property === 'id' ? rule.value : null : null);

  useEffect(() => {
    async function fetchData(id) {
      try {
        const response = await axios.get(`/api/v1/products/${id}`);
        const product = response.data.product || null;
        setProductFilter(product);
      } catch (e) {
        // No - op
      }
    }

    const productId = productFilter?.id ? null : productFilter;
    if (productId && readOnly && !productFilter?.title) {
      fetchData(productId);
    }
  }, [productFilter, readOnly]);

  const handlePropertyChange = useCallback((value) => {
    setProperty(value);
    if (value === 'id') {
      setOperator('eq');
    }
  }, []);

  const handleOperatorChange = useCallback((value) => {
    setOperator(value);
  }, []);

  const handleValueChange = useCallback((newValue) => setValue(newValue), []);

  const add = async () => {
    if (readOnly) {
      return;
    }
    const newRule = {
      property,
      negate: operator.includes('!'),
      operator: operator.replace('!', ''),
      value: property === 'id' ? productFilter?.id : value,
      value_type: property === 'id' ? 'integer' : 'string',
      target: 'product'
    };

    setLoading(true);
    await addRule(newRule);

    setProperty('id');
    setOperator('eq');
    setValue('');
    setProductFilter(null);

    setLoading(false);
  };

  const isValid = () => {
    if (property === 'id') {
      if (!productFilter?.id) {
        return false;
      }
    }

    if (property === 'title') {
      if (!value) {
        return false;
      }
    }

    return true;
  };

  const propertyOptions = [
    { label: 'Product', value: 'id' },
    { label: 'Product title', value: 'title' },
    { label: 'Product type', value: 'category' }
  ];

  const operatorOptions = [
    { label: 'is', value: 'eq' },
    { label: 'is not', value: '!eq' },
    { label: 'contains', value: 'ico', disabled: property === 'id' },
    { label: 'does not contain', value: '!ico', disabled: property === 'id' },
    { label: 'starts with', value: 'isw', disabled: property === 'id' },
    { label: 'ends with', value: 'iew', disabled: property === 'id' },
  ];

  return <>
    { readOnly ? (
      <LegacyCard.Subsection>
        <LegacyStack alignment="center">
          <LegacyStack.Item fill>
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {propertyOptions.find(option => option.value === property).label}
            </Text>{' '}
            {operatorOptions.find(option => option.value === operator).label}
            {' '}
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {property === 'id' ? productFilter?.title : value}
            </Text>
          </LegacyStack.Item>
          <LegacyStack.Item>
            {action}
          </LegacyStack.Item>
        </LegacyStack>
      </LegacyCard.Subsection>
    ) : (
      <LegacyCard.Section title={readOnly ? undefined : 'New condition'} subdued={!readOnly}>
        <Form onSubmit={add}>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              <Select
                options={propertyOptions}
                onChange={handlePropertyChange}
                value={property}
                disabled={readOnly}
              />
            </LegacyStack.Item>
            <LegacyStack.Item fill>
              <Select
                options={operatorOptions}
                onChange={handleOperatorChange}
                value={operator}
                disabled={readOnly}
              />
            </LegacyStack.Item>
            <LegacyStack.Item fill>
              {property === 'id' ?
                <ProductFilter disabled={readOnly} productFilter={productFilter} setProductFilter={setProductFilter} isFilter={false} />
              :
                <TextField
                  type="text"
                  value={value}
                  onChange={handleValueChange}
                  readOnly={readOnly}
                />
              }
            </LegacyStack.Item>
            <Button submit disabled={!isValid()} loading={loading} variant="primary">
              Save
            </Button>
          </LegacyStack>
        </Form>
      </LegacyCard.Section>
    )}
  </>;
}
