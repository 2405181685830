import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from 'redux/user';

let savedPath = null;

const AuthorizedRoute = ({ user, logout, component, ...rest }) => {
  const isLoginPath = rest.path.includes('/login');
  const isResetPasswordPath = rest.path === '/login/reset';
  const isOauthPath = rest.path.includes('/oauth');

  const search = rest.location.search;

  if (user.loggedIn === false) {
    if (isLoginPath || isOauthPath) {
      return <Route component={component} {...rest} />
    } else if (user.loading) {
      return <Route component={component} {...rest} />
    }
    savedPath = `${rest.location.pathname}`; // Save path user was going to
    logout(); // Log the user out
    return <Redirect push to={{ pathname: '/login', search }} {...rest} />
  } else if (user.loggedIn === true) {
    if (isLoginPath && !isResetPasswordPath) {
      return <Redirect push to={{ pathname: savedPath || '/home', search }} {...rest} />
    }
  }

  return <Route component={component} {...rest} />
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedRoute);
