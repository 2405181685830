import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { fetchBillingSubscriptions } from 'redux/billingSubscriptions';
import axios from 'axios';
import { Modal, TextField, Form, TextContainer, Banner, Link, Text } from "@shopify/polaris";

function SendTestEmailModal({
  messageTemplateId,
  showModal,
  setShowModal,
  showToast,
  user,
  stores,
  fetchBillingSubscriptions,
  billingSubscriptions
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(user.data?.email || null);
  const store = stores?.data?.find?.(s => s.scoped === true) || {};
  const readOnly = (() => {
    if (user?.data?.employee) {
      return false;
    }

    return (store?.remote_plan_name === 'trial') || (billingSubscriptions?.data?.findIndex((b) => b.active === true && b.provider === 'junip') !== -1);
  })();
  const disableTesting = !user?.data?.verified_email;

  const handleChange = useCallback((newEmail) => setEmail(newEmail), []);

  useEffect(() => {
    if (!billingSubscriptions.data) {
      fetchBillingSubscriptions();
    }
  }, [fetchBillingSubscriptions, billingSubscriptions.data]);

  const sendTestEmail = async () => {
    if (!isValid() || disableTesting) {
      return;
    }

    setLoading(true);
    try {
      await axios.post('/api/v1/messages', {
        message: {
          test: true,
          target: readOnly ? user.data?.email : email,
          template_id: messageTemplateId
        }
      });
      setLoading(false);
      showToast('Test message sent successfully');
      setShowModal(false);
    } catch(e) {
      setLoading(false);
      showToast('Error sending your test message, please check your email and try again', true);
      trackEvent('Error: Send test email modal - Send test email')
    }
  };

  const isValid = () => {
    if (!email) {
      return false;
    }
    if (!email.includes('@')) {
      return false;
    }

    return true;
  };

  return (
    <Modal
      open={showModal}
      title="Send a test email"
      onClose={() => !loading && setShowModal(false)}
      primaryAction={{
        content: 'Send test email',
        disabled: loading || !isValid() || disableTesting,
        loading: loading,
        onAction: () => { sendTestEmail(); trackEvent('Send test email modal - Send test email') }
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: loading,
          onAction: () => { setShowModal(false); trackEvent('Send test email modal - Cancel') },
        }
      ]}
    >
      {disableTesting && (
        <Modal.Section>
          <Banner tone="critical">
            You must verify your email address before you can send test emails.{' '}
            Click the confirmation that was sent to your email or visit <Link url="/settings/user">User Settings</Link> to resend it
          </Banner>
        </Modal.Section>
      )}
      <Modal.Section>
        <TextContainer>
          <Form onSubmit={sendTestEmail}>
            <TextField
              value={email}
              name="email"
              onChange={handleChange}
              label="Enter the email address you'd like to send the test message to:"
              helpText="Be sure to save your message settings before sending your test email"
              type="email"
              placeholder="Email"
              autoFocus={true}
              autoComplete="email"
              disabled={readOnly || disableTesting}
            />
          </Form>
          <p><Text variant="bodyMd" as="span" fontWeight="semibold">Note</Text>: Test emails will be populated with placeholder products and sample customer information</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  billingSubscriptions: state.billingSubscriptions,
  user: state.user,
  stores: state.stores
});

const mapDispatchToProps = (dispatch) => ({
  fetchBillingSubscriptions: () => dispatch(fetchBillingSubscriptions()),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendTestEmailModal);
