import React, { useState } from 'react';

import {
  LegacyCard,
  Form,
  FormLayout,
  TextField,
  ButtonGroup,
  Button,
  Banner,
  Collapsible
} from '@shopify/polaris';

import LoginWrapper from 'components/LoginWrapper';

import axios from 'axios';

const Reset = (props) => {
  const [inputs, setInputs] = useState({ password: '', passwordConfirmation: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidPasswordError, setInvalidPasswordError] = useState(null);

  const params = new URLSearchParams(window?.location?.search);
  const token = params.get('token');

  // Push to login if there's no auth token.
  if (!token) props.history.push('/login');

  const content = () => {
    if (isSubmitted) return (
      <LegacyCard sectioned title="Success!">
        <p className="mb-5">Your password has been reset.</p>
        <Button size="large" url={'/login'} variant="primary">Log in</Button>
      </LegacyCard>
    );

    return (
      <LegacyCard sectioned title="Enter a new password">
        <Collapsible open={isError}>
          <div className="pb-5">
            <Banner
              tone="critical"
              action={{ url: '/login/forgot_password', content: 'Request a new link' }}
              onDismiss={handleDismissError}>
              There was a problem resetting your password.
            </Banner>
          </div>
        </Collapsible>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              autoFocus={true}
              value={inputs.password}
              name="password"
              onChange={handleInputChange('password')}
              type="password"
              label="Password"
              disabled={isSubmitted}
              placeholder="Password"
              helpText="Password must be at least 8 characters long"
              error={invalidPasswordError}
              maxLength="72"
              minLength="8"
              autoComplete="new-password"
            />
            <TextField
              value={inputs.passwordConfirmation}
              name="passwordConfirmation"
              onChange={handleInputChange('passwordConfirmation')}
              type="password"
              label="Password confirmation"
              disabled={isSubmitted}
              placeholder="Password confirmation"
              error={confirmationError()}
              maxLength="72"
              minLength="8"
              autoComplete="new-password"
            />
            <ButtonGroup>
              <Button
                size="large"
                submit
                disabled={buttonDisabled()}
                loading={isLoading}
                variant="primary">
                Reset password
              </Button>
            </ButtonGroup>
          </FormLayout>
        </Form>
      </LegacyCard>
    );
  };

  const confirmationError = () => {
    if (inputs.password === inputs.passwordConfirmation) return false;
    if (!inputs.passwordConfirmation) return false;
    return "Password must match confirmation"
  };

  const buttonDisabled = () => {
    if (invalidPasswordError && (inputs.password?.length >= 8 && inputs.password?.length <= 72)) {
      setInvalidPasswordError(null);
    }

    return !!confirmationError() || !inputs.password || !inputs.passwordConfirmation;
  }

  const handleSubmit = async(event) => {
    if (inputs.password?.length < 8 || inputs.password?.length > 72) {
      setInvalidPasswordError('Invalid password length');
      return;
    }

    setIsLoading(true);

    try {
      await axios.put(
        '/api/v1/users/reset_password',
        {
          user: {
            password: inputs.password,
            password_confirmation: inputs.passwordConfirmation
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      setIsLoading(false);
      setIsSubmitted(true);
    } catch(e) {
      inputs.password = null;
      inputs.passwordConfirmation = null;
      setIsLoading(false);
      setIsError(true);
    }
  };

  const handleInputChange = (field) => {
    return (value) => setInputs(inputs => ({...inputs, [field]: value}));;
  };

  const handleDismissError = () => {
    setIsError(false);
  }

  return (
    <LoginWrapper>
      {content()}
    </LoginWrapper>
  );
}

export default Reset;
