export function calcPercentChange(current, previous) {
  const pctChange = ((current - previous) / previous) * 100;
  return pctChange === Infinity || isNaN(pctChange) ? 0 : pctChange;
};

// format "WHERE" statements for queries
export function whereFormatter(...args) {
  let conditions = Array.from(args).filter((e) => e !== null);

  if (conditions.length === 0) return '';

  let statement = ` WHERE ${conditions[0]}`;
  for (var i = 1; i < conditions.length; i++) {
    statement += ` AND ${conditions[i]}`;
  }

  return statement;
};

export function calcPercentage(num, den) {
  const percent = den !== 0 ? num / den * 100 : 0;
  return +percent.toFixed(1);
};

export function isEmpty(value) {
  if (value && Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === '' || value == null;
  }
}
