import React, { useState, useEffect, useCallback } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import {
  Button,
  Badge,
  LegacyStack,
  Select,
  TextContainer,
  Text,
  Card,
  List,
  Box,
} from "@shopify/polaris";

import billingPlanDetails from 'utils/billingPlanDetails';

export default function BillingPlan({
  planActive,
  activeBillingPlan,
  activeBillingSubscription,
  planGroup,
  length,
  chooseLoading,
  choosePlan,
  cancelLoading,
  twoPointOh = false,
}) {
  const [selectedPlan, setSelectedPlan] = useState(planGroup[0]);
  const [loading, setLoading] = useState(false);

  const planOptions = !twoPointOh ? planGroup.map((plan) => {
    return {
      label: `${new Intl.NumberFormat().format(plan?.usage_limit)} orders`,
      value: plan.id
    }
  }) : undefined;

  useEffect(() => {
    if (!chooseLoading) {
      setLoading(false);
    }
  }, [chooseLoading]);

  const handleSelectChange = useCallback((value) => {
    setSelectedPlan(planGroup.find(p => p.id === parseInt(value)));
    trackEvent('Billing plan - Select variant');
  }, [planGroup]);

  const selectPlan = async (plan) => {
    setLoading(true);
    await choosePlan(plan);
  };

  const {
    name,
    description,
    recommended,
    price,
    summary,
  } = billingPlanDetails(selectedPlan, twoPointOh) || {};

  const isActive = planActive && activeBillingPlan?.id === selectedPlan.id && activeBillingSubscription.auto_renew !== false;

  const renderCta = () => {
    if (twoPointOh) {
      return 'Select';
    }

    if (activeBillingSubscription?.auto_renew === false) {
      return 'Upgrade';
    }

    if (selectedPlan?.base_price?.cents >= activeBillingPlan?.base_price?.cents) {
      return 'Upgrade';
    } else if (selectedPlan?.base_price?.cents < activeBillingPlan?.base_price?.cents) {
      return 'Downgrade';
    }

    return 'Select';
  };

  return <>
    {(selectedPlan?.free === false) &&
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          background: "var(--p-color-bg-surface)",
          padding: "var(--p-space-400)",
          borderRadius: "var(--p-border-radius-200)"
        }}
      >
        <div className="d-flex justify-content-between" style={{ height: '24px' }}>
          <Text variant="headingSm" as="h3">{name}</Text>
          {recommended &&
            <Badge tone="info">Recommended</Badge>
          }
        </div>
        <div className="mb-4">
          <>
            <Text variant="headingLg" as="span">{price}</Text>
            <span>{' '}per month</span>
          </>
        </div>
        <div className="mb-4">
          <p>
            <Text variant="bodyMd" as="span" tone="subdued">{description}</Text>
          </p>
        </div>
        {summary &&
          <div className="mb-4">
            <List type="bullet">
              {summary.map((item, index) => {
                return <List.Item key={index}>{item}</List.Item>
              })}
            </List>
          </div>
        }
        {planGroup[0]?.usage_limit &&
          <div className="mb-4">
            {planOptions?.length > 1 ?
              <Select
                label="Monthly usage"
                options={planOptions}
                onChange={handleSelectChange}
                value={selectedPlan.id}
              />
              :
              <>
                Up to {planOptions[0].label} / month
              </>
            }
          </div>
        }
        <div className={length > 2 ? 'text-center' : ''} style={{ marginTop: "auto" }}>
          <Button
            loading={loading}
            onClick={() => selectPlan(selectedPlan)}
            fullWidth={length > 2}
            size="large"
            disabled={isActive || chooseLoading || cancelLoading}
            variant="primary">
            {isActive ? 'Current Plan' : renderCta()}
          </Button>
        </div>
      </Box>
    }
    {
      selectedPlan?.free === true &&
      <Card>
        <LegacyStack spacing="loose" alignment="trailing">
          <LegacyStack.Item fill>
            <TextContainer spacing="tight">
              <Text variant="headingSm" as="h3">Free plan</Text>
              <p>{description}</p>
            </TextContainer>
          </LegacyStack.Item>
          <Button
            loading={loading}
            onClick={() => selectPlan(selectedPlan)}
            disabled={isActive || chooseLoading || cancelLoading}
          >
            {isActive ? 'Current Plan' : 'Downgrade to free'}
          </Button>
        </LegacyStack>
      </Card>
    }
  </>;
}
