import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { countries } from 'utils/countries';
import ShippingLocation from 'components/ShippingLocation';
import {
  Select,
  LegacyStack,
  InlineStack,
  BlockStack,
} from '@shopify/polaris';
import LoadingContentWrapper from './LoadingContentWrapper';

export default function ShippingLocationsSelector({ fetchShippingLocations, dispatch, shippingLocations }) {
  const [adding, setAdding] = useState(false);
  const [selected, setSelected] = useState('');

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const addShippingLocation = useCallback(async () => {
    setAdding(true);
    try {
      const { code, name } = JSON.parse(selected);

      await axios.post('/api/v1/shipping_locations', {
        shipping_location: {
          country: name,
          country_code: code
        }
      });
      dispatch(showToast('Shipping location added'));
      setSelected('');
      dispatch(fetchShippingLocations());
      trackEvent('Shipping locations modal - Add location');
    } catch (e) {
      dispatch(showToast('Error adding shipping location, please try again', true));
      trackEvent('Error: Shipping locations modal - Add location');
    } finally {
      setAdding(false);
    }
  }, [dispatch, fetchShippingLocations, selected]);

  const deleteShippingLocation = async (id) => {
    try {
      await axios.delete(`/api/v1/shipping_locations/${id}`);
      dispatch(showToast('Shipping location removed'));
      dispatch(fetchShippingLocations());
      trackEvent('Shipping locations modal - Delete location');
    } catch (e) {
      dispatch(showToast('Error removing shipping location, please try again', true));
      trackEvent('Error: Shipping locations modal - Delete location');
    }
  };

  useEffect(() => {
    if (selected) {
      addShippingLocation();
    }
  }, [addShippingLocation, selected]);

  const renderCountryLabel = ({ name, code }) => {
    if (!code) {
      const country = countries.find((country) => country.name === name);
      code = country?.code;
    }
    const flag = code?.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) || '';
    return `${flag} ${name}`;
  };

  let options = [{ label: '--Select country--', value: '', disabled: true }]
  options = [...options, ...countries.map(country => {
    const disabled = shippingLocations?.data?.findIndex((location) => {
      return location.country.includes(country.name);
    }) > -1;
    return { label: renderCountryLabel(country), value: JSON.stringify(country), disabled: disabled }
  })];

  return (
    <LoadingContentWrapper loading={adding} cards={1}>
      <BlockStack gap="200">
        <InlineStack align="space-between">
          <LegacyStack.Item fill>
            <Select
              id="shipping-locations"
              label="Domestic shipping locations"
              value={selected}
              onChange={handleSelectChange}
              options={options}
              error={shippingLocations?.data?.length < 1 ? 'At least one domestic shipping location is required when setting an international delay' : null}
            />
          </LegacyStack.Item>
        </InlineStack>
        {
          shippingLocations.data?.map((shippingLocation) =>
            <ShippingLocation
              key={shippingLocation.id}
              renderCountryLabel={renderCountryLabel}
              shippingLocation={shippingLocation}
              deleteShippingLocation={deleteShippingLocation}
            />
          )
        }
      </BlockStack>
    </LoadingContentWrapper>
  );
}
