import React, { useEffect, useState } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { Switch, useRouteMatch } from 'react-router-dom';
import { setUser } from 'redux/user';
import { showToast } from 'redux/toast';
import AuthorizedRoute from 'utils/AuthorizedRoute';
import axios from 'axios';
import LoadingCover from 'components/LoadingCover';
import LoginWrapper from 'components/LoginWrapper';
import Continue from 'routes/oauth/Continue';
import CreateAccount from 'routes/oauth/CreateAccount';
import SignIn from 'routes/oauth/SignIn';
import CreateOrg from 'routes/oauth/CreateOrg';
import SelectOrg from 'routes/oauth/SelectOrg';
import SelectStore from 'routes/oauth/SelectStore';
import Failure from 'routes/oauth/Failure';
import PermissionDenied from 'routes/oauth/PermissionDenied';
import NoStores from 'routes/oauth/NoStores';
import UpgradePlan from 'routes/oauth/UpgradePlan';
import Expired from 'routes/oauth/Expired';

function Oauth({ history, setUser, showToast }) {
  const match = useRouteMatch();
  const { push, replace, location } = history;
  const params = new URLSearchParams(window.location.search);
  const orId = params.get('or_id');
  const orNew = params.get('or_new') === 'true' ? true : false; // Whether or not oauth result has an org true if no org, false if has org

  const [loading, setLoading] = useState(true);
  const [working, setWorking] = useState(false);
  const [me, setMe] = useState(null);
  const [oauthResult, setOauthResult] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [orgMeta, setOrgMeta] = useState(null);
  const [stores, setStores] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/api/v1/users/me');
        setMe(response.data.user);
        setLoading(false);

        if (orId) {
          replace(`/oauth/continue${window.location.search}`);
        } else {
          replace('/home');
        }
      } catch (e) {
        setLoading(false);

        if (orId) {
          replace(`/oauth/create_account${window.location.search}`);
        } else {
          replace('/login');
        }
      }
    }

    fetchData();
  }, [replace, orId]);

  const connectOauth = async (result) => {
    if (!result) {
      result = oauthResult;
    }
    setWorking(true);
    try {
      await axios.post(`/api/v1/oauth_results/${result.id}/connect`);
      setWorking(false);
      if (result.provider === 'gorgias') {
        replace('/integrations/gorgias')
      } else {
        replace('/home');
      }
      trackEvent('Oauth - Connect');
    } catch (e) {
      const statusCode = e?.response?.status;
      setWorking(false);
      trackEvent('Error: Oauth - Connect', { statusCode });
      showToast('Error authenticating, please try again or contact support@juniphq.com', true);
    }
  };

  const selectStore = async (selected, result) => {
    if (!result) {
      result = oauthResult;
    }
    setWorking(true);
    try {
      await axios.post(`/api/v1/stores/${selected}/scope`);
      connectOauth(result);
      trackEvent('Oauth - Select store');
    } catch (e) {
      showToast('Error selecting store, please try again', true);
      setWorking(false);
      trackEvent('Error: Oauth - Select store', { statusCode: e?.response?.status });
    }
  };

  const selectOrg = async (selected, org, skipScope = false, result) => {
    if (!result) {
      result = oauthResult;
    }
    setWorking(true);
    try {
      if (!skipScope) {
        await axios.post(`/api/v1/organizations/${selected}/scope`);
      }

      // Connecting integration, check if plan allows it
      if (result?.app?.store_provider === false && result?.app?.context !== 'organization') {
        if (org?.plan_feature_set.pf_integrations === false) {
          setWorking(false);
          push(`/oauth/upgrade_plan${window.location.search}`)
          trackEvent('Oauth - Upgrade required');
          return;
        } else {
          if (!org.stores?.length) {
            setWorking(false);
            push(`/oauth/no_stores${window.location.search}`)
            trackEvent('Oauth - No stores on organization');
            return;
          } else {
            const scopedStore = org.stores?.find(s => s.scoped === true);
            if (scopedStore) {
              selectStore(scopedStore.id, result);
              return;
            }
            setStores(org.stores);
            setWorking(false);
            push(`/oauth/select_store${window.location.search}`);
            return;
          }
        }
      }

      // Connecting a new store to org, check if allowed
      if (result?.app?.store_provider === true && !result.organization) {
        if (org?.stores?.length >= org?.plan_feature_set?.pf_store_limit) {
          window?.localStorage?.removeItem('redirectToOnboarding');
          setWorking(false);
          push(`/oauth/upgrade${window.location.search}`)
          trackEvent('Oauth - Upgrade required');
          return;
        }
      }

      connectOauth(result);
      trackEvent('Oauth - Select organization');
    } catch (e) {
      showToast('Error selecting organization, please try again', true);
      setWorking(false);
      trackEvent('Error: Oauth - Select organization', { statusCode: e?.response?.status });
    }
  };

  const createOrg = async (name, result) => {
    if (!result) {
      result = oauthResult;
    }

    setWorking(true);
    try {
      const response = await axios.post(
        '/api/v1/organizations',
        {
          organization: {
            name,
            referrer: result.provider
          }
        }
      );

      const organization = response.data.organization;
      selectOrg(organization.id, organization, true, result);
      trackEvent('Oauth - Create organization');
    } catch (e) {
      showToast('Error creating organization, please try again', true);
      setWorking(false);
      trackEvent('Error: Oauth - Create organization', { statusCode: e?.response?.status });
    }
  };

  const selectUser = async (user) => {
    setUser(user);
    setMe(user);
    setWorking(true);
    try {
      const resultResponse = await axios.get(`/api/v1/oauth_results/${orId}`, {
        params: {
          include: 'organization,app'
        }
      });

      const result = resultResponse.data.oauth_result;
      setOauthResult(result);

      const orgResponse = await axios.get(`/api/v1/organizations`, {
        params: {
          include: 'plan_feature_set,stores',
          'page[size]': 100,
        }
      });

      const orgs = orgResponse.data.organizations;
      setOrganizations(orgs);
      setOrgMeta(orgResponse?.data?.meta);

      if (result?.requires_onboarding === true) {
        // Redirect to onboarding after
        window?.localStorage?.setItem('redirectToOnboarding', true);
      } else {
        // Don't redirect to onboarding
        window?.localStorage?.removeItem('redirectToOnboarding');
      }

      // If oauth result already belongs to org
      if (result.organization?.id) {
        const matchingOrg = orgs.find(o => o.id === result.organization.id);
        if (!matchingOrg) {
          replace(`/oauth/permission_denied${window.location.search}`);
          setWorking(false);
          return;
        } else {
          // Scope to org and continue home
          // FUTURE: check if user connecting new store and allow them to pick org
          selectOrg(result.organization.id, matchingOrg, false, result);
          return;
        }
      }

      // Auto select org if initiated oauth from Junip
      const scopedOrg = orgs.find(o => o.scoped === true);
      if (scopedOrg) {
        selectOrg(scopedOrg.id, scopedOrg, true, result);
        return;
      }

      // If user belongs to at least one org, get them to select which one to connect to
      if (orgs.length) {
        push(`/oauth/select_organization${window.location.search}`);
        setWorking(false);
        return;
      }

      // If user belongs to no orgs and org name provided in oauthResult, auto create org
      if (result.account_name) {
        createOrg(result.account_name, result);
        return;
      }

      // Otherwise get user to create a new org
      push(`/oauth/create_organization${window.location.search}`)
      setWorking(false);
    } catch (e) {
      setWorking(false);
      if (e?.response?.status === 404) {
        push(`/oauth/expired${window.location.search}`)
      } else {
        push('/oauth/failure');
      }
    }
  };

  return (
    <>
      <LoadingCover loading={loading} showSpinner={true} />
      <LoginWrapper showExplainer={location.pathname === '/oauth/create_account'}>
        <Switch>
          <AuthorizedRoute exact path={`${match.path}/continue`}>
            <Continue orNew={orNew} currentUser={me} working={working} selectUser={selectUser} />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/create_account`}>
            <CreateAccount working={working} selectUser={selectUser} showToast={showToast} push={push} />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/sign_in`}>
            <SignIn orNew={orNew} working={working} selectUser={selectUser} showToast={showToast} email={location?.state?.email || undefined} />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/create_organization`}>
            <CreateOrg working={working} createOrg={createOrg} />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/select_organization`}>
            <SelectOrg working={working} selectOrg={selectOrg} organizations={organizations} orgMeta={orgMeta} />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/select_store`}>
            <SelectStore working={working} selectStore={selectStore} stores={stores} />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/failure`}>
            <Failure />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/permission_denied`}>
            <PermissionDenied push={push} organization={oauthResult?.organization} />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/no_stores`}>
            <NoStores replace={replace} />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/upgrade`}>
            <UpgradePlan
              title="Upgrade to connect a store"
              body="You need to upgrade your plan in order to connect another store to this organization"
            />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/upgrade_plan`}>
            <UpgradePlan
              title="Upgrade to install integrations"
              body="You need to upgrade your plan to install this app"
            />
          </AuthorizedRoute>
          <AuthorizedRoute exact path={`${match.path}/expired`}>
            <Expired push={push} />
          </AuthorizedRoute>
        </Switch>
      </LoginWrapper>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(Oauth);
