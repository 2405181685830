import React, {Fragment, useState} from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import { fetchInvitations } from 'redux/invitations';
import axios from 'axios';
import {
  Avatar,
  Badge,
  Button,
  Tooltip
} from '@shopify/polaris';

function TeamMemberInvitation({invitation, isOwner, showToast, fetchInvitations}) {
  const [loading, setLoading] = useState(false);

  const cancelInvitation = async () => {
    setLoading(true);
    try {
      await axios.post(`/api/v1/invitations/${invitation.id}/cancel`);
      setLoading(false);
      showToast('Invitation cancelled');
      fetchInvitations({ state: 'pending' });
      trackEvent('Team settings - Cancel invitation');
    } catch(e) {
      setLoading(false);
      showToast('Error cancelling invitation, please try again', true);
      trackEvent('Error: Team settings - Cancel invitation', {statusCode: e?.response?.status});
    }
  };

  return (
    <Fragment key={invitation.id}>
      <div className="team-grid-item">
        <div className="d-flex align-items-center">
          <Avatar size="md" customer={false} name={invitation?.email} initials={invitation?.email.charAt(0).toUpperCase()} />
          <div className="ml-2">{invitation?.email}</div>
        </div>
      </div>
      <div className="team-grid-item status">
        <Badge
          tone={invitation.state === 'pending' ? 'info' : 'default'}
        >
          {invitation.state}
        </Badge>
      </div>
      <div className="team-grid-item">
        {invitation.state === 'pending' ? (
          <>
            {isOwner ? (
              <Button loading={loading} onClick={cancelInvitation} variant="plain">Cancel invitation</Button>
            ) : (
              <Tooltip content="Only owners can cancel invitations">
                <Button disabled variant="plain">Cancel invitation</Button>
              </Tooltip>
            )}
          </>
        ) : <>-</>}
      </div>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchInvitations: (params) => dispatch(fetchInvitations(params))
});

export default connect(
  null,
  mapDispatchToProps
)(TeamMemberInvitation);
