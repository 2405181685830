import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Icon,
  LegacyCard,
  LegacyStack,
  Text,
  Banner,
  Tooltip
} from '@shopify/polaris'
import {
  TrendIndicator
} from '@shopify/polaris-viz'
import moment from 'moment';
import useLoadAnalyticsDb from 'utils/useLoadAnalyticsDb';
import AnalyticsDatePicker from 'components/AnalyticsDatePicker';
import styles from 'styles/components/TemplateAnalytics.module.scss';
import { calcPercentChange } from 'utils/AnalyticsUtils'
import { InfoIcon } from "@shopify/polaris-icons";

export default function TemplateAnalytics({ messageTypeId }) {
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState(null);
  const [selected, setSelected] = useState('7days');
  const [sent, setSent] = useState(0);
  const [opened, setOpened] = useState(0);
  const [clicked, setClicked] = useState(0);
  const [bounced, setBounced] = useState(0);
  const [sentTrend, setSentTrend] = useState(0);
  const [openedTrend, setOpenedTrend] = useState(0);
  const [clickedTrend, setClickedTrend] = useState(0);
  const [bouncedTrend, setBouncedTrend] = useState(0);
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(7, 'day').toDate(),
    end: moment().subtract(1, 'day').toDate()
  });
  const handleSelectedDates = useCallback((value) => {
    setLoading(true);
    setSelectedDates(value);
    setLoading(false);
  }, []);
  const period = useMemo(() => {
    return moment(selectedDates.end).utc().diff(moment(selectedDates.start).utc(), 'day') + 1;
  }, [selectedDates]);

  const { db } = useLoadAnalyticsDb(setLoading);

  const CURRENT_PERIOD = `date >= '${moment(selectedDates.start).utc().format('YYYY-MM-DD')}' AND date <= '${moment(selectedDates.end).utc().format('YYYY-MM-DD')}'`;
  const PREVIOUS_PERIOD = `date >= '${moment(selectedDates.start).utc().subtract(period, 'day').format('YYYY-MM-DD')}' AND date <= '${moment(selectedDates.end).utc().subtract(period, 'day').format('YYYY-MM-DD')}'`;

  useEffect(() => {
    if (db) {
      if (!templateName) {
        // need to query the prefix maps table to get the template name
        const templateNameQuery = `SELECT message_prefix FROM analytics_message_prefix_maps WHERE message_type_id = ${messageTypeId};`;
        setTemplateName(db.exec(templateNameQuery)[0]?.values[0][0]);
      } else {
        const formatQuery = ((type, dateFilter) => {
          return `SELECT SUM(${templateName}_messages_${type}) FROM analytics_daily_message_stats WHERE ${dateFilter};`;
        });

        // current period queries
        const sentQuery = formatQuery('sent', CURRENT_PERIOD);
        const openedQuery = formatQuery('opened', CURRENT_PERIOD);
        const clickedQuery = formatQuery('clicked', CURRENT_PERIOD);
        const bouncedQuery = formatQuery('bounced', CURRENT_PERIOD);

        // previous period queries
        const previousSentQuery = formatQuery('sent', PREVIOUS_PERIOD);
        const previousOpenedQuery = formatQuery('opened', PREVIOUS_PERIOD);
        const previousClickedQuery = formatQuery('clicked', PREVIOUS_PERIOD);
        const previousBouncedQuery = formatQuery('bounced', PREVIOUS_PERIOD);

        // execute queries
        const currSent = db.exec(sentQuery)[0]?.values[0][0] || 0;
        setSent(currSent);
        const currOpened = db.exec(openedQuery)[0]?.values[0][0] || 0;
        setOpened(currOpened);
        const currClicked = db.exec(clickedQuery)[0]?.values[0][0] || 0;
        setClicked(currClicked);
        const currBounced = db.exec(bouncedQuery)[0]?.values[0][0] || 0;
        setBounced(currBounced);

        const previousSent = db.exec(previousSentQuery)[0]?.values[0][0] || 0;
        const previousOpened = db.exec(previousOpenedQuery)[0]?.values[0][0] || 0;
        const previousClicked = db.exec(previousClickedQuery)[0]?.values[0][0] || 0;
        const previousBounced = db.exec(previousBouncedQuery)[0]?.values[0][0] || 0;

        setSentTrend(calcPercentChange(currSent, previousSent));
        setOpenedTrend(calcPercentChange(currOpened, previousOpened));
        setClickedTrend(calcPercentChange(currClicked, previousClicked));
        setBouncedTrend(calcPercentChange(currBounced, previousBounced));
      }
    }
  }, [CURRENT_PERIOD, PREVIOUS_PERIOD, db, messageTypeId, setLoading, templateName]
  );

  const datePicker = (
    <LegacyStack alignment="center">
      <AnalyticsDatePicker
        selectedDates={selectedDates}
        setSelectedDates={handleSelectedDates}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
        disableDatesBefore={new Date('2023-10-01')}
      />
      <Text variant="bodyMd" tone="subdued">{`${moment(selectedDates.start).utc().format('MMMM DD, YYYY')}`} to {`${moment(selectedDates.end).utc().format('MMMM DD, YYYY')}`}</Text>
      <Tooltip content="Historical data is unavailable prior to October 13, 2023.">
        <Icon source={InfoIcon} tone="subdued" />
      </Tooltip>
    </LegacyStack>
  )

  function performanceCard(title, value, trend, trendCondition = (trend > 0)) {
    return (
      <LegacyCard sectioned>
        <Text variant="bodySm" fontWeight="medium">{title}</Text>
        <LegacyStack alignment="center">
          <LegacyStack.Item fill>
            <Text variant="headingXl">{value}</Text>
          </LegacyStack.Item>
          <Tooltip content='Compared with previous period'>
            <LegacyStack.Item>
              {trend !== 0 &&
                <TrendIndicator
                  direction={trend > 0 ? 'upward' : 'downward'}
                  trend={trendCondition ? 'positive' : 'negative'}
                  value={Math.abs(trend?.toFixed(2)) + '%'}
                />
              }
            </LegacyStack.Item>
          </Tooltip>
        </LegacyStack>
      </LegacyCard>
    )
  };

  return (
    <div className={styles.performance}>
      <Text variant="headingMd">Performance</Text>
      {datePicker}
      <Banner tone='info'>Please note that data prior to October 13, 2023 is not available.</Banner>
      <LegacyStack distribution="fillEvenly">
        {performanceCard('Sent', sent, sentTrend)}
        {performanceCard('Opened', opened, openedTrend)}
        {performanceCard('Clicked', clicked, clickedTrend)}
        {performanceCard('Bounced', bounced, bouncedTrend, bouncedTrend < 0)}
      </LegacyStack >
    </div >
  );
};
