import React, { useState, useEffect, useCallback  } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { setFormDirty } from 'redux/settings';
import {
  fetchDisplaySettings,
  saveDisplaySettings
} from 'redux/displaySettings';
import { savingChanges } from 'redux/settings';
import {
  Page,
  Link,
  LegacyCard,
  Layout,
  Button,
  Checkbox,
  TextContainer,
  ButtonGroup,
  Text,
} from "@shopify/polaris";

import LoadingCardWrapper from 'components/LoadingCardWrapper';
import ProductFilter from 'components/ProductFilter';
import BrandingFooterHelp from 'components/BrandingFooterHelp';
import ColorPicker from 'components/ColorPicker';

function Preferences(props) {
  const {
    fetchDisplaySettings,
    setFormDirty,
    saveDisplaySettings
  } = props;
  const data = props.displaySettings.data || null;
  const settings = props.settings;

  const [inputs, setInputs] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [productFilter, setProductFilter] = useState(null);

  useEffect(() => {
    return () => {
      setFormDirty(false);
    }
  }, [setFormDirty]);

  useEffect(() => {
    if (!props.displaySettings.data) {
      fetchDisplaySettings();
    }
  }, [fetchDisplaySettings, props.displaySettings.data]);


  useEffect(() => {
    if (data) {
      setInputs(data);
    }
  }, [data]);

  useEffect(() => {
    if (settings.discardChanges) {
      if (data) {
        setInputs(data);
        setIsDirty(false);
        trackEvent('Forms - Discard changes')
      }
    }
  }, [data, settings.discardChanges]);

  useEffect(() => {
    if (settings.saving) {
      if (!isSaving) {
        saveDisplaySettings(data.id, inputs);
        setIsSaving(true);
        trackEvent('Forms - Save changes');
      }
    } else {
      if (isSaving) {
        setIsSaving(false);
      }
    }
  }, [data, inputs, isSaving, saveDisplaySettings, settings.saving]);

  const checkIsDirty = useCallback(() => {
    if (!data || typeof (data) !== 'object') {
      return;
    }
    const propertyNames = Object.getOwnPropertyNames(inputs);
    let dirty = false;
    for (const name of propertyNames) {
      if (inputs[name] !== data[name]) {
        dirty = true;
        break;
      }
    }

    if (dirty && !isDirty) {
      setIsDirty(true);
      if (!settings.isDirty) {
        setFormDirty(true);
      }
    } else if (!dirty && isDirty) {
      setIsDirty(false);
      if (settings.isDirty) {
        setFormDirty(false);
      }
    }
  }, [data, inputs, isDirty, setFormDirty, settings.isDirty]);

  useEffect(() => {
    checkIsDirty()
  }, [checkIsDirty]);

  const handleInputChange = (field) => {
    return (value) => {
      setInputs(inputs => ({ ...inputs, [field]: value }));
    };
  };

  const openFormPreview = (type) => {
    const storeKey = props.displaySettings.data.store.key;
    window.open(`${process.env.REACT_APP_FORMS_URL}/review/${type}?preview_mode=true&store_key=${storeKey}`, '_blank');
  };

  const generateProductReviewLink = () => {
    return `${process.env.REACT_APP_FORMS_URL}/review/onsite/product?product_id=${productFilter?.id}&store_key=${props.displaySettings?.data?.store?.key}&preview_mode=true`;
  };

  return (
    <Page
      title="Preferences"
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Settings"
          description="Configure your review submission experience"
        >
          <LoadingCardWrapper loading={props.displaySettings.loading}>
            <TextContainer>
              <Checkbox
                label="Enable photo & video reviews"
                checked={inputs.review_media_enabled}
                onChange={handleInputChange('review_media_enabled')}
                disabled={settings.saving}
                helpText="Enable this to allow customers to submit photos and videos with their product reviews"
              />
              <Checkbox
                label="Ask customer to leave a store review"
                checked={inputs.request_store_review_action_enabled}
                onChange={handleInputChange('request_store_review_action_enabled')}
                disabled={settings.saving}
                helpText="Enabling this option will present a link for your customers to leave a store review after they finish leaving their product reviews"
              />
              <div>
                <p className="mb-2">Background tint color</p>
                <ColorPicker colorKey="background_tint_color" colorValue={inputs.background_tint_color} handleInputChange={handleInputChange} />
                <p className="mt-2"><Text variant="bodyMd" as="span" tone="subdued">A subtle tint color that will be applied to the background of submission experience</Text></p>
              </div>
            </TextContainer>
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Preview"
          description="Preview your customers review experience"
        >
          <LoadingCardWrapper loading={props.displaySettings.loading} sectioned={false}>
            <LegacyCard.Section>
              <TextContainer spacing="tight">
                <p>Preview the Junip review submission experience for a specific product. Select a product and click preview</p>
                <ButtonGroup>
                  <ProductFilter productFilter={productFilter} setProductFilter={setProductFilter} isFilter={false} />
                  <Button

                    disabled={!productFilter?.id}
                    onClick={() => trackEvent('Forms - Preview')}
                    url={generateProductReviewLink()}
                    variant="primary">
                    Preview
                  </Button>
                </ButtonGroup>
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section title="Generic preview" subdued>
              <TextContainer>
                <p>Preview the review submission experience using sample data</p>
                <p>
                  <Link external="true" onClick={() => { openFormPreview('product'); trackEvent('Forms - Generic product review preview') }}>
                    Generic product review preview
                  </Link>
                </p>
                <p>
                  <Link external="true" onClick={() => { openFormPreview('store'); trackEvent('Forms - Store review preview') }}>
                    Store review preview
                  </Link>
                </p>
              </TextContainer>
            </LegacyCard.Section>
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
      </Layout>
      <BrandingFooterHelp />
    </Page>
  );
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  displaySettings: state.displaySettings
});

const mapDispatchToProps = (dispatch) => ({
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  saveDisplaySettings: (id, settings) => dispatch(saveDisplaySettings(id, settings)),
  setFormDirty: (dirty) => dispatch(setFormDirty(dirty)),
  savingChanges: () => dispatch(savingChanges())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preferences);
