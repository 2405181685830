import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { fetchDisplaySettings } from 'redux/displaySettings';
import { showToast } from 'redux/toast';
import {
  Layout,
  Page,
  Banner,
  Link,
  TextField,
  Button,
  ButtonGroup,
  LegacyCard,
  TextContainer
} from '@shopify/polaris';
import ColorSchemeWrapper from 'components/ColorSchemeWrapper';
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import ProductFilter from 'components/ProductFilter';
import QRCode from 'qrcode';
import styles from 'styles/routes/ReviewLinks.module.scss';
import { SaveIcon, CodeIcon } from "@shopify/polaris-icons";
import sticker from 'assets/images/sticker.svg';

function ReviewLinks({ displaySettings, fetchDisplaySettings, showToast }) {
  const [productFilter, setProductFilter] = useState(null);

  useEffect(() => {
    fetchDisplaySettings();
  }, [fetchDisplaySettings]);

  const formsPortalUrl = `${process.env.REACT_APP_FORMS_URL}/${displaySettings?.data?.store?.slug}`;

  const leaveReviewUrl = (withUtmParams = false) => {
    const UTM_PARAMS = {
      utm_source: 'junip',
      utm_medium: 'qr_code',
      utm_campaign: displaySettings?.data?.store?.id
    };

    if (withUtmParams) {
      return `${formsPortalUrl}?${new URLSearchParams(UTM_PARAMS).toString()}`;
    }

    return `${formsPortalUrl}`;
  };

  const editReviewUrl = () => {
    return `${formsPortalUrl}#edit`;
  };

  const generateProductReviewLink = () => {
    return `${process.env.REACT_APP_FORMS_URL}/review/onsite/product?product_id=${productFilter?.id}&store_key=${displaySettings?.data?.store?.key}`;
  };

  const copy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      showToast('Link copied');
      trackEvent('Manual Review Links - Copy link');
    } catch (e) {
      // error
    }
  };

  return (
    <Page
      title="Forms"
      subtitle="A dedicated place for customers to review past purchases and modify previous reviews."
    >
      <Layout>
        <Layout.Section>
          <Banner>
            <p>Manual review links should be used to <b>complement</b> your existing post purchase request flows, not replace them</p>
          </Banner>
        </Layout.Section>
        <Layout.AnnotatedSection
          title="Links"
          description="Use links wherever customers might want to review - such as support chat, account pages & packaging."
        >
          <LoadingCardWrapper
            loading={displaySettings.loading}
            title="Leave a review"
            sectioned={false}
          >
            <LegacyCard.Section>
              <TextContainer>
                <p>Customers can use this link to leave a review for any item in their purchase history.</p>
                <TextField
                  readOnly
                  value={leaveReviewUrl()}
                  onFocus={(e) => e.target.select()}
                  connectedRight={
                    <Button onClick={() => copy(leaveReviewUrl())} variant="primary" size="large">
                      Copy link
                    </Button>
                  }
                />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <TextContainer>
                <p>QR Code</p>
                <LeaveReviewQR value={leaveReviewUrl(true)} />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <TextContainer>
                <p>Embed button</p>
                <LeaveReviewEmbed value={leaveReviewUrl()} showToast={showToast} />
              </TextContainer>
            </LegacyCard.Section>
          </LoadingCardWrapper>
          <LoadingCardWrapper
            loading={displaySettings.loading}
            title="Edit reviews"
            sectioned={false}
          >
            <LegacyCard.Section>
              <TextContainer>
                <p>This link allows authors to view, edit, and delete any reviews they have previously left on your store.</p>
                <TextField
                  readOnly
                  value={editReviewUrl()}
                  onFocus={(e) => e.target.select()}
                  connectedRight={
                    <Button onClick={() => copy(editReviewUrl())} variant="primary" size="large">
                      Copy link
                    </Button>
                  }
                />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <TextContainer>
                <p>Embed button</p>
                <EditReviewEmbed value={editReviewUrl()} showToast={showToast} />
              </TextContainer>
            </LegacyCard.Section>
          </LoadingCardWrapper>
          <LoadingCardWrapper
            loading={displaySettings.loading}
            title="Manual product review link"
          >
            <TextContainer>
              <p>
                Use this link to manually request a review for a specific product. To learn more about manual review links check out this{' '}
                <Link external url="https://help.junip.co/en/articles/4607276-manual-product-review-link">help doc</Link>
              </p>
              <ButtonGroup>
                <ProductFilter productFilter={productFilter} setProductFilter={setProductFilter} isFilter={false} />
                <Button
                  disabled={!productFilter?.id}
                  onClick={() => copy(generateProductReviewLink())}
                  variant="primary">
                  Copy link
                </Button>
              </ButtonGroup>
            </TextContainer>
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}

function LeaveReviewQR({ value }) {
  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const stickerImg = new Image();
    stickerImg.src = sticker;
    stickerImg.onload = async () => {
      context.drawImage(stickerImg, 0, 0, canvas.width, canvas.height);

      const qrImg = new Image();
      qrImg.src = await QRCode.toDataURL(value, {
        margin: 0,
        width: 420
      });
      qrImg.onload = () => {
        context.drawImage(qrImg, 90, 55, 420, 420);
      };
    };
  }, [value]);

  const saveQR = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      const link = document.createElement('a');
      link.download = 'junip-review-qr-sticker.png';
      link.href = URL.createObjectURL(blob);
      link.click();
    });
    trackEvent('Manual Review Links - Download QR Image');
  };

  return <>
    <div className={`${styles.container} ${styles.canvasContainer}`}>
      <canvas className={styles.canvas} ref={canvasRef} height={695} width={600} />
    </div>
    <p>Good for products & packaging (inserts or stickers!)</p>
    <Button
      icon={SaveIcon}
      onClick={saveQR}
    >
      Download image
    </Button>
  </>;
}

function LeaveReviewEmbed({ value, showToast }) {
  const html = `<iframe style="border: 0;overflow: hidden;" height=44 width=200 srcdoc="<html><style>body{display:flex;align-items:center;justify-content:center;overflow:hidden;margin:0;height:100vh;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif}.junipBtn{display:inline-block;cursor:pointer;height:100%;width:100%;text-transform:uppercase;color:#fff;background-color:#000;font-weight:600;border:1px solid #000;border-radius:4px;letter-spacing:1px;font-size:15px;padding:12px 18px;text-align:center;box-sizing:border-box;text-decoration:none}</style><body><a class=junipBtn href='${value}' target='_top'>Leave a review</a></body></html>"></iframe>`;

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(html);
      showToast('Embed code copied to clipboard');
      trackEvent('Manual Review Links - Copy leave a review embed code');
    } catch (e) {
      // error
    }
  };

  return <>
    <ColorSchemeWrapper colorScheme="light">
      <div className={styles.container}>
        <div className={styles.embedContainer} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </ColorSchemeWrapper>
    <p>Good for account page, tracking page, subscription portals</p>
    <Button
      icon={CodeIcon}
      onClick={copy}
    >
      Copy Embed Code
    </Button>
  </>;
}

function EditReviewEmbed({ value, showToast }) {
  const html = `<iframe style="border: 0;overflow: hidden;" height=44 width=200 srcdoc="<html><style>body{display:flex;align-items:center;justify-content:center;overflow:hidden;margin:0;height:100vh;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif}.junipBtn{display:inline-block;cursor:pointer;height:100%;width:100%;text-transform:uppercase;color:#202223;background-color:transparent;font-weight:600;border:1px solid #202223;border-radius:4px;letter-spacing:1px;font-size:15px;padding:12px 18px;text-align:center;box-sizing:border-box;text-decoration:none}</style><body><a class=junipBtn href='${value}' target='_top'>Edit my reviews</a></body></html>"></iframe>`;

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(html);
      showToast('Embed code copied to clipboard');
      trackEvent('Manual Review Links - Copy edit reviews embed code');
    } catch (e) {
      // error
    }
  };

  return <>
    <ColorSchemeWrapper colorScheme="light">
      <div className={styles.container}>
        <div className={styles.embedContainer} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </ColorSchemeWrapper>
    <Button
      icon={CodeIcon}
      onClick={copy}
    >
      Copy Embed Code
    </Button>
  </>;
}

const mapStateToProps = (state) => ({
  displaySettings: state.displaySettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  showToast: (message, error) => dispatch(showToast(message, error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewLinks);
