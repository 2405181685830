import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import { trackEvent } from 'utils/Mixpanel';
import {
  Page,
  LegacyCard,
  Thumbnail,
  TextContainer,
  TextField,
  EmptyState,
  Button,
  LegacyStack,
  Text,
} from "@shopify/polaris";
import ProductFilter from 'components/ProductFilter';
import UpgradeTrigger from 'components/UpgradeTrigger';
import ProductPickerModal from 'components/ProductPickerModal';

import noImage from 'assets/images/noImage.jpg';
import addProductSvg from 'assets/images/addProduct.svg';

function CreateGroup({ showToast, history }) {
  const queryParams = history.location.search;
  const params = new URLSearchParams(queryParams);

  const [bundleProduct, setBundleProduct] = useState(null);
  const [creating, setCreating] = useState(false);
  const [name, setName] = useState('');
  const [style] = useState(params.get('style') || 'family');
  const [showProductPicker, setShowProductPicker] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (bundleProduct?.id) {
      setProducts(prev => prev.filter((item) => item.id !== bundleProduct.id));
    }
  }, [bundleProduct]);

  const createGroup = async () => {
    try {
      setCreating(true);
      const memberships = products.map((product) => {
        return {
          product_id: product.id,
          relationship: style === 'family' ? 'owner' : 'contributor',
        };
      });

      if (style === 'bundle') {
        memberships.push({
          product_id: bundleProduct.id,
          relationship: 'owner',
        });
      }

      const response = await axios.post('/api/v1/product_groups', {
        product_group: {
          name: style === 'family' ? name : bundleProduct.title,
          style: style,
          memberships
        }
      });
      showToast('Product group created');
      trackEvent(`Product groups - Create ${style} group`);
      history.push(`/products/groups/${response.data.product_group.id}`);
    } catch (e) {
      setCreating(false);
      showToast('Error creating group, please try again', true);
      trackEvent('Error: Product groups - Create group', { statusCode: e?.response?.status });
    }
  };

  const isInvalid = () => {
    if (style === 'family') {
      return !name || products.length === 0;
    } else {
      return !bundleProduct || products.length === 0;
    }
  };

  return (
    <Page
      title={
        <>
          Product <span style={{ textTransform: 'capitalize' }}>{style}</span>
        </>
      }
      backAction={{ content: 'Back to product groups', url: '/products/groups' }}
      primaryAction={{
        content: 'Create',
        onAction: createGroup,
        loading: creating,
        disabled: creating || isInvalid(),
      }}
    >
      <UpgradeTrigger
        feature={'pf_product_aliasing'}
        title={'Upgrade to use product groups'}
        description={'You need to upgrade your plan to use product groups'}
        cardWrapper={true}
      >
        {style === 'family' ? (
          <LegacyCard sectioned title="Group name">
            <TextField
              value={name}
              onChange={setName}
              placeholder="Name this group"
            />
          </LegacyCard>
        ) : (
          <LegacyCard sectioned title="Bundle">
            <TextContainer spacing="tight">
              <ProductFilter
                productFilter={bundleProduct}
                setProductFilter={setBundleProduct}
                fullWidth
                disabled={creating}
                isFilter={false}
                paramFilter={{ 'filter[without_group_owners]': true }}
              />
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">Select bundle from your product catalog.</Text>
              </p>
            </TextContainer>
          </LegacyCard>
        )}
        <LegacyCard
          title={`Products in this ${style}`}
          actions={products.length > 0 && [{
            content: 'Add products',
            onAction: () => setShowProductPicker(true),
            disabled: creating
          }]}>
          {products.length > 0 ? (
            <>
              {products.map((product) => (
                <LegacyCard.Section key={product.id}>
                  <LegacyStack alignment="center">
                    <LegacyStack.Item fill>
                      <LegacyStack alignment="center">
                        <Thumbnail
                          source={product?.image_url || noImage}
                          alt={product.title}
                          size="small"
                        />
                        <h3>
                          <Text variant="bodyMd" as="span" fontWeight="semibold">
                            {product.title}
                          </Text>
                        </h3>
                      </LegacyStack>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                      <Button


                        onClick={() => {
                          setProducts((prev) =>
                            prev.filter((p) => p.id !== product.id)
                          );
                        }}
                        variant="plain"
                        tone="critical">
                        Remove
                      </Button>
                    </LegacyStack.Item>
                  </LegacyStack>
                </LegacyCard.Section>
              ))}
            </>
          ) : (
            <EmptyState
              heading={`Add products to this ${style}`}
              action={{
                content: 'Add products',
                onAction: () => setShowProductPicker(true),
                disabled: creating,
              }}
              image={addProductSvg}
            >
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">Select products to add to this group.</Text>
              </p>
            </EmptyState>
          )}
        </LegacyCard>
      </UpgradeTrigger>
      <ProductPickerModal
        open={showProductPicker}
        close={() => setShowProductPicker(false)}
        submit={(products) => {
          setProducts(products);
        }}
        preSelected={products}
        preSelectedProduct={bundleProduct}
        filter={style === 'family' ? { 'filter[without_group_owners]': true } : null}
      />
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateGroup);
