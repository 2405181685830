import React from 'react';
import {
  Page
} from '@shopify/polaris';
import ProductsTable from 'components/ProductsTable';

export default function Products() {
  return (
    <Page title="Products">
      <ProductsTable />
    </Page>
  );
}
