import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { showToast } from 'redux/toast';
import useLastLocation from 'utils/useLastLocation';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import {
  Page,
  Layout,
  TextContainer,
  Button,
  Modal
} from '@shopify/polaris';
import { fetchInvitations } from 'redux/invitations';
import { fetchMemberships } from 'redux/memberships';
import LoadingContentWrapper from 'components/LoadingContentWrapper';
import TeamMembers from 'components/TeamMembers';
import InviteUser from 'components/InviteUser';
import UpgradeTrigger from 'components/UpgradeTrigger';

function TeamSettings(props) {
  const lastLocation = useLastLocation();
  const {
    fetchInvitations,
    fetchMemberships,
    user,
    invitations,
    memberships,
    showToast
  } = props;

  const [isOwner, setIsOwner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showInviteSupportModal, setShowInviteSupportModal] = useState(false);

  useEffect(() => {
    fetchInvitations({ state: "pending" });
  }, [fetchInvitations]);

  useEffect(() => {
    if (!memberships.data) {
      fetchMemberships();
    }
  }, [memberships.data, fetchMemberships]);

  useEffect(() => {
    if (memberships.data && user.data) {
      const ownerMemberShip = memberships.data.find(m => m.owner === true);
      if (ownerMemberShip?.user.id === user.data.id) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [memberships.data, user.data]);

  const inviteSupport = async () => {
    setLoading(true);
    try {
      await axios.post('/api/v1/invitations', {
        invitation: {
          email: 'support@juniphq.com'
        }
      });
      setLoading(false);
      fetchInvitations({ state: "pending" });
      showToast('User invitation sent');
      trackEvent('Team settings - Invite Junip support');
    } catch (e) {
      showToast('Error sending support invite, please try again', true);
      setLoading(false);
      trackEvent('Error: Team settings - Invite Junip support', { statusCode: e?.response?.status });
    } finally {
      setShowInviteSupportModal(false);
    }
  };

  const supportInvited = invitations.data?.find(i => i.email?.includes('support@juniphq.com')) ||
    memberships.data?.find(m => m.user.email?.includes('support@juniphq.com'));

  return (
    <Page
      title="Team settings"
      backAction={{ content: 'Settings', url: lastLocation || '/settings' }}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Team"
          description="Users invited to manage this organization"
        >
          <LoadingContentWrapper cards={1} loading={!user?.data?.email || invitations.loading || memberships.loading}>
            <TeamMembers
              memberships={memberships?.data}
              invitations={invitations?.data || []}
              isOwner={isOwner}
              user={user.data}
            />
          </LoadingContentWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Invite user"
          description={
            <TextContainer>
              <p>Users have permission to view and manage your Junip admin account. Only owners can invite users.</p>
              {!supportInvited && (
                <Button onClick={() => setShowInviteSupportModal(true)}>Invite Junip support</Button>
              )}
            </TextContainer>
          }
        >
          <LoadingContentWrapper cards={1} loading={!user?.data?.email || invitations.loading || memberships.loading}>
            <UpgradeTrigger
              feature={'pf_user_limit'}
              title={'Upgrade to invite more users'}
              limit={invitations?.data?.filter(i => !i.email?.includes('juniphq.com')).length + memberships.data?.length}
              description={'You need to upgrade your plan to invite more users to this organization'}
            >
              <InviteUser isOwner={isOwner} />
            </UpgradeTrigger>
          </LoadingContentWrapper>
        </Layout.AnnotatedSection>
      </Layout>
      <Modal
        open={showInviteSupportModal}
        onClose={() => setShowInviteSupportModal(false)}
        title="Invite Junip support"
        primaryAction={{
          content: 'Invite',
          onAction: inviteSupport,
          loading,
        }}
        secondaryActions={[{
          content: 'Cancel',
          disabled: loading,
          onAction: () => setShowInviteSupportModal(false)
        }]}
      >
        <Modal.Section>
          <p>Are you sure you want to invite Junip support (support@juniphq.com) to this organization?</p>
        </Modal.Section>
      </Modal>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  invitations: state.invitations,
  memberships: state.memberships
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvitations: (data) => dispatch(fetchInvitations(data)),
  fetchMemberships: () => dispatch(fetchMemberships()),
  showToast: (message, error) => dispatch(showToast(message, error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSettings);

