import { useState } from 'react';
import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  InlineGrid,
  Link,
  List,
  RangeSlider,
  Select,
  Text,
  TextField
} from "@shopify/polaris";

import { useSelector } from "react-redux";
import ProductReviewsWidgetWrapper from "components/widgets/ProductReviewsWidgetWrapper";
import StarRatingWidgetWrapper from "components/widgets/StarRatingWidgetWrapper";
import UgcGalleryWidgetWrapper from "components/widgets/UgcGalleryWidgetWrapper";
import CarouselWidgetWrapper from "components/widgets/CarouselWidgetWrapper";
import DedicatedReviewsPageWidgetWrapper from "components/widgets/DedicatedReviewsPageWidgetWrapper";
import CopyableSnippet, { CopyableMultiLineSnippet } from 'components/CopyableSnippet';
import InstallStepWrapper from 'components/InstallStepWrapper';
import ProductFilter from 'components/ProductFilter';

const HELP_DOC = 'https://help.junip.co/en/articles/4607115-custom-html-installation';
const REVIEWS_PAGE_HELP_DOC = 'https://help.junip.co/en/articles/4607115-custom-html-installation#h_0ae2e625f5';
const UGC_GALLERY_HELP_DOC = 'https://help.junip.co/en/articles/8441528-adding-a-ugc-gallery-to-your-storefront';
const CAROUSEL_HELP_DOC = 'https://help.junip.co/en/articles/7203177-add-the-homepage-reviews-carousel-to-your-homepage';

export default function HTMLInstallTab({ previewUrl }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const stores = useSelector((state) => state.stores);
  const activeStore = stores?.data?.find((store) => store.scoped);

  const junipScriptHTML = `<script type="text/javascript" async="true" src="https://scripts.juniphq.com/v1/junip_shopify.js"></script>`;
  const junipStoreKeyHTML = `<span class="junip-store-key" data-store-key="${activeStore?.key}></span>`;
  const productReviewHTML = `<span class="junip-product-review" data-product-id="${selectedProduct?.remote_id || 'ENTER PRODUCT ID'}"></span>`;
  const starRatingHTML = `<span class="junip-product-summary" data-product-id="${selectedProduct?.remote_id || 'ENTER PRODUCT ID'}"></span>`;

  return (
    <BlockStack gap="400">
      <Card>
        <Bleed marginBlockStart="400" marginInline="400">
          <Box background="bg-surface-secondary" padding="400">
            <Text variant="headingSm" as="h1">First steps</Text>
          </Box>
        </Bleed>
        <Box paddingBlockStart="400">
          <BlockStack gap="500">
            <BlockStack gap="200">
              <Text variant="bodyLg">1. Add the Junip script tag to your site</Text>
              <CopyableSnippet snippet={junipScriptHTML} />
            </BlockStack>
            <BlockStack gap="200">
              <Text variant="bodyLg">2. Add the Junip store key element to your site </Text>
              <CopyableSnippet snippet={junipStoreKeyHTML} />
            </BlockStack>
            <Text>
              For detailed instructions and customization options, read our{' '}
              <Link target="_blank" url={HELP_DOC}>HTML installation guide</Link>.
            </Text>
          </BlockStack>
        </Box>
      </Card>
      <ProductReviewsWidgetWrapper>
        <InstallStepWrapper>
          <BlockStack gap="300">
            <Text>To install this widget with HTML:</Text>
            <List type="number" gap="loose">
              <BlockStack gap="200">
                <List.Item>
                  <BlockStack gap="200">
                    <Text as="p">Select a product:</Text>
                    <ProductFilter
                      productFilter={selectedProduct}
                      setProductFilter={setSelectedProduct}
                      isFilter={false}
                      fullWidth={true}
                    />
                  </BlockStack>
                </List.Item>
                <List.Item>
                  <p className="mb-2">Copy the following code and paste it where you want the selected product’s reviews to display.</p>
                  <CopyableSnippet snippet={productReviewHTML} />
                </List.Item>
                <List.Item>
                  To see if the installation was successful, <Link url={previewUrl} target="_blank">preview with placeholder content.</Link>
                </List.Item>
              </BlockStack>
            </List>
            <Box>
              <Button
                variant="plain"
                external
                url={HELP_DOC}
              >
                Get detailed installation instructions
              </Button>
            </Box>
          </BlockStack>
        </InstallStepWrapper>
      </ProductReviewsWidgetWrapper>
      <StarRatingWidgetWrapper>
        <InstallStepWrapper>
          <BlockStack gap="300">
            <Text>To install this widget with HTML:</Text>
            <List type="number" gap="loose">
              <BlockStack gap="200">
                <List.Item>
                  <BlockStack gap="200">
                    <Text as="p">Select a product:</Text>
                    <ProductFilter
                      productFilter={selectedProduct}
                      setProductFilter={setSelectedProduct}
                      isFilter={false}
                      fullWidth={true}
                    />
                  </BlockStack>
                </List.Item>
                <List.Item>
                  <p className="mb-2">Copy the following code and paste it where you want the selected product’s average rating to display.</p>
                  <CopyableSnippet snippet={starRatingHTML} />
                </List.Item>
                <List.Item>
                  To see if the installation was successful, <Link url={previewUrl} target="_blank">preview with placeholder content.</Link>
                </List.Item>
              </BlockStack>
            </List>
            <Box>
              <Button
                variant="plain"
                external
                url={HELP_DOC}
              >
                Get detailed installation instructions
              </Button>
            </Box>
          </BlockStack>
        </InstallStepWrapper>
      </StarRatingWidgetWrapper>
      <DedicatedReviewsPageWidgetWrapper>
        <InstallStepWrapper>
          <DedicatedReviewPageSteps previewUrl={previewUrl} />
        </InstallStepWrapper>
      </DedicatedReviewsPageWidgetWrapper>
      <UgcGalleryWidgetWrapper>
        <InstallStepWrapper>
          <UgcGallerySteps previewUrl={previewUrl} />
        </InstallStepWrapper>
      </UgcGalleryWidgetWrapper>
      <CarouselWidgetWrapper>
        <InstallStepWrapper>
          <CarouselInstallSteps previewUrl={previewUrl} />
        </InstallStepWrapper>
      </CarouselWidgetWrapper>
    </BlockStack>
  );
}

function DedicatedReviewPageSteps({ previewUrl }) {
  const [layoutOption, setLayoutOption] = useState('list');
  const [reviewType, setReviewType] = useState('all');
  const [numberOfReviews, setNumberOfReviews] = useState(5);
  const [showSummary, setShowSummary] = useState(true);

  const layoutOptions = [
    { value: 'list', label: 'List' },
    { value: 'grid', label: 'Grid' },
  ];

  const reviewTypeOptions = [
    { value: 'all', label: 'All reviews' },
    { value: 'product_reviews' , label: 'Product reviews' },
    { value: 'store_reviews', label: 'Store reviews' },
  ];

  const htmlSnippet = `<span
  class="junip-review-section"
  data-layout="${layoutOption}"
  data-reviews-type="${reviewType}"
  data-show-summary="${showSummary}"
  data-reviews-count="${numberOfReviews}">
</span>`;

  return (
    <InlineGrid gap="800" columns={{ md: 1, lg: 2 }}>
      <BlockStack gap="400">
        <Text variant="headingSm">Options</Text>
        <Select
          label="Layout"
          options={layoutOptions}
          onChange={setLayoutOption}
          value={layoutOption}
        />
        <Select
          label="Review type"
          options={reviewTypeOptions}
          onChange={setReviewType}
          value={reviewType}
        />
        <RangeSlider
          label="Number of reviews loaded by default"
          min={3}
          max={20}
          value={numberOfReviews}
          onChange={setNumberOfReviews}
          suffix={<p style={{ minWidth: '24px', textAlign: 'right' }}>{numberOfReviews}</p>}
        />
        <Checkbox
          label="Show summary"
          helpText="Show average star rating and review count"
          checked={showSummary}
          onChange={setShowSummary}
        />
      </BlockStack>
      <CustomHTML
        description="Paste this code into a new page in your theme."
        snippet={htmlSnippet}
        helpDoc={REVIEWS_PAGE_HELP_DOC}
        previewUrl={previewUrl}
      />
    </InlineGrid>
  );
}

function UgcGallerySteps({ previewUrl }) {
  const [title, setTitle] = useState('');
  const [layoutStyle, setLayoutStyle] = useState('scroll');
  const [productFilter, setProductFilter] = useState(null);


  const layoutOptions = [
    { value: 'scroll', label: 'Scroll' },
    { value: 'tile', label: 'Tile' },
  ];

  const htmlSnippet = `<div
  class="junip-ugc-gallery"
  data-title="${title}"
  data-layout="${layoutStyle}"
  data-product-id="${productFilter?.remote_id || ''}">
</div>`;

  return (
    <InlineGrid gap="800" columns={{ md: 1, lg: 2 }}>
      <BlockStack gap="400">
        <Text variant="headingSm">Configure</Text>
        <TextField
          label="Title (optional)"
          value={title}
          onChange={setTitle}
        />
        <Select
          label="Layout style"
          options={layoutOptions}
          onChange={setLayoutStyle}
          value={layoutStyle}
        />
        <BlockStack gap="200">
          <Text>Product (optional)</Text>
          <ProductFilter
            productFilter={productFilter}
            setProductFilter={setProductFilter}
            isFilter={true}
            fullWidth={true}
            size="large"
          />
        </BlockStack>
      </BlockStack>
      <CustomHTML
        description="Add this code wherever you want the widget to appear."
        snippet={htmlSnippet}
        helpDoc={UGC_GALLERY_HELP_DOC}
        previewUrl={previewUrl}
      />
    </InlineGrid>
  );
}

function CarouselInstallSteps({ previewUrl }) {
  const [title, setTitle] = useState('');
  const [reviewsType, setReviewsType] = useState('product_reviews');
  const [showSummary, setShowSummary] = useState(true);
  const [productFilter, setProductFilter] = useState(null);

  const reviewsTypeOptions = [
    { value: 'product_reviews', label: 'Product reviews' },
    { value: 'store_reviews', label: 'Store reviews' },
  ];

  const htmlSnippet = `<div
  class="junip-review-carousel"
  data-title="${title}"
  data-reviews-type="${reviewsType}"
  data-product-id="${productFilter?.remote_id || ''}"
  data-show-summary="${showSummary}">
</div>`;

  return (
    <InlineGrid gap="800" columns={{ md: 1, lg: 2 }}>
      <BlockStack gap="400">
        <Text variant="headingSm">Configure</Text>
        <TextField
          label="Title (optional)"
          value={title}
          onChange={setTitle}
        />
        <Select
          label="Reviews type"
          options={reviewsTypeOptions}
          onChange={setReviewsType}
          value={reviewsType}
        />
        <BlockStack gap="200">
          <Text>Product (optional)</Text>
          <ProductFilter
            productFilter={productFilter}
            setProductFilter={setProductFilter}
            isFilter={true}
            fullWidth={true}
            size="large"
          />
        </BlockStack>
        <Checkbox
          label="Show summary"
          helpText="Show average star rating and review count just above the carousel"
          checked={showSummary}
          onChange={setShowSummary}
        />
      </BlockStack>
      <CustomHTML
        description="Add this code wherever you want the widget to appear."
        snippet={htmlSnippet}
        helpDoc={CAROUSEL_HELP_DOC}
        previewUrl={previewUrl}
      />
    </InlineGrid>
  );
}

function CustomHTML({ description, snippet, previewUrl, helpDoc }) {
  const snippetLines = snippet.split(/\r\n|\r|\n/).length;

  return (
    <BlockStack gap="400">
      <Text variant="headingSm">Custom HTML</Text>
      <Text>{description}</Text>
      <CopyableMultiLineSnippet
        snippet={snippet}
        secondaryAction={<Button variant="plain" external url={helpDoc}>View installation guide</Button>}
        multiline={snippetLines + 1}
      />
      <Text>
        To check if the installation was successful,{' '}
        <Link url={previewUrl} target="_blank">preview with placeholder content</Link>.
      </Text>
    </BlockStack>
  );
}
