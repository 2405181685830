import React from 'react';
import useLastLocation from 'utils/useLastLocation';
import { Page, Layout, LegacyCard, TextContainer, Text } from "@shopify/polaris";
import ReferralUrl from 'components/ReferralUrl';

export default function ReferralSettings() {
  const lastLocation = useLastLocation();

  return (
    <Page
      title="Referral settings"
      backAction={{ content: 'Settings', url: lastLocation || '/settings' }}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Junip referral URL"
          description="View and configure your unique Junip referral url"
        >
          <LegacyCard sectioned>
            <ReferralUrl />
          </LegacyCard>
          <LegacyCard title="General questions" sectioned>
            <TextContainer spacing="loose">
              <div>
                <div className="mb-2">
                  <Text variant="headingMd" as="h2">What are Junip referrals?</Text>
                </div>
                <p>Junip referrals are a way to help spread Junip and get rewarded when you do.</p>
              </div>
              <div>
                <div className="mb-2">
                  <Text variant="headingMd" as="h2">What is my Junip referral URL?</Text>
                </div>
                <p>Your Junip referral URL is a unique URL tied to your account. Share this URL with other merchants to give them a discount on Junip and get rewarded yourself.</p>
              </div>
              <div>
                <div className="mb-2">
                  <Text variant="headingMd" as="h2">What are the rewards?</Text>
                </div>
                <p>
                  You'll receive the full first months subscription of any referred brand as a discount towards your own Junip subscription. Newly referred brands will get 25% off their first 2 months when they signup using your URL
                </p>
              </div>
            </TextContainer>
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}
