import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import {
  LegacyStack,
  Spinner,
  Banner,
  DropZone,
  List
} from '@shopify/polaris';

import fileChecksum from 'utils/fileChecksum';

import 'styles/components/LogoDropZone.scss';

const FILE_SIZE_LIMIT = 10000000;

const LogoDropZone = ({logoUrl, handleInputChange, discardChanges, removeImage, setRemoveImage}) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;
  let source = logoUrl;
  if (files.length > 0) {
    source = window.URL.createObjectURL(files[0]);
  }

  useEffect(() => {
    if (discardChanges) {
      setFiles([]);
    }
  }, [discardChanges]);

  useEffect(() => {
    if (removeImage) {
      setFiles([]);
      setRemoveImage(false);
    }
  }, [removeImage, setRemoveImage]);

  const uploadFile = useCallback(async (file) => {
    const { checksum } = await fileChecksum(file);
    const response = await axios.post('/api/active_storage/direct_uploads', {
      blob: {
        'filename': file.name,
        'content_type': file.type,
        'byte_size': file.size,
        'checksum': checksum
      }
    });

    await axios.put(response.data.direct_upload.url, file.slice(), {
      headers: response.data.direct_upload.headers,
      withCredentials: false
    });

    handleInputChange('logo')(response.data.signed_id);
    setLoading(false);
  }, [handleInputChange]);

  const handleAcceptedDrop = useCallback(
    (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];
      if (acceptedFile.size > FILE_SIZE_LIMIT) {
        setRejectedFiles([acceptedFile]);
      } else {
        setFiles([acceptedFile]);
        setRejectedFiles([]);
        setLoading(true);
        uploadFile(acceptedFile);
      }
    },
    [uploadFile]
  );

  const handleRejectedDrop = useCallback(
    (rejectedFiles) => {
      setRejectedFiles([rejectedFiles[0]]);
    },
    []
  );

  const uploadedFiles = source && (
    <img className="logo" src={source} alt="logo"/>
  );

  const fileUpload = !source && <DropZone.FileUpload />;

  const errorMessage = hasError && (
    <Banner
      title="The following images couldn’t be uploaded:"
      tone="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .jpg/.jpeg or .png and less than 10MB.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <LegacyStack vertical>
      {errorMessage}
      { loading &&
        <div className="dropzone-spinner">
          <Spinner accessibilityLabel="Uploading logo" size="large" color="primary" />
        </div>
      }
      { !loading &&
        <DropZone
          allowMultiple={false}
          accept="image/png,image/jpg,image/jpeg"
          type="image"
          onDropAccepted={handleAcceptedDrop}
          onDropRejected={handleRejectedDrop}
        >
          {uploadedFiles}
          {fileUpload}
        </DropZone>
      }
    </LegacyStack>
  );
};

export default LogoDropZone;
