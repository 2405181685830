import React, { useState, useCallback, useEffect } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import moment from 'moment';
import { LegacyCard, ProgressBar, Collapsible, Button, ButtonGroup, LegacyStack, Text } from "@shopify/polaris";
import OnboardingStep from 'components/OnboardingStep';

const DEFAULT_PRIMARY_COLOR = '#42799f';

export default function OnboardingGuide({ user, total_reviews, displaySettings, flows, billingSubscriptions }) {
  const checkSolicitationComplete = () => {
    const request = flows?.data?.find((f) => f.flow_type === 'request' && (f.event_topic?.action !== 'targeted-by-campaign' && f.event_topic?.action !== 'request-by-campaign'));
    return request?.disabled === false;
  };

  let steps = [
    {
      type: 'sign_up',
      completed: user?.verified_email,
      expanded: false,
      title: 'Verify your email',
      description: 'Confirm your email via the link that was sent to your inbox. You can edit your email or password anytime in User Settings.',
      action: (
        <Button

          url="/settings/user"
          onClick={() => trackEvent('Onboarding - Visit user settings')}
          variant="primary">Visit user settings</Button>
      )
    },
    {
      type: 'add_branding',
      completed: displaySettings?.data?.logo_url !== null || displaySettings?.data?.primary_color !== DEFAULT_PRIMARY_COLOR,
      expanded: false,
      title: 'Add your branding',
      description: 'Upload a logo and customize the colours used in your forms and emails to fit your brand. ',
      action: (
        <Button
          url="/settings/branding"
          onClick={() => trackEvent('Onboarding - Visit branding')}
          variant="primary">Visit branding</Button>
      )
    },
    {
      type: 'add_snippets',
      completed: displaySettings.data?.store?.snippets_installed || displaySettings.data?.store?.supports_shopify_theme_extensions,
      expanded: false,
      title: 'Add Junip to your site',
      description: 'Visit the on-site section to add the snippets to your site or take a more custom approach and manually add the snippets',
      action: (
        <ButtonGroup>
          <Button
            url="/on_site"
            onClick={() => trackEvent('Onboarding - Visit onsite')}
            variant="primary">Visit on-site</Button>
          <Button
            url="https://help.junip.co/en/articles/4607127-getting-started-guide"
            external

            onClick={() => trackEvent('Onboarding - Learn More')}
            variant="plain">
            Learn more
          </Button>
        </ButtonGroup>
      )
    },
    {
      type: 'enable_onsite',
      completed: displaySettings.data?.onsite_enabled,
      expanded: false,
      title: 'Enable your on-site widgets',
      description: 'Preview the widgets on your site, then enable the live experience so your customers can start engaging with reviews.',
      action: (
        <ButtonGroup>
          <Button

            url="/on_site"
            onClick={() => trackEvent('Onboarding - Enable on-site widget')}
            variant="primary">Visit on-site</Button>
          <Button
            url="https://help.junip.co/en/articles/4607260-displaying-reviews"
            external

            onClick={() => trackEvent('Onboarding - Learn More')}
            variant="plain">
            Learn more
          </Button>
        </ButtonGroup>
      )
    },
    {
      type: 'enable_solicitation',
      completed: checkSolicitationComplete(),
      expanded: false,
      title: 'Start requesting reviews from customers ',
      description: 'Flows allow you to automate communications with your customers. To start requesting reviews from your customers when they make a new purchase, enable your review request flow.',
      action: (
        <ButtonGroup>
          <Button

            url="/flows"
            onClick={() => trackEvent('Onboarding - Manage flows')}
            variant="primary">Visit flows</Button>
          <Button
            url="https://help.junip.co/en/articles/5902201-the-basics-of-flows"
            external

            onClick={() => trackEvent('Onboarding - Learn More')}
            variant="plain">
            Learn more
          </Button>
        </ButtonGroup>
      )
    },
    {
      type: 'enable_billing',
      completed: billingSubscriptions.data?.findIndex(b => b.active && (b.provider !== 'junip' || b.auto_renew)) !== -1,
      expanded: false,
      title: 'Select a plan that suits your needs',
      description: 'Choose a plan that suits your business. Try any paid plan free for 14 days to make sure it meets your needs.',
      action: (
        <Button

          url="/settings/billing"
          onClick={() => trackEvent('Onboarding - View plans')}
          variant="primary">View plans</Button>
      )
    }
  ];
  const firstIncomplete = steps.find(step => !step.completed);
  if (firstIncomplete) {
    firstIncomplete.expanded = true;
  }

  const completed = (steps.filter(step => step.completed === true)).length;
  const progress = (completed / steps.length) * 100;

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (progress < 100) {
      setActive(true);
    }
  } , [progress]);

  const handleToggle = useCallback(() => setActive((active) => !active), []);

  if (progress === 100 && total_reviews > 0) {
    const userCreated = moment(user?.created_at);
    const now = moment();
    const diff = now.diff(userCreated, 'days');
    if (diff > 3) {
      return (null);
    }
  }

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <LegacyStack>
          <LegacyStack.Item fill>
            <Text variant="headingMd" as="h2">
              { progress === 100 ?
                'Thanks for setting up Junip!'
                :
                'Finish setting up Junip'
              }
            </Text>
          </LegacyStack.Item>
          { progress === 100 &&
            <Button  onClick={handleToggle} variant="plain">
              {`${active ? 'Hide' : 'Show'} steps`}
            </Button>
          }
        </LegacyStack>
        <div className="my-3">
          <ProgressBar progress={progress} size="small" tone="success"></ProgressBar>
        </div>
      </LegacyCard.Section>
      <Collapsible id={`start-guide-collapsible`} open={active}>
        { steps.map((step, index) => <OnboardingStep key={step.type} index={index} step={step} /> ) }
      </Collapsible>
    </LegacyCard>
  );
}
