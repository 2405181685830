import React, { useState } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import axios from 'axios';
import {
  Checkbox,
  FormLayout,
  Modal
} from '@shopify/polaris';

function ExportReviewsModal({ active, hasReviewTagging, close, success, showToast }) {
  const [loading, setLoading] = useState(false);
  const [includePrivateAnswers, setIncludePrivateAnswers] = useState(false);
  const [includeReviewTags, setIncludeReviewTags] = useState(false);

  const startExport = async () => {
    setLoading(true);
    try {
      await axios.post('/api/v1/review_exports', {
        review_export: {
          include_private_answers: includePrivateAnswers,
          include_review_tags: includeReviewTags
        }
      });
      setLoading(false);
      success();
      close();
    } catch(e) {
      showToast('Error exporting reviews, please try again', true);
      setLoading(false);
      trackEvent('Error: Export reviews modal - Export');
    }
  };

  return (
    <Modal
      open={active}
      onClose={close}
      title="Export reviews?"
      primaryAction={{
        content: 'Export',
        disabled: loading,
        onAction: () => { startExport(); trackEvent('Export reviews modal - Export') },
        loading
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => { close(); trackEvent('Export reviews modal - Cancel') },
          disabled: loading
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <p>This will export all your existing reviews as a CSV. Note this action may take a little while.</p>
          <Checkbox
            label="Include private answers"
            checked={includePrivateAnswers}
            onChange={() => setIncludePrivateAnswers(!includePrivateAnswers)}
          />
          {
            hasReviewTagging &&
            <Checkbox
              label="Include review tags"
              checked={includeReviewTags}
              onChange={() => setIncludeReviewTags(!includeReviewTags)}
            />
          }
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(ExportReviewsModal);
