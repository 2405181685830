import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSurveyQuestions } from 'redux/surveyQuestions';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import {
  Page,
  LegacyCard,
  Pagination,
  LegacyStack,
  Button,
  TextContainer,
  Text,
} from "@shopify/polaris";
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import CustomQuestions from 'components/CustomQuestions';
import UpgradeTrigger from 'components/UpgradeTrigger';

import customQuestionsEmpty from 'assets/images/customQuestions/custom-questions-empty.svg';

function Questions({ surveyQuestions, planFeatureSets, fetchSurveyQuestions, fetchPlanFeatureSets }) {
  useEffect(() => {
    fetchSurveyQuestions();
  }, [fetchSurveyQuestions]);

  useEffect(() => {
    if (!planFeatureSets?.data) {
      fetchPlanFeatureSets();
    }
  }, [fetchPlanFeatureSets, planFeatureSets?.data]);

  return (
    <Page
      title="Custom questions"
      subtitle="Add custom questions to your review form to gather more detailed feedback and learn more about your customers."
      primaryAction={{
        content: 'New Question',
        url: '/forms/questions/new',
        disabled: planFeatureSets?.loading || planFeatureSets?.data?.pf_attributes === false
      }}
    >
      <LoadingCardWrapper loading={surveyQuestions?.loading} sectioned={false}>
        <UpgradeTrigger
          feature={'pf_attributes'}
          title={'Upgrade to use custom questions'}
          description={'You need to upgrade your plan to use custom questions'}
        >
          <CustomQuestions questions={surveyQuestions.data || []} />
          {!surveyQuestions.data?.length &&
            <LegacyCard.Section>
              <div className="text-center py-6 my-6">
                <LegacyStack vertical spacing="extraLoose">
                  <img src={customQuestionsEmpty} alt="Custom questions" />
                  <TextContainer>
                    <Text variant="headingMd" as="p">Add custom questions to your product reviews</Text>
                    <p>
                      <Text tone="subdued">
                        Use custom questions to get feedback on specific attributes or learn more about your customers.
                      </Text>
                    </p>
                  </TextContainer>
                  <Button url="/forms/questions/new" variant="primary">
                    Create Question
                  </Button>
                </LegacyStack>
              </div>
            </LegacyCard.Section>
          }
          <LegacyCard.Section>
            <Pagination
              hasPrevious={surveyQuestions?.meta?.page?.before}
              onPrevious={() => fetchSurveyQuestions({ page: { before: surveyQuestions?.meta?.page?.before } })}
              hasNext={surveyQuestions?.meta?.page?.after}
              onNext={() => fetchSurveyQuestions({ page: { after: surveyQuestions?.meta?.page?.after } })}
            />
          </LegacyCard.Section>
        </UpgradeTrigger>
      </LoadingCardWrapper>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  surveyQuestions: state.surveyQuestions,
  planFeatureSets: state.planFeatureSets
});

const mapDispatchToProps = (dispatch) => ({
  fetchSurveyQuestions: (params) => dispatch(fetchSurveyQuestions(params)),
  fetchPlanFeatureSets: () => dispatch(fetchPlanFeatureSets()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Questions);
