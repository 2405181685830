import React from 'react';
import { connect } from 'react-redux';
import { setUser } from 'redux/user';

import {
  Card,
  Text,
  Button,
  BlockStack
} from '@shopify/polaris';

import LoginWrapper from 'components/LoginWrapper';

const DEFAULT_SHOPIFY_APP_URL = 'https://apps.shopify.com/junip';

const ConnectStore = (props) => {
  if (!props.location.state || !props.location.state.user) {
    props.history.replace('/login');
  }

  return (
    <LoginWrapper>
      <Card>
        <BlockStack gap="300">
          <Text variant="headingMd" as="h1">Connect to your Shopify Store</Text>
          <Text variant="bodyLg" as="p">Install the Junip app on your store to continue</Text>
          <div>
            <Button
              url={process.env.REACT_APP_SHOPIFY_APP_URL || DEFAULT_SHOPIFY_APP_URL}
              size="large"
              variant="primary">
                Install Junip
            </Button>
          </div>
        </BlockStack>
      </Card>
    </LoginWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user))
});

export default connect(
  null,
  mapDispatchToProps
)(ConnectStore);
