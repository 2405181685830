import React, { useState } from 'react';
import { TextContainer, Button, Icon, Tooltip, Text } from "@shopify/polaris";
import SolicitationConditions from 'components/SolicitationConditions';
import CampaignConditions from 'components/CampaignConditions';
import IncentiveConditions from 'components/IncentiveConditions';
import ReviewCreatedConditions from 'components/ReviewCreatedConditions';
import ReviewVerificationConditions from 'components/ReviewVerificationConditions';
import { EditIcon, FilterIcon } from "@shopify/polaris-icons";
import styles from 'styles/components/Flow.module.scss';

export default function Condition({ flow, action, updateActionConditionList, showConnector }) {
  const [editing, setEditing] = useState(false);

  const renderConditions = () => {
    const name = ((flow) => {
      return `${flow?.event_topic?.subject}/${flow?.event_topic?.action}`;
    })(flow);

    let component = <p>No conditions</p>;
    let showEdit = false;

    switch (name) {
      case 'order/fulfilled':
      case 'review/request':
        component = (
          <SolicitationConditions flow={flow} action={action} updateActionConditionList={updateActionConditionList} editing={editing} setEditing={setEditing} />
        );
        showEdit = true;
        break;
      case 'order/targeted-by-campaign':
      case 'review/request-by-campaign':
        component = (
          <CampaignConditions action={action} updateActionConditionList={updateActionConditionList} editing={editing} setEditing={setEditing} />
        );
        showEdit = true;
        break;
      case 'incentive/rewarded':
        component = (
          <IncentiveConditions flow={flow} editing={editing} setEditing={setEditing} />
        );
        showEdit = true;
        break;
      case 'review/created':
      case 'review/submitted':
        component = (
          <ReviewCreatedConditions action={action} editing={editing} setEditing={setEditing} updateActionConditionList={updateActionConditionList} />
        );
        showEdit = false;
        break;
      case 'review/confirmation-required':
        component = (
          <ReviewVerificationConditions action={action} editing={editing} setEditing={setEditing} />
        );
        showEdit = false;
        break;
      case 'response/created':
        component = (
          <div className="d-flex">
            <div className="mr-3">
              <Icon tone="primary" source={FilterIcon} />
            </div>
            <p><Text variant="bodyMd" as="span" fontWeight="semibold">If "Notify customer of your response" is checked</Text></p>
          </div>
        )
        break;
      default:
        component = (
          <p>No conditions</p>
        );
        break;
    }

    return { component, showEdit };
  };

  const { component, showEdit } = renderConditions();

  return (
    <div style={{ position: 'relative' }}>
      {showConnector > 0 && <div className={styles.flowConnector} />}
      <div className={`${styles.flowCard} ${styles.condition}`}>
        <div className={styles.flowContent}>
          <TextContainer>
            <Text variant="headingSm" as="h3">
              <Text variant="bodyMd" as="span" tone="subdued">Conditions</Text>
            </Text>
            {component}
          </TextContainer>
        </div>
        {showEdit &&
          <Tooltip content="Edit">
            <Button onClick={() => setEditing(true)}  icon={EditIcon} variant="plain" />
          </Tooltip>
        }
      </div>
    </div>
  );
}
