import axios from 'axios';

// Actions
const FETCH_SOLICITATION_CAMPAIGNS_BEGIN = 'FETCH_SOLICITATION_CAMPAIGNS_BEGIN';
const FETCH_SOLICITATION_CAMPAIGNS_SUCCESS = 'FETCH_SOLICITATION_CAMPAIGNS_SUCCESS';
const FETCH_SOLICITATION_CAMPAIGNS_ERROR = 'FETCH_SOLICITATION_CAMPAIGNS_ERROR';
const UPDATE_SOLICITATION_CAMPAIGN = 'UPDATE_SOLICITATION_CAMPAIGN';
const ADD_SOLICITATION_CAMPAIGN = 'ADD_SOLICITATION_CAMPAIGN';

// Action Creators
export function fetchSolicitationCampaigns(data = {}) {
  return async dispatch => {
    dispatch(fetchSolicitationCampaignsBegin());
    try {
      const response = await axios.get('/api/v1/solicitation_campaigns', {
        params: {
          'page[after]': data.page?.after,
          'page[before]': data.page?.before
        }
      });
      dispatch(fetchSolicitationCampaignsSuccess(response.data.solicitation_campaigns, response.data.meta));
    } catch (e) {
      dispatch(fetchSolicitationCampaignsError(e));
    }
  }
}

export const updateSolicitationCampaign = (data) => ({
  type: UPDATE_SOLICITATION_CAMPAIGN,
  data,
});

export const addSolicitationCampaign = (data) => ({
  type: ADD_SOLICITATION_CAMPAIGN,
  data,
});

export const fetchSolicitationCampaignsBegin = () => ({
  type: FETCH_SOLICITATION_CAMPAIGNS_BEGIN
});

export const fetchSolicitationCampaignsSuccess = (data, meta) => ({
  type: FETCH_SOLICITATION_CAMPAIGNS_SUCCESS,
  data,
  meta
});

export const fetchSolicitationCampaignsError = (error) => ({
  type: FETCH_SOLICITATION_CAMPAIGNS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_SOLICITATION_CAMPAIGNS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SOLICITATION_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        loading: false,
        error: null
      };
      case UPDATE_SOLICITATION_CAMPAIGN:
        const index = state.data.findIndex(b => b.id === action.data.id);
        const newStateData = state.data.slice();
        newStateData[index] = action.data;
        state.data = newStateData;
        return {
          ...state,
          loading: false,
          error: null
        };
    case ADD_SOLICITATION_CAMPAIGN:
      state.data.push(action.data);
      return {
        ...state,
        loading: false,
        error: null
      };
    case FETCH_SOLICITATION_CAMPAIGNS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
