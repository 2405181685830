import React, { useEffect } from 'react';
import moment from 'moment';
import { fetchBillingSubscriptions } from 'redux/billingSubscriptions';
import { fetchSolicitationCampaigns } from 'redux/solicitationCampaigns';
import { fetchFlows } from 'redux/flows';
import { fetchDisplaySettings } from 'redux/displaySettings';
import { fetchAnalytics } from 'redux/analytics';
import { fetchNotificationSettings } from 'redux/notificationSettings';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import {
  Page,
  LegacyCard,
  TextContainer,
  Layout,
  Button,
  LegacyStack,
  CalloutCard,
  ButtonGroup,
  MediaCard,
  Link,
  Text,
} from "@shopify/polaris";
import Onboarding from 'components/OnboardingGuide';
import GatherReviewsGuide from 'components/GatherReviewsGuide';
import StoreSyncBanner from 'components/StoreSyncBanner';
import BillingBanner from 'components/BillingBanner';
import MigrateEventsBanner from 'components/MigrateEventsBanner';
import LoadingContentWrapper from 'components/LoadingContentWrapper';

import styles from 'styles/routes/Home.module.scss';
import upgrade from 'assets/images/upgrade.svg';
import customQuestions from 'assets/images/home/customQuestions.svg';
import notifications from 'assets/images/home/notifications.svg';
import manualInstall from 'assets/images/home/manualInstall.png';
import disableReviews from 'assets/images/home/disableReviews.svg';
import discount from 'assets/images/home/discount.svg';
import flowsImage from 'assets/images/home/flows.png';
import ugc from 'assets/images/home/ugc.svg';
import google from 'assets/images/home/google.svg';
import reviewLinks from 'assets/images/home/reviewLinks.svg';
import apps from 'assets/images/home/apps.svg';
import shareReview from 'assets/images/home/shareReview.svg';
import upis from 'assets/images/home/upis.svg';
import reviewsPage from 'assets/images/home/reviewsPage.png';
import referJunip from 'assets/images/home/referJunip.svg';
import bestPracticesBlog from 'assets/images/home/blog/bestPractices.png';
import displayBlog from 'assets/images/home/blog/display.png';
import reviewLinkBlog from 'assets/images/home/blog/reviewLinks.png';
import kickstartBlog from 'assets/images/home/blog/kickstart.png';
import mobileFirstBlog from 'assets/images/home/blog/mobileFirst.png';
import negativeReviewsBlog from 'assets/images/home/blog/negativeReviews.png';
import photoReviewsBlog from 'assets/images/home/blog/photoReviews.png';
import richSnippetsBlog from 'assets/images/home/blog/richSnippets.png';
import ugcBlog from 'assets/images/home/blog/ugc.png';
import flowsDoodle from 'assets/images/flowsDoodle.svg';

const Home = (props) => {
  const {
    billingSubscriptions,
    solicitationCampaigns,
    flows,
    displaySettings,
    analytics,
    notificationSettings,
    fetchBillingSubscriptions,
    fetchSolicitationCampaigns,
    fetchFlows,
    fetchDisplaySettings,
    fetchAnalytics,
    fetchNotificationSettings,
  } = props;
  const user = props.user.data || {};
  const { total_reviews, message_deliveries } = analytics?.data || {};

  useEffect(() => {
    if (!billingSubscriptions.data) {
      fetchBillingSubscriptions();
    }
  }, [fetchBillingSubscriptions, billingSubscriptions.data]);

  useEffect(() => {
    if (!solicitationCampaigns.data) {
      fetchSolicitationCampaigns();
    }
  }, [fetchSolicitationCampaigns, solicitationCampaigns.data]);

  useEffect(() => {
    fetchFlows();
  }, [fetchFlows]);

  useEffect(() => {
    fetchDisplaySettings();
  }, [fetchDisplaySettings]);

  useEffect(() => {
    if (!analytics.data) {
      fetchAnalytics();
    }
  }, [fetchAnalytics, analytics.data]);

  useEffect(() => {
    if (!notificationSettings.data) {
      fetchNotificationSettings();
    }
  }, [fetchNotificationSettings, notificationSettings.data]);

  const cards = [
    {
      key: 'custom_questions',
      type: 'full',
      image: customQuestions,
      title: 'Add custom questions to your review forms',
      description: 'Get feedback on specific product features and learn more about your customers by adding custom questions to your product review form.',
      primaryAction: {
        content: 'Visit custom questions',
        url: '/forms/questions',
        onAction: () => trackEvent('Home - Custom questions - Visit custom questions')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://help.junip.co/en/articles/4607278-how-to-add-custom-questions',
        onAction: () => trackEvent('Home - Custom questions - Learn more'),
        external: true,
      }
    },
    {
      key: 'notifications',
      type: 'callout',
      image: notifications,
      hide: (notificationSettings?.data?.new_review_email_enabled === true || notificationSettings?.data?.periodic_digest_email_enabled === true),
      title: 'Get notified when a customer leaves a review',
      description: 'Enable digest emails to read new reviews and see what’s happening in Junip without logging in.',
      primaryAction: {
        content: 'Visit notification settings',
        url: '/settings/notifications',
        onAction: () => trackEvent('Home - Notifications - Visit notification settings')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://help.junip.co/en/articles/4607142-notification-update-emails',
        onAction: () => trackEvent('Home - Notifications - Learn more'),
        external: true,
      }
    },
    {
      key: 'install_junip',
      type: 'media',
      image: manualInstall,
      title: 'Manually adding the Junip snippets',
      description: 'If you’re on a Shopify 1.0 theme - or if your 2.0 theme doesn’t support Junip widgets in some sections - learn to manually add the Junip sections to your theme’s code.',
      primaryAction: {
        content: 'Read help article',
        url: 'https://help.junip.co/en/articles/4606706-manually-installing-the-junip-snippets',
        onAction: () => trackEvent('Home - Install Junip - Read help article'),
        external: true,
      }
    },
    {
      key: 'disable_reviews',
      type: 'callout',
      image: disableReviews,
      title: 'Disable reviews for irrelevant products',
      description: 'If your store has products that do not need reviews - i.e. gift cards - you can disable reviews for specific items in the products section.',
      primaryAction: {
        content: 'Visit products',
        url: '/products',
        onAction: () => trackEvent('Home - Disable reviews - Visit products')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://help.junip.co/en/articles/4607279-disable-products',
        onAction: () => trackEvent('Home - Disable reviews - Learn more'),
        external: true,
      }
    },
    {
      key: 'upgrade',
      type: 'full',
      image: upgrade,
      hide: billingSubscriptions.data?.findIndex(b => b.active && (b.provider !== 'junip' || b.auto_renew)) !== -1,
      title: 'Take your reviews to the next level',
      description: 'Ready to get more out of your reviews? Upgrade your plan to get advanced features, more usage, and much more!',
      primaryAction: {
        content: 'View plans',
        url: '/settings/billing',
        onAction: () => trackEvent('Home - Upgrade - View plans')
      },
    },
    {
      key: 'book_meeting_bfcm',
      type: 'callout',
      title: 'Want some last minute help before the big day?',
      image: referJunip,
      hide: billingSubscriptions.data?.findIndex(b => b.active && (b.provider === 'junip' || !b.auto_renew)) !== -1,
      description: "Book here for a 15-minute check-up, read through some last minute tips or send us a chat if there's anything we can do to help!",
      primaryAction: {
        content: 'Book a meeting',
        url: 'https://meetings.hubspot.com/blair-beckwith/bfcm-prep',
        external: true,
        onAction: () => trackEvent('Home - BFCM card book meeting'),
      },
      secondaryAction: {
        content: 'Read tips',
        url: 'https://junip.co/blog/get-the-most-out-of-reviews-bfcm-edition/',
        external: true,
        onAction: () => trackEvent('Home - BFCM card read tips'),
      },
    },
    {
      key: 'incentives',
      type: 'callout',
      image: discount,
      title: 'Reward customers for leaving a review',
      description: 'Enable incentives to send your customers a discount code when they submit reviews or media.',
      primaryAction: {
        content: 'Visit incentives',
        url: '/incentives',
        onAction: () => trackEvent('Home - Incentives - Visit incentives')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://help.junip.co/en/articles/4607281-incentives',
        onAction: () => trackEvent('Home - Incentives - Learn more'),
        external: true,
      }
    },
    {
      key: 'flows',
      type: 'media',
      image: flowsImage,
      title: 'Automate your communications using flows',
      description: 'Learn to automatically send well-timed messages through Junip or other apps using Flows.',
      primaryAction: {
        content: 'Visit flows',
        url: '/flows',
        onAction: () => trackEvent('Home - Flows - Visit flows')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://help.junip.co/en/articles/5902201-the-basics-of-flows',
        onAction: () => trackEvent('Home - Flows - Learn more'),
        external: true,
      }
    },
    {
      key: 'flows_full',
      type: 'full',
      image: flowsDoodle,
      title: 'Automate your communications using flows',
      description: 'Learn to automatically send well-timed messages through Junip or other apps using Flows.',
      primaryAction: {
        content: 'Visit flows',
        url: '/flows',
        onAction: () => trackEvent('Home - Flows - Visit flows')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://help.junip.co/en/articles/5902201-the-basics-of-flows',
        onAction: () => trackEvent('Home - Flows - Learn more'),
        external: true,
      }
    },
    {
      key: 'media_gallery',
      type: 'full',
      image: ugc,
      title: 'Showcase your user generated content',
      description: 'Media galleries allow you to showcase your user generated content at the top of your product review feeds. Enable galleries now in your advanced on-site settings.',
      primaryAction: {
        content: 'Visit on-site',
        url: '/on_site',
        onAction: () => trackEvent('Home - Media gallery - Visit on-site')
      },
    },
    {
      key: 'google_shopping',
      type: 'callout',
      image: google,
      title: 'Share reviews to your Google Shopping feed',
      description: 'Display ratings & reviews on your Google Shopping feed by enabling Google syndication.',
      primaryAction: {
        content: 'Visit Syndication',
        url: '/settings/syndication',
        onAction: () => trackEvent('Home - Google shopping - Visit syndication')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://help.junip.co/en/articles/4658170-google-shopping-product-listings',
        onAction: () => trackEvent('Home - Google shopping - Learn more'),
        external: true,
      }
    },
    {
      key: 'review_links',
      type: 'callout',
      image: reviewLinks,
      title: 'Prompt reviews in more places with review links',
      description: 'Review links let your customers access your product and store review forms at any time, from anywhere.',
      primaryAction: {
        content: 'Visit Forms',
        url: '/forms',
        onAction: () => trackEvent('Home - Review links - Visit forms')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://junip.co/blog/introducing-review-links/',
        onAction: () => trackEvent('Home - Review links - Learn more'),
        external: true,
      }
    },
    {
      key: 'integrations',
      type: 'callout',
      image: apps,
      title: 'Integrate your favourite apps with Junip',
      description: 'Seamlessly integrate reviews into your existing workflow by connecting your preferred apps for messaging & more.',
      primaryAction: {
        content: 'Visit integrations',
        url: '/integrations',
        onAction: () => trackEvent('Home - Integrations - Visit integrations')
      },
    },
    {
      key: 'social_sharing',
      type: 'callout',
      image: shareReview,
      title: 'Share standout reviews with your followers',
      description: 'Easily share reviews and media submissions on social media using the “Share” option in the review management section.',
      primaryAction: {
        content: 'Manage reviews',
        url: '/reviews',
        onAction: () => trackEvent('Home - Social sharing - Manage reviews')
      },
      secondaryAction: {
        content: 'Learn more',
        url: 'https://help.junip.co/en/articles/6170830-sharing-reviews',
        onAction: () => trackEvent('Home - Social sharing - Learn more'),
        external: true,
      }
    },
    {
      key: 'product_identifiers',
      type: 'callout',
      image: upis,
      title: 'Manage product identifiers directly in Junip',
      description: 'View and manage UPIs (Unique product identifiers) in the products section so you can successfully syndicate reviews with other channels.',
      primaryAction: {
        content: 'Visit products',
        url: '/products',
        onAction: () => trackEvent('Home - Product identifiers - Visit products')
      },
    },
    {
      key: 'reviews_page',
      type: 'media',
      image: reviewsPage,
      title: 'Creating a dedicated reviews page',
      description: 'Learn to create a page on your site showcasing all store & product reviews.',
      primaryAction: {
        content: 'Read help article',
        url: 'https://help.junip.co/en/articles/4607259-dedicated-reviews-page',
        onAction: () => trackEvent('Home - Reviews page - Read help article'),
        external: true
      }
    },
    {
      key: 'refer_junip',
      type: 'callout',
      image: referJunip,
      title: 'Share Junip to earn discounts, swag, & more',
      description: `Referred brands get 25% off their first 2 months. You'll get a discount on your subscription when they start :)`,
      primaryAction: {
        content: 'Create referral code',
        url: '/settings/referral',
        onAction: () => trackEvent('Home - Refer Junip - Create referral code'),
      }
    },
  ];

  const blogTiles = [
    {
      key: 'display_reviews',
      image: displayBlog,
      subtitle: 'Review strategy',
      title: 'Displaying reviews on your Shopify store',
      url: 'https://junip.co/blog/how-to-display-review-on-your-shopify-store/'
    },
    {
      key: 'best_practices',
      image: bestPracticesBlog,
      subtitle: 'Review strategy',
      title: '8 Examples of Emails Brands Use To Get More Reviews',
      url: 'https://junip.co/blog/review-email-best-practices-examples/',
    },
    {
      key: 'kickstart',
      image: kickstartBlog,
      subtitle: 'Review strategy',
      title: 'Kickstart your reviews with campaigns',
      url: 'https://junip.co/blog/kickstart-your-ecommerce-reviews/'
    },
    {
      key: 'photo_reviews',
      image: photoReviewsBlog,
      subtitle: 'Product updates',
      title: 'Get 2x the photo submissions with Junip',
      url: 'https://junip.co/blog/how-to-display-review-on-your-shopify-store/',
    },
    {
      key: 'mobile_first',
      image: mobileFirstBlog,
      subtitle: 'Review strategy',
      title: 'Why mobile-first reviews matter to brands',
      url: 'https://junip.co/blog/why-mobile-first-reviews-matter-for-brands/',
    },
    {
      key: 'review_links',
      image: reviewLinkBlog,
      subtitle: 'Product updates',
      title: 'Prompt reviews from anywhere with review links',
      url: 'https://junip.co/blog/introducing-review-links/',
    },
    {
      key: 'rich_snippets',
      image: richSnippetsBlog,
      subtitle: 'Review strategy',
      title: 'Why your store needs Google rich snippets',
      url: 'https://junip.co/blog/why-your-shopify-store-needs-google-rich-snippets-for-your-shopify-store/',
    },
    {
      key: 'ugc',
      image: ugcBlog,
      subtitle: 'Review strategy',
      title: 'Everything you need to know about UGC',
      url: 'https://junip.co/blog/ugc-for-dtc-everything-you-need-to-know-about-ugc/'
    },
    {
      key: 'negative_reviews',
      image: negativeReviewsBlog,
      subtitle: 'Review strategy',
      title: 'How to deal with negative reviews',
      url: 'https://junip.co/blog/how-to-handle-negative-reviews/'
    }
  ];

  const TIER_1 = [
    { type: 'text', value: 'Get the most out of Junip' },
    { type: 'card', key: 'custom_questions' },
    { type: 'card', key: 'notifications' },
    { type: 'card', key: 'install_junip' },
    { type: 'card', key: 'disable_reviews' },
    { type: 'card', key: 'upgrade' },
    { type: 'shopify_review_card' },
    { type: 'card', key: 'refer_junip' },
    { type: 'blog', tiles: ['display_reviews', 'best_practices', 'kickstart']}
  ];

  const TIER_2 = [
    { type: 'shopify_review_card' },
    { type: 'text', value: 'Discover more ways to collect & leverage your reviews' },
    { type: 'card', key: 'notifications' },
    { type: 'card', key: 'custom_questions' },
    { type: 'card', key: 'incentives' },
    { type: 'card', key: 'flows' },
    { type: 'card', key: 'media_gallery' },
    { type: 'card', key: 'google_shopping' },
    { type: 'card', key: 'review_links' },
    { type: 'card', key: 'integrations' },
    { type: 'card', key: 'upgrade' },
    { type: 'card', key: 'refer_junip' },
    { type: 'blog', tiles: ['photo_reviews', 'mobile_first', 'review_links']}
  ];

  const TIER_3 = [
    { type: 'shopify_review_card' },
    { type: 'text', value: 'Discover more ways to collect & leverage your reviews' },
    { type: 'card', key: 'flows_full' },
    { type: 'card', key: 'notifications' },
    { type: 'card', key: 'social_sharing' },
    { type: 'card', key: 'product_identifiers' },
    { type: 'card', key: 'reviews_page' },
    { type: 'card', key: 'media_gallery' },
    { type: 'card', key: 'review_links' },
    { type: 'card', key: 'integrations' },
    { type: 'card', key: 'upgrade' },
    { type: 'card', key: 'refer_junip' },
    { type: 'blog', tiles: ['rich_snippets', 'ugc', 'negative_reviews'] }
  ];

  const renderList = (() => {
    const storeCreated = moment(displaySettings?.data?.store?.created_at);
    const now = moment();
    const diff = now.diff(storeCreated, 'days');
    if (diff < 14) {
      return TIER_1;
    }

    if (total_reviews < 100 && diff < 60) {
      return TIER_2;
    }

    return TIER_3;
  })();

  const billingCallout = () => {
    if (!billingSubscriptions.data || billingSubscriptions.data?.[0]?.active === true) return null;

    return (
      <LegacyCard
        title="Set up a billing plan to go live with Junip!"
        footerActionAlignment="left"
        primaryFooterAction={{
          content: 'View plans',
          url: '/settings/billing',
          onAction: () => trackEvent('Home - Set up billing card view plans')
        }}
        sectioned>
        <TextContainer>
          { billingSubscriptions?.data?.length === 0 &&
            <p>This starts your trial with Junip & let's you go live with reviews. You don't pay anything until your trial ends & can cancel up until that point with absolutely no charge.</p>
          }
          <p>Junip offers a variety of plans suitable for any type of business</p>
        </TextContainer>
      </LegacyCard>
    );
  };

  return (
    <Page
      title={`Welcome to Junip${user.first_name ? `, ${user.first_name.trim()}!` : '!'}`}
    >
      <LoadingContentWrapper
        loading={
          billingSubscriptions.loading ||
          flows.loading ||
          solicitationCampaigns.loading ||
          displaySettings.loading ||
          analytics.loading
        }
        cards={6}
      >
        <MigrateEventsBanner/>
        <StoreSyncBanner />
        <BillingBanner isHomePage={true} skipFetch={true} />
        <Layout>
          <Layout.Section>
            {billingCallout()}
            <Onboarding
              user={user}
              total_reviews={total_reviews}
              displaySettings={displaySettings}
              flows={flows}
              billingSubscriptions={billingSubscriptions}
            />
            {total_reviews < 10 && (
              <GatherReviewsGuide
                store={displaySettings?.data?.store}
                total_reviews={total_reviews}
                solicitationCampaigns={solicitationCampaigns}
              />
            )}
            {renderList.map(({ type, key, tiles, value }, index) => {
              if (type === 'card') {
                const cardData = cards.find(c => c.key === key);

                if (cardData.hide) return null;

                return (
                  <React.Fragment key={key}>
                    { cardData.type === 'full' &&
                      <LegacyCard sectioned>
                        <LegacyStack vertical distribution="center" alignment="center">
                          <img width={200} src={cardData.image} alt={cardData.title} />
                          <Text variant="headingMd" as="p">{cardData.title}</Text>
                          <p className="text-center">
                            <Text variant="bodyMd" as="span" tone="subdued">{cardData.description}</Text>
                          </p>
                          <ButtonGroup>
                            <Button
                              url={cardData.primaryAction.url}
                              onClick={cardData.primaryAction.onAction}
                            >
                              {cardData.primaryAction.content}
                            </Button>
                            {cardData.secondaryAction &&
                              <Button
                                url={cardData.secondaryAction.url}
                                onClick={cardData.secondaryAction.onAction}
                                external={cardData.secondaryAction.external || false}
                                variant="plain"
                              >
                                {cardData.secondaryAction.content}
                              </Button>
                            }
                          </ButtonGroup>
                        </LegacyStack>
                      </LegacyCard>
                    }
                    { cardData.type === 'callout' &&
                      <CalloutCard
                        title={cardData.title}
                        illustration={cardData.image}
                        primaryAction={cardData.primaryAction}
                        secondaryAction={cardData.secondaryAction || undefined}
                      >
                        <p>{cardData.description}</p>
                      </CalloutCard>
                    }
                    { cardData.type === 'media' &&
                      <MediaCard
                        title={cardData.title}
                        description={cardData.description}
                        primaryAction={cardData.primaryAction}
                        secondaryAction={cardData.secondaryAction || undefined}
                        portrait={false}
                      >
                        <img height="100%" width="100%" src={cardData.image} alt={cardData.title} style={{objectFit: 'cover', objectPosition: 'center'}} />
                      </MediaCard>
                    }
                  </React.Fragment>
                );
              } else if (type === 'text') {
                return (
                  <div className="free-wrapper py-4" key={type}>
                    <Text variant="headingMd" as="p">{value}</Text>
                  </div>
                );
              } else if (type === 'shopify_review_card') {
                return (
                  <LeaveReviewCard key={type} />
                );
              } else if (type === 'blog') {
                return (
                  <React.Fragment key={type}>
                    <div className="free-wrapper my-4 pt-2">
                      <Text variant="headingMd" as="p">Blog</Text>
                    </div>
                    <div className={styles.blogTilesContainer}>
                      {tiles.map((tile, index) => {
                        const blog = blogTiles.find(b => b.key === tile);
                        return (
                          <div key={index}>
                            <MediaCard
                              portrait={true}
                              title={
                                <TextContainer>
                                  <Text variant="bodySm" fontWeight="semibold" as="h3">{blog.subtitle}</Text>
                                  <Text variant="bodyMd" as="h2">{blog.title}</Text>
                                </TextContainer>
                              }
                              description={blog.description}
                              primaryAction={{
                                content: 'Read article',
                                external: true,
                                url: blog.url,
                                onAction: () => trackEvent('Home - Blog card read article')
                              }}
                            >
                              <img height="130px" width="100%" src={blog.image} alt={blog.title} style={{objectFit: 'cover', objectPosition: 'center'}} />
                            </MediaCard>
                          </div>
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              }
              return null;
            }
          )}
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <div className={`free-wrapper ${styles.stats}`}>
              <TextContainer spacing="loose">
                <div className="mb-6">
                  <Text variant="headingMd" as="h2">Your stats</Text>
                </div>
                <TextContainer spacing="tight">
                  <Text variant="bodyMd" fontWeight="medium" as="h3">Reviews gathered</Text>
                  <Text variant="headingLg" as="p">{total_reviews}</Text>
                  <p>
                    <Button
                      url="/analytics"
                      onClick={() => trackEvent('Home - Stats reviews gathered Analytics')}
                    >
                      View analytics
                    </Button>
                  </p>
                </TextContainer>
                <div className="divider mb-4" />
                <TextContainer spacing="tight">
                  <Text variant="bodyMd" fontWeight="medium" as="h3">Messages sent</Text>
                  <Text variant="headingLg" as="p">{message_deliveries}</Text>
                  <p>
                    <Button
                      url="/flows/outbox"
                      onClick={() => trackEvent('Home - Stats reviews requested Outbox')}
                    >
                      View outbox
                    </Button>
                  </p>
                </TextContainer>
              </TextContainer>
            </div>
          </Layout.Section>
        </Layout>
      </LoadingContentWrapper>
    </Page>
  );
}

function LeaveReviewCard() {
  const KEY = 'writeShopifyReviewClicked';

  const writeShopifyReview = (dismiss = false) => {
    window?.localStorage?.setItem(KEY, true);
    trackEvent('Home - Write shopify review');
  };

  const writeReviewClicked = () => {
    try {
      const clicked = JSON.parse(window?.localStorage?.getItem(KEY));
      if (clicked === true) {
        return true;
      }
      return false;
    } catch (e) {
      return true;
    }
  };

  if (writeReviewClicked()) {
    return null;
  }

  return (
    <LegacyCard subdued sectioned>
      <LegacyStack alignment="center">
        <LegacyStack.Item fill>
          <Text variant="headingMd" as="p">Enjoying Junip? Leave us a review!</Text>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <LegacyStack wrap={false} spacing="tight">
            {[...Array(5)].map((_, index) => (
              <Link removeUnderline url="https://apps.shopify.com/junip#modal-show=ReviewListingModal" external onClick={writeShopifyReview} key={index + Date.now()}>
                <svg key={index} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m4.79961 23.9999c-.2484 0-.4956-.0768-.7044-.228-.36001-.2616-.54721-.7008-.48361-1.1424l1.11481-7.8045-4.375226-4.3763c-.3180023-.318-.4308029-.78596-.2940021-1.21434.1368011-.42839.5016031-.74398.9444081-.81718l6.59764-1.10036 3.32637-6.652598c.2052-.409188.6552-.6791794 1.0801-.66357993.4572.00239993.8724.26399193 1.0728.67557893l3.1848 6.542199 6.7464 1.20116c.4404.078.8004.39479.9348.82078.1344.42719.0204.89274-.2964 1.20834l-4.3764 4.3763 1.116 7.8045c.0624.444-.1248.8856-.4896 1.146-.3636.2616-.8436.2964-1.2408.0948l-6.5736-3.3287-6.75249 3.3335c-.168.084-.3504.1248-.5316.1248z" fill="#adadad" fillRule="evenodd" /></svg>
              </Link>
            ))}
          </LegacyStack>
        </LegacyStack.Item>
      </LegacyStack>
    </LegacyCard>
  );
}

const mapStateToProps = (state) => ({
  billingSubscriptions: state.billingSubscriptions,
  solicitationCampaigns: state.solicitationCampaigns,
  flows: state.flows,
  displaySettings: state.displaySettings,
  analytics: state.analytics,
  notificationSettings: state.notificationSettings,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBillingSubscriptions: () => dispatch(fetchBillingSubscriptions()),
  fetchSolicitationCampaigns: () => dispatch(fetchSolicitationCampaigns()),
  fetchFlows: () => dispatch(fetchFlows()),
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  fetchAnalytics: () => dispatch(fetchAnalytics()),
  fetchNotificationSettings: () => dispatch(fetchNotificationSettings()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
