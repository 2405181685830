import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchFlows } from 'redux/flows';
import { Page, LegacyCard, Button, LegacyStack, Text } from "@shopify/polaris";
import LoadingContentWrapper from 'components/LoadingContentWrapper';

function TransactionalFlowSettings({ stores, flows, fetchFlows }) {
  const store = stores?.data?.find?.(s => s.scoped === true) || {};

  useEffect(() => {
    fetchFlows();
  }, [fetchFlows]);

  const renderTopic = (flow) => {
    return `${flow.event_topic?.subject}/${flow.event_topic?.action}`;
  };

  const renderName = (flow) => {
    switch(renderTopic(flow)) {
      case 'review/created':
        return 'Review created notification';
      default:
        return flow?.name
    }
  };

  const notificationFlows = flows?.data?.filter(
    (flow) => flow.flow_type === 'notification' && renderTopic(flow) !== 'review/submitted'
  )

  return (
    <Page
      title="Transactional flows"
      backAction={{ content: 'Settings', url: '/settings' }}
      subtitle="Manage and configure flows for transactional events"
    >
      <LoadingContentWrapper loading={flows?.loading}>
        {notificationFlows?.map((flow) => (
          <LegacyCard key={flow.id} sectioned>
            <LegacyStack alignment="center">
              <LegacyStack.Item fill>
                <Text variant="headingMd" as="h2">{renderName(flow)}</Text>
              </LegacyStack.Item>
              <Button url={`/settings/transactional-flows/${flow?.id}`} variant="plain">Edit flow</Button>
            </LegacyStack>
          </LegacyCard>
        ))}
      </LoadingContentWrapper>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  flows: state.flows,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFlows: () => dispatch(fetchFlows()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionalFlowSettings);
