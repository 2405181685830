import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import useLastLocation from 'utils/useLastLocation';
import moment from 'moment';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import Oauth from 'utils/oauth';
import { showToast } from 'redux/toast';
import { fetchAppInstalls } from 'redux/appInstalls';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import {
  Page,
  Badge,
  Banner,
  Thumbnail,
  LegacyCard,
  Link,
  TextContainer,
  List,
  PageActions,
  Button,
  Modal,
  FooterHelp,
  Select,
  TextField,
  Text,
  InlineCode,
} from "@shopify/polaris";
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import UpgradeTrigger from 'components/UpgradeTrigger';
import { appListingDetails } from 'utils/appListingDetails';
import ApiKeyModal from 'components/ApiKeyModal';
import WebhookSecretModal from 'components/WebhookSecretModal';
import GorgiasModal from 'components/GorgiasModal';
import { GlobeIcon, AppExtensionIcon, EditIcon, ViewIcon, DuplicateIcon } from "@shopify/polaris-icons";
import 'styles/routes/Integration.scss';

function Integration(props) {
  const lastLocation = useLastLocation();
  const replace = props.history.replace;
  const slug = props.match.params.slug;
  const { planFeatureSets, fetchPlanFeatureSets } = props;
  const appDetails = appListingDetails[slug];
  const [loading, setLoading] = useState(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [working, setWorking] = useState(false);
  const [installing, setInstalling] = useState(false);
  const [app, setApp] = useState(null);
  const [appInstall, setAppInstall] = useState(null);
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);
  const [showWebhookSecretModal, setShowWebhookSecretModal] = useState(false);
  const [showGorgiasModel, setShowGorgiasModel] = useState(false);
  const [showUninstallModal, setShowUninstallModal] = useState(false);
  const [showTestEventModal, setShowTestEventModal] = useState(false);
  const [showDisconnectedBanner, setShowDisconnectedBanner] = useState(true);
  const [showPendingSection] = useState(true);
  const [testEventSelected, setTestEventSelected] = useState('');

  useEffect(() => {
    if (!planFeatureSets?.data) {
      fetchPlanFeatureSets();
    }
  }, [fetchPlanFeatureSets, planFeatureSets?.data]);

  useEffect(() => {
    async function fetchApp() {
      try {
        const appResponse = await axios.get(`/api/v1/apps/${slug}?include=event_subscriptions`);
        setApp(appResponse.data.app);
        const appInstallResponse = await axios.get('/api/v1/app_installs', {
          params: {
            'filter[app_id]': appResponse.data.app.id
          }
        });
        if (appInstallResponse?.data?.app_installs?.length) {
          // TODO change up for when handling apps that allow multi install
          setAppInstall(appInstallResponse.data.app_installs[0]);
        }
        setLoading(false);
      } catch (e) {
        replace('/integrations');
      }
    }
    if (!app) {
      fetchApp();
    }
  }, [slug, app, replace]);

  const handleSelectChange = useCallback((value) => setTestEventSelected(value), []);

  const installApp = () => {
    if (planFeatureSets?.data?.pf_integrations === false) {
      setShowUpgradeModal(true);
      return;
    }

    if (appInstall?.provider === 'shopify') {
      Oauth({ action: '/api/v1/oauth/shopify', body: { shop: appInstall?.uid } });
      return;
    }

    if (app?.slug === 'aftership') {
      setShowWebhookSecretModal(true);
    } else if (app?.slug === 'gorgias') {
      setShowGorgiasModel(true);
    } else if (app?.auth_type === 'api_key') {
      setShowApiKeyModal(true);
    } else if (app?.auth_type === 'oauth') {
      setInstalling(true);
      Oauth({ action: app.auth_url });
    }
  };

  const uninstallApp = async () => {
    setWorking(true);
    try {
      await axios.delete(`/api/v1/app_installs/${appInstall.id}`);
      props.showToast(`${appDetails?.name} uninstalled`);
      setAppInstall(null);
      props.fetchAppInstalls();
    } catch(e) {
      props.showToast(`Error uninstalling ${appDetails?.name} please try again`, true);
      trackEvent(`Error: ${appDetails?.name} listing - Uninstall`, { statusCode: e?.response?.status });
    } finally {
      setWorking(false);
      setShowUninstallModal(false);
    }
  };

  const apiKeyInstall = async (apiKey) => {
    setWorking(true);
    try {
      const response = await axios.post('/api/v1/app_installs', {
        app_install: {
          app_id: app.id,
          access_token: apiKey
        }
      });
      props.showToast(`${appDetails?.name} installed`);
      setAppInstall(response.data.app_install);
      props.fetchAppInstalls();
    } catch(e) {
      if (e?.response?.status === 400) {
        trackEvent(`Error: ${appDetails?.name} listing install - Invalid key`, { statusCode: e?.response?.status });
        throw e;
      }
      props.showToast(`Error installing ${appDetails?.name} please try again`, true);
      trackEvent(`Error: ${appDetails?.name} listing - Install`, { statusCode: e?.response?.status });
    } finally {
      setWorking(false);
    }
  };

  const apiKeyUpdate = async (apiKey) => {
    setWorking(true);
    try {
      const response = await axios.put(`/api/v1/app_installs/${appInstall.id}`, {
        app_install: {
          access_token: apiKey
        }
      });
      props.showToast(`${appDetails?.name} API key updated`);
      setAppInstall(response.data.app_install);
      props.fetchAppInstalls();
    } catch (e) {
      if (e?.response?.status === 400) {
        trackEvent(`Error: ${appDetails?.name} listing update - Invalid key`, { statusCode: e?.response?.status });
        throw e;
      }
      props.showToast(`Error updating ${appDetails?.name} API key please try again`, true);
      trackEvent(`Error: ${appDetails?.name} listing - Edit API key`, { statusCode: e?.response?.status });
    } finally {
      setWorking(false);
    }
  };

  const sendTestEvent = async () => {
    setWorking(true);
    const event = app?.event_subscriptions?.find((e) => e.id === parseInt(testEventSelected));
    try {
      await axios.post('/api/v1/events', {
        event: {
          action: event.action,
          subject: event.subject,
          app_install_id: appInstall.id
        }
      });
      props.showToast('Test event sent');
      setShowTestEventModal(false);
      setTestEventSelected('');
    } catch {
      props.showToast('Error sending test event, please try again', true);
      trackEvent(`Error: ${appDetails?.name} listing - Send test event`);
    } finally {
      setWorking(false);
    }
  };

  const supportedEvents = app?.event_subscriptions?.filter((e) => {
    const LEGACY_EVENTS = [
      'incentive/rewarded',
      'order/fulfilled',
      'order/targeted-by-campaign',
      'response/created',
      'review/created',
    ];
    const name = `${e?.subject}/${e?.action}`;
    return !LEGACY_EVENTS.includes(name);
  });

  const copy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      props.showToast(`Webhook URL copied to clipboard`);
    } catch (e) {
      // error
    }
  };

  const getStatusStyle = (state) => {
    if (state === 'connected') {
      return 'success';
    } else if (state === 'pending') {
      return 'warning';
    } else {
      return 'critical';
    }
  }

  const getStateBadge = () => {
    let badge = <></>

    if (appInstall?.state === 'pending') {
      <Badge tone="warning">Pending</Badge>
    } else if (appInstall?.state === 'connected') {
      <Badge tone="success">Installed</Badge>
    }

    return badge;
  }

  return (
    <LoadingPageWrapper loading={loading || planFeatureSets.loading}>
      <Page
        title={appDetails?.name}
        titleMetadata={ getStateBadge() }
        backAction={{ content: 'Integrations', url: lastLocation || '/integrations' }}
        thumbnail={
          <Thumbnail
            source={appDetails?.icon}
            alt={`${appDetails?.name} icon`}
          />
        }
        secondaryActions={[
          {
            content: 'Website',
            icon: GlobeIcon,
            external: true,
            url: appDetails?.websiteUrl,
          },
          {
            content: 'App listing',
            icon: AppExtensionIcon,
            external: true,
            url: appDetails?.shopifyAppUrl,
          }
        ].filter(action => action.url)}
      >
        {appInstall?.id && appInstall?.state === 'disconnected' && showDisconnectedBanner &&
          <div className="mb-4">
            <Banner
              tone="critical"
              title="App disconnected"
              onDismiss={() => setShowDisconnectedBanner(false)}
              action={{
                content: 'Reconnect',
                onAction: () => { installApp(); trackEvent(`${appDetails?.name} listing disconnected banner - Reconnect`) },
                loading: installing
              }}
            >
              <p>{appDetails?.name} has been disconnected. Use the button below to reconnect this app.</p>
            </Banner>
          </div>
        }
        {appInstall?.id && appInstall?.state === 'pending' && showPendingSection &&
          <LegacyCard title='Complete App Setup'>
            <LegacyCard.Section>
              <TextContainer>
                <p> Please visit your <Link url="https://admin.aftership.com/notifications/webhooks">AfterShip admin panel</Link> and add the following Webhook URL:</p>
                <TextField value={appInstall?.webhook_url} readOnly connectedRight={<Button size="large" icon={DuplicateIcon} onClick={() => copy(appInstall?.webhook_url)}>Copy </Button>} />
              </TextContainer>
            </LegacyCard.Section>
          </LegacyCard>
        }
        <LegacyCard title={`About ${appDetails?.name}`}>
          <LegacyCard.Section>
            <TextContainer>
            {(app?.sends_messages || app?.slug === 'aftership') &&
              <Banner tone="info">
                This app provides flow action functionality. Make sure to <Link url="/flows" onClick={() => trackEvent(`${appDetails?.name} listing banner - Update flows settings`)}>update your flows</Link> accordingly.
              </Banner>
            }
              <p>{appDetails?.description}</p>
              <p>
                <Text variant="bodyMd" as="span" fontWeight="semibold">Features:</Text>
              </p>
              <List type="bullet">
                {appDetails?.newEventFeatures?.map((f, i) => <List.Item key={i}>{f}</List.Item>)}
              </List>
            </TextContainer>
          </LegacyCard.Section>
          { !!app?.event_subscriptions?.length &&
            <LegacyCard.Section
              title="Supported events"
              actions={appInstall?.id && [{
                content: 'Send test event',
                icon: ViewIcon,
                onAction: () => { setShowTestEventModal(true); }
              }]}>
                <div className="integration-events-grid">
                  <div className="integration-grid-title">
                    <Text variant="bodyMd" as="span" fontWeight="semibold">Event name</Text>
                  </div>
                  <div className="integration-grid-title">
                    <Text variant="bodyMd" as="span" fontWeight="semibold">Description</Text>
                  </div>
                  {supportedEvents.map((e, i) =>
                    <React.Fragment key={i}>
                      <div className="integration-grid-item">
                        <Text variant="bodyMd" as="span"><InlineCode>{e.name}</InlineCode></Text>
                      </div>
                      <div className="integration-grid-item">{e.description}</div>
                    </React.Fragment>
                  )}
                </div>
            </LegacyCard.Section>
          }
          { appInstall?.id &&
            <LegacyCard.Section subdued title="Installation details">
              <TextContainer spacing="tight">
                <div className="d-flex align-items-center">
                  <p className="mr-2">
                    Status:
                  </p>
                  <p>
                    <Text tone={getStatusStyle(appInstall.state)}>
                      <span style={{ textTransform: 'capitalize' }}>{appInstall.state}</span>
                    </Text>
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="mr-2">
                    Installed on:
                  </p>
                  {moment(appInstall.created_at).format('MMMM Do, YYYY')}
                </div>
                {app.auth_type === 'api_key' && appInstall.access_token &&
                  <div className="d-flex align-items-center">
                    <p className="mr-2">
                      API key:{' '}
                    </p>
                    <p className="mr-2" style={{overflowX: 'auto'}}>
                      <Text variant="bodyMd" as="span"><InlineCode>{appInstall.access_token}</InlineCode></Text>
                    </p>
                    <Button

                      onClick={() => {setShowApiKeyModal(true); trackEvent(`${appDetails?.name} listing - Edit API key`) }}
                      icon={EditIcon}
                      variant="plain"></Button>
                  </div>
                }
              </TextContainer>
            </LegacyCard.Section>
          }
        </LegacyCard>
        <PageActions
          primaryAction={{
            content: 'Install',
            disabled: appInstall?.id || working,
            onAction: () => { installApp(); trackEvent(`${appDetails?.name} listing - Install`) },
            loading: installing
          }}
          secondaryActions={[{
            content: 'Uninstall',
            destructive: true,
            disabled: !appInstall?.id || app?.store_provider || working || installing,
            onAction: () => { setShowUninstallModal(true); trackEvent(`${appDetails?.name} listing - Uninstall`)  }
          }]}
        />
        { appDetails?.helpGuide &&
          <FooterHelp>
            Need help setting up {appDetails?.name}? Check out this{' '}
            <Link external url={appDetails.helpGuide}>
              help doc
              </Link>
          </FooterHelp>
        }
      </Page>
      <ApiKeyModal
        showModal={showApiKeyModal}
        setShowModal={setShowApiKeyModal}
        installed={appInstall?.id}
        app={app}
        appDetails={appDetails}
        install={apiKeyInstall}
        update={apiKeyUpdate}
      />
      <WebhookSecretModal
        showModal={showWebhookSecretModal}
        setShowModal={setShowWebhookSecretModal}
        installed={appInstall?.id}
        app={app}
        appDetails={appDetails}
        install={apiKeyInstall}
        update={apiKeyUpdate}
        showToast={props.showToast}
        webhookUrl={appInstall?.webhook_url}
      />
      { app?.slug === 'gorgias' &&
        <GorgiasModal
          showModal={showGorgiasModel}
          setShowModal={setShowGorgiasModel}
          appDetails={appDetails}
        />
      }
      <Modal
        open={showTestEventModal}
        title={'Send test event'}
        onClose={() => !working && setShowTestEventModal(false)}
        primaryAction={{
          content: 'Send',
          loading: working,
          disabled: !testEventSelected,
          onAction: () => { sendTestEvent(); trackEvent('Send test event modal - Send') }
        }}
        secondaryActions={[{
          content: 'Cancel',
          disabled: loading,
          onAction: () => { setShowTestEventModal(false); trackEvent('Send test event modal - Cancel') },
        }]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Send a sample event to test your integration. The event will be targeted at your Junip account with the follow details:</p>
            <div>
              <p><Text variant="bodyMd" as="span" fontWeight="semibold">Email:</Text> {props.user.data?.email}</p>
              <p><Text variant="bodyMd" as="span" fontWeight="semibold">Phone number:</Text> {props.user.data?.phone || 'Not set'}</p>
            </div>
            <p>You can update these settings by editing your Junip <Link url="/settings/user">user settings</Link>.</p>
            <Select
              label="Select an event to test:"
              options={
                supportedEvents?.length ?
                  [
                    {label: '--Select--', value: ''},
                    ...supportedEvents?.map((e) => { return {label: e.name, value: `${e.id}`} })
                  ]
                :
                  []
              }
              onChange={handleSelectChange}
              value={testEventSelected}
            />
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Modal
        open={showUninstallModal}
        title={`Uninstall ${appDetails?.name}?`}
        onClose={() => !working && setShowUninstallModal(false)}
        primaryAction={{
          content: 'Uninstall',
          loading: working,
          destructive: true,
          onAction: () => { uninstallApp(); trackEvent(`Uninstall ${appDetails?.name} modal - Uninstall`) }
        }}
        secondaryActions={[{
          content: 'Cancel',
          disabled: loading,
          onAction: () => { setShowUninstallModal(false); trackEvent(`Uninstall ${appDetails?.name} modal - Cancel`) },
        }]}
      >
        <Modal.Section>
          <p>Are you sure you want to uninstall {appDetails?.name}?</p>
        </Modal.Section>
      </Modal>
      <Modal
        open={showUpgradeModal}
        title="Upgrade required"
        onClose={() => setShowUpgradeModal(false)}
        secondaryActions={[{
          content: 'Close',
          onAction: () => setShowUpgradeModal(false)
        }]}
      >
        <UpgradeTrigger
          feature={'pf_integrations'}
          title={'Upgrade to use integrations'}
          description={'You need to upgrade your plan to use integrations'}
          cardWrapper={false}
        />
      </Modal>
    </LoadingPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  planFeatureSets: state.planFeatureSets,
  stores: state.stores
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlanFeatureSets: () => dispatch(fetchPlanFeatureSets()),
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchAppInstalls: () => dispatch(fetchAppInstalls())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Integration);
