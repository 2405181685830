import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showToast } from 'redux/toast';
import axios from 'axios';
import pako from 'pako';
import initSqlJs from 'sql.js';
// lets webpack know to copy the wasm file to assets
// eslint-disable-next-line import/no-webpack-loader-syntax
import sqlWasm from '!!file-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm';
import { fetchSlice, fetchSliceDemo } from 'redux/slice';

const BASE_FILE_NAME = 'junipAnalytics.db.gz';

export default function useLoadAnalyticsDb(setLoading) {
  const [db, setDb] = useState(null);
  const [initialzedDb, setInitializedDb] = useState(false);
  const [fileName, setFileName] = useState(null);

  const params = new URLSearchParams(window?.location?.search);
  const previewMode = params.get('preview-mode') === 'true';
  const slice = useSelector(state => state.slice);
  const stores = useSelector(state => state.stores);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!slice.data) {
      if (previewMode) {
        dispatch(fetchSliceDemo());
      } else {
        dispatch(fetchSlice());
      }
    }
  }, [slice.data, dispatch, previewMode]);

  useEffect(() => {
    if (previewMode) {
      setFileName(`demo-${BASE_FILE_NAME}`);
    } else if (stores?.data) {
      const scopedStore = stores.data?.find((store) => store.scoped);
      if (scopedStore) {
        setFileName(`${scopedStore.id}-${BASE_FILE_NAME}`);
      }
    }
  }, [stores?.data, previewMode]);

  useEffect(() => {
    async function fetchDbFromS3() {
      const axiosInstance = axios.create({
        baseURL: slice.data,
        responseType: 'arraybuffer',
      });
      return await axiosInstance.get().then(res => res.data);
    }

    async function readDbFromOpfs() {
      const opfsRoot = await navigator.storage.getDirectory();
      const dbFile = await opfsRoot.getFileHandle(fileName);
      return await dbFile.getFile();
    }

    async function setDbFile(SQL, file) {
      file = pako.inflate(file); // ungzip the db before saving to state
      const newDb = new SQL.Database(file);
      setDb(newDb);
      setInitializedDb(true);
    }

    async function writeDbToOpfs(file) {
      try {
        const opfsRoot = await navigator.storage.getDirectory();
        const dbFile = await opfsRoot.getFileHandle(fileName, { create: true });
        const writable = await dbFile.createWritable();

        // write to OPFS
        await writable.write(file);
        await writable.close();
      } catch (e) {
        // No - op: browser does not support OPFS writing
      }
    }

    async function loadDb() {
      const FILE_TIMEOUT = 15 * 60 * 1000; // 15 minutes
      setLoading(true);
      const SQL = await initSqlJs({ locateFile: () => sqlWasm });
      let fileData = null;

      try {
        // Try to read from OPFS if browser supports it and we're not in preview mode
        const opfsFile = await readDbFromOpfs();
        fileData = await opfsFile.arrayBuffer();

        if (!fileData?.byteLength || ((Date.now() - (opfsFile?.lastModified || 0)) > FILE_TIMEOUT)) {
          // File either does not exist or is older than 15 minutes
          fileData = await fetchDbFromS3();

          // Attempt to write to OPFS
          writeDbToOpfs(fileData);
        }

        await setDbFile(SQL, fileData);
      } catch (e) {
        try {
          fileData = await fetchDbFromS3();
          await setDbFile(SQL, fileData);

          // Attempt to write to OPFS if browser supports it and we're not in preview mode
          writeDbToOpfs(fileData);
        } catch (e) {
          // If link has expired, fetch a new one and try again
          if (e?.response?.status === 403) {
            if (previewMode) {
              dispatch(fetchSliceDemo());
            } else {
              dispatch(fetchSlice());
            }
          } else if (e?.response?.status === 404) {
            // No-op: file does not exist yet
          } else {
            dispatch(showToast(
              'Unable to load analytics database. Please try again later.',
              true
            ));
          }
        }
      } finally {
        setLoading(false);
      }
    }

    if (!initialzedDb && slice.data && fileName) {
      loadDb();
    }
  }, [setLoading, slice.data, initialzedDb, dispatch, fileName, previewMode]);

  return { db, initialzedDb, previewMode };
}
