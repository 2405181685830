import React, { useState, useCallback } from 'react';
import { hexToHsv, hsvToHex } from 'utils/colors';
import {
  ColorPicker,
  Button,
  Popover,
  TextField,
} from '@shopify/polaris';

import 'styles/components/ColorPicker.scss';

const ColorPickerButton = (props) => {
  const { colorKey, colorValue, handleInputChange } = props;
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const handleTextChange = useCallback((value) => handleInputChange(colorKey)(value.replace(/[^A-Fa-f0-9#]/g, '')), [colorKey, handleInputChange]);

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      <div className="color-button-wrapper">
        <div className="color-input" style={{backgroundColor: colorValue}}></div>
      </div>
    </Button>
  );

  const handleChange = (field) => {
    return (color) => {
      if (!color) {
        color = {};
      }
      let h = color.hue || 0;
      let s = color.saturation * 100 || 0;
      let v = color.brightness * 100 || 0;
      let hex = hsvToHex(h, s, v);
      handleInputChange(field)(hex);
    }
  };

  const getHsv = (color) => {
    if (!color) {
      color = "#000000";
    }
    let { h, s, v } = hexToHsv(color);
    return {hue: h, saturation: s / 100, brightness: v / 100};
  };

  return (
    <TextField
      value={colorValue}
      onChange={handleTextChange}
      type="text"
      pattern="/#([a-f0-9]{3}){1,2}\b/i"
      maxLength={7}
      error={!colorValue?.includes?.('#') && 'Please make sure your color format is correct (ex #15AD96)'}
      connectedLeft={
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={togglePopoverActive}
        >
          <div className="p-3">
            <ColorPicker onChange={handleChange(colorKey)} color={getHsv(colorValue)} />
          </div>
        </Popover>
      }
    />
  );
};

export default ColorPickerButton;
