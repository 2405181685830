import React from 'react';
import logo from 'assets/images/logo.svg';
import logoWhite from 'assets/images/logoWhite.svg';

export default function JunipLogo({ height = '21px' }) {
  return (
    <>
      <picture>
        <source
          srcSet={logoWhite}
          media="(prefers-color-scheme: dark)"
        />
        <img height={height} className="junip-logo" src={logo} alt="Junip Logo" />
      </picture>
    </>
  );
}
