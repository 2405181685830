import React, { useState } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import {
  Button,
  Modal
} from '@shopify/polaris';

export default function SkipOnboarding({ disabled }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="py-10 text-center">
      <Button

        disabled={disabled}
        onClick={() => { setShowModal(true); trackEvent('Onboarding - Skip start guide') }}
        variant="plain">
        Skip start guide
      </Button>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title="Skip start guide?"
        primaryAction={{
          content: 'Yes, skip start guide',
          onAction: () => { setShowModal(false); trackEvent('Onboarding skip modal - Yes, skip start guide') },
          url: '/home',
          destructive: true
        }}
        secondaryActions={[{
          content: 'Cancel',
          onAction: () => { setShowModal(false); trackEvent('Onboarding skip modal - Cancel') }
        }]}
      >
        <Modal.Section>
          <p>Are you sure you want to skip the start guide? Any missed start guide steps will appear on the "Home" section of the Junip admin.</p>
        </Modal.Section>
      </Modal>
    </div>
  );
}
