import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import {
  Banner,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Collapsible,
  InlineStack,
  Link,
  List,
  Text,
} from "@shopify/polaris";

import { useDispatch, useSelector } from "react-redux";
import ProductReviewsWidgetWrapper from "components/widgets/ProductReviewsWidgetWrapper";
import StarRatingWidgetWrapper from "components/widgets/StarRatingWidgetWrapper";
import UgcGalleryWidgetWrapper from "components/widgets/UgcGalleryWidgetWrapper";
import CarouselWidgetWrapper from "components/widgets/CarouselWidgetWrapper";
import DedicatedReviewsPageWidgetWrapper from "components/widgets/DedicatedReviewsPageWidgetWrapper";
import { useState } from 'react';
import StoreSnippetActionBanner from 'components/StoreSnippetActionBanner';
import CopyableSnippet from 'components/CopyableSnippet';
import InstallStepWrapper from 'components/InstallStepWrapper';

const HELP_DOC = 'https://help.junip.co/en/articles/4606706-manually-installing-the-junip-snippets';
const REVIEWS_PAGE_HELP_DOC = 'https://help.junip.co/en/articles/4607259-dedicated-reviews-page';

export default function SnippetInstallTab({ selectedTheme, previewUrl, switchTab }) {
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [actionStarted, setActionStarted] = useState(false);
  const [installing, setInstalling] = useState(false);
  const [uninstalling, setUninstalling] = useState(false);
  const stores = useSelector((state) => state.stores);
  const activeStore = stores?.data?.find((store) => store.scoped);

  const shopifyAdminUrl = `https://${activeStore?.app_install?.uid}/admin`

  const actionCallback = () => {
    setActionStarted(false);
  };

  const installSnippet = async () => {
    setInstalling(true);
    try {
      await axios.post(`/api/v1/store_snippet_actions`, {
        store_snippet_action: {
          action: 'setup',
          theme_remote_id: selectedTheme
        }
      });
      dispatch(showToast('Adding Junip snippets to your site'));
      setActionStarted(true);
      trackEvent('Store settings - Add snippets');
    } catch (e) {
      dispatch(showToast('Error adding the Junip snippets to your site, please try again', true));
      trackEvent('Error: Store settings - Add snippets');
    } finally {
      setInstalling(false);
    }
  };

  const uninstallSnippet = async () => {
    setUninstalling(true);
    try {
      await axios.post(`/api/v1/store_snippet_actions`, {
        store_snippet_action: {
          action: 'teardown',
          theme_remote_id: selectedTheme
        }
      });
      dispatch(showToast('Removing Junip snippets from your site'));
      setActionStarted(true);
      trackEvent('Store settings - Remove snippets');
    } catch (e) {
      dispatch(showToast('Error removing the Junip snippets from your site, please try again', true));
      trackEvent('Error: Store settings - Remove snippets');
    } finally {
      setUninstalling(false);
    }
  };

  const unavailableInstall = (
    <Box>
      <Banner
        tone="info"
      >
        Snippet installation is not available for this widget. Use{' '}
        <Link onClick={() => switchTab('app-embed')}>app blocks</Link> or{' '}
        <Link onClick={() => switchTab('html')}>HTML</Link>{' '}
        instead.
      </Banner>
    </Box>
  );

  return (
    <BlockStack gap="400">
      <Card>
        <BlockStack gap="400">
          <InlineStack align="space-between" blockAlign="center">
            <Text variant="headingSm" as="h1">Automatic snippet installation</Text>
            <Button
              onClick={() => setCollapsed(!collapsed)}
              aria-expanded={collapsed}
              aria-controls="snippet-installation"
              variant="plain"
            >
              {collapsed ? 'Show more' : 'Show less'}
            </Button>
          </InlineStack>
        </BlockStack>
        <Collapsible open={!collapsed} id="snippet-installation">
          <Box paddingBlockStart="400">
            <BlockStack gap="400">
              <StoreSnippetActionBanner actionStarted={actionStarted} actionCallback={actionCallback} />
              <Text>When you run the automatic installation, Junip will:</Text>
              <List type="bullet">
                <List.Item>Add the snippet files to your theme code</List.Item>
                <List.Item>Install the product reviews widget on your product page</List.Item>
                <List.Item>Install the star reviews widget on your product page</List.Item>
              </List>
              <Text>We’ll also attempt to add the star reviews to your collections page - however, this typically must be done manually.</Text>
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={installSnippet}
                  loading={installing}
                  disabled={installing || uninstalling}
                >
                  Add snippets
                </Button>
                <Button
                  tone="critical"
                  onClick={uninstallSnippet}
                  loading={uninstalling}
                  disabled={installing || uninstalling}
                >
                  Remove snippets
                </Button>
                <Button
                  variant="plain"
                  external
                  url={HELP_DOC}
                >
                  View installation guide
                </Button>
              </ButtonGroup>
            </BlockStack>
          </Box>
        </Collapsible>
      </Card>
      <ProductReviewsWidgetWrapper>
        <InstallStepWrapper>
          <BlockStack gap="300">
            <Text>To install this widget:</Text>
            <List type="number" gap="loose">
              <BlockStack gap="200">
                <List.Item>Add the required snippet file to your theme by clicking "Add snippets" above.</List.Item>
                <List.Item>
                  <p className="mb-2">Insert this snippet into your product page template code:</p>
                  <CopyableSnippet snippet={`{% render 'junip-product-review', product: product %}`} />
                </List.Item>
                <List.Item>
                  To see if the installation was successful, <Link url={previewUrl} target="_blank">preview with placeholder content.</Link>
                </List.Item>
              </BlockStack>
            </List>
            <Box>
              <Button
                variant="plain"
                external
                url={HELP_DOC}
              >
                Get detailed installation instructions
              </Button>
            </Box>
          </BlockStack>
        </InstallStepWrapper>
      </ProductReviewsWidgetWrapper>
      <StarRatingWidgetWrapper>
        <InstallStepWrapper>
          <BlockStack gap="300">
            <Text>To install this widget:</Text>
            <List type="number" gap="loose">
              <BlockStack gap="200">
                <List.Item>Add the required snippet file to your theme by clicking "Add snippets" above.</List.Item>
                <List.Item>
                  <p className="mb-2">Insert this snippet where you want it to appear:</p>
                  <CopyableSnippet snippet={`{% render 'junip-product-summary', product: product %}`} />
                </List.Item>
                <List.Item>
                  To see if the installation was successful, <Link url={previewUrl} target="_blank">preview with placeholder content.</Link>
                </List.Item>
              </BlockStack>
            </List>
            <Box>
              <Button
                variant="plain"
                external
                url={HELP_DOC}
              >
                Get detailed installation instructions
              </Button>
            </Box>
          </BlockStack>
        </InstallStepWrapper>
      </StarRatingWidgetWrapper>
      <DedicatedReviewsPageWidgetWrapper>
        <InstallStepWrapper>
          <BlockStack gap="300">
            <List type="number" gap="loose">
              <BlockStack gap="200">
                <List.Item>Add the review page template file to your theme by clicking "Add snippets" above.</List.Item>
                <List.Item>
                  Visit your Shopify admin and{' '}
                  <Link target="_blank" url={`${shopifyAdminUrl}/pages/new`}>add a new page</Link>{' '}
                  to your site.
                </List.Item>
                <List.Item>
                  Select <b>page.junip-reviews</b> from the template options on the righthand side.
                </List.Item>
                <List.Item>
                  Title and save your new page.
                </List.Item>
                <List.Item>
                  Use the theme editor to edit the page template as desired.
                </List.Item>
                <List.Item>
                 View your page in <Link url={previewUrl} target="_blank">preview mode</Link> to check if the installation was successful.
                </List.Item>
              </BlockStack>
            </List>
            <Box>
              <Button
                variant="plain"
                external
                url={REVIEWS_PAGE_HELP_DOC}
              >
                Get detailed installation instructions
              </Button>
            </Box>
          </BlockStack>
        </InstallStepWrapper>
      </DedicatedReviewsPageWidgetWrapper>
      <UgcGalleryWidgetWrapper children={unavailableInstall} />
      <CarouselWidgetWrapper children={unavailableInstall} />
    </BlockStack>
  );
}
