import {
  Page,
  Banner,
  BlockStack,
  IndexTable,
  Text,
  Button,
  LegacyStack,
  Popover,
  Card,
  EmptyState,
  OptionList,
} from '@shopify/polaris';
import { ExportIcon } from "@shopify/polaris-icons";
import { useState, useCallback } from 'react';
import useSubmissionRate from 'utils/useSubmissionRate';
import createCsv from 'utils/createCsv';

export default function ReportsSubmissionRateOverTime() {
  const [loading, setLoading] = useState(false);

  const [dateRangeActive, setDateRangeActive] = useState(false);
  const [dateRange, setDateRange] = useState('last-3-months');
  const [timePeriodActive, setTimePeriodActive] = useState(false);
  const [timePeriod, setTimePeriod] = useState('monthly');

  const { tableData } = useSubmissionRate(setLoading, dateRange, timePeriod);

  const toggleDateRangeActive = useCallback(() => setDateRangeActive((dateRangeActive) => !dateRangeActive), []);
  const toggleTimePeriodActive = useCallback(() => setTimePeriodActive((timePeriodActive) => !timePeriodActive), []);

  const dateRangeOptions = [
    timePeriod !== 'monthly' ? { label: 'Last 7 days', value: 'last-7-days' } : null,
    { label: 'Last 30 days', value: 'last-30-days' },
    { label: 'Last 3 months', value: 'last-3-months' },
    { label: 'Last 12 months', value: 'last-12-months' },
  ].filter((option) => option !== null);
  const timePeriodOptions = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Weekly', value: 'weekly' }
  ];

  const dateRangeActivator = (
    <Button
      onClick={toggleDateRangeActive}
      disclosure='down'
      disabled={loading}
    >
      {dateRangeOptions.find(d => d.value === dateRange)?.label}
    </Button>
  );
  const timePeriodActivator = (
    <Button
      onClick={toggleTimePeriodActive}
      disclosure='down'
      disabled={loading}
    >
      {timePeriod === 'monthly' ? 'Monthly' : 'Weekly'}
    </Button>
  );

  const filters = (
    <LegacyStack spacing='tight'>
      <LegacyStack.Item>
        <Popover
          active={dateRangeActive}
          activator={dateRangeActivator}
          onClose={toggleDateRangeActive}
          fluidContent
          preferredAlignment='left'
        >
          <OptionList
            options={dateRangeOptions}
            onChange={(value) => {
              setDateRange(value[0]);
              toggleDateRangeActive();
            }}
            selected={dateRange}
          />
        </Popover>
      </LegacyStack.Item>
      <LegacyStack.Item>
        <Popover
          active={timePeriodActive}
          activator={timePeriodActivator}
          onClose={toggleTimePeriodActive}
          fluidContent
          preferredAlignment='left'
        >
          <OptionList
            options={timePeriodOptions}
            onChange={(value) => {
              setTimePeriod(value[0]);
              if (value[0] === 'monthly' && dateRange === 'last-7-days') {
                setDateRange('last-30-days');
              }
              toggleTimePeriodActive();
            }}
            selected={timePeriod}
          />
        </Popover>
      </LegacyStack.Item>
    </LegacyStack>
  );

  const resourceName = {
    singular: 'submission rate',
    plural: 'submission rates',
  };

  const rowMarkup = tableData.map(
    (
      { date, submissionRate, ordersRequested, reviews, mediaReviews, mediaSubmissionRate },
      index,
    ) => (
      <IndexTable.Row id={index} key={index} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">{date}</Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">{submissionRate}% </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{ordersRequested}</IndexTable.Cell>
        <IndexTable.Cell>{reviews}</IndexTable.Cell>
        <IndexTable.Cell>{mediaReviews}</IndexTable.Cell>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">{mediaSubmissionRate}%</Text>
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const emptyStateMarkup = (
    <Card>
      <EmptyState heading='No data for selected date range'>
        <p>No review requests were sent within this date range. Submission rate is approximated based on the number of requests sent compared with reviews submitted. </p>
      </EmptyState>
    </Card>
  );

  const handleExport = () => {
    const headers = ['Period', 'Submission rate', 'Orders requested', 'Reviews', 'Media reviews', 'Media submission rate'];
    const csv = createCsv(headers, tableData.map(row => [row.date, row.submissionRate + '%', row.ordersRequested, row.reviews, row.mediaReviews, row.mediaSubmissionRate + '%']));
    const encodedUri = encodeURI(csv);
    window.open(encodedUri);
  }

  return (
    <Page
      title='Submission rate over time'
      fullWidth
      backAction={{ content: 'Back', url: '/analytics/reports' }}
      secondaryActions={[
        {
          content: 'Export as CSV',
          icon: ExportIcon,
          onAction: handleExport,
        }
      ]}>
      <BlockStack gap='300'>
        <Banner>
          <p>
            Submission rate is calculated based on the number of orders for which a review request was triggered versus the number of reviews submitted. It can be used as a rough estimate of your review program’s performance.
          </p>
        </Banner>
        {filters}
        <Card padding="0">
          <IndexTable
            resourceName={resourceName}
            itemCount={tableData.length}
            emptyState={emptyStateMarkup}
            selectable={false}
            headings={[
              { title: 'Period' },
              { title: 'Submission rate' },
              { title: 'Orders requested' },
              { title: 'Reviews' },
              { title: 'Media reviews' },
              { title: 'Media submission rate' },
            ]}
          >
            {rowMarkup}
          </IndexTable>
        </Card>
      </BlockStack>
    </Page >
  );
}
