import React, { useState, useEffect } from 'react';
import Trigger from 'components/FlowTrigger';
import Condition from 'components/FlowCondition';
import Action from 'components/FlowAction';
import styles from 'styles/components/Flow.module.scss';

export default function Flow({ appInstalls, flow, messages }) {
  const [data, setData] = useState(flow);
  const [actions, setActions] = useState(flow.actions);

  useEffect(() => {
    setData(flow);
    setActions(flow.actions);
  }, [flow]);

  const addAction = (newAction) => {
    setActions(actions => [...actions, newAction]);
  };

  const updateAction = (actionId, newAction) => {
    setActions(actions => actions.map(action => (
      action.id === actionId ? newAction : action
    )));
  };

  const updateActionConditionList = (actionId, newConditions) => {
    setActions(actions => actions.map(action => (
      action.id === actionId ? { ...action, condition_list: newConditions } : action
    )));
  };

  const removeAction = (actionId) => {
    setActions(actions => actions.filter(a => a.id !== actionId));
  };

  return (
    <div className={styles.flowWrapper}>
      <div className={styles.flow}>
        <Trigger flow={data} setFlow={setData}>
          <Action appInstalls={appInstalls} flow={data} messages={messages} isNew={true} actions={actions} add={addAction} />
        </Trigger>
        <div className={styles.actionContainer}>
          {actions.map((action, index) => (
            <React.Fragment key={action.id}>
              <Condition flow={data} action={action} updateActionConditionList={updateActionConditionList} showConnector={(index + 1) < actions.length} />
              <Action appInstalls={appInstalls} flow={data} actions={actions} action={action} messages={messages} remove={removeAction} update={updateAction} />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="divider mb-4" />
      <Action appInstalls={appInstalls} flow={data} messages={messages} isNew={true} expanded={true} actions={actions} add={addAction} />
    </div>
  );
}
