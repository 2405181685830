import React, { useState } from 'react';
import { Button, LegacyCard, Icon, Text } from "@shopify/polaris";

import { DeleteIcon } from "@shopify/polaris-icons";

import styles from 'styles/components/SurveyOptions.module.scss';

export default function SurveyOptions({ options, removeOption }) {
  return (
    <>
      { options?.map((option, index) =>
        <SurveyOption
          key={`${index + Date.now()}`}
          option={option}
          index={index}
          removeOption={removeOption}
        />
      )}
    </>
  );
}

function SurveyOption({option, index, removeOption}) {
  const [loading, setLoading] = useState(false);
  const remove = () => {
    setLoading(true);
    removeOption(option?.id, index);
  };

  return (
    <LegacyCard.Section key={index}>
      <div className={styles.option}>
        <p>
          <Text variant="bodyMd" as="span" fontWeight="semibold">{option.value}</Text>
        </p>
        <Text variant="bodySm" as="p">
          {option.description}
        </Text>
        <div className={styles.deleteOption}>
          {removeOption &&
            <Button
              onClick={() => { remove(option?.id, index) }}
              loading={loading}
              size="slim"
            >
              <Icon accessibilityLabel="Delete option" source={DeleteIcon} />
            </Button>
          }
        </div>
      </div>
    </LegacyCard.Section>
  );
}
