import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page,
  ResourceItem,
  ResourceList,
  Avatar,
  LegacyCard,
} from '@shopify/polaris';
import { fetchAppInstalls } from 'redux/appInstalls';
import { fetchMessageTemplates } from 'redux/messages';
import TemplateCard from 'components/TemplateCard';
import useLoadAnalyticsDb from 'utils/useLoadAnalyticsDb';
import ReviewRequestIcon from 'assets/images/templates/review-request.svg';
import IncentiveIcon from 'assets/images/templates/incentive.svg';
import ConfirmationIcon from 'assets/images/templates/confirmation.svg';
import ReplyIcon from 'assets/images/templates/reply.svg';
import StoreRequestIcon from 'assets/images/templates/store.svg';
import LoadingWrapper from 'components/LoadingWrapper';

export default function Templates(props) {
  const appInstalls = useSelector(state => state.appInstalls);
  const messages = useSelector(state => state.messages);
  const data = messages.data;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [productReviewSent, setProductReviewSent] = useState(0);
  const [storeReviewSent, setStoreReviewSent] = useState(0);
  const [identityConfirmationSent, setIdentityConfirmationSent] = useState(0);
  const [reviewResponseSent, setReviewResponseSent] = useState(0);
  const [rewardDistributedSent, setRewardDistributedSent] = useState(0);

  const { db } = useLoadAnalyticsDb(setLoading);

  // queries
  const productReviewQuery = 'SELECT SUM(product_review_request_messages_sent) FROM analytics_daily_message_stats';
  const storeReviewQuery = 'SELECT SUM(store_review_request_messages_sent) FROM analytics_daily_message_stats';
  const identityConfirmationQuery = 'SELECT SUM(review_confirmation_messages_sent) FROM analytics_daily_message_stats';
  const reviewResponseQuery = 'SELECT SUM(review_response_messages_sent) FROM analytics_daily_message_stats';
  const rewardDistributedQuery = 'SELECT SUM(reward_distributed_messages_sent) FROM analytics_daily_message_stats';

  const resourceName = {
    singular: 'template',
    plural: 'templates',
  };

  useEffect(() => {
    if (!appInstalls.data) {
      dispatch(fetchAppInstalls());
    }
  }, [appInstalls.data, dispatch]);

  useEffect(() => {
    if (!messages.data) {
      dispatch(fetchMessageTemplates());
    }
  }, [dispatch, messages.data]);

  useEffect(() => {
    if (db) {
      setProductReviewSent(db.exec(productReviewQuery)[0]?.values[0][0]);
      setStoreReviewSent(db.exec(storeReviewQuery)[0]?.values[0][0]);
      setIdentityConfirmationSent(db.exec(identityConfirmationQuery)[0]?.values[0][0]);
      setReviewResponseSent(db.exec(reviewResponseQuery)[0]?.values[0][0]);
      setRewardDistributedSent(db.exec(rewardDistributedQuery)[0]?.values[0][0])
    }
  }, [db, productReviewQuery, storeReviewQuery, identityConfirmationQuery, reviewResponseQuery, rewardDistributedQuery]);

  // want to display product review request first and identity confirmation last
  let orderedData = null;
  if (data) {
    const productReviewRequest = data.find(item => item.message_type.slug === 'product_review_requested');
    const identityConfirmation = data.find(item => item.message_type.slug === 'review_confirmation');
    const otherTemplates = data.filter(item => item.message_type.slug !== 'product_review_requested' && item.message_type.slug !== 'review_confirmation');
    orderedData = [productReviewRequest, ...otherTemplates, identityConfirmation];
  }
  const templateItems = orderedData ? orderedData.map((item) => {
    const { id } = item;
    const messageType = item.message_type;

    let name = `${messageType.name} email template`;
    let description = '';
    let icon = ReviewRequestIcon;
    let sent = 0;

    switch (messageType.slug) {
      case 'store_review_requested':
        description = 'This message is sent to customers requesting them to leave a review for your store after they make a purchase.';
        icon = StoreRequestIcon;
        sent = storeReviewSent;
        break;
      case 'review_confirmation':
        name = 'Identity confirmation request'
        description = 'Asks customers to confirm their authorship after leaving a review via a publicly accessible form.'
        icon = ConfirmationIcon;
        sent = identityConfirmationSent;
        break;
      case 'review_response':
        description = 'This message is sent to customers when you reply to one of their reviews.'
        icon = ReplyIcon;
        sent = reviewResponseSent;
        break;
      case 'reward_distributed':
        description = 'This message is sent to customers when they receive an incentive for leaving a review.'
        icon = IncentiveIcon;
        sent = rewardDistributedSent;
        break;
      default:
        name = 'Product review request'
        description = 'Use this template to request product reviews from your customers.';
        sent = productReviewSent;
        break;
    }

    return (
      {
        id,
        url: `/flows/templates/${id}`,
        name,
        description,
        icon,
        sent,
      }
    )
  }) : [];

  return (
    <Page
      title="Email templates"
      secondaryActions={[
        {
          content: 'Edit branding',
          accessibilityLabel: 'Edit branding label',
          url: '/settings/branding',
        }]}
      divider
    >
      <LoadingWrapper loading={loading || appInstalls?.loading || messages.loading}>
        <LegacyCard>
          <ResourceList
            resourceName={resourceName}
            items={templateItems}
            renderItem={(item) => {
              const { id, url, name, description, icon, sent } = item;

              return (
                <ResourceItem
                  verticalAlignment="center"
                  id={id}
                  onClick={() => {
                    props.history.push(url);
                  }}
                  accessibilityLabel={`View details for ${name}`}
                  media={<Avatar
                    customer
                    size="lg"
                    name={name}
                    source={icon}
                  />}
                >
                  <TemplateCard name={name} description={description} id={id} sent={sent} />
                </ResourceItem>
              );
            }}
          />
        </LegacyCard>
      </LoadingWrapper>
    </Page>
  );
}
