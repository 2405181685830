import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { fetchAppInstalls } from 'redux/appInstalls';
import { fetchMessageTemplates } from 'redux/messages';
import {
  Page,
  Badge,
  Button
} from '@shopify/polaris';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import ToggleFlowStateModal from 'components/ToggleFlowStateModal';
import FlowComponent from 'components/Flow';
import { useCallback } from 'react';

import flowDetails from 'utils/flowDetails';

function Flow({ appInstalls, messages, history, match, fetchAppInstalls, fetchMessageTemplates, stores }) {
  const flowId = match.params.id;
  const replace = history.replace;

  const [loading, setLoading] = useState(true);
  const [flow, setFlow] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { name, title, subtitle } = flowDetails(flow);

  const fetchFlow = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/v1/flows/${flowId}`, {
        params: {
          include: 'trigger,actions.message_type,actions.app_install,actions.condition_list'
        }
      });

      setFlow(response.data.flow);
    } catch (e) {
      replace('/flows');
    } finally {
      setLoading(false);
    }
  }, [replace, flowId]);

  useEffect(() => {
    fetchAppInstalls();
    fetchMessageTemplates();
  }, [fetchAppInstalls, fetchMessageTemplates]);

  useEffect(() => {
    if (flowId) {
      fetchFlow();
    }
  }, [fetchFlow, flowId]);

  useEffect(() => {
    if (flow && stores?.data) {
      const store = stores?.data?.find?.(s => s.scoped === true) || {};
      if (store?.id !== flow?.store_id) {
        replace('/flows');
      }
    }
  }, [flow, stores?.data, replace]);

  const toggleState = () => {
    fetchFlow();
  };

  return (
    <LoadingPageWrapper loading={loading || appInstalls.loading || messages.loading} cards={3}>
      <Page
        title={title || `${flow?.name} flow`}
        subtitle={subtitle}
        titleMetadata={
          (name !== 'review/confirmation-required' && name !== 'review/created') ?
            <Badge tone={flow?.disabled ? 'default' : 'success'}>{flow?.disabled ? 'Disabled' : 'Active'}</Badge>
          :
            <Badge tone="critical">Required</Badge>
        }
        backAction={{
          content: 'Flows',
          url: history?.location?.pathname?.includes('/settings/transactional-flows') ? '/settings/transactional-flows' : '/flows'
        }}
        primaryAction={ flow?.disabled === true || (name !== 'review/confirmation-required' && name !== 'review/created') ?
          <Button
            variant={flow?.disabled ? 'primary' : undefined}
            onClick={() => setShowModal(true)}
          >
            {flow?.disabled ? 'Turn on' : 'Turn off'}
          </Button>
        : undefined}
        fullWidth
      >
        <FlowComponent flow={flow} appInstalls={appInstalls} messages={messages} />
      </Page>
      <ToggleFlowStateModal open={showModal} close={() => setShowModal(false)} flow={flow} callback={toggleState} />
    </LoadingPageWrapper>
  );
}

const mapStateToProps = (state) => ({
  appInstalls: state.appInstalls,
  messages: state.messages,
  stores: state.stores,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAppInstalls: () => dispatch(fetchAppInstalls()),
  fetchMessageTemplates: () => dispatch(fetchMessageTemplates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Flow);
