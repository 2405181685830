import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { fetchOrganizations } from 'redux/organizations';
import { fetchStores } from 'redux/stores';
import { showToast } from 'redux/toast';
import AdapterLink from 'utils/AdapterLink';
import UpgradeTrigger from 'components/UpgradeTrigger';
import Oauth from 'utils/oauth';
import useHash from 'utils/useHash';
import {
  Icon,
  SkeletonDisplayText,
  Popover,
  ActionList,
  Button,
  Modal,
  Select,
  FormLayout,
  TextField,
  Text,
} from "@shopify/polaris";

import { CaretDownIcon, ExchangeIcon, PlusIcon, CheckSmallIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import logoWhite from 'assets/images/logoWhite.svg';
import logoGreen from 'assets/images/logoGreen.svg';
import 'styles/components/StoreSwitcher.scss';

function StoreSwitcher({ colorScheme, stores, organizations, fetchStores, fetchOrganizations, showToast }) {
  const [hash, setHash] = useHash();
  const [popoverActive, setPopoverActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [selected, setSelected] = useState(0);
  const [shop, setShop] = useState('');

  const defaultOption = { label: '--Select an organization--', value: 0, disabled: true };
  const options = organizations?.data?.filter?.(o => o.scoped !== true).map?.(org => {
    return { label: org.name, value: org.id };
  }) || [];
  options?.sort((a, b) => a.label?.localeCompare(b.label));
  options.unshift(defaultOption);
  const currentOrg = organizations?.data?.find?.(o => o.scoped === true);

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  useEffect(() => {
    if (hash === '#connectStore') {
      setShowStoreModal(true);
      setHash('');
    }
  }, [hash, setHash]);

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);
  const handleSelectChange = useCallback((value) => setSelected(parseInt(value)), []);
  const handleShopChange = useCallback((value) =>
    setShop(value.replace(/^(https?|ftp):\/\//, '').replace(/(.myshopify.com).*/, ''))
  , []);

  const switchStore = async (id) => {
    setLoading(true);
    try {
      await axios.post(`/api/v1/stores/${id}/scope`);
      window.location.href = '/';
    } catch (e) {
      setLoading(false);
      showToast('Error switching your store, please try again', true);
      trackEvent('Error: Store switcher drop down - Select', { statusCode: e?.response?.status });
    }
  };

  const switchOrg = async () => {
    setLoading(true);
    try {
      await axios.post(`/api/v1/organizations/${selected}/scope`);
      const response = await axios.get('/api/v1/stores');
      const storeId = response.data.stores[0].id;
      switchStore(storeId);
    } catch (e) {
      setLoading(false);
      showToast('Error switching your organization, please try again', true);
      trackEvent('Error: Organization switcher modal - Select', { statusCode: e?.response?.status });
    }
  };

  const connectStore = () => {
    setLoading(true);
    const shopName = `${shop}.myshopify.com`;
    window?.localStorage?.setItem('redirectToOnboarding', true);
    Oauth({ action: '/api/v1/oauth/shopify', body: { shop: shopName } });
  };

  const switcherContent = () => {
    if (stores.loading || organizations.loading) {
      return (
        <div className="switcher-loader">
          <SkeletonDisplayText size="small" />
        </div>
      );
    };

    const store = stores?.data?.find?.(s => s.scoped === true) || {};

    if (stores?.data?.length >= 1 || organizations?.data?.length >= 1) {
      const activator = (
        <Text variant="headingMd" as="h2">
          <div onClick={togglePopoverActive} className="switcher">
            <div className="store-name">
              { store.name }
            </div>
            <Icon source={CaretDownIcon} />
          </div>
        </Text>
      );

      const items = stores.data?.map((s) => {
        return {
          suffix: s.scoped ? <Icon source={CheckSmallIcon} /> : undefined,
          content: `${s.name} (${s.url})`,
          onAction: () => { switchStore(s.id); trackEvent('Store switcher drop down - Select'); },
          disabled: loading || s.scoped
        };
      }) || [];

      return (
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={togglePopoverActive}
        >
          <Popover.Pane fixed>
            <Popover.Section>
              <div style={{ textAlign: 'center', minWidth: '200px' }}>
                <Text variant="bodyMd" as="span" fontWeight="semibold">Stores ({items?.length})</Text>
              </div>
            </Popover.Section>
          </Popover.Pane>
          <Popover.Pane>
            <ActionList
              items={[...items, {
                content: 'Connect store',
                icon: PlusIcon,
                suffix: <Icon source={ChevronRightIcon} />,
                onAction: () => { setShowStoreModal(true); trackEvent('Store switcher drop down - Connect store'); }
              }]}
            />
          </Popover.Pane>
          { organizations?.data?.length > 1 &&
            <Popover.Pane fixed>
              <Popover.Section>
                <Button

                  icon={ExchangeIcon}
                  onClick={() => {
                    setShowOrgModal(true);
                    togglePopoverActive();
                    trackEvent('Store switcher drop down - Switch organization')
                  }}
                  variant="plain">
                  Switch organization
                </Button>
              </Popover.Section>
            </Popover.Pane>
          }
        </Popover>
      );
    }

    return (
      <Text variant="headingMd" as="h2">
        <div className="store-name">{store.name}</div>
      </Text>
    );
  };

  return <>
    <div className="store-switcher">
      <AdapterLink url="/home">
        <img width="72" src={colorScheme === 'dark' ? logoWhite : logoGreen} alt="Junip logo" />
      </AdapterLink>
      { switcherContent() }
    </div>
    <Modal
      open={showOrgModal}
      title="Switch your organization"
      onClose={() => !loading && setShowOrgModal(false)}
      primaryAction={{
        content: 'Select',
        loading: loading,
        disabled: !selected,
        onAction: () => { switchOrg(); trackEvent('Organization switcher modal - Select') }
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: loading,
        onAction: () => { setShowOrgModal(false); trackEvent('Organization switcher modal - Cancel') },
      }]}
    >
      <Modal.Section>
        <FormLayout>
          <p>Current organization: <Text variant="bodyMd" as="span" fontWeight="semibold">{ currentOrg?.name }</Text></p>
          <Select
            label="Select the organization you want to switch to:"
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
    <Modal
      open={showStoreModal}
      title="Connect a store to your organization"
      onClose={() => !loading && setShowStoreModal(false)}
      primaryAction={{
        content: 'Connect store',
        loading: loading,
        disabled: !shop,
        onAction: () => { connectStore(); trackEvent('Connect store modal - Connect store') }
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: loading,
        onAction: () => { setShowStoreModal(false); trackEvent('Connect store modal modal - Cancel') },
      }]}
    >
      <Modal.Section>
        <UpgradeTrigger
          feature={'pf_store_limit'}
          title={'Upgrade to connect a store'}
          limit={stores?.data?.length}
          description={'You need to upgrade your plan in order to connect another store to this organization'}
          cardWrapper={false}
          actionCallback={() => setShowStoreModal(false)}
        >
          <FormLayout>
            <p>Current organization: <Text variant="bodyMd" as="span" fontWeight="semibold">{ currentOrg?.name }</Text></p>
            <TextField
              value={shop}
              name="store_name"
              onChange={handleShopChange}
              type="text"
              placeholder="Enter your Shopify store name"
              autoFocus={true}
              disabled={loading}
              suffix=".myshopify.com"
            />
          </FormLayout>
        </UpgradeTrigger>
      </Modal.Section>
    </Modal>
  </>;
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  organizations: state.organizations,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizations: () => dispatch(fetchOrganizations()),
  fetchStores: () => dispatch(fetchStores()),
  showToast: (message, error) => dispatch(showToast(message, error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreSwitcher);
