import React from 'react';
import { withRouter } from 'react-router-dom';
import { ResourceItem, Thumbnail, TextContainer, Badge, Text } from "@shopify/polaris";
import { appListingDetails } from 'utils/appListingDetails';

function IntegrationListItem({ app, history }) {
  const appDetails = appListingDetails[app.slug];
  const installed = app.app_install?.id;
  const isDisconnected = app.app_install?.state !== 'connected';

  const viewApp = () => {
    history.push(`/integrations/${app.slug}`, { app });
  };

  return (
    <ResourceItem
      id={app.id}
      onClick={viewApp}
      media={
        <Thumbnail alt={`${appDetails?.name} icon`} source={appDetails?.icon} />
      }
    >
      <TextContainer spacing="tight">
        <Text variant="headingMd" as="h2">
          {appDetails?.name}
          { installed && isDisconnected &&
            <>
              {' '}<Badge tone="critical">Action required</Badge>
            </>
          }
        </Text>
        <p>
          {appDetails?.description}
        </p>
      </TextContainer>
    </ResourceItem>
  );
}

export default withRouter(IntegrationListItem);
