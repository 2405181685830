import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { changesSaved, savingChanges } from 'redux/settings';
import { showToast } from 'redux/toast';

// Actions
const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
const USER_LOGOUT = 'USER_LOGOUT';

// Action Creators
export function fetchUser() {
  return async dispatch => {
    dispatch(fetchUserBegin());
    try {
      const response = await axios.get('/api/v1/users/me');
      dispatch(setUser(response.data.user));
    } catch (e) {
      dispatch(fetchUserError(e));
    }
  }
}

export function saveUser(id, settings) {
  return async dispatch => {
    const {
      first_name,
      last_name,
      email,
      phone,
      current_password,
      new_password,
      new_password_confirmation
    } = settings;

    let data = {
      user: {
        first_name,
        last_name,
        email,
        phone
      }
    };

    if ('email' in settings && !email.includes('@')) {
      dispatch(showToast('Invalid email', true));
      dispatch(savingChanges(false));
      return;
    }

    if (current_password || new_password || new_password_confirmation) {
      if (new_password !== new_password_confirmation) {
        dispatch(showToast('New password does not match new password confirmation', true));
        dispatch(savingChanges(false));
        return;
      }

      if (new_password?.length < 8) {
        dispatch(showToast('New password must be at least 8 characters long', true));
        dispatch(savingChanges(false));
        return;
      }

      data.password = current_password;
      data.user.password = new_password;
      data.user.password_confirmation = new_password_confirmation;
    }
    try {
      const response = await axios.put(`/api/v1/users/${id}`, data);
      dispatch(fetchUserSuccess(response.data.user));
      dispatch(changesSaved());
    } catch (e) {
      if (current_password) {
        dispatch(showToast('Invalid current password', true));
        dispatch(savingChanges(false));
      } else {
        dispatch(showToast('Error saving changes, please try again', true));
        dispatch(savingChanges(false));
      }
      trackEvent('Error: User settings - Save changes', { statusCode: e?.response?.status });
    }
  }
}

export const fetchUserBegin = () => ({
  type: FETCH_USER_BEGIN
});

export const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  data: { user }
});

export const fetchUserError = error => ({
  type: FETCH_USER_ERROR,
  data: { error }
});

export function setUser(user) {
  return dispatch => {
    dispatch(fetchUserSuccess(user));
  };
}

export function setLoggedOut() {
  return async dispatch => {
    return dispatch({
      type: USER_LOGOUT
    });
  }
}

export function logout() {
  return async dispatch => {
    try {
      await axios.post('/api/v1/users/sign_out');
      return dispatch({
        type: USER_LOGOUT
      });
    } catch {
      return dispatch({
        type: USER_LOGOUT
      });
    }
  }
}

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_USER_BEGIN:
      return {
        ...state,
        loggedIn: null,
        loading: true,
        error: null
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        data: action.data.user,
        loading: false,
        error: null
      };
    case FETCH_USER_ERROR:
      return {
        ...state,
        data: null,
        loggedIn: false,
        loading: false,
        error: action.data.error
      };
    case USER_LOGOUT:
      return {
        ...state,
        data: null,
        loggedIn: false,
        loading: false,
        error: null
      };
    default:
      return state;
  }
}
