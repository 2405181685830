import React, { useState } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { fetchInvitations } from 'redux/invitations';
import axios from 'axios';
import {
  LegacyCard,
  TextField,
  Form,
  FormLayout,
  Button
} from '@shopify/polaris';

function InviteUser({isOwner, fetchInvitations, showToast}) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const allowSubmit = () => {
    if (!isOwner) {
      return false;
    }
    if (loading) {
      return false;
    }
    if (!email) {
      return false;
    }
    if (email.indexOf('@') === -1) {
      return false;
    }
    return true;
  };

  const inviteUser = async () => {
    if (!allowSubmit()) {
      return;
    }
    setLoading(true);
    try {
      await axios.post('/api/v1/invitations', {
        invitation: {
          email
        }
      });
      setLoading(false);
      fetchInvitations({ state: 'pending' });
      showToast('User invitation sent');
      trackEvent('Team settings - Invite user');
    } catch(e) {
      showToast('Error sending user invite, please try again', true);
      setLoading(false);
      trackEvent('Error: Team settings - Invite user', {statusCode: e?.response?.status});
    }
  };
  return (
    <LegacyCard sectioned>
      <Form onSubmit={inviteUser}>
        <FormLayout>
          <TextField
            value={email}
            name="email"
            onChange={setEmail}
            label="Email"
            type="email"
            placeholder="User email..."
            disabled={!isOwner}
            helpText={!isOwner && 'Only owners can invite users'}
          />
          <Button  disabled={!allowSubmit()} loading={loading} submit variant="primary">Invite user</Button>
        </FormLayout>
      </Form>
    </LegacyCard>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchInvitations: (params) => dispatch(fetchInvitations(params))
});

export default connect(
  null,
  mapDispatchToProps
)(InviteUser);
