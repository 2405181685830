import axios from 'axios';

// Actions
const FETCH_INVITATIONS_BEGIN = 'FETCH_INVITATIONS_BEGIN';
const FETCH_INVITATIONS_SUCCESS = 'FETCH_INVITATIONS_SUCCESS';
const FETCH_INVITATIONS_ERROR = 'FETCH_INVITATIONS_ERROR';

// Action Creators
export function fetchInvitations({ state, email, organization_id } = {}) {
  return async dispatch => {
    dispatch(fetchInvitationsBegin());
    try {
      const response = await axios.get('/api/v1/invitations', {
        params: {
          'include': 'organization',
          'filter[state]': state,
          'filter[email]': email,
          'filter[organization_id]': organization_id
        }
      });
      dispatch(fetchInvitationsSuccess(response.data.invitations));
    } catch (e) {
      dispatch(fetchInvitationsError(e));
    }
  }
}

export const fetchInvitationsBegin = () => ({
  type: FETCH_INVITATIONS_BEGIN
});

export const fetchInvitationsSuccess = data => ({
  type: FETCH_INVITATIONS_SUCCESS,
  data
});

export const fetchInvitationsError = error => ({
  type: FETCH_INVITATIONS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_INVITATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_INVITATIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_INVITATIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
