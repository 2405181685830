import axios from 'axios';

// Actions
const FETCH_REVIEW_TAG_LINKS_BEGIN = 'FETCH_REVIEW_TAG_LINKS_BEGIN';
const FETCH_REVIEW_TAG_LINKS_SUCCESS = 'FETCH_REVIEW_TAG_LINKS_SUCCESS';
const FETCH_REVIEW_TAG_LINKS_ERROR = 'FETCHREVIEW_TAG_LINKS__ERROR';

// Action Creators
export function fetchReviewTagLinks() {
  return async dispatch => {
    dispatch(fetchReviewTagLinksBegin());
    try {
      const response = await axios.get('/api/v1/review_tag_links');
      dispatch(fetchReviewTagLinksSuccess(response.data.review_tag_links));
    } catch (e) {
      dispatch(fetchReviewTagLinksError(e));
    }
  }
}

export const fetchReviewTagLinksBegin = () => ({
  type: FETCH_REVIEW_TAG_LINKS_BEGIN
});

export const fetchReviewTagLinksSuccess = data => ({
  type: FETCH_REVIEW_TAG_LINKS_SUCCESS,
  data
});

export const fetchReviewTagLinksError = error => ({
  type: FETCH_REVIEW_TAG_LINKS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_REVIEW_TAG_LINKS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_REVIEW_TAG_LINKS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_REVIEW_TAG_LINKS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
