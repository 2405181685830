import axios from 'axios';

// Actions
const FETCH_PRODUCT_VARIANTS_FOR_PRODUCT = 'FETCH_PRODUCT_VARIANTS_FOR_PRODUCT';
const FETCH_PRODUCT_VARIANTS_FOR_PRODUCT_SUCCESS = 'FETCH_PRODUCT_VARIANTS_FOR_PRODUCT_SUCCESS';
const FETCH_PRODUCT_VARIANTS_FOR_PRODUCT_ERROR = 'FETCH_PRODUCT_VARIANTS_FOR_PRODUCT_ERROR';

const DEFAULT_PAGE_SIZE = 5;

export function fetchProductVariantsForProduct(id, page = {}) {
  return async dispatch => {
    dispatch(fetchProductVariantsForProductBegin());
    try {
      const response = await axios.get(`/api/v1/product_variants?filter[product_id]=${id}`, {
        params: {
          'page[size]': DEFAULT_PAGE_SIZE,
          'page[after]': page.after,
          'page[before]': page.before
        }
      });
      dispatch(fetchProductVariantsForProductSuccess(response.data));
    } catch (e) {
      dispatch(fetchProductVariantsForProductError(e));
    }
  }
}

export const fetchProductVariantsForProductBegin = () => ({
  type: FETCH_PRODUCT_VARIANTS_FOR_PRODUCT
});

export const fetchProductVariantsForProductSuccess = (data) => ({
  type: FETCH_PRODUCT_VARIANTS_FOR_PRODUCT_SUCCESS,
  data
});

export const fetchProductVariantsForProductError = (error) => ({
  type: FETCH_PRODUCT_VARIANTS_FOR_PRODUCT_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_PRODUCT_VARIANTS_FOR_PRODUCT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PRODUCT_VARIANTS_FOR_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_PRODUCT_VARIANTS_FOR_PRODUCT_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
