import React, { useState, useEffect } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import {
  fetchDisplaySettings,
  saveDisplaySettings
} from 'redux/displaySettings';
import { Button, Page, LegacyStack, LegacyCard, ButtonGroup, Text, Link } from "@shopify/polaris";
import LoadingWrapper from 'components/LoadingWrapper';
import JunipLogo from 'components/JunipLogo';
import SkipOnboarding from 'components/SkipOnboarding';

import { ExternalIcon } from "@shopify/polaris-icons";
import previewImage from 'assets/images/onboarding/preview.png';

const NEXT_STEP = '/onboarding/solicitation';

function Preview({ displaySettings, fetchDisplaySettings, saveDisplaySettings, history, progress }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!displaySettings.data) {
      fetchDisplaySettings();
    }
  }, [displaySettings.data, fetchDisplaySettings]);

  const nextStep = async () => {
    setLoading(true);
    await saveDisplaySettings(displaySettings.data.id, { onsite_enabled: true });
    setLoading(false);
    history.push(NEXT_STEP);

    trackEvent('Onboarding preview - Enable & continue');
  };

  const renderStorePreviewUrl = () => {
    if (displaySettings.data) {
      let url = displaySettings.data?.store?.url;
      if (url && url.indexOf('http') === -1) {
        url = `https://${url}`;
      }
      url = `${url}?junip-preview-mode=true&junip-break-cache=true`;
      const themeId = history?.location?.state?.themeId;
      if (themeId) {
        url = `${url}&preview_theme_id=${themeId}`;
      }
      return url;
    }
  };

  return (
    <Page>
      <LegacyCard flush>
        <div className="onboarding-step o-divider">
          <div className="o-step o-step-subdued o-divider-right">
            <LegacyStack vertical spacing="extraLoose">
              <JunipLogo height={'24px'} />
              <Text variant="heading2xl" as="p">Preview & Enable Junip on your store</Text>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">
                  Next let's check out how Junip looks on your store. Check out{' '}
                  <Link url="https://help.junip.co/en/articles/4606706-manually-installing-the-junip-snippets">this help doc</Link>
                  {' '}on manually installing Junip if things aren’t looking right.
                </Text>
              </p>
              <LoadingWrapper loading={displaySettings?.loading} sections={1}>
                <Button
                  url={renderStorePreviewUrl()}
                  external
                  icon={ExternalIcon}
                  onClick={() => trackEvent('Onboarding preview - Preview Junip on your store')}
                >
                  Preview Junip on your store
                </Button>
              </LoadingWrapper>
            </LegacyStack>
          </div>
          <div className="o-step o-step-highlight">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <img src={previewImage} alt="Preview Junip" className="w-100" />
            </div>
          </div>
        </div>
        <LegacyCard.Section subdued>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              {progress}
            </LegacyStack.Item>
            <LegacyStack.Item>
              <ButtonGroup gap="loose">
                <Button

                  disabled={loading}
                  url={NEXT_STEP}
                  onClick={() => trackEvent('Onboarding preview - Skip')}
                  variant="plain">
                  Skip
                </Button>
                <Button size="large" loading={loading} onClick={nextStep} variant="primary">
                  Enable & Continue
                </Button>
              </ButtonGroup>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <SkipOnboarding disabled={loading} />
    </Page>
  );
}

const mapStateToProps = (state) => ({
  displaySettings: state.displaySettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  saveDisplaySettings: (id, settings) => dispatch(saveDisplaySettings(id, settings, false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preview);

