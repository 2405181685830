import React from 'react';
import StarRating from 'components/StarRating';
import 'styles/components/StarPreview.scss';

const StarPreview = ({color, star_option, showText = true}) => {
  return (
    <div className="star-preview-container">
      {showText &&
        <p className="mb-1">Preview</p>
      }
      <StarRating rating={3.5} color={color} star_option={star_option} />
    </div>
  );
};

export default StarPreview;
