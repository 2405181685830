import WidgetCardWrapper from "./WidgetCardWrapper";

import carouselWidget from 'assets/images/widgets/carousel-widget.svg';

export default function CarouselWidgetWrapper({ children }) {
  return (
    <WidgetCardWrapper
      image={carouselWidget}
      imageAlt="Carousel widget"
      title="Carousel"
      description="Highlight your best reviews."
      children={children}
    />
  );
}
