import React, { useState } from 'react';
import axios from 'axios';
import {
  LegacyCard,
  Form,
  FormLayout,
  TextField,
  Button
} from '@shopify/polaris';

export default function SignIn({ orNew, working, selectUser, showToast, email }) {
  const queryParams = window.location.search || '';
  const [inputs, setInputs] = useState({ email });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        '/api/v1/users/sign_in',
        {
          user: {
            email: inputs.email,
            password: inputs.password
          }
        }
      );
      selectUser(response.data.user);
    } catch (e) {
      showToast('Oops, unable to login! Please check your email and password and try again', true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field) => {
    return (value) => setInputs(inputs => ({ ...inputs, [field]: value }));;
  };

  return <>
    <LegacyCard sectioned>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <TextField
            value={inputs.email}
            name="email"
            onChange={handleInputChange('email')}
            label="Email"
            type="email"
            placeholder="Email"
            autoFocus={true}
            autoComplete="email"
            disabled={isLoading}
          />
          <TextField
            value={inputs.password}
            name="password"
            onChange={handleInputChange('password')}
            label="Password"
            type="password"
            placeholder="Password"
            maxLength="72"
            autoComplete="current-password"
            disabled={isLoading}
          />
          <Button size="large" submit loading={isLoading || working} variant="primary">Login</Button>
        </FormLayout>
      </Form>
    </LegacyCard>
    { orNew &&
      <LegacyCard sectioned>Don't have an account?{' '}
        <Button

          disabled={isLoading || working}
          url={`/oauth/create_account${queryParams}`}
          variant="plain">Create one</Button>
      </LegacyCard>
    }
  </>;
}
