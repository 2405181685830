import WidgetCardWrapper from "./WidgetCardWrapper";

import ugcGalleryWidget from 'assets/images/widgets/ugc-widget.svg';

export default function UgcGalleryWidgetWrapper({ children }) {
  return (
    <WidgetCardWrapper
      image={ugcGalleryWidget}
      imageAlt="UGC gallery widget"
      title="UGC Gallery"
      description={
        <>
          Showcase media submissions. <b fontWeight="medium">At least 3 reviews with photos or videos required.</b>
        </>
      }
      children={children}
    />
  );
}
