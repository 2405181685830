import axios from 'axios';

// Actions
const FETCH_SHIPPING_LOCATIONS_BEGIN = 'FETCH_SHIPPING_LOCATIONS_BEGIN';
const FETCH_SHIPPING_LOCATIONS_SUCCESS = 'FETCH_SHIPPING_LOCATIONS_SUCCESS';
const FETCH_SHIPPING_LOCATIONS_ERROR = 'FETCH_SHIPPING_LOCATIONS_ERROR';

// Action Creators
export function fetchShippingLocations() {
  return async dispatch => {
    dispatch(fetchShippingLocationsBegin());
    try {
      const response = await axios.get('/api/v1/shipping_locations');
      dispatch(fetchShippingLocationsSuccess(response.data.shipping_locations));
    } catch (e) {
      dispatch(fetchShippingLocationsError(e));
    }
  }
}

export const fetchShippingLocationsBegin = () => ({
  type: FETCH_SHIPPING_LOCATIONS_BEGIN
});

export const fetchShippingLocationsSuccess = (data) => ({
  type: FETCH_SHIPPING_LOCATIONS_SUCCESS,
  data
});

export const fetchShippingLocationsError = (error) => ({
  type: FETCH_SHIPPING_LOCATIONS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SHIPPING_LOCATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SHIPPING_LOCATIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_SHIPPING_LOCATIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
