import axios from 'axios';

// Actions
const FETCH_SURVEY_QUESTIONS_BEGIN = 'FETCH_SURVEY_QUESTIONS_BEGIN';
const FETCH_SURVEY_QUESTIONS_SUCCESS = 'FETCH_SURVEY_QUESTIONS_SUCCESS';
const FETCH_SURVEY_QUESTIONS_ERROR = 'FETCH_SURVEY_QUESTIONS_ERROR';

// Action Creators
export function fetchSurveyQuestions(params = {}) {
  return async dispatch => {
    dispatch(fetchSurveyQuestionsBegin());
    try {
      const response = await axios.get('/api/v1/survey_questions', {
        params: {
          'page[after]': params?.page?.after,
          'page[before]': params?.page?.before
        }
      });
      dispatch(fetchSurveyQuestionsSuccess(response.data.survey_questions, response.data?.meta));
    } catch (e) {
      dispatch(fetchSurveyQuestionsError(e));
    }
  }
}

export const fetchSurveyQuestionsBegin = () => ({
  type: FETCH_SURVEY_QUESTIONS_BEGIN
});

export const fetchSurveyQuestionsSuccess = (data, meta) => ({
  type: FETCH_SURVEY_QUESTIONS_SUCCESS,
  data,
  meta
});

export const fetchSurveyQuestionsError = error => ({
  type: FETCH_SURVEY_QUESTIONS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SURVEY_QUESTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        loading: false,
        error: null
      };
    case FETCH_SURVEY_QUESTIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
