import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  LegacyCard,
  Button,
  ButtonGroup,
} from '@shopify/polaris';
import EventsTable from './EventsTable';
import MessagesTable from './MessagesTable';

function SentTable({ mapConnected, handleTabChange, showToast, push }) {
  const [showEvents, setShowEvents] = useState(mapConnected || false);

  const handleEventsButtonClick = useCallback(() => {
    if (showEvents) return;
    setShowEvents(true);
  }, [showEvents]);

  const handleMessagesButtonClick = useCallback(() => {
    if (!showEvents) return;
    setShowEvents(false);
  }, [showEvents]);

  return <>
    <LegacyCard.Section>
      <ButtonGroup variant="segmented">
        <Button pressed={showEvents} onClick={handleEventsButtonClick}>
          Events
        </Button>
        <Button pressed={!showEvents} onClick={handleMessagesButtonClick}>
          Messages
        </Button>
      </ButtonGroup>
    </LegacyCard.Section>
    {showEvents ?
      <EventsTable push={push} />
    :
      <MessagesTable handleTabChange={handleTabChange} />
    }
  </>;
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(SentTable);
