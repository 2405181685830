import React, { useState, useCallback } from 'react';
import moment from 'moment';
import {
  LegacyCard,
  LegacyStack,
  Popover,
  ActionList,
  Button,
  ButtonGroup,
  TextField,
  Modal,
  Text,
} from "@shopify/polaris";

export default function ReviewNote({reviewNote, user, updateNote, deleteNote}) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const [newNote, setNewNote] = useState(reviewNote.content);
  const [showModal, setShowModal] = useState(false);

  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), []);
  const handleChange = useCallback((newValue) => setNewNote(newValue), []);

  const isDirty = () => {
    if (reviewNote.content === newNote) {
      return false;
    }

    return true;
  };

  const update = async () => {
    setLoading(true);
    try {
      await updateNote(reviewNote.id, newNote);
    } catch(e) {
      setLoading(false);
    }
  };

  const removeNote = async () => {
    setLoading(true);
    try {
      await deleteNote(reviewNote.id);
    } catch(e) {
      setLoading(false);
    }
  };

  return (
    <LegacyCard.Section>
      <LegacyStack>
        <LegacyStack.Item fill>
          { !editing &&
            <p style={{whiteSpace: 'pre-wrap'}}>
              {reviewNote.content}
              {'  '}&ndash;{' '}
              <Text variant="bodyMd" as="span" fontWeight="semibold">
                {reviewNote.user?.first_name}{' '}
                {reviewNote.user?.last_name}
              </Text>
              <Text variant="bodyMd" as="span" tone="subdued">
                {' '}({moment(reviewNote.created_at).fromNow()})</Text>
              { reviewNote.created_at !== reviewNote.updated_at &&
                <Text variant="bodyMd" as="span" tone="subdued">
                  {' '}(edited)</Text>
              }
            </p>
          }
          { editing &&
            <TextField
              type="text"
              value={newNote}
              onChange={handleChange}
              placeholder="Enter internal note..."
              spellCheck
              multiline
            />
          }
        </LegacyStack.Item>
        { !editing && user?.data?.id === reviewNote?.user_id &&
          <Popover
            active={popoverActive}
            activator={
              <Button
                onClick={togglePopoverActive}
                disclosure
                disabled={loading}
                size="slim"
              >
                Actions
              </Button>
            }
            onClose={togglePopoverActive}
            disabled={loading}
          >
            <ActionList
              items={[
                { content: 'Edit',
                  disabled: false,
                  onAction: () => { setPopoverActive(false); setEditing(true); }
                },
                {
                  content: 'Delete',
                  destructive: true,
                  disabled: false,
                  onAction: () => { setShowModal(true) }
                },
              ]}
            />
          </Popover>
        }
        { editing &&
          <div>
            <ButtonGroup>
              <Button

                disabled={loading}
                onClick={() => { setEditing(false); setNewNote(reviewNote.content) }}
                size="slim"
                variant="plain">
                Cancel
              </Button>
              <Button

                disabled={!isDirty()}
                loading={loading}
                onClick={update}
                size="slim"
                variant="primary">
                Save
              </Button>
            </ButtonGroup>
          </div>
        }
      </LegacyStack>
      <Modal
        open={showModal}
        title={'Delete internal note?'}
        onClose={() => !loading && setShowModal(false)}
        primaryAction={{
          content: 'Delete',
          destructive: true,
          loading: loading,
          onAction: removeNote
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            disabled: loading,
            onAction: () => { setShowModal(false); },
          }
        ]}
      >
        <Modal.Section>
          <p>
            Are you sure you want to delete this note?
          </p>
        </Modal.Section>
      </Modal>
    </LegacyCard.Section>
  );
}
