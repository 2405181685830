import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BlockStack, Button, Checkbox, Collapsible, Link } from "@shopify/polaris";
import { saveDisplaySettings } from "redux/displaySettings";

import WidgetCardWrapper from "./WidgetCardWrapper";
import globalDisplaySettings from 'assets/images/widgets/global-display-settings.svg';

export default function GlobalDisplaySettings() {
  const displaySettings = useSelector((state) => state.displaySettings);
  const dispatch = useDispatch();
  const [darkModeEnabled, setDarkModeEnabled] = useState(displaySettings?.data?.dark_mode_enabled);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDarkModeEnabled(displaySettings?.data?.dark_mode_enabled);
  }, [displaySettings?.data]);

  const saveSettings = async () => {
    setLoading(true);
    // Save the display settings, I believe we can actually use await here because of how saveDisplaySettings is implemented
    await dispatch(saveDisplaySettings(displaySettings?.data?.id, { dark_mode_enabled: darkModeEnabled }));
    setLoading(false);
  };

  return (
    <WidgetCardWrapper
      image={globalDisplaySettings}
      imageAlt="Dark or light mode"
      title="Global display settings"
      description={
        <>
          Enable dark mode if your theme uses a dark background. To edit other colors and styles,{' '}
          <Link url="/settings/branding">visit the branding settings</Link>.
        </>
      }
    >
      <BlockStack gap="300">
        <Checkbox
          label="Enable dark mode"
          checked={darkModeEnabled}
          onChange={setDarkModeEnabled}
        />
        <Collapsible open={displaySettings?.data?.dark_mode_enabled !== darkModeEnabled}>
          <Button
            variant="primary"
            loading={loading}
            onClick={saveSettings}
          >
            Save
          </Button>
        </Collapsible>
      </BlockStack>
    </WidgetCardWrapper>
  );
}
