import axios from 'axios';

// Actions
const FETCH_EVENT_TOPICS_BEGIN = 'FETCH_EVENT_TOPICS_BEGIN';
const FETCH_EVENT_TOPICS_SUCCESS = 'FETCH_EVENT_TOPICS_SUCCESS';
const FETCH_EVENT_TOPICS_ERROR = 'FETCH_EVENT_TOPICS_ERROR';

// Action Creators
export function fetchEventTopics() {
  return async dispatch => {
    dispatch(fetchEventTopicsBegin());
    try {
      const response = await axios.get('/api/v1/event_topics');
      dispatch(fetchEventTopicsSuccess(response.data.event_topics));
    } catch (e) {
      dispatch(fetchEventTopicsError(e));
    }
  }
}

export const fetchEventTopicsBegin = () => ({
  type: FETCH_EVENT_TOPICS_BEGIN
});

export const fetchEventTopicsSuccess = data => ({
  type: FETCH_EVENT_TOPICS_SUCCESS,
  data
});

export const fetchEventTopicsError = error => ({
  type: FETCH_EVENT_TOPICS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_EVENT_TOPICS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_EVENT_TOPICS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_EVENT_TOPICS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
