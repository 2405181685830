import React from 'react';
import 'styles/components/ButtonPreview.scss';

const ButtonPreview = ({buttonColor, buttonTextColor, buttonShape, showLabel = true, text = 'Sample button'}) => {
  return (
    <div>
      {showLabel &&
        <p className="mb-1">Preview</p>
      }
      <div className="text-center">
        <div className="sample-button" style={{
          backgroundColor: buttonColor,
          color: buttonTextColor,
          borderRadius: buttonShape
        }}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default ButtonPreview;
