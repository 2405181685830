import React, { useState, useCallback } from 'react';
import {
  LegacyCard,
  Collapsible,
  Icon,
  Button,
  LegacyStack,
  Text,
} from "@shopify/polaris";
import { CheckCircleIcon, ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";

import circle from 'assets/images/circle.svg';

export default function OnboardingStep({ step, index }) {
  const {
    type,
    completed,
    expanded,
    title,
    description,
    action
  } = step;

  const [active, setActive] = useState(expanded);
  const handleToggle = useCallback(() => setActive((active) => !active), []);

  return (
    <LegacyCard.Section subdued={completed}>
      <div className="d-flex align-items-center justify-content-between" onClick={handleToggle} style={{cursor: 'pointer'}}>
        <div className="d-flex align-items-center">
          { completed ?
            <Icon source={CheckCircleIcon} tone="success"></Icon>
            :
            <img height="20" width="20" style={{padding: '3px'}} src={circle} alt="Circle" />
          }
          <div className="ml-3">
            <Text variant="headingMd" as="h2">
              <Text tone={completed ? 'subdued' : undefined}>{title}</Text>
            </Text>
          </div>
        </div>
        <Button

          onClick={() => {}}
          ariaExpanded={active}
          ariaControls={`${type}-collapsible`}
          icon={active ? ChevronDownIcon : ChevronRightIcon}
          variant="plain" />
      </div>
      <Collapsible id={`${type}-collapsible`} open={active}>
        <div className="mt-4">
          <LegacyStack spacing="loose" vertical>
            <p>
              {description}
            </p>
            <LegacyStack>
              {action}
            </LegacyStack>
          </LegacyStack>
        </div>
      </Collapsible>
    </LegacyCard.Section>
  );
}
