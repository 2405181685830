import React from 'react';
import { LegacyCard, LegacyStack, ResourceList, ResourceItem, Thumbnail, Badge, Text } from "@shopify/polaris";

import text from 'assets/images/customQuestions/text.svg';
import radio from 'assets/images/customQuestions/radio.svg';
import trait from 'assets/images/customQuestions/trait.svg';

export default function CustomQuestions({ questions }) {
  const renderImageSrc = (questionType) => {
    switch (questionType) {
      case 'text':
        return text;
      case 'radio':
        return radio;
      case 'rating':
        return trait;
      default:
        return null;
    }
  };

  return <>
    { questions?.length > 0 &&
      <>
        <LegacyCard.Section>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              <p>Questions</p>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <p>Status</p>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
        <ResourceList
          resourceName={{ singular: 'question', plural: 'questions' }}
          items={questions}
          renderItem={(question) => (
            <ResourceItem
              id={question.id}
              url={`/forms/questions/${question.id}`}
              accessibilityLabel={`View details for question ${question.id}`}
              name={question.name}
              media={
                <Thumbnail source={renderImageSrc(question.value_type)} size="medium" />
              }
              verticalAlignment="center"
            >
              <LegacyStack alignment="center">
                <LegacyStack.Item fill>
                  <Text variant="headingMd" as="h2">{question.name}</Text>
                  <Text variant="bodyMd" as="span" tone="subdued">
                    <p>{question.question}</p>
                  </Text>
                </LegacyStack.Item>
                <LegacyStack alignment="center">
                  <Badge tone={question.private ? 'info' : 'success'}>{question.private ? 'Private' : 'Public'}</Badge>
                </LegacyStack>
              </LegacyStack>
            </ResourceItem>
          )}
        />
      </>
    }
  </>;
}
