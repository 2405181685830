import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDisplaySettings, saveDisplaySettings } from 'redux/displaySettings';
import { fetchStoreThemes } from 'redux/storeThemes';

import {
  Page,
  Select,
  Box,
  Tabs,
  Text,
  Divider,
  Banner,
  BlockStack,
  ButtonGroup,
  Button,
  Link,
} from '@shopify/polaris';

import { ViewIcon, ColorIcon, HideIcon } from '@shopify/polaris-icons';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import AppEmbedTab from 'components/AppEmbedTab';
import GlobalDisplaySettings from 'components/widgets/GlobalDisplaySettings';
import SnippetInstallTab from 'components/SnippetInstallTab';
import HTMLInstallTab from 'components/HTMLInstallTab';

export default function OnSiteV2() {
  const [selected, setSelected] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [enabling, setEnabling] = useState(false);

  const dispatch = useDispatch();
  const displaySettings = useSelector((state) => state.displaySettings);
  const storeThemes = useSelector((state) => state.storeThemes);

  const supportsShopifyThemeExtensions = useMemo(() =>
    displaySettings?.data?.store.supports_shopify_theme_extensions,
  [displaySettings?.data?.store]);

  useEffect(() => {
    if (!supportsShopifyThemeExtensions) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  }, [supportsShopifyThemeExtensions]);

  useEffect(() => {
    dispatch(fetchDisplaySettings());
  }, [dispatch]);

  useEffect(() => {
    if (storeThemes?.data) {
      setSelected(storeThemes.data.find((theme) => theme.active)?.remote_id);
    } else {
      dispatch(fetchStoreThemes());
    }
  }, [storeThemes?.data, dispatch]);

  const options = [];
  if (storeThemes?.data?.length) {
    for (const theme of storeThemes?.data) {
      options.push({
        label: `${theme.name}${theme.active ? ' (live)' : ''}`,
        value: theme.remote_id
      });
    }
  }

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const storePreviewUrl = () => {
    let url = displaySettings?.data?.store?.url;
    if (url && url.indexOf('http') === -1) {
      url = `https://${url}`;
    }
    return `${url}?junip-preview-mode=true&junip-break-cache=true`;
  };

  const generatePreviewUrl = () => {
    return `${storePreviewUrl()}&preview_theme_id=${selected}`;
  };

  const enableOnSite = async () => {
    setEnabling(true);
    await dispatch(saveDisplaySettings(displaySettings.data?.id, { onsite_enabled: true }));
    setEnabling(false);
  };

  const switchTab = (tab) => {
    const TAB_MAPPING = {
      'html': 2,
      'snippets': 1,
      'app-embed': 0,
    };

    setActiveTab(TAB_MAPPING[tab]);
  };

  const tabs = [
    {
      id: 'app-embed',
      content: 'Install with app embed',
      panelID: 'app-embed-panel',
    },
    {
      id: 'snippets',
      content: 'Install with snippets',
      panelID: 'snippets-panel',
    },
    {
      id: 'html',
      content: 'Install with HTML',
      panelID: 'html-panel',
    },
  ];

  return (
    <LoadingPageWrapper loading={displaySettings.loading || storeThemes.loading}>
      <Page
        title="On-site displays"
        subtitle="Display ratings, reviews, media on your site"
        secondaryActions={[
          {
            content: 'Manage visibility',
            url: '/settings/on_site',
          },
          {
            content: 'Edit styles',
            icon: ColorIcon,
            url: '/settings/branding',
          },
          {
            content: 'Preview',
            icon: ViewIcon,
            external: true,
            url: generatePreviewUrl(),
          },
        ]}
      >
        {displaySettings?.data?.onsite_enabled === false && (
          <Box paddingBlockEnd="800">
            <Banner
              title="Review displays are disabled"
              tone="warning"
              icon={HideIcon}
            >
              <BlockStack gap="200">
                <Text>
                  Until you change this setting, your widgets will only be visible in{' '}
                  <Link url={storePreviewUrl()} external>preview mode</Link>.{' '}
                  Be sure to update this setting when you’re ready to go live!
                </Text>
                <ButtonGroup>
                  <Button
                    onClick={enableOnSite}
                    loading={enabling}
                    disabled={enabling}
                  >
                    Enable
                  </Button>
                  <Button
                    variant="plain"
                    url="/settings/on_site"
                  >
                    Manage settings
                  </Button>
                </ButtonGroup>
              </BlockStack>
            </Banner>
          </Box>
        )}
        <Box paddingInline={{ "xs": "400", "sm": "0" }} paddingBlockEnd="400" maxWidth="320px">
          <Select
            label="Shopify theme"
            labelInline
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
        </Box>
        <Box paddingBlockEnd="400">
          <Tabs tabs={tabs} selected={activeTab} onSelect={setActiveTab} />
        </Box>
        {activeTab === 0 && (
          <AppEmbedTab selectedTheme={selected} switchTab={switchTab} />
        )}
        {activeTab === 1 && (
          <SnippetInstallTab selectedTheme={selected} previewUrl={generatePreviewUrl()} switchTab={switchTab} />
        )}
        {activeTab === 2 && (
          <HTMLInstallTab selectedTheme={selected} previewUrl={generatePreviewUrl()} />
        )}
        <Box paddingBlock="500">
          <Divider />
        </Box>
        <GlobalDisplaySettings />
      </Page>
    </LoadingPageWrapper>
  );
}
