import React, { useState } from 'react';
import { connect } from 'react-redux';
import IncentiveModal from './IncentiveModal';
import { Icon, Button, ButtonGroup, Modal, LegacyStack, Badge, Text } from "@shopify/polaris";

import { CashDollarIcon, DiscountIcon, DeliveryIcon } from "@shopify/polaris-icons";

import 'styles/components/Incentive.scss';

function Incentive({ incentive, updateIncentive, deleteIncentive, updating, stores }) {
  const [newIncentive, setNewIncentive] = useState(incentive);
  const [saving, setSaving] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const store = stores?.data?.find?.(s => s.scoped === true) || {};

  const saveIncentive = async (newIncentive) => {
    setSaving(true);
    try {
      await updateIncentive(newIncentive);
    } catch (e) {
      setSaving(false);
    }
  };

  const removeIncentive = async (newIncentive) => {
    setSaving(true);
    try {
      await deleteIncentive(newIncentive);
    } catch (e) {
      setSaving(false);
    }
  };

  const iconSource = () => {
    if (incentive.incentive_type.slug.includes('shipping')) {
      return DeliveryIcon;
    }
    if (incentive.incentive_type.slug.includes('percent')) {
      return DiscountIcon;
    }
    return CashDollarIcon;
  };

  return <>
    <div className="incentive">
      <LegacyStack alignment="center" distribution="fill">
        <LegacyStack.Item fill>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              <div className="d-flex flex-row">
                <div className="incentive-icon mr-3">
                  <Icon tone="base" source={iconSource()} />
                </div>
                <div className="incentive-details">
                  <p>{incentive.name}</p>
                  <p>
                    <Text variant="bodyMd" as="span" tone="subdued">
                      {incentive.verified_buyers_only ? "Verified buyers only" : "Includes unverified buyers"}
                    </Text>
                  </p>
                </div>
              </div>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Badge tone="success">Active</Badge>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <p className="incentive-claims">Claimed {incentive.claimed_count} {incentive.claimed_count === 1 ? 'time' : 'times'}</p>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <div className="d-flex flex-row incentive-actions">
            <ButtonGroup>
              <Button


                onClick={() => setShowRemoveModal(true)}
                variant="plain"
                tone="critical">
                Remove
              </Button>
              <Button
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </Button>
            </ButtonGroup>
          </div>
        </LegacyStack.Item>
      </LegacyStack>
    </div>
    <IncentiveModal
      title={incentive?.requires_media === true ? 'Edit media incentive' : 'Edit standard incentive'}
      store={store}
      showModal={showEditModal}
      incentiveTypes={[incentive.incentive_type]}
      newIncentive={{ ...newIncentive.incentive_type, value: incentive.value }}
      setNewIncentive={setNewIncentive}
      primaryAction={{
        content: 'Save',
        loading: saving,
        disabled: newIncentive.verified_buyers_only === incentive.verified_buyers_only,
        onAction: () => saveIncentive(newIncentive)
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: saving,
        onAction: () => { setShowEditModal(false) },
      }]}
      defaultBuyers={incentive.verified_buyers_only ? 'verified' : 'anyone'}
      close={() => !saving && setShowEditModal(false)}
      disableFields={true}
    />
    <Modal
      open={showRemoveModal}
      title={`Delete the ${incentive.name} incentive?`}
      onClose={() => !saving && setShowRemoveModal(false)}
      primaryAction={{
        content: 'Delete incentive',
        destructive: true,
        loading: saving,
        onAction: () => { removeIncentive({ ...incentive }) }
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: saving,
          onAction: () => { setShowRemoveModal(false); },
        }
      ]}
    >
      <Modal.Section>
        <p>
          Are you sure you want to delete the {incentive.name} incentive? This action cannot be undone.
        </p>
      </Modal.Section>
    </Modal>
  </>;
}

const mapStateToProps = (state) => ({
  stores: state.stores
});

export default connect(
  mapStateToProps
)(Incentive);
