import React from 'react';
import {
  FooterHelp,
  Link
} from '@shopify/polaris';

export default function BrandingFooterHelp() {
  return (
    <FooterHelp>
      Want to configure the look and feel? Set your{' '}
      <Link url="/settings/branding">
        branding
      </Link>
      .
    </FooterHelp>
  );
}
