import React from 'react';
import { Icon, Text } from "@shopify/polaris";
import { FilterIcon } from "@shopify/polaris-icons";

export default function ReviewVerificationConditions({ action, editing, setEditing }) {
  return (
    <div className="d-flex">
      <div className="mr-3">
        <Icon tone="primary" source={FilterIcon} />
      </div>
      <p>
        <Text variant="bodyMd" as="span" fontWeight="semibold">If the review needs to be verified</Text>{' '}
        (on-site or manual reviews only)
      </p>
    </div>
  );
}
