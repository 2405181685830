import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function TagManager({ location }) {
  const pathname = location.pathname;

  useEffect(() => {
    const gtag = window.gtag;
    if (gtag) {
      if (pathname.includes('/onboarding/intro')) {
        gtag('event', 'conversion', {
          'send_to': 'AW-11396044694/HkVyCInEt_MYEJavh7oq'
        });
      }

      if (pathname.includes('/login/create/organization')) {
        gtag('event', 'conversion', {
          'send_to': 'AW-11396044694/z-lYCPKst_MYEJavh7oq'
        });
      }

      if (pathname.includes('/onboarding/thanks')) {
        gtag('event', 'conversion', {
          'send_to': 'AW-11396044694/yokyCKTiufMYEJavh7oq'
        });
      }
    }
  }, [pathname]);
}

export default withRouter(TagManager);
