import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { fetchAppInstalls } from 'redux/appInstalls';
import { fetchMessageTemplates } from 'redux/messages';
import { fetchBillingSubscriptions } from 'redux/billingSubscriptions';
import { fetchFlows } from 'redux/flows';
import {
  fetchSolicitationCampaigns,
} from 'redux/solicitationCampaigns';
import {
  Page,
  Pagination,
  EmptyState,
  DataTable,
  Badge,
  FooterHelp,
  Link,
  Tabs
 } from '@shopify/polaris';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import LoadingContentWrapper from 'components/LoadingContentWrapper';
import SolicitationCampaignBanner from 'components/SolicitationCampaignBanner';
import SolicitationCampaignModal from 'components/SolicitationCampaignModal';
import BillingPrompt from 'components/BillingPrompt';
import Flow from 'components/Flow';

function Campaigns({
  appInstalls,
  billingSubscriptions,
  messages,
  solicitationCampaigns,
  flows,
  fetchAppInstalls,
  fetchMessageTemplates,
  fetchSolicitationCampaigns,
  fetchBillingSubscriptions,
  fetchFlows
}) {
  const params = new URLSearchParams(window.location.search);

  const [tab, setTab] = useState(parseInt(params.get('tab')) || 0);
  const [loading, setLoading] = useState(true);
  const [billingModalActive, setBillingModalActive] = useState(false);
  const [showSolicitationCampaignModal, setShowSolicitationCampaignModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [meta, setMeta] = useState(null);

  const handleTabChange = useCallback(
    (newTab) => {
      setTab(newTab);
    }
    , [setTab]);

  useEffect(() => {
    fetchFlows({ include: 'actions.message_type,actions.app_install,actions.condition_list' });
  }, [tab, fetchFlows]); // We want to refetch flows when the tab changes

  useEffect(() => {
    if (!billingSubscriptions.data) {
      fetchBillingSubscriptions();
    }
  }, [fetchBillingSubscriptions, billingSubscriptions.data]);

  useEffect(() => {
    fetchAppInstalls();
    fetchMessageTemplates();
  }, [fetchAppInstalls, fetchMessageTemplates]);

  const parseData = useCallback((data) => {
    setMeta(solicitationCampaigns.meta);
    let newRows = [];
    for (let campaign of data) {
      const {
        created_at,
        purchased_since,
        purchased_before,
        state
      } = campaign;
      const startDate = moment(purchased_since).format('MMMM Do, YYYY');
      const endDate = moment(purchased_before).format('MMMM Do, YYYY');
      let row = [];
      row.push(moment(created_at).format('MM-DD-YY'));
      row.push(`${startDate} - ${endDate}`);
      if (state === 'completed') {
        row.push(<Badge tone="success" progress="complete">Completed</Badge>)
      } else {
        row.push(<Badge tone="default" progress="incomplete">Pending</Badge>)
      }
      newRows.push(row);
    }
    setRows(newRows);
  }, [solicitationCampaigns.meta]);

  useEffect(() => {
    if (!solicitationCampaigns.data) {
      fetchSolicitationCampaigns();
    } else {
      setLoading(false);
      parseData(solicitationCampaigns.data);
    }
  }, [fetchSolicitationCampaigns, solicitationCampaigns.data, parseData]);

  const createCampaignAction = () => {
    trackEvent('Campaigns - Create campaign');
    if (!billingSubscriptions.data?.length || billingSubscriptions.data?.[0].usage_limit_reached === true) {
      setBillingModalActive(true);
      trackEvent('Campaigns - Billing prompt');
    } else {
      setShowSolicitationCampaignModal(true);
    }
  };

  const flow = flows?.data?.find((f) => f.flow_type === 'request' && (f.event_topic?.action === 'targeted-by-campaign' || f.event_topic?.action === 'request-by-campaign'));

  const tabs = [
    { content: 'Flow', id: 'flow'},
    { content: 'Campaign history', id: 'campaignHistory'},
  ];

  return (
    <LoadingPageWrapper loading={loading || billingSubscriptions.loading || appInstalls.loading || messages.loading}>
      <Page
        title="Review request campaign"
        subtitle="Send a one-time review request to past customers."
        fullWidth
        primaryAction={{
          content: 'Create Campaign',
          onAction: createCampaignAction
        }}
        backAction={{
          content: 'Flows',
          url: '/flows'
        }}
      >
        <SolicitationCampaignBanner />
        <Tabs tabs={tabs} selected={tab} onSelect={handleTabChange}>
          { tab === 0 ?
            <div className="mt-4">
              <LoadingContentWrapper loading={flows.loading}>
                {flow &&
                  <Flow flow={flow} appInstalls={appInstalls} messages={messages} />
                }
              </LoadingContentWrapper>
            </div>
          :
            <div className="mt-4">
              <LoadingCardWrapper
                sectioned={false}
                title="Campaign history"
                loading={solicitationCampaigns.loading}
              >
                {rows.length > 0 &&
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'numeric',
                    ]}
                    headings={[
                      'Date created',
                      'Campaign range',
                      'Status',
                    ]}
                    rows={rows}
                    footerContent={
                      <Pagination
                        hasPrevious={meta?.page?.before !== null}
                        hasNext={meta?.page?.after !== null}
                        onPrevious={() => fetchSolicitationCampaigns({page: {before: meta?.page?.before}})}
                        onNext={() => fetchSolicitationCampaigns({ page: { after: meta?.page?.after } })}
                      />
                    }
                  />
                }
                {rows.length === 0 &&
                  <EmptyState
                    heading="No campaigns"
                    action={{ content: 'Launch a campaign', onAction: () => {setShowSolicitationCampaignModal(true); trackEvent('Campaigns - Empty state Create a campaign')}}}
                  >
                    <p>
                      Looks like you haven't created any campaigns yet. Start gathering customer reviews by creating a campaign!
                    </p>
                  </EmptyState>
                }
              </LoadingCardWrapper>
            </div>
          }
        </Tabs>
        <SolicitationCampaignModal
          showSolicitationCampaignModal={showSolicitationCampaignModal}
          setShowSolicitationCampaignModal={setShowSolicitationCampaignModal}
        />
      </Page>
      <FooterHelp>
        Need help creating a campaign? Check out this{' '}
        <Link external={true} url="https://help.junip.co/en/articles/4607216-how-to-create-a-campaign">
          doc
      </Link>
      .
    </FooterHelp>
      <BillingPrompt
        active={billingModalActive}
        setActive={setBillingModalActive}
      />
    </LoadingPageWrapper>
  );
}

const mapStateToProps = (state) => ({
  appInstalls: state.appInstalls,
  messages: state.messages,
  billingSubscriptions: state.billingSubscriptions,
  solicitationCampaigns: state.solicitationCampaigns,
  flows: state.flows,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBillingSubscriptions: () => dispatch(fetchBillingSubscriptions()),
  fetchSolicitationCampaigns: (data) => dispatch(fetchSolicitationCampaigns(data)),
  fetchFlows: (data) => dispatch(fetchFlows(data)),
  fetchAppInstalls: () => dispatch(fetchAppInstalls()),
  fetchMessageTemplates: () => dispatch(fetchMessageTemplates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns);
