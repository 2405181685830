import React from 'react';
import { connect } from 'react-redux';
import { Text } from "@shopify/polaris";
import IncentiveConditions from './IncentiveConditions';
import SubmittedConditions from './SubmittedConditions';

function ReviewCreatedConditions({ action, stores, editing, setEditing, flow, updateActionConditionList }) {
  const store = stores?.data?.find?.(s => s.scoped === true) || {};

  const getNewEventConditions = () => {
    if (action?.action_type === 'email') {
      return (
        <IncentiveConditions editing={editing} setEditing={setEditing} />
      );
    }

    if (action?.app_install?.provider === 'gorgias') {
      return (
        <SubmittedConditions action={action} editing={editing} setEditing={setEditing} updateActionConditionList={updateActionConditionList} />
      );
    }

    return (
      <p>
        No conditions
      </p>
    )
  }

  const getOldEventConditions = () => {
    if (action?.action_type === 'email') {
      return (
        <p>
          The review created <Text variant="bodyMd" as="span" fontWeight="semibold">requires confirmation</Text>
        </p>
      );
    } else {
      return (
        <p>
          No conditions
        </p>
      );
    }
  }

  return <>
    <>
      { getNewEventConditions() }
    </>
  </>;
}

const mapStateToProps = (state) => ({
  stores: state.stores,
});

export default connect(
  mapStateToProps,
)(ReviewCreatedConditions);
