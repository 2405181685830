import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import useWindowResize from 'utils/useWindowResize';
import {
  Popover,
  DatePicker,
  Button,
  OptionList,
  LegacyStack,
  TextField,
} from '@shopify/polaris';
import { CalendarIcon } from "@shopify/polaris-icons";
import styles from 'styles/components/AnalyticsDatePicker.module.scss';

export default function AnalyticsDatePicker({
  selectedDates,
  setSelectedDates,
  selected,
  setSelected,
  maxRange = undefined,
  disableDatesBefore,
  loading,
}) {
  const RANGE_LIMIT = 400;

  const [active, setActive] = useState(false);
  const [{ month, year }, setDate] = useState({
    month: selectedDates ? moment(selectedDates.start).month() : moment().month(),
    year: selectedDates ? moment(selectedDates.start).year() : moment().year()
  });
  const [currentRange, setCurrentRange] = useState(selectedDates);
  const [inputValues, setInputValues] = useState({
    start: moment(selectedDates.start).format('MM/DD/YYYY'),
    end: moment(selectedDates.end).format('MM/DD/YYYY'),
  });
  const [invalidRange, setInvalidRange] = useState(false);
  const [options, setOptions] = useState([]);
  const { width } = useWindowResize();

  useEffect(() => {
    // TODO: once Polaris is upgraded, use their useBreakpoints hook instead
    if (width > 576) {
      setOptions([
        { label: 'Last 7 days', value: '7days' },
        { label: 'Last 30 days', value: '30days' },
        { label: 'Last 90 days', value: '90days' },
        { label: 'Last year', value: '365days' },
        { label: 'Custom', value: 'custom', disabled: true }
      ]);
    } else {
      setOptions([
        { label: 'Last 7 days', value: '7days' },
        { label: 'Last 30 days', value: '30days' },
        { label: 'Last 90 days', value: '90days' },
        { label: 'Custom', value: 'custom', disabled: true }
      ]);
    }
  }, [width]);

  const toggleActive = useCallback(
    () => setActive((active) => !active), []);

  const resetState = useCallback(() => {
    setDate({
      month: selectedDates ? moment(selectedDates.start).month() : moment().month(),
      year: selectedDates ? moment(selectedDates.start).year() : moment().year()
    });
    setInputValues({
      start: moment(selectedDates.start).format('MM/DD/YYYY'),
      end: moment(selectedDates.end).format('MM/DD/YYYY'),
    });
    setInvalidRange(false);
    setCurrentRange(selectedDates);
  }, [selectedDates]);

  const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{1,2}\/\d{1,2}\/\d{4}/;
  function isDate(date) {
    return !isNaN(new Date(date).getDate());
  };

  function isValidYearMonthDayDateString(date) {
    return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date);
  };

  function isValidDate(date) {
    return date.length === 10 && isValidYearMonthDayDateString(date) && moment(date, 'MM/DD/YYYY').isBefore(moment().subtract(1, 'days'));
  };

  const validRangeDiff = (range) => {
    const diff = moment(range.end).diff(moment(range.start), 'day');
    if (maxRange) {
      return diff > 1 && diff <= maxRange;
    } else {
      return diff > 1 && diff <= RANGE_LIMIT;
    }
  };

  function handleStartInputValueChange(value) {
    const date = moment(value, 'MM/DD/YYYY');
    const newRange = { ...currentRange, start: date.toDate() };

    setSelected('custom');
    setInputValues((prevState) => {
      return { ...prevState, start: value };
    });

    if (isValidDate(value) && date.isBefore(moment(currentRange.end)) && validRangeDiff(newRange)) {
      setDate({ month: date.month(), year: date.year() });
      setInvalidRange(false);
    } else {
      setInvalidRange(true);
    }

    setCurrentRange(newRange);
  }

  function handleEndInputValueChange(value) {
    const date = moment(value, 'MM/DD/YYYY');
    const newRange = { ...currentRange, end: date.toDate() };

    setSelected('custom');
    setInputValues((prevState) => {
      return { ...prevState, end: value };
    });

    if (isValidDate(value) && date.isAfter(moment(currentRange.start)) && validRangeDiff(newRange)) {
      setDate({ month: date.month(), year: date.year() });
      setInvalidRange(false);
    } else {
      setInvalidRange(true);
    }

    setCurrentRange(newRange);
  }

  const handleSelectChange = useCallback((value) => {
    const end = moment().toDate();
    let start;
    switch (value[0]) {
      case '7days':
        start = moment().subtract(6, 'days').toDate();
        break;
      case '30days':
        start = moment().subtract(29, 'days').toDate();
        break;
      case '90days':
        start = moment().subtract(89, 'days').toDate();
        break;
      case '365days':
        start = moment().subtract(364, 'days').toDate();
        break;
      default:
        break;
    }

    setSelected(value[0]);
    setDate({ month: moment(start).month(), year: moment(start).year() });
    setInputValues({
      start: moment(start).format('MM/DD/YYYY'),
      end: moment(end).format('MM/DD/YYYY'),
    });
    setCurrentRange({ start, end });
    setInvalidRange(false);
  }, [setSelected]);

  const handleDateChange = (date) => {
    setInvalidRange(!validRangeDiff({ start: date.start, end: date.end }));
    setSelected('custom');
    setInputValues({
      start: moment(date.start).format('MM/DD/YYYY'),
      end: moment(date.end).format('MM/DD/YYYY'),
    });
    setCurrentRange(date);
  };

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const activator = (
    <Button
      disclosure='down'
      icon={CalendarIcon}
      onClick={toggleActive}
      disabled={loading}
    >
      {
        selected !== 'custom' ?
          options.find((option) => option?.value === selected)?.label :
          `${moment(selectedDates?.start).format('MM/DD/YY')} - ${moment(selectedDates?.end).format('MM/DD/YY')}`
      }
    </Button>
  );

  return (
    <Popover
      active={active}
      activator={activator}
      onClose={() => {
        toggleActive();
        resetState();
      }}
      fluidContent
      preferredAlignment='left'
      sectioned
    >
      <Popover.Pane>
        <LegacyStack>
          <LegacyStack.Item>
            <OptionList
              onChange={handleSelectChange}
              options={options}
              selected={selected}
            />
          </LegacyStack.Item>
          <LegacyStack.Item>
            <div className={`${styles.datePicker} p-4`}>
              <LegacyStack distribution='equalSpacing'>
                <LegacyStack.Item fill>
                  <TextField
                    label='From'
                    value={inputValues.start}
                    onChange={(value) => handleStartInputValueChange(value)}
                  />
                </LegacyStack.Item>
                <LegacyStack.Item fill>
                  <TextField
                    label='To'
                    value={inputValues.end}
                    onChange={(value) => handleEndInputValueChange(value)}
                  />
                </LegacyStack.Item>
              </LegacyStack>
              <div className="my-3">
                <DatePicker
                  month={month}
                  year={year}
                  onChange={handleDateChange}
                  onMonthChange={handleMonthChange}
                  selected={currentRange}
                  disableDatesAfter={moment().subtract(1, 'days').toDate()}
                  disableDatesBefore={disableDatesBefore}
                  allowRange
                  multiMonth
                />
              </div>
            </div>
          </LegacyStack.Item>
        </LegacyStack>
      </Popover.Pane>
      <Popover.Pane fixed>
        <div className='p-4'>
          <LegacyStack distribution='trailing' spacing="tight">
            <LegacyStack.Item>
              <Button
                onClick={() => {
                  toggleActive();
                  resetState();
                }}
              >
                Cancel
              </Button>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Button
                onClick={() => {
                  toggleActive();
                  setSelectedDates({ start: currentRange.start, end: currentRange.end });
                }}
                disabled={invalidRange}
                variant="primary">
                Apply
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        </div>
      </Popover.Pane>
    </Popover>
  );
};
