import React from 'react';
import 'styles/components/RenderedTraits.scss';

export default function RenderedTraits({ traits, labelAccessor = (t) => t}) {
  if (!traits || traits.length === 0) {
    return (null);
  }

  return (
    <div className="traits-container">
      {
        traits.map((trait) => {
          const rating = trait?.rating_average || trait?.rating || parseInt(trait?.value);
          if (rating < 1) {
            return (null);
          }
          const percent = Math.round(((rating - 1) / 4) * 100);
          return (
            <div key={trait.id} className="trait-container">
              <div className="trait-title">{trait.name}</div>
              <div className="trait">
                <div className="trait-bar">
                  <div className="trait-step-wrapper">
                    <div className="trait-step trait-step-1"></div>
                    <div className="trait-step trait-step-2"></div>
                    <div className="trait-step trait-step-3"></div>
                  </div>
                  <div className="trait-indicator" style={{left: `${percent}%`}}></div>
                </div>
                <div className="trait-labels">
                  <div className="trait-label trait-label-left">{labelAccessor(trait).label_left}</div>
                  <div className="trait-label trait-label-center">{labelAccessor(trait).label_center}</div>
                  <div className="trait-label trait-label-right">{labelAccessor(trait).label_right}</div>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}
