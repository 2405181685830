import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  DataTable,
  Badge,
  EmptyState,
  Pagination,
  Spinner,
  Tag,
  LegacyStack,
  Link,
  Tooltip,
  Thumbnail
} from '@shopify/polaris';
import { appListingDetails } from 'utils/appListingDetails';

function EventsTable({ push, showToast }) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [meta, setMeta] = useState(null);

  const renderEvent = useCallback((data) => {
    const action = <span style={{ textTransform: 'capitalize' }}>Junip - {`${data.subject} ${data.action.replaceAll('-', ' ')}`}</span>;
    if (data.context_object_id) {
      if (data.context_object_type === 'Review' && data.action !== 'confirmation-required' && data.action !== 'created') {
        return (
          <Tooltip content={`Review #${data.context_object_id}`}>
            <Tag onClick={() => push(`/reviews/${data.context_object_id}`)}>{action}</Tag>
          </Tooltip>
        );
      } else if (data.context_object_type === 'Order') {
        return (
          <Tooltip content={`Order`}>
            <Tag>{action}</Tag>
          </Tooltip>
        );
      }
    }

    return (<p style={{ textTransform: 'capitalize' }}><Tag>{action}</Tag></p>);
  }, [push]);

  const renderApp = useCallback((data) => {
    if (data.app_install) {
      const appDetails = appListingDetails[data.app_install.app?.slug];
      const source = appDetails?.icon;
      return (
        <Tooltip content={`${appDetails?.name}`}>
          <Thumbnail size="extraSmall" source={source} alt={appDetails?.name} />
        </Tooltip>
      );
    }

    return (<p>-</p>);
  }, []);

  const renderCustomer = ({ contact }) => {
    return (
      <Tooltip content={
        <div>
          <p>{contact?.first_name || 'No'} {contact?.last_name || 'name'}</p>
          {contact.accepts_marketing && <p>Accepts marketing</p>}
        </div>
      }>
        <Link>{contact?.email}</Link>
      </Tooltip>
    );
  };

  const renderRow = useCallback((data) => {
    const row = [];
    row.push(moment(data.created_at).format('MMM D Y, h:mm a'));
    row.push(renderEvent(data));
    row.push(renderApp(data));
    row.push(renderCustomer(data));
    row.push(
      <LegacyStack spacing="extraTight">
        {data.manual && <Badge tone="info">Manual</Badge>}
        {!data.manual && <Badge tone="success">Auto</Badge>}
        {data.test && <Badge tone="default">Test</Badge>}
      </LegacyStack>
    );
    return row;
  }, [renderEvent, renderApp]);

  const parseData = useCallback((data) => {
    let newRows = [];
    for (const event of data?.events) {
      newRows.push(renderRow(event));
    }
    setRows(newRows);
  }, [renderRow]);

  const fetchData = useCallback(async ({ before = null, after = null }) => {
    setLoading(true);
    try {
      const response = await axios.get('/api/v1/events', {
        params: {
          include: 'contact,app_install.app',
          'page[after]': after,
          'page[before]': before
        }
      });
      setMeta(response.data.meta);
      parseData(response.data);
    } catch (e) {
      showToast('Error fetching data, please try again.', true);
    } finally {
      setLoading(false);
    }
  }, [parseData, showToast]);

  useEffect(() => {
    fetchData({});
  }, [fetchData]);

  const nextPage = (after) => {
    fetchData({ after });
  };

  const previousPage = (before) => {
    fetchData({ before });
  };

  return (
    <>
      {loading &&
        <div className="text-center" style={{ paddingTop: '2.5rem', paddingBottom: '4rem' }}>
          <Spinner />
        </div>
      }
      {!loading && rows.length > 0 &&
        <DataTable
          verticalAlign="middle"
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Date',
            'Event',
            'App',
            'Target',
            'Trigger',
          ]}
          rows={rows}
          footerContent={
            <Pagination
              hasPrevious={meta?.page?.before !== null}
              hasNext={meta?.page?.after !== null}
              onPrevious={() => previousPage(meta?.page?.before)}
              onNext={() => nextPage(meta?.page?.after)}
            />
          }
        />
      }
      {!loading && rows.length === 0 &&
        <EmptyState
          heading="No event history"
          action={{ content: 'Send a test event', url: 'https://help.junip.co/en/articles/4904473-send-a-test-event', external: true}}
        >
          <p>
            There is no event history at the moment, please check back later. In the meantime, send a test event to test out any installed integrations.{' '}
            Click below to learn how to send test events
          </p>
        </EmptyState>
      }
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(EventsTable);
