import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { Button, LegacyCard, Icon, Page, LegacyStack, TextContainer, Text } from "@shopify/polaris";
import ThemeSelectorModal from 'components/ThemeSelectorModal';
import JunipLogo from 'components/JunipLogo';
import SkipOnboarding from 'components/SkipOnboarding';

import { ColorIcon, StarIcon, CodeIcon } from "@shopify/polaris-icons";

import 'styles/routes/Intro.scss';

const NEXT_STEP = '/onboarding/branding';

function Intro({ user, store, history }) {
  const [loading, setLoading] = useState(false);
  const [showThemeModal, setShowThemeModal] = useState(null);

  const supportsShopifyThemeExtensions = store?.supports_shopify_theme_extensions;

  useEffect(() => {
    if (window.localStorage) {
      localStorage.removeItem('redirectToOnboarding');
    }
  }, []);

  const nextStep = async ({ themeId } = {}) => {
    if (supportsShopifyThemeExtensions) {
      history.push(NEXT_STEP);
      return;
    }

    setLoading(true);
    try {
      await axios.post(`/api/v1/store_snippet_actions`, {
        store_snippet_action: {
          action: 'setup',
          theme_remote_id: themeId
        }
      });
      if (themeId) {
        trackEvent('Onboarding intro - Theme selected');
      } else {
        trackEvent('Onboarding intro - Continue');
      }
      history.push(NEXT_STEP, { themeId });
    } catch(e) {
      trackEvent('Error: Onboarding intro - Continue');
      history.push(NEXT_STEP);
    }
  };

  const steps = [
    {
      title: 'Set up your branding',
      description: 'Configure your brand colors and set your logo so Junip is on brand right off the bat',
      icon: ColorIcon
    },
    {
      title: 'Preview & enable Junip on your store',
      description: 'Check out how Junip looks on your store and enable it so customers can start seeing reviews!',
      icon: CodeIcon
    },
    {
      title: 'Start gathering reviews',
      description: 'Enable review solicitation so you can start gathering reviews as soon as possible',
      icon: StarIcon
    },
  ];

  return (
    <Page>
      <div className="intro-header text-center">
        <Text variant="headingXl" as="p">{`Welcome, ${user.data?.first_name ? ` ${user.data?.first_name.trim()}` : ''}!`}</Text>
      </div>
      <LegacyCard flush>
        <div className="onboarding-step o-intro">
          <div className="o-step o-step-intro o-divider-right o-step-subdued">
            <LegacyStack vertical spacing="extraLoose">
              <JunipLogo height={'32px'} />
              <Text variant="heading3xl" as="p">Let's get started.</Text>
              <Text variant="headingLg" as="p">
                <Text variant="bodyMd" as="span" tone="subdued">
                  {supportsShopifyThemeExtensions ?
                    <>This simple setup will make sure you get the most out of Junip as quick as possible.</>
                  :
                    <>Just hit continue to add the snippets to your active theme & start the implementation.</>
                  }
                </Text>
              </Text>
              <div>
                <Button  size="large" loading={loading} onClick={nextStep} variant="primary">
                  Continue
                </Button>
              </div>
              {!supportsShopifyThemeExtensions &&
                <p>
                  <Text variant="bodyMd" as="span" tone="subdued">If you'd like to add the Junip snippets to a different theme,{' '}
                    <Button

                      onClick={() => { setShowThemeModal({ active: true, type: 'install' }); trackEvent('Onboarding intro - Select theme'); }}
                      variant="plain">
                      click here
                    </Button>.</Text>
                </p>
              }
            </LegacyStack>
          </div>
          <div className="o-step">
            <LegacyStack vertical spacing="extraLoose">
              <Text variant="headingMd" as="h2">We're going to</Text>
              <div>
                {steps.map((step, index) =>
                  <div className="intro-item" key={index}>
                    <LegacyStack wrap={false}>
                      <div className="intro-icon">
                        <Icon source={step.icon} tone="base" />
                      </div>
                      <LegacyStack.Item fill>
                        <TextContainer spacing="tight">
                          <Text variant="headingSm" as="h3">{step.title}</Text>
                          <p>{step.description}</p>
                        </TextContainer>
                      </LegacyStack.Item>
                    </LegacyStack>
                  </div>
                )}
              </div>
            </LegacyStack>
          </div>
        </div>
      </LegacyCard>
      <ThemeSelectorModal data={showThemeModal} setData={setShowThemeModal} callback={nextStep} />
      <SkipOnboarding disabled={loading} />
    </Page>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(
  mapStateToProps
)(Intro);
