import React from 'react';
import { connect } from 'react-redux';
import { dimissToast } from 'redux/toast';

import {
  Toast
} from '@shopify/polaris';

const ToastMessage = (props) => {
  return props.toast.message ? (
    <Toast
      content={props.toast.message}
      error={props.toast.error === true}
      onDismiss={props.dimissToast}
    />
  ) : null;
};

const mapStateToProps = (state) => ({
  toast: state.toast
});

const mapDispatchToProps = (dispatch) => ({
  dimissToast: () => dispatch(dimissToast()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastMessage);
