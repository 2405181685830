import React, { useState } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  Modal
} from '@shopify/polaris';

function ToggleFlowStateModal({ flow, open, close, callback, showToast }) {
  const [loading, setLoading] = useState(false);

  const toggleState = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`/api/v1/flows/${flow.id}`, {
        flow: { disabled: !flow.disabled }
      });
      close();
      showToast(`${flow?.name} flow ${flow?.disabled ? 'enabled' : 'disabled'}`, false);
      callback(response.data);
      trackEvent(`Flow state modal - ${flow?.disabled ? 'Enable' : 'Disable'} flow`);
    } catch (e) {
      showToast(`Error ${flow?.disabled ? 'enabling' : 'disabling'} the ${flow?.name} flow, please try again`, true);
      trackEvent(`Error: Flow state modal - ${flow?.disabled ? 'Enable' : 'Disable'} flow`);
    } finally {
      setLoading(false);
    }
  };

  const renderText = () => {
    const name = ((flow) => {
      return `${flow?.event_topic?.subject}/${flow?.event_topic?.action}`;
    })(flow);

    switch (name) {
      case 'order/fulfilled':
        if (flow?.disabled) {
          return null;
        }
        return (
          <>
            Disabling this flow will stop all review requests from being sent out
          </>
        );
      case 'incentive/rewarded':
        if (flow?.disabled) {
          return null;
        }
        return (
          <>
            Disabling this flow will prevent customers from being notified when they receive rewards
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      title={`${flow?.disabled ? 'Enable' : 'Disable'} the ${flow?.name} flow?`}
      primaryAction={{
        content: `${flow?.disabled ? 'Enable' : 'Disable'}`,
        onAction: toggleState,
        loading,
        destructive: !flow?.disabled
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: close,
          disabled: loading
        },
      ]}
    >
      <Modal.Section>
        <p>
          {renderText() ? renderText() : <>{`Are you sure you want to ${flow?.disabled ? 'enable' : 'disable'} this flow?`}</>}
        </p>
      </Modal.Section>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
});

export default connect(
  null,
  mapDispatchToProps
)(ToggleFlowStateModal);
