import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import {
  LegacyCard,
  Select,
  Form,
  FormLayout,
  Button,
  ButtonGroup
} from '@shopify/polaris';

export default function SelectOrg({ working, selectOrg, organizations, orgMeta }) {
  const [orgList, setOrgList] = useState(organizations || []);
  const [meta, setMeta] = useState(orgMeta || {});
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (organizations?.length > 0) {
      setOrgList(organizations);
      setSelected(organizations[0].id);
    }
  }, [organizations]);

  useEffect(() => {
    if (orgMeta) {
      setMeta(orgMeta);
    }
  }, [orgMeta]);

  const queryParams = window.location.search || '';
  const options = orgList?.map(org => {
    return { label: org.name, value: org.id };
  }) || [];
  options?.sort((a, b) => a.label?.localeCompare(b.label));

  const handleSelectChange = useCallback((value) => setSelected(parseInt(value)), []);

  const handleSubmit = () => {
    selectOrg(selected, orgList.find(o => o.id === parseInt(selected)), false);
  };

  const loadMoreOrgs = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/v1/organizations', {
        params: {
          include: 'plan_feature_set,stores',
          'page[after]': meta?.page?.after,
          'page[size]': 100
        }
      });

      if (response?.data?.organizations) {
        setOrgList([...orgList, ...response.data.organizations]);
      }

      setMeta(response?.data?.meta || {});
    } catch(e) {
      // No-op
    } finally {
      setLoading(false);
    }
  };

  return (
    <LegacyCard sectioned title="Select an organization">
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
            disabled={loading || working}
          />
          {meta?.page?.after && (
            <Button  onClick={loadMoreOrgs} loading={loading} variant="plain">Load more</Button>
          )}
          <ButtonGroup>
            <Button size="large"  submit loading={working} variant="primary">Select</Button>
            <Button
              disabled={working}

              url={`/oauth/create_organization${queryParams}`}
              variant="plain">Create new organization</Button>
          </ButtonGroup>
        </FormLayout>
      </Form>
    </LegacyCard>
  );
}
