import React from 'react';
import { Icon, Text } from "@shopify/polaris";
import { CheckCircleIcon } from "@shopify/polaris-icons";
import logo from 'assets/images/logoGreen.svg';
import 'styles/routes/Login.scss';

const LoginWrapper = ({ showExplainer, children }) => {
  const steps = [
    {
      title: 'Quick and free sign-up',
      description: 'Enter your email address to create an account.'
    },
    {
      title: 'Seamless integration',
      description: 'Effortlessly integrates into your stores theme.'
    }
  ];

  return (
    <div className={`login-page-container ${showExplainer ? 'with-explainer' : ''}`}>
      <div className="logo-container">
        <div>
          <picture>
            <img className="junip-logo" src={logo} alt="Junip Logo" />
          </picture>
          { showExplainer &&
            <div className="create-explainer mt-6 pt-2">
              { steps.map((step, index) =>
                <div key={index} className={`explainer-step d-flex ${index === (steps.length - 1) ? '' : 'mb-4'}`}>
                  <div className="mr-3 mt-1">
                    <Icon source={CheckCircleIcon} tone="base" />
                  </div>
                  <div>
                    <Text variant="headingMd" as="h2">{step.title}</Text>
                    <p className="pt-1">{step.description}</p>
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      </div>
      <div className="form-container">
        { children }
      </div>
    </div>
  );
};

export default LoginWrapper;
