import React, { useState } from 'react';
import moment from 'moment';
import { trackEvent } from 'utils/Mixpanel';
import {
  Banner,
  TextContainer,
  ProgressBar
} from '@shopify/polaris';

export default function UsageBanner({ billingSubscription }) {
  const {
    usage,
    usage_limit,
    next_billing_date
  } = billingSubscription;
  const progress = (usage / usage_limit) * 100;
  let status = 'default';
  let title = 'Usage limit';
  let body = '';

  const [active, setActive] = useState(progress >= 90);

  if (progress >= 100) {
    title = 'Usage limit reached';
    status = 'warning';
    body = 'To continue using Junip before you usage resets you need to upgrade your plan'
  } else if (progress >= 90) {
    status = 'info';
  }

  let bannerAction = {
    content: 'View plans',
    url: '/settings/billing',
    onAction: () => trackEvent('Usage banner - View plans')
  };

  return <>
  { active &&
    <div className="billing-banner-container mb-4">
      <Banner
        title={title}
        tone={status}
        onDismiss={() => setActive(false)}
        action={bannerAction}
      >
        <TextContainer spacing="tight">
          <p>You have used {usage} of your {usage_limit} order monthly usage limit. Upgrade your plan to get more usage and advanced features! Your usage will reset on {moment(next_billing_date).format('MMMM Do, YYYY')}. {body}</p>
        </TextContainer>
        { progress > 0 &&
          <div className="mt-4">
            <ProgressBar size="small" progress={progress} />
          </div>
        }
      </Banner>
    </div>
  }
  </>;
}
