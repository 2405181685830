import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { updateSolicitationCampaign } from 'redux/solicitationCampaigns';
import axios from 'axios';
import moment from 'moment';

import {
  Banner
} from '@shopify/polaris';

import useInterval from 'utils/useInterval';

const DEFAULT_POLLING_DELAY = 5000; // 5 seconds
const TIME_RANGE = 5*60*1000; // 5 minutes

function SolicitationCampaignBanner(props) {
  const { solicitationCampaigns, updateSolicitationCampaign } = props;
  const [pendingCampaign, setPendingCampaign] = useState(null);
  const [delay, setDelay] = useState(null);
  let status = '';
  let title = '';
  let text = '';
  let action = null;

  if (pendingCampaign) {
    const purchasedSince = moment(pendingCampaign.purchased_since).format('MMMM Do, YYYY');
    const purchasedBefore = moment(pendingCampaign.purchased_before).subtract(1, 'days').format('MMMM Do, YYYY');
    switch(pendingCampaign.state) {
      case 'pending':
      case 'processing':
      case 'waiting':
        status = 'info';
        title = 'Running campaign';
        text = `Requesting customers who placed an order between ${purchasedSince} and ${purchasedBefore} to leave a review.`;
        if (pendingCampaign.processed) {
          text += ` Processed ${pendingCampaign.processed} customers`;
        }
        break;
      case 'completed':
        status = 'success';
        title = 'Campaign complete';
        text = `We've requested your customers that have placed an order between ${purchasedSince} and ${purchasedBefore} to leave a review.`;
        if (pendingCampaign.processed) {
          text += ` Notified ${pendingCampaign.processed} customers.`;
        }
        text += ' View your message outbox to see sent messages';
        action = { content: 'View outbox', url: '/flows/outbox?tab=1', onAction: () => trackEvent('Campaign banner - View outbox') };
        break;
      case 'failed':
        status = 'critical';
        title = 'Campaign failed';
        text = 'There was an error running your campaign, please try again later.';
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    return () => {
      setDelay(null);
    };
  }, []);

  useEffect(() => {
    const data = solicitationCampaigns.data || [];
    const firstPendingCampaign = data.find(i => i.state === 'processing' || i.state === 'pending' || i.state === 'waiting');
    if (firstPendingCampaign) {
      setPendingCampaign(firstPendingCampaign);
    } else {
      const recentlyActive = data.find(i => (Date.now() - Date.parse(i.updated_at)) < TIME_RANGE);
      if (recentlyActive) {
        setPendingCampaign(recentlyActive);
      }
    }
  }, [solicitationCampaigns]);

  useEffect(() => {
    if (pendingCampaign) {
      if (pendingCampaign.state === 'processing' || pendingCampaign.state === 'pending' || pendingCampaign.state === 'waiting') {
        setDelay(DEFAULT_POLLING_DELAY);
      } else {
        setDelay(null);
      }
    }
  }, [pendingCampaign]);

  useInterval(() => {
    async function fetchData(id) {
      const response = await axios.get(`/api/v1/solicitation_campaigns/${id}`);
      const campaign = response?.data?.solicitation_campaign;
      setPendingCampaign(campaign);
      updateSolicitationCampaign(campaign);
    }
    if (pendingCampaign) {
      fetchData(pendingCampaign.id);
    }
  }, delay);

  return <>
    {pendingCampaign &&
      <div className="mb-4">
        <Banner
          title={title}
          tone={status}
          action={action ? action : undefined}
          onDismiss={() => setPendingCampaign(null)}
        >
          <p>{text}</p>
        </Banner>
      </div>
    }
  </>;
}

const mapStateToProps = (state) => ({
  solicitationCampaigns: state.solicitationCampaigns,
});

const mapDispatchToProps = (dispatch) => ({
  updateSolicitationCampaign: (campaign) => dispatch(updateSolicitationCampaign(campaign)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitationCampaignBanner);
