import React from 'react';

import {
  LegacyCard,
  TextContainer,
  SkeletonDisplayText,
  SkeletonBodyText
} from '@shopify/polaris';

const LoadingCardWrapper = ({
  loading,
  children,
  title,
  primaryFooterAction = null,
  sectioned = true,
  subdued = false,
  actions = null,
  footerActionAlignment = 'right'
}) => {
  if (loading) {
    return (
      <LegacyCard sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LegacyCard>
    );
  } else {
    return (
      <LegacyCard
        sectioned={sectioned}
        title={title}
        primaryFooterAction={primaryFooterAction}
        footerActionAlignment={footerActionAlignment}
        subdued={subdued}
        actions={actions}>
        {children}
      </LegacyCard>
    );
  }
}

export default LoadingCardWrapper;
