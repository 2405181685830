import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  Modal,
  FormLayout,
  TextField,
  Checkbox,
  RangeSlider,
  TextContainer,
  Button,
  Collapsible,
  Icon,
  Text,
} from "@shopify/polaris";
import { CalendarIcon, FilterIcon } from "@shopify/polaris-icons";

function CampaignConditions({ editing, setEditing, action, updateActionConditionList, showToast }) {
  const [conditions, setConditions] = useState(action?.condition_list);
  const [inputs, setInputs] = useState({});
  const [saving, setSaving] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);

  const resetInputs = useCallback(() => {
    if (conditions) {
      setInputs({
        max_requests_per_product: conditions.max_requests_per_product,
        product_review_percent: conditions.product_review_percent,
        marketing_check_disabled: conditions.marketing_check_disabled
      });
    } else {
      setInputs({});
    }
  }, [conditions]);

  useEffect(() => {
    if (conditions) {
      resetInputs();
    }
  }, [conditions, resetInputs]);

  const handleInputChange = (field) => {
    return (value) => {
      if (field === 'auto_approve_min_rating') {
        value = parseInt(value);
      }
      setInputs(inputs => ({ ...inputs, [field]: value }));
    };
  };

  const save = async () => {
    try {
      setSaving(true);
      const response = await axios.put(`/api/v1/flow_condition_lists/${conditions.id}`, {
        flow_condition_list: inputs
      });
      const newConditions = response.data.flow_condition_list;
      setConditions(newConditions);
      showToast('Conditions updated');
      trackEvent('Flows - Save conditions');
      setEditing(false);

      updateActionConditionList(action?.id, newConditions);
    } catch (e) {
      showToast('Error updating conditons, please try again', true);
      trackEvent('Error: Flows - Save conditions', { statusCode: e?.response?.status });
    } finally {
      setSaving(false);
    }
  };

  return <>
    <TextContainer spacing="loose">
      <div className="d-flex">
        <div className="mr-3">
          <Icon source={CalendarIcon} tone="primary" />
        </div>
        <TextContainer spacing="tight">
          <p>
            {conditions?.marketing_check_disabled ?
              <Text variant="bodyMd" as="span" fontWeight="semibold">All customers who have placed an order in the campaign timeframe</Text>
              :
              <Text variant="bodyMd" as="span" fontWeight="semibold">All customers that have opted into marketing and have placed an order in the campaign timeframe</Text>
            }
          </p>
        </TextContainer>
      </div>
      <div className="d-flex">
        <div className="mr-3">
          <Icon tone="primary" source={FilterIcon} />
        </div>
        <p>
          <Text variant="bodyMd" as="span" tone="subdued">
            <Button


              removeUnderline
              onClick={() => setEditing(true)}
              variant="monochromePlain">
              2 filters
            </Button>
          </Text>
        </p>
      </div>
    </TextContainer>
    <Modal
      open={editing}
      onClose={() => setEditing(false)}
      title="Conditions"
      primaryAction={{
        content: 'Save',
        onAction: () => save(),
        loading: saving
      }}
      secondaryActions={[{
        content: 'Cancel',
        onAction: () => setEditing(false),
        disabled: saving,
      }]}
    >
      <Modal.Section>
        <FormLayout>
          <Text variant="headingSm" as="h3">
            <Text variant="bodyMd" as="span" tone="subdued">Recipient filters</Text>
          </Text>
          <Checkbox
            label="Exclude customers who have already reviewed the same product."
            checked
            disabled
          />
          <Checkbox
            label="Require marketing opt-in"
            helpText="Junip will only send review requests to customers who accept marketing communications."
            checked={inputs.marketing_check_disabled === false}
            onChange={(value) => handleInputChange('marketing_check_disabled')(!value)}
            disabled={saving}
          />
        </FormLayout>
      </Modal.Section>
      {action?.action_type === 'email' && (
        <Modal.Section subdued>
          <TextContainer>
            <Button


              disclosure={advancedOpen ? 'up' : 'down'}
              onClick={() => setAdvancedOpen(!advancedOpen)}
              ariaExpanded={advancedOpen}
              ariaControls="advanced-options"
              variant="monochromePlain">
              <Text variant="headingSm" as="h3">Advanced conditions</Text>
            </Button>
            <Collapsible
              id="advanced-options"
              open={advancedOpen}
            >
              <TextContainer>
                {false && (
                  <TextField
                    label="Max review requests (per product)"
                    helpText="This limits the number of review requests that can be sent to the same customer."
                    type="number"
                    max={10}
                    min={1}
                    pattern="[0-9]"
                    value={`${inputs.max_requests_per_product || 10}`}
                    onChange={handleInputChange('max_requests_per_product')}
                    disabled={saving}
                  />
                )}
                <RangeSlider
                  label="Product review percent"
                  value={inputs.product_review_percent}
                  onChange={handleInputChange('product_review_percent')}
                  output
                  helpText="The percent at which a review request will be for a product review vs. a store review."
                  disabled={saving}
                  suffix={<p>{' '}{inputs.product_review_percent}</p>}
                />
              </TextContainer>
            </Collapsible>
          </TextContainer>
        </Modal.Section>
      )}
    </Modal>
  </>;
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(CampaignConditions);
