import React from 'react';

import {
  LegacyCard,
  TextContainer,
  SkeletonPage,
  Layout,
  SkeletonDisplayText,
  SkeletonBodyText
} from '@shopify/polaris';

export default function LoadingPageWrapper ({loading, children, cards = 2}) {
  if (loading) {

    return (
      <SkeletonPage breadcrumbs>
        <Layout>
          <Layout.Section>
            {
              [...Array(cards)].map((e, i) =>
                <LegacyCard sectioned key={i}>
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </TextContainer>
                </LegacyCard>
              )
            }
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );
  } else {
    return (
      <>
        {children}
      </>
    );
  }
}
