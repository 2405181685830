import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, OptionList } from '@shopify/polaris';

export default function ImportReviewPlatformSelectModal({ active, close }) {
  const providers = [
    {label: 'Stamped', value: 'stamped'},
    {label: 'Yotpo', value: 'yotpo'},
    {label: 'Nexus', value: 'generic'}
  ];

  const [provider, setProvider] = useState('');

  const history = useHistory();
  const submit = () => {
    if (provider) {
      history.push(`/imports`, { provider });
    }
  };

  return(
    <Modal
      open={active}
      onClose={close}
      title="Select your previous review provider"
      primaryAction={{
        content: 'Select provider',
        onAction: () => { submit(); },
        disabled: !provider
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => { close() }
        }
      ]}
    >
      <Modal.Section>
        <OptionList
          onChange={setProvider}
          options={providers}
          selected={provider}
        />
      </Modal.Section>
    </Modal>
  )
}
