import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import axios from 'axios';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import { appListingDetails } from 'utils/appListingDetails';
import {
  LegacyCard,
  Popover,
  ActionList,
  Link,
  Button,
  TextContainer,
  Icon,
  Modal,
  OptionList,
  Checkbox,
  LegacyStack,
  Tooltip,
  Text,
} from "@shopify/polaris";
import {
  EmailIcon,
  InfoIcon,
  PlusCircleIcon,
  EditIcon,
  DeleteIcon,
  ViewIcon,
  CheckCircleIcon,
  AppExtensionIcon,
} from "@shopify/polaris-icons";

import webhooks from 'assets/images/webhooks.svg';
import styles from 'styles/components/Flow.module.scss';
import FlowActionHelpModal from './FlowActionHelpModal';

function Actions({
  appInstalls,
  flow,
  messages,
  isNew = false,
  expanded = false,
  actions,
  action,
  remove,
  update,
  add,
  showToast
}) {

  const actionTemplateMapping = useMemo(() => {
    return {
      'order/fulfilled': ['product_review_requested'],
      'review/request': ['product_review_requested'],
      'order/targeted-by-campaign': ['product_review_requested'],
      'review/request-by-campaign': ['product_review_requested'],
      'review/created': ['review_confirmation'],
      'review/submitted': ['reward_distributed'],
      'review/confirmation-required': ['review_confirmation'],
      'response/created': ['review_response'],
      'incentive/rewarded': ['reward_distributed'],
    };
  }, []);

  const name = ((flow) => {
    return `${flow?.event_topic?.subject}/${flow?.event_topic?.action}`;
  })(flow);

  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [addPopoverActive, setAddPopoverActive] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [messageOptions, setMessageOptions] = useState([]);
  const [appOptions, setAppOptions] = useState([]);
  const [cleanup, setCleanup] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const toggleAddPopoverActive = useCallback(() => setAddPopoverActive(addPopoverActive => !addPopoverActive), []);
  const handleCleanupChange = useCallback((cleanup) => setCleanup(cleanup), []);

  const addAction = useCallback(async (target, id) => {
    setAdding(true);
    try {
      const response = await axios.post('/api/v1/flow_actions?include=condition_list,message_type,app_install', {
        flow_action: {
          flow_id: flow.id,
          action_type: target === 'message_type' ? 'email' : 'event',
          message_type_id: target === 'message_type' ? id : null,
          app_install_id: target === 'app_install' ? id : null,
        }
      });
      setAdding(false);
      add(response.data.flow_action);
      showToast('Action added');
      trackEvent('Flows - Add action');
    } catch (e) {
      setAdding(false);
      showToast('Error adding action, please try again', true);
      trackEvent('Error: Flows - Add action', { statusCode: e?.response?.status });
    }
  }, [flow, add, showToast]);

  const updateAction = async (target, id) => {
    try {
      const response = await axios.put(`/api/v1/flow_actions/${action.id}?include=condition_list,message_type,app_install`, {
        flow_action: {
          flow_id: flow.id,
          action_type: target === 'message_type' ? 'email' : 'event',
          message_type_id: target === 'message_type' ? id : null,
          app_install_id: target === 'app_install' ? id : null,
        }
      });
      update(action.id, response.data?.flow_action);
      showToast('Action updated');
      trackEvent('Flows - Update action');
    } catch (e) {
      showToast('Error updating action, please try again', true);
      trackEvent('Error: Flows - Update action', { statusCode: e?.response?.status });
      throw e;
    }
  };

  const deleteAction = async () => {
    try {
      setDeleting(true);
      await axios.delete(`/api/v1/flow_actions/${action.id}`, {
        params: {
          cleanup
        }
      });
      setShowDeleteModal(false);
      setDeleting(false);
      remove(action.id);
      showToast('Action removed');
      trackEvent('Flows - Delete action');
    } catch (e) {
      setDeleting(false);
      showToast('Error removing action, please try again', true);
      trackEvent('Error: Flows - Delete action', { statusCode: e?.response?.status });
    }
  };

  useEffect(() => {
    // Extract the current actions
    const currentActionMessageTypes = actions?.map(a => a?.message_type?.id);
    const currentActionAppInstalls = actions?.map(a => a?.app_install?.id);

    // Isolate the message types and app installs that are for this flow
    const messageTypes = messages?.data?.map(m => m.message_type)?.filter(m => actionTemplateMapping[name].includes(m.slug));
    const supportedApps = appInstalls?.data?.filter(a => {
      // Make sure the app install can send messages
      if (!a?.app?.sends_messages && !a?.app?.junip_app) {
        return false;
      }

      // Make sure that the app install supports this triggering event
      for (const event of a?.event_subscriptions) {
        if (`${event.subject}/${event.action}` === name) {
          return true;
        }
      }

      return false;
    });

    const messageTypeOptions = messageTypes?.filter(m => !currentActionMessageTypes.includes(m.id)) || [];
    const appInstallOptions = supportedApps?.filter(a => !currentActionAppInstalls.includes(a.id)) || [];

    setMessageOptions(messageTypeOptions);
    setAppOptions(appInstallOptions);
  }, [flow, name, actions, addAction, appInstalls, messages, actionTemplateMapping]);

  const disableRemoveButton = () => {
    if (actions?.length > 1) {
      return false;
    }

    if (name === 'review/created' || name === 'review/confirmation-required') {
      return true;
    }

    return false;
  };

  if (isNew) {
    let hasOptions = true;
    if (!appOptions.length && !messageOptions.length) {
      hasOptions = false;
    }

    const sections = [];
    if (messageOptions.length) {
      sections.push({
        title: 'Junip messages',
        items: messageOptions.map((option) => {
          return {
            content: `${option.name} template`,
            onAction: () => { setAddPopoverActive(false); addAction('message_type', option.id); },
            icon: EmailIcon,
          };
        })
      })
    }

    if (appOptions.length) {
      sections.push({
        title: 'Integrations',
        items: appOptions.map((option) => {
          const appDetails = appListingDetails[option.app?.slug];
          const icon = appDetails?.icon;
          return {
            content: `${appDetails?.name}`,
            prefix: <img height={20} alt="Action integration icon" src={icon} />,
            onAction: () => { setAddPopoverActive(false); addAction('app_install', option.id); }
          };
        })
      })
    }

    if (expanded) {
      return (
        <div className="free-wrapper">
          <TextContainer>
            <Text variant="headingMd" as="h2">{hasOptions ? 'Available actions' : 'No actions available'}</Text>
            {hasOptions &&
              <div className={styles.addGrid}>
                {messageOptions.map((option) =>
                  <FlowAddAction
                    key={option.id}
                    name={`${option.name} template`}
                    icon={<Icon source={EmailIcon} />}
                    add={() => addAction('message_type', option.id)}
                    adding={adding}
                  />
                )}
                {appOptions.map((option) => {
                  const appDetails = appListingDetails[option.app?.slug];
                  const icon = appDetails?.icon || webhooks;
                  return (
                    <FlowAddAction
                      key={option.id}
                      name={appDetails?.name || option.app?.name}
                      icon={<img height={20} alt="Action integration icon" src={icon} />}
                      add={() => addAction('app_install', option.id)}
                      adding={adding}
                    />
                  )})}
              </div>
            }
            <p>
              <Text variant="bodyMd" as="span" tone="subdued">Connect an email or SMS app to access more actions.{' '}
                <Link url="/integrations">Manage integrations</Link>
              </Text>
            </p>
          </TextContainer>
        </div>
      );
    }

    return (
      <Popover
        active={addPopoverActive}
        activator={
          <Tooltip content="Add action">
            <Button
              onClick={toggleAddPopoverActive}
              icon={PlusCircleIcon}

              loading={adding}
              variant="plain" />
          </Tooltip>
        }
        onClose={toggleAddPopoverActive}
      >
        {hasOptions && (
          <ActionList
            sections={sections}
          />
        )}
        <Popover.Pane sectioned>
          <TextContainer spacing="tight">
            <p>Add a messaging app</p>
            <p>
              <Text variant="bodyMd" as="span" tone="subdued">Integrate your favorite email or SMS app to get more functionality out of flows</Text>
            </p>
            <p><Link url="/integrations">View integrations</Link></p>
          </TextContainer>
        </Popover.Pane>
      </Popover>
    );
  }

  const editItems = [];

  // TODO: We should be using the app slug here not the provider! This is a hack for now
  const appDetails = appListingDetails[action?.app_install?.provider] || {};
  const appInstall = appInstalls?.data?.find(a => a.app_id === action?.app_install?.app_id);
  const appData = appInstall?.app;
  if (!appDetails?.name && appData) {
    appDetails.name = appData.name;
    appDetails.icon = webhooks;
  }
  if (appDetails?.helpGuide) {
    editItems.push({
      content: 'View tips',
      icon: InfoIcon,
      onAction: () => setShowHelpModal(true)
    });
  }

  const messageTemplate = messages?.data?.find(m => m.message_type?.id === action?.message_type?.id);
  if (messageTemplate) {
    editItems.push({
      content: 'View template',
      icon: ViewIcon,
      url: `/flows/templates/${messageTemplate.id}`,
    });
  }

  editItems.push({
    content: 'Delete',
    destructive: true,
    icon: DeleteIcon,
    loading: deleting,
    disabled: disableRemoveButton(),
    onAction: () => { setShowDeleteModal(true); }
  });

  editItems.push({
    content: 'Edit',
    icon: EditIcon,
    onAction: () => { setShowEditModal(true); }
  });

  return <>
    <div>
      <div className={`${styles.flowCard} ${styles.actionWrapper}`}>
        <div className={styles.flowCompleteIcon}>
          <Tooltip content={
            action?.action_type === 'email' ? "Complete" : "Visit the connected app to send your message. "
          }>
            <Button
              icon={action?.action_type === 'email' ? CheckCircleIcon : AppExtensionIcon}

              disabled={action?.action_type === 'email'}
              onClick={() => setShowHelpModal(true)}
              variant="plain" />
          </Tooltip>
        </div>
        <div className={styles.flowContent}>
          <TextContainer>
            <div className="d-flex justify-content-between" style={{marginBottom: "-0.5rem"}}>
              <Text variant="headingSm" as="h3">
                <Text variant="bodyMd" as="span" tone="subdued">Action</Text>
              </Text>
              <LegacyStack>
                {editItems.map((item, index) => (
                  <Tooltip key={item.content} content={item.content}>
                    <Button
                      icon={item.icon}
                      size="small"

                      disabled={item.disabled || adding}
                      loading={item.loading || false}
                      url={item?.url || null}
                      onClick={item.onAction}
                      accessibilityLabel={item.content}
                      variant="plain" />
                  </Tooltip>
                ))}
              </LegacyStack>
            </div>
            <div className="d-flex align-items-center">
              <div className="mr-3">
                {appDetails?.icon ?
                  <img height={20} alt="Action integration icon" src={appDetails.icon} style={{display: "block"}} />
                  :
                  <Icon source={EmailIcon} tone="primary" />
                }
              </div>
              <p>
                <Text variant="bodyMd" as="span" fontWeight="semibold">Send {action?.action_type === 'email' ? 'email from Junip' : `event to ${appDetails?.name}`}
                </Text>
              </p>
            </div>
            <Action action={action} messages={messages} flow={flow} name={name} />
          </TextContainer>
        </div>
      </div>
    </div>
    <Modal
      open={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      title="Delete this sequence?"
      primaryAction={{
        content: 'Yes, delete',
        onAction: deleteAction,
        destructive: true,
        loading: deleting,
      }}
      secondaryActions={[{
        content: 'Cancel',
        onAction: () => setShowDeleteModal(false),
        disabled: deleting,
      }]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Are you sure you want to delete this sequence (including the action & its rules)?
          </p>
          <p>
            Check the box below if you'd like to cancel any pending events or emails that are associated with this action:
          </p>
          <Checkbox
            label="Cancel pending events and emails associated with this action"
            checked={cleanup}
            onChange={handleCleanupChange}
          />
        </TextContainer>
      </Modal.Section>
    </Modal>
    <ActionEditModal
      show={showEditModal}
      close={() => setShowEditModal(false)}
      save={updateAction}
      messageOptions={messageOptions}
      appOptions={appOptions}
      name={name}
    />
    <FlowActionHelpModal
      open={showHelpModal}
      close={() => setShowHelpModal(false)}
      flow={flow}
      action={action}
      appDetails={appDetails}
    />
  </>;
}

function Action({ flow, action, messages, name }) {
  const actionName = () => {
    if (action?.action_type === 'email' && action?.message_type) {
      if (['review/request-by-campaign', 'order/targeted-by-campaign', 'order/fulfilled', 'review/request'].includes(name)) {
        return <RequestEmailAction action={action} messages={messages} />;
      }
      const message = messages?.data?.find(m => m.message_type_id === action?.message_type?.id);
      const subjectBlock = message.message_blocks?.find(m => m.name === 'subject');
      return (
        <div className={styles.actionTextContainer}>
          <TextContainer spacing="tight">
            <p>
              {subjectBlock.template || subjectBlock.default_template}
            </p>
            <Text variant="bodySm" as="p">
              <Link monochrome url={`/flows/templates/${message?.id}`}>{message?.message_type?.name} template</Link>
            </Text>
          </TextContainer>
        </div>
      );
    } else {
      return (
        <div className={styles.actionTextContainer}>
          <code style={{ textTransform: 'capitalize' }}>
            Junip - {`${flow?.event_topic?.subject} ${flow?.event_topic?.action?.replaceAll?.('-', ' ')}`}
          </code>
        </div>
      );
    }
  };

  return (
    <>
      {actionName()}
    </>
  );
}

function RequestEmailAction({ action, messages }) {
  const percentSplit = action?.condition_list?.product_review_percent;

  const productMessage = messages?.data?.find(m => m.message_type?.slug === 'product_review_requested');
  const productSubjectBlock = productMessage?.message_blocks?.find(m => m.name === 'subject');

  const storeMessage = messages?.data?.find(m => m.message_type?.slug === 'store_review_requested');
  const storeSubjectBlock = storeMessage?.message_blocks?.find(m => m.name === 'subject');

  return (
    <TextContainer spacing="loose">
      {percentSplit > 0 && (
        <div className={styles.actionTextContainer}>
          <TextContainer spacing="tight">
            <p>
              <span>
                {productSubjectBlock.template || productSubjectBlock.default_template}
              </span>{' '}
              <Text variant="bodyMd" as="span" tone="success">
                {percentSplit !== 100 ? `(${percentSplit}%)` : ''}
              </Text>
            </p>
            <Text variant="bodySm" as="p">
              <Link monochrome url={`/flows/templates/${productMessage?.id}`}>{productMessage?.message_type?.name} template</Link>
            </Text>
          </TextContainer>
        </div>
      )}
      {percentSplit < 100 && (
        <div className={styles.actionTextContainer}>
          <TextContainer spacing="tight">
            <p>
              <span>
                {storeSubjectBlock.template || storeSubjectBlock.default_template}
              </span>{' '}
              <Text variant="bodyMd" as="span" tone="success">
                {percentSplit !== 0 ? `(${100 - percentSplit}%)` : ''}
              </Text>
            </p>
            <Text variant="bodySm" as="p">
              <Link monochrome url={`/flows/templates/${storeMessage?.id}`}>{storeMessage?.message_type?.name} template</Link>
            </Text>
          </TextContainer>
        </div>
      )}
    </TextContainer>
  );
}

function ActionEditModal({ name, show, close, messageOptions, appOptions, save }) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const closeModal = () => {
    if (loading) {
      return;
    }

    setSelected([]);
    close();
  };

  const submit = async () => {
    setLoading(true);
    const [target, id] = selected[0].split('::');
    try {
      await save(target, id);
      closeModal();
    } catch (e) {
      // No-op
    } finally {
      setLoading(false);
    }
  };

  const mOptions = messageOptions?.map((m) => {
    return {
      label: `${m.name} template`,
      value: `message_type::${m.id}`,
      media: <Icon source={EmailIcon} />,
    };
  }) || [];

  const aOptions = appOptions?.map((a) => {
    const appDetails = appListingDetails[a.app?.slug];
    const icon = appDetails?.icon;
    return {
      label: `${appDetails?.name}`,
      value: `app_install::${a.id}`,
      media: <img height={20} alt="Action integration icon" src={icon} />,
    };
  }) || [];

  const options = [...mOptions, ...aOptions];

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title="Edit action"
      primaryAction={{
        content: 'Save',
        onAction: submit,
        loading: loading,
        disabled: !selected.length,
      }}
      secondaryActions={[{
        content: 'Cancel',
        onAction: closeModal,
        disabled: loading,
      }]}
    >
      <Modal.Section flush={options?.length}>
        <TextContainer>
          {options.length ?
            <OptionList
              title="Select a new action"
              options={options}
              selected={selected}
              onChange={setSelected}
            />
            :
            <p>No actions available to switch to</p>
          }
        </TextContainer>
      </Modal.Section>
      {name !== 'response/created' && (
        <Modal.Section subdued>
          <TextContainer spacing="tight">
            <p>Add a messaging app</p>
            <p>
              <Text variant="bodyMd" as="span" tone="subdued">Integrate your favorite email or SMS app to get more functionality out of flows</Text>
            </p>
            <p><Link url="/integrations">View integrations</Link></p>
          </TextContainer>
        </Modal.Section>
      )}
    </Modal>
  );
};

function FlowAddAction({ name, icon, add, adding }) {
  const [loading, setLoading] = useState(false);

  const addAction = async () => {
    setLoading(true);
    try {
      await add();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div>
      <LegacyCard sectioned>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {icon}
            <p className="ml-3">
              <Text variant="bodyMd" as="span" fontWeight="semibold">{name}</Text>
            </p>
          </div>
          <Button loading={loading} onClick={addAction} disabled={adding}  variant="plain">
            Add
          </Button>
        </div>
      </LegacyCard>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(Actions);
