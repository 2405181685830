import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDisplaySettings, saveDisplaySettings } from "redux/displaySettings";
import {
  Button,
  Card,
  Page,
  Layout,
  Checkbox,
  Collapsible,
  BlockStack
} from "@shopify/polaris";
import useLastLocation from "utils/useLastLocation";
import LoadingWrapper from "components/LoadingWrapper";

export default function OnSiteSettings() {
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();
  const displaySettings = useSelector((state) => state.displaySettings);
  const [onSiteEnabled, setOnSiteEnabled] = useState(displaySettings?.data?.onsite_enabled);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch(fetchDisplaySettings());
  }, [dispatch]);

  useEffect(() => {
    if (displaySettings?.data) {
      setOnSiteEnabled(displaySettings?.data?.onsite_enabled);
    }
  }, [displaySettings?.data]);

  const saveSettings = async () => {
    setSaving(true);
    // Save the display settings, I believe we can actually use await here because of how saveDisplaySettings is implemented
    await dispatch(saveDisplaySettings(displaySettings?.data?.id, { onsite_enabled: onSiteEnabled }));
    setSaving(false);
  };

  return (
    <Page
      title="On-site settings"
      backAction={{ content: 'Settings', url: lastLocation || '/settings' }}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Visibility"
          description="Show or hide all installed widgets."
        >
          <LoadingWrapper sections={1} loading={displaySettings.loading}>
            <Card>
              <BlockStack gap="300">
                <Checkbox
                  label="Allow Junip displays on public site"
                  checked={onSiteEnabled}
                  onChange={setOnSiteEnabled}
                  helpText="When disabled, any installed widgets will be hidden on your site. They will still be visible to you in preview mode."
                />
                <Collapsible open={displaySettings?.data?.onsite_enabled !== onSiteEnabled}>
                  <Button
                    variant="primary"
                    loading={saving}
                    onClick={saveSettings}
                  >
                    Save
                  </Button>
                </Collapsible>
              </BlockStack>
            </Card>
          </LoadingWrapper>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}
