import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { Banner } from '@shopify/polaris';

function EmailUnverifiedBanner(props) {
  const [sendingEmail, setSendingEmail] = useState(false);
  const title = props.title || 'Email not verified';
  const description = props.description || 'Check your email for a verification link, you can resend the link using the button below.';

  const sendVerification = async () => {
    setSendingEmail(true);
    const id = props.user.id;
    try {
      await axios.post(`/api/v1/users/${id}/send_verification_email`);
      props.showToast('Verification sent, please check your inbox');
      setSendingEmail(false);
      trackEvent('Email unverified banner - Resend verification');
    } catch (e) {
      props.showToast('Error sending verification, please check your email and try again', true);
      setSendingEmail(false);
      trackEvent('Error: Email unverified banner - Resend verification');
    }
  };

  return (
    <Banner
      title={title}
      action={{
        content: 'Resend verification',
        onAction: sendVerification,
        loading: sendingEmail,
        disabled: sendingEmail
      }}
      tone="warning"
    >
      <p>{description}</p>
    </Banner>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(EmailUnverifiedBanner);
