import React, { useState, useCallback } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { 
  Modal,
  Form, 
  TextField, 
  Link,
  List,
  TextContainer,
} from '@shopify/polaris';

export default function ApiKeyModal({showModal, setShowModal, installed, app, appDetails, install, update}) {
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitKey = async () => {
    setLoading(true);
    try {
      if (installed) {
        await update(apiKey);
      } else {
        await install(apiKey);
      }
      setShowModal(false);
      setLoading(false);
      setApiKey('');
    } catch(e) {
      setError(true);
      setLoading(false);
    }
  };

  const handleClearButtonClick = useCallback(() => { setApiKey(''); setError(false); }, []);
  const handleChange = useCallback((value) => {setApiKey(value); setError(false); }, []);

  return (
    <Modal
      open={showModal}
      title={installed ? `Update your ${appDetails?.name} API key` : `Install ${appDetails?.name}?`}
      onClose={() => !loading && setShowModal(false)}
      primaryAction={{
        content: installed ? 'Update' : 'Install',
        loading: loading,
        disabled: !apiKey,
        onAction: () => { submitKey(); trackEvent('API key modal - Submit key') }
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: loading,
        onAction: () => { setShowModal(false); trackEvent('API key modal - Cancel') },
      }]}
    >
      <Modal.Section>
        <Form onSubmit={submitKey}>
          <TextField
            value={apiKey}
            label={`Enter your ${appDetails?.name} API key`}
            type="text"
            clearButton
            onClearButtonClick={handleClearButtonClick}
            onChange={handleChange}
            error={error && `Please enter a valid ${appDetails?.name} API key`}
          />
        </Form>
      </Modal.Section>
      <Modal.Section subdued>
        <TextContainer>
          <p>Steps on how to find your private API key:</p>
          <List type="number">
            {appDetails?.apiKeyHelpSteps?.map((f, i) => <List.Item key={i}>{f}</List.Item>)}
          </List>
          <p>Visit this <Link url={app.auth_url} external>link</Link> to find your {appDetails?.name} private API key</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}
