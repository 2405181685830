import React, { useState, useCallback } from 'react';
import {
  LegacyCard,
  Form,
  FormLayout,
  TextField,
  Button
} from '@shopify/polaris';

export default function CreateOrg({ working, createOrg }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSubmit = async () => {
    setLoading(true);
    await createOrg(value);
    setLoading(false);
  };

  return (
    <LegacyCard sectioned title="What's the name of your business?">
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <TextField
            value={value}
            name="organization"
            onChange={handleChange}
            type="text"
            placeholder="Enter your organization name"
            autoFocus={true}
            disabled={loading}
          />
          <Button
            disabled={!value}
            size="large"

            submit
            loading={loading || working}
            variant="primary">
            Create
          </Button>
        </FormLayout>
      </Form>
    </LegacyCard>
  );
}
