import React, { useState, useEffect } from 'react';
import {
  Banner
} from '@shopify/polaris';
import axios from 'axios';
import useInterval from 'utils/useInterval';

const DEFAULT_POLLING_DELAY = 5000;
const TIME_RANGE = 24*60*60*1000; // 24 hours

export default function ExportReviewsStatusBanner({ exportStarted }) {
  const [pendingExport, setPendingExport] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [delay, setDelay] = useState(null);

  useEffect(() => {
    return () => {
      setDelay(null);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (exportStarted) {
      fetchData();
    }
  }, [exportStarted]);

  useEffect(() => {
    if (pendingExport) {
      if (!pendingExport.download_url) {
        setDelay(DEFAULT_POLLING_DELAY);
        setExporting(true);
      } else {
        setDelay(null);
        setExporting(false);
      }
    }
  }, [pendingExport]);

  useInterval(() => {
    if (pendingExport) {
      fetchData(pendingExport.id);
    }
  }, delay);

  const fetchData = async (id) => {
    let url = '/api/v1/review_exports';
    if (id) {
      url = `${url}/${id}`;
    }
    try {
      const response = await axios.get(url);
      if (id) {
        setPendingExport(response?.data.review_export);
      } else {
        const firstPendingExport = response?.data?.review_exports?.[0];
        if (firstPendingExport) {
          if (firstPendingExport.download_url) {
            if ((Date.now() - Date.parse(firstPendingExport.updated_at) < TIME_RANGE)) {
              setPendingExport(firstPendingExport);
            }
          } else {
            setPendingExport(firstPendingExport);
          }
        }
      }
    } catch (e) {
      // No-op
    }
  };

  return <>
    { pendingExport &&
      <div className="mb-4">
        <Banner
          title={exporting ? 'Exporting reviews...' : 'Your export is ready!'}
          tone={exporting ? 'info' : 'success'}
          onDismiss={() => setPendingExport(null)}
          action={exporting ? null : {content: 'Download', url: pendingExport.download_url}}
        >
          <p>{exporting ? 'Generating your export' : 'Your reviews export has been generated. Click the button below to download'}</p>
        </Banner>
      </div>
    }
  </>;
};
