import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { setUser } from 'redux/user';
import { showToast } from 'redux/toast';
import {
  LegacyCard,
  Select,
  Form,
  FormLayout,
  Button
} from '@shopify/polaris';
import LoginWrapper from 'components/LoginWrapper';

function SelectStore(props) {
  if (!props.location.state || !props.location.state.user) {
    props.history.replace('/login');
  }

  const { user, stores } = props.location.state;

  const options = stores.map(store => {
    return {label: `${store.name} (${store.url})`, value: store.id};
  });

  const [selected, setSelected] = useState(options[0].value);
  const [loading, setLoading] = useState(false);
  const handleSelectChange = useCallback((value) => setSelected(parseInt(value)), []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.post(`/api/v1/stores/${selected}/scope`);
      props.setUser(user);
    } catch(e) {
      setLoading(false);
      props.showToast('Oops, unable to login! Please try again', true);
    }
  };

  return (
    <LoginWrapper>
      <LegacyCard sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <Select
              label="Select a store:"
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
            <Button size="large" submit loading={loading} variant="primary">Select</Button>
          </FormLayout>
        </Form>
      </LegacyCard>
    </LoginWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(SelectStore);
