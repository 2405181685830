import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { fetchProducts } from 'redux/products';
import {
  ResourceList,
  TextField,
  Icon,
  Button,
  LegacyStack,
  Popover,
  Pagination,
  Form,
  Thumbnail,
  Text,
} from "@shopify/polaris";
import noImage from 'assets/images/noImage.jpg';

import { SearchIcon } from "@shopify/polaris-icons";

function ProductFilter({
  productFilter,
  setProductFilter,
  products,
  fetchProducts,
  isFilter = true,
  fullWidth = false,
  disabled = false,
  size = 'small',
  paramFilter
}) {
  const [popoverActive, setPopoverActive] = useState(false);
  const [queryValue, setQueryValue] = useState(null);
  const [filters] = useState(paramFilter || {});

  useEffect(() => {
    if (popoverActive) {
      fetchProducts({ filters });
    }
  }, [fetchProducts, filters, popoverActive]);

  useEffect(() => {
    if (products.data) {
      const productId = productFilter?.id ? null : productFilter;
      if (productId) {
        const product = products.data.find(p => p.id === parseInt(productId));
        if (product) {
          setProductFilter(product);
        } else {
          setProductFilter(null);
        }
      }
    }
  }, [products.data, productFilter, setProductFilter]);

  useEffect(() => {
    async function fetchData(id) {
      try {
        const response = await axios.get(`/api/v1/products/${id}`, {
          params: {
            include: 'image,product_category,traits',
            ...filters
          }
        });
        const product = response.data.product || null;
        setProductFilter(product);
      } catch (e) {
        // No - op
      }
    }

    const productId = productFilter?.id ? null : productFilter;
    if (productId) {
      fetchData(productId);
    }
  }, [productFilter, setProductFilter, filters]);

  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );

  const handleQueryValueRemove = useCallback(() => {
    setQueryValue(null);
    fetchProducts({ filters });
  }, [fetchProducts, filters]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const handleFilterSubmit = (e) => {
    e.stopPropagation();
    fetchProducts({ queryValue, filters });
  };

  const nextPage = (after, queryValue) => {
    fetchProducts({ page: { after }, queryValue, filters });
  };

  const previousPage = (before, queryValue) => {
    fetchProducts({ page: { before }, queryValue, filters });
  };

  const textSize = size === 'small' ? 'bodySm' : 'bodyMd';

  const activator = (
    <Button
      onClick={togglePopoverActive}
      disclosure="select"
      disabled={disabled}
      fullWidth={fullWidth}
      textAlign={fullWidth ? 'left' : 'center'}
    >
      <Text variant={textSize}>
        { isFilter ?
          <>
            <Text as="span" tone="subdued">Product</Text>{' '}
            { productFilter?.title || 'All'}
          </>
        :
          <>
            { productFilter?.title || <Text as="span" tone="subdued">Select a product</Text> }
          </>
        }
      </Text>
    </Button>
  );

  const renderItem = (product) => {
    const source = product.image?.url_200x200 || noImage;
    return (
      <ResourceList.Item
        id={product.id}
        media={
          <Thumbnail size="small" source={source} />
        }
        verticalAlignment="center"
        onClick={() => { setProductFilter(product); togglePopoverActive() }}
      >
        {product.title}
      </ResourceList.Item>
    );
  };

  return (
    <Popover
      fluidContent
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      fullWidth={fullWidth}
    >
      <div className="p-2">
        <Form name="productFilterForm" onSubmit={handleFilterSubmit}>
          <LegacyStack wrap={false} spacing="tight">
            <LegacyStack.Item fill>
              <TextField
                type="text"
                placeholder="Filter by name"
                value={queryValue || ''}
                onChange={handleQueryValueChange}
                clearButton
                onClearButtonClick={handleQueryValueRemove}
                disabled={products.loading}
              />
            </LegacyStack.Item>
            <Button loading={products.loading} disabled={products.loading} submit><Icon source={SearchIcon} /></Button>
          </LegacyStack>
          <div className="mt-2">
            <Button
              disabled={products.loading}
              onClick={() => setProductFilter(null)}
              variant="plain">Clear selection</Button>
          </div>
        </Form>
      </div>
      <Popover.Pane>
        <ResourceList
          items={products?.data || []}
          renderItem={renderItem}
          loading={products.loading}
          emptyState={
            <>
              {products?.data?.length === 0 && queryValue !== null &&
                <p className="p-2 text-center">There are no products with the name "{queryValue}"</p>
              }
              {products?.data?.length === 0 && queryValue === null &&
                <p className="p-2 text-center">No products</p>
              }
            </>
          }
        />
        <div className="text-center p-2">
          <Pagination
            hasPrevious={products?.meta?.page?.before !== null}
            hasNext={products?.meta?.page?.after !== null}
            onPrevious={() => previousPage(products?.meta?.page?.before, queryValue)}
            onNext={() => nextPage(products?.meta?.page?.after, queryValue)}
          />
        </div>
      </Popover.Pane>
    </Popover>
  );
}

const mapStateToProps = (state) => ({
  products: state.products,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: ({ page, queryValue, filters }) => dispatch(fetchProducts({ page, queryValue, filters })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFilter);
