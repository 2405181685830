import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import {
  Page,
  LegacyCard,
  FormLayout,
  TextField,
  PageActions,
  Layout,
  TextContainer,
  Select,
  Button,
  ChoiceList,
  Text,
} from "@shopify/polaris";
import SurveyOptions from 'components/SurveyOptions';
import NewSurveyOption from 'components/NewSurveyOption';
import NewSurveyRule from 'components/NewSurveyRule';
import SurveyRules from 'components/SurveyRules';
import QuestionPreviewModal from 'components/QuestionPreviewModal';

import ratingExample from 'assets/images/customQuestions/rating-example.svg';

function Create({ showToast, history }) {
  const queryParams = history.location.search;
  const params = new URLSearchParams(queryParams);

  const [creating, setCreating] = useState(false);
  const [name, setName] = useState('');
  const [type] = useState(params.get('type') || 'text');
  const [question, setQuestion] = useState('');
  const [labels, setLabels] = useState({label_left: '', label_center: '', label_right: ''});
  const [responseSharing, setResponseSharing] = useState('public');
  const [showExampleModal, setShowExampleModal] = useState(false);
  const [appliesToAllProducts, setAppliesToAllProducts] = useState([null]);

  const [options, setOptions] = useState([]);
  const [rules, setRules] = useState([]);

  const handleNameChange = useCallback((newName) => setName(newName), []);
  const handleQuestionChange = useCallback((newQuestion) => setQuestion(newQuestion), []);
  const handleLabelChange = (field) => {
    return (value) => {
      setLabels(labels => ({ ...labels, [field]: value }));
    };
  };

  const handleResponseSharingChange = useCallback((newResponseSharing) => setResponseSharing(newResponseSharing), []);

  const handleAppliesToAllProductsChange = useCallback((newAppliesToAllProducts) => setAppliesToAllProducts(newAppliesToAllProducts), []);

  const addOption = (newValue, newDescription) => {
    setOptions([...options, { value: newValue, description: newDescription }]);
    trackEvent('New custom question - Add option');
  };

  const removeOption = (_, index) => {
    setOptions(options.filter((_, i) => i !== index));
    trackEvent('New custom question - Remove option');
  };

  const addRule = (newRule) => {
    setRules([...rules, newRule]);
    trackEvent('New custom question - Add rule');
  };

  const removeRule = (_, index) => {
    setRules(rules.filter((_, i) => i !== index));
    trackEvent('New custom question - Remove rule');
  };

  const createSurveyQuestion = async () => {
    setCreating(true);
    try {
      const response = await axios.post('/api/v1/survey_questions', {
        survey_question: {
          name,
          question,
          applies_to_all_products: appliesToAllProducts?.includes(true) || false,
          private: responseSharing === 'private'  ? true : false,
          value_type: type,
          meta: type === 'rating' ? labels : undefined,
        }
      });

      if (type === 'radio') {
        for (let option of options) {
          try {
            await axios.post('/api/v1/survey_options', {
              survey_option: {
                question_id: response.data.survey_question.id,
                value: option.value,
                description: option.description || null
              }
            });
          } catch(e) {
            if (e.response?.status === 400) {
              trackEvent('Error: New custom question - Create survey option');
            }
          }
        }
      }

      if (response.data.survey_question.applies_to_all_products === false) {
        for (let rule of rules) {
          await axios.post('/api/v1/survey_rules', {
            survey_rule: {
              question_id: response.data.survey_question.id,
              ...rule
            }
          });
        }
      }


      showToast('Custom question created');
      history.replace(`/forms/questions/${response.data.survey_question.id}`);

      trackEvent('New custom question - Create');
    } catch (e) {
      showToast('Error creating custom question, please try again', true);
      trackEvent('Error: New custom question - Create', { statusCode: e?.response?.status });
      setCreating(false);
    }
  };

  const isValid = () => {
    if (!name || !question || !type) {
      return false;
    }

    if (type === 'radio' && !options.length) {
      return false;
    }

    if (type === 'rating' && !labels.label_left && !labels.label_center && !labels.label_right) {
      return false;
    }

    return true;
  };

  const primaryAction = {
    content: 'Save',
    onAction: createSurveyQuestion,
    disabled: !isValid(),
    loading: creating
  };

  const renderTypeName = () => {
    if (type === 'radio') {
      return 'Multiple Choice';
    } else if (type === 'rating') {
      return 'Linear Scale';
    } else {
      return 'Written Answer';
    }
  };

  const renderPlaceholderText = () => {
    switch (type) {
      case 'radio':
        return {
          name: 'ex. Skin type',
          question: 'ex. What is your skin type?'
        };
      case 'rating':
        return {
          name: 'ex. Fit',
          question: 'ex. How was the fit?'
        };
      default:
        return {
          name: 'ex. Intended use',
          question: 'ex. What do you intend to use this product for?'
        };
    };
  };

  return (
    <Page
      title={`New ${renderTypeName()} Question`}
      backAction={{
        content: 'New custom questions',
        url: '/forms/questions/new'
      }}
    >
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  type="text"
                  label={responseSharing === 'private' ? 'Name' : 'Display name'}
                  value={name}
                  placeholder={renderPlaceholderText().name}
                  helpText={responseSharing === 'private' ? undefined : "We'll use this when displaying responses on your site."}
                  maxLength={20}
                  onChange={handleNameChange}
                />
              </FormLayout.Group>
              <TextField
                type="text"
                label="Question"
                value={question}
                placeholder={renderPlaceholderText().question}
                onChange={handleQuestionChange}
              />
            </FormLayout>
          </LegacyCard>
          {type === 'rating' && (
            <LegacyCard title="Scale">
              <LegacyCard.Section>
                <div className="text-center mx-auto">
                  <img src={ratingExample} alt="Rating example" className="w-100" style={{maxWidth: '575px'}} />
                </div>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField
                      label="Min label"
                      onChange={handleLabelChange('label_left')}
                      placeholder="ex. Runs small"
                      value={labels.label_left}
                    />
                    <TextField
                      label="Middle label (optional)"
                      onChange={handleLabelChange('label_center')}
                      placeholder="ex. Perfect"
                      value={labels.label_center}
                    />
                    <TextField
                      label="Max label"
                      onChange={handleLabelChange('label_right')}
                      placeholder="ex. Runs large"
                      value={labels.label_right}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </LegacyCard.Section>
            </LegacyCard>
          )}
          {type === 'radio' &&
            <LegacyCard title="Responses">
              <LegacyCard.Section>
                <p>
                  <Text variant="bodyMd" as="span" tone="subdued">Add at least 2 options</Text>
                </p>
              </LegacyCard.Section>
              <SurveyOptions options={options} removeOption={removeOption} />
              <NewSurveyOption addOption={addOption} />
            </LegacyCard>
          }
          <LegacyCard title="Product assignment">
            <LegacyCard.Section>
              <TextContainer>
                <p>Assign this question to:</p>
                <ChoiceList
                  choices={[
                    {
                      label: 'All products',
                      value: true,
                      helpText: 'This question will be included on all product review forms.'
                    },
                    {
                      label: 'Some products',
                      value: false,
                      helpText: 'This question will be included on only some of your products.'
                    }
                  ]}
                  selected={appliesToAllProducts}
                  onChange={handleAppliesToAllProductsChange}
                />
              </TextContainer>
            </LegacyCard.Section>
            {appliesToAllProducts?.includes(false) && (
              <>
                <SurveyRules
                  rules={rules}
                  removeRule={removeRule}
                />
                <NewSurveyRule addRule={addRule} />
              </>
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <LegacyCard title="Details">
            <LegacyCard.Section title="Question type">
              <p>{renderTypeName()}</p>
            </LegacyCard.Section>
            <LegacyCard.Section title="Response sharing">
              <TextContainer spacing="tight">
                <Select
                  options={[
                    { label: 'Public', value: 'public' },
                    { label: 'Private', value: 'private' },
                  ]}
                  value={responseSharing}
                  onChange={handleResponseSharingChange}
                  helpText={responseSharing === 'public' ? 'Responses will be published with reviews' : <>Responses are <b>only visible to your team</b>. Once created, private questions can never be switched to public.</>}
                />
                {responseSharing === 'public' && (
                  <Button onClick={() => setShowExampleModal(true)} variant="plain">View example</Button>
                )}
              </TextContainer>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={primaryAction}
            secondaryActions={[{
              content: 'Discard',
              disabled: creating,
              onAction: () => history.replace('/forms/questions/new')
            }]}
          />
        </Layout.Section>
      </Layout>
      <QuestionPreviewModal open={showExampleModal} close={() => setShowExampleModal(false)} />
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(Create);
