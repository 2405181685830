import React, { useEffect, useState } from 'react';
import {
  Page,
  Badge,
  Card,
  List,
  Link,
  BlockStack,
  TextField,
  Banner,
  Divider
} from '@shopify/polaris';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaReviewSettings, saveMetaReviewSettings } from 'redux/metaReviewSettings';
import UpgradeTrigger from 'components/UpgradeTrigger';

export default function Meta() {
  const dispatch = useDispatch();
  const metaReviewSettings = useSelector(state => state.metaReviewSettings);
  const planFeatureSets = useSelector(state => state.planFeatureSets);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch(fetchMetaReviewSettings());
  }, [dispatch]);

  const toggleMetaReviewSettings = async (product_feed_enabled) => {
    setSaving(true);
    await dispatch(saveMetaReviewSettings(metaReviewSettings.data.id, { product_feed_enabled: !product_feed_enabled }));
    setSaving(false);
  };

  const { product_feed_enabled, store_path, product_feed_generated_at } = metaReviewSettings.data || {};

  return (
    <LoadingPageWrapper loading={metaReviewSettings.loading}>
      <Page
        title="Meta syndication"
        titleMetadata={product_feed_enabled ? <Badge tone="success">On</Badge> : <Badge tone="default">Off</Badge>}
        subtitle="Send reviews to Facebook and Instagram Shops"
        backAction={{ content: 'Back', url: '/syndication' }}
        primaryAction={{
          content: product_feed_enabled ? 'Turn off' : 'Turn on',
          destructive: product_feed_enabled,
          primary: !product_feed_enabled,
          loading: saving,
          disabled: planFeatureSets?.data?.pf_google_shopping === false,
          onAction: () => toggleMetaReviewSettings(product_feed_enabled)
        }}
        secondaryActions={[{
          content: 'Contact Meta support',
          external: true,
          url: 'https://www.facebook.com/business/help/support'
        }]}
      >
        <UpgradeTrigger
          feature={'pf_google_shopping'}
          title={'Upgrade to use Meta syndication'}
          description={'You need to upgrade your plan to use Meta syndication'}
        >
          <BlockStack gap="300">
            {product_feed_enabled && product_feed_generated_at && (
              <>
                <Banner
                  tone="success"
                  title="Successfully sending reviews to Meta."
                >
                  <p>
                    Last update sent {new Date(product_feed_generated_at).toLocaleString('en-US', { timeZone: 'UTC' })}
                  </p>
                </Banner>
                <Divider />
              </>
            )}
            <Card>
              <List>
                <List.Item>
                  Junip is obligated to send all reviews to Meta, including rejected reviews.
                </List.Item>
                <List.Item>
                  Your <Link url="/products/catalog-health">product catalog</Link> in Junip and Meta must use the same product identifiers.
                </List.Item>
                <List.Item>
                  For best results, ensure each product variant in your catalog has either a GTIN or Brand + MPN pairing that matches the GTIN or Brand + MPN pairing in your product feed.
                  To learn more about these product identifiers, <Link url="https://help.junip.co/en/articles/8370160-product-identifiers-upis">visit this help doc</Link>.
                </List.Item>
                <List.Item>
                  Your Shop URL in Junip must be the same as the URL connected to your Meta account.
                </List.Item>
              </List>
            </Card>
            <Card>
              <TextField
                label="Shop URL"
                readOnly
                value={store_path}
              />
            </Card>
          </BlockStack>
        </UpgradeTrigger>
      </Page>
    </LoadingPageWrapper>
  )
}
