import React, { useEffect, useState } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { fetchBillingSubscriptions } from 'redux/billingSubscriptions';

import { Button, TextContainer, Text } from "@shopify/polaris";
import LoadingContentWrapper from './LoadingContentWrapper';
import SolicitationCampaignModal from 'components/SolicitationCampaignModal';
import BillingPrompt from 'components/BillingPrompt';

function CampaignTrigger({ billingSubscriptions, fetchBillingSubscriptions }) {
  const [billingModalActive, setBillingModalActive] = useState(false);
  const [showSolicitationCampaignModal, setShowSolicitationCampaignModal] = useState(false);

  const createCampaignAction = () => {
    trackEvent('Campaign Trigger - Create campaign');
    if (!billingSubscriptions.data?.length || billingSubscriptions.data?.[0].usage_limit_reached === true) {
      setBillingModalActive(true);
      trackEvent('Campaign Trigger - Billing prompt');
    } else {
      setShowSolicitationCampaignModal(true);
    }
  };

  useEffect(() => {
    if (!billingSubscriptions.data) {
      fetchBillingSubscriptions();
    }
  }, [fetchBillingSubscriptions, billingSubscriptions.data]);

  return (
    <LoadingContentWrapper loading={billingSubscriptions.loading}>
      <TextContainer>
        <p>
          <Text variant="bodyMd" as="span" fontWeight="semibold">When a campaign is launched</Text>
        </p>
        <Button size="slim" onClick={createCampaignAction}>Create Campaign</Button>
      </TextContainer>
      <SolicitationCampaignModal
        showSolicitationCampaignModal={showSolicitationCampaignModal}
        setShowSolicitationCampaignModal={setShowSolicitationCampaignModal}
      />
      <BillingPrompt
        active={billingModalActive}
        setActive={setBillingModalActive}
      />
    </LoadingContentWrapper>
  );
}
const mapStateToProps = (state) => ({
  billingSubscriptions: state.billingSubscriptions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBillingSubscriptions: () => dispatch(fetchBillingSubscriptions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignTrigger);
