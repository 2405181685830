import React from 'react';
import {
  Layout,
  LegacyCard,
  TextContainer,
  Link,
  Text,
  InlineCode
} from "@shopify/polaris";

export default function BillingFaq() {
  const openHelpDocs = () => {
    window.open('https://help.junip.co', '_blank');
  };

  return (
    <Layout.Section variant="fullWidth">
      <LegacyCard title="General questions">
        <LegacyCard.Section>
          <TextContainer spacing="loose">
            <div>
              <div className="mb-2">
                <Text variant="headingMd" as="h2">What currency are the plan prices in?</Text>
              </div>
              <p>The prices shown above are in $USD.</p>
            </div>
            <div>
              <div className="mb-2">
                <Text variant="headingMd" as="h2">Can I change my plan later on?</Text>
              </div>
              <p>Of course! You can upgrade or downgrade your plan at any time.</p>
            </div>
            <div>
              <div className="mb-2">
                <Text variant="headingMd" as="h2">Can I choose to be billed annually instead of monthly?</Text>
              </div>
              <p className="mb-4">Currently, Junip only offers monthly billing. This is because our price tiers are organized based on your monthly order volume (plus included features).</p>
              <p>This means you can select a plan that meets your current needs and easily upgrade as needed as your business grows. </p>
            </div>
            <div>
              <div className="mb-2">
                <Text variant="headingMd" as="h2">Can I move existing reviews to Junip?</Text>
              </div>
              <p>
                Yup! Simply visit the <Link url="/reviews">Manage reviews</Link> section and hit import reviews at the top.
                You need a <Text variant="bodyMd" as="span"><InlineCode>.csv</InlineCode></Text> file of your existing reviews.
              </p>
            </div>
          </TextContainer>
        </LegacyCard.Section>
        <LegacyCard.Section subdued>
          <p>For more FAQs and information check out our <Link external onClick={openHelpDocs}>Help docs</Link>.</p>
        </LegacyCard.Section>
      </LegacyCard>
    </Layout.Section>
  );
}
