import React, { useState, useEffect, useCallback } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import axios from 'axios';
import useInterval from 'utils/useInterval';
import { Banner, ProgressBar, List, Text, BlockStack } from "@shopify/polaris";

const DEFAULT_POLLING_DELAY = 3000; // 3 sec
const TIME_RANGE = 1000 * 60 * 5; // 5 min

const PENDING_STATES = ['pending', 'processing'];

export default function StoreSnippetActionBanner({ actionStarted, actionCallback }) {
  const [action, setAction] = useState(null);
  const [delay, setDelay] = useState(null);

  const fetchData = useCallback(async (id) => {
    let url = '/api/v1/store_snippet_actions';
    if (id) {
      url = `${url}/${id}`;
    }
    const response = await axios.get(url, {
      params: {
        include: 'progress_tracker,items'
      }
    });
    if (id) {
      setAction(response?.data?.store_snippet_action);
    } else {
      const firstPendingAction = response?.data?.store_snippet_actions?.[0];
      if (Date.now() - Date.parse(firstPendingAction?.created_at) < TIME_RANGE) {
        setAction(firstPendingAction);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      setDelay(null);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (actionStarted) {
      fetchData();

      if (actionCallback) {
        actionCallback();
      }
    }
  }, [actionStarted, actionCallback, fetchData]);

  useEffect(() => {
    if (PENDING_STATES.includes(action?.state)) {
      setDelay(DEFAULT_POLLING_DELAY);
    } else {
      setDelay(null);
    }
  }, [action]);

  useInterval(() => {
    if (action) {
      fetchData(action.id);
    }
  }, delay);

  const contactSupport = () => {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      window.open('mailto:support@juniphq.com', '_blank');
    }

    trackEvent('Store snippet banner - Contact support ')
  };

  const errorItems = action?.items?.filter(i => i.state === 'failed');

  let errorText = '';

  if (action?.failure_reason?.includes('disconnected')) {
    errorText = 'It looks like your store is disconnected from Junip, click the button at the bottom of this page to reconnect Junip and try again or contact support';
  } else if (action?.failure_reason?.includes('Cannot install snippets on theme trials')) {
    errorText = 'It looks like you tried to add Junip snippets to a trial theme, Junip snippets cannot be installed on theme trials.';
  } else {
    let detail = ''

    if (action?.action === 'setup') {
      detail = 'add the Junip snippets to your site';
    }

    if (action?.action === 'teardown') {
      detail = 'remove the Junip snippets from your site';
    }

    if (action?.action === 'manual_setup') {
      detail = 'mark Junip as manually installed';
    }

    errorText = `We were unable to ${detail}, please try again or contact support`
  }

  const processing = action?.state === 'processing';
  const pending = action?.state === 'pending';
  const completed = action?.state === 'completed';
  const failed = action?.state === 'failed';

  return <>
    { (pending || processing) &&
      <Banner tone="info">
        <BlockStack gap="100">
          <Text as="div">
            {action?.progress_tracker?.current_step_description ?
              action?.progress_tracker.current_step_description
            :
              'Working...'
            }
          </Text>
          {processing &&
            <div style={{ width: '300px' }}>
              <ProgressBar
                size="small"
                progress={
                  ((action?.progress_tracker?.steps_completed / action?.progress_tracker?.total_steps) * 100) || 0
                }
              />
            </div>
          }
        </BlockStack>
      </Banner>
    }
    { completed && (errorItems?.length || (Date.now() - Date.parse(action?.updated_at) < TIME_RANGE)) &&
      <>
        { errorItems?.length ?
          <Banner
            tone="info"
          >
            <BlockStack gap="100">
              <p>The Junip snippets were partially added to your site:</p>
              <List gap="extraTight">
                {errorItems.map(e =>
                  <List.Item key={e.id}>
                    <Text variant="bodySm" as="span">{e.name}</Text>{' '}-{' '}
                    <Text variant="bodySm" as="span">{e.description}</Text>
                  </List.Item>
                )}
              </List>
            </BlockStack>
          </Banner>
        :
          <Banner
            tone={(Date.now() - Date.parse(action?.updated_at) < TIME_RANGE) ? 'success' : 'default'}
          >
            <p>
              We've successfully {action?.action === 'setup' ? 'added the Junip snippets to your site' : 'removed the Junip snippets from your site'}
            </p>
          </Banner>
        }
      </>
    }
    { failed &&
      <Banner
        tone="critical"
        action={{ content: 'Contact support', onAction: contactSupport}}
      >
        <p>
          {errorText}
        </p>
      </Banner>
    }
  </>;
}
