import React from 'react';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import {
  Page,
  Layout,
  ResourceList,
  LegacyCard,
  Text
} from '@shopify/polaris';
import styles from 'styles/routes/Reports.module.scss';

export default function Reports() {
  const reportsTableHeader = (
    <div className={styles.reportsTableHeader}>
      <div className="reports-table-header-title">
        <Text fontWeight='semibold'>Report</Text>
      </div>
      <div className="reports-table-header-last-viewed">
        <Text fontWeight='semibold'>Last viewed</Text>
      </div>
    </div>
  )

  const reports = [
    { title: 'Ratings collected over time' },
    { title: 'Ratings by product' },
    { title: 'Submission rate over time' }
  ]

  const toKebabCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/[\s_]+/g, '-')
      .toLowerCase();
  }

  const getLastViewed = (index) => {
    return localStorage.getItem(`lastViewed${index}`)
  }

  const history = useHistory();

  return (
    <Page title='Reports'>
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <div className="reports-table">
              <div className={styles.reportsTableHeaderWrapper}>
                {reportsTableHeader}
              </div>
              <ResourceList
                resourceName={{ singular: 'report', plural: 'reports' }}
                items={reports}
                hasMoreItems={false}
                renderItem={(item, itemId, itemIndex) => {
                  const { title } = item;

                  const pageSlug = `reports/${toKebabCase(title)}`;

                  return (
                    <ResourceList.Item
                      id={itemId}
                      name={title}
                      onClick={() => {
                        localStorage.setItem(`lastViewed${itemIndex}`, moment().format('MMMM Do YYYY'));
                        setTimeout(() => {
                          history.push(pageSlug);
                        }, 100)
                      }}
                    >
                      <div className={styles.reportItemWrapper}>
                        <div className="report-item-title">
                          <Text variation="strong">{title}</Text>
                        </div>
                        <div className="report-item-last-viewed">
                          {getLastViewed(itemIndex)}
                        </div>
                      </div>
                    </ResourceList.Item>
                  )
                }}
              />
            </div>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
