import React from 'react';
import {
  Modal
} from '@shopify/polaris';

import EmailPreview from 'components/EmailPreview';

export default function CustomHtmlPreviewModal({
  customHtmlTemplate,
  template,
  blocks,
  showModal,
  setShowModal
}) {

  return (
    <Modal
      open={showModal}
      title="Custom HTML email preview"
      onClose={() => setShowModal(false)}
      primaryAction={{
        content: 'Close',
        onAction: () => {setShowModal(false);},
      }}
    >
      <Modal.Section>
        <div style={{ backgroundColor: 'white' }}>
          <EmailPreview template={template} customHtmlTemplate={customHtmlTemplate} blocks={blocks} inputs={{}} customHtmlEnabled={true} />
        </div>
      </Modal.Section>
    </Modal>
  );
}
