import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSyndicationSettings, saveSyndicationSettings } from 'redux/syndicationSettings';
import { fetchStores } from 'redux/stores';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import LoadingWrapper from 'components/LoadingWrapper';
import {
  Page,
  Card,
  BlockStack,
  Badge,
  Text,
  InlineStack,
  Button,
  Modal,
  Divider,
  Banner
} from '@shopify/polaris';
import UpgradeTrigger from 'components/UpgradeTrigger';
import contactSupport from 'utils/contactSupport';

export default function MultiStore() {
  const [saving, setSaving] = useState(false);
  const [showStoresModal, setShowStoresModal] = useState(false);
  const [working, setWorking] = useState(false);

  const dispatch = useDispatch();
  const syndicationSettings = useSelector(state => state.syndicationSettings);
  const stores = useSelector(state => state.stores);
  const planFeatureSets = useSelector(state => state.planFeatureSets);

  useEffect(() => {
    dispatch(fetchSyndicationSettings());
    dispatch(fetchStores());
  }, [dispatch]);

  const toggleSyndicationSettings = async (enabled) => {
    setSaving(true);
    await dispatch(saveSyndicationSettings(syndicationSettings.data.id, { enabled: !enabled }));
    setSaving(false);
  };

  const closeModal = () => {
    setShowStoresModal(false);
  };

  const toggleState = async (id, newState) => {
    setWorking(true);
    try {
      await axios.put(`/api/v1/stores/${id}`, {
        store: {
          should_syndicate: newState
        }
      });
      trackEvent(`Configure stores modal - ${newState ? 'Enable' : 'Disable'} should syndicate`);
      dispatch(fetchStores());
    } catch (e) {
      trackEvent(`Error: Configure stores modal - ${newState ? 'Enable' : 'Disable'} should syndicate`);
    } finally {
      setWorking(false);
    }
  };

  const { pf_syndication } = planFeatureSets.data || {};
  const { batch_running, enabled, network } = syndicationSettings.data || {};

  const globallySyndicating = network === "global";
  const canLocallySyndicate = stores?.data?.length > 1;
  const scopedStore = stores?.data?.find(s => s.scoped === true);
  const syndicateStores = stores.data?.filter(s => s.should_syndicate === true);
  const syndicateStoresCount = syndicateStores?.length || 0;
  const nonSyndicateStores = stores.data?.filter(s => s.should_syndicate === false);

  const renderBanner = () => {
    if (globallySyndicating) {
      return (
        <Banner
          title="Global syndication (beta) enabled"
          tone="success"
          action={{ content: 'Contact support', onAction: contactSupport }}
        >
          <p>Your organization is using Global syndication to share reviews.
            To manage your settings, contact support.</p>
        </Banner>
      );
    }

    if (!canLocallySyndicate) {
      return (
        <Banner
          title="No other stores"
          tone="info"
          action={{ content: 'Connect store', onAction: () => window.location.hash = 'connectStore' }}
        >
          <p>There are no other stores in your organization. To syndicate reviews, connect another store to this account.</p>
        </Banner>
      );
    }

    if (batch_running) {
      return (
        <Banner
          title="Applying changes"
          tone="info"
          action={{ content: 'Reload', onAction: () => dispatch(fetchSyndicationSettings()) }}
        >
          <p>We're applying your syndication setting changes across your stores, this may take a while depending on your volume of reviews.</p>
        </Banner>
      );
    }

    if (enabled) {
      if (!scopedStore?.should_syndicate && syndicateStoresCount > 0) {
        return (
          <Banner
            title="This store is not syndicating reviews"
            tone="warning"
            action={{ content: 'Edit syndicating stores', onAction: () => setShowStoresModal(true) }}
          >
            <p>Join the stores in your organization that are already syndicating reviews with each other.</p>
          </Banner>
        );
      }

      if (syndicateStoresCount === 0) {
        return (
          <Banner
            title="Action required"
            tone="warning"
            action={{ content: 'Edit syndicating stores', onAction: () => setShowStoresModal(true) }}
          >
            <p>You must select at least one store to syndicate reviews.</p>
          </Banner>
        );
      }
    }
  };

  return (
    <LoadingPageWrapper loading={syndicationSettings.loading}>
      <Page
        title="Multi-store syndication"
        subtitle="Share reviews across stores in your organization"
        titleMetadata={enabled ? <Badge tone="success">On</Badge> : <Badge tone="default">Off</Badge>}
        backAction={{ content: 'Back', url: '/syndication' }}
        primaryAction={{
          content: enabled ? 'Turn off' : 'Turn on',
          destructive: enabled,
          primary: !enabled,
          loading: saving,
          disabled: pf_syndication === false || !canLocallySyndicate || globallySyndicating || (!enabled && syndicateStoresCount === 0),
          onAction: () => toggleSyndicationSettings(enabled)
        }}
      >
        <UpgradeTrigger
          feature={'pf_syndication'}
          title="Upgrade to enable multi-store syndication"
          description="You need to upgrade your plan to enable multi-store syndication"
        >
          <BlockStack gap="300">
            {renderBanner()}
            {!globallySyndicating && (
              <Card>
                <BlockStack gap="200">
                  <InlineStack align="space-between" >
                    <Text variant="headingSm">Eligible stores</Text>
                    {syndicateStoresCount > 0 && (
                      <Button variant="plain" onClick={() => setShowStoresModal(true)}>
                        Edit
                      </Button>
                    )}
                  </InlineStack>
                  <Text tone="subdued" variant="bodyMd">
                    Choose which stores in your organization should be eligible to syndicate reviews with each other
                  </Text>
                  {syndicateStoresCount > 0 && (
                    <BlockStack gap="300">
                      <Divider />
                      {syndicateStores.map(s => (
                        <BlockStack gap="100">
                          <InlineStack gap="100">
                            <Text variant="bodyMd" fontWeight="semibold" key={s.id}>{s.name}</Text>
                            {s.scoped && <Badge tone="info">This store</Badge>}
                          </InlineStack>
                          <Text variant="bodyMd" tone="subdued">{s.url}</Text>
                        </BlockStack>
                      ))}
                    </BlockStack>
                  )}
                  {syndicateStoresCount === 0 && (
                    <div>
                      <Button variant="primary" onClick={() => setShowStoresModal(true)}>
                        Select stores
                      </Button>
                    </div>
                  )}
                </BlockStack>
              </Card>
            )}
          </BlockStack>
        </UpgradeTrigger>
      </Page>
      <Modal
        open={showStoresModal}
        title="Configure stores"
        onClose={() => !working && closeModal()}
        secondaryActions={[{
          content: 'Close',
          disabled: working,
          onAction: closeModal,
        }]}
      >
        <Modal.Section>
          <LoadingWrapper sections={1} loading={stores.loading}>
            <BlockStack gap="200">
              <Text variant="headingSm" as="h3">Syndicating stores</Text>
              {syndicateStores?.length ? syndicateStores?.map(store =>
                <StoreToggle key={store.id} store={store} working={working} toggleState={toggleState} />
              ) : <p>No stores found, add a store below</p>}
            </BlockStack>
          </LoadingWrapper>
        </Modal.Section>
        <Modal.Section>
          <LoadingWrapper sections={1} loading={stores.loading}>
            <BlockStack gap="200">
              {nonSyndicateStores?.length ? nonSyndicateStores?.map(store =>
                <StoreToggle key={store.id} store={store} working={working} toggleState={toggleState} />
              ) : <p>No more stores to add</p>}
            </BlockStack>
          </LoadingWrapper>
        </Modal.Section>
      </Modal>
    </LoadingPageWrapper>
  );
}

function StoreToggle({ store, working, toggleState }) {
  const [loading, setLoading] = useState(false);
  const active = store?.should_syndicate;

  const toggle = async () => {
    setLoading(true);
    toggleState(store.id, !active);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', alignItems: 'center' }}>
      <BlockStack gap="200">
        <p>{store.name}</p>
        <Text variant="bodyMd" as="span" tone="subdued">{store.url}</Text>
      </BlockStack>
      <div>
        <Button
          disabled={working}
          loading={loading}
          onClick={toggle}
          variant={active ? 'tertiary' : 'primary'}
          tone={active ? 'critical' : 'success'}
        >
          {active ? 'Remove' : 'Add'}
        </Button>
      </div>
    </div>
  );
}
