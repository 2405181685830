import axios from 'axios';
import { changesSaved, savingChanges } from 'redux/settings';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';

// Actions
const FETCH_MESSAGE_TEMPLATES_BEGIN = 'FETCH_MESSAGE_TEMPLATES_BEGIN';
const FETCH_MESSAGE_TEMPLATES_SUCCESS = 'FETCH_MESSAGE_TEMPLATES_SUCCESS';
const FETCH_MESSAGE_TEMPLATES_ERROR = 'FETCH_MESSAGE_TEMPLATES_ERROR';
const SAVE_MESSAGE_TEMPLATE_SUCCESS = 'SAVE_MESSAGE_TEMPLATE_SUCCESS';
const SAVE_MESSAGE_BLOCKS_SUCCESS = 'SAVE_MESSAGE_BLOCKS_SUCCESS';
const TOGGLE_MESSAGE_TEMPLATE_STATE = 'TOGGLE_MESSAGE_TEMPLATE_STATE';

// Action Creators
export function fetchMessageTemplates() {
  return async dispatch => {
    dispatch(fetchMessageTemplatesBegin());
    try {
      const response = await axios.get('/api/v1/message_templates', {
        params: {
          include: 'message_type,message_blocks'
        }
      });
      dispatch(fetchMessageTemplatesSuccess(response.data.message_templates));
    } catch (e) {
      dispatch(fetchMessageTemplatesError(e));
    }
  }
}

export function saveMessageTemplate(customTemplate, template) {
  return async dispatch => {
    try {
      const response = await axios.put(
        `/api/v1/message_templates/${template.id}?include=message_type,message_blocks`,
        {
          template: customTemplate
        }
      );
      dispatch(saveMessageTemplateSuccess(response.data.message_template, template.id));
      dispatch(changesSaved());
      trackEvent('Message template - Save custom template');
    } catch (e) {
      if (e?.response?.status === 422) {
        dispatch(savingChanges(false));
        dispatch(showToast('Error saving changes, please check your template variables and try again', true));
      } else {
        dispatch(changesSaved(true));
      }
      trackEvent('Error: Message template - Save custom template');
    }
  }
}

export function saveMessageBlocks(templateId, blocks) {
  let data = [];
  for (let [key, value] of Object.entries(blocks)) {
    if (value !== null) {
      data.push({
        id: key,
        template: value || ''
      });
    }
  }
  return async dispatch => {
    if (data?.length === 0) {
      dispatch(changesSaved());
      return;
    }
    try {
      const response = await axios.put(
        `/api/v1/message_blocks`,
        {
          message_blocks: data
        }
      );
      dispatch(saveMessageBlocksSuccess(response.data.message_blocks, templateId));
      dispatch(changesSaved());
      trackEvent('Message template - Update blocks');
    } catch (e) {
      if (e?.response?.status === 422) {
        dispatch(savingChanges(false));
        dispatch(showToast('Error saving changes, please check your template variables and try again', true));
      } else {
        dispatch(changesSaved(true));
      }
      trackEvent('Error: Message template - Update blocks', { statusCode: e?.response?.status });
    }
  }
}

export const fetchMessageTemplatesBegin = () => ({
  type: FETCH_MESSAGE_TEMPLATES_BEGIN
});

export const fetchMessageTemplatesSuccess = data => ({
  type: FETCH_MESSAGE_TEMPLATES_SUCCESS,
  data
});

export const saveMessageTemplateSuccess = (data, id) => ({
  type: SAVE_MESSAGE_TEMPLATE_SUCCESS,
  data,
  id
});

export const saveMessageBlocksSuccess = (data, id) => ({
  type: SAVE_MESSAGE_BLOCKS_SUCCESS,
  data,
  id
});

export const toggleMessageTemplateState = (newState, id) => ({
  type: TOGGLE_MESSAGE_TEMPLATE_STATE,
  newState,
  id
});

export const fetchMessageTemplatesError = error => ({
  type: FETCH_MESSAGE_TEMPLATES_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_MESSAGE_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_MESSAGE_TEMPLATES_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case SAVE_MESSAGE_TEMPLATE_SUCCESS:
      let tempIndex = state.data.findIndex(data => data.id === action.id);
      state.data[tempIndex] = action.data;
      return {
        ...state,
        loading: false,
        error: null
      };
    case SAVE_MESSAGE_BLOCKS_SUCCESS:
      let newStateData = state.data;
      let templateIndex = state.data.findIndex(data => data.id === action.id);
      let newMessageBlocks = state.data[templateIndex].message_blocks;
      for (let block of action.data) {
        let index = newMessageBlocks.findIndex(b => b.id === block.id);
        newMessageBlocks[index] = block;
      }
      newStateData[templateIndex].message_blocks = newMessageBlocks;
      return {
        ...state,
        data: newStateData,
        loading: false,
        error: null
      };
    case TOGGLE_MESSAGE_TEMPLATE_STATE:
      let index = state.data.findIndex(data => data.id === action.id);
      state.data[index].enabled = action.newState;
      return {
        ...state,
        loading: false,
        error: null
      };
    case FETCH_MESSAGE_TEMPLATES_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
