import React from 'react';
import { connect } from 'react-redux';
import { discardChanges, savingChanges } from 'redux/settings';
import ColorSchemeWrapper from 'components/ColorSchemeWrapper';

import {
  ContextualSaveBar
} from '@shopify/polaris';

const SaveBar = (props) => {
  if (!props.settings.isDirty) {
    return null;
  }

  const handleSave = () => {
    props.savingChanges();
  };

  const handleDiscard = () => {
    props.discardChanges();
  };

  return (
    <ColorSchemeWrapper colorScheme="light">
      <ContextualSaveBar
        message="Unsaved changes"
        saveAction={{
          onAction: handleSave,
          loading: props.settings.saving
        }}
        discardAction={{
          onAction: handleDiscard,
          disabled: props.settings.saving
        }}
      />
    </ColorSchemeWrapper>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings
});

const mapDispatchToProps = (dispatch) => ({
  discardChanges: () => dispatch(discardChanges()),
  savingChanges: () => dispatch(savingChanges())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveBar);
