import React, { useEffect } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { Button, Page, LegacyCard, LegacyStack, ButtonGroup, Text } from "@shopify/polaris";
import JunipLogo from 'components/JunipLogo';

import { ExternalIcon } from "@shopify/polaris-icons";
import thanks from 'assets/images/onboarding/thanks.jpg';

export default function Thanks() {
  useEffect(() => {
    try {
      localStorage.removeItem('backToOnboarding');
    } catch (e) {
      // No-op
    }
  }, []);

  return (
    <Page>
      <LegacyCard flush>
        <div className="onboarding-step">
          <div className="o-step o-step-intro o-divider-right o-step-subdued">
            <LegacyStack vertical spacing="extraLoose">
              <JunipLogo height={'32px'} />
              <Text variant="heading3xl" as="p">Thanks for setting up Junip!</Text>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">You’re all set! 🎉</Text>
              </p>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">You can find more resources in our help center, and on the admin home page.</Text>
              </p>
              <ButtonGroup>
                <Button
                  url="https://help.junip.co"
                  external
                  icon={ExternalIcon}
                  onClick={() => trackEvent('Onboarding thanks - Visit Help Center')}
                >
                  Visit Help Center
                </Button>
                <Button

                  url="/home"
                  onClick={() => trackEvent('Onboarding thanks - Take me home')}
                  variant="primary">
                  Take me Home
                </Button>
              </ButtonGroup>
            </LegacyStack>
          </div>
          <div>
            <div className="h-100 d-flex align-items-center justify-content-center">
              <img src={thanks} alt="Junip stars" className="w-100" />
            </div>
          </div>
        </div>
      </LegacyCard>
    </Page>
  );
}
