import React, { useState, useEffect } from 'react';
import useLastLocation from 'utils/useLastLocation';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { fetchStripeRecords } from 'redux/stripeRecords';
import { fetchDisplaySettings } from 'redux/displaySettings';
import { fetchBillingPlans } from 'redux/billingPlans';
import {
  fetchBillingSubscriptions,
  updateBillingSubscription,
} from 'redux/billingSubscriptions';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import { showToast } from 'redux/toast';
import {
  Page,
  Layout,
  Banner,
  Link
} from '@shopify/polaris';

import BillingBanner from 'components/BillingBanner';
import ActivePlan from 'components/ActivePlan';
import BillingPlans from 'components/BillingPlans';
import BillingPlansTable from 'components/BillingPlansTable';
import BillingFaq from 'components/BillingFaq';
import BillingCancelModal from 'components/BillingCancelModal';
import LoadingContentWrapper from 'components/LoadingContentWrapper';
import StripeModal from 'components/StripeModal';
import OfferCard from 'components/OfferCard';

import { SmileyHappyIcon } from "@shopify/polaris-icons";

function BillingSettings(props) {
  const lastLocation = useLastLocation();
  const {
    stripeRecords,
    fetchStripeRecords,
    fetchPlanFeatureSets,
    fetchDisplaySettings,
    billingPlans,
    fetchBillingPlans,
    billingSubscriptions,
    fetchBillingSubscriptions,
    updateBillingSubscription,
    showToast
  } = props;

  const replace = props.history.replace;

  const billingSubscriptionId = props.match.params.id;
  const params = new URLSearchParams(window.location.search);
  const activateSubscription = params.get('activate');

  const [orgLoading, setOrgLoading] = useState(true);
  const [organization, setOrganization] = useState({});
  const [stripeModalData, setStripeModalData] = useState(null);
  const [activeBillingPlan, setActiveBillingPlan] = useState({});
  const [planActive, setPlanActive] = useState(false);
  const [activating, setActivating] = useState(false);
  const [chooseLoading, setChooseLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [activeBillingSubscription, setActiveBillingSubscription] = useState({});
  const [explicitlyHideBanner, setExplicitlyHideBanner] = useState(false);

  useEffect(() => {
    async function fetchOrganization() {
      try {
        setOrgLoading(true);
        const response = await axios.get('/api/v1/organizations', {
          params: {
            include: 'referral_record',
            'filter[scoped_only]': true
          }
        });

        const firstOrg = response.data?.organizations?.[0];

        if (!firstOrg) {
          replace('/');
          trackEvent('Error: Billing settings - No scoped organization');
          return;
        }

        setOrganization(firstOrg);
        setOrgLoading(false);
      } catch (e) {
        setOrgLoading(false);
        trackEvent('Error: Billing settings - Fetch scoped organization', { statusCode: e?.response?.status });
        replace('/');
      }
    }

    fetchOrganization();
  }, [replace]);

  useEffect(() => {
    if (organization.payment_provider === 'stripe' && !stripeRecords.data) {
      fetchStripeRecords();
    }
  }, [organization, fetchStripeRecords, stripeRecords.data]);

  useEffect(() => {
    fetchBillingPlans({});
  }, [fetchBillingPlans]);

  useEffect(() => {
    fetchBillingSubscriptions();
  }, [fetchBillingSubscriptions]);

  useEffect(() => {
    if (billingSubscriptions.data) {
      const activeBillingSub = billingSubscriptions.data.find(b => b.active === true && (b.provider !== 'junip' || b.auto_renew));
      if (activeBillingSub) {
        setActiveBillingSubscription(activeBillingSub);
        const activePlan = activeBillingSub.billing_plan;
        if (activePlan) {
          setActiveBillingPlan(activePlan);
          setPlanActive(true);
        }
      } else {
        setActiveBillingSubscription({});
      }
    }
  }, [billingSubscriptions]);

  useEffect(() => {
    async function activate(id) {
      setActivating(true);
      let pushToOnboarding = null;
      try {
        pushToOnboarding = JSON.parse(window?.localStorage?.getItem('backToOnboarding'));
      } catch (e) {
        pushToOnboarding = false;
      }
      try {
        await axios.put(`/api/v1/billing_subscriptions/${id}/activate`);
        fetchBillingSubscriptions();
        showToast('Subscription activated, enjoy using Junip!');
        setActivating(false);
        fetchPlanFeatureSets();
        if (pushToOnboarding) {
          trackEvent('Onboarding billing - Activate plan');
          props.history.replace('/onboarding/thanks');
        } else {
          props.history.replace(props.history.location.pathname);
          trackEvent('Billing settings - Activate plan');
        }
      } catch (e) {
        setActivating(false);
        trackEvent('Error: Billing settings - Activate plan', { statusCode: e?.response?.status });
      }
    }
    if (activateSubscription && billingSubscriptionId) {
      activate(billingSubscriptionId);
    }
  }, [
    fetchPlanFeatureSets,
    fetchBillingSubscriptions,
    billingSubscriptionId,
    activateSubscription,
    showToast,
    props.history
  ]);

  const choosePlan = async (plan) => {
    setChooseLoading(true);

    // Clear if choosing plan from billing section
    try {
      localStorage.removeItem('backToOnboarding');
    } catch (e) {
      // No-op
    }

    if (organization.billable === false || plan.free === true) {
      await createBillingSubscription(plan.id, 'junip');
    } else if (organization.payment_provider === 'shopify') {
      await createBillingSubscription(plan.id);
    } else if (organization.payment_provider === 'stripe') {
      setStripeModalData({
        plan,
        action: 'select'
      });
    }
  };

  const createBillingSubscription = async (planId, provider) => {
    try {
      const billingSubscriptionResponse = await axios.post('/api/v1/billing_subscriptions', {
        billing_subscription: {
          billing_plan_id: planId,
          provider: provider || organization.payment_provider
        }
      });
      const newBillingSubscription = billingSubscriptionResponse.data.billing_subscription;

      if (!activeBillingSubscription?.id) {
        setExplicitlyHideBanner(true);
      }

      if (newBillingSubscription.authorization_url) {
        window.location.href = newBillingSubscription.authorization_url;
      } else {
        fetchBillingSubscriptions();
        setPlanActive(true);
        setChooseLoading(false);
        setExplicitlyHideBanner(false);
        showToast('Plan selected');
        fetchDisplaySettings();
        fetchPlanFeatureSets();
      }
      trackEvent('Billing settings - Select plan');
    } catch (e) {
      if (e?.response?.status === 400 && organization?.payment_provider === 'shopify') {
        showToast('Cannot change your subscription while your Shopify account is paused', true);
      } else {
        showToast('Something went wrong while selecting this plan, please check your payment method and try again', true);
      }
      setChooseLoading(false);
      trackEvent('Error: Billing settings - Select plan', { statusCode: e?.response?.status });
    }
  };

  const cancelBillingSubscription = async () => {
    let subscriptionId = activeBillingSubscription.id;
    setCancelLoading(true);
    try {
      const response = await axios.put(`/api/v1/billing_subscriptions/${subscriptionId}/cancel`);
      updateBillingSubscription(response.data.billing_subscription);
      setPlanActive(false);
      setCancelLoading(false);
      showToast('Subscription cancelled');
      fetchBillingPlans({});
      fetchBillingSubscriptions();
      fetchDisplaySettings();
      fetchPlanFeatureSets();
      trackEvent('Billing settings - Cancel plan');
    } catch (e) {
      if (e?.response?.status === 400 && organization?.payment_provider === 'shopify') {
        showToast('We cannot cancel your subscription while your Shopify account is paused, but your billing on Junip is automatically paused while Shopify is paused.', true);
      } else {
        showToast('Something went wrong while canceling this subscription, please try again', true);
      }
      setCancelLoading(false);
      trackEvent('Error: Billing settings - Cancel plan', { statusCode: e?.response?.status });
    }
  };

  const hasOffer = () => {
    if (organization?.payment_provider === 'stripe' && !organization.referrer_discount_used && organization.referral_record) {
      return true;
    }

    return false;
  };

  return <>
    <Page
      title="Billing settings"
      backAction={{ content: 'Settings', url: lastLocation || '/settings' }}
    >
      <LoadingContentWrapper
        loading={
          activating ||
          billingPlans.loading ||
          billingSubscriptions.loading ||
          orgLoading
        }
        cards={3}
      >
        {!explicitlyHideBanner &&
          <BillingBanner skipFetch={true} />
        }
        {!organization?.billable &&
          <div className="mb-4 w-100">
            <Banner tone="success" icon={SmileyHappyIcon} title="You're eligible for a free upgrade">
              Junip Partners and development stores can upgrade to any paid plan for free. Learn how to{' '}
              <Link external monochrome url="https://junip.co/partners">become a Junip partner</Link>
            </Banner>
          </div>
        }
        <Layout>
          {hasOffer() &&
            <OfferCard offer={organization?.referral_record} />
          }
          <ActivePlan
            planActive={planActive}
            activeBillingPlan={activeBillingPlan}
            activeBillingSubscription={activeBillingSubscription}
            setShowCancelModal={setShowCancelModal}
            setStripeModalData={setStripeModalData}
            cancelLoading={cancelLoading}
            chooseLoading={chooseLoading}
            organization={organization}
            stripeRecord={stripeRecords.data?.[0]}
            twoPointOh={organization.two_point_oh}
          />
          <BillingPlans
            planActive={planActive}
            activeBillingPlan={activeBillingPlan}
            activeBillingSubscription={activeBillingSubscription}
            cancelLoading={cancelLoading}
            chooseLoading={chooseLoading}
            choosePlan={choosePlan}
            billingPlans={billingPlans.data?.filter(b => !b.free)}
            organization={organization}
            twoPointOh={organization.two_point_oh}
          />
          <BillingPlans
            planActive={planActive}
            activeBillingPlan={activeBillingPlan}
            activeBillingSubscription={activeBillingSubscription}
            cancelLoading={cancelLoading}
            chooseLoading={chooseLoading}
            choosePlan={choosePlan}
            billingPlans={billingPlans.data?.filter(b => b.free)}
            organization={organization}
            twoPointOh={organization.two_point_oh}
          />
          {!organization.two_point_oh && <Layout.Section variant="fullWidth">
            <BillingPlansTable billingPlans={billingPlans.data} organization={organization} />
          </Layout.Section>}
          <BillingFaq billingPlans={billingPlans.data} />
        </Layout>
        <BillingCancelModal
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          cancelBillingSubscription={cancelBillingSubscription}
        />
      </LoadingContentWrapper>
    </Page>
    <StripeModal
      showToast={showToast}
      modalData={stripeModalData}
      setModalData={setStripeModalData}
      stripeRecord={stripeRecords.data?.[0]}
      fetchStripeRecords={fetchStripeRecords}
      createBillingSubscription={createBillingSubscription}
      setChooseLoading={setChooseLoading}
    />
  </>;
}

const mapStateToProps = (state) => ({
  stripeRecords: state.stripeRecords,
  billingPlans: state.billingPlans,
  billingSubscriptions: state.billingSubscriptions
});

const mapDispatchToProps = (dispatch) => ({
  fetchStripeRecords: () => dispatch(fetchStripeRecords()),
  fetchPlanFeatureSets: () => dispatch(fetchPlanFeatureSets()),
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  fetchBillingPlans: () => dispatch(fetchBillingPlans({})),
  fetchBillingSubscriptions: () => dispatch(fetchBillingSubscriptions()),
  updateBillingSubscription: (newSubscription) => dispatch(updateBillingSubscription(newSubscription)),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingSettings);
