import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchApps } from 'redux/apps';
import { fetchAppInstalls } from 'redux/appInstalls';
import {
  Page,
  LegacyCard,
  EmptyState,
  FooterHelp,
  Link,
  Text,
} from "@shopify/polaris";
import LoadingContentWrapper from 'components/LoadingContentWrapper';
import UpgradeTrigger from 'components/UpgradeTrigger';
import IntegrationList from 'components/IntegrationList';

import appsPlaceholder from 'assets/images/apps/appsPlaceholder.svg';

function Integrations({ apps, appInstalls, fetchApps, fetchAppInstalls }) {
  const [appListing, setAppListing] = useState(null);
  const [installedListing, setInstalledListing] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchApps({
      'filter[visibility]': 'public',
      include: 'event_subscriptions,event_destination'
    });
  }, [fetchApps]);

  useEffect(() => {
    fetchAppInstalls();
  }, [fetchAppInstalls]);

  useEffect(() => {
    if (appInstalls.data && apps.data) {
      let listing = [];
      let installed = [];
      apps.data.forEach(app => {
        const appInstall = appInstalls.data.find(a => a.app_id === app.id);
        if (appInstall) {
          app.app_install = appInstall;
          installed.push(app);
        } else {
          listing.push(app);
        }
      });
      setAppListing(listing);
      setInstalledListing(installed);
      setLoading(false);
    }
  }, [appInstalls.data, apps.data])

  return (
    <Page
      title="Integrations"
    >
      <LoadingContentWrapper loading={loading || apps?.loading || appInstalls?.loading }>
        <LegacyCard title="What are integrations?" sectioned>
          <p>Integrations help extend the functionality of Junip. Integrate with the various other tools you're already using and love, because we love them too.</p>
        </LegacyCard>
        { installedListing?.length > 0 &&
          <LegacyCard>
            <LegacyCard.Section>
              <Text variant="headingMd" as="h2">Installed</Text>
            </LegacyCard.Section>
            <IntegrationList apps={installedListing} />
          </LegacyCard>
        }
        <UpgradeTrigger
          feature={'pf_integrations'}
          title={'Upgrade to use integrations'}
          description={'You need to upgrade your plan to use integrations'}
        >
          <LegacyCard>
            <LegacyCard.Section>
              <Text variant="headingMd" as="h2">All apps</Text>
            </LegacyCard.Section>
            { appListing?.length > 0 ?
              <IntegrationList apps={appListing} />
            :
              <EmptyState
                heading="More integrations coming soon..."
                image={appsPlaceholder}
              >
                <p>
                  We are working hard to bring you more integrations to Junip, stay tuned!
                </p>
              </EmptyState>
            }
          </LegacyCard>
          <LegacyCard title="App development" sectioned secondaryFooterActions={[{
              content: 'Develop apps',
              url: '/integrations/custom',
            }]}
            footerActionAlignment="left"
          >
            <p>
              Send webhooks from Junip to a custom application.
            </p>
          </LegacyCard>
        </UpgradeTrigger>
      </LoadingContentWrapper>
      <FooterHelp>
        Looking for Google Shopping? Head to{' '}
        <Link url="/settings/syndication">
          Syndication settings
        </Link>
        .
      </FooterHelp>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  apps: state.apps,
  appInstalls: state.appInstalls,
});

const mapDispatchToProps = (dispatch) => ({
  fetchApps: (params) => dispatch(fetchApps(params)),
  fetchAppInstalls: () => dispatch(fetchAppInstalls()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Integrations);
