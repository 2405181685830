import React, { useState } from 'react';
import { Modal, TextContainer } from '@shopify/polaris';

export default function ToggleUnreviewableModal({ showModal, setShowModal, product, toggleUnreviewable }) {
  const [loading, setLoading] = useState(false);

  const toggleState = async () => {
    setLoading(true);
    await toggleUnreviewable(!product.unreviewable);
    setShowModal(false);
    setLoading(false);
  };

  return (
    <Modal
      open={showModal}
      title={`${product.unreviewable ? 'Enable reviews' : 'Disable reviews'} on ${product.title}?`}
      onClose={() => !loading && setShowModal(false)}
      primaryAction={{
        content: product.unreviewable ? 'Enable reviews' : 'Disable reviews',
        destructive: !product.unreviewable,
        loading: loading,
        onAction: toggleState
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: loading,
          onAction: () => { setShowModal(false); },
        }
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Are you sure you want to {`${product.unreviewable ? 'enable reviews' : 'disable reviews'} on ${product.title}`}?</p>
          { !product.unreviewable &&
            <p>Disabling reviews will hide all review content for this product and customers will no longer be prompted to leave reviews for this product.</p>
          }
          { product.unreviewable &&
            <p>Enabling reivews will show any review content for this product on the product page and customers that purchase this product will be prompted to leave a review for it.</p>
          }
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}
