import React from 'react';
import amex from 'assets/images/paymentIcons/amex.svg';
import unknown from 'assets/images/paymentIcons/default.svg';
import diners from 'assets/images/paymentIcons/diners.svg';
import discover from 'assets/images/paymentIcons/discover.svg';
import jcb from 'assets/images/paymentIcons/jcb.svg';
import mastercard from 'assets/images/paymentIcons/mastercard.svg';
import unionpay from 'assets/images/paymentIcons/unionpay.svg';
import visa from 'assets/images/paymentIcons/visa.svg';

import 'styles/components/PaymentIcon.scss';

export default function PaymentIcon({ paymentType }) {
  const renderSource = () => {
    switch(paymentType) {
      case 'amex':
        return amex;
      case 'diners':
        return diners;
      case 'discover':
        return discover;
      case 'jcb':
        return jcb;
      case 'mastercard':
        return mastercard;
      case 'unionpay':
        return unionpay;
      case 'visa':
        return visa;
      default:
        return unknown;
    };
  };

  return (
    <div className="payment-icon-container d-flex">
      <img
        className="payment-icon"
        src={renderSource()}
        alt={`${paymentType} logo`}
      />
    </div>
  );
}
