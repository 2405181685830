import React from 'react';
import { ResourceList } from '@shopify/polaris';
import IntegrationListItem from 'components/IntegrationListItem';

export default function IntegrationList({apps}) {
  return (
    <ResourceList
      items={apps}
      renderItem={(app, _, index) => {
        return (
          <IntegrationListItem app={app} key={app.id} />
        );
      }}
    />
  );
}
