import Papa from 'papaparse';
import {
  Banner,
  BlockStack,
  Box,
  Card,
  DropZone,
  Link,
  List,
  Page,
  Text
} from '@shopify/polaris';
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from 'redux/toast';
import axios from 'axios';

const FILE_SIZE_LIMIT = 100_000_000; // 100MB

const providers = [
  { label: 'Stamped', value: 'stamped' },
  { label: 'Yotpo', value: 'yotpo' },
  { label: 'Nexus', value: 'generic' }
];

export default function Import() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [csvData, setCsvData] = useState({});
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [title, setTitle] = useState('');
  const [titleTone, setTitleTone] = useState('');
  const [provider, setProvider] = useState(location.state.provider[0])
  const [questions, setQuestions] = useState([]);
  const [rowBasedQuestions, setRowBasedQuestions] = useState(false);
  const hasRejectedFile = rejectedFiles.length > 0;

  const getSchema = useCallback(async () => {
    try {
      const response = await axios.get('/api/v1/review_import_previews/schema', {
        params: {
          provider: provider.value
        }
      });
      return response.data.schema;
    } catch (error) {
      dispatch(showToast('An error occurred while processing your CSV. Please try again.', true));
    }
  }, [provider, dispatch]);

  const validateCSV = useCallback(async (headers, dataRows) => {
    const errors = {
      duplicateHeaders: [],
      extraHeaders: [],
      missingHeaders: [],
    };
    const schema = await getSchema();

    // Validate headers
    const requiredHeaders = new Set(schema.required);
    const schemaHeaders = new Set([...schema.required, ...Object.keys(schema.properties)]);
    const seenHeaders = new Set();

    // Extract regular expressions for headers that have 'x-survey-question' equal to 'column' 
    const extraHeaderRegexes = Object.keys(schema.patternProperties)
      .filter(key => {
        const surveyQuestion = schema.patternProperties[key]['x-survey-question'];
        if (surveyQuestion === 'column') {
          return true;
        } else if (surveyQuestion === 'row') {
          setRowBasedQuestions(true);
          return false;
        }
        return false;
      })
      .map(key => new RegExp(key));

    // Iterate over the headers to perform all checks
    headers.forEach(header => {
      // Check for duplicates
      if (seenHeaders.has(header)) {
        errors.duplicateHeaders.push(header);
      } else {
        seenHeaders.add(header);
      }

      // Check for required headers
      requiredHeaders.delete(header);

      // Check if the header is in the schema or matches any of the regexes
      const isSchemaHeader = schemaHeaders.has(header);
      const isExtraHeader = extraHeaderRegexes.some(regex => regex.test(header));

      if (!isSchemaHeader && !isExtraHeader) {
        errors.extraHeaders.push(header);
      }
    });

    // Any remaining headers in requiredHeaders are missing
    errors.missingHeaders = Array.from(requiredHeaders);

    // Iterate over the headers and find matches, directly returning the names
    const names = headers.flatMap(header => {
      const match = extraHeaderRegexes.find(regex => regex.test(header));
      return match ? match.exec(header)[1] : null;
    }).filter(header => header !== null);

    // Fetch questions based on the extracted names
    const questions = await getQuestions(names);
    setQuestions(questions);

    // Create an array of objects using the column names as keys
    const data = dataRows.map(row => {
      const obj = {};
      headers.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    setCsvData({
      data,
      errors
    });
  }, [getSchema]);

  const handleAcceptedDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file.size > FILE_SIZE_LIMIT) {
      setRejectedFiles([file]);
    } else {
      setFiles([file])
      setRejectedFiles([]);
    }
  }, []);

  const handleRejectedDrop = useCallback((rejectedFiles) => {
    setRejectedFiles([rejectedFiles[0]]);
    },
    []
  );

  useEffect(() => {
    if (!provider) {
      history.push('/reviews');
    } else {
      const providerObject = providers.find(p => p.value === provider)
      if (providerObject) setProvider(providerObject)
    }
  }, [history, provider]);

  useEffect(() => {
    if (files.length === 0) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const csv = e.target.result;
      const results = Papa.parse(csv, { header: false, dynamicTyping: false, skipEmptyLines: true, preview: 100 });
      // PapaParse automatically renames duplicate headers, so we need to check ourselves
      let headers = results.data[0].map(header => header.trim().toLowerCase());
      validateCSV(headers, results.data.slice(1));
    };

    reader.readAsText(files[0]);
  }, [files, validateCSV]);

  useEffect(() => {
    if (!csvData.data) return;

    let errors = false;
    for (let key in csvData.errors) {
      if (csvData.errors[key].length > 0) {
        errors = true;
        break;
      }
    }

    if (!errors) {
      history.push('/imports/confirm', { csvData, provider: provider.value, files, questions });
    }
  }, [csvData, files, history, provider.value, questions]);

  useEffect(() => {
    if (csvData.errors) {
      setTitle(`There were errors encountered in your file. Fix these errors, then try uploading the file again.`);
      setTitleTone("critical");
    }
  }, [csvData]);


  const getQuestions = async (names) => {
    try {
      const response = await axios.get(`/api/v1/survey_questions`, {
        params: {
          names
        }
      });
      return response.data.survey_questions;
    } catch (error) {
      dispatch(showToast('An error occurred while processing your CSV. Please try again.', true));
    }
  };

  const uploadedFiles = files.length > 0 && (
    <div>{files[0].name}</div>
  );

  const fileUpload = files.length === 0 && <DropZone.FileUpload actionTitle='Add CSV file' />;

  const errorList = (csvData.errors && Object.keys(csvData.errors)) && (
    <div>
      {csvData.errors.missingHeaders.length > 0 && (
        <List type="bullet">
          <List.Item>
            <Text as="h6" variant="headingSm">
              Required columns are missing
            </Text>
            <List type="bullet">
              {csvData.errors.missingHeaders.map((header, index) => (
                <List.Item key={index}>
                  {header}
                </List.Item>
              ))}
            </List>
          </List.Item>
        </List>
      )}
      {csvData.errors.duplicateHeaders.length > 0 && (
        <List type="bullet">
          <List.Item>
            <Text as="h6" variant="headingSm">
              Column header cannot be repeated
            </Text>
            <List type="bullet">
              {csvData.errors.duplicateHeaders.map((header, index) => (
                <List.Item key={index}>
                  {header}
                </List.Item>
              ))}
            </List>
          </List.Item>
        </List>
      )}
      {csvData.errors.extraHeaders.length > 0 && (
        <List type="bullet">
          <List.Item>
            <Text as="h6" variant="headingSm">
              File has unrecognized column headers
            </Text>
            <Text>
              Either remove these columns, or create a destination for the content in Junip using custom questions. <Link url="https://help.junip.co/en/articles/4607278-how-to-add-custom-questions" external>Learn more</Link>.
            </Text>
            <List type="bullet">
              {csvData.errors.extraHeaders.map((header, index) => (
                <List.Item key={index}>
                  {header}
                </List.Item>
              ))}
            </List>
          </List.Item>
        </List>
      )}
    </div>
  );

  // If the names of the custom questions are in the rows, then we can display a warning
  // telling the user to make sure the questions are created. It is currently too much to
  // process the whole file to check for all the question names.
  const customQuestionWarning = rowBasedQuestions && (
    <Banner
      title="Custom questions detected:"
      tone="warning"
    >
      <Text>
        Make sure there is a destination for the content in Junip using custom questions. <Link url="https://help.junip.co/en/articles/4607278-how-to-add-custom-questions" external>Learn more</Link>.
      </Text>
    </Banner>
  );

  const rejectedFileMessage = hasRejectedFile && (
    <Banner
      title="The following files couldn’t be uploaded:"
      tone="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .csv (was ${file.type}) and less than 100MB.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  const titleBanner = files.length > 0 && titleTone && (
    <Banner
      tone={titleTone}
    >
      <BlockStack gap="500">
        <p>{title}</p>
        {errorList}
      </BlockStack>
    </Banner>
  );

  return (
    <Page
      title={`Import reviews from ${provider.label}`}
      secondaryActions={[{ content: 'Cancel', url: `/reviews${window.location.search}` }]}
    >
      <BlockStack gap="200">
        <Card oundedAbove="sm">
          <Text as="h6" variant="headingSm">
            {`Upload review CSV`}
          </Text>
          <Box paddingBlock="300">
            <Text as="p" variant="bodyLg">
              {`Export your reviews from ${provider.label} in .csv format and drop the file below.`}
            </Text>
          </Box>
          {customQuestionWarning}
          {titleBanner}
          {rejectedFileMessage}
          <Box paddingBlock="200">
            <DropZone
              accept=".csv, text/csv"
              type="file"
              onDropAccepted={handleAcceptedDrop}
              onDropRejected={handleRejectedDrop}
              allowMultiple={false}
            >
              {uploadedFiles}
              {fileUpload}
            </DropZone>
          </Box>
        </Card>
      </BlockStack>
    </Page>
  );
};
