import React, { useState, useCallback } from 'react';
import {
  LegacyCard,
  LegacyStack,
  Button,
  TextField,
  Form
} from '@shopify/polaris';

export default function NewSurveyOption({ addOption }) {
  const [loading, setLoading] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [newDescription, setNewDescription] = useState('');

  const handleValueChange = useCallback((value) => setNewValue(value), []);
  const handleDescriptionChange = useCallback((description) => setNewDescription(description), []);

  const add = async () => {
    setLoading(true);
    await addOption(newValue, newDescription)
    setNewValue('');
    setNewDescription('');
    setLoading(false);
  };

  return (
    <LegacyCard.Section title="New option" subdued>
      <Form onSubmit={add}>
        <LegacyStack alignment="trailing">
          <LegacyStack.Item fill>
            <TextField
              type="text"
              label="Option"
              value={newValue}
              maxLength={30}
              onChange={handleValueChange}
              placeholder="ex. Dry"
            />
          </LegacyStack.Item>
          <LegacyStack.Item fill>
            <TextField
              type="text"
              label="Description (optional)"
              value={newDescription}
              onChange={handleDescriptionChange}
              placeholder="Add a brief description"
            />
          </LegacyStack.Item>
          <Button submit disabled={!newValue} loading={loading} variant="primary">
            Save
          </Button>
        </LegacyStack>
      </Form>
    </LegacyCard.Section>
  );
}
