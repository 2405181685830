import React from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { Button, Page, LegacyStack, LegacyCard, List, TextContainer, Text } from "@shopify/polaris";
import JunipLogo from 'components/JunipLogo';
import SkipOnboarding from 'components/SkipOnboarding';

import { ExternalIcon } from "@shopify/polaris-icons";
import enableJunip from 'assets/images/onboarding/enableJunip.gif';

const NEXT_STEP = '/onboarding/add-junip-2';

export default function AddJunip1({ store, progress }) {
  const renderAppUrl = (template, handle) => {
    return `https://${store?.app_install?.uid}/admin/themes/current/editor?context=apps&template=${template}&activateAppId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/${handle}`;
  };

  const steps = [
    'Visit your Shopify theme editor',
    'Enable the Junip App embed under Theme settings.',
    'Hit Save.',
  ];

  return (
    <Page>
      <LegacyCard flush>
        <div className="onboarding-step o-divider">
          <div className="o-step o-step-subdued o-divider-right">
            <LegacyStack vertical spacing="extraLoose">
              <JunipLogo height={'24px'} />
              <Text variant="heading2xl" as="p">Add Junip to your Shopify theme</Text>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">It looks like your Shopify theme supports sections everywhere! We'll walk you through the steps on how to add Junip to your theme:</Text>
              </p>
              <LegacyCard title="Enable the Junip App Embed" sectioned>
                <TextContainer spacing="loose">
                  <Text variant="bodyMd" as="span" tone="subdued">
                    <List type="number">
                      {steps.map((step, index) =>
                        <List.Item key={index}>
                          {step}
                        </List.Item>
                      )}
                    </List>
                  </Text>
                  <div>
                    <Button
                      external
                      size="large"

                      url={renderAppUrl('index', 'junip-store-key')}
                      icon={ExternalIcon}
                      onClick={() => trackEvent('Onboarding add junip 1 - Enable App Embed')}
                      variant="primary">
                      Enable App Embed
                    </Button>
                  </div>
                </TextContainer>
              </LegacyCard>
            </LegacyStack>
          </div>
          <div className="o-step o-step-highlight">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <img src={enableJunip} alt="Enable Junip app embed" className="w-100" />
            </div>
          </div>
        </div>
        <LegacyCard.Section subdued>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              {progress}
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Button
                size="large"
                url={NEXT_STEP}
                onClick={() => trackEvent('Onboarding add junip 1 - Continue')}
              >
                Continue
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <SkipOnboarding disabled={false} />
    </Page>
  );
}
