import React, { useState, useEffect, useCallback } from 'react';
import useLastLocation from 'utils/useLastLocation';
import moment from 'moment';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { setFormDirty, savingChanges } from 'redux/settings';
import {
  fetchNotificationSettings,
  saveNotificationSettings
} from 'redux/notificationSettings';
import {
  Page,
  Layout,
  FormLayout,
  Checkbox,
  Select,
  TextField,
  OptionList,
  Popover,
  Button,
  LegacyStack,
  Text,
} from "@shopify/polaris";
import LoadingCardWrapper from 'components/LoadingCardWrapper';

function NotificationSettings(props) {
  const lastLocation = useLastLocation();
  const [inputs, setInputs] = useState({});
  const [time, setTime] = useState('09:00');
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const {
    fetchNotificationSettings,
    moderationSettings,
    notificationSettings,
    saveNotificationSettings,
    setFormDirty,
    settings,
  } = props;
  const data = notificationSettings.data || null;
  const selfModerationEnabled = moderationSettings?.data?.self_moderation_enabled;

  useEffect(() => {
    return () => {
      setFormDirty(false);
    }
  }, [setFormDirty]);

  useEffect(() => {
    if (!notificationSettings.data) {
      fetchNotificationSettings();
    } else if (notificationSettings.data) {
      setTime(moment(notificationSettings.data.periodic_digest_email_anchor || Date.now()).format(moment.HTML5_FMT.TIME));
    }
  }, [fetchNotificationSettings, notificationSettings.data]);

  useEffect(() => {
    if (data) {
      setInputs(data);
    }
  }, [data]);

  useEffect(() => {
    if (settings.discardChanges) {
      if (data) {
        setInputs(data);
        setTime(moment(data?.periodic_digest_email_anchor || Date.now()).format(moment.HTML5_FMT.TIME));
        setIsDirty(false);
        trackEvent('Notification settings - Discard changes');
      }
    }
  }, [data, settings.discardChanges]);

  useEffect(() => {
    if (settings.saving) {
      if (!isSaving) {
        saveNotificationSettings(data.id, { ...inputs, time });
        setIsSaving(true);
        trackEvent('Notification settings - Save changes');
      }
    } else {
      if (isSaving) {
        setIsSaving(false);
      }
    }
  }, [data, inputs, time, isSaving, saveNotificationSettings, settings.saving]);

  const checkIsDirty = useCallback(() => {
    if (!data || typeof (data) !== 'object') {
      return;
    }
    const propertyNames = Object.getOwnPropertyNames(inputs);
    let dirty = false;
    for (const name of propertyNames) {
      if (inputs[name] !== data[name]) {
        dirty = true;
        break;
      }
    }

    if (time !== moment(data?.periodic_digest_email_anchor || Date.now()).format(moment.HTML5_FMT.TIME)) {
      dirty = true;
    }

    if (dirty && !isDirty) {
      setIsDirty(true);
      if (!settings.isDirty) {
        setFormDirty(true);
      }
    } else if (!dirty && isDirty) {
      setIsDirty(false);
      if (settings.isDirty) {
        setFormDirty(false);
      }
    }
  }, [data, inputs, time, isDirty, setFormDirty, settings.isDirty]);

  useEffect(() => {
    checkIsDirty()
  }, [checkIsDirty]);

  const handleInputChange = (field) => {
    return (value) => {
      setInputs(inputs => ({ ...inputs, [field]: value }));
    };
  };

  const getDate = (dateTime) => {
    if (!dateTime) {
      return '';
    }
    return moment(dateTime).day();
  };

  const handleTimeChange = useCallback((value) => {
    setTime(value.replace(/[^0-9:]/g, ''));
  }, []);

  const handleDayChange = (day) => {
    let newValue = moment(inputs.periodic_digest_email_anchor || Date.now());
    newValue.day(day);
    handleInputChange('periodic_digest_email_anchor')(newValue.format());
  };

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const newReviewStateOptions = [
    { label: 'All', value: 'all' },
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' }
  ];

  const digestPeriodOptions = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' }
  ];

  const dayOptions = [
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 }
  ];

  const ratingOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 }
  ];

  const renderRatingsButtonText = () => {
    if (!inputs.new_review_email_dependent_ratings || inputs.new_review_email_dependent_ratings.length === 0) {
      return 'Select at least one rating';

    } else if (inputs.new_review_email_dependent_ratings.length === 5) {
      return 'All reviews';
    }

    return `${inputs.new_review_email_dependent_ratings.sort().join(', ')} star reviews`;
  };

  return (
    <Page
      title="Notification settings"
      backAction={{ content: 'Settings', url: lastLocation || '/settings' }}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="New review emails"
          description="Receive notifications when a customer leaves a review"
        >
          <LoadingCardWrapper loading={notificationSettings.loading}>
            <FormLayout>
              <Checkbox
                label="Enable new review emails"
                checked={inputs.new_review_email_enabled}
                onChange={handleInputChange('new_review_email_enabled')}
                disabled={settings.saving}
              />
              {selfModerationEnabled && (
                <Select
                  label="Review state"
                  helpText="You will only be notified on new reviews in the above state"
                  options={newReviewStateOptions}
                  disabled={settings.saving || !inputs.new_review_email_enabled}
                  value={inputs.new_review_email_dependent_state || 'all'}
                  onChange={handleInputChange('new_review_email_dependent_state')}
                />
              )}
              <LegacyStack vertical spacing="extraTight">
                <p>Review rating</p>
                <Popover
                  active={popoverActive}
                  activator={
                    <Button
                      onClick={togglePopoverActive}
                      disabled={settings.saving || !inputs.new_review_email_enabled}
                      disclosure="select"
                      fullWidth
                      textAlign="left"
                    >
                      {renderRatingsButtonText()}
                    </Button>
                  }
                  onClose={togglePopoverActive}
                  preferredAlignment="left"
                  fullWidth
                >
                  <OptionList
                    onChange={handleInputChange('new_review_email_dependent_ratings')}
                    options={ratingOptions}
                    selected={inputs.new_review_email_dependent_ratings}
                    title="Select ratings"
                    allowMultiple
                  />
                </Popover>
                <p>
                  <Text variant="bodyMd" as="span" tone="subdued">You will only be notified on new reviews with the above ratings</Text>
                </p>
              </LegacyStack>
            </FormLayout>
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Digest emails"
          description="Emails summarizing your program performance sent on a daily or weekly basis"
        >
          <LoadingCardWrapper loading={notificationSettings.loading}>
            <FormLayout>
              <Checkbox
                label="Enable digest emails"
                checked={inputs.periodic_digest_email_enabled}
                onChange={handleInputChange('periodic_digest_email_enabled')}
                disabled={settings.saving}
              />
              <Select
                label="Frequency"
                helpText="Configure how often you would like to receive digest emails"
                options={digestPeriodOptions}
                disabled={settings.saving || !inputs.periodic_digest_email_enabled}
                value={inputs.periodic_digest_email_frequency}
                onChange={handleInputChange('periodic_digest_email_frequency')}
              />

              {inputs.periodic_digest_email_frequency === 'weekly' &&
                <Select
                  label="Day of the week"
                  helpText="Set the day of the week you'd like to receive the digest email"
                  options={dayOptions}
                  disabled={settings.saving || !inputs.periodic_digest_email_enabled}
                  value={getDate(inputs.periodic_digest_email_anchor)}
                  onChange={handleDayChange}
                />
              }
              <TextField
                label="Time"
                helpText="Set the time of day when you'd like to receive the digest email"
                type="time"
                disabled={settings.saving || !inputs.periodic_digest_email_enabled}
                value={time}
                error={moment(time, 'HH:mm', true).isValid() ? false : 'Enter a valid time (09:00)'}
                onChange={handleTimeChange}
              />
              <p>Note it may take up to 24 hours for changes to propagate</p>
            </FormLayout>
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  notificationSettings: state.notificationSettings,
  moderationSettings: state.moderationSettings,
});

const mapDispatchToProps = (dispatch) => ({
  setFormDirty: (dirty) => dispatch(setFormDirty(dirty)),
  savingChanges: () => dispatch(savingChanges()),
  fetchNotificationSettings: () => dispatch(fetchNotificationSettings()),
  saveNotificationSettings: (id, settings) => dispatch(saveNotificationSettings(id, settings)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSettings);
