import React, { useState, useCallback } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import {
  Modal,
  Form,
  TextField,
  Link,
  TextContainer,
  Button,
} from '@shopify/polaris';
import { DuplicateIcon } from "@shopify/polaris-icons";

export default function WebhookSecretModal({showModal, setShowModal, installed, app, appDetails, install, update, showToast, webhookUrl}) {
  const [webhookSecret, setWebhookSecret] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNextStepModal, setShowNextStepModal] = useState(false);

  const submitKey = async () => {
    setLoading(true);
    try {
      if (installed) {
        await update(webhookSecret);
      } else {
        await install(webhookSecret);
      }
      setShowModal(false);
      setLoading(false);
      setWebhookSecret('');
      setShowNextStepModal(true)
    } catch(e) {
      setError(true);
      setLoading(false);
    }
  };

  const handleClearButtonClick = useCallback(() => { setWebhookSecret(''); setError(false); }, []);
  const handleChange = useCallback((value) => {setWebhookSecret(value); setError(false); }, []);

  const copy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      showToast(`Webhook URL copied to clipboard`);
    } catch (e) {
      // error
    }
  };

  return <>
    <Modal
      open={showModal}
      title={installed ? `Update your ${appDetails?.name} webhook secret` : `Install ${appDetails?.name}?`}
      onClose={() => !loading && setShowModal(false)}
      primaryAction={{
        content: 'Next',
        loading: loading,
        disabled: !webhookSecret,
        onAction: () => { submitKey(); trackEvent('Webhook secret modal - Submit secret') }
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: loading,
        onAction: () => { setShowModal(false); trackEvent('Webhook secret modal - Cancel') },
      }]}
    >
      <Modal.Section>
        <Form onSubmit={submitKey}>
          <TextField
            value={webhookSecret}
            label={`Enter your ${appDetails?.name} webhook secret`}
            type="text"
            clearButton
            onClearButtonClick={handleClearButtonClick}
            onChange={handleChange}
            error={error && `Please enter a valid ${appDetails?.name} webhook secret`}
          />
        </Form>
      </Modal.Section>
      <Modal.Section subdued>
        <TextContainer>
          <p>Steps on how to find your webhook secret:</p>
          <p>Visit this <Link url={app.auth_url} external>link</Link> to find your {appDetails?.name} webhook secret</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
    <Modal
      open={showNextStepModal}
      title="Complete installation in AfterShip"
      onClose={() => setShowNextStepModal(false)}
      primaryAction={{
        content: 'Done',
        onAction: () => { setShowNextStepModal(false) }
      }}
    >
      <Modal.Section>
        <TextContainer>
          <p> To complete the installation, visit your <Link url="https://admin.aftership.com/notifications/webhooks">AfterShip admin panel</Link> and add the following Webhook URL:</p>
          <TextField value={webhookUrl} readOnly />
          <Button icon={DuplicateIcon} onClick={() => copy(webhookUrl)}>Copy</Button>
        </TextContainer>
      </Modal.Section>
    </Modal>
  </>;
}
