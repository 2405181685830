import React, { useCallback, useState, useEffect } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { fetchAppInstalls } from 'redux/appInstalls';
import { fetchMessageTemplates } from 'redux/messages';
import {
  Page,
  Link,
  FooterHelp,
  LegacyCard,
  Tabs
} from '@shopify/polaris';

import LoadingContentWrapper from 'components/LoadingContentWrapper';
import FlowRidersTable from 'components/FlowRidersTable';
import SentTable from 'components/SentTable';

function UnifiedOutbox({ appInstalls, fetchAppInstalls, messages, fetchMessageTemplates, push }) {
  const params = new URLSearchParams(window.location.search);
  const tab = parseInt(params.get('tab')) || 0;
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(tab <= 2 ? tab : 0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => { setSelected(selectedTabIndex); trackEvent('Outbox - Change tab'); },
    [],
  );

  useEffect(() => {
    if (!appInstalls.data) {
      fetchAppInstalls();
    }
    if (!messages.data) {
      fetchMessageTemplates();
    }
    setLoading(false);
  }, [fetchAppInstalls, appInstalls.data, fetchMessageTemplates, messages.data]);

  const mapConnected = appInstalls?.data?.findIndex(a => a.app?.sends_messages === true && a.app?.name !== 'Shopify' && a.state === 'connected') !== -1;

  const tabs = [
    { content: 'Queue', id: 'queue' },
    { content: 'Sent', id: 'sent' },
    { content: 'Incomplete', id: 'incomplete' },
  ];

  return (
    <Page
      title="Outbox"
      subtitle="View your queue and history of messages and events"
      fullWidth
    >
      <LoadingContentWrapper loading={loading || appInstalls?.loading || messages?.loading} cards={3}>
        <LegacyCard>
          <Tabs
            tabs={tabs}
            selected={selected}
            onSelect={handleTabChange}
          />
          {selected === 1 ?
            <SentTable
              push={push}
              handleTabChange={handleTabChange}
              mapConnected={mapConnected}
            />
            :
            <FlowRidersTable
              push={push}
              selected={selected}
              handleTabChange={handleTabChange}
              messages={messages}
            />
          }
        </LegacyCard>
      </LoadingContentWrapper>
      <FooterHelp>
        Configure how messages and events are queued up via the{' '}
        <Link url="/flows">
          flows section
        </Link>
        .
      </FooterHelp>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  appInstalls: state.appInstalls,
  messages: state.messages,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAppInstalls: () => dispatch(fetchAppInstalls()),
  fetchMessageTemplates: () => dispatch(fetchMessageTemplates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnifiedOutbox);
