import React, { useState, useCallback } from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { setUser } from 'redux/user';
import { showToast } from 'redux/toast';

import {
  LegacyCard,
  Select,
  Form,
  FormLayout,
  Button
} from '@shopify/polaris';

import LoginWrapper from 'components/LoginWrapper';

function SelectOrganization(props) {
  if (!props.location.state || !props.location.state.user) {
    props.history.replace('/login');
  }

  const queryParams = props.history.location.search;
  const { user, organizations } = props.location?.state || {};

  const options = organizations?.map(org => {
    return {label: org.name, value: org.id};
  });
  options.sort((a, b) => a.label?.localeCompare(b.label));

  const [selected, setSelected] = useState(options[0].value);
  const [loading, setLoading] = useState(false);
  const handleSelectChange = useCallback((value) => setSelected(parseInt(value)), []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.post(`/api/v1/organizations/${selected}/scope`);
      fetchStores();
    } catch(e) {
      setLoading(false);
      props.showToast('Oops, unable to login! Please try again', true);
    }
  };

  const fetchStores = async () => {
    try {
      const response = await axios.get('/api/v1/stores');
      const stores = response?.data?.stores;
      if (stores?.length > 0) {
        if (stores.length === 1) {
          const storeId = stores[0].id;
          await axios.post(`/api/v1/stores/${storeId}/scope`);
          props.setUser(user);
        } else {
          props.history.push('/login/select/store' + queryParams, { user, stores });
        }
      } else {
        props.history.push('/login/connect_store' + queryParams, { user });
      }
    } catch (e) {
      setLoading(false);
      props.showToast('Oops, unable to login! Please try again', true);
    }
  };

  return (
    <LoginWrapper>
      <LegacyCard sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <Select
              label="Select an organization:"
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
            <Button size="large" submit loading={loading} variant="primary">Select</Button>
          </FormLayout>
        </Form>
      </LegacyCard>
    </LoginWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(SelectOrganization);
