import React from 'react';
import { Button, Checkbox, FormLayout, Modal, TextContainer, Icon, Text } from "@shopify/polaris";
import { ExternalIcon, CheckCircleIcon, FilterIcon } from "@shopify/polaris-icons";

export default function IncentiveConditions({ flow, editing, setEditing }) {
  return <>
    <div className="d-flex">
      <div className="mr-3">
        <Icon tone="primary" source={CheckCircleIcon} />
      </div>
      <p>
        <Text variant="bodyMd" as="span" fontWeight="semibold">IF</Text>{' '}
        incentives are enabled
      </p>
    </div>
    <div className="d-flex">
      <div className="mr-3">
        <Icon tone="primary" source={FilterIcon} />
      </div>
      <p>
        <Text variant="bodyMd" as="span" fontWeight="semibold">AND</Text>{' '}
        the customer's review is eligible for an incentive
      </p>
    </div>
    <Modal
      open={editing}
      onClose={() => setEditing(false)}
      title="Conditions"
      primaryAction={{
        content: 'Done',
        onAction: () => setEditing(false),
      }}
    >
      <Modal.Section>
        <TextContainer>
          <FormLayout>
            <Checkbox
              label="Require incentives to be enabled"
              checked
              disabled
            />
            <Checkbox
              label="Review must be eligible for incentive"
              checked
              disabled
            />
          </FormLayout>
          <p>
            <Text variant="bodyMd" as="span" tone="subdued">Visit the incentives section to enable and manage rewards for customers who leave a review.</Text>
          </p>
          <Button icon={ExternalIcon} url="/incentives" size="slim">Manage incentives</Button>
        </TextContainer>
      </Modal.Section>
    </Modal>
  </>;
}
