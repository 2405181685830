import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  LegacyCard,
  TextField,
  Form,
  Button,
  LegacyStack
} from '@shopify/polaris';
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import ReviewNote from 'components/ReviewNote';

function ReviewNotes({ reviewId, user, showToast, reload, setReload }) {
  const [notesLoading, setNotesLoading] = useState(true);
  const [reviewNotes, setReviewNotes] = useState(null);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchReviewNotes = useCallback(async () => {
    setNotesLoading(true);
    try {
      const response = await axios.get('/api/v1/review_notes', {
        params: {
          include: 'user',
          'filter[review_id]': reviewId
        }
      });
      setReviewNotes(response.data.review_notes);
    } catch (e) {
      // TODO
    } finally {
      setNotesLoading(false);
    }
  }, [reviewId]);

  useEffect(() => {
    fetchReviewNotes();
  }, [fetchReviewNotes]);

  useEffect(() => {
    if (reload) {
      fetchReviewNotes();
      setReload(false);
    }
  }, [reload, fetchReviewNotes, setReload]);

  const handleChange = useCallback((newValue) => setNewNote(newValue), []);

  const addNote = async () => {
    setLoading(true);
    try {
      await axios.post('/api/v1/review_notes', {
        content: newNote,
        review_id: reviewId
      });
      setNewNote('');
      fetchReviewNotes();
      showToast('Internal note added');
      trackEvent('Review - Add internal note');
    } catch(e) {
      showToast('Error adding internal note, please try again', true);
      trackEvent('Error: Review - Add internal note');
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async (id, newNote) => {
    try {
      await axios.put(`/api/v1/review_notes/${id}`, {
        review_note: {
          content: newNote
        }
      });
      showToast('Note updated');
      fetchReviewNotes();
      trackEvent('Review - Edit internal note');
    } catch(e) {
      showToast('Error updating note, please try again', true);
      trackEvent('Error: Review - Edit internal note');
      throw e;
    }
  };

  const deleteNote = async (id) => {
    try {
      await axios.delete(`/api/v1/review_notes/${id}`);
      showToast('Internal note deleted');
      fetchReviewNotes();
      trackEvent('Review - Delete internal note');
    } catch(e) {
      showToast('Error delete note, please try again', true);
      trackEvent('Error: Review - Delete internal note');
      throw e;
    }
  };

  return (
    <LoadingCardWrapper subdued={true} sectioned={false} loading={notesLoading} title="Internal notes">
      { reviewNotes?.map?.(reviewNote =>
        <ReviewNote
          key={reviewNote.id}
          reviewNote={reviewNote}
          user={user}
          updateNote={updateNote}
          deleteNote={deleteNote}
        />
      )}
      <LegacyCard.Section>
        <Form onSubmit={addNote}>
          <LegacyStack>
            <LegacyStack.Item fill>
              <TextField
                type="text"
                value={newNote}
                onChange={handleChange}
                placeholder="Enter a new internal note..."
                spellCheck
                multiline
              />
            </LegacyStack.Item>
            <Button
              disabled={!newNote}
              loading={loading}
              submit
            >
              Add note
              </Button>
          </LegacyStack>
        </Form>
      </LegacyCard.Section>
    </LoadingCardWrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewNotes);
