import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import {
  LegacyCard,
  EmptyState,
} from '@shopify/polaris';
import LoadingCardWrapper from 'components/LoadingCardWrapper';

import upgrade from 'assets/images/upgrade.svg';

function UpgradeTrigger({
  planFeatureSets,
  fetchPlanFeatureSets,
  children,
  feature,
  limit,
  title,
  description,
  cardWrapper = true,
  actionCallback = () => {}
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!planFeatureSets.data) {
      fetchPlanFeatureSets();
    } else {
      setLoading(false);
    }
  }, [fetchPlanFeatureSets, planFeatureSets.data]);

  let featureDisabled = planFeatureSets?.data && planFeatureSets?.data?.[feature] === false;
  if (limit) {
    featureDisabled = planFeatureSets?.data && limit >= parseInt(planFeatureSets?.data?.[feature]);
  }

  if (loading) {
    return <LoadingCardWrapper loading={true} />;
  }

  if (featureDisabled) {
    const emptyState = (
      <EmptyState
        heading={title}
        image={upgrade}
        action={{content: 'View plans', url: '/settings/billing', onAction: () => { actionCallback(); trackEvent('Upgrade trigger - View plans'); } }}
      >
        <p>
          {description}
        </p>
      </EmptyState>
    );

    if (cardWrapper) {
      return (
        <LegacyCard>
          {emptyState}
        </LegacyCard>
      );
    } else {
      return emptyState;
    }
  } else {
    return (
      <>
        {children}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  planFeatureSets: state.planFeatureSets,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlanFeatureSets: () => dispatch(fetchPlanFeatureSets())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeTrigger);
