import axios from 'axios';

// Actions
const FETCH_PRODUCT_REVIEWS_AGGREGATE = 'FETCH_PRODUCT_REVIEWS_AGGREGATE';
const FETCH_PRODUCT_REVIEWS_AGGREGATE_SUCCESS = 'FETCH_PRODUCT_REVIEWS_AGGREGATE_SUCCESS';
const FETCH_PRODUCT_REVIEWS_AGGREGATE_ERROR = 'FETCH_PRODUCT_REVIEWS_AGGREGATE_ERROR';

// Action Creators
export function fetchProductReviewsAggregate() {
  return async dispatch => {
    dispatch(fetchProductReviewsAggregateBegin());
    try {
      const response = await axios.get('/api/v1/product_reviews/aggregate');
      dispatch(fetchProductReviewsAggregateSuccess(response.data));
    } catch (e) {
      dispatch(fetchProductReviewsAggregateError(e));
    }
  }
}

export const fetchProductReviewsAggregateBegin = () => ({
  type: FETCH_PRODUCT_REVIEWS_AGGREGATE
});

export const fetchProductReviewsAggregateSuccess = (data) => ({
  type: FETCH_PRODUCT_REVIEWS_AGGREGATE_SUCCESS,
  data
});

export const fetchProductReviewsAggregateError = (error) => ({
  type: FETCH_PRODUCT_REVIEWS_AGGREGATE_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_PRODUCT_REVIEWS_AGGREGATE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PRODUCT_REVIEWS_AGGREGATE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_PRODUCT_REVIEWS_AGGREGATE_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
