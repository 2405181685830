import { trackEvent } from 'utils/Mixpanel';
import {
  Banner,
  Bleed,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Link,
  List,
  Text
} from "@shopify/polaris";

import {
  ExternalSmallIcon
} from '@shopify/polaris-icons';
import { useSelector } from "react-redux";
import ProductReviewsWidgetWrapper from "components/widgets/ProductReviewsWidgetWrapper";
import StarRatingWidgetWrapper from "./widgets/StarRatingWidgetWrapper";
import UgcGalleryWidgetWrapper from "./widgets/UgcGalleryWidgetWrapper";
import CarouselWidgetWrapper from "./widgets/CarouselWidgetWrapper";
import DedicatedReviewsPageWidgetWrapper from "./widgets/DedicatedReviewsPageWidgetWrapper";

const HELP_DOC = 'https://help.junip.co/en/articles/5544190-adding-junip-to-your-2-0-theme';

export default function AppEmbedTab({ selectedTheme, switchTab }) {
  const stores = useSelector((state) => state.stores);
  const activeStore = stores?.data?.find((store) => store.scoped);

  const shopifyAdminUrl = `https://${activeStore?.app_install?.uid}/admin`;
  const baseUrl = `https://${activeStore?.app_install?.uid}/admin/themes/${selectedTheme || 'current'}/editor`;
  const appEmbedUrl = `${baseUrl}?context=apps&template=index&activateAppId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/junip-store-key`;
  const productReviewsUrl = `${baseUrl}?template=product&addAppBlockId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/junip-product-review&target=newAppsSection`;
  const starRatingUrl = `${baseUrl}?template=product&addAppBlockId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/junip-product-summary&target=mainSection`;
  const ugcGalleryProductUrl = `${baseUrl}?template=product&addAppBlockId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/junip-ugc-gallery&target=newAppsSection`;
  const ugcGalleryHomeUrl = `${baseUrl}?template=index&addAppBlockId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/junip-ugc-gallery&target=newAppsSection`;
  const carouselUrl = `${baseUrl}?template=index&addAppBlockId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_UUID}/junip-review-carousel&target=newAppsSection`;

  return (
    <BlockStack gap="400">
      <Card>
        <Bleed marginBlockStart="400" marginInline="400">
          <Box background="bg-surface-secondary" padding="400">
            <Text variant="headingSm" as="h1">First steps</Text>
          </Box>
        </Bleed>
        <Box paddingBlockStart="400">
          <BlockStack gap="400">
            <Text>Before adding the widgets, make sure the Junip app embed is enabled in Shopify.</Text>
            <ButtonGroup gap="loose">
              <Button
                icon={ExternalSmallIcon}
                url={appEmbedUrl}
                external
                variant="primary"
              >
                Enable app embed
              </Button>
              <Button
                variant="plain"
                external
                url={HELP_DOC}
              >
                View installation guide for Shopify 2.0 themes
              </Button>
            </ButtonGroup>
          </BlockStack>
        </Box>
      </Card>
      <ProductReviewsWidgetWrapper>
        <Box>
          <Button
            external
            url={productReviewsUrl}
            icon={ExternalSmallIcon}
          >
            Install on product page
          </Button>
        </Box>
      </ProductReviewsWidgetWrapper>
      <StarRatingWidgetWrapper>
        <BlockStack gap="400">
          <Banner tone="warning">
            Stars can be installed on collections pages using{' '}
            <Link onClick={() => switchTab('snippets')}>snippets</Link> or{' '}
            <Link onClick={() => switchTab('html')}>HTML</Link>{' '}
            if app blocks are not supported
          </Banner>
          <Box>
            <Button
              external
              url={starRatingUrl}
              icon={ExternalSmallIcon}
            >
              Install on product page
            </Button>
          </Box>
        </BlockStack>
      </StarRatingWidgetWrapper>
      <DedicatedReviewsPageWidgetWrapper>
        <Card>
          <BlockStack gap="300">
            <Text>To add a reviews page, visit your Shopify Admin panel and follow these steps:</Text>
            <List type="number" gap="loose">
              <List.Item>
                Create a new template within your theme (check this{' '}
                <Link
                  target="_blank"
                  url="https://help.shopify.com/en/manual/online-store/themes/theme-structure/templates#create-a-new-template"
                >
                  Shopify help guide
                </Link>
                {' '}for detailed instructions).
              </List.Item>
              <List.Item>
                Add the “Junip - Storewide Reviews” block to the template and save.
              </List.Item>
              <List.Item>
                Add a <Link external url={`${shopifyAdminUrl}/pages/new`} onClick={() => { trackEvent('On-site - Reviews page add page') }}>new page</Link>
                {' '}to your site.
              </List.Item>
              <List.Item>
                Using the “Theme template” dropdown, apply your newly created template to the page and save.
              </List.Item>
            </List>
          </BlockStack>
        </Card>
      </DedicatedReviewsPageWidgetWrapper>
      <UgcGalleryWidgetWrapper>
        <ButtonGroup>
          <Button
            external
            url={ugcGalleryProductUrl}
            icon={ExternalSmallIcon}
          >
            Install on product page
          </Button>
          <Button
            external
            url={ugcGalleryHomeUrl}
            icon={ExternalSmallIcon}
          >
            Install on home page
          </Button>
        </ButtonGroup>
      </UgcGalleryWidgetWrapper>
      <CarouselWidgetWrapper>
        <Box>
          <Button
            external
            url={carouselUrl}
            icon={ExternalSmallIcon}
          >
            Install on home page
          </Button>
        </Box>
      </CarouselWidgetWrapper>
    </BlockStack>
  );
}
