import axios from 'axios';

// Actions
const FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_BEGIN = 'FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_BEGIN';
const FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_SUCCESS = 'FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_SUCCESS';
const FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_ERROR = 'FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_ERROR';

// Action Creators
export function fetchGoogleReviewSnapshotStats() {
  return async dispatch => {
    dispatch(fetchGoogleReviewSnapshotStatsBegin());
    try {
      let beforeDate = new Date();
      beforeDate.setUTCHours(5, 0, 0, 0);
      beforeDate = beforeDate.toISOString();

      const response = await axios.get('/api/v1/google_review_snapshots/stats', {
        params: {
          before: beforeDate
        }
      });
      dispatch(fetchGoogleReviewSnapshotStatsSuccess(response.data));
    } catch (e) {
      dispatch(fetchGoogleReviewSnapshotStatsError(e));
    }
  }
}

export const fetchGoogleReviewSnapshotStatsBegin = () => ({
  type: FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_BEGIN
});

export const fetchGoogleReviewSnapshotStatsSuccess = data => ({
  type: FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_SUCCESS,
  data
});

export const fetchGoogleReviewSnapshotStatsError = error => ({
  type: FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_GOOGLE_REVIEW_SNAPSHOT_STATS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
