import React from 'react';
import { LegacyCard, LegacyStack, Text, Icon, Tooltip, InlineStack, BlockStack } from "@shopify/polaris";
import { TrendIndicator } from '@shopify/polaris-viz';
import { InfoIcon } from "@shopify/polaris-icons";

export default function SummaryCard({ title, currentValue, allTime, theme, trendValue, emptyState, showPercentage = false, tooltipContent = '' }) {
  return (
    <LegacyCard>
      <LegacyCard.Section>
        <BlockStack gap='200'>
          <div style={{ height: '20px' }}>
            <InlineStack gap='200' blockAlign='center'>
              <Text variant='bodySm' fontWeight='medium'>{title}</Text>
              {tooltipContent &&
                <Tooltip content={tooltipContent}>
                  <Icon source={InfoIcon} tone="subdued" />
                </Tooltip>}
            </InlineStack>
          </div>
          {emptyState ?
            <div style={{ height: '24px' }}>
              <Text variant="bodyMd" as="span" tone="subdued">No data available from this period</Text>
            </div>
            :
            <LegacyStack alignment="center">
              <LegacyStack.Item fill>
                {currentValue}
              </LegacyStack.Item>
              <LegacyStack.Item>
                {trendValue !== 0 &&
                  <TrendIndicator
                    theme={theme ? theme : 'Default'}
                    direction={trendValue > 0 ? 'upward' : 'downward'}
                    trend={trendValue > 0 ? 'positive' : 'negative'}
                    value={Math.abs(trendValue.toPrecision(3)) + '%'}
                  />
                }
              </LegacyStack.Item>
            </LegacyStack>
          }
        </BlockStack>
      </LegacyCard.Section>
      <LegacyCard.Section subdued>
        <LegacyStack alignment="fill">
          <div style={{ height: '20px' }}>
            {allTime &&
              <LegacyStack.Item>
                <p>All time: <b>{allTime}{showPercentage ? "%" : ""}</b></p>
              </LegacyStack.Item>
            }
          </div>
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
}
