import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import ReviewTagContainer from 'components/ReviewTagContainer';
import ReviewItem from 'components/ReviewItem';
import RenderedTraits from 'components/RenderedTraits';
import MediaPreview from 'components/MediaPreview';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import ReviewNotes from 'components/ReviewNotes';
import ReviewShareModal from 'components/ReviewShareModal';
import {
  Page,
  LegacyCard,
  Button,
  Form,
  FormLayout,
  TextField,
  Checkbox,
  LegacyStack,
  Badge,
  TextContainer,
  Icon,
  Thumbnail,
  Link,
  Banner,
  ButtonGroup,
  Modal,
  Text,
} from "@shopify/polaris";
import { StarIcon, StarFilledIcon, CheckIcon, DiscountIcon, ShareIcon } from "@shopify/polaris-icons";
import noImage from 'assets/images/noImage.jpg';
import 'styles/routes/Review.scss';

const ReviewLayout = (props) => {
  const reviewId = props.match.params.id;
  const queryParams = props.history.location.search;
  const params = new URLSearchParams(queryParams);

  const [review, setReview] = useState(props.location?.state?.review || {});
  const [originalResponse, setOriginalResponse] = useState('');
  const [response, setResponse] = useState('');
  const [sendResponseNotification, setSendResponseNotification] = useState(false);
  const [saveResponseLoading, setSaveResponseLoading] = useState(false);
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [featureLoading, setFeatureLoading] = useState(false);
  const [showShareModal, setShowShareModal] = useState(params.get('share') === 'true' ? true : false);
  const [showProtestModal, setShowProtestModal] = useState(false);
  const [reloadNotes, setReloadNotes] = useState(false);
  const {
    fetchPlanFeatureSets,
    planFeatureSets,
    showToast,
    history,
    moderationSettings,
  } = props;

  const replace = history.replace;
  const selfModerationEnabled = moderationSettings?.data?.self_moderation_enabled;

  const store = props?.stores?.data?.find?.(s => s.scoped === true) || {};
  const customer = review?.customer || {};
  const product = review?.product;
  const publicTraitAnswers = review?.survey_answers?.filter(a => a.private === false && a.value_type === 'rating');
  const privateTraitAnswers = review?.survey_answers?.filter(a => a.private === true && a.value_type === 'rating');
  const publicAnswers = review?.survey_answers?.filter(a => a.private === false && a.value_type !== 'rating');
  const privateAnswers = review?.survey_answers?.filter(a => a.private === true && a.value_type !== 'rating');

  const globallySyndicated = review?.origin === 'global';
  const locallySyndicated = review?.origin === 'local';
  const syndicated = globallySyndicated || locallySyndicated;

  useEffect(() => {
    const newParams = new URLSearchParams(queryParams);
    if (newParams.get('share')) {
      newParams.delete('share');
      replace({
        search: `?${newParams.toString()}`
      });
    }
  } , [replace, queryParams]);

  useEffect(() => {
    if (!planFeatureSets.data) {
      fetchPlanFeatureSets();
    }
  }, [fetchPlanFeatureSets, planFeatureSets.data]);

  useEffect(() => {
    async function fetchReview() {
      try {
        const response = await axios.get(`/api/v1/reviews/${reviewId}`, {
          params: {
            include: 'customer,product,store,reward,survey_answers,tik_tok_urls'
          }
        });
        setReview(response.data.review);
        setLoading(false);
      } catch(e) {
        // Redirect to moderation page
        replace('/reviews');
      }
    }
    if (reviewId) {
      fetchReview();
    }
  }, [reviewId, replace]);

  useEffect(() => {
    if (review.id) {
      setResponse(review.response || '');
      setOriginalResponse(review.response || '');
      setLoading(false);
    }
  }, [review]);

  const toggleSendResponseNotification = useCallback(
    () => setSendResponseNotification((sendResponseNotification) => !sendResponseNotification),
    [],
  );

  const approveReview = async () => {
    setApproving(true);
    await reviewAction('approve');
    setApproving(false);
  };

  const rejectReview = async () => {
    setRejecting(true);
    await reviewAction('reject');
    setRejecting(false);
  };

  const addNote = async (note) => {
    try {
      await axios.post('/api/v1/review_notes', {
        content: `Protest: ${note}`,
        review_id: reviewId
      });
    } catch (e) {
      // Do nothing
    }
  };

  const protestReview = async (reason) => {
    await addNote(reason);
    setReloadNotes(true);
    await reviewAction('protest');
  };

  const reviewAction = async (type) => {
    try {
      const response = await axios.put(`/api/v1/reviews/${reviewId}/${type}`);
      setReview(Object.assign(review, response.data.review));
      props.history.replace({ ...props.history.location, review: null });
      if (type === 'protest') {
        props.showToast('Review flagged. This review has been removed from your feed and will be reviewed by our team.');
      } else {
        props.showToast('Review updated');
      }
      trackEvent(`Review - Action ${type}`);
    } catch(e) {
      props.showToast('Error :( please trying again later.', true);
      trackEvent(`Error: Review - Action ${type}`);
    }
  };

  const submitResponse = async () => {
    setSaveResponseLoading(true);
    try {
      await axios.put(
        `/api/v1/reviews/${reviewId}`,
        {
          review: {
            response: response || null,
            response_alert: sendResponseNotification || undefined // this force omits the param if not set
          }
        }
      );
      setSaveResponseLoading(false);
      setOriginalResponse(response);
      props.showToast('Reply saved');
      props.history.replace({ ...props.history.location, review: null });
      trackEvent('Review - Reply saved');
      setSendResponseNotification(false);
    } catch(e) {
      props.showToast('Error saving reply please trying again later.', true);
      setSaveResponseLoading(false);
      trackEvent('Error: Review - Reply saved');
    }
  };

  const thumbnailClick = (url, index, type, data) => {
    setMediaPreview({url, type: type || 'image', data});
  };

  const toggleFeatured = async (isFeatured) => {
    setFeatureLoading(true);
    try {
      const response = await axios.put(`/api/v1/reviews/${review.id}`, {
        review: {
          featured: !isFeatured
        }
      });
      setReview(Object.assign(review, response.data.review));
      props.history.replace({ ...props.history.location, review: null });
      props.showToast(`${isFeatured === true ? 'Unmarked' : 'Marked'} review as featured`);
      trackEvent('Review - Toggle featured');
    } catch (e) {
      props.showToast('Error updating featured, please try again', true);
      trackEvent('Error: Review - Toggle featured');
    } finally {
      setFeatureLoading(false);
    }
  };

  const featuredAction = planFeatureSets?.data?.['pf_featured_reviews'] === false || globallySyndicated ?
    []
    :
    [{
      icon: review.featured ? StarFilledIcon : StarIcon,
      content: review.featured ? 'Unmark as featured' : 'Mark as featured',
      loading: featureLoading,
      disabled: featureLoading,
      onAction: () => toggleFeatured(review.featured)
    }];

  const renderBadge = () => {
    if (syndicated) {
      return <Badge tone="info">Syndicated</Badge>;
    }

    const state = review?.state;
    switch (state) {
      case 'approved':
        if (selfModerationEnabled) {
          return <Badge tone="success">Published</Badge>;
        } else {
          return (
            <></>
          );
        }
      case 'rejected':
        return <Badge tone="critical">Rejected</Badge>;
      case 'pending':
        return <Badge tone="attention">Pending</Badge>;
      default:
        return (
          <Badge>Pending</Badge>
        );
    }
  };

  const copy = async (text, string) => {
    try {
      await navigator.clipboard.writeText(text);
      showToast(`${string} copied to clipboard`);
      trackEvent(`Review - Copy ${string}`);
    } catch (e) {
      // error
    }
  };

  const editReviewUrl = () => {
    return `${process.env.REACT_APP_FORMS_URL}/${store?.slug}#edit`;
  };

  return (
    <LoadingPageWrapper loading={loading || planFeatureSets.loading}>
      <Page
        title={product?.id ? 'Product review' : 'Store review'}
        titleMetadata={
          <LegacyStack spacing="extraTight">
            { review?.featured &&
              <Badge tone="info">Featured</Badge>
            }
            { renderBadge() }
          </LegacyStack>
        }
        backAction={{content: 'Reviews', url: `/reviews${window.location.search}`}}
        secondaryActions={[{
          content: 'Share this review',
          icon: ShareIcon,
          onAction: () => {
            trackEvent('Review - Share');
            setShowShareModal(true);
          }
        }, ...featuredAction]}
      >
        {review?.content_moderation_flagged && review?.state === 'pending' &&
          <div className="mb-4">
            <Banner title="Review flagged" tone="warning">
              <p>This review was automatically flagged because it may contain explicit content. Please verify the content before approving</p>
            </Banner>
          </div>
        }
        <div className="review-grid-container">
          <div>
            <LegacyCard>
              <LegacyCard.Section>
                <div className="review-container">
                  <ReviewItem
                    customer={review.customer || {}}
                    product={review.product || {}}
                    review={review}
                    showTitle={false}
                    thumbnailSpacing={'default'}
                    thumbnailSize={'large'}
                    thumbnailClick={thumbnailClick}
                  />
                  {publicTraitAnswers?.length > 0 &&
                    <div className="mt-4 mb-4">
                      <RenderedTraits
                        traits={publicTraitAnswers}
                        labelAccessor={(t) => t.meta}
                      />
                    </div>
                  }
                  {publicAnswers?.length > 0 &&
                    <div className="mt-4">
                      <TextContainer spacing="tight">
                        {publicAnswers.map((a) =>
                          <p key={a.id}>
                            <Text variant="bodyMd" as="span" fontWeight="semibold">{a.name}</Text>: {a.value}
                          </p>
                        )}
                      </TextContainer>
                    </div>
                  }
                  {(globallySyndicated && response) &&
                    <div className="mt-4">
                      <LegacyCard sectioned subdued>
                        <TextContainer spacing="tight">
                          <p className="mb-0"><Text variant="bodyMd" as="span" fontWeight="semibold">Public response:</Text></p>
                          <p>{response}</p>
                        </TextContainer>
                      </LegacyCard>
                    </div>
                  }
                </div>
              </LegacyCard.Section>
              {(privateAnswers?.length > 0 || privateTraitAnswers?.length > 0) &&
                <LegacyCard.Section subdued title="Private responses">
                  {privateTraitAnswers?.length > 0 &&
                    <div className="mt-4 mb-4">
                      <RenderedTraits
                        traits={privateTraitAnswers}
                        labelAccessor={(t) => t.meta}
                      />
                    </div>
                  }
                  {privateAnswers?.length > 0 &&
                    <div className="mt-4">
                      <TextContainer spacing="tight">
                        {privateAnswers.map((a) =>
                          <p key={a.id}>
                            <Text variant="bodyMd" as="span" fontWeight="semibold">{a.name}</Text>: {a.value}
                          </p>
                        )}
                      </TextContainer>
                    </div>
                  }
                </LegacyCard.Section>
              }
              {!globallySyndicated && (
                <LegacyCard.Section>
                  <div className="d-flex align-items-between">
                    <Text variant="bodySm" as="div" tone="subdued">
                      FTC guidelines require all reviews to be published unless they contain spam, fraudulent, or inappropriate content.
                    </Text>
                    {selfModerationEnabled ? (
                      <ButtonGroup noWrap>
                        <Button
                          onClick={rejectReview}
                          loading={rejecting}
                          disabled={review.state === 'rejected'}

                          variant="primary"
                          tone="critical">
                          Reject
                        </Button>
                        <Button
                          onClick={approveReview}
                          loading={approving}
                          disabled={review.state === 'approved'}

                          variant="primary">
                          Publish
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <Button
                        onClick={() => setShowProtestModal(true)}
                        disabled={review.state === 'pending'}
                      >
                        Flag
                      </Button>
                    )}
                  </div>
                </LegacyCard.Section>
              )}
              {globallySyndicated && (
                <LegacyCard.Section subdued>
                  Shared by <strong>{review.store?.name}</strong> ({review.store?.url})
                </LegacyCard.Section>
              )}
            </LegacyCard>
            {review?.reward?.id && !globallySyndicated &&
              <LegacyCard
                title={
                  <LegacyStack alignment="center" spacing="tight">
                    <Icon source={DiscountIcon} tone="primary" />
                    <Text variant="headingMd" as="h2">Reward received</Text>
                  </LegacyStack>
                }
                sectioned>
                <TextContainer spacing="tight">
                  <p>Name: <Text variant="bodyMd" as="span" fontWeight="semibold">{review.reward.name}</Text></p>
                  <TextField
                    label="Code"
                    readOnly
                    type="text"
                    value={review.reward.code}
                    connectedRight={
                      <Button
                        size="large"
                        onClick={() => copy(review.reward.code, 'Reward code')}
                      >
                        Copy
                      </Button>
                    }
                  />
                </TextContainer>
              </LegacyCard>
            }
            {!globallySyndicated &&
              <LegacyCard sectioned title="Response">
                <Form onSubmit={submitResponse}>
                  <FormLayout>
                    <TextField
                      type="text"
                      value={response}
                      onChange={value => setResponse(value)}
                      helpText="This will show up publicly under the review"
                      placeholder="Enter your public response..."
                      multiline={3}
                      spellCheck
                    />
                    {originalResponse === '' &&
                      <Checkbox
                        label="Notify customer of your response"
                        checked={sendResponseNotification}
                        onChange={toggleSendResponseNotification}
                        disabled={saveResponseLoading}
                        helpText={
                          <>
                            Enabling this will send a notification to your customer with the above response using
                            the <Link url="/flows/templates">Review response email template</Link>.
                          </>
                        }
                      />
                    }
                    <Button
                      disabled={response === originalResponse}
                      loading={saveResponseLoading}
                      submit
                      variant="primary">{originalResponse === '' ? 'Respond' : 'Edit response'}</Button>
                  </FormLayout>
                </Form>
              </LegacyCard>
            }
            {!syndicated &&
              <ReviewNotes reviewId={reviewId} reload={reloadNotes} setReload={setReloadNotes} />
            }
          </div>
          <div>
            {product &&
              <LegacyCard title="Product reviewed" sectioned>
                <TextContainer spacing="tight">
                  <LegacyStack alignment="center">
                    {product.image &&
                      <Thumbnail size="small" source={product?.image?.url_200x200 || noImage} />
                    }
                    {syndicated ? (
                      <Text variant="bodyMd" as="span" fontWeight="semibold">{product?.title}</Text>
                    ) : (
                      <Link url={`/products/${product?.id}`}>
                        <Text variant="bodyMd" as="span" fontWeight="semibold">{product?.title}</Text>
                      </Link>
                    )}
                  </LegacyStack>
                  <LegacyStack alignment="center" spacing="tight">
                    <Icon source={StarFilledIcon} tone="caution" />
                    <Text variant="headingLg" as="p">{Math.round(product.rating_average * 100) / 100 || 0}</Text>
                    <Text variant="bodyMd" as="span" tone="subdued">({product.rating_count} review{product.rating_count === 1 ? '' : 's'})</Text>
                  </LegacyStack>
                </TextContainer>
              </LegacyCard>
            }
            <LegacyCard title="Customer">
              <LegacyCard.Section>
                <TextContainer spacing="tight">
                  <p>{customer.first_name || 'No'} {customer.last_name || 'Name'}</p>
                  {customer?.email &&
                    <p className="text-truncate"><Text variant="bodyMd" as="span" tone="subdued">{customer.email}</Text></p>
                  }
                  {review?.verified_buyer &&
                    <div className="d-flex">
                      <Icon tone="primary" source={CheckIcon} />
                      <p className="w-100">Verified buyer</p>
                    </div>
                  }
                  {review?.would_recommend &&
                    <div className="d-flex">
                      <Icon tone="primary" source={CheckIcon} />
                      <p className="w-100">Recommends this {product ? 'product' : 'store'}</p>
                    </div>
                  }
                </TextContainer>
              </LegacyCard.Section>
              {!syndicated &&(
                <LegacyCard.Section subdued title="Review editing">
                  <TextContainer>
                    <Text variant="bodyMd" as="span" tone="subdued">You can encourage customers to modify their reviews by sharing a Manual Review Link.</Text>
                    <TextContainer spacing="tight">
                      <TextField
                        readOnly
                        value={editReviewUrl()}
                        onFocus={(e) => e.target.select()}
                      />
                      <Button
                        onClick={() => copy(editReviewUrl(), 'Edit url')}
                      >
                        Copy link
                      </Button>
                    </TextContainer>
                  </TextContainer>
                </LegacyCard.Section>
              )}
            </LegacyCard>
            <LegacyCard
              sectioned
              title="Share review"
              primaryFooterAction={{
                content: 'Share',
                onAction: () => {setShowShareModal(true); trackEvent('Review - Open share modal')},
                icon: ShareIcon,
              }}
              footerActionAlignment="left">
              <p>Click the button below to share this review</p>
            </LegacyCard>
            { !selfModerationEnabled && !globallySyndicated && planFeatureSets?.data?.['pf_review_tagging'] === true &&
              <ReviewTagContainer reviewId={reviewId} />
            }
          </div>
        </div>
        <MediaPreview
          url={mediaPreview?.url}
          type={mediaPreview?.type}
          data={mediaPreview?.data}
          closePreview={() => {setMediaPreview(null)}}
        />
        <ReviewShareModal open={showShareModal} close={() => setShowShareModal(false)} review={review} />
      </Page>
      <ProtestModal
        open={showProtestModal}
        close={() => setShowProtestModal(false)}
        protest={protestReview}
      />
    </LoadingPageWrapper>
  );
}

function ProtestModal({ open, close, protest }) {
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleProtest = async () => {
    setLoading(true);
    await protest(reason);
    setLoading(false);
    close();
  };

  return (
    <Modal
      open={open}
      onClose={close}
      title="Flag review"
      primaryAction={{
        content: 'Flag',
        loading,
        onAction: handleProtest,
        disabled: !reason,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: loading,
          onAction: close
        }
      ]}
    >
      <Modal.Section>
        <Form onSubmit={handleProtest}>
          <TextField
            label="Reason"
            value={reason}
            onChange={setReason}
            placeholder="What did our filters miss?"
            helpText="This will be added as an internal note to the review"
          />
        </Form>
      </Modal.Section>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  planFeatureSets: state.planFeatureSets,
  stores: state.stores,
  moderationSettings: state.moderationSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlanFeatureSets: () => dispatch(fetchPlanFeatureSets()),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewLayout);
