import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApps } from 'redux/apps';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import { showToast } from 'redux/toast';
import { trackEvent } from 'utils/Mixpanel';
import axios from 'axios';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import UpgradeTrigger from 'components/UpgradeTrigger';
import {
  Card,
  EmptyState,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
  Thumbnail
} from '@shopify/polaris';

import { AppsIcon } from "@shopify/polaris-icons";

import image from 'assets/images/webhooks.svg';

export default function Custom({ history }) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [creating, setCreating] = useState(false);
  const [newAppName, setNewAppName] = useState('');

  const apps = useSelector(state => state.apps);
  const planFeatureSets = useSelector(state => state.planFeatureSets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApps({
      'filter[visibility]': 'private',
      include: 'event_subscriptions,event_destination'
    }));
    dispatch(fetchPlanFeatureSets());
  }, [dispatch]);

  const openCreateModal = () => {
    setShowCreateModal(true);
    trackEvent('App development - Create app');
  };

  const closeCreateModal = () => {
    if (creating) return;

    setShowCreateModal(false);
    trackEvent('App development create modal - Cancel');
  };

  const createApp = async () => {
    setCreating(true);

    trackEvent('App development create modal - Create app');

    try {
      const response = await axios.post('/api/v1/apps', {
        name: newAppName,
        context: 'store'
      });

      history.push(`/integrations/custom/${response?.data?.app?.slug}`);
    } catch (e) {
      dispatch(showToast(
        'Error creating app, please try again.',
        true
      ));

      trackEvent('Error: App development create modal - Create app');
    } finally {
      setCreating(false);
      setShowCreateModal(false);
      setNewAppName('');
    }
  };

  return (
    <LoadingPageWrapper loading={apps?.loading || planFeatureSets?.loading}>
      <Page
        title="Custom apps"
        backAction={{
          content: 'Back',
          url: '/integrations'
        }}
        primaryAction={{
          content: 'Create app',
          onAction: openCreateModal,
          disabled: planFeatureSets?.data?.pf_integrations === false
        }}
      >
        <Card padding="0">
          <UpgradeTrigger
            feature={'pf_integrations'}
            title={'Upgrade to build custom apps'}
            description={'You need to upgrade your plan to build custom apps'}
          >
            <ResourceList
              emptyState={
                <EmptyState
                  image={image}
                  heading="Share Junip webhooks with a custom app"
                  action={{
                    content: 'Create app',
                    onAction: openCreateModal
                  }}
                  secondaryAction={{
                    content: 'Learn more',
                    url: 'https://junip.co/docs/api/webhooks',
                    onClick: () => trackEvent('App development - Learn more')
                  }}
                >
                  <p>
                    Your app will be notified each time a review is submitted through Junip, making it easy to trigger follow-up actions.
                  </p>
                </EmptyState>
              }
              items={apps?.data || []}
              renderItem={({id, name, slug}) => {
                return (
                  <ResourceItem
                    id={id}
                    onClick={() => history.push(`/integrations/custom/${slug}`)}
                    media={<Thumbnail size="small" source={AppsIcon} />}
                    accessibilityLabel={`View details for ${name}`}
                    name={name}
                    verticalAlignment="center"
                  >
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {name}
                    </Text>
                  </ResourceItem>
                );
              }}
            />
          </UpgradeTrigger>
        </Card>
        <Modal
          open={showCreateModal}
          onClose={closeCreateModal}
          title="Create app"
          primaryAction={{
            content: 'Create app',
            loading: creating,
            disabled: !newAppName,
            onAction: createApp
          }}
          secondaryActions={[{
              content: 'Cancel',
              disabled: creating,
              onClick: closeCreateModal
            }
          ]}
        >
          <Modal.Section>
            <TextField
              label="App name"
              value={newAppName}
              onChange={setNewAppName}
              placeholder="My Junip App"
              autoFocus
            />
          </Modal.Section>
        </Modal>
      </Page>
    </LoadingPageWrapper>
  );
}
