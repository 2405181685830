import React, { useState, useCallback, useEffect, useMemo } from 'react';
import useLastLocation from 'utils/useLastLocation';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import Oauth from 'utils/oauth';
import { fetchStores } from 'redux/stores';
import { fetchDisplaySettings } from 'redux/displaySettings';
import { showToast } from 'redux/toast';
import { logout } from 'redux/user';
import axios from 'axios';
import { countries } from 'utils/countries';
import {
  Page,
  LegacyStack,
  Badge,
  Button,
  Link,
  LegacyCard,
  TextContainer,
  FooterHelp,
  FormLayout,
  Layout,
  Select,
  TextField,
  Banner,
  Thumbnail,
  Text,
} from "@shopify/polaris";

import LoadingContentWrapper from 'components/LoadingContentWrapper';

import shopifyIcon from 'assets/images/shopifyIcon.svg';

const MANUAL_INSTALL_URL = 'https://help.junip.co/en/articles/4606706-manually-installing-the-junip-snippets';

function StoreSettings(props) {
  const lastLocation = useLastLocation();
  const [connecting, setConnecting] = useState(false);
  const [newStoreUrl, setNewStoreUrl] = useState('');
  const [updatingStoreUrl, setUpdatingStoreUrl] = useState(false);
  const [updatingStoreAddress, setUpdatingStoreAddress] = useState(false);
  const {
    fetchStores,
    showToast,
    stores,
    logout
  } = props;
  const store = useMemo(() => stores?.data?.find?.(s => s.scoped === true) || {}, [stores?.data]);
  const appInstall = store?.app_install || {};
  const connected = appInstall.state === 'connected' ? true : false;
  const [storeCountry, setStoreCountry] = useState('');
  const [storeAddressLine1, setStoreAddressLine1] = useState('');
  const [storeAddressLine2, setStoreAddressLine2] = useState('');
  const [storeCity, setStoreCity] = useState('');
  const [storeState, setStoreState] = useState('');
  const [storeZip, setStoreZip] = useState('');
  const [addressChanged, setAddressChanged] = useState(false);

  useEffect(() => {
    if (stores?.data?.length === 0) {
      // If user not scoped to any stores, log them out
      logout();
    }
  }, [stores.data, logout]);

  useEffect(() => {
    if (store) {
      setStoreCountry(store.country);
      setStoreAddressLine1(store.address_line_1);
      setStoreAddressLine2(store.address_line_2);
      setStoreCity(store.city);
      setStoreState(store.state);
      setStoreZip(store.zip);
    }
  }, [store]);

  const getStores = useCallback(() => {
    fetchStores({ include: 'app_install' });
  }, [fetchStores]);

  const openUrl = (url) => {
    if (url) {
      if (url && url.indexOf('http') === -1) {
        url = `https://${url}`;
      }
      window.open(`${url}`, '_blank');
    }
  };

  const copyStoreKey = async () => {
    try {
      await navigator.clipboard.writeText(store.key);
      showToast('Store key copied');
      trackEvent('Store settings - Copy store key');
    } catch (e) {
      // error
    }
  };

  const connectStore = () => {
    setConnecting(true);
    Oauth({ action: '/api/v1/oauth/shopify', body: { shop: appInstall?.uid } });
    trackEvent('Store settings - Reconnect');
  };

  const updateStoreUrl = async () => {
    setUpdatingStoreUrl(true);
    try {
      await axios.put(`/api/v1/stores/${store.id}`, {
        url: newStoreUrl
      });
      getStores();
      showToast(`Your store's URL has been updated`)
    } catch(e) {
      showToast(`Error updating your store's URL, please try again`, true);
    } finally {
      setUpdatingStoreUrl(false);
    }
  }

  const updateStoreAddress = async () => {
    setUpdatingStoreAddress(true);
    try {
      await axios.put(`/api/v1/stores/${store.id}`, {
        address_override: true,
        address_line_1: storeAddressLine1,
        address_line_2: storeAddressLine2,
        country: storeCountry,
        city: storeCity,
        state: storeState,
        zip: storeZip,
      });
      getStores();
      showToast(`Your store's address has been updated`)
      trackEvent('Store Settings - Update address')
    } catch(e) {
      showToast(`Error updating your store's address, please try again`, true);
      trackEvent('Error: Store Settings - Update address')
    } finally {
      setUpdatingStoreAddress(false);
    }
  }

  let options = [{ label: '--Select a country--', value: '', disabled: true}]
  options = [...options, ...countries.map(country => {
    return { label: country.name, value: country.code }
  })];

  const handleStoreUrlChange = useCallback((value) => setNewStoreUrl(value.replace(/[^A-Za-z0-9\-.]/g, '')), [])
  const handleSelectChange = useCallback((value) => {
    setStoreCountry(value);
    setAddressChanged(true);
  }, []);
  const handleAddressLine1Change = useCallback((value) => {
    setStoreAddressLine1(value);
    setAddressChanged(true);
  }, []);
  const handleAddressLine2Change = useCallback((value) => {
    setStoreAddressLine2(value);
    setAddressChanged(true);
  }, []);
  const handleCityChange = useCallback((value) => {
    setStoreCity(value);
    setAddressChanged(true);
  }, []);
  const handleStateChange = useCallback((value) => {
    setStoreState(value);
    setAddressChanged(true);
  }, []);
  const handleZipChange = useCallback((value) => {
    setStoreZip(value);
    setAddressChanged(true);
  }, []);

  return (
    <Page
      title="Store settings"
      backAction={{content: 'Settings', url: lastLocation || '/settings'}}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Store health"
          description="View your store connection status"
        >
          <LoadingContentWrapper loading={stores.loading || !store.id} cards={1}>
            <LegacyCard sectioned>
              { !connected &&
                <div className="mb-4">
                  <Banner
                    action={{
                      onAction: connectStore,
                      content: 'Reconnect',
                      loading: connecting,
                      disabled: connected
                    }}
                    tone="warning"
                  >
                    You're store is not connected with Junip. Click the button below to reconnect your store
                  </Banner>
                </div>
              }
              <div className="mb-4">
                <LegacyStack wrap={false} alignment="center">
                  <LegacyStack.Item fill>
                    <LegacyStack wrap={false} alignment="center">
                      { appInstall?.provider === 'shopify' &&
                        <Thumbnail
                          source={shopifyIcon}
                          size="small"
                          alt="Shopify icon"
                        />
                      }
                      <Text variant="headingMd" as="h2">{store.name}</Text>
                    </LegacyStack>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <Badge tone={connected ? 'success' : 'warning'}>
                      <span style={{textTransform: 'capitalize'}}>{appInstall.state}</span>
                    </Badge>
                  </LegacyStack.Item>
                </LegacyStack>
              </div>
              <TextContainer spacing="tight">
                <p>Provider: <span style={{textTransform: 'capitalize'}}>{appInstall.provider}</span></p>
                <p>URL:{' '}
                  <Link external="true" onClick={() => openUrl(store.url)}>
                    {store.url}
                  </Link>
                </p>
                <TextField
                  readOnly
                  label="Store key:"
                  value={store.key}
                  onFocus={(e) => e.target.select()}
                  connectedRight={
                    <Button
                      size="large"
                      onClick={copyStoreKey}
                    >
                      Copy
                    </Button>
                  }
                />
              </TextContainer>
            </LegacyCard>
          </LoadingContentWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Store URL"
          description="If your store URL is different than what is displayed above, you can change it here."
        >
          <LoadingContentWrapper loading={stores.loading || !store.id} cards={1}>
            <LegacyCard sectioned>
              <FormLayout>
                <TextField
                  type="text"
                  label="Edit your store URL"
                  placeholder={store.url}
                  prefix="https://"
                  value={newStoreUrl}
                  onChange={handleStoreUrlChange}
                />

                <Button

                  disabled={!newStoreUrl}
                  onClick={updateStoreUrl}
                  loading={updatingStoreUrl}
                  variant="primary">
                  Save
               </Button>
             </FormLayout>
            </LegacyCard>
          </LoadingContentWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Physical Address"
          description="This address is displayed in the footer of your emails."
        >
          <LoadingContentWrapper loading={stores.loading || !store.id} cards={1}>
            <LegacyCard sectioned>
              <FormLayout>
                <TextField
                  value={storeAddressLine1}
                  onChange={handleAddressLine1Change}
                  label="Address"
                  type="text"
                  placeholder="Address"
                />
                <TextField
                  value={storeAddressLine2}
                  onChange={handleAddressLine2Change}
                  label="Apartment, suite. etc"
                  type="text"
                  placeholder="Apartment, suite. etc"
                />
                <TextField
                  value={storeCity}
                  onChange={handleCityChange}
                  label="City"
                  type="text"
                  placeholder="City"
                />
                <FormLayout.Group condensed>
                  <Select
                    label="Country"
                    options={options}
                    onChange={handleSelectChange}
                    value={storeCountry}
                  />
                  <TextField
                    value={storeState}
                    onChange={handleStateChange}
                    label="Province/State"
                    type="text"
                    placeholder="Province"
                  />
                  <TextField
                    value={storeZip}
                    onChange={handleZipChange}
                    label="Postal Code/Zip"
                    type="text"
                    placeholder="Postal Code"
                  />
                </FormLayout.Group>
                <Button

                  disabled={!addressChanged}
                  onClick={updateStoreAddress}
                  loading={updatingStoreAddress}
                  variant="primary">
                  Save
                </Button>
              </FormLayout>
            </LegacyCard>
          </LoadingContentWrapper>
        </Layout.AnnotatedSection>
      </Layout>
      <FooterHelp>
        Having trouble with your Junip snippet installation? Check out this{' '}
        <Link external onClick={() => openUrl(MANUAL_INSTALL_URL)}>
          doc
        </Link>
        {' '}on how to manually add the snippets.
      </FooterHelp>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  displaySettings: state.displaySettings,
  settings: state.settings
});

const mapDispatchToProps = (dispatch) => ({
  fetchStores: (params) => dispatch(fetchStores(params)),
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  showToast: (message, error) => dispatch(showToast(message, error)),
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreSettings);
