import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGoogleReviewSettings } from 'redux/googleReviewSettings';
import { fetchMetaReviewSettings } from 'redux/metaReviewSettings';
import { fetchSyndicationSettings } from 'redux/syndicationSettings';
import {
  Banner,
  BlockStack,
  ButtonGroup,
  Button,
  Card,
  Divider,
  Page,
  InlineStack,
  Text,
  Badge
} from '@shopify/polaris';
import LoadingWrapper from 'components/LoadingWrapper';
import contactSupport from 'utils/contactSupport';

import googleShopping from 'assets/images/syndication/googleShopping.png';
import meta from 'assets/images/syndication/meta.png';
import UpgradeTrigger from 'components/UpgradeTrigger';

export default function Syndication() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGoogleReviewSettings());
    dispatch(fetchMetaReviewSettings());
    dispatch(fetchSyndicationSettings());
  }, [dispatch]);

  return (
    <Page
      title="Syndication"
      subtitle="Share product reviews across sales channels"
    >
      <BlockStack gap="300">
        <Banner
          title="Ensure your product catalog is complete and up to date"
          tone="info"
        >
          <BlockStack gap="200">
            <p>Your catalog defines your products so that matches can be accurately identified on other channels.</p>
            <ButtonGroup>
              <Button
                url="/products/catalog-health"
              >
                Manage Catalog
              </Button>
              <Button
                variant="monochromePlain"
                url="https://help.junip.co/en/articles/8160833-editing-your-product-catalog-in-junip"
                external
              >
                Learn more
              </Button>
            </ButtonGroup>
          </BlockStack>
        </Banner>
        <Divider />
        <UpgradeTrigger
          feature={'pf_google_shopping'}
          title={'Upgrade to use Google Shopping syndication'}
          description={'You need to upgrade your plan to use Google Shopping syndication'}
        >
          <GoogleShoppingCard />
          <MetaCard />
        </UpgradeTrigger>
        <UpgradeTrigger
          feature={'pf_syndication'}
          title="Upgrade to enable multi-store syndication"
          description="You need to upgrade your plan to enable multi-store syndication"
        >
          <MultiStoreCards />
        </UpgradeTrigger>
      </BlockStack>
    </Page>
  );
}

function GoogleShoppingCard() {
  const googleReviewSettings = useSelector(state => state.googleReviewSettings);
  const { product_feed_enabled } = googleReviewSettings.data || {};
  return (
    <Card>
      <LoadingWrapper loading={googleReviewSettings.loading} sections={1}>
        <BlockStack gap="200">
          <InlineStack align="space-between" >
            <InlineStack blockAlign="center" gap="100">
              <Text variant="headingSm">Google Shopping</Text>
              <img src={googleShopping} alt="Google Shopping" height="20px" width="20px" />
              <Badge tone={product_feed_enabled ? 'success' : 'default'}>
                {product_feed_enabled ? 'On' : 'Off'}
              </Badge>
            </InlineStack>
            <Button url="/syndication/google-shopping">
              Manage
            </Button>
          </InlineStack>
          <Text tone="subdued" variant="bodyMd">
            Send reviews to your Google Shopping feed.
          </Text>
        </BlockStack>
      </LoadingWrapper>
    </Card>
  );
}

function MetaCard() {
  const metaReviewSettings = useSelector(state => state.metaReviewSettings);
  const { product_feed_enabled } = metaReviewSettings.data || {};

  return (
    <Card>
      <LoadingWrapper loading={metaReviewSettings.loading} sections={1}>
        <BlockStack gap="200">
          <InlineStack align="space-between" >
            <InlineStack blockAlign="center" gap="100">
              <Text variant="headingSm">Meta</Text>
              <img src={meta} alt="Meta" height="20px" width="20px" />
              <Badge tone={product_feed_enabled ? 'success' : 'default'}>
                {product_feed_enabled ? 'On' : 'Off'}
              </Badge>
            </InlineStack>
            <Button url="/syndication/meta">
              Manage
            </Button>
          </InlineStack>
          <Text tone="subdued" variant="bodyMd">
            Share reviews on Instagram and Facebook Shops.
          </Text>
        </BlockStack>
      </LoadingWrapper>
    </Card>
  );
}

function MultiStoreCards() {
  const syndicationSettings = useSelector(state => state.syndicationSettings);
  const stores = useSelector(state => state.stores);
  const organizations = useSelector(state => state.organizations);

  const { batch_running, enabled, network } = syndicationSettings.data || {};

  const globallySyndicating = network === "global";
  const canLocallySyndicate = stores?.data?.length > 1;
  const scopedStore = stores?.data?.find(s => s.scoped === true);
  const syndicateStores = stores.data?.filter(s => s.should_syndicate === true);
  const syndicateStoresCount = syndicateStores?.length || 0;
  const twoPointOh = organizations?.data?.find(o => o.scoped === true)?.two_point_oh;

  const render = (() => {
    let badge = <Badge tone="default">Off</Badge>;
    let banner = null;
    let buttonEnabled = true;

    if (batch_running) {
      badge = <Badge tone="info">Applying changes</Badge>;
      banner = <Banner>We're applying your syndication setting changes across your stores, this may take a while depending on your volume of reviews.</Banner>;
    }

    if (enabled) {
      badge = <Badge tone="success">On</Badge>;

      if (scopedStore?.should_syndicate === false) {
        banner = <Banner tone="warning">Only active on remote stores.</Banner>;
      }

      if (syndicateStoresCount === 0) {
        badge = <Badge tone="warning">Action required</Badge>;
        banner = <Banner tone="warning">You must select at least one store to syndicate reviews.</Banner>;
      }
    }

    if (!enabled) {
      badge = <Badge tone="default">Off</Badge>;
    }

    if (!canLocallySyndicate) {
      banner = <Banner>There are no other stores in your organization. To syndicate reviews, <Button variant="monochromePlain" onClick={() => window.location.hash = 'connectStore'}>connect another store</Button> to this account.</Banner>
      buttonEnabled = false;
    }

    if (globallySyndicating) {
      badge = <Badge tone="default">Unavailable</Badge>;
      banner = <Banner tone="info">Your store is using Global Syndication to share reviews. To manage your settings, <Button variant="monochromePlain" onClick={contactSupport}>contact support</Button>.</Banner>;
      buttonEnabled = false;
    }

    return { badge, banner, buttonEnabled };
  })();

  return (
    <BlockStack gap="300">
      <Card>
        <LoadingWrapper loading={syndicationSettings.loading} sections={1}>
          <BlockStack gap="200">
            <InlineStack align="space-between">
              <InlineStack blockAlign="center" gap="100">
                <Text variant="headingSm">Multi-store</Text>
                {render.badge}
              </InlineStack>
              <Button url="/syndication/multi-store" disabled={!render.buttonEnabled}>
                Manage
              </Button>
            </InlineStack>
            <Text tone="subdued" variant="bodyMd">
              Share product reviews across other stores in your organization.
            </Text>
            {render.banner}
          </BlockStack>
        </LoadingWrapper>
      </Card>
      {!twoPointOh && globallySyndicating &&
        <Card>
          <LoadingWrapper loading={syndicationSettings.loading} sections={1}>
            <BlockStack gap="200">
              <InlineStack blockAlign="center" gap="100">
                <Text variant="headingSm">Global syndication (Beta)</Text>
                <Badge tone="success">
                  On
                </Badge>
              </InlineStack>
              <Text tone="subdued" variant="bodyMd">
                Share reviews with other brands and retailers in Junip’s network.
              </Text>
              <Banner tone="success">
                <p>
                  Beta version enabled. To manage your settings, <Button variant="monochromePlain" onClick={contactSupport}>contact support</Button>.
                </p>
              </Banner>
            </BlockStack>
          </LoadingWrapper>
        </Card>
      }
    </BlockStack>
  )
}
