const RGB_MAX = 255
const HUE_MAX = 360
const SV_MAX = 100

function _normalizeAngle (degrees) {
  return (degrees % 360 + 360) % 360;
}

export function rgbToHex(r, g, b) {
  if (typeof r === 'object') {
    const args = r;
    r = args.r; g = args.g; b = args.b;
  }
  r = Math.round(r).toString(16);
  g = Math.round(g).toString(16);
  b = Math.round(b).toString(16);

  r = r.length === 1 ? '0' + r : r;
  g = g.length === 1 ? '0' + g : g;
  b = b.length === 1 ? '0' + b : b;

  return '#' + r + g + b;
}

export function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : {};
}

export function rgbToHsv(r, g, b) {
  if (typeof r === 'object') {
    const args = r;
    r = args.r; g = args.g; b = args.b;
  }

  // It converts [0,255] format, to [0,1]
  r = (r === RGB_MAX) ? 1 : (r % RGB_MAX / parseFloat(RGB_MAX));
  g = (g === RGB_MAX) ? 1 : (g % RGB_MAX / parseFloat(RGB_MAX));
  b = (b === RGB_MAX) ? 1 : (b % RGB_MAX / parseFloat(RGB_MAX));

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h, s, v = max;

  let d = max - min;

  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  return {
    h: Math.round(h * HUE_MAX),
    s: Math.round(s * SV_MAX),
    v: Math.round(v * SV_MAX)
  };
}

export function hsvToRgb(h, s, v) {
  if (typeof h === 'object') {
    const args = h;
    h = args.h; s = args.s; v = args.v;
  }

  h = _normalizeAngle(h);
  h = (h === HUE_MAX) ? 1 : (h % HUE_MAX / parseFloat(HUE_MAX) * 6);
  s = (s === SV_MAX) ? 1 : (s % SV_MAX / parseFloat(SV_MAX));
  v = (v === SV_MAX) ? 1 : (v % SV_MAX / parseFloat(SV_MAX));

  let i = Math.floor(h);
  let f = h - i;
  let p = v * (1 - s);
  let q = v * (1 - f * s);
  let t = v * (1 - (1 - f) * s);
  let mod = i % 6;
  let r = [v, q, p, p, t, v][mod];
  let g = [t, v, v, q, p, p][mod];
  let b = [p, p, t, v, v, q][mod];

  return {
    r: Math.floor(r * RGB_MAX),
    g: Math.floor(g * RGB_MAX),
    b: Math.floor(b * RGB_MAX),
  };
}

export function hexToHsv(hex) {
  let {r, g, b} = hexToRgb(hex);
  return rgbToHsv(r, g, b);
}

export function hsvToHex(h, s, v) {
  let {r, g, b} = hsvToRgb(h, s, v);
  return rgbToHex(r, g, b);
}
