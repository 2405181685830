import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { changesSaved } from 'redux/settings';

// Actions
const FETCH_SYNDICATION_SETTINGS_BEGIN = 'FETCH_SYNDICATION_SETTINGS_BEGIN';
const FETCH_SYNDICATION_SETTINGS_SUCCESS = 'FETCH_SYNDICATION_SETTINGS_SUCCESS';
const FETCH_SYNDICATION_SETTINGS_ERROR = 'FETCH_SYNDICATION_SETTINGS_ERROR';

// Action Creators
export function fetchSyndicationSettings() {
  return async dispatch => {
    dispatch(fetchSyndicationSettingsBegin());
    try {
      const response = await axios.get('/api/v1/syndication_settings');
      dispatch(fetchSyndicationSettingsSuccess(response.data.syndication_settings[0]));
    } catch (e) {
      dispatch(fetchSyndicationSettingsError(e));
    }
  }
}

export function saveSyndicationSettings(id, settings) {
  return async dispatch => {
    try {
      const response = await axios.put(
        `/api/v1/syndication_settings/${id}`,
        {
          syndication_setting: settings
        }
      );
      dispatch(fetchSyndicationSettingsSuccess(response.data.syndication_setting));
      dispatch(changesSaved());
      trackEvent('Syndication settings - Save changes');
    } catch (e) {
      dispatch(fetchSyndicationSettingsError(e));
      dispatch(changesSaved(true));
      trackEvent('Error: Syndication settings - Save changes', { statusCode: e?.response?.status });
    }
  }
}

export const fetchSyndicationSettingsBegin = () => ({
  type: FETCH_SYNDICATION_SETTINGS_BEGIN
});

export const fetchSyndicationSettingsSuccess = settings => ({
  type: FETCH_SYNDICATION_SETTINGS_SUCCESS,
  data: { settings }
});

export const fetchSyndicationSettingsError = error => ({
  type: FETCH_SYNDICATION_SETTINGS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SYNDICATION_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SYNDICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.data.settings,
        loading: false,
        error: null
      };
    case FETCH_SYNDICATION_SETTINGS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
