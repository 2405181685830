import WidgetCardWrapper from "./WidgetCardWrapper";

import page from 'assets/images/widgets/page-widget.svg';

export default function DedicatedReviewsPageWidgetWrapper({ children }) {
  return (
    <WidgetCardWrapper
      image={page}
      imageAlt="Dedicated reviews page"
      title="Dedicated reviews page"
      description="Showcase all of your product and store reviews on one page."
      children={children}
    />
  );
}
