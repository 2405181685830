import { useState } from 'react';
import { TextField, Button, BlockStack, ButtonGroup } from "@shopify/polaris";

export default function CopyableSnippet({ snippet }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(snippet);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <TextField
      readOnly={true}
      onFocus={(e) => e.target.select()}
      value={snippet}
      connectedRight={
        <Button
          size="large"
          onClick={copyToClipboard}
        >
          {copied ? 'Copied!' : 'Copy'}
        </Button>
      }
    />
  );
}

export function CopyableMultiLineSnippet({ snippet, secondaryAction = null, multiline = 5 }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(snippet);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <BlockStack gap="400">
      <TextField
        readOnly={true}
        onFocus={(e) => e.target.select()}
        value={snippet}
        multiline={multiline}
      />
      <ButtonGroup>
        <Button
          size="large"
          onClick={copyToClipboard}
        >
          {copied ? 'Copied!' : 'Copy'}
        </Button>
        {secondaryAction}
      </ButtonGroup>
    </BlockStack>
  );
}
