import user from 'redux/user';
import planFeatureSets from 'redux/planFeatureSets';
import rolloutFlagSets from 'redux/rolloutFlagSets';
import reviews from 'redux/reviews';
import analytics from 'redux/analytics';
import eventTopics from 'redux/eventTopics';
import billingPlans from 'redux/billingPlans';
import billingSubscriptions from 'redux/billingSubscriptions';
import settings from 'redux/settings';
import products from 'redux/products';
import productCategories from 'redux/productCategories';
import productGroups from 'redux/productGroups';
import messages from 'redux/messages';
import metaReviewSettings from 'redux/metaReviewSettings';
import flows from 'redux/flows';
import incentives from 'redux/incentives';
import incentiveTypes from 'redux/incentiveTypes';
import displaySettings from 'redux/displaySettings';
import moderationSettings from 'redux/moderationSettings';
import solicitationCampaigns from 'redux/solicitationCampaigns';
import notificationSettings from 'redux/notificationSettings';
import googleReviewSettings from 'redux/googleReviewSettings';
import syndicationSettings from 'redux/syndicationSettings';
import productVariantsAggregate from 'redux/productVariants';
import productVariantsForProduct from 'redux/productVariantsForProduct';
import productReviewsAggregate from 'redux/productReviews';
import productIdentifierImports from 'redux/productIdentifierImports';
import googleReviewSnapshotStats from 'redux/googleReviewSnapshotStats';
import apps from 'redux/apps';
import appInstalls from 'redux/appInstalls';
import stores from 'redux/stores';
import storeSyncs from 'redux/storeSyncs';
import storeThemes from 'redux/storeThemes';
import stripeRecords from 'redux/stripeRecords';
import surveyQuestions from 'redux/surveyQuestions';
import surveyComponents from 'redux/surveyComponents';
import invitations from 'redux/invitations';
import organizations from 'redux/organizations';
import memberships from 'redux/memberships';
import shippingLocations from 'redux/shippingLocations';
import referralRecords from 'redux/referralRecords';
import toast from 'redux/toast';
import starOptions from 'redux/starOptions';
import slice from 'redux/slice';
import reviewTags from 'redux/reviewTags';
import reviewTagLinks from 'redux/reviewTagLinks';

import { combineReducers } from 'redux';

const appReducer = combineReducers({
  user,
  planFeatureSets,
  rolloutFlagSets,
  reviews,
  analytics,
  eventTopics,
  billingPlans,
  billingSubscriptions,
  settings,
  products,
  productCategories,
  productGroups,
  invitations,
  organizations,
  memberships,
  messages,
  metaReviewSettings,
  flows,
  incentives,
  incentiveTypes,
  displaySettings,
  moderationSettings,
  googleReviewSettings,
  syndicationSettings,
  productVariantsAggregate,
  productVariantsForProduct,
  productReviewsAggregate,
  productIdentifierImports,
  googleReviewSnapshotStats,
  solicitationCampaigns,
  notificationSettings,
  shippingLocations,
  apps,
  appInstalls,
  stores,
  storeSyncs,
  storeThemes,
  stripeRecords,
  surveyQuestions,
  surveyComponents,
  referralRecords,
  toast,
  starOptions,
  slice,
  reviewTags,
  reviewTagLinks,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action)
}

export default rootReducer;
