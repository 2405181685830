import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LastLocationContext from './LastLocationContext';
import { hasBeenPrevented, prevent, shouldPrevent } from './prevent';

let lastLocation = null;

const updateLastLocation = ({ location, nextLocation, watchOnlyPathname }) => {
  if (location === null) {
    return;
  }

  if (nextLocation === location) {
    return;
  }

  if (watchOnlyPathname && location.pathname === nextLocation.pathname) {
    return;
  }

  if (shouldPrevent(nextLocation) && !hasBeenPrevented(nextLocation)) {
    prevent(nextLocation);
    return;
  }

  lastLocation = { ...location };
};

class LastLocationProvider extends React.Component {
  static propTypes = {
    watchOnlyPathname: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    watchOnlyPathname: false,
  };

  static getDerivedStateFromProps(props, state) {
    updateLastLocation({
      location: state.currentLocation,
      nextLocation: props.location,
      watchOnlyPathname: props.watchOnlyPathname,
    });

    return {
      currentLocation: props.location,
    };
  }

  state = {
    currentLocation: null,
  };

  render() {
    const { children } = this.props;

    return (
      <LastLocationContext.Provider value={lastLocation}>{children}</LastLocationContext.Provider>
    );
  }
}

export const getLastLocation = () => lastLocation;

export const setLastLocation = (nextLastLocation) => {
  lastLocation = nextLastLocation;
};

export default withRouter(LastLocationProvider);
