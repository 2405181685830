import React from "react";
import { trackEvent } from "utils/Mixpanel";
import { connect } from "react-redux";
import { Banner } from "@shopify/polaris";

const STORES = [
  1072,
  1276,
  4644,
  5068,
  5536,
  6092,
  6201,
  6611,
];

function MigrateEventsBanner({ stores }) {
  const title = "NOTICE: You have been moved to our new event system, any active integrations may need to be updated";
  const body =
    "Junip has a new events system (the system used to power our review requests) & has retired our old system. Old events are no longer processing data. We tried a number of times through many channels to get in contact to facilitate your transition & were unable to reach you team. Please contact support (in-app or support@juniphq.com) if you would like help ensuring your review requests are set up correctly on the new system, we'd be happy to help.";
  const scopedStore = stores?.data?.find((s) => s.scoped === true);

  const contactSupport = () => {
    if (window.Intercom) {
      window.Intercom("show");
    } else {
      window.open("mailto:support@juniphq.com", "_blank");
    }

    trackEvent("Migrate to new events banner - Contact support ");
  };

  return <>
    {STORES.includes(scopedStore?.id) && (
      <div className="mb-4">
        <Banner
          title={title}
          action={{
            content: "Contact support",
            onAction: contactSupport,
          }}
          tone="critical"
        >
          <p>{body}</p>
        </Banner>
      </div>
    )}
  </>;
}

const mapStateToProps = (state) => ({
  stores: state.stores,
});

export default connect(mapStateToProps)(MigrateEventsBanner);
