import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  DataTable,
  Badge,
  Link,
  EmptyState,
  Pagination,
  Spinner,
  Tooltip,
  Tag
} from '@shopify/polaris';

import noMessages from 'assets/images/noMessages.svg';

function MessagesTable({ handleTabChange, showToast }) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [meta, setMeta] = useState(null);

  const renderBadges = (message) => {
    let statusBadge = (
      <Tooltip content="This message has been sent to our ESP and will be delivered within the next 24 hours">
        <Badge progress="incomplete">Pending delivery</Badge>
      </Tooltip>
    );
    let bouncedBadge = null;
    let droppedBadge = null;
    let reportedBadge = null;
    let testBadge = null;

    if (message.clicked) {
      statusBadge = <Badge tone="success" progress="complete">Clicked</Badge>;
    } else if (message.opened) {
      statusBadge = <Badge tone="success" progress="partiallyComplete">Opened</Badge>;
    } else if (message.delivered) {
      statusBadge = <Badge tone="success" progress="incomplete">Delivered</Badge>;
    }

    if (message.bounced) {
      bouncedBadge = <Badge tone="attention">Bounced</Badge>;
      statusBadge = null;
    }
    if (message.dropped) {
      droppedBadge = <Badge tone="attention">Dropped</Badge>;
      statusBadge = null;
    }
    if (message.reported) {
      reportedBadge = <Badge tone="warning">Reported</Badge>;
    }
    if (message.test) {
      testBadge = <Badge tone="info">Test</Badge>;
    }

    return (
      <>
        {statusBadge}
        {bouncedBadge}
        {droppedBadge}
        {reportedBadge}
        {testBadge}
      </>
    );
  };

  const parseData = useCallback((data) => {
    setMeta(data.meta);
    let newRows = [];
    for (let message of data.messages) {
      let row = [];
      row.push(moment(message.created_at).format('MMM D Y, h:mm a'));
      row.push(
        <Tag>
          <Link url={`/flows/templates/${message.template_id}`} monochrome>
            {message.message_type.name}
          </Link>
        </Tag>
      );
      row.push(message.target);
      row.push(renderBadges(message));
      newRows.push(row);
    }
    setRows(newRows);
  }, []);

  const fetchData = useCallback(async ({ before = null, after = null }) => {
    setLoading(true);
    try {
      const response = await axios.get('/api/v1/messages', {
        params: {
          include: 'message_type',
          'page[after]': after,
          'page[before]': before
        }
      });
      parseData(response.data);
    } catch (e) {
      showToast('Error fetching message analytics, please try again.', true);
    }
    setLoading(false);
  }, [parseData, showToast]);

  const nextPage = (after) => {
    fetchData({ after });
  };

  const previousPage = (before) => {
    fetchData({ before });
  };

  useEffect(() => {
    fetchData({});
  }, [fetchData]);

  return (
    <>
      {loading &&
        <div className="text-center" style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
          <Spinner />
        </div>
      }
      {!loading && rows.length > 0 &&
        <DataTable
          verticalAlign="middle"
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Date',
            'Template',
            'Recipient',
            'Status',
          ]}
          rows={rows}
          footerContent={
            <Pagination
              hasPrevious={meta?.page?.before !== null}
              hasNext={meta?.page?.after !== null}
              onPrevious={() => previousPage(meta?.page?.before)}
              onNext={() => nextPage(meta?.page?.after)}
            />
          }
        />
      }
      {!loading && rows.length === 0 &&
        <EmptyState
          heading="No sent messages"
          image={noMessages}
          action={{ content: 'View queue', onAction: () => handleTabChange(0) }}
        >
          <p>
            There are no sent messages to display at the moment, please check back later.
            In the meantime, view your queue of messages intended to go out
          </p>
        </EmptyState>
      }
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(MessagesTable);
