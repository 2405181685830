import SparkMD5 from 'spark-md5';

// TODO move to service worker
// TODO use string slice instead of Blob.prototype.
export default async function fileChecksum(file) {
  return new Promise((resolve, reject) => {
    let chunkSize = 64 * 1024;
    let chunks = Math.ceil(file.size / chunkSize);
    let spark = new SparkMD5.ArrayBuffer();
    let currentChunk = 0;

    let fileReader = new FileReader();

    fileReader.onload = (e) => {
      let arrayBuffer = e.target.result;
      spark.append(arrayBuffer);
      if (currentChunk === chunks) {
        resolve({checksum: btoa(spark.end(true))});
      } else {
        let start = currentChunk * chunkSize;
        let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(File.prototype.slice.call(file, start, end));
        currentChunk++;
      }
    };

    fileReader.readAsArrayBuffer(File.prototype.slice.call(file, currentChunk + chunkSize, chunkSize));
  });
}
