import React, { useState } from 'react';

import {
  LegacyCard,
  Form,
  FormLayout,
  TextField,
  Button
} from '@shopify/polaris';

import LoginWrapper from 'components/LoginWrapper';

import axios from 'axios';

const CreateOrganization = (props) => {
  if (!props.location.state || !props.location.state.user) {
    props.history.replace('/login');
  }

  let params = new URLSearchParams(window.location.search);
  let referrer = params.get('referrer') || null;

  const [inputs, setInputs] = useState({});
  const [isOrgSubmitLoading, setIsOrgSubmitLoading] = useState(false);
  const handleInputChange = (field) => {
    return (value) => setInputs(inputs => ({...inputs, [field]: value}));;
  };

  const queryParams = props.history.location.search;

  const isValid = () => {
    if (!inputs.organization) {
      return false;
    }

    return true;
  };

  const handleOrgSubmit = async (event) => {
    if (!isValid()) {
      return;
    }

    setIsOrgSubmitLoading(true);
    try {
      await axios.post(
        '/api/v1/organizations',
        {
          organization: {
            name: inputs.organization,
            referrer: referrer
          }
        }
      );

      setIsOrgSubmitLoading(false);
      props.history.push('/login/connect_store' + queryParams, { user: props.location.state.user })
    } catch(e) {
      // TODO show toast for invalid org
      // Show error message if network error
      setIsOrgSubmitLoading(false);
    }
  };
  return (
    <LoginWrapper>
      <LegacyCard sectioned>
        <Form onSubmit={handleOrgSubmit}>
          <FormLayout>
            <TextField
              value={inputs.organization}
              name="organization"
              onChange={handleInputChange('organization')}
              label="What's the name of your business?"
              type="text"
              placeholder="Enter your organization name"
              autoFocus={true}
              disabled={isOrgSubmitLoading}
            />
            <Button
              disabled={!isValid()}
              size="large"

              submit
              loading={isOrgSubmitLoading}
              variant="primary">Submit</Button>
          </FormLayout>
        </Form>
      </LegacyCard>
    </LoginWrapper>
  );
};

export default CreateOrganization;
