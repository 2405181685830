import { useState } from 'react';
import { Button, Card, Collapsible, Box, BlockStack } from "@shopify/polaris";

export default function InstallStepWrapper({ children }) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <BlockStack gap="400">
      <Box>
        <Button
          variant="plain"
          onClick={() => setCollapsed(!collapsed)}
          disclosure={collapsed ? 'up' : 'down'}
        >
          Install
        </Button>
      </Box>
      <Collapsible open={!collapsed}>
        <Card>
          {children}
        </Card>
      </Collapsible>
    </BlockStack>
  );
}
