import { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

export default function Mantle({ location, user }) {
  const [initialized, setInitialized] = useState(false);
  const currentUser = useSelector(state => state.user);
  const stores = useSelector(state => state.stores);
  const scopedStore = stores?.data?.find((s) => s.scoped === true);

  useEffect(() => {
    if (!initialized && window?.mantleTrack && scopedStore?.id && currentUser?.data?.id) {
      window.mantleTrack.startTracking({
        customerId: scopedStore?.app_install?.uid,
        properties: {
          user_id: scopedStore?.id,
          email: currentUser?.data?.email,
          organization_id: scopedStore?.organization_id,
        }
      });

      setInitialized(true);
    }
  }, [scopedStore, currentUser, initialized]);

  return (
    null
  );
}
