import React, { useRef, useState } from 'react';
import Handlebars from 'handlebars/dist/handlebars.min.js';
import Frame from 'react-frame-component';

const EmailPreview = (props) => {
  const [iframeHeight, setIframeHeight] = useState('100%');
  const contentRef = useRef();
  const { template, blocks, inputs, customHtmlEnabled, customHtmlTemplate } = props;
  if (!template || blocks.length === 0) {
    return (
      <h1>Loading...</h1>
    );
  }

  const renderHeight = () => {
    setTimeout(() => {
      setIframeHeight(`${contentRef?.current?.ownerDocument?.body?.offsetHeight + 5}px`);
    }, 500);
  };

  const isValidHandlebars = (str) => {
    return !str.split('').reduce((uptoPrevChar, thisChar) => {
      if (thisChar === '{') {
        return ++uptoPrevChar;
      } else if (thisChar === '}') {
        return --uptoPrevChar;
      }

      return uptoPrevChar
    }, 0);
  }
  const markup = customHtmlEnabled ? customHtmlTemplate : template.default_template;
  const templateMarkup = Handlebars.compile(markup);
  let data = template.example_context;

  for (let block of blocks) {
    let value = inputs[block.id];
    if (!value || value === '') {
      value = block.default_template;
    }

    if (isValidHandlebars(value)) {
      try {
        let compiledValue = Handlebars.compile(value);
        data[block.name] = compiledValue(template.example_context);
      } catch {
        data[block.name] = value;
      }
    } else {
      data[block.name] = value;
    }
  }

  const evaluateTemplate = () => {
    try {
      return templateMarkup(data);
    } catch(e) {
      let encodedError = e.message.replace(/[\u00A0-\u9999<>&]/gim, function(i) {
        return '&#' + i.charCodeAt(0) + ';';
      });
      return `<pre>${encodedError}</pre>`;
    }
  };

  return (
    <div className="email-preview-container">
      <Frame
        style={{height: iframeHeight}}
        className="email-preview-frame"
        contentDidMount={renderHeight}
        contentDidUpdate={renderHeight}
      >
        <div ref={contentRef} className="container" style={{pointerEvents: 'none'}} dangerouslySetInnerHTML={{ __html: evaluateTemplate() }} />
      </Frame>
    </div>
  );
};

export default EmailPreview;
