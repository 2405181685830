export default function flowDetails(flow) {
  const name = ((flow) => {
    return `${flow?.event_topic?.subject}/${flow?.event_topic?.action}`;
  })(flow);

  const flowDescriptions = () => {
    switch (name) {
      case 'order/fulfilled':
      case 'review/request':
        return {
          title: 'Post-purchase review request',
          subtitle: 'Ask your customers for a review after they make a purchase',
        };
      case 'review/submitted':
        return {
          title: 'Post-review actions',
          subtitle: 'Take actions after a review is submitted.',
        };
      case 'incentive/rewarded':
        return {
          subtitle: 'Send customers a reward when they complete a review',
        };
      case 'review/created':
        return {
          title: 'Review created notification',
          subtitle: 'Send an email to a customer to confirm their review',
        };
      case 'review/confirmation-required':
        return {
          subtitle: 'Send an email to a customer to confirm their review',
        };
      case 'response/created':
        return {
          subtitle: 'Send an email to a customer with a public review response',
        };
      default:
        return {};
    }
  };

  return {
    name,
    ...flowDescriptions(),
  };
}
