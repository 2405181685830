import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Avatar,
  Banner,
  Pagination,
  Spinner,
  LegacyStack,
  LegacyCard,
  TextContainer
} from '@shopify/polaris';

import { RefreshIcon } from "@shopify/polaris-icons";

import noImage from 'assets/images/noImage.jpg';

export default function MatchedProducts({ questionId }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);

  const fetchData = useCallback(async ({ before = null, after = null }) => {
    setLoading(true);
    try {
      setLoading(true);

      const response = await axios.get('/api/v1/survey_components', {
        params: {
          'filter[question_id]': questionId,
          include: 'product,product.image',
          'page[after]': after,
          'page[before]': before
        }
      });

      setData(response.data?.survey_components?.map(s => s.product));
      setMeta(response.data?.meta);
    } catch (e) {
      // No - op
    } finally {
      setLoading(false);
    }
  }, [questionId]);

  useEffect(() => {
    if (questionId) {
      fetchData({});
    }
  }, [fetchData, questionId]);

  return (
    <LegacyCard
      title="Matched products"
      actions={[{
        content: 'Refresh',
        onAction: () => fetchData({}),
        disabled: loading,
        icon: RefreshIcon
      }]}>
      <LegacyCard.Section>
        <Banner tone="default">If you've recently added conditions, it may take a few minutes for this list to update.</Banner>
      </LegacyCard.Section>
      <LegacyCard.Section>
        {loading ?
          <div className="text-center" style={{ paddingTop: '2.5rem', paddingBottom: '4rem' }}>
            <Spinner />
          </div>
        :
          <>
            { data?.length ?
              <TextContainer>
                {data.map((product) =>
                  <LegacyStack key={product.id} alignment="center">
                    <Avatar size="sm" source={product.image?.url_200x200 || noImage} />
                    <p>{product.title}</p>
                  </LegacyStack>
                )}
              </TextContainer>
            :
              <p>No matched products</p>
            }
          </>
        }
      </LegacyCard.Section>
      <LegacyCard.Section>
        <Pagination
          hasPrevious={meta?.page?.before !== null && !loading}
          hasNext={meta?.page?.after !== null && !loading}
          onPrevious={() => fetchData({ before: meta?.page?.before })}
          onNext={() => fetchData({ after: meta?.page?.after })}
        />
      </LegacyCard.Section>
    </LegacyCard>
  );
}
