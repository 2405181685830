import React, { useState, useEffect } from 'react';
import useLastLocation from 'utils/useLastLocation';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { fetchDisplaySettings } from 'redux/displaySettings';
import { fetchSurveyComponents } from 'redux/surveyComponents';
import {
  Page,
  Thumbnail,
  LegacyCard,
  ProgressBar,
  Link,
  Badge,
  Modal,
  TextField,
  Banner,
  Button,
  TextContainer,
  Tooltip,
  Box,
  Text,
  Divider,
  BlockStack,
  Card,
  InlineGrid,
  InlineStack,
} from "@shopify/polaris";
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import StarRating from 'components/StarRating';
import RenderedTraits from 'components/RenderedTraits';
import ToggleUnreviewableModal from 'components/ToggleUnreviewableModal';
import { ViewIcon, HideIcon, ExternalIcon } from "@shopify/polaris-icons";
import noImage from 'assets/images/noImage.jpg';
import filledStar from 'assets/images/filledStar.svg';
import 'styles/components/Product.scss';
import ProductVariantsTable from 'components/ProductVariantsTable';

function Product(props) {
  const lastLocation = useLastLocation();
  const reviewBars = [5, 4, 3, 2, 1];
  const productId = props.match.params.id;
  const {
    showToast,
    fetchDisplaySettings,
    displaySettings,
    stores,
    surveyComponents,
    fetchSurveyComponents
  } = props;
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [showProductReviewLink, setShowProductReviewLink] = useState(false);
  const [showToggleUnreviewableModal, setShowToggleUnreviewableModal] = useState(false);
  const [appInstall, setAppInstall] = useState({});
  const [surveyComponentList, setSurveyComponentList] = useState([]);
  const [customQuestionToDisplayCount, setCustomQuestionToDisplayCount] = useState(5);
  const [displayAllCustomQuestions, setDisplayAllCustomQuestions] = useState(false);

  useEffect(() => {
    if (stores.data?.length > 0) {
      const scopedStore = stores.data?.find((store) => store.scoped);
      setAppInstall(scopedStore?.app_install);
    }
  }, [stores])

  useEffect(() => {
    if (!displaySettings.data) {
      fetchDisplaySettings();
    }
  }, [fetchDisplaySettings, displaySettings.data])

  useEffect(() => {
    async function fetchProduct() {
      try {
        setLoading(true);
        const response = await axios.get(`/api/v1/products/${productId}`, {
          params: {
            include: 'image,product_category,traits,survey_questions,tags'
          }
        });
        setProduct(response.data.product);
        setLoading(false);
      } catch (e) {
        showToast('Error fetch product, please try again', true);
      }
    }
    if (productId) {
      fetchProduct();
    }
  }, [productId, showToast]);

  useEffect(() => {
    if (product.id) {
      fetchSurveyComponents({ 'product_id': product.id, include: 'question' });
    }
  }, [fetchSurveyComponents, product.id]);

  useEffect(() => {
    if (surveyComponents.data) {
      setSurveyComponentList(surveyComponents.data);
    }
  }, [surveyComponents]);

  useEffect(() => {
    if (displayAllCustomQuestions) {
      // Technically we're only referencing the first page of custom questions but
      // it's highly unlikely a merchant would have anywhere near 50 questions
      // on a single product (currently the largest we have is 12)
      setCustomQuestionToDisplayCount(50);
    } else {
      setCustomQuestionToDisplayCount(5);
    }
  }, [displayAllCustomQuestions]);

  const getActionToolTip = () => {
    return (
      <div>
        <div className="primary-action-tooltip-line">You can edit group status and MPNs here</div>
        <div className="primary-action-tooltip-line">To edit GTIN, product name, & other product information, visit your Shopify Account.</div>
      </div>
    );
  }

  const generateProductReviewLink = () => {
    if (product?.unreviewable) {
      return '';
    }
    return `${process.env.REACT_APP_FORMS_URL}/review/onsite/product?product_id=${product.id}&store_key=${displaySettings.data?.store?.key}`;
  };

  const copyProductReviewLink = async () => {
    try {
      await navigator.clipboard.writeText(generateProductReviewLink());
      showToast('Product review link copied');
    } catch (e) {
      // error
    }
  };

  const toggleUnreviewable = async (newState) => {
    try {
      await axios.put(`/api/v1/products/${product.id}`, {
        unreviewable: newState
      });
      showToast('Product updated');
      setProduct({ ...product, unreviewable: newState });
      trackEvent(`Product - ${newState ? 'Disable' : 'Enable'} reviews`);
    } catch (e) {
      showToast('Error updating product, please try again', true);
      trackEvent(`Error: Product - ${newState ? 'Disable' : 'Enable'} reviews`, { statusCode: e?.response?.status });
    }
  };

  const titleMetaData = (() => {
    if (product.unreviewable) {
      return <Badge>Reviews disabled</Badge>;
    }
    return null;
  })();

  const overviewProduct = product;

  const getQuestionType = (value_type) => {
    switch (value_type) {
      case 'text':
        return 'Written answer';
      case 'radio':
        return 'Multiple choice';
      case 'rating':
        return 'Linear scale';
      default:
        return '';
    }
  }

  const starBreakdown = (
    <div className="product-rating-container">
      <div className="product-ratings-breakdown">
        {
          reviewBars.map((rating) => {
            let percent = 0;
            if (overviewProduct.rating_count > 0) {
              percent = Math.round((overviewProduct.rating_distribution?.[rating] / overviewProduct.rating_count) * 100);
            }
            return <>
              <div className="breakdown-rating mr-2">
                <div className="rating-number">
                  {rating}
                </div>
                <div className="rating-star">
                  <img src={filledStar} alt="Rating star icon" />
                </div>
              </div>
              <ProgressBar tone='primary' progress={percent} size="small" />
              <div className="breakdown-count">{overviewProduct.rating_distribution?.[rating]}</div>
            </>;
          })
        }
      </div>
    </div>
  );

  const productOverviewCard = (
    <Card>
      <BlockStack>
        <InlineGrid columns="1fr auto" alignItems="center">
          <InlineGrid columns="auto 1fr" gap="400">
            <Thumbnail size="large" source={product.image?.url_200x200 || noImage} alt={product.title} />
            <BlockStack gap="100">
              <Text variant="headingSm">
                {product.title}
              </Text>
              <InlineStack gap="100" blockAlign="center" align="start">
                <Text variant="headingLg" as="p">
                  {Math.round(overviewProduct.rating_average * 100) / 100 || 0}
                </Text>
                <StarRating rating={overviewProduct.rating_average || 0} />
              </InlineStack>
              <Link external monochrome url={`/reviews?productFilter=${product.id}`}>{overviewProduct.rating_count} review{overviewProduct.rating_count === 1 ? '' : 's'}</Link>
            </BlockStack>
          </InlineGrid>
          {starBreakdown}
        </InlineGrid>
        {
          overviewProduct?.traits?.length > 0 &&
          // Ensure at least one trait rating exists before displaying the Attribute rating section
          // otherwise display a helper text section
          overviewProduct.traits?.map((trait) => trait.rating_count).some((count) => count > 0) &&
          <>
            <Box padding="400" />
            <Divider />
            <RenderedTraits
              traits={overviewProduct.traits.filter(trait => trait.active === true && trait.rating_average > 0 && !trait.private)}
            />
          </>
        }
      </BlockStack>
    </Card>
  );

  const productDetailsCard = (
    <Card>
      <BlockStack gap="400">
        <Text variant="headingSm">Product details</Text>
        <BlockStack gap="100">
          <Text variant="headingSm">Shopify ID</Text>
          <InlineStack gap="100">
            <Text variant="bodyMd" tone="magic-secondary">{product.remote_id || "-"}</Text>
            <Button
              icon={ExternalIcon}
              accessibilityLabel="Open product in Shopify"
              external
              url={`https://${appInstall?.uid}/admin/products/${product?.remote_id}`}
              variant="plain"
            />
          </InlineStack>
        </BlockStack>
        <BlockStack gap="100">
          <Text variant="headingSm">Brand</Text>
          <Text variant="bodyMd" tone="magic-secondary">{product.vendor}</Text>
        </BlockStack>
        <BlockStack gap="100">
          <Text variant="headingSm">Product type</Text>
          <Text variant="bodyMd" tone="magic-secondary">{product?.product_category?.name || "Not specified"}</Text>
        </BlockStack>
      </BlockStack>
    </Card>
  );

  const customQuestionsCard = (
    <Card>
      <BlockStack gap="200">
        <InlineGrid columns="1fr auto">
          <Text variant="headingSm">Custom questions</Text>
          <Button
            url="/forms/questions"
            accessibilityLabel="Manage custom questions"
            variant="plain"
          >
            Manage
          </Button>
        </InlineGrid>
        {
          surveyComponentList.slice(0, customQuestionToDisplayCount).map((component) => {
            return (
              <div>
                <p>
                  <Text variant="bodyMd" as="span" fontWeight="semibold">
                    {component.question.question}
                  </Text>
                  {component.question?.private &&
                    <Text variant="bodyMd" as="span">{' '}(Private)</Text>
                  }
                </p>
                <p><Text variant="bodyMd" as="span" tone="subdued">{getQuestionType(component.question.value_type)}</Text></p>
              </div>
            );
          })
        }
        {
          surveyComponentList.length > 5 &&
          <Link onClick={() => { setDisplayAllCustomQuestions(!displayAllCustomQuestions) }}>
            {
              displayAllCustomQuestions ?
                'Collapse' :
                `Show ${surveyComponentList.length - 5} More`
            }
          </Link>
        }
      </BlockStack>
    </Card>
  );

  const manualReviewLinksCard = (
    <LegacyCard
      sectioned
      title="Manual product review link"
      subdued={product?.unreviewable}>
      <TextContainer>
        <p>
          Use this link to manually request a review for a specific product. To learn more about manual review links check out this{' '}
          <Link url="https://help.junip.co/en/articles/4607276-manual-product-review-link" external>help doc</Link>.
        </p>
        <TextField
          readOnly={true}
          onFocus={(e) => e.target.select()}
          value={generateProductReviewLink()}
          disabled={product?.unreviewable}
          connectedRight={
            <Button
              size="large"
              onClick={copyProductReviewLink}
              disabled={product?.unreviewable}
            >
              Copy
            </Button>
          }
        />
      </TextContainer>
    </LegacyCard>
  );

  return (
    <LoadingPageWrapper loading={loading || displaySettings.loading} cards={3}>
      <Page
        title={product.title}
        titleMetadata={titleMetaData}
        backAction={{ content: 'All products', url: lastLocation?.includes?.(product?.id) ? '/products' : lastLocation || '/products' }}
        thumbnail={
          <Thumbnail
            source={product.image?.url_200x200 || noImage}
            alt={`${product.title} image`}
          />
        }
        fullWidth
        primaryAction={
          <Tooltip content={getActionToolTip()}>
            <Button icon={ExternalIcon} external url={`https://${appInstall?.uid}/admin/products/${product?.remote_id}`} disabled={!appInstall?.uid}>Edit in Shopify</Button>
          </Tooltip>
        }
        actionGroups={[
          {
            title: "More actions",
            actions: [
              {
                content: product.unreviewable ? 'Enable Reviews' : 'Disable Reviews',
                icon: product.unreviewable ? ViewIcon : HideIcon,
                onAction: () => { setShowToggleUnreviewableModal(true); }
              }
            ]
          }
        ]}
      >
        {product.unreviewable &&
          <div className="mb-4">
            <Banner tone="info">
              This product has reviews disabled. No review content will appear on it's product page and customers will not be able to leave reviews for this product.
            </Banner>
          </div>
        }
        <InlineGrid columns={["twoThirds", "oneThird"]} gap="500">
          <BlockStack gap="500">
            {productOverviewCard}
            <Divider />
            <BlockStack gap="400">
              <BlockStack gap="200">
                <Text variant="headingMd">
                  Catalog information
                </Text>
                <Text tone="subdued">
                  If syndicating reviews, make sure this information is complete and accurate.
                </Text>
              </BlockStack>
              <ProductVariantsTable product={product} />
              {manualReviewLinksCard}
            </BlockStack>
          </BlockStack>
          <BlockStack gap="500">
            {productDetailsCard}
            {surveyComponentList?.length > 0 && customQuestionsCard}
          </BlockStack>
        </InlineGrid>
      </Page>
      <Modal
        open={showProductReviewLink}
        title={`${product.title} review link`}
        onClose={() => setShowProductReviewLink(false)}
        secondaryActions={[{
          content: 'Close',
          onAction: () => setShowProductReviewLink(false)
        }]}
      >
        <Modal.Section>
          <TextField
            readOnly={true}
            onFocus={(e) => e.target.select()}
            value={generateProductReviewLink()}
            connectedRight={
              <Button
                size="large"
                onClick={copyProductReviewLink}
              >
                Copy
              </Button>
            }
          />
        </Modal.Section>
      </Modal>
      <ToggleUnreviewableModal
        showModal={showToggleUnreviewableModal}
        setShowModal={setShowToggleUnreviewableModal}
        product={product}
        toggleUnreviewable={toggleUnreviewable}
      />
    </LoadingPageWrapper>
  );
}

const mapStateToProps = (state) => ({
  displaySettings: state.displaySettings,
  productVariantsForProduct: state.productVariantsForProduct,
  stores: state.stores,
  surveyComponents: state.surveyComponents
});

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  fetchSurveyComponents: (params) => dispatch(fetchSurveyComponents(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product);
