import React, { useEffect, useState } from 'react';
import { AppProvider } from '@shopify/polaris';
import App from 'App';
import AdapterLink from 'utils/AdapterLink';

import enTranslations from '@shopify/polaris/locales/en.json';
import ColorSchemeWrapper, { setRootColorScheme } from 'components/ColorSchemeWrapper';

export default function Root() {
  const [colorScheme, setColorScheme] = useState(() => {
    return window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  });

  // Hack: This hack ensures modals and popups (portals) are also colored properly
  useEffect(() => {
    setRootColorScheme(colorScheme);
  }, [colorScheme]);

  const toggleColorScheme = () => {
    if (colorScheme === 'dark') {
      setColorScheme('light');
    } else {
      setColorScheme('dark');
    }
  };

  return (
    <AppProvider
      linkComponent={AdapterLink}
      i18n={enTranslations}
      colorScheme={colorScheme}
    >
      <ColorSchemeWrapper colorScheme={colorScheme} className="global-wrapper">
        <App toggleColorScheme={toggleColorScheme} colorScheme={colorScheme} />
      </ColorSchemeWrapper>
    </AppProvider>
  )
};
