import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import useLoadAnalyticsDb from "utils/useLoadAnalyticsDb";
import { whereFormatter, calcPercentage, isEmpty } from "utils/AnalyticsUtils";
import createCsv from 'utils/createCsv';

export default function useRatingsByProduct({
  setLoading,
  selectedDates,
  numericalType,
  ratingType,
  sortSelected,
  productCategoryFilter,
  filterSelectedDates,
  dateFilterActive
}) {
  const PAGE_SIZE = 20;
  const PRODUCTS_TABLE = 'analytics_products products';
  const REVIEWS_TABLE = 'analytics_reviews reviews';

  const { db, previewMode } = useLoadAnalyticsDb(setLoading);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const [allTableData, setAllTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [allProductsData, setAllProductsData] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  const [productCategories, setProductCategories] = useState([]);

  const productReviewFilter = `reviews.type = 'ProductReview'`;
  const reviewPeriodFilter = ratingType === 'new'
    ? `reviews.created_at >= '${moment(selectedDates.start).utc().format('YYYY-MM-DD')}' 
    AND reviews.created_at <= '${moment(selectedDates.end).utc().format('YYYY-MM-DD')}'`
    : null;
  const getSortCondition = useCallback(() => {
    switch (sortSelected[0]) {
      case 'total asc':
        return `ORDER BY Total ASC`;
      case 'total desc':
        return `ORDER BY Total DESC`;
      case '5-star asc':
        return `ORDER BY \`5-star\` ASC`;
      case '5-star desc':
        return `ORDER BY \`5-star\` DESC`;
      default:
        return 'ORDER BY Total DESC';
    }
  }, [sortSelected]);
  const categoryFilter = (productCategoryFilter && !isEmpty(productCategoryFilter))
    ? `products.product_category_id IN (${productCategoryFilter})`
    : null;
  const startDate = moment(filterSelectedDates.start).utc();
  const endDate = moment(filterSelectedDates.end).utc();
  const createdAtDateFilter = dateFilterActive
    ? startDate.isSame(endDate, 'day')
      ? `products.remote_created_at >= '${startDate.format('YYYY-MM-DD')}' 
      AND products.remote_created_at <= '${startDate.add(1, 'days').format('YYYY-MM-DD')} '`
      : `products.remote_created_at >= '${startDate.format('YYYY-MM-DD')}'
       AND products.remote_created_at <= '${endDate.format('YYYY-MM-DD')} '`
    : null;
  const paginate = `LIMIT ${PAGE_SIZE} OFFSET ${currentPage * PAGE_SIZE}`;
  const tableDataQuery = `SELECT 
        products.id, 
        products.title, 
        products.product_category_id, 
        products.product_category_name, 
        products.remote_created_at, 
        products.deleted_at,
        AVG(reviews.rating) as 'Avg. Rating', 
        COUNT(case WHEN reviews.rating = 1 then 1 else null end) as '1-star', 
        COUNT(case WHEN reviews.rating = 2 then 1 else null end) as '2-star', 
        COUNT(case WHEN reviews.rating = 3 then 1 else null end) as '3-star', 
        COUNT(case WHEN reviews.rating = 4 then 1 else null end) as '4-star', 
        COUNT(case WHEN reviews.rating = 5 then 1 else null end) as '5-star', 
        COUNT(rating) as 'Total'
      FROM ${PRODUCTS_TABLE}
      LEFT JOIN ${REVIEWS_TABLE} ON products.id = reviews.product_id ${reviewPeriodFilter ? `AND ${reviewPeriodFilter}` : ''}
      ${whereFormatter(categoryFilter, createdAtDateFilter)} 
      GROUP BY products.id, products.title 
      ${getSortCondition()}`;

  const handleExport = () => {
    const formatting = (numericalType === 'percent') ? ' (%)' : '';
    const headers = ['Product', 'Avg. Rating', `1-star${formatting} `, `2-star${formatting} `, `3-star${formatting} `, `4-star${formatting} `, `5-star${formatting} `, 'Total'];
    const queryData = db.exec(tableDataQuery + ';')[0]?.values;
    const data = queryData.map((product, index) => {
      const productTitle = previewMode ? `Product ${index + 1}` : product[5] ? `${product[1]} [Deleted]` : product[1];
      return (
        [productTitle, product[6] || 0, product[7], product[8], product[9], product[10], product[11], product[12]])
    });
    const allProductsData = ['All products',
      allProducts.avgRating || 0,
      allProducts.oneStar || 0,
      allProducts.twoStar || 0,
      allProducts.threeStar || 0,
      allProducts.fourStar || 0, allProducts.fiveStar || 0,
      allProducts.total || 0
    ];
    data.unshift(allProductsData);
    const csv = createCsv(headers, data);
    const encodedUri = encodeURI(csv);
    window.open(encodedUri);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    // when filters or sort order changes, reset currentPage
    setCurrentPage(0);
  }, [categoryFilter, reviewPeriodFilter, createdAtDateFilter, sortSelected]);

  useEffect(() => {
    if (db) {
      const numProductsQuery = `SELECT COUNT(id) FROM ${PRODUCTS_TABLE}${whereFormatter(categoryFilter, createdAtDateFilter)}; `; // filter by category, date created
      const categoriesQuery = `SELECT DISTINCT product_category_id, product_category_name FROM ${PRODUCTS_TABLE} WHERE product_category_id IS NOT NULL AND product_category_name IS NOT NULL; `;

      setCurrentPage(0);
      setTotalPages(Math.ceil(db.exec(numProductsQuery)[0]?.values[0][0] / PAGE_SIZE));
      setProductCategories(db.exec(categoriesQuery)[0]?.values);
    }
  }, [categoryFilter, createdAtDateFilter, db]);

  useEffect(() => {
    if (totalPages > 0) {
      const next = currentPage + 1 < totalPages ? true : false;
      const previous = currentPage > 0 ? true : false;

      setHasNext(next);
      setHasPrevious(previous);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (db) {  // maybe needs check on totalPages
      const allDataQuery = `${tableDataQuery} ${paginate}; `;
      const allProductsQuery = `SELECT
        AVG(rating) as 'Avg. Rating',
        SUM(case WHEN rating = 1 then 1 else null end) as "1-star",
        SUM(case WHEN rating = 2 then 1 else null end) as "2-star",
        SUM(case WHEN rating = 3 then 1 else null end) as "3-star",
        SUM(case WHEN rating = 4 then 1 else null end) as "4-star",
        SUM(case WHEN rating = 5 then 1 else null end) as "5-star",
        COUNT(rating) as "Total" FROM ${REVIEWS_TABLE}
        ${whereFormatter(productReviewFilter, reviewPeriodFilter)} `;

      const data = db.exec(allDataQuery)[0]?.values || [];
      const allProducts = db.exec(allProductsQuery)[0]?.values[0];

      setAllTableData(data);
      setAllProductsData(allProducts);
    }
  }, [db, currentPage, reviewPeriodFilter, productReviewFilter, getSortCondition, categoryFilter, createdAtDateFilter, tableDataQuery, paginate]);

  useEffect(() => {
    if (allTableData) {
      const data = allTableData.map((product, index) => {
        let [
          productId,
          productTitle,
          productCategoryId,
          productCategoryName,
          createdAt,
          deletedAt,
          avgRating,
          oneStar,
          twoStar,
          threeStar,
          fourStar,
          fiveStar,
          total,
        ] = product;

        if (numericalType === "percent") {
          oneStar = calcPercentage(oneStar, total);
          twoStar = calcPercentage(twoStar, total);
          threeStar = calcPercentage(threeStar, total);
          fourStar = calcPercentage(fourStar, total);
          fiveStar = calcPercentage(fiveStar, total);
        }

        return {
          productId,
          productTitle: previewMode ? `Product ${index + 1}` : productTitle,
          productCategoryId,
          productCategoryName,
          createdAt,
          deletedAt,
          avgRating,
          oneStar,
          twoStar,
          threeStar,
          fourStar,
          fiveStar,
          total,
        };
      });
      setTableData(data);
    }
  }, [allTableData, numericalType, previewMode]);

  useEffect(() => {
    if (allProductsData) {
      let [avgRating, oneStar, twoStar, threeStar, fourStar, fiveStar, total] =
        allProductsData;

      if (numericalType === "percent") {
        oneStar = calcPercentage(oneStar, total);
        twoStar = calcPercentage(twoStar, total);
        threeStar = calcPercentage(threeStar, total);
        fourStar = calcPercentage(fourStar, total);
        fiveStar = calcPercentage(fiveStar, total);
      }
      setAllProducts({
        avgRating,
        oneStar,
        twoStar,
        threeStar,
        fourStar,
        fiveStar,
        total,
      });
    }
  }, [allProductsData, numericalType]);

  return { tableData, allProducts, productCategories, hasNext, hasPrevious, handleNextPage, handlePreviousPage, handleExport };
}
