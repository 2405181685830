import { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
let initialized = false;

export const init = () => {
  if (TOKEN) {
    mixpanel.init(TOKEN);
    initialized = true;
  }
};

export const trackEvent = (eventName, data) => {
  if (initialized) {
    mixpanel.track(eventName, data);
  }
};

const trackPageView = (pathname) => {
  if (initialized) {
    mixpanel.track('Page view', { pathname });
  }
};

function MixpanelAnalytics({ location, user }) {
  const [identified, setIdentified] = useState(false);
  const pathname = location.pathname;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (identified) {
      // Don't track page views for users that aren't logged in
      trackPageView(pathname);
    }
  }, [pathname, identified]);

  useEffect(() => {
    if (initialized && user.data?.id) {
      mixpanel.identify(user.data.id);
      mixpanel.people.set({
        '$email': user.data.email,
        '$first_name': user.data.first_name,
        '$last_name': user.data.last_name,
      });
      setIdentified(true);
    }
  }, [user.data]); 

  return (
    null
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(
  mapStateToProps,
)(MixpanelAnalytics));
