import axios from 'axios';

// Actions
const FETCH_PRODUCT_CATEGORIES = 'FETCH_PRODUCT_CATEGORIES';
const FETCH_PRODUCT_CATEGORIES_SUCCESS = 'FETCH_PRODUCT_CATEGORIES_SUCCESS';
const FETCH_PRODUCT_CATEGORIES_ERROR = 'FETCH_PRODUCT_CATEGORIES_ERROR';

// Action Creators
export function fetchProductCategories() {
  return async dispatch => {
    dispatch(fetchProductCategoriesBegin());
    try {
      const response = await axios.get('/api/v1/product_categories');
      dispatch(fetchProductCategoriesSuccess(response.data.product_categories));
    } catch (e) {
      dispatch(fetchProductCategoriesError(e));
    }
  }
}

export const fetchProductCategoriesBegin = () => ({
  type: FETCH_PRODUCT_CATEGORIES
});

export const fetchProductCategoriesSuccess = (data) => ({
  type: FETCH_PRODUCT_CATEGORIES_SUCCESS,
  data
});

export const fetchProductCategoriesError = (error) => ({
  type: FETCH_PRODUCT_CATEGORIES_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_PRODUCT_CATEGORIES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_PRODUCT_CATEGORIES_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
