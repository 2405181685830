import React, { useState, useEffect } from 'react';
import {
  BlockStack,
  Page,
  Text,
  Card,
  Box,
  InlineStack,
  Badge,
  ProgressBar,
  Collapsible,
  List,
  Icon,
  Tooltip,
  Button,
  Bleed,
  Banner
} from '@shopify/polaris';
import axios from 'axios';
import useInterval from 'utils/useInterval';
import ImportReviewPlatformSelectModal from 'components/ImportReviewPlatformSelectModal';

import { InfoIcon } from '@shopify/polaris-icons';

const DEFAULT_POLLING_DELAY = 5000;
const TIME_RANGE = 20 * 60 * 1000; // 20 minutes
const PENDING_STATES = ['processing', 'pending', 'waiting'];
const ERROR_STATES = ['failed', 'cancelled'];
const PROVIDER_TO_NAME_MAP = {
  'judge_me': 'Judge.me',
  'loox': 'Loox',
  'okendo': 'Okendo',
  'power_reviews': 'Power Reviews',
  'reviews_io': 'Reviews.io',
  'shopify': 'Shopify (Product Reviews)',
  'stamped': 'Stamped',
  'yotpo': 'Yotpo',
  'generic': 'Nexus'
};

export default function Transfers() {
  const [pendingImport, setPendingImport] = useState(null);
  const [reviewImports, setReviewImports] = useState([]);
  const [delay, setDelay] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);

  useEffect(() => {
    return () => {
      setDelay(null);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/api/v1/review_imports');
        const imports = response?.data?.review_imports;
        const firstPendingImport = imports?.find(i => PENDING_STATES.includes(i.state));
        if (firstPendingImport) {
          setPendingImport(firstPendingImport);
        } else {
          const recentlyActive = imports?.[0];
          if ((Date.now() - Date.parse(recentlyActive?.updated_at) < TIME_RANGE)) {
            setPendingImport(recentlyActive);
          }
        }

        // Filter out pending/recent imports
        setReviewImports(imports.filter(i => i.id !== firstPendingImport?.id));
      } catch (e) {
        // TODO: Handle error
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (pendingImport) {
      if (PENDING_STATES.includes(pendingImport.state)) {
        setDelay(DEFAULT_POLLING_DELAY);
      } else {
        setDelay(null);
      }
    }
  }, [pendingImport]);

  useInterval(() => {
    async function fetchData(id) {
      const response = await axios.get(`/api/v1/review_imports/${id}`);
      setPendingImport(response?.data?.review_import);
    }
    if (pendingImport) {
      fetchData(pendingImport.id);
    }
  }, delay);

  console.log(reviewImports, pendingImport);
  return (
    <Page
      title="Review Transfers"
      backAction={[{ content: "Back", url: "/reviews" }]}
      secondaryActions={[
        {
          content: 'Transfer reviews',
          onAction: () => setShowImportModal(true)
        }
      ]}
    >
      <BlockStack gap="200">
        {pendingImport && (
          <TransferCard transfer={pendingImport} />
        )}
        {reviewImports?.length && (
          <>
            <Text variant="headingXs" as="h6">Previous Transfers</Text>
            {reviewImports.map(transfer => (
              <TransferCard key={transfer.id} transfer={transfer} />
            ))}
          </>
        )}
        {reviewImports?.length === 0 && !pendingImport && (
          <Card>
            <Box padding="400">
              <BlockStack gap="300" inlineAlign="center">
                <Text variant="headingMd">You have not transferred any reviews</Text>
                <Text>Start a new transfer to bring reviews collected on another platform to Junip.</Text>
                <Button
                  variant="primary"
                  onClick={() => setShowImportModal(true)}
                >
                  Transfer reviews
                </Button>
              </BlockStack>
            </Box>
          </Card>
        )}
      </BlockStack>
      <ImportReviewPlatformSelectModal
        active={showImportModal}
        close={() => setShowImportModal(false)}
      />
    </Page>
  );
}

function TransferCard({ transfer = {} }) {
  const {
    id,
    created_at,
    duplicate_count,
    imported_count,
    invalid_count,
    product_review_count,
    provider,
    state,
    store_review_count,
    target_dne_count,
    total_count,
  } = transfer;

  const [open, setOpen] = useState(false);

  const isActive = PENDING_STATES.includes(state);
  const hasError = ERROR_STATES.includes(state) || (invalid_count + duplicate_count + target_dne_count) > 0;
  const progress = ((imported_count + duplicate_count + invalid_count + target_dne_count) / total_count) * 100;

  const formatDate = (date) => {
    // As MMMM DD, YYYY HH:MM AM/PM
    return new Date(date).toLocaleString(undefined, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
  };
  return (
    <Card>
      <BlockStack gap="300">
        {isActive ? (
          <Text as="h2" variant="headingSm">
            Transfer from {PROVIDER_TO_NAME_MAP[provider]} in progress...
          </Text>
        ) : (
          <Text as="h2" variant="bodyMd" tone="subdued">
            Transfer from {PROVIDER_TO_NAME_MAP[provider]}
          </Text>
        )}
        {state === 'waiting' && (
          <Banner tone="info">
            Junip is currently syncing your store information. Your review import will start as soon as the sync is
            complete. Note this may take a couple of hours depending on the size of your store
          </Banner>
        )}
        <InlineStack gap="200">
          <Text variant="headingMd">{formatDate(created_at)}</Text>
          {!isActive &&
            <Badge tone={hasError ? 'warning' : 'success'}>
              {hasError ? 'Completed with errors' : 'Completed'}
            </Badge>
          }
        </InlineStack>
        {isActive &&
          <ProgressBar
            size="medium"
            progress={progress}
          />
        }
        <Text variant="bodyMd">
          {imported_count} of {total_count} review{total_count > 1 ? 's' : ''} transferred.
        </Text>
        <Collapsible open={open} id={`transfer-details-${id}`}>
          <Bleed marginInline="400">
            <Box background="bg-surface-secondary" padding="400">
              <BlockStack gap="150">
                <Text variant="headingSm" as="p">{imported_count} reviews successfully imported:</Text>
                <List>
                  <List.Item>Product reviews: {product_review_count}</List.Item>
                  <List.Item>Store reviews: {store_review_count}</List.Item>
                </List>
                <Text variant="headingSm" as="p">
                  {duplicate_count + invalid_count + target_dne_count} skipped:
                </Text>
                <List>
                  <List.Item>Duplicates: {duplicate_count}</List.Item>
                  <List.Item>Invalid: {invalid_count}</List.Item>
                  <List.Item>
                    <div className="d-flex">
                      <div className="mr-1">
                        Product IDs submitted do not match those in your account: {target_dne_count}
                      </div>
                      {target_dne_count > 0 &&
                        <Tooltip content="Some spreadsheet tools automatically convert these long strings to more simple numbers when opened. If you re-export from your previous provider & import directly it should work fine! Otherwise double check the Product ID column to ensure it hasn't been changed.">
                          <div style={{ cursor: 'pointer' }}>
                            <Icon source={InfoIcon} tone="subdued" />
                          </div>
                        </Tooltip>
                      }
                    </div>
                  </List.Item>
                </List>
                <Text>
                  Note: Junip only supports importing reviews gathered directly from customers on your store. We do not support generic .csv uploads at this time.
                </Text>
              </BlockStack>
            </Box>
          </Bleed>
        </Collapsible>
        <Box>
          <Button
            variant="plain"
            onClick={() => setOpen(!open)}
            ariaExpanded={open}
            ariaControls={`transfer-details-${id}`}
          >
            {open ? 'Show' : 'Hide'} details
          </Button>
        </Box>
      </BlockStack>
    </Card>
  );
}
