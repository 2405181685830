import React from 'react';
import {
  Text,
  LegacyStack,
  Badge,
} from '@shopify/polaris';

export default function TemplateCard({ name, description, sent }) {
  return (
    <LegacyStack alignment="center">
      <LegacyStack.Item fill>
        <Text variant="bodyMd" as="span" fontWeight="semibold">{name}</Text>
        <div>
          <Text variant="bodyMd" as="span" tone="subdued">
            {description}
          </Text>
        </div>
      </LegacyStack.Item>
      <LegacyStack.Item>
        <Badge tone="info">{sent ? sent : 0} sent</Badge>
      </LegacyStack.Item>
    </LegacyStack>
  );
}
