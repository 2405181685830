import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import {
  Modal,
  TextContainer
} from '@shopify/polaris';

function BillingPrompt(props) {
  const { active, setActive } = props;
  const billingSubscription = props.billingSubscriptions.data?.[0] || {};
  const {
    usage_limit_reached,
    next_billing_date
  } = billingSubscription;

  let title = 'Select a billing plan to go live with Junip';
  let body = (
    <>
      { !billingSubscription.active &&
        <p>This starts your trial with Junip & let's you go live with reviews. You don't pay anything until your trial ends & can cancel up until that point with absolutely no charge.</p>
      }
      <p>Junip offers a variety of plans suitable for any type of business</p>
    </>
  );

  if (usage_limit_reached) {
    title = 'Usage limit reached';
    body = (
      <p>
        Upgrade your plan to get more usage and advanced features. Your usage will reset on {moment(next_billing_date).format('MMMM Do, YYYY')}.
      </p>
    )
  }

  return (
    <Modal
      open={active}
      onClose={() => setActive(false)}
      title={title}
      primaryAction={{
        content: 'View plans',
        url: '/settings/billing',
        onAction: () => { setActive(false); trackEvent('Billing prompt - View plans'); }
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => { setActive(false); trackEvent('Billing prompt - Cancel'); },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          {body}
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  billingSubscriptions: state.billingSubscriptions,
});

export default connect(
  mapStateToProps,
)(BillingPrompt);
