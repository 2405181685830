import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { fetchFlows } from 'redux/flows';
import {
  fetchMessageTemplates
} from 'redux/messages';
import {
  Button,
  Page,
  LegacyCard,
  LegacyStack,
  ButtonGroup,
  Checkbox,
  TextField,
  Text,
} from "@shopify/polaris";
import JunipLogo from 'components/JunipLogo';
import SkipOnboarding from 'components/SkipOnboarding';
import LoadingWrapper from 'components/LoadingWrapper';
import EmailPreview from 'components/EmailPreview';

import { MAX_TIME_DELAY } from 'components/SolicitationConditions';
import ColorSchemeWrapper from 'components/ColorSchemeWrapper';

const NEXT_STEP = '/onboarding/campaign';

function Solicitation({
  flows,
  messages,
  fetchFlows,
  fetchMessageTemplates,
  history,
  progress
}) {
  const [loading, setLoading] = useState(false);
  const [flow, setFlow] = useState(null);
  const [action, setAction] = useState(null);
  const [timeDelay, setTimeDelay] = useState('0');
  const [marketingCheckDisabled, setMarketingCheckDisabled] = useState(false);

  useEffect(() => {
    fetchFlows({include: 'actions.message_type,actions.app_install,actions.condition_list'});
  }, [fetchFlows]);

  useEffect(() => {
    if (flows?.data) {
      const request = flows?.data?.find((f) => f.flow_type === 'request' && f.event_topic?.action !== 'targeted-by-campaign' && f.event_topic?.action !== 'request-by-campaign');
      setFlow(request || null);
      const emailAction  = request?.actions?.find(a => a.action_type === 'email');
      setAction(emailAction || null);
    }
  }, [flows?.data]);


  useEffect(() => {
    if (action?.condition_list) {
      setTimeDelay(action?.condition_list?.queue_time_days);
      setMarketingCheckDisabled(action?.condition_list?.marketing_check_disabled);
    }
  }, [action]);

  useEffect(() => {
    if (!messages.data) {
      fetchMessageTemplates();
    }
  }, [messages.data, fetchMessageTemplates]);

  const nextStep = async () => {
    if (!flow || !action?.condition_list) {
      history.push(NEXT_STEP);
      return;
    }

    setLoading(true);
    try {
      await axios.put(`/api/v1/flows/${flow.id}`, {
        flow: { disabled: false }
      });

      await axios.put(`/api/v1/flow_condition_lists/${action?.condition_list?.id}`, {
        flow_condition_list: {
          queue_time_days: timeDelay,
          marketing_check_disabled: marketingCheckDisabled
        }
      });

      setLoading(false);
      trackEvent('Onboarding flow solicitation - Enable & continue');
    } catch(e) {
      setLoading(false);
      trackEvent('Error: Onboarding flow solicitation - Enable & continue');
    }

    history.push(NEXT_STEP);
  };

  const handleChange = useCallback((newValue) => setTimeDelay(newValue), []);

  const message = messages?.data?.find((t) => t.message_type.slug === 'product_review_requested');
  return (
    <Page fullWidth>
      <LegacyCard flush>
        <div className="onboarding-step o-divider">
          <div className="o-step o-step-subdued o-divider-right">
            <LegacyStack vertical spacing="extraLoose">
              <JunipLogo height={'24px'} />
              <Text variant="heading2xl" as="p">Start gathering reviews</Text>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">Next let's preview your review request flow and enable it so you can start gathering reviews! The preview is shown below using sample product information.</Text>
              </p>
              <LoadingWrapper loading={flows?.loading}>
                {flow && action?.condition_list ?
                  <LegacyStack vertical spacing="loose">
                    <Text variant="headingMd" as="h2">Configure your message time delay</Text>
                    <p>
                      <Text variant="bodyMd" as="span" tone="subdued">After your customer’s order is fulfilled, how long should Junip wait before asking them to leave a review?</Text>
                    </p>
                    <LegacyStack alignment="center" wrap={false}>
                      <TextField
                        type="number"
                        max={MAX_TIME_DELAY}
                        min={0}
                        pattern="[0-9]"
                        value={`${timeDelay}`}
                        onChange={handleChange}
                        disabled={loading}
                      />
                      <p>
                        <Text variant="bodyMd" as="span" tone="subdued">days after order is fulfilled.</Text>
                      </p>
                    </LegacyStack>
                    <Checkbox
                      label="Only send review requests to buyers who accept marketing"
                      helpText="Disabling this will send review requests to all customers, including those who have not opted in to marketing"
                      checked={marketingCheckDisabled === false}
                      onChange={(value) => setMarketingCheckDisabled(!value)}
                      disabled={loading}
                    />
                  </LegacyStack>
                :
                  <p>Visit the messages flows section to finish configuring your review request flow</p>
                }
              </LoadingWrapper>
            </LegacyStack>
          </div>
          <ColorSchemeWrapper colorScheme="light">
            <div className="o-step o-step-default">
              {message &&
                <EmailPreview template={message} blocks={message.message_blocks} inputs={{}} customHtmlEnabled={false} />
              }
            </div>
          </ColorSchemeWrapper>
        </div>
        <LegacyCard.Section subdued>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              {progress}
            </LegacyStack.Item>
            <LegacyStack.Item>
              <ButtonGroup gap="loose">
                <Button

                  disabled={loading}
                  url={NEXT_STEP}
                  onClick={() => trackEvent('Onboarding flow solicitation - Skip')}
                  variant="plain">
                  Skip
                </Button>
                <Button size="large" loading={loading} onClick={nextStep} variant="primary">
                  {flow && action?.condition_list ? 'Enable & Continue' : 'Continue'}
                </Button>
              </ButtonGroup>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <SkipOnboarding disabled={loading} />
    </Page>
  );
}

const mapStateToProps = (state) => ({
  flows: state.flows,
  messages: state.messages
});

const mapDispatchToProps = (dispatch) => ({
  fetchFlows: (data) => dispatch(fetchFlows(data)),
  fetchMessageTemplates: () => dispatch(fetchMessageTemplates())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Solicitation);
