import React, { useState, useEffect, useCallback } from 'react';
import useLastLocation from 'utils/useLastLocation';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { setFormDirty } from 'redux/settings';
import { saveUser, setUser } from 'redux/user';
import { savingChanges } from 'redux/settings';
import { showToast } from 'redux/toast';
import { fetchInvitations } from 'redux/invitations';
import axios from 'axios';
import {
  Page,
  PageActions,
  Layout,
  LegacyCard,
  FormLayout,
  TextField,
  TextContainer,
  SkeletonDisplayText,
  SkeletonBodyText,
  Collapsible,
  Button,
  Banner,
  Link
} from '@shopify/polaris';
import LoadingContentWrapper from 'components/LoadingContentWrapper';
import Invitations from 'components/Invitations';
import EmailUnverifiedBanner from 'components/EmailUnverifiedBanner';

function UserSettings(props) {
  const lastLocation = useLastLocation();
  const [inputs, setInputs] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [toggleActive, setToggleActive] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  const {
    setFormDirty,
    saveUser,
    setUser,
    showToast,
    invitations
  } = props;
  const data = props.user.data || null;
  const settings = props.settings;

  useEffect(() => {
    return () => {
      setFormDirty(false);
    }
  }, [setFormDirty]);

  useEffect(() => {
    async function verifyEmail(token, id) {
      setVerifying(true);
      try {
        await axios.put(`/api/v1/users/${id}/verify_email`, { token });
        setVerifying(false);
        setVerified(true);
        let newUser = data;
        newUser.verified_email = true;
        setUser(newUser);
        trackEvent('User settings - Verify email');
        showToast('Email verified');
      } catch(e) {
        showToast('Error verifying email, please try again', true);
        setVerifying(false);
        setVerified(false);
        trackEvent('Error: User settings - Verify email', { statusCode: e?.response?.status });
      }
    }
    if (token && data && !data.verified_email) {
      verifyEmail(token, data.id);
    }
  }, [token, data, setUser, showToast]);

  useEffect(() => {
    if (data) {
      setInputs({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone
      });
    }
  }, [data]);

  useEffect(() => {
    if (data?.email !== inputs?.email) {
      setEmailChanged(true);
    } else {
      setEmailChanged(false);
    }
  }, [data, inputs]);

  useEffect(() => {
    if (settings.discardChanges) {
      if (data) {
        setInputs({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
        });
        setIsDirty(false);
        trackEvent('User settings - Discard changes');
      }
    }
  }, [data, settings.discardChanges]);

  useEffect(() => {
    if (settings.saving) {
      if (!isSaving) {
        const changedValues = Object.entries(inputs).reduce((acc, [key, value]) => {
          if (data[key] !== value) {
            acc[key] = value;
          }
          return acc;
        }, {});
        saveUser(data.id, changedValues);
        setIsSaving(true);
        trackEvent('User settings - Save changes');
      }
    } else {
      if (isSaving) {
        setIsSaving(false);
      }
    }
  }, [data, inputs, isSaving, saveUser, settings.saving]);

  const checkIsDirty = useCallback(() => {
    if (!data || typeof(data) !== 'object') {
      return;
    }
    const propertyNames = Object.getOwnPropertyNames(inputs);
    let dirty = false;
    for (const name of propertyNames) {
      if (inputs[name] !== data[name]) {
        dirty = true;
        break;
      }
    }

    if (dirty && !isDirty) {
      setIsDirty(true);
      if (!settings.isDirty) {
        setFormDirty(true);
      }
    } else if (!dirty && isDirty) {
      setIsDirty(false);
      if (settings.isDirty) {
        setFormDirty(false);
      }
    }
  }, [data, inputs, isDirty, setFormDirty, settings.isDirty]);

  useEffect(() => {
    checkIsDirty()
  }, [checkIsDirty]);

  const handleInputChange = (field) => {
    return (value) => {
      if (field === 'phone') {
        value = value.replace(/[^\d+]/g, '');
      }
      setInputs(inputs => ({...inputs, [field]: value}));
    };
  };

  const handleToggle = useCallback(() => setToggleActive((toggleActive) => !toggleActive), []);

  const primaryAction = {
    content: 'Save',
    disabled: !isDirty,
    onAction: props.savingChanges,
    loading: settings.saving
  };

  const loadingMarkup = props.user.loading ? (
    <TextContainer>
      <SkeletonDisplayText size="small" />
      <SkeletonBodyText />
    </TextContainer>
  ) : null;

  const formMarkup = !props.user.loading ? (
    <>
    <FormLayout>
      <TextField
        value={inputs.first_name}
        onChange={handleInputChange('first_name')}
        label="First name"
        type="text"
        placeholder="First name"
        disabled={verifying}
      />
      <TextField
        value={inputs.last_name}
        onChange={handleInputChange('last_name')}
        label="Last name"
        type="text"
        placeholder="Last name"
        disabled={verifying}
      />
      <TextField
        value={inputs.email}
        onChange={handleInputChange('email')}
        label="Email"
        type="text"
        placeholder="Email"
        disabled={verifying}
      />
      <TextField
        value={inputs.phone}
        autoComplete="tel"
        onChange={handleInputChange('phone')}
        label="Phone number"
        type="tel"
        helpText={
        <p>
          Be sure to add the appropriate <Link external url="https://countrycode.org/">country code</Link> when entering your phone number
        </p>
        }
        inputMode="tel"
        maxLength="15"
        placeholder="+18881234567"
        disabled={verifying}
      />
      <Button
        onClick={handleToggle}
        ariaExpanded={toggleActive}
        ariaControls="password-collapsible"
        disabled={verifying}
      >
        Change password
      </Button>
    </FormLayout>
    <Collapsible open={emailChanged} id="password-collapsible">
      <div className="pt-3">
      <FormLayout>
        <TextField
          value={inputs.current_password}
          onChange={handleInputChange('current_password')}
          label="Confirm current password"
          type="password"
          placeholder="Current password"
          maxLength="72"
        />
        </FormLayout>
      </div>
    </Collapsible>
    <Collapsible open={toggleActive} id="password-collapsible">
      <div className="pt-3">
      <FormLayout>
        <TextField
          value={inputs.current_password}
          onChange={handleInputChange('current_password')}
          label="Current password"
          type="password"
          placeholder="Current password"
          maxLength="72"
        />
        <TextField
          value={inputs.new_password}
          onChange={handleInputChange('new_password')}
          label="New password"
          type="password"
          placeholder="New password"
          helpText="Password must be at least 8 characters long"
          maxLength="72"
          minLength="8"
          autoComplete="new-password"
        />
        <TextField
          value={inputs.new_password_confirmation}
          onChange={handleInputChange('new_password_confirmation')}
          label="Confirm new password"
          type="password"
          placeholder="Confirm new password"
          maxLength="72"
          minLength="8"
          autoComplete="new-password"
        />
        </FormLayout>
      </div>
    </Collapsible>
    </>
  ) : null;

  return (
    <Page
      title="User settings"
      backAction={{content: 'Settings', url: lastLocation || '/settings'}}
    >
      <Layout>
        { data && !data.verified_email && !verifying && !verified &&
          <Layout.Section>
          <EmailUnverifiedBanner
            user={data}
            description="You must verify your email to accept invitations. Check your email for a verification link, you can resend the link using the button below."
          />
          </Layout.Section>
        }
        { verifying &&
          <Layout.Section>
            <Banner
              title="Verifying your email"
              tone="info"
            >
            </Banner>
          </Layout.Section>
        }
        { verified &&
          <Layout.Section>
            <Banner
              title="Your email has been verified!"
              tone="success"
            >
            </Banner>
          </Layout.Section>
        }
        <Layout.AnnotatedSection
          title="Your invitations"
          description="Manage your pending invitations from other organizations"
        >
          <LoadingContentWrapper cards={1} loading={!data?.email || invitations.loading}>
            <Invitations disabled={!data?.verified_email} invitations={invitations?.data?.filter(i => i.email === data?.email && i.state === 'pending') || []} />
          </LoadingContentWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="User"
          description="Configure your user and login information."
        >
          <LegacyCard sectioned>
            {loadingMarkup}
            {formMarkup}
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.Section>
          <PageActions
            primaryAction={primaryAction}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  user: state.user,
  invitations: state.invitations
});

const mapDispatchToProps = (dispatch) => ({
  setFormDirty: (dirty) => dispatch(setFormDirty(dirty)),
  saveUser: (id, settings) => dispatch(saveUser(id, settings)),
  setUser: (user) => dispatch(setUser(user)),
  savingChanges: () => dispatch(savingChanges()),
  showToast: (message, error) => dispatch(showToast(message, error)),
  fetchInvitations: (data) => dispatch(fetchInvitations(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings);
