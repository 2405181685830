import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { fetchReviews, updateReviews } from 'redux/reviews';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import { fetchReviewTags } from 'redux/reviewTags';
import { showToast } from 'redux/toast';
import axios from 'axios';
import ReviewListItem from 'components/ReviewListItem';
import ProductFilter from 'components/ProductFilter';
import ImportReviewModal from 'components/ImportReviewModal';
import ImportReviewStatusBanner from 'components/ImportReviewStatusBanner';
import ExportReviewsModal from 'components/ExportReviewsModal';
import ExportReviewsStatusBanner from 'components/ExportReviewsStatusBanner';
import MigrateEventsBanner from 'components/MigrateEventsBanner';
import {
  Page,
  Pagination,
  Button,
  LegacyCard,
  Select,
  ResourceList,
  ResourceItem,
  EmptyState,
  TextField,
  Form,
  LegacyStack,
  Icon,
  Tabs,
  Checkbox,
  Banner
} from '@shopify/polaris';
import { ImportIcon, ExportIcon, SettingsIcon, SearchIcon } from "@shopify/polaris-icons";

import noReviews from 'assets/images/noReviews.svg';
import ImportReviewPlatformSelectModal from 'components/ImportReviewPlatformSelectModal';

const ReviewsLayout = (props) => {
  const { fetchReviews, fetchPlanFeatureSets, planFeatureSets, moderationSettings, fetchReviewTags, reviewTags } = props;
  const { data, loading, meta } = props.reviews;

  const queryParams = props.history.location.search;
  const params = new URLSearchParams(queryParams);
  const selfModerationEnabled = moderationSettings.data?.self_moderation_enabled;

  const [selectedItems, setSelectedItems] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [stateFilter, setStateFilter] = useState(params.get('stateFilter') || 'approved');
  const [ratingFilter, setRatingFilter] = useState(params.get('ratingFilter') || 'all');
  const [typeFilter, setTypeFilter] = useState(params.get('typeFilter') || 'all');
  const [productFilter, setProductFilter] = useState(params.get('productFilter') || null);
  const [mediaFilter, setMediaFilter] = useState(params.get('mediaFilter') || false);
  const [responseFilter, setResponseFilter] = useState(params.get('responseFilter') || 'all');
  const [superSpecialSecretCondition] = useState(params.get('superSpecialSecretCondition') || false);
  const [tagFilter, setTagFilter] = useState(params.get('tagFilter') || 'all');
  const [page, setPage] = useState(() => {
    try {
      return JSON.parse(params.get('page'));
    } catch (e) {
      return null;
    }
  });
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [showImportReviewModal, setShowImportReviewModal] = useState(params.get('showImportModal') ? true : false);
  const [importStarted, setImportStarted] = useState(false);
  const [showExportReviewsModal, setShowExportReviewsModal] = useState(false);
  const [exportStarted, setExportStarted] = useState(false);
  const [queryValue, setQueryValue] = useState(params.get('queryValue') || null);
  const [sortValue, setSortValue] = useState(params.get('sortValue') || 'mostRecent');
  const [tagOptions, setTagOptions] = useState([]);

  const [stateOptionsTabs] = useState([
    { content: 'Published', id: 'approved' },
    { content: 'Pending', id: 'pending' },
    { content: 'Rejected', id: 'rejected' },
    { content: 'All', id: 'all' },
  ]);

  const hasReviewTagging = !selfModerationEnabled && planFeatureSets?.data?.['pf_review_tagging'] === true;

  useEffect(() => {
    if (exportStarted) {
      setExportStarted(false);
    }
  }, [exportStarted]);

  useEffect(() => {
    if (!planFeatureSets.data) {
      fetchPlanFeatureSets();
    }
  }, [fetchPlanFeatureSets, planFeatureSets.data]);

  const setQueryParams = useCallback((data) => {
    let newParams = new URLSearchParams(props.history.location.search);
    for (let [key, value] of Object.entries(data)) {
      if (value) {
        if (typeof value === 'object') {
          newParams.set(key, value?.id || JSON.stringify(value));
        } else {
          newParams.set(key, value || '');
        }
      } else {
        newParams.delete(key);
      }
    }
    props.history.replace({
      search: `?${newParams.toString()}`
    });
  }, [props.history]);

  useEffect(() => {
    fetchReviews({
      stateFilter,
      ratingFilter,
      typeFilter,
      productFilter,
      mediaFilter,
      responseFilter,
      sortValue,
      tagFilter,
      page,
      queryValue
    });
    setQueryParams({
      stateFilter,
      ratingFilter,
      typeFilter,
      productFilter,
      mediaFilter,
      responseFilter,
      sortValue,
      tagFilter,
      page,
      queryValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchReviews,
    stateFilter,
    ratingFilter,
    typeFilter,
    productFilter,
    mediaFilter,
    responseFilter,
    sortValue,
    tagFilter,
    setQueryParams,
    page
  ]);

  useEffect(() => {
    if (meta && meta.page) {
      const { page } = meta;
      if (page.after && !hasNext) {
        setHasNext(true);
      } else if (!page.after && hasNext) {
        setHasNext(false);
      }

      if (page.before && !hasPrevious) {
        setHasPrevious(true);
      } else if (!page.before && hasPrevious) {
        setHasPrevious(false);
      }
    }
  }, [meta, hasNext, hasPrevious]);

  useEffect(() => {
    if (!reviewTags.data) {
      fetchReviewTags();
    }
  }, [fetchReviewTags, reviewTags.data]);

  useEffect(() => {
    if (reviewTags.data) {
      const parsedTags = reviewTags.data.map(tag => {
        return {
          label: tag.name,
          value: tag.name
        };
      });

      parsedTags.unshift({ label: 'All', value: 'all' });

      setTagOptions(parsedTags);
    }
  }, [reviewTags.data]);

  const handleRatingFilter = useCallback((filterValue) => {
    setRatingFilter(filterValue);
    setPage(null);
    setSelectedItems([]);
  }, []);

  const handleTypeFilter = useCallback((filterValue) => {
    setTypeFilter(filterValue);
    setPage(null);
    setSelectedItems([]);
  }, []);

  const handleMediaFilter = useCallback((value) => {
    setMediaFilter(value);
    setPage(null);
    setSelectedItems([]);
  }, []);

  const handleResponseFilter = useCallback((value) => {
    setResponseFilter(value);
    setPage(null);
    setSelectedItems([]);
  }, []);

  const handleTagFilter = useCallback((value) => {
    setTagFilter(value);
    setPage(null);
    setSelectedItems([]);
  }, []);

  const handleSortValue = useCallback((selected) => {
    setSortValue(selected);
    setPage(null);
    setSelectedItems([]);
  }, []);

  const bulkAction = async (type) => {
    setListLoading(true);
    let reviews = [];
    for (let item of selectedItems) {
      reviews.push({
        id: item
      });
    }
    try {
      await axios.put(`/api/v1/reviews/${type}`, {
        reviews
      });
      setListLoading(false);
      setSelectedItems([]);
      props.showToast(`Review${reviews?.length === 1 ? '' : 's'} ${type === 'approve' ? 'published' : 'rejected'}`);
      if (stateFilter !== 'all' && page) {
        setPage(null);
      } else {
        fetchReviews({ stateFilter, ratingFilter, typeFilter, productFilter, mediaFilter, queryValue, sortValue, page });
      }
      trackEvent(`Moderation - Bulk action ${type}`);
    } catch(e) {
      setListLoading(false);
      props.showToast('Error :( please trying again later.', true);
      trackEvent(`Error: Moderation - Bulk action ${type}`, { statusCode: e?.response?.status });
    }
  };

  const approve = () => {
    bulkAction('approve');
  };

  const reject = () => {
    bulkAction('reject');
  };

  const nextPage = () => {
    setPage({ after: meta.page.after });
  };

  const previousPage = () => {
    setPage({ before: meta.page.before });
  };

  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );

  const handleQueryValueRemove = useCallback(() => {
    setQueryValue(null);
    setPage(null);
    setQueryParams({
      stateFilter,
      ratingFilter,
      typeFilter,
      productFilter,
      mediaFilter,
      responseFilter,
      tagFilter,
      queryValue: null,
      sortValue,
      page: null
    });
    fetchReviews({
      stateFilter,
      ratingFilter,
      typeFilter,
      productFilter,
      mediaFilter,
      responseFilter,
      tagFilter,
      sortValue
    });
  }, [
    fetchReviews,
    stateFilter,
    ratingFilter,
    typeFilter,
    productFilter,
    mediaFilter,
    responseFilter,
    tagFilter,
    sortValue,
    setQueryParams
  ]);

  const handleFilterSubmit = () => {
    setPage(null);
    setQueryParams({
      stateFilter,
      ratingFilter,
      typeFilter,
      productFilter,
      mediaFilter,
      responseFilter,
      queryValue,
      tagFilter,
      sortValue,
      page: null
    });
    fetchReviews({
      queryValue,
      stateFilter,
      ratingFilter,
      typeFilter,
      productFilter,
      mediaFilter,
      responseFilter,
      tagFilter,
      sortValue
    });
  };

  const handleStateFilter = useCallback((tabIndex) => {
    setStateFilter(stateOptionsTabs[tabIndex].id);
    setPage(null);
    setSelectedItems([]);
  }, [stateOptionsTabs]);

  const resetFilters = () => {
    const baseFiltersDirty = ratingFilter !== 'all' ||
      typeFilter !== 'all' ||
      productFilter !== null ||
      mediaFilter !== false ||
      responseFilter !== 'all' ||
      tagFilter !== 'all';
    setQueryValue(null);
    setQueryParams({
      stateFilter,
      ratingFilter: 'all',
      typeFilter: 'all',
      productFilter: null,
      mediaFilter: false,
      responseFilter: 'all',
      queryValue: null,
      tagFilter: 'all',
      sortValue,
      page: null
    });
    setProductFilter(null);
    setRatingFilter('all');
    setTypeFilter('all');
    setMediaFilter(false);
    setResponseFilter('all');
    setTagFilter('all');
    setPage(null);
    if (!baseFiltersDirty) {
      fetchReviews({
        stateFilter,
        ratingFilter,
        typeFilter,
        productFilter,
        mediaFilter,
        responseFilter,
        tagFilter,
        sortValue
      });
    }
  };

  const ratingOptions = [
    {label: 'All', value: 'all'},
    {label: '5', value: '5'},
    {label: '4', value: '4'},
    {label: '3', value: '3'},
    {label: '2', value: '2'},
    {label: '1', value: '1'},
  ];

  const typeOptions = [
    {label: 'All', value: 'all'},
    {label: 'Store review', value: 'store_review'},
    {label: 'Product review', value: 'product_review'}
  ];

  const responseOptions = [
    {label: 'All', value: 'all'},
    {label: 'Yes', value: 'true'},
    {label: 'No', value: 'false'}
  ];

  const sortOptions = [
    { label: 'Most recent', value: 'mostRecent' },
    { label: 'Oldest', value: 'oldest' },
    { label: 'Highest rated', value: 'highestRated' },
    { label: 'Lowest rated', value: 'lowestRated' }
  ];

  if (planFeatureSets?.data?.pf_featured_reviews) {
    sortOptions.push({ label: 'Featured', value: 'featuredFirst' });
  }

  const promotedBulkActions = [
    {
      content: 'Publish',
      disabled: stateFilter === 'approved',
      onAction: approve,
    },
    {
      content: 'Reject',
      disabled: stateFilter === 'rejected',
      onAction: reject,
    },
  ];

  const renderItem = (reviewData) => {
    return (
      <ResourceItem
        id={reviewData.id}
        url={`/reviews/${reviewData.id}${window.location.search}`}
        accessibilityLabel={`View details for this review`}
      >
        <ReviewListItem review={reviewData} customer={reviewData.customer} product={reviewData.product} />
      </ResourceItem>
    );
  };

  const resourceNameSingular = 'review';
  const resourceNamePlural = `${resourceNameSingular}s`;

  const filtersDirty = ratingFilter !== 'all' ||
    typeFilter !== 'all' ||
    productFilter !== null ||
    mediaFilter !== false ||
    responseFilter !== 'all' ||
    !!(params.get('queryValue'));

  const secondaryActions = [
    {
      icon: ImportIcon,
      content: 'Import reviews',
      onAction: () => { setShowImportReviewModal(true); trackEvent('Moderation - Import reviews') }
    },
    {
      icon: ExportIcon,
      content: 'Export reviews',
      onAction: () => { setShowExportReviewsModal(true); trackEvent('Moderation - Export reviews') }
    }
  ];

  if (selfModerationEnabled) {
    secondaryActions.push({
      icon: SettingsIcon,
      content: 'Settings',
      url: '/settings/moderation'
    });
  };

  return (
    <Page
      title="Manage reviews"
      secondaryActions={secondaryActions}
    >
      <MigrateEventsBanner/>
      <ImportReviewStatusBanner
        importStarted={importStarted}
      />
      <ImportReviewPlatformSelectModal
        active={showImportReviewModal && superSpecialSecretCondition }
        close={() => { setShowImportReviewModal(false); }}
      />
      <ImportReviewModal
        active={showImportReviewModal && !superSpecialSecretCondition }
        close={() => { setShowImportReviewModal(false); }}
        success={() => setImportStarted(true)}
      />
      <ExportReviewsModal
        active={showExportReviewsModal}
        hasReviewTagging={hasReviewTagging}
        close={() => setShowExportReviewsModal(false)}
        success={() => setExportStarted(true)}
      />
      <ExportReviewsStatusBanner
        exportStarted={exportStarted}
      />
      <LegacyCard>
        {selfModerationEnabled &&
          <Tabs
            tabs={stateOptionsTabs}
            selected={stateOptionsTabs.findIndex(s => s.id === stateFilter)}
            onSelect={handleStateFilter}
          >
          </Tabs>
        }
        {selfModerationEnabled && stateFilter === 'pending' &&
          <div className="p-2">
            <Banner tone="warning">
              <p>Check these reviews for inappropriate, fraudulent or spam content. Pending reviews will be automatically published after 14 days.</p>
            </Banner>
          </div>
        }
        <ResourceList
          items={data?.length ? data : []}
          renderItem={renderItem}
          resourceName={{
            singular: resourceNameSingular,
            plural: resourceNamePlural,
          }}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          promotedBulkActions={selfModerationEnabled ? promotedBulkActions : []}
          selectable={selfModerationEnabled}
          loading={loading || listLoading}
          sortOptions={sortOptions}
          sortValue={sortValue}
          onSortChange={handleSortValue}
          isFiltered={filtersDirty}
          filterControl={
            <Form onSubmit={handleFilterSubmit}>
              <div className="pb-2">
              <LegacyStack spacing="tight" alignment="center">
                <LegacyStack.Item fill>
                  <TextField
                    type="text"
                    placeholder="Filter reviews by customer email"
                    value={queryValue || ''}
                    onChange={handleQueryValueChange}
                    prefix={<Icon source={SearchIcon} />}
                    clearButton
                    onClearButtonClick={handleQueryValueRemove}
                    disabled={loading}
                  />
                </LegacyStack.Item>
                <Select
                  label="Rating"
                  labelInline
                  options={ratingOptions}
                  onChange={handleRatingFilter}
                  value={ratingFilter}
                  disabled={loading}
                />
                <Select
                  label="Type"
                  labelInline
                  options={typeOptions}
                  onChange={handleTypeFilter}
                  value={typeFilter}
                  disabled={loading}
                />
                <Select
                  label="Response"
                  labelInline
                  options={responseOptions}
                  onChange={handleResponseFilter}
                  value={responseFilter}
                  disabled={loading}
                />
                <ProductFilter
                  productFilter={productFilter}
                  setProductFilter={setProductFilter}
                  size="medium"
                />
                {
                  hasReviewTagging &&
                  <Select
                    label="Tag"
                    labelInline
                    options={tagOptions}
                    onChange={handleTagFilter}
                    value={tagFilter}
                    disabled={loading}
                  />
                }
                <Checkbox
                  label="With media"
                  checked={mediaFilter}
                  onChange={handleMediaFilter}
                />
                <Button disabled={loading} submit variant="primary">Search</Button>
              </LegacyStack>
              </div>
            </Form>
          }
          emptyState={!filtersDirty ? (
            <EmptyState
              heading={'Your reviews will display here'}
              image={noReviews}
              action={{
                content: 'Import reviews',
                onAction: () => { setShowImportReviewModal(true); trackEvent('Moderation - Import reviews') }
              }}
              secondaryAction={{
                content: 'Request review by campaign',
                url: '/flows/campaigns'
              }}
            >
              <p>
                Import content from your previous review platform, or collect your first reviews with Junip by running a campaign.
              </p>
            </EmptyState>
          ) : undefined}
          emptySearchState={
            <EmptyState
              image={noReviews}
              action={{ content: 'Reset filters', onAction: resetFilters }}
            >
              <p>
                There are no reviews with these filters
              </p>
            </EmptyState>
          }
        />
        <LegacyCard.Section separator>
          <div className="text-center">
            <Pagination
              hasPrevious={hasPrevious && selectedItems.length === 0}
              onPrevious={previousPage}
              hasNext={hasNext && selectedItems.length === 0}
              onNext={nextPage}
            />
          </div>
        </LegacyCard.Section>
      </LegacyCard>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  reviews: state.reviews,
  reviewTags: state.reviewTags,
  planFeatureSets: state.planFeatureSets,
  moderationSettings: state.moderationSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReviews: (data) => dispatch(fetchReviews(data)),
  fetchPlanFeatureSets: () => dispatch(fetchPlanFeatureSets()),
  fetchReviewTags: () => dispatch(fetchReviewTags()),
  updateReviews: (data) => dispatch(updateReviews(data)),
  showToast: (message, error) => dispatch(showToast(message, error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewsLayout);
