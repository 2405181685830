import React, { useState } from 'react';
import axios from 'axios';
import { LegacyCard, Link, TextContainer, Text } from "@shopify/polaris";

export default function PermissionDenied({ push, organization }) {
  const [loading, setLoading] = useState(false);

  const home = async () => {
    setLoading(true);
    try {
      await axios.post('/api/v1/users/sign_out');
    } catch (e) {
      // No-op
    } finally {
      setLoading(false);
      push('/login');
    }
  };

  const support = () => {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      window.open('mailto:support@juniphq.com', '_blank');
    }
  }

  return (
    <LegacyCard
      sectioned
      title="Permission denied"
      primaryFooterAction={{
        content: 'Contact support',
        onAction: support
      }}
      secondaryFooterActions={[{
        content: 'Take me home',
        onAction: home,
        loading: loading
      }]}
      footerActionAlignment="left">
      <TextContainer>
        <p>
          This app already belongs to <Text variant="bodyMd" as="span" fontWeight="semibold">{organization?.name}</Text>.{' '}
          If you wish to join this organization contact the owner and ask them to{' '}
          <Link url="https://help.junip.co/en/articles/4607137-inviting-a-new-user-to-junip" external>invite you</Link>.
        </p>
        <p>
          Don't know the owner? Contact support@juniphq.com.
        </p>
      </TextContainer>
    </LegacyCard>
  );
}
