import React, { useState, useEffect, useCallback } from 'react';
import useLastLocation from 'utils/useLastLocation';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { setFormDirty } from 'redux/settings';
import {
  fetchDisplaySettings,
  saveDisplaySettings
} from 'redux/displaySettings';
import {
  fetchStarOptions,
} from 'redux/starOptions';
import { savingChanges } from 'redux/settings';
import ColorPicker from 'components/ColorPicker';
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import ButtonPreview from 'components/ButtonPreview';
import StarPreview from 'components/StarPreview';
import LogoDropZone from 'components/LogoDropZone';
import {
  Page,
  PageActions,
  Layout,
  FormLayout,
  Button,
  Select,
  TextContainer,
  Banner,
  Link,
  Text,
} from "@shopify/polaris";

const BrandingSettings = (props) => {
  const lastLocation = useLastLocation();
  const [inputs, setInputs] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);

  const { fetchDisplaySettings, setFormDirty, saveDisplaySettings, fetchStarOptions } = props;
  const data = props.displaySettings.data || null;
  const settings = props.settings;

  useEffect(() => {
    return () => {
      setFormDirty(false);
    }
  }, [setFormDirty]);

  useEffect(() => {
    if (!props.displaySettings.data) {
      fetchDisplaySettings();
    }
  }, [fetchDisplaySettings, props.displaySettings.data]);

  useEffect(() => {
    if (!props.starOptions.data) {
      fetchStarOptions();
    }
  }, [fetchStarOptions, props.starOptions.data]);

  useEffect(() => {
    if (data) {
      setInputs(data);
    }
  }, [data]);

  useEffect(() => {
    if (settings.discardChanges) {
      if (data) {
        setInputs(data);
        setIsDirty(false);
        trackEvent('Branding - Discard changes');
      }
    }
  }, [data, settings.discardChanges]);

  useEffect(() => {
    if (settings.saving) {
      if (!isSaving) {
        saveDisplaySettings(data.id, inputs);
        setIsSaving(true);
        trackEvent('Branding - Save changes');
      }
    } else {
      if (isSaving) {
        setIsSaving(false);
      }
    }
  }, [data, inputs, isSaving, saveDisplaySettings, settings.saving]);

  const checkIsDirty = useCallback(() => {
    if (!data || typeof(data) !== 'object') {
      return;
    }
    const propertyNames = Object.getOwnPropertyNames(inputs);
    let dirty = false;
    for (const name of propertyNames) {
      if (inputs[name] !== data[name]) {
        dirty = true;
        break;
      }
    }

    if (dirty && !isDirty) {
      setIsDirty(true);
      if (!settings.isDirty) {
        setFormDirty(true);
      }
    } else if (!dirty && isDirty) {
      setIsDirty(false);
      if (settings.isDirty) {
        setFormDirty(false);
      }
    }
  }, [data, inputs, isDirty, setFormDirty, settings.isDirty]);

  useEffect(() => {
    checkIsDirty()
  }, [checkIsDirty]);

  const handleInputChange = (field) => {
    return (value) => {
      setInputs(inputs => ({...inputs, [field]: value}));
    };
  };

  const storeUrl = () => {
    let url = data?.store?.url;
    if (url && url.indexOf('http') === -1) {
      url = `https://${url}`;
    }
    return `${url}?junip-break-cache=true`;
  };

  const buttonColorOptions = [
    {label: 'Primary', value: 'primary'},
    {label: 'Secondary', value: 'secondary'},
  ];

  const buttonTextColorOptions = [
    {label: 'Light', value: 'light'},
    {label: 'Dark', value: 'dark'},
  ];

  const buttonShapeOptions = [
    {label: 'Sharp', value: 'sharp'},
    {label: 'Shaved', value: 'shaved'},
    {label: 'Rounded', value: 'rounded'},
    {label: 'Circular', value: 'circular'},
  ];

  const starColorOptions = [
    {label: 'Black', value: 'black'},
    {label: 'Gold', value: 'gold'},
    {label: 'Primary', value: 'primary'},
    {label: 'Secondary', value: 'secondary'},
  ];

  const allStarOptions = props.starOptions?.data?.map(star => {
    return { label: star.name, value: `${star.id}` };
  }) || [];

  const primaryAction = {
    content: 'Save',
    disabled: !isDirty,
    onAction: props.savingChanges,
    loading: settings.saving
  };

  return (
    <Page
      title="Branding settings"
      backAction={{
        content: 'Back',
        url: lastLocation || '/settings'
      }}
    >
      <Layout>
        <Layout.Section>
          <Banner
            tone="default"
          >
            <p>
              To optimize performance, Junip caches your settings across a global CDN. This means it may take a few minutes to see changes. To view changes right away, visit your store using this{' '}
              <Link
                url={storeUrl()}
                external
                onClick={() => trackEvent('Branding banner - Visit store')}
              >
                link
              </Link>.
              You can also preview Junip with sample data using the 'Preview Junip' button found in{' '}
              <Link url="/on_site">on-site section</Link>.
            </p>
          </Banner>
        </Layout.Section>
        <Layout.AnnotatedSection
          title="Logo"
          description="Upload your brand logo"
        >
          <LoadingCardWrapper loading={props.displaySettings.loading || props.starOptions.loading}>
            <LogoDropZone
              logoUrl={inputs.logo_url}
              handleInputChange={handleInputChange}
              discardChanges={settings.discardChanges}
              removeImage={removeImage}
              setRemoveImage={setRemoveImage}
            />
            <div className="mt-2">
              <TextContainer>
                <Text variant="bodyMd" as="span" tone="subdued">Drag and drop an image file or click above to upload your logo.</Text>
                <p>
                  <Text variant="bodyMd" as="span" tone="subdued">We render the logo 150px wide, but recommend uploading an asset that's 300px wide so it appears higher fidelity on fancy screens.</Text>
                </p>
              </TextContainer>
            </div>
            { inputs.logo_url &&
              <div className="mt-2">
                <Button
                  onClick={() => {
                    handleInputChange('logo')(null);
                    handleInputChange('logo_url')(null);
                    setRemoveImage(true)
                  }}>Remove image</Button>
              </div>
            }
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Colors"
          description="Set your brand colors"
        >
          <LoadingCardWrapper loading={props.displaySettings.loading}>
            <FormLayout>
              <div>
                <p className="mb-1">Primary color</p>
                <ColorPicker colorKey="primary_color" colorValue={inputs.primary_color} handleInputChange={handleInputChange} />
              </div>
              <div>
                <p className="mb-1">Secondary color</p>
                <ColorPicker colorKey="secondary_color" colorValue={inputs.secondary_color} handleInputChange={handleInputChange} />
              </div>
            </FormLayout>
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Buttons"
          description="Configure the look and feel of your CTAs"
        >
          <LoadingCardWrapper loading={props.displaySettings.loading}>
            <FormLayout>
              <Select
                label="Button color"
                options={buttonColorOptions}
                value={inputs.button_color_option}
                onChange={handleInputChange('button_color_option')}
              />
              <Select
                label="Button text color"
                options={buttonTextColorOptions}
                value={inputs.button_text_color_option}
                onChange={handleInputChange('button_text_color_option')}
              />
              <Select
                label="Button shape"
                options={buttonShapeOptions}
                value={inputs.button_border_radius_option}
                onChange={handleInputChange('button_border_radius_option')}
              />
              <ButtonPreview
                buttonColor={inputs[`${inputs.button_color_option}_color`]}
                buttonTextColor={inputs[`${inputs.button_text_color_option}_text_color`]}
                buttonShape={inputs[`${inputs.button_border_radius_option}_border_radius`]}
              />
            </FormLayout>
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Stars"
          description="Edit the look of your review stars"
        >
          <LoadingCardWrapper loading={props.displaySettings.loading}>
            <FormLayout>
              <Select
                label="Color"
                options={starColorOptions}
                value={inputs.star_color_option || 1}
                onChange={handleInputChange('star_color_option')}
              />
              <FormLayout.Group condensed>
                <Select
                  label="Star Style"
                  options={allStarOptions}
                  value={`${inputs.star_option_id}`}
                  onChange={handleInputChange('star_option_id')}
                  helpText="Note: Holiday star packs don't respect color settings. Remember to switch back to your preferred star style after the holidays!"
                />
              </FormLayout.Group>
              <div className="branding-star-preview">
                <StarPreview
                  color={inputs[`${inputs.star_color_option}_color`]}
                  star_option={props.starOptions?.data?.find(x => x.id === (parseInt(inputs.star_option_id || 1)))}
                />
              </div>
            </FormLayout>
          </LoadingCardWrapper>
        </Layout.AnnotatedSection>
        <Layout.Section>
          <PageActions
            primaryAction={primaryAction}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  displaySettings: state.displaySettings,
  starOptions: state.starOptions
});

const mapDispatchToProps = (dispatch) => ({
  setFormDirty: (dirty) => dispatch(setFormDirty(dirty)),
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  saveDisplaySettings: (id, settings) => dispatch(saveDisplaySettings(id, settings)),
  savingChanges: () => dispatch(savingChanges()),
  fetchStarOptions: () => dispatch(fetchStarOptions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandingSettings);
