import React, { useCallback, useEffect, useState } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import useInterval from 'utils/useInterval';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'redux/toast';
import { fetchEventTopics } from 'redux/eventTopics';
import { fetchFlows } from 'redux/flows';
import axios from 'axios';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import {
  LegacyCard,
  Page,
  LegacyTabs,
  TextField,
  Button,
  FormLayout,
  ButtonGroup,
  Banner,
  InlineCode,
  BlockStack,
  Text,
  Link
} from '@shopify/polaris';
import { ViewIcon, HideIcon } from "@shopify/polaris-icons";

export default function CustomApp({ match }) {
  const { slug } = match.params;
  const dispatch = useDispatch();
  const eventTopics = useSelector(state => state.eventTopics);
  const flows = useSelector(state => state.flows);

  const [selectedTab, setSelectedTab] = useState(0);
  const [app, setApp] = useState(null);
  const [appInstall, setAppInstall] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSecret, setShowSecret] = useState(false);
  const [endpointUrl, setEndpointUrl] = useState('');
  const [eventDestination, setEventDestination] = useState(null);
  const [urlState, setUrlState] = useState('');
  const [eventSubscriptions, setEventSubscriptions] = useState([]);
  const [saving, setSaving] = useState(false);
  const [pollingDelay, setPollingDelay] = useState(null);
  const [challenging, setChallenging] = useState(false);
  const [installing, setInstalling] = useState(false);
  const [uninstalling, setUninstalling] = useState(false);

  const fetchApp = useCallback(async (toggleLoading = true) => {
    if (toggleLoading) {
      setLoading(true);
    }
    try {
      const response = await axios.get(`/api/v1/apps/${slug}`, {
        params: {
          include: 'event_subscriptions,event_destination'
        }
      });
      const app = response.data?.app;
      setApp(app);
      setEventDestination(app?.event_destination);
      setEventSubscriptions(app?.event_subscriptions);
    } catch (e) {
      dispatch(showToast(
        'Failed to fetch app.',
        true
      ));
    } finally {
      setLoading(false);
    }
  } , [dispatch, slug]);

  const createEventSubscriptions = useCallback(async () => {
    const eventTopic = eventTopics?.data?.find(topic => topic.topic_string === 'review/submitted');

    if (!eventTopic) return;

    try {
      await axios.post('/api/v1/event_subscriptions', {
        event_subscription: {
          event_destination_id: eventDestination?.id,
          event_topic_id: eventTopic?.id
        }
      });
      fetchApp(false);
    } catch (e) {
      // No-op
    }
  }, [eventDestination, fetchApp, eventTopics]);

  useEffect(() => {
    fetchApp();
  } , [dispatch, slug, fetchApp]);

  useEffect(() => {
    dispatch(fetchEventTopics());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFlows());
  }, [dispatch]);

  useEffect(() => {
    async function fetchAppInstall(appId) {
      setLoading(true);
      const response = await axios.get(`/api/v1/app_installs`, {
        params: {
          'filter[app_id]': appId,
        }
      });

      const appInstall = response?.data?.app_installs?.[0];
      setAppInstall(appInstall || null);
      setLoading(false);
    }

    if (app?.id) {
      fetchAppInstall(app?.id);
    }
  }, [app?.id]);

  useEffect(() => {
    if (eventDestination?.id) {
      setEndpointUrl(eventDestination?.uri);
      setPollingDelay(3000);

      if (!eventSubscriptions?.length) {
        createEventSubscriptions();
      }
    }
  }, [eventDestination, eventSubscriptions, createEventSubscriptions]);

  useInterval(() => {
    async function fetchEventDestination(id) {
      const response = await axios.get(`/api/v1/event_destinations/${id}`);

      setUrlState(response?.data?.event_destination?.state);
    }

    if (eventDestination?.id) {
      fetchEventDestination(eventDestination?.id);
    }
  }, pollingDelay);

  const handleTabChange = (selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  };

  const copy = (name, text) => {
    navigator.clipboard.writeText(text);
    dispatch(showToast(
      `${name} copied to clipboard.`
    ));
  };

  const isDirty = () => {
    return endpointUrl !== eventDestination?.uri && endpointUrl !== '';
  };

  const updateOrCreateEventDestination = async () => {
    setSaving(true);
    const method = eventDestination ? 'put' : 'post';
    const url = eventDestination ? `/api/v1/event_destinations/${eventDestination?.id}` : '/api/v1/event_destinations';
    try {
      const response = await axios({
        method,
        url,
        data: {
          event_destination: {
            uri: endpointUrl,
            app_id: app?.id,
            name: app?.name
          }
        }
      });

      setEventDestination(response.data?.event_destination);

      dispatch(showToast(
        'URL saved successfully.'
      ));

      trackEvent('App development - Save URL');
    } catch (e) {
      dispatch(showToast(
        'Failed to save url. Please ensure your URL is a valid HTTPS URL.',
        true
      ));

      trackEvent('Error: App development - Save URL');
    } finally {
      setSaving(false);
    }
  };

  const installApp = async () => {
    setInstalling(true);

    try {
      const response = await axios.post(`/api/v1/app_installs`, {
        app_install: {
          app_id: app?.id
        }
      });

      setAppInstall(response.data?.app_install);
      trackEvent('App development - Install');

      dispatch(showToast(
        'App installed successfully.'
      ));

    } catch (e) {
      dispatch(showToast(
        'Failed to install app.',
        true
      ));
      trackEvent('Error: App development - Install', { statusCode: e?.response?.status });
    } finally {
      setInstalling(false);
    }
  };

  const uninstallApp = async () => {
    setUninstalling(true);

    try {
      await axios.delete(`/api/v1/app_installs/${appInstall?.id}`);

      setAppInstall(null);
      trackEvent('App development - Uninstall');

      dispatch(showToast(
        'App uninstalled successfully.'
      ));
    } catch (e) {
      dispatch(showToast(
        'Failed to uninstall app.',
        true
      ));

      trackEvent('Error: App development - Uninstall');
    } finally {
      setUninstalling(false);
    }
  };

  const handleUrlChange = useCallback((value) => {
    setEndpointUrl(value.replace(/[^A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]/g, ''));
  }, []);

  const challengeUrl = async () => {
    setChallenging(true);
    setPollingDelay(null);
    await axios.put(`/api/v1/event_destinations/${eventDestination?.id}/challenge`);
    setPollingDelay(3000);
    setChallenging(false);
  };

  const renderUrlStateBanner = () => {
    if (!eventDestination?.id) {
      return null;
    }

    const state = urlState || eventDestination?.state;

    let banner = null;

    const retestLink = (
      <Button
        onClick={() => challengeUrl()}
        loading={challenging}
        variant="monochromePlain">
        Retest
      </Button>
    );

    switch (state) {
      case 'undiagnosed':
        banner = (
          <Banner
            tone="info"

          >
            <p>
              We're currently diagnosing your URL. This can take up to 30 seconds.
            </p>
          </Banner>
        );
        break;
      case 'healthy':
        banner = (
          <Banner
            tone="success"
          >
            <p>
              Your URL is healthy and ready to receive events.
            </p>
          </Banner>
        );
        break;
      case 'sick':
        banner = (
          <Banner
            tone="warning"
          >
            <p>
              Your URL is unhealthy and is not properly responding to events. {retestLink}
            </p>
          </Banner>
        );
        break;
      case 'dead':
        banner = (
          <Banner
            tone="critical"
          >
            <p>
              Your URL is not responding to events. {retestLink}
            </p>
          </Banner>
        );
        break;
      default:
        return null;
    }
    return (
      <div className="w-100">
        {banner}
      </div>
    );
  };

  const tabs = [
    {
      id: 'configuration',
      content: 'Configuration',
      accessibilityLabel: 'Configuration',
      panelID: 'configuration-content'
    },
    {
      id: 'api-credentials',
      content: 'API Credentials',
      accessibilityLabel: 'API Credentials',
      panelID: 'api-credentials-content'
    }
  ];

  const reviewSubmittedFlow = flows?.data?.find(flow => {
    const eventTopic = flow?.event_topic;

    return eventTopic?.subject === 'review' && eventTopic?.action === 'submitted';
  });

  return (
    <LoadingPageWrapper loading={loading || eventTopics?.loading}>
      <Page
        title={app?.name}
        backAction={{
          content: 'Back',
          accessibilityLabel: 'Back',
          url: '/integrations/custom'
        }}
      >
        <div className="mb-4">
          <LegacyTabs
            tabs={tabs}
            selected={selectedTab}
            onSelect={handleTabChange}
          />
        </div>
        {selectedTab === 0 &&
          <>
            <LegacyCard title="1. Endpoint configuration">
              <LegacyCard.Section>
                <p>
                  Each time a review is submitted through Junip, a{' '}
                  <InlineCode>review-submitted</InlineCode>{' '}
                  event will be send to your endpoint URL.
                </p>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <BlockStack gap="300" align="start" inlineAlign="start">
                  <div className="w-100">
                    <TextField
                      label="URL"
                      placeholder="https://example.com"
                      value={endpointUrl}
                      onChange={handleUrlChange}
                    />
                  </div>
                  { renderUrlStateBanner() }
                  <Text variant="body" tone="subdued">
                    When you type and save your URL, we'll send a request with a challenge parameter in order to verify the address.
                    Your endpoint must respond with the expected value.{' '}
                    <Link target="_blank" url="https://junip.co/docs/api/webhooks">
                      Learn more
                    </Link>
                  </Text>
                  <Button
                    disabled={!isDirty()}
                    loading={saving}
                    onClick={updateOrCreateEventDestination}
                    variant="primary">
                    Save
                  </Button>
                </BlockStack>
              </LegacyCard.Section>
            </LegacyCard>
            <LegacyCard title="2. Install app on your store">
              <LegacyCard.Section>
                <p>
                  Once you've saved and setup your endpoint URL, you can install your app on your store.
                </p>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <ButtonGroup>
                  <Button

                    loading={installing}
                    disabled={appInstall?.id || uninstalling || !eventDestination?.id}
                    onClick={installApp}
                    variant="primary">
                    Install
                  </Button>
                  <Button
                    loading={uninstalling}
                    disabled={!appInstall?.id || installing}
                    onClick={uninstallApp}

                    variant="primary"
                    tone="critical">
                    Uninstall
                  </Button>
                </ButtonGroup>
              </LegacyCard.Section>
            </LegacyCard>
            <LegacyCard title="3. Set up your Review submitted actions flow">
              <LegacyCard.Section>
                <p>
                  Once your app is installed, you can configure your review submitted actions flow to send review data to your new app.
                </p>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <Button

                  url={`/flows/${reviewSubmittedFlow?.id}`}
                  disabled={!appInstall?.id}
                  variant="primary">
                  Configure flow
                </Button>
              </LegacyCard.Section>
            </LegacyCard>
          </>
        }
        {selectedTab === 1 &&
          <LegacyCard title="API Credentials" sectioned>
            <FormLayout>
            <TextField
              readOnly
              type="text"
              label="API Key"
              value={app?.client_id}
              connectedRight={
                <Button
                  onClick={() => copy('API Key', app?.client_id)}
                  size="large"
                >
                  Copy
                </Button>
              }
            />
            <TextField
              type={showSecret ? 'text' : 'password'}
              label="Secret Key"
              readOnly
              value={app?.client_secret}
              connectedRight={
                <ButtonGroup gap="extraTight">
                  <Button
                    size="large"
                    onClick={() => setShowSecret(!showSecret)}
                    icon={showSecret ? HideIcon : ViewIcon}
                  />
                  <Button
                    size="large"
                    onClick={() => copy('Secret Key', app?.client_secret)}
                  >
                    Copy
                  </Button>
                </ButtonGroup>
              }
            />
            </FormLayout>
          </LegacyCard>
        }
      </Page>
    </LoadingPageWrapper>
  );
}
