import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from 'redux/rootReducer';

const intialState = {
  user: {
    data: null,
    loggedIn: null,
    loading: false,
    error: null
  }
}

const store = createStore(
  rootReducer,
  intialState,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
);

export default store;
