import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { useSelector, useDispatch } from 'react-redux';
import { showToast } from 'redux/toast';
import {
  Modal,
  FormLayout,
  TextField,
  Checkbox,
  RangeSlider,
  TextContainer,
  Button,
  Collapsible,
  Icon,
  Text,
  BlockStack,
} from "@shopify/polaris";
import { fetchShippingLocations } from 'redux/shippingLocations';
import ShippingLocationsSelector from 'components/ShippingLocationsSelector';
import { ClockIcon, FilterIcon } from "@shopify/polaris-icons";

export const MAX_TIME_DELAY = 60;

export default function SolicitationConditions({ flow, editing, setEditing, action, updateActionConditionList }) {
  const [conditions, setConditions] = useState(action?.condition_list);
  const [inputs, setInputs] = useState({});
  const [saving, setSaving] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [internationalDelayEnabled, setInternationalDelayEnabled] = useState(false);
  const shippingLocations = useSelector((state) => state.shippingLocations);
  const dispatch = useDispatch();

  const isDeliveredTrigger = flow?.trigger?.trigger_type.includes('delivered');

  const resetInputs = useCallback(() => {
    if (conditions) {
      setInputs({
        max_requests_per_product: conditions.max_requests_per_product,
        product_review_percent: conditions.product_review_percent,
        queue_time_days: conditions.queue_time_days,
        international_queue_time_days: conditions.international_queue_time_days,
        override_queue_time_days: conditions.override_queue_time_days,
        marketing_check_disabled: conditions.marketing_check_disabled
      });
    } else {
      setInputs({});
    }
  }, [conditions]);

  useEffect(() => {
    if (!shippingLocations.data) {
      dispatch(fetchShippingLocations());
    }
  }, [dispatch, shippingLocations.data]);

  useEffect(() => {
    if (conditions) {
      resetInputs();
      if (conditions?.international_queue_time_days) {
        setInternationalDelayEnabled(true);
      }
    }
  }, [conditions, resetInputs]);

  const handleInputChange = (field) => {
    return (value) => {
      if (field === 'auto_approve_min_rating') {
        value = parseInt(value);
      }
      if (field === 'queue_time_days' || field === 'international_queue_time_days' || field === 'override_queue_time_days') {
        if (value === '') {
          value = 0;
        }
      }
      setInputs(inputs => ({ ...inputs, [field]: value }));
    };
  };

  const handleInternationalDelayChange = useCallback((newChecked) => {
    setInternationalDelayEnabled(newChecked);
    if (newChecked === false) {
      handleInputChange('international_queue_time_days')(null);
    }
  }, []);

  const save = async () => {
    try {
      setSaving(true);
      const response = await axios.put(`/api/v1/flow_condition_lists/${conditions.id}`, {
        flow_condition_list: inputs
      });
      const newConditions = response.data.flow_condition_list;
      setConditions(newConditions);
      dispatch(showToast('Conditions updated'));
      trackEvent('Flows - Save conditions');
      setEditing(false);
      updateActionConditionList(action.id, newConditions);
    } catch (e) {
      dispatch(showToast('Error updating conditions, please try again', true));
      trackEvent('Error: Flows - Save conditions', { statusCode: e?.response?.status });
    } finally {
      setSaving(false);
    }
  };

  return <>
    <TextContainer spacing="loose">
      <div className="d-flex">
        <div className="mr-3">
          <Icon source={ClockIcon} tone="primary" />
        </div>
        <TextContainer spacing="tight">
          {isDeliveredTrigger ? (
            <>
              <p>
                <Text variant="bodyMd" as="span" fontWeight="semibold">Wait {conditions.override_queue_time_days} {conditions.override_queue_time_days === 1 ? 'day' : 'days'}</Text>
              </p>
            </>
          ) : (
            <>
              <p>
                <Text variant="bodyMd" as="span" fontWeight="semibold">Wait {conditions.queue_time_days} {conditions.queue_time_days === 1 ? 'day' : 'days'}</Text>
              </p>
              {conditions.international_queue_time_days && (
                <p>
                  <Text variant="bodyMd" as="span" fontWeight="semibold">Wait {conditions.international_queue_time_days} {conditions.international_queue_time_days === 1 ? 'day' : 'days'} for international orders</Text>
                </p>
              )}
            </>
          )}
        </TextContainer>
      </div>
      <div className="d-flex">
        <div className="mr-3">
          <Icon tone="primary" source={FilterIcon} />
        </div>
        <p>
          <Text variant="bodyMd" as="span" tone="subdued">
            <Button
              removeUnderline
              onClick={() => setEditing(true)}
              variant="monochromePlain">
              2 filters
            </Button>
          </Text>
        </p>
      </div>
    </TextContainer>
    <Modal
      open={editing}
      onClose={() => setEditing(false)}
      title="Conditions"
      primaryAction={{
        content: 'Save',
        onAction: () => save(),
        loading: saving,
        disabled: internationalDelayEnabled && shippingLocations?.data?.length < 1,
      }}
      secondaryActions={[{
        content: 'Cancel',
        onAction: () => setEditing(false),
        disabled: saving,
      }]}
    >
      {isDeliveredTrigger && (
        <Modal.Section>
          <TextField
            label="Time delay"
            type="number"
            max={MAX_TIME_DELAY}
            min={0}
            pattern="[0-9]"
            value={`${inputs.override_queue_time_days || 0}`}
            onChange={handleInputChange('override_queue_time_days')}
            helpText="Add a delay before this action is sent"
            suffix="days"
            disabled={saving}
          />
        </Modal.Section>
      )}
      <Modal.Section subdued={isDeliveredTrigger ? true : undefined}>
        <FormLayout>
          {isDeliveredTrigger && (
            <TextContainer>
              <Text variant="headingSm" as="h3">
                <Text variant="bodyMd" as="span" tone="subdued">Fallback conditions</Text>
              </Text>
              <p>
                In some cases, an order may never be marked as delivered. Set the maximum time Junip should wait to send this action after an order is marked as fulfilled.
              </p>
            </TextContainer>
          )}
          <TextField
            label={!isDeliveredTrigger ? 'Delay' : 'Fallback time delay'}
            type="number"
            max={MAX_TIME_DELAY}
            min={0}
            pattern="[0-9]"
            value={`${inputs.queue_time_days || 0}`}
            onChange={handleInputChange('queue_time_days')}
            helpText={!isDeliveredTrigger && 'Set delay between trigger and review request action.'}
            suffix="days"
            disabled={saving}
          />
          <Checkbox
            label="Use a different delay for international orders"
            checked={internationalDelayEnabled}
            onChange={handleInternationalDelayChange}
            disabled={saving}
          />
          {internationalDelayEnabled &&
            <BlockStack blockAlign="start" gap="200">
              <ShippingLocationsSelector fetchShippingLocations={fetchShippingLocations} dispatch={dispatch} shippingLocations={shippingLocations} />
              <TextField
                label="International delay"
                type="number"
                max={MAX_TIME_DELAY}
                min={0}
                pattern="[0-9]"
                value={`${inputs.international_queue_time_days || 0}`}
                onChange={handleInputChange('international_queue_time_days')}
                suffix="days"
                helpText={
                  <>
                    This delay will be used for orders placed outside your domestic shipping locations.
                  </>
                }
                disabled={saving}
              />
            </BlockStack>
          }
        </FormLayout>
      </Modal.Section>
      <Modal.Section>
        <FormLayout>
          <Text variant="headingSm" as="h3">
            <Text variant="bodyMd" as="span" tone="subdued">Recipient filters</Text>
          </Text>
          <Checkbox
            label="Exclude customers who have already reviewed the same product. "
            checked
            disabled
          />
          <Checkbox
            label="Require marketing opt-in"
            helpText="Only send review requests to customers who accept marketing communications."
            checked={inputs.marketing_check_disabled === false}
            onChange={(value) => handleInputChange('marketing_check_disabled')(!value)}
            disabled={saving}
          />
        </FormLayout>
      </Modal.Section>
      {action?.action_type === 'email' && (
        <Modal.Section subdued>
          <TextContainer>
            <Button
              disclosure={advancedOpen ? 'up' : 'down'}
              onClick={() => setAdvancedOpen(!advancedOpen)}
              ariaExpanded={advancedOpen}
              ariaControls="advanced-options"
              variant="monochromePlain">
              <Text variant="headingSm" as="h3">Advanced conditions</Text>
            </Button>
            <Collapsible
              id="advanced-options"
              open={advancedOpen}
            >
              <TextContainer>
                {false && (
                  <TextField
                    label="Max review requests (per product)"
                    helpText="This limits the number of review requests that can be sent to the same customer."
                    type="number"
                    max={10}
                    min={1}
                    pattern="[0-9]"
                    value={`${inputs.max_requests_per_product || 10}`}
                    onChange={handleInputChange('max_requests_per_product')}
                    disabled={saving}
                  />
                )}
                <RangeSlider
                  label="Product review percent"
                  value={inputs.product_review_percent}
                  onChange={handleInputChange('product_review_percent')}
                  output
                  helpText="The percent at which a review request will be for a product review vs. a store review."
                  disabled={saving}
                  suffix={<p>{' '}{inputs.product_review_percent}</p>}
                />
              </TextContainer>
            </Collapsible>
          </TextContainer>
        </Modal.Section>
      )}
    </Modal>
  </>;
}