import React from 'react';
import { connect } from 'react-redux';
import UnifiedOutbox from 'components/UnifiedOutbox';

function Outbox({ stores, history }) {
  return (
    <UnifiedOutbox push={history.push} />
  );
}

const mapStateToProps = (state) => ({
  stores: state.stores,
});

export default connect(
  mapStateToProps,
)(Outbox);
