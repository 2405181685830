import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import flowDetails from 'utils/flowDetails';
import { appListingDetails } from 'utils/appListingDetails';
import {
  DataTable,
  Badge,
  Link,
  Icon,
  Tooltip,
  LegacyStack,
  EmptyState,
  Pagination,
  Spinner,
  Button,
  Popover,
  ActionList,
  Modal,
  TextField,
  Thumbnail,
  Tag
} from '@shopify/polaris';

import {
  InfoIcon,
  SendIcon,
  ClockIcon,
  XIcon,
  ReplayIcon,
  MenuHorizontalIcon,
  EmailIcon,
} from "@shopify/polaris-icons";

import noMessages from 'assets/images/noMessages.svg';

function FlowRidersTable({ push, selected, handleTabChange, showToast, messages }) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [meta, setMeta] = useState(null);
  const [sortDirection, setSortDirection] = useState('descending');

  const handleSort = useCallback((_, direction) => setSortDirection(direction), []);

  const renderState = (data) => {
    const state = <p style={{textTransform: 'capitalize'}}>{data?.state}</p>;
    switch(data.state) {
      case 'pending':
        return (
          <Badge progress="incomplete">{state}</Badge>
        );
      case 'queued':
        const runAt = moment(data.run_at);
        const diff = runAt.diff(moment.utc());
        if (diff <= 0) {
          return <Badge progress="complete" tone="success">Completed</Badge>;
        } else {
          return <Badge progress="partiallyComplete" tone="success">In transit</Badge>;
        }
      case 'completed':
        return <Badge progress="complete" tone="success">Completed</Badge>;
      case 'cancelled':
        return (
          <Tooltip content={data.state_reason || 'This action was cancelled'}>
            <Badge tone="warning">{state}</Badge>
          </Tooltip>
        );
      case 'failed':
        return (
          <Tooltip content={data.state_reason || 'This action failed to complete'}>
            <Badge tone="critical">{state}</Badge>
          </Tooltip>
        );
      default:
        return (<Badge>{state}</Badge>);
    }
  };

  const renderFlow = ({ flow }) => {
    const { title } = flowDetails(flow);

    return (
      <Link monochrome url={`/flows/${flow.id}`}>{ title }</Link>
    );
  };

  const renderEventName = (data) => {
    const action = (
      <span style={{ textTransform: 'capitalize' }}>
        Junip - {`${data?.flow?.event_topic?.subject} ${data?.flow?.event_topic?.action?.replaceAll?.('-', ' ')}`}
      </span>
    );

    return <Tag>{action}</Tag>;
  };

  const renderApp = (data) => {
    const appInstall = data?.flow_action?.app_install;
    if (appInstall) {
      const appDetails = appListingDetails[appInstall.provider];
      const source = appDetails?.icon;
      return (
        <Tooltip content={appDetails?.name}>
          <Thumbnail size="extraSmall" source={source} alt={appDetails?.name} />
        </Tooltip>
      );
    }

    return (<p>-</p>);
  };

  const renderEvent = (data) => {
    return (
      <LegacyStack>
        {renderEventName(data)}
        {renderApp(data)}
      </LegacyStack>
    );
  };

  const renderEmail = ({ flow_action }) => {
    return (
      <Tooltip content="Junip email">
        <Icon source={EmailIcon} tone="info" />
      </Tooltip>
    );
  };


  const renderAction = (data) => {
    const actionType = data.flow_action?.action_type;
    switch(actionType) {
      case 'event':
        return renderEvent(data);
      case 'email':
        return renderEmail(data);
      default:
        return '';
    }
  };

  const renderCustomer = ({ customer }) => {
    return (
      <Tooltip content={
        <div>
          <p>{customer?.first_name || 'No'} {customer?.last_name || 'name'}</p>
          {customer.accepts_marketing && <p>Accepts marketing</p>}
        </div>
      }>
        <Link>{customer?.email}</Link>
      </Tooltip>
    );
  };

  const actionCallback = (oldData, newData, index) => {
    const data = { ...oldData, ...newData };
    if (data) {
      const newRow = renderRow(index, data);
      setRows((state) => {
        const newState = [...state];
        newState[index] = newRow;
        return newState;
      });
    }
  };

  const renderOptions = (data, index) => {
    return (<QueueActions showToast={showToast} data={data} index={index} callback={actionCallback} />);
  };

  const renderRow = useCallback((index, data) => {
    const row = [];
    // Schedule
    row.push(moment(data.run_at).format('MMM D Y, h:mm a'));
    // State
    row.push(renderState(data));
    // Flow
    row.push(renderFlow(data));
    // Action
    row.push(renderAction(data));
    // Recipient
    row.push(renderCustomer(data));
    // Options
    row.push(renderOptions(data, index));
    return row;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseData = useCallback((data) => {
    let newRows = [];
    for (const [index, value] of data?.flow_riders?.entries()) {
      newRows.push(renderRow(index, value));
    }
    setRows(newRows);
  }, [renderRow]);

  const fetchData = useCallback(async ({ before = null, after = null }) => {
    setLoading(true);
    try {
      const response = await axios.get('/api/v1/flow_riders', {
        params: {
          include: 'customer,flow_action.message_type,flow_action.app_install,flow.event_topic',
          'sort[field]': 'run_at',
          'sort[order]': sortDirection === 'ascending' ? 'asc' : 'desc',
          'filter[state]': selected === 0 ? 'pending' : 'cancelled',
          'page[after]': after,
          'page[before]': before
        }
      });
      setMeta(response.data.meta);
      parseData(response.data);
    } catch (e) {
      showToast('Error fetching data, please try again.', true);
    } finally {
      setLoading(false);
    }
  }, [parseData, showToast, sortDirection, selected]);

  useEffect(() => {
    fetchData({});
  }, [fetchData, sortDirection, selected]);

  const nextPage = (after) => {
    fetchData({ after });
  };

  const previousPage = (before) => {
    fetchData({ before });
  };

  return <>
    {loading &&
      <div className="text-center" style={{ paddingTop: '2.5rem', paddingBottom: '4rem' }}>
        <Spinner />
      </div>
    }
    {!loading && rows.length > 0 &&
      <DataTable
        verticalAlign="middle"
        columnContentTypes={[
          'text',
          'text',
          'text',
          'text',
          'text',
          'numeric',
        ]}
        headings={[
          <div className="d-flex align-items-center">
            <p className="mr-1">Schedule</p>
            <Tooltip content="When this action is scheduled to go out">
              <Icon source={InfoIcon} accessibilityLabel="Schedule explainer" />
            </Tooltip>
          </div>,
          'State',
          'Flow',
          'Action',
          'Recipient',
          '',
        ]}
        sortable={[true, false, false, false, false, false]}
        defaultSortDirection={sortDirection}
        initialSortColumnIndex={3}
        onSort={handleSort}
        rows={rows}
        footerContent={
          <Pagination
            hasPrevious={meta?.page?.before !== null}
            hasNext={meta?.page?.after !== null}
            onPrevious={() => previousPage(meta?.page?.before)}
            onNext={() => nextPage(meta?.page?.after)}
          />
        }
      />
    }
    {!loading && rows.length === 0 &&
      <EmptyState
        heading={`No ${selected === 0 ? 'queued' : 'incomplete'} actions`}
        image={noMessages}
        action={{
          content: selected === 0 ? 'View sent' : 'View queue',
          onAction: () => selected === 0 ? handleTabChange(1) : handleTabChange(0)
        }}
      >
        <p>
          { selected === 0 ?
            'There are no actions queued to go out, please check back later. In the meantime, view actions that have already been sent out'
          :
            'There are no incomplete actions. Click below to view actions that are queued to go out'
          }
        </p>
      </EmptyState>
    }
  </>;
}

function QueueActions ({showToast, data, index, callback}) {
  const [popoverActive, setPopoverActive] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showDelayModal, setShowDelayModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [working, setWorking] = useState(false);
  const [delayDays, setDelayDays] = useState('0');

  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), []);
  const handleChange = useCallback((newValue) => setDelayDays(newValue), []);

  const sendNow = async () => {
    setWorking(true);
    const newDate = moment.utc();
    try {
      const response = await axios.put(`/api/v1/flow_riders/${data.id}`, {
        flow_rider: {
          run_at: newDate.format()
        }
      });
      callback(data, {...response.data.flow_rider, state: 'queued'}, index);
      setWorking(false);
      setShowSendModal(false);
      showToast('Action set to in transit');
      trackEvent('Queue actions - Send now');
    } catch (e) {
      setWorking(false);
      showToast('Error sending action, please try again', true);
      trackEvent('Error: Queue actions - Send now', { statusCode: e?.response?.status });
    }
  };

  const delay = async () => {
    setWorking(true);
    const newDate = moment(data.run_at).add(delayDays, 'days');
    try {
      const response = await axios.put(`/api/v1/flow_riders/${data.id}`, {
        flow_rider: {
          run_at: newDate.format()
        }
      });
      callback(data, response.data.flow_rider, index);
      setWorking(false);
      setShowDelayModal(false);
      showToast(`Action delayed by ${delayDays} day(s)`);
      setDelayDays('0');
      trackEvent('Queue actions - Delay');
    } catch (e) {
      setWorking(false);
      showToast('Error delaying action, please try again', true);
      trackEvent('Error: Queue actions - Delay', { statusCode: e?.response?.status });
    }
  };

  const cancel = async () => {
    setWorking(true);
    try {
      const response = await axios.put(`/api/v1/flow_riders/${data.id}/cancel`);
      callback(data, response.data.flow_rider, index);
      setWorking(false);
      setShowCancelModal(false);
      showToast('Action cancelled');
      trackEvent('Queue actions - Cancel action');
    } catch (e) {
      setWorking(false);
      showToast('Error cancelling action, please try again', true);
      trackEvent('Error: Queue actions - Cancel action', { statusCode: e?.response?.status });
    }
  };

  const retry = async () => {
    setWorking(true);
    try {
      const response = await axios.put(`/api/v1/flow_riders/${data.id}/retry`);
      callback(data, response.data.flow_rider, index);
      setWorking(false);
      setShowRetryModal(false);
      showToast('Action requeued');
      trackEvent('Queue actions - Retry action');
    } catch (e) {
      setWorking(false);
      showToast('Error retrying action, please try again', true);
      trackEvent('Error: Queue actions - Retry action', { statusCode: e?.response?.status });
    }
  };

  if (data?.state !== 'pending') {
    return <>
      <Button
        icon={ReplayIcon}
        size="slim"
        onClick={() => setShowRetryModal(true)}
      >
        Retry
      </Button>
      <Modal
        open={showRetryModal}
        title="Retry action?"
        onClose={() => !working && setShowRetryModal(false)}
        primaryAction={{
          content: 'Retry',
          loading: working,
          onAction: retry
        }}
        secondaryActions={[{
          content: 'Cancel',
          disabled: working,
          onAction: () => setShowRetryModal(false)
        }]}
      >
        <Modal.Section>
          <p>
            Are you sure you want to retry sending this action?
          </p>
        </Modal.Section>
      </Modal>
    </>;
  }

  return <>
    <Popover
      active={popoverActive}
      onClose={togglePopoverActive}
      activator={
        <Button
          icon={MenuHorizontalIcon}
          size="slim"
          onClick={togglePopoverActive}
        >
        </Button>
      }
    >
      <ActionList
        items={[
          {
            content: 'Send now',
            icon: SendIcon,
            onAction: () => setShowSendModal(true)
          },
          {
            content: 'Delay action',
            icon: ClockIcon,
            onAction: () => setShowDelayModal(true)
          },
          {
            content: 'Cancel action',
            destructive: true,
            icon: XIcon,
            onAction: () => setShowCancelModal(true)
          },
        ]}
      />
    </Popover>
    <Modal
      open={showSendModal}
      title="Send now?"
      onClose={() => !working && setShowSendModal(false)}
      primaryAction={{
        content: 'Send now',
        loading: working,
        onAction: sendNow
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: working,
        onAction: () => setShowSendModal(false)
      }]}
    >
      <Modal.Section>
        <p>Are you sure you want to send this action now? We'll mark it as in transit and it will be sent within the next 24 hours</p>
      </Modal.Section>
    </Modal>
    <Modal
      open={showDelayModal}
      title="Set the delay amount"
      onClose={() => !working && setShowDelayModal(false)}
      primaryAction={{
        content: 'Delay',
        loading: working,
        disabled: !delayDays || delayDays === '0',
        onAction: delay
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: working,
        onAction: () => setShowDelayModal(false)
      }]}
    >
      <Modal.Section>
        <TextField
          label="How many days do you want to delay this action?"
          type="number"
          suffix="days"
          min={moment.utc().diff(moment(data.run_at), 'days')}
          value={delayDays}
          onChange={handleChange}
        />
      </Modal.Section>
    </Modal>
    <Modal
      open={showCancelModal}
      title="Cancel action?"
      onClose={() => !working && setShowCancelModal(false)}
      primaryAction={{
        content: 'Cancel action',
        loading: working,
        destructive: true,
        onAction: cancel
      }}
      secondaryActions={[{
        content: 'Cancel',
        disabled: working,
        onAction: () => setShowCancelModal(false)
      }]}
    >
      <Modal.Section>
        <p>
          Are you sure you want to cancel this action?
        </p>
      </Modal.Section>
    </Modal>
  </>;
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(FlowRidersTable);
