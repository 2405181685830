const props = ['key', 'pathname', 'search', 'hash'];
const isEqual = (a, b) => props.every(prop => a[prop] === b[prop]);

const prevented = [];

export const prevent = (location) => {
  const { state, ...rest } = location;
  prevented.push(rest);
};

export const hasBeenPrevented = (location) =>
  prevented.some(preventedLocation => isEqual(location, preventedLocation));

export const shouldPrevent = (location) =>
  Boolean(location.state && location.state.preventLastLocation);
