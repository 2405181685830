import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  LegacyStack,
  Icon,
  Autocomplete,
  Tag
} from "@shopify/polaris";
import { SearchIcon, PlusCircleIcon } from "@shopify/polaris-icons";
import { showToast } from 'redux/toast';
import { fetchReviewTags } from 'redux/reviewTags';
import { fetchReviewTagLinks } from 'redux/reviewTagLinks';
import LoadingCardWrapper from './LoadingCardWrapper';


export default function ReviewTagContainer({reviewId}) {

  const [tagOptions, setTagOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [autocompleteLoading, seAutocompleteLoading] = useState(false);
  const [appliedTags, setAppliedTags] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const reviewTags = useSelector((state) => state.reviewTags);
  const reviewTagLinks = useSelector((state) => state.reviewTagLinks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReviewTags());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchReviewTagLinks());
  }, [dispatch]);

  useEffect(() => {
    if (reviewTags.data) {
      const options = reviewTags.data.map((tag) => {
        return {
          value: tag.name,
          label: tag.name
        }
      });

      setTagOptions(options);
    }
  }, [reviewTags.data]);

  useEffect(() => {
    if (reviewTagLinks.data && reviewTags.data) {
      const alreadySelected = reviewTagLinks.data.map((link) => {
        return reviewTags.data.find((tag) => {
          return tag.id === link.review_tag_id
        })
      }).map((tag) => { return tag.name });

      setAppliedTags(alreadySelected);

      const allOptions = alreadySelected;

      for (const newTag of newTags) {
        if (!allOptions.includes(newTag)) {
          allOptions.push(newTag);
        }
      }

      setSelectedOptions(allOptions.sort());
    }

  }, [reviewTagLinks.data, newTags, reviewTags.data]);

  useEffect(() => {
    setOptions(tagOptions);
  }, [tagOptions]);

  const checkSaveDisabled = useCallback(() => {
    let saveDisabled = true;

    const matchedTags = selectedOptions.map((tag) => {
      const matchedOption = reviewTags.data.find((option) => {
        return option.name === tag;
      });
      return matchedOption;
    });

    const removedTags = appliedTags.map((tag) => {
      if (!selectedOptions.includes(tag)) {
        const matchedOption = reviewTags.data.find((option) => {
          return option.name === tag;
        });
        return matchedOption;
      }
      return null;
    }).filter((tag) => tag !== null);

    for (const tag of removedTags) {
      const tagLink = reviewTagLinks.data.find((link) => { return link.review_tag_id === tag.id });

      if (tagLink) {
        saveDisabled = false;
      }
    }

    for (const tag of matchedTags) {
      if (!tag) {
        saveDisabled = false;
        continue;
      }

      const tagLink = reviewTagLinks.data.find((link) => { return link.review_tag_id === tag.id });

      if (!tagLink) {
        saveDisabled = false;
      }
    }

    return saveDisabled;
  }, [appliedTags, reviewTags.data, selectedOptions, reviewTagLinks.data]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === '') {
        setOptions(tagOptions);
        seAutocompleteLoading(false);
        return;
      }
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = tagOptions.filter((option) =>
        option.label.match(filterRegex),
      );
      setOptions(resultOptions);
      seAutocompleteLoading(false);
    },
    [tagOptions],
  );

  const removeTag = useCallback(
    (tag) => () => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedOptions(options.sort());
    },
    [selectedOptions]
  );

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions.map((option) => {
          let tagLabel = '';
          tagLabel = option;
          return (
            <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {tagLabel}
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      value={inputValue}
      prefix={<Icon source={SearchIcon} />}
      verticalContent={verticalContentMarkup}
      placeholder="Search tags"
      autoComplete="off"
    />
  );

  const handleTagCreate = async () => {
    if (inputValue === '') {
      return;
    }

    try {
      await axios.post('/api/v1/review_tags', {
        review_tag: {
          name: inputValue
        }
      });

      dispatch(fetchReviewTags());

      const newOptions = [...selectedOptions];
      newOptions.push(inputValue);
      setSelectedOptions(newOptions.sort());

      const updatedNewTags = [...newTags];
      updatedNewTags.push(inputValue);
      setNewTags(updatedNewTags);
      setInputValue('');
      dispatch(showToast('Tag created'));
    } catch (e) {
      // Do nothing
    }
  }

  const handleTagSave = async () => {
    setIsSaving(true);
    const matchedTags = selectedOptions.map((tag) => {
      const matchedOption = reviewTags.data.find((option) => {
        return option.name === tag;
      });
      return matchedOption;
    });

    const removedTags = appliedTags.map((tag) => {
      if (!selectedOptions.includes(tag)) {
        const matchedOption = reviewTags.data.find((option) => {
          return option.name === tag;
        });
        return matchedOption;
      }
      return null;
    }).filter((tag) => tag !== null);

    for (const tagToRemove of removedTags) {
      try {
        const tagLink = reviewTagLinks.data.find((link) => { return link.review_tag_id === tagToRemove.id });

        await axios.delete(`/api/v1/review_tag_links/${tagLink.id}`);
      } catch (e) {
        // Do nothing
      }
    }

    for (const matchedTag of matchedTags) {
      try {
        const tagLink = reviewTagLinks.data.find((link) => { return link.review_tag_id === matchedTag.id });

        if (tagLink) {
          continue;
        }

        await axios.post('/api/v1/review_tag_links', {
          review_tag_link: {
            review_tag_id: matchedTag.id,
            review_id: reviewId
          }
        });
      } catch (e) {
        // Do nothing
      }
    }

    dispatch(fetchReviewTags());
    dispatch(fetchReviewTagLinks());
    setNewTags([]);
    setIsSaving(false);
    dispatch(showToast('Saved changes'));
  }

  return (
    <LoadingCardWrapper
      sectioned
      title="Tags"
      loading={reviewTags.loading || reviewTagLinks.loading}
      primaryFooterAction={{
        content: 'Save',
        disabled: checkSaveDisabled(),
        onAction: () => { handleTagSave() },
        loading: isSaving
      }}
      footerActionAlignment="left">
        <Autocomplete
          actionBefore={{
            content: `Create ${inputValue}`,
            onAction: () => { handleTagCreate() },
            disabled: inputValue === '' || options.find((option) => option.label === inputValue),
            icon: PlusCircleIcon
          }}
          allowMultiple
          options={options}
          selected={selectedOptions}
          onSelect={(selected) => { setSelectedOptions(selected.sort()) }}
          listTitle="Suggested tags"
          loading={autocompleteLoading}
          textField={textField}
        />
    </LoadingCardWrapper>
  );
}
