import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setUser } from 'redux/user';
import { showToast } from 'redux/toast';

import {
  LegacyCard,
  Form,
  FormLayout,
  TextField,
  ButtonGroup,
  Button
} from '@shopify/polaris';

import LoginWrapper from 'components/LoginWrapper';

import axios from 'axios';

const LoginLayout = (props) => {
  const queryParams = props.history.location.search;
  const params = new URLSearchParams(queryParams);
  const token = params.get('token');
  const email = params.get('email') || '';

  const [inputs, setInputs] = useState({ email: props.location?.state?.email || email });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        '/api/v1/users/sign_in?include=organizations',
        {
          user: {
            email: inputs.email,
            password: inputs.password
          }
        }
      );
      const user = response.data.user;
      const organizations = user.organizations;
      fetchInvitations({user, organizations});
    } catch(e) {
      setIsLoading(false);
      props.showToast('Oops, unable to login! Please check your email and password and try again', true);
    }
  };

  const fetchInvitations = async ({user, organizations}) => {
    try {
      if (token) {
        try {
          await axios.put(`/api/v1/users/${user.id}/verify_email`, { token });
          user.verified_email = true;
        } catch (e) {
          handleOrgRedirect(organizations, user);
          return;
        }
      } else if (!user.verified_email) {
        handleOrgRedirect(organizations, user);
        return;
      }
      const response = await axios.get('/api/v1/invitations', {
        params: {
          include: 'organization',
          'filter[email]': user.email,
          'filter[state]': 'pending'
        }
      });

      if (response?.data?.invitations?.length > 0) {
        props.history.push('/login/invitations' + queryParams, { user, invitations: response.data.invitations });
      } else {
        handleOrgRedirect(organizations, user);
      }
    } catch (e) {
      setIsLoading(false);
      handleOrgRedirect(organizations, user);
    }
  };

  const handleOrgRedirect = async (organizations, user) => {
    if (organizations.length > 0) {
      if (organizations.length === 1) {
        const organizationId = organizations[0].id;
        await axios.post(`/api/v1/organizations/${organizationId}/scope`);
        fetchStores(user);
      } else {
        props.history.push('/login/select/organization' + queryParams, { user, organizations });
      }
    } else {
      props.history.push('/login/create/organization' + queryParams, { user });
    }
  };

  const fetchStores = async (user) => {
    try {
      const response = await axios.get('/api/v1/stores');
      const stores = response?.data?.stores;
      if (stores?.length > 0) {
        if (stores.length === 1) {
          const storeId = stores[0].id;
          await axios.post(`/api/v1/stores/${storeId}/scope`);
          props.setUser(user);
        } else {
          props.history.push('/login/select/store' + queryParams, { user, stores });
        }
      } else {
        props.history.push('/login/connect_store' + queryParams, { user });
      }
    } catch (e) {
      setIsLoading(false);
      props.showToast('Oops, unable to login! Please check your email and password and try again', true);
    }
  };

  const handleInputChange = (field) => {
    return (value) => setInputs(inputs => ({...inputs, [field]: value}));;
  };

  return (
    <LoginWrapper>
      <LegacyCard sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              value={inputs.email}
              name="email"
              onChange={handleInputChange('email')}
              label="Email"
              type="email"
              placeholder="Email"
              autoFocus={true}
              autoComplete="email"
              disabled={isLoading}
            />
            <TextField
              value={inputs.password}
              name="password"
              onChange={handleInputChange('password')}
              label="Password"
              type="password"
              placeholder="Password"
              maxLength="72"
              autoComplete="current-password"
              disabled={isLoading}
            />
            <ButtonGroup>
              <Button size="large" submit loading={isLoading} variant="primary">Login</Button>
              <Button url={"/login/forgot_password" + queryParams} variant="plain">Forgot password?</Button>
            </ButtonGroup>
          </FormLayout>
        </Form>
      </LegacyCard>
      <LegacyCard sectioned>Don't have an account? <Button  url={"/login/create" + queryParams} variant="plain">Create one</Button>
      </LegacyCard>
    </LoginWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(LoginLayout);
