import React from 'react';
import 'styles/components/MediaPreview.scss';
import {
  Modal
} from '@shopify/polaris';

export default function MediaPreview({url, type, closePreview, data}) {
  return (
    <Modal
      open={url}
      onClose={closePreview}
      title="Attachment preview"
    >
      <Modal.Section>
        {type !== 'tik_tok' ? (
          <div className="media-container">
            { type === 'image' &&
              <img className="media-preview" alt="Review attachment" src={url} />
            }
            { type === 'video' &&
              <video className="media-preview" src={url} width={400} controls />
            }
          </div>
        ) : (
          <div className="media-iframe-wrapper">
            <div
              className="media-iframe-container"
              style={{
                aspectRatio: type === 'tik_tok' ? `${data.width}/${data.height}` : 'auto'
              }}
            >
              <iframe
                title={data.title}
                className="media-iframe"
                src={url}
                frameBorder="0"
                allowFullScreen
                referrerPolicy="no-referrer"
                sandbox="allow-popups allow-scripts allow-same-origin"
              />
            </div>
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
}
