import React from 'react';

import {
  LegacyCard,
  TextContainer,
  Layout,
  SkeletonDisplayText,
  SkeletonBodyText
} from '@shopify/polaris';

export default function LoadingContentWrapper({loading, children, cards = 2}) {
  if (loading) {
    return (
      <Layout>
        <Layout.Section>
          {
            [...Array(cards)].map((e, i) =>
              <LegacyCard sectioned key={i}>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </LegacyCard>
            )
          }
        </Layout.Section>
      </Layout>
    );
  } else {
    return (
      <>
        {children}
      </>
    );
  }
}
