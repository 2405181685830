import axios from 'axios';

// Actions
const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

const DEFAULT_PAGE_SIZE = 20;

// Action Creators
export function fetchProducts({
  categoryId = null,
  page = {},
  queryValue = null,
  filters = {},
  sort = {},
}) {
  return async dispatch => {
    dispatch(fetchProductsBegin());
    try {
      const response = await axios.get('/api/v1/products', {
        params: {
          include: 'image,product_category,traits',
          'filter[product_category_id]': categoryId,
          'filter[search]': queryValue || null,
          'page[size]': DEFAULT_PAGE_SIZE,
          'page[after]': page.after,
          'page[before]': page.before,
          ...filters,
          ...sort,
        }
      });
      dispatch(fetchProductsSuccess(response.data.products, response.data.meta));
    } catch (e) {
      dispatch(fetchProductsError(e));
    }
  }
}

export const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS
});

export const fetchProductsSuccess = (data, meta) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  data,
  meta
});

export const fetchProductsError = (error) => ({
  type: FETCH_PRODUCTS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        loading: false,
        error: null
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
