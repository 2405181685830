import React, { useState } from 'react';
import { Button, Icon, InlineStack, Text, Divider } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';

export default function ShippingLocation({ shippingLocation, renderCountryLabel, deleteShippingLocation }) {
  const [loading, setLoading] = useState(false);

  const removeShippingLocation = async () => {
    setLoading(true);
    await deleteShippingLocation(shippingLocation.id);
  };

  return (
    <>
      <InlineStack align="space-between" blockAlign="center">
        <Text>{renderCountryLabel({ name: shippingLocation.country, code: shippingLocation.country_code })}</Text>
        <Button
          loading={loading}
          onClick={removeShippingLocation}
        >
          <Icon source={DeleteIcon} />
        </Button>
      </InlineStack>
      <Divider />
    </>
  );
};
