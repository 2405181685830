import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import useLastLocation from 'utils/useLastLocation';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { setFormDirty, discardChanges, savingChanges } from 'redux/settings';
import { showToast } from 'redux/toast';
import { saveMessageBlocks, saveMessageTemplate } from 'redux/messages';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import Editor from 'utils/ReactSimpleCodeEditor';
import Prism from 'prismjs';
import EmailPreview from 'components/EmailPreview';
import BrandingFooterHelp from 'components/BrandingFooterHelp';
import SendTestEmailModal from 'components/SendTestEmailModal';
import CustomHtmlPreviewModal from 'components/CustomHtmlPreviewModal';
import TemplateAnalytics from 'components/TemplateAnalytics';
import {
  Page,
  Link,
  LegacyCard,
  Collapsible,
  FormLayout,
  TextContainer,
  TextField,
  Tooltip,
  Checkbox,
  Modal,
  Text,
  InlineCode,
} from "@shopify/polaris";
import ColorSchemeWrapper from 'components/ColorSchemeWrapper';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import { ViewIcon } from "@shopify/polaris-icons";
import 'prismjs/themes/prism.css'
import 'styles/routes/Template.scss';

function Template({
  planFeatureSets,
  fetchPlanFeatureSets,
  showToast,
  saveMessageTemplate,
  saveMessageBlocks,
  savingChanges,
  setFormDirty,
  settings,
  discardChanges,
  match,
  history,
}) {
  const lastLocation = useLastLocation();
  const messageTemplateId = match.params.id;
  const replace = history.replace;
  const [inputs, setInputs] = useState({});
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [variablesShowing, setVariablesShowing] = useState(true);
  const [requiredVariablesShowing, setRequiredVariablesShowing] = useState(true);
  const [showTestEmailModal, setShowTestEmailModal] = useState(false);
  const [showCustomHtmlPreviewModal, setShowCustomHtmlPreviewModal] = useState(false);
  const [showCustomHtmlDiscardModal, setShowCustomHtmlDiscardModal] = useState(false);
  const [customHtmlEnabled, setCustomHtmlEnabled] = useState(false);
  const [customHtmlTemplate, setCustomHtmlTemplate] = useState('');
  const [customTemplateValid, setCustomTemplateValid] = useState(true);

  useEffect(() => {
    return () => {
      setFormDirty(false);
    }
  }, [setFormDirty]);

  useEffect(() => {
    if (!planFeatureSets.data) {
      fetchPlanFeatureSets();
    }
  }, [fetchPlanFeatureSets, planFeatureSets.data]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axios.get(`/api/v1/message_templates/${messageTemplateId}`, {
          params: {
            include: 'message_type,message_blocks'
          }
        });

        const messageTemplate = response.data.message_template;
        setTemplate(messageTemplate);
        if (messageTemplate.template) {
          setCustomHtmlEnabled(true);
        }
        setCustomHtmlTemplate(messageTemplate.template);
        setLoading(false);
      } catch (e) {
        // Redirect to message templates page
        replace('/flows/templates');
      }
    }
    if (messageTemplateId) {
      fetchData();
    }
  }, [messageTemplateId, replace]);

  useEffect(() => {
    if (template) {
      let blockList = template.message_blocks;
      blockList.sort((a, b) => a.position - b.position);
      setBlocks(blockList);
      let inputList = {};
      for (let block of blockList) {
        if (block.read_only === false) {
          inputList[block.id] = block.template;
        }
      }
      setInputs(inputList);
    }
  }, [template]);

  useEffect(() => {
    if (settings.discardChanges) {
      setCustomHtmlTemplate(template.template);
      if (!template.template) {
        setCustomHtmlEnabled(false);
      } else if (template.template) {
        setCustomHtmlEnabled(true);
      }
      if (blocks) {
        let inputList = {};
        for (let block of blocks) {
          if (block.read_only === false) {
            inputList[block.id] = block.template;
          }
        }
        setInputs(inputList);
      }
    }
  }, [blocks, settings.discardChanges, template]);

  useEffect(() => {
    if (settings.saving) {
      if (!isSaving) {
        if (customHtmlEnabled) {
          if (!customTemplateValid) {
            showToast('Invalid custom HTML, please ensure you have included the required variables and try again', true);
            setTimeout(() => {
              savingChanges(false);
            }, 300);
            return;
          }
          saveMessageTemplate(customHtmlTemplate, template);
          saveMessageBlocks(template.id, inputs);
        } else {
          saveMessageBlocks(template.id, inputs);
          if (template.template) {
            saveMessageTemplate(null, template);
          }
        }
        setIsSaving(true);
      }
    } else {
      if (isSaving) {
        setIsSaving(false);
      }
    }
  }, [
    showToast,
    customTemplateValid,
    template,
    inputs,
    isSaving,
    savingChanges,
    settings.saving,
    saveMessageBlocks,
    customHtmlEnabled,
    saveMessageTemplate,
    customHtmlTemplate
  ]);

  const checkIsDirty = useCallback(() => {
    let dirty = false;
    for (let block of blocks) {
      let blockValue = block.template || '';
      let inputValue = inputs[block.id] || '';
      if (inputValue !== blockValue) {
        dirty = true;
        break;
      }
    }

    if (dirty && !isDirty) {
      setIsDirty(true);
      if (!settings.isDirty) {
        setFormDirty(true);
      }
    } else if (!dirty && isDirty) {
      setIsDirty(false);
      if (settings.isDirty) {
        setFormDirty(false);
      }
    }
  }, [blocks, inputs, isDirty, setFormDirty, settings.isDirty]);

  useEffect(() => {
    checkIsDirty();
  }, [checkIsDirty]);

  const handleCodeChange = useCallback((newCode) => {
    setCustomHtmlTemplate(newCode);
    if (customHtmlEnabled) {
      if (newCode) {
        if (newCode !== template.template) {
          if (!settings.isDirty) {
            setFormDirty(true);
          }
          setCustomTemplateValid(true);
          const requiredBlocks = template.message_blocks.filter(b => b.template_must_reference);
          for (let block of requiredBlocks) {
            let blockMarkup = `{{ ?${block.name} ?}}`;
            if (block.supports_html) {
              blockMarkup = `{${blockMarkup}}`;
            }
            let re = new RegExp(`${blockMarkup}`, 'g');
            if (!re.test(newCode)) {
              setCustomTemplateValid(false);
            }
          }
        } else {
          setFormDirty(false);
        }
      } else if (settings.isDirty) {
        setFormDirty(false);
      }
    }
  }, [customHtmlEnabled, template, setFormDirty, settings.isDirty]);

  const handleInputChange = (field) => {
    return (value) => setInputs(inputs => ({ ...inputs, [field]: value }));;
  };

  const handleCheckboxChange = useCallback((newChecked) => {
    if (newChecked === false) {
      setShowCustomHtmlDiscardModal(true);
    } else {
      if (settings.isDirty) {
        discardChanges();
      }
      setCustomHtmlEnabled(newChecked);
    }
  }, [discardChanges, settings.isDirty]);

  const disabledCustomHtml = () => {
    setCustomHtmlEnabled(false);
    setCustomHtmlTemplate(template.template);
    setShowCustomHtmlDiscardModal(false);
    if (!settings.isDirty && template.template) {
      setFormDirty(true);
    } else if (settings.isDirty && !template.template) {
      setFormDirty(false);
    }
  }

  const inputItems = blocks.filter(block => block.read_only === false)
    .map((block, index) =>
      <TextField
        key={block.id}
        name={block.name}
        value={inputs[block.id] || ''}
        label={block.title}
        onChange={handleInputChange(block.id)}
        type="text"
        placeholder={block.default_template}
        disabled={settings.saving}
        multiline
      />
    );

  const standAloneInputItems = blocks.filter(block => block.read_only === false && block.stand_alone === true)
    .map((block) =>
      <TextField
        key={block.id}
        name={block.name}
        value={inputs[block.id] || ''}
        label={block.title}
        onChange={handleInputChange(block.id)}
        type="text"
        placeholder={block.default_template}
        disabled={settings.saving}
        multiline
      />
    );

  const addressCtaMarkup = (
    <LegacyCard title="Address" sectioned>
      <TextContainer spacing="tight">
        <p>
          Emails are legally required to include a physical store address. You can edit this information in your <Link url="/settings/store">Store Settings</Link>.
        </p>
      </TextContainer>
    </LegacyCard>
  )

  const variablesMarkup = template && template.variables ? (
    <LegacyCard
      title="Variable reference"
      sectioned
      subdued
      actions={[{
        content: variablesShowing ? 'Hide' : 'Show',
        onAction: () => setVariablesShowing(!variablesShowing),
        ariaExpanded: variablesShowing,
        ariaControls: 'collapsible'
      }]}>
      <Collapsible open={variablesShowing} id="collapsible">
        <TextContainer spacing="tight">
          {
            template.variables.filter(v => v.documented).map((variable, index) => {
              return (
                <div key={index}>
                  <Tooltip key={index} content={variable.description}>
                    <p><Text variant="bodyMd" as="span"><InlineCode>&#123;&#123;{variable.name}&#125;&#125;</InlineCode></Text></p>
                  </Tooltip>
                </div>
              );
            })
          }
        </TextContainer>
      </Collapsible>
    </LegacyCard>
  ) : null;

  const requiredVariablesMarkup = template && template.message_blocks ? (
    <LegacyCard
      title="Required variables"
      sectioned
      actions={[{
        content: requiredVariablesShowing ? 'Hide' : 'Show',
        onAction: () => setRequiredVariablesShowing(!requiredVariablesShowing),
        ariaExpanded: requiredVariablesShowing,
        ariaControls: 'collapsible'
      }]}>
      <Collapsible open={requiredVariablesShowing} id="collapsible">
        <TextContainer spacing="tight">
          <p>Your custom HTML template must reference the following variables:</p>
          {
            template.message_blocks.filter(b => b.template_must_reference).map((block, index) => {
              let blockMarkup = `{{${block.name}}}`;
              if (block.supports_html) {
                blockMarkup = `{${blockMarkup}}`;
              }
              return (
                <div key={index}>
                  <p><Text variant="bodyMd" as="span"><InlineCode>{blockMarkup}</InlineCode></Text></p>
                </div>
              );
            })
          }
        </TextContainer>
      </Collapsible>
    </LegacyCard>
  ) : null;

  const secondaryActions = [
    {
      content: 'Send a test email',
      accessibilityLabel: 'Send a test email',
      icon: ViewIcon,
      disabled: isDirty || settings.isDirty,
      onAction: () => { setShowTestEmailModal(true); trackEvent('Message template - Send a test email') }
    }
  ];

  return (
    <LoadingPageWrapper loading={loading || planFeatureSets.loading}>
      <Page
        fullWidth
        title={`${template?.message_type.name || 'Loading'} template`}
        backAction={{ content: 'Templates', url: lastLocation || '/flows/templates' }}
        secondaryActions={secondaryActions}
      >
        <div className="message-container">
          <div>
            <div className="inputs-container">
              {!customHtmlEnabled &&
                <LegacyCard sectioned>
                  <FormLayout>
                    {inputItems}
                  </FormLayout>
                </LegacyCard>
              }
              {customHtmlEnabled &&
                <LegacyCard sectioned>
                  <FormLayout>
                    {standAloneInputItems}
                  </FormLayout>
                </LegacyCard>
              }
              {customHtmlEnabled &&
                requiredVariablesMarkup
              }
              {variablesMarkup}
              {addressCtaMarkup}
              <LegacyCard sectioned title="Custom HTML email">
                <Checkbox
                  label="Enable custom HTML"
                  checked={customHtmlEnabled}
                  onChange={(newChecked) => handleCheckboxChange(newChecked)}
                  disabled={settings.saving || (planFeatureSets?.data && planFeatureSets?.data?.['pf_custom_emails'] === false)}
                  helpText={
                    (planFeatureSets?.data && planFeatureSets.data['pf_custom_emails'] === false) ? (
                      <>
                        You need to upgrade your <Link url="/settings/billing">plan</Link> to use custom HTML emails.
                      </>
                    ) : null
                  }
                />
              </LegacyCard>
            </div>
          </div>

          <div className="message-preview-container">
            {!customHtmlEnabled &&
              <ColorSchemeWrapper colorScheme="light">
                <LegacyCard title="Preview">
                  <LegacyCard.Section>
                    <EmailPreview template={template} blocks={blocks} inputs={inputs} customHtmlEnabled={false} />
                  </LegacyCard.Section>
                  <LegacyCard.Section subdued><p>Previewing your email template above with placeholder products and sample customer data. Properly used variables will be replaced with sample data</p></LegacyCard.Section>
                </LegacyCard>
              </ColorSchemeWrapper>
            }
            {customHtmlEnabled &&
              <ColorSchemeWrapper colorScheme="light">
                <LegacyCard
                  sectioned
                  title="Custom HTML"
                  actions={[{
                    content: 'Preview',
                    disabled: !customHtmlTemplate,
                    onAction: () => setShowCustomHtmlPreviewModal(true)
                  }]}>
                  <Editor
                    value={customHtmlTemplate || ''}
                    onValueChange={handleCodeChange}
                    highlight={code => Prism.highlight(code || '', Prism.languages.html, 'html')}
                    padding={10}
                    textareaClassName={'custom-html-textarea'}
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 14
                    }}
                  />
                </LegacyCard>
              </ColorSchemeWrapper>
            }
          </div>
        </div>
        <TemplateAnalytics
          messageTypeId={template?.message_type_id || null}
        />
        <BrandingFooterHelp />
        <SendTestEmailModal
          messageTemplateId={messageTemplateId}
          showModal={showTestEmailModal}
          setShowModal={setShowTestEmailModal}
        />
        <CustomHtmlPreviewModal
          template={template}
          customHtmlTemplate={customHtmlTemplate}
          blocks={blocks}
          showModal={showCustomHtmlPreviewModal}
          setShowModal={setShowCustomHtmlPreviewModal}
        />
        <Modal
          open={showCustomHtmlDiscardModal}
          title="Disable custom HTML?"
          onClose={() => setShowCustomHtmlDiscardModal(false)}
          primaryAction={{
            content: 'Disable',
            onAction: disabledCustomHtml
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => { setShowCustomHtmlDiscardModal(false); },
            }
          ]}
        >
          <Modal.Section>
            <p>Disabling custom HTML emails will discard your custom HTML, please ensure you have it saved elsewhere.</p>
          </Modal.Section>
        </Modal>
      </Page>
    </LoadingPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  planFeatureSets: state.planFeatureSets,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlanFeatureSets: () => dispatch(fetchPlanFeatureSets()),
  showToast: (message, error) => dispatch(showToast(message, error)),
  saveMessageBlocks: (id, blocks) => dispatch(saveMessageBlocks(id, blocks)),
  saveMessageTemplate: (newTemplate, template) => dispatch(saveMessageTemplate(newTemplate, template)),
  savingChanges: (saving) => dispatch(savingChanges(saving)),
  setFormDirty: (dirty) => dispatch(setFormDirty(dirty)),
  discardChanges: () => dispatch(discardChanges()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Template);
