import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Page,
  Card,
  BlockStack,
  List,
  Text,
  InlineStack,
  Layout,
} from "@shopify/polaris";
import { fetchBillingPlans } from "redux/billingPlans";
import { fetchBillingSubscriptions } from "redux/billingSubscriptions";
import { fetchDisplaySettings } from 'redux/displaySettings';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import { fetchStripeRecords } from 'redux/stripeRecords';
import BillingPlans from "components/BillingPlans";
import LoadingContentWrapper from "components/LoadingContentWrapper";
import StripeModal from 'components/StripeModal';
import { showToast } from "redux/toast";
import { trackEvent } from 'utils/Mixpanel';
import PlaceholderSvg from 'assets/images/placeholder.svg';
import ActivePlan from 'components/ActivePlan';

export default function TwoPointOh(props) {
  const dispatch = useDispatch();
  const billingPlans = useSelector((state) => state.billingPlans.data);
  const billingSubscriptions = useSelector((state) => state.billingSubscriptions);
  const stripeRecords = useSelector((state) => state.stripeRecords);

  const [organization, setOrganization] = useState({});
  const [orgLoading, setOrgLoading] = useState(false);
  const [activeBillingSubscription, setActiveBillingSubscription] = useState({});
  const [activeBillingPlan, setActiveBillingPlan] = useState({});
  const [chooseLoading, setChooseLoading] = useState(false);
  const [planActive, setPlanActive] = useState(false);
  const [stripeModalData, setStripeModalData] = useState(null);

  useEffect(() => {
    dispatch(fetchBillingPlans({ shouldUseTwoPointOh: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBillingSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    if (billingSubscriptions.data) {
      const activeBillingSub = billingSubscriptions.data.find(b => b.active === true && (b.provider !== 'junip' || b.auto_renew));
      if (activeBillingSub) {
        setActiveBillingSubscription(activeBillingSub);
        const activePlan = activeBillingSub.billing_plan;
        if (activePlan) {
          setActiveBillingPlan(activePlan);
          setPlanActive(true);
        }
      } else {
        setActiveBillingSubscription({});
      }
    }
  }, [billingSubscriptions]);

  useEffect(() => {
    async function fetchOrganization() {
      try {
        setOrgLoading(true);
        const response = await axios.get('/api/v1/organizations', {
          params: {
            include: 'referral_record',
            'filter[scoped_only]': true
          }
        });

        setOrganization(response.data?.organizations?.[0]);
        setOrgLoading(false);
      } catch (e) {
        setOrgLoading(false);
        trackEvent('Error: 2.0 Billing - Fetch scoped organization', { statusCode: e?.response?.status });
        props.history.replace('/');
      }
    }

    fetchOrganization();
  }, [props.history]);

  const choosePlan = async (plan) => {
    setChooseLoading(true);

    // Clear if choosing plan from 2.0 link
    try {
      localStorage.removeItem('backToOnboarding');
    } catch (e) {
      // No-op
    }

    if (organization.billable === false || plan.free === true) {
      await createBillingSubscription(plan.id, 'junip');
    } else if (organization.payment_provider === 'shopify') {
      await createBillingSubscription(plan.id);
    } else if (organization.payment_provider === 'stripe') {
      setStripeModalData({
        plan,
        action: 'select'
      });
    }
  };

  const createBillingSubscription = async (planId, provider) => {
    try {
      const billingSubscriptionResponse = await axios.post('/api/v1/billing_subscriptions', {
        billing_subscription: {
          billing_plan_id: planId,
          provider: provider || organization.payment_provider
        }
      });
      const newBillingSubscription = billingSubscriptionResponse.data.billing_subscription;

      if (newBillingSubscription.authorization_url) {
        window.location.href = newBillingSubscription.authorization_url;
      } else {
        dispatch(fetchBillingSubscriptions());
        setPlanActive(true);
        setChooseLoading(false);
        dispatch(showToast('Plan selected'));
        dispatch(fetchDisplaySettings());
        dispatch(fetchPlanFeatureSets());
      }
      trackEvent('2.0 Billing settings - Select plan');
    } catch (e) {
      if (e?.response?.status === 400 && organization?.payment_provider === 'shopify') {
        dispatch(showToast('Cannot change your subscription while your Shopify account is paused', true));
      } else {
        dispatch(showToast('Something went wrong while selecting this plan, please check your payment method and try again', true));
      }
      setChooseLoading(false);
      trackEvent('2.0 Error: Billing settings - Select plan', { statusCode: e?.response?.status });
    }
  };

  return (
    <Page title="Junip 2.0">
      <LoadingContentWrapper
        loading={
          billingPlans?.loading ||
          billingSubscriptions?.loading ||
          orgLoading
        }
        cards={3}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <InlineStack align="space-between">
                <BlockStack gap="300">
                  <Text variant="headingMd">New with Junip 2.0</Text>
                  <List type="bullet">
                    <List.Item>Fully automated moderation</List.Item>
                    <List.Item>Unlimited orders for all plans</List.Item>
                    <List.Item>Access to new features</List.Item>
                  </List>
                </BlockStack>
                <img
                  src={PlaceholderSvg}
                  alt="Junip 2.0 graphic"
                  width="150"
                  height="100"
                />
              </InlineStack>
            </Card>
          </Layout.Section>
          <ActivePlan
            planActive={planActive}
            activeBillingPlan={activeBillingPlan}
            activeBillingSubscription={activeBillingSubscription}
            setShowCancelModal={() => { }}
            setStripeModalData={setStripeModalData}
            cancelLoading={false}
            chooseLoading={chooseLoading}
            organization={organization}
            stripeRecord={stripeRecords.data?.[0]}
            twoPointOh={true}
          />
          <Layout.Section>
            <Text variant="headingMd">
              Choose a new plan
            </Text>
          </Layout.Section>
          <BillingPlans
            planActive={planActive}
            activeBillingPlan={activeBillingPlan}
            activeBillingSubscription={activeBillingSubscription}
            cancelLoading={false}
            chooseLoading={chooseLoading}
            choosePlan={choosePlan}
            billingPlans={billingPlans?.filter(b => !b.free)}
            organization={organization}
            twoPointOh={true}
          />
          <BillingPlans
            planActive={planActive}
            activeBillingPlan={activeBillingPlan}
            activeBillingSubscription={activeBillingSubscription}
            cancelLoading={false}
            chooseLoading={chooseLoading}
            choosePlan={choosePlan}
            billingPlans={billingPlans?.filter(b => b.free)}
            organization={organization}
            twoPointOh={true}
          />
        </Layout>
      </LoadingContentWrapper>
      <StripeModal
        showToast={showToast}
        modalData={stripeModalData}
        setModalData={setStripeModalData}
        stripeRecord={stripeRecords.data?.[0]}
        fetchStripeRecords={fetchStripeRecords}
        createBillingSubscription={createBillingSubscription}
        setChooseLoading={setChooseLoading}
      />
    </Page >
  );
}