import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { trackEvent } from "utils/Mixpanel";
import { fetchAppInstalls } from "redux/appInstalls";
import { showToast } from "redux/toast";
import {
  Modal,
  Text,
  OptionList,
  Icon,
  BlockStack,
  InlineStack,
  Button,
  Thumbnail,
  ActionList,
  Box,
  Divider,
  Link,
} from "@shopify/polaris";
import { OrderFulfilledIcon, LocationIcon } from "@shopify/polaris-icons";
import wondermentSvg from "assets/images/apps/wonderment.svg";
import malomoSvg from "assets/images/apps/malomo.svg";
import aftershipSvg from "assets/images/apps/aftership.jpg";

export default function RequestTrigger({ flow, setFlow, editing, setEditing }) {
  const [trigger, setTrigger] = useState(flow?.trigger);
  const [saving, setSaving] = useState(false);
  const [selected, setSelected] = useState([trigger?.trigger_type]);
  const [showUninstalledApps, setShowUninstalledApps] = useState(false);

  const appInstalls = useSelector((state) => state.appInstalls);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appInstalls.data) {
      dispatch(fetchAppInstalls());
    }
  }, [appInstalls.data, dispatch]);

  const save = async () => {
    try {
      setSaving(true);
      const response = await axios.put(
        `/api/v1/flows/${flow.id}?include=trigger`,
        {
          flow: {
            trigger_type: selected[0],
          },
        }
      );
      setTrigger(response.data.flow.trigger);
      setFlow((flow) => ({ ...flow, trigger: response.data.flow.trigger }));
      dispatch(showToast("Trigger updated", false));
      trackEvent("Request flow - Save trigger");
      setEditing(false);
    } catch (e) {
      dispatch(showToast("Error saving trigger, please try again", true));
      trackEvent("Error: Request flow - Save trigger", {
        statusCode: e?.response?.status,
      });
    } finally {
      setSaving(false);
    }
  };

  const triggerName = (() => {
    return trigger?.trigger_type?.split("/")?.[1];
  })();

  const triggerMarkup = (title, content) => {
    return (
      <BlockStack align="start" as="span">
        <Text variant="bodyMd">
          {title}
        </Text>
        <Text variant="bodySm" tone="subdued">
          {content}
        </Text>
      </BlockStack>
    );
  }

  const options = [
    {
      value: "order/fulfilled",
      label: "Order is fulfilled",
      description: "Trigger when an order is marked as Fulfilled in Shopify.",
      icon: OrderFulfilledIcon,
      installed: true,
    },
    {
      value: "order/delivered",
      label: "Order is delivered",
      description: "Trigger when an order is marked as Delivered in Shopify. Accuracy will vary depending on your 3rd party shipping and tracking partners and other factors.",
      icon: LocationIcon,
      installed: true,
    },
    {
      value: "wonderment/delivered",
      label: "Wonderment - Order is delivered",
      description:
        <>
          Trigger when an order is marked as Delivered in Wonderment. Turn on fulfillment events in Wonderment to use this trigger. {<Link url="https://docs.wonderment.com/article/15idybs8n2-trigger-review-requests-with-junip" external>Learn more</Link>}
        </>,
      svg: wondermentSvg,
      installed: true,
    },
    {
      value: "aftership/delivered",
      label: "Aftership - Order is delivered",
      description: "Trigger when an order is marked as Delivered in AfterShip.",
      svg: aftershipSvg,
      installed: appInstalls.data?.find(
        (appInstall) => appInstall.provider === "aftership"
      ),
      url: "/integrations/aftership",
    },
    {
      value: "malomo/delivered",
      label: "Malomo - Order is delivered",
      description: "Trigger when an order is marked as Delivered in Malomo.",
      svg: malomoSvg,
      installed: appInstalls.data?.find(
        (appInstall) => appInstall.provider === "malomo"
      ),
      url: "/integrations/malomo",
    },
  ];

  const installedOptions = options.filter((option) => option.installed).map(
    (option) => {
      return ({
        value: option.value,
        label: triggerMarkup(option.label, option?.description),
        media: option?.svg
          ? <Thumbnail source={option?.svg} transparent size="extraSmall" />
          : <Icon source={option?.icon} />,
      });
    }
  );

  const uninstalledOptions = options.filter((option) => !option.installed).map(
    (option) => {
      return ({
        content: triggerMarkup(
          option.label,
          <Button variant="plain" url={option?.url}>
            Install app
          </Button>
        ),
        image: option?.svg,
      })
    });

  return (
    <>
      <p>
        <Text variant="bodyMd" as="span" fontWeight="semibold">
          When an order is {triggerName}
        </Text>
      </p>
      <Modal
        open={editing}
        onClose={() => setEditing(false)}
        title="Trigger"
        primaryAction={{
          content: "Save",
          onAction: () => save(),
          loading: saving,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => setEditing(false),
            disabled: saving,
          },
        ]}
      >
        <OptionList
          onChange={setSelected}
          sections={[
            {
              title: "Available triggers",
              options: installedOptions,
            },
          ]}
          selected={selected}
        />
        {(selected[0] !== "order/fulfilled") &&
          (
            <>
              <Divider />
              <Box padding="300" background="bg-surface-secondary">
                <BlockStack gap="200">
                  <Text variant="headingSm">
                    Fallback trigger
                  </Text>
                  <Text variant="bodySm" tone="subdued">
                    If delivery is not confirmed, the flow will be automatically triggered after a fallback time delay. You can edit this delay under conditions.
                  </Text>
                </BlockStack>
              </Box>
            </>
          )
        }
        <Divider />
        <Box paddingBlockStart="300" paddingInline="300">
          <InlineStack align="space-between">
            <Text variant="headingSm">
              Trigger options from uninstalled apps
            </Text>
            <Button
              variant="plain"
              onClick={() => setShowUninstalledApps(!showUninstalledApps)}
            >
              {showUninstalledApps ? "Hide" : "Show"}
            </Button>
          </InlineStack>
        </Box>
        <ActionList
          sections={[
            {
              items: showUninstalledApps ? uninstalledOptions : [],
            }]}
          actionRole="menuitem"
        />
        <Box paddingBlockEnd="200" />
      </Modal >
    </>
  );
}