import React, { useState, useEffect } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import {
  fetchDisplaySettings,
  saveDisplaySettings
} from 'redux/displaySettings';
import {
  fetchStarOptions,
} from 'redux/starOptions';
import { Button, FormLayout, Page, Select, LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import JunipLogo from 'components/JunipLogo';
import SkipOnboarding from 'components/SkipOnboarding';
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import LogoDropZone from 'components/LogoDropZone';
import ColorPicker from 'components/ColorPicker';
import ButtonPreview from 'components/ButtonPreview';
import StarPreview from 'components/StarPreview';

function Branding({
  store,
  displaySettings,
  fetchDisplaySettings,
  saveDisplaySettings,
  fetchStarOptions,
  starOptions,
  history,
  progress
}) {
  const supportsShopifyThemeExtensions = store?.supports_shopify_theme_extensions;
  const NEXT_STEP = supportsShopifyThemeExtensions ? '/onboarding/add-junip-1' : '/onboarding/preview';

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [removeImage, setRemoveImage] = useState(false);

  useEffect(() => {
    fetchDisplaySettings();
  }, [fetchDisplaySettings]);

  useEffect(() => {
    fetchStarOptions();
  }, [fetchStarOptions]);

  useEffect(() => {
    if (displaySettings.data) {
      setInputs(displaySettings.data);
    }
  }, [displaySettings.data]);

  const handleInputChange = (field) => {
    return (value) => {
      setInputs(inputs => ({ ...inputs, [field]: value }));
    };
  };

  const nextStep = async () => {
    setLoading(true);
    await saveDisplaySettings(displaySettings.data.id, inputs);
    history.push(NEXT_STEP, { themeId: history?.location?.state?.themeId })

    trackEvent('Onboarding branding - Save & continue');
  };

  const buttonTextColorOptions = [
    { label: 'Light', value: 'light' },
    { label: 'Dark', value: 'dark' },
  ];

  const starColorOptions = [
    { label: 'Gold', value: 'gold' },
    { label: 'Black', value: 'black' },
    { label: 'Primary', value: 'primary' },
  ];

  const allStarOptions = starOptions?.data?.map(star => {
    return { label: star.name, value: `${star.id}` };
  }) || [];

  return (
    <Page>
      <LegacyCard flush>
        <div className="onboarding-step o-divider">
          <div className="o-step o-step-subdued o-divider-right">
            <LegacyStack vertical spacing="extraLoose">
              <JunipLogo height={'24px'} />
              <Text variant="heading2xl" as="p">Set your branding & colors</Text>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">First things first, configure your branding and upload your logo so Junip can seamlessly adopt your branding</Text>
              </p>
              <LoadingCardWrapper loading={displaySettings.loading || starOptions.loading} sectioned={false}>
                <LegacyCard.Section title={<Text variant="headingMd" as="h2">Upload your logo</Text>}>
                  <LogoDropZone
                    logoUrl={inputs.logo_url}
                    handleInputChange={handleInputChange}
                    discardChanges={false}
                    removeImage={removeImage}
                    setRemoveImage={setRemoveImage}
                  />
                  <div className="mt-2">
                    <Text variant="bodyMd" as="span" tone="subdued">Drag and drop an image file or click above to upload your logo.</Text>
                  </div>
                </LegacyCard.Section>
                <LegacyCard.Section title={<Text variant="headingMd" as="h2">Set colors</Text>}>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <div>
                        <p className="mb-1">Primary color</p>
                        <ColorPicker colorKey="primary_color" colorValue={inputs.primary_color} handleInputChange={handleInputChange} />
                      </div>
                      <Select
                        label="Button text"
                        options={buttonTextColorOptions}
                        value={inputs.button_text_color_option}
                        onChange={handleInputChange('button_text_color_option')}
                      />
                    </FormLayout.Group>
                    <Select
                      label="Star color"
                      options={starColorOptions}
                      value={inputs.star_color_option}
                      onChange={handleInputChange('star_color_option')}
                    />
                    <Select
                      label="Star Style"
                      options={allStarOptions}
                      value={`${inputs.star_option_id}`}
                      onChange={handleInputChange('star_option_id')}
                    />
                  </FormLayout>
                </LegacyCard.Section>
              </LoadingCardWrapper>
            </LegacyStack>
          </div>
          <div className="o-step">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <LegacyStack vertical spacing="extraLoose" alignment="center">
                <LegacyStack vertical alignment="center">
                  <p style={{fontSize: '3rem'}} className="pb-2">4.5</p>
                  <StarPreview
                    showText={false}
                    color={inputs[`${inputs.star_color_option}_color`]}
                    star_option={starOptions?.data?.find(x => x.id === (parseInt(inputs.star_option_id || 1)))}
                  />
                  <p>15 reviews</p>
                </LegacyStack>
                <ButtonPreview
                  buttonColor={inputs[`${inputs.button_color_option}_color`]}
                  buttonTextColor={inputs[`${inputs.button_text_color_option}_text_color`]}
                  buttonShape={inputs[`${inputs.button_border_radius_option}_border_radius`]}
                  showLabel={false}
                  text={'Write a review'}
                />
              </LegacyStack>
            </div>
          </div>
        </div>
        <LegacyCard.Section subdued>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              {progress}
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Button size="large" loading={loading} onClick={nextStep} variant="primary">
                Save & Continue
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <SkipOnboarding disabled={loading} />
    </Page>
  );
}

const mapStateToProps = (state) => ({
  displaySettings: state.displaySettings,
  starOptions: state.starOptions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDisplaySettings: () => dispatch(fetchDisplaySettings()),
  saveDisplaySettings: (id, settings) => dispatch(saveDisplaySettings(id, settings, false)),
  fetchStarOptions: () => dispatch(fetchStarOptions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Branding);
