import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import { Button, Modal, Tag, TextContainer, Tooltip, Text } from "@shopify/polaris";
import { CheckIcon, ExternalIcon } from "@shopify/polaris-icons";

function FlowActionHelpModal({open, close, flow, action, appDetails, showToast, user}) {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (open) {
      setSent(false);
    }
  }, [open]);

  const sendTestEvent = async () => {
    setSending(true);
    try {
      await axios.post('/api/v1/events', {
        event: {
          action: flow.event_topic.action,
          subject: flow.event_topic.subject,
          app_install_id: action?.app_install.id
        }
      });
      showToast('Test event sent');
      setSending(false);
      setSent(true);
    } catch {
      showToast('Error sending test event, please try again', true);
      trackEvent(`Error: ${appDetails?.name} listing - Send test event`);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      title={`Integrating ${appDetails?.name} with Junip`}
      primaryAction={{
        content: 'Close',
        onAction: close
      }}

      secondaryActions={[{
        content: 'Learn more',
        url: appDetails?.helpGuide,
        external: true,
        icon: ExternalIcon
      }]}
    >
      <Modal.Section>
        <TextContainer>
          <div className="d-flex justify-content-between">
            <Text variant="headingSm" as="h3">Event name</Text>
            <Tooltip content={
              <TextContainer spacing="tight">
                <p>The test event will target your Junip account:</p>
                <div>
                  <p><Text variant="bodyMd" as="span" fontWeight="semibold">Email: </Text>{user.data?.email}</p>
                  <p><Text variant="bodyMd" as="span" fontWeight="semibold">Phone number: </Text>{user.data?.phone || 'Not set'}</p>
                </div>
                <p>Visit user settings to edit these</p>
              </TextContainer>
            }>
              <Button

                disabled={sent}
                onClick={sendTestEvent}
                loading={sending}
                icon={sent ? CheckIcon : undefined}
                variant="plain">
                {sent ? 'Test event sent' : 'Send test event'}
              </Button>
            </Tooltip>
          </div>
          <Tag>
            <span style={{textTransform: 'capitalize'}}>
              Junip - {`${flow?.event_topic?.subject} ${flow?.event_topic?.action?.replaceAll?.('-', ' ')}`}
            </span>
          </Tag>
        </TextContainer>
      </Modal.Section>
      <Modal.Section>
        <TextContainer>
          <p>
            Complete your setup in {appDetails?.name} by using the event name above.
            Click "Send test event" to test out your setup.
          </p>
          <p>
            For more information on how to get started with {appDetails?.name} and Junip click "Learn more".
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlowActionHelpModal);
