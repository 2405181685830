import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import {
  Badge,
  LegacyCard,
  EmptyState,
  Page,
  TextContainer,
  ProgressBar,
  Thumbnail,
  Link,
  Pagination,
  PageActions,
  Modal,
  ResourceList,
  ResourceItem,
  Text,
} from "@shopify/polaris";
import LoadingCardWrapper from 'components/LoadingCardWrapper';
import StarRating from 'components/StarRating';
import ProductPickerModal from 'components/ProductPickerModal';
import roundRating from 'utils/roundRating';

import addProductSvg from 'assets/images/addProduct.svg';
import filledStar from 'assets/images/filledStar.svg';
import noImage from 'assets/images/noImage.jpg';
import styles from 'styles/components/Group.module.scss';

function Group({ history, match, showToast }) {
  const groupId = match.params.id;
  const replace = history.replace;
  const [group, setGroup] = useState({});
  const [groupLoading, setGroupLoading] = useState(true);
  const [groupReloading, setGroupReloading] = useState(false);
  const [productMembershipsData, setProductMembershipsData] = useState([]);
  const [bundleProductMembership, setBundleProductMembership] = useState(null);
  const [bundleProductLoading, setBundleProductLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [showProductPicker, setShowProductPicker] = useState(false);
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const fetchGroup = useCallback(async () => {
    const response = await axios.get(`/api/v1/product_groups/${groupId}`);
    return response;
  }, [groupId]);

  const fetchGroupMemberships = useCallback(async ({ page = {}, relationship = null }) => {
    const response = await axios.get(`/api/v1/product_group_memberships`, {
      params: {
        include: 'product.image',
        'page[after]': page.after,
        'page[before]': page.before,
        'filter[relationship]': relationship,
        'filter[product_group_id]': groupId
      }
    });
    return response;
  }, [groupId]);

  const fetchAndSetMemberships = useCallback(async ({ group, page }) => {
    try {
      setProductsLoading(true);
      if (group.style === 'bundle') {
        const contributorResponse = await fetchGroupMemberships({ relationship: 'contributor', page });
        setProductMembershipsData(contributorResponse.data);
      } else {
        const response = await fetchGroupMemberships({ page });
        setProductMembershipsData(response.data);
      }
    } catch (e) {
      showToast('Error fetching products', true);
    } finally {
      setProductsLoading(false);
    }
  }, [fetchGroupMemberships, showToast]);

  const reloadGroup = useCallback(async () => {
    try {
      setGroupReloading(true);
      const response = await fetchGroup();
      setGroup(response.data.product_group);
    } catch (e) {
      showToast('Error fetching group', true);
    } finally {
      setGroupReloading(false);
    }
  }, [fetchGroup, showToast]);

  useEffect(() => {
    async function fetchData() {
      try {
        setGroupLoading(true);
        const response = await fetchGroup();
        setGroup(response.data.product_group);
      } catch (e) {
        replace('/products/groups');
      } finally {
        setGroupLoading(false);
      }
    }
    if (groupId) {
      fetchData();
    }
  }, [groupId, replace, fetchGroup]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (group.style === 'bundle') {
          setBundleProductLoading(true);
          const ownerResponse = await fetchGroupMemberships({ relationship: 'owner' });
          setBundleProductMembership(ownerResponse.data.product_group_memberships[0]);
        }

        fetchAndSetMemberships({ group });
      } catch (e) {
        replace('/products/groups');
      } finally {
        setBundleProductLoading(false);
      }
    }
    if (groupId && group?.id) {
      fetchData();
    }
  }, [groupId, group, replace, fetchGroupMemberships, fetchAndSetMemberships]);

  const deleteProductMemeberships = async () => {
    if (!selected?.length) {
      return;
    }

    try {
      setRemoving(true);
      for (const membershipId of selected) {
        try {
          await axios.delete(`/api/v1/product_group_memberships/${membershipId}`);
        } catch (e) {
          showToast('Error deleting product', true);
        }
      }
      reloadGroup();
      setSelected([]);
      showToast('Products removed from group');
      trackEvent('Product Group - Remove Products');
    } catch (e) {
      showToast('There was an error removing the products from the group.', true);

      trackEvent('Error: Product Group - Remove Products', { statusCode: e?.response?.status });
    } finally {
      setRemoving(false);
    }
  };

  const addProductMemberships = async (products) => {
    try {
      setAdding(true);
      for (const product of products) {
        try {
          await axios.post(`/api/v1/product_group_memberships`, {
            product_group_membership: {
              product_id: product.id,
              product_group_id: groupId,
              relationship: group.style === 'bundle' ? 'contributor' : 'owner'
            }
          });
        } catch (e) {
          showToast(`There was an error adding ${product.title} to the group.`, true);
        }
      }
      reloadGroup();
      showToast('Products added to group');

      trackEvent('Product Group - Add Products');
    } catch (e) {
      showToast('There was an error adding these products to the group.', true);

      trackEvent('Error: Product Group - Add Products', { statusCode: e?.response?.status });
    } finally {
      setAdding(false);
    }
  };

  const deleteGroup = async () => {
    setDeleting(true);
    try {
      await axios.delete(`/api/v1/product_groups/${groupId}`);
      trackEvent('Product Group - Delete group');

      replace('/products/groups');
      showToast(`${group?.name} group deleted`);
    } catch (e) {
      showToast('There was an error deleting this group, please try again', true);
      setDeleting(false);

      trackEvent('Error: Product Group - Delete group', { statusCode: e?.response?.status });
    }
  };

  function renderItem(item) {
    const { product } = item;
    const media = <Thumbnail source={product?.image?.url_200x200 || noImage} alt={product.title} size="small" />;
    return (
      <ResourceItem
        id={item.id}
        media={media}
        verticalAlignment="center"
        onClick={() => {
          if (selected.includes(item.id)) {
            setSelected((prev) => prev.filter((i) => i !== item.id));
          } else {
            setSelected((prev) => [...prev, item.id]);
          }
        }}
      >
        <h3>
          <Text variant="bodyMd" as="span" fontWeight="semibold">{product.title}</Text>
        </h3>
      </ResourceItem>
    );
  }

  const memberships = productMembershipsData?.product_group_memberships || [];

  return (
    <LoadingPageWrapper loading={groupLoading}>
      <Page
        title={group?.name}
        titleMetadata={
          <Badge tone={group.style === 'family' ? 'default' : 'info'}>
            <span style={{ textTransform: 'capitalize' }}>{group.style}</span>
          </Badge>
        }
        backAction={{ content: 'Product Groups', url: '/products/groups' }}
      >
        <LoadingCardWrapper
          loading={groupReloading}
          sectioned
          title={`Group rating summary`}
        >
          <div className={styles.summaryContainer}>
            <TextContainer spacing="tight">
              <div className="d-flex align-items-center">
                <div className="mr-3">
                  <Text variant="heading2xl" as="p">{roundRating(group?.rating_average)}</Text>
                </div>
                <StarRating rating={group?.rating_average || 0} size="md" />
              </div>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">{group?.rating_count} customer {group?.rating_count === 1 ? 'review' : 'reviews'}</Text>
              </p>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">{group?.product_count} {group?.product_count === 1 ? 'product' : 'products'}</Text>
              </p>
            </TextContainer>
            <div className={styles.reviewBars}>
              {
                [5, 4, 3, 2, 1].map((rating) => {
                  let percent = 0;
                  if (group.rating_count > 0) {
                    percent = Math.round((group.rating_distribution?.[rating] / group.rating_count) * 100);
                  }
                  return (
                    <React.Fragment key={rating}>
                      <p>{rating}</p>
                      <img className="d-block" src={filledStar} alt="Rating star icon" width={12} />
                      <ProgressBar tone="primary" progress={percent} size="medium" />
                      <p>{group.rating_distribution?.[rating]}</p>
                    </React.Fragment>
                  );
                })
              }
            </div>
          </div>
        </LoadingCardWrapper>
        {group.style === 'bundle' && (
          <LoadingCardWrapper
            sectioned
            title="Bundle"
            loading={bundleProductLoading}
          >
            <div className="d-flex align-items-center">
              <div className="mr-4">
                <Thumbnail
                  source={bundleProductMembership?.product?.image?.url_200x200 || noImage}
                  alt={bundleProductMembership?.product?.title}
                  size="medium"
                />
              </div>
              <TextContainer spacing="tight">
                <p>
                  <Text variant="bodyMd" as="span" fontWeight="semibold">
                    <Link url={`/products/${bundleProductMembership?.product?.id}`}>{bundleProductMembership?.product?.title}</Link>
                  </Text>
                </p>
                <p>
                  <Text variant="bodyMd" as="span" tone="subdued">Showing reviews from this group</Text>
                </p>
              </TextContainer>
            </div>
          </LoadingCardWrapper>
        )}
        <LegacyCard
          title={`Products in this ${group?.style}`}
          actions={memberships?.length > 0 && [{
            content: 'Add products',
            onAction: () => setShowProductPicker(true),
            disabled: adding || removing
          }]}>
          <ResourceList
            resourceName={{ singular: 'product', plural: 'products' }}
            items={memberships || []}
            renderItem={renderItem}
            onSelectionChange={setSelected}
            selectedItems={selected}
            selectable={true}
            loading={productsLoading || removing}
            hasMoreItems={false}
            emptyState={
              <EmptyState
                heading={`Add products to this ${group?.style}`}
                action={{
                  content: 'Add products',
                  onAction: () => setShowProductPicker(true),
                  disabled: adding || removing,
                }}
                image={addProductSvg}
              >
                <p>
                  <Text variant="bodyMd" as="span" tone="subdued">Select products to add to this group.</Text>
                </p>
              </EmptyState>
            }
            bulkActions={[
              {
                content: 'Remove products',
                onAction: deleteProductMemeberships,
                disabled: selected.length === 0 || adding || removing || deleting,
                destructive: true,
                loading: removing
              },
            ]}
          />
          {memberships?.length > 0 && (
            <LegacyCard.Section>
              <Pagination
                hasPrevious={productMembershipsData?.meta?.page?.before || false}
                onPrevious={() => {
                  fetchAndSetMemberships({
                    page: { before: productMembershipsData?.meta?.page?.before },
                    group
                  });
                }}
                hasNext={productMembershipsData?.meta?.page?.after || false}
                onNext={() => {
                  fetchAndSetMemberships({
                    page: { after: productMembershipsData?.meta?.page?.after },
                    group
                  });
                }}
              />
            </LegacyCard.Section>
          )}
        </LegacyCard>
        <div className="mt-6">
          <PageActions
            secondaryActions={[
              {
                content: 'Delete group',
                onAction: () => setShowDeleteModal(true),
                destructive: true,
                outline: true,
                disabled: deleting
              }
            ]}
          />
        </div>
      </Page>
      <ProductPickerModal
        open={showProductPicker}
        close={() => setShowProductPicker(false)}
        submit={addProductMemberships}
        preSelected={[]}
        filter={group?.style === 'family' ? { 'filter[without_group_owners]': true } : { 'filter[not_in_group]': groupId }}
      />
      <Modal
        open={showDeleteModal}
        onClose={() => !deleting && setShowDeleteModal(false)}
        title={`Delete this group?`}
        primaryAction={{
          content: 'Delete',
          onAction: deleteGroup,
          destructive: true,
          disabled: deleting,
          loading: deleting
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setShowDeleteModal(false),
            disabled: deleting
          }
        ]}
      >
        <Modal.Section>
          <p>Are you sure you want to delete the {group?.name} group?</p>
        </Modal.Section>
      </Modal>
    </LoadingPageWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(Group);
