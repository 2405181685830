import React, { useState, useEffect } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { logout } from 'redux/user';

import { TopBar, Icon } from '@shopify/polaris';
import StoreSwitcher from 'components/StoreSwitcher';

import { ExitIcon, NotificationIcon, LightbulbIcon } from "@shopify/polaris-icons";
import 'styles/components/TopBar.scss';

const HW_CONFIG = {
  selector: ".hw-widget-container",
  account:  "yj05K7"
};

const MainTopBar = (props) => {
  const [userMenuOpen, setUserMenuOpen ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (window.Headway) {
        window.Headway.init(HW_CONFIG);
      }
    }, 500);
  }, []);

  const toggleMenuOpen = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const userMenuActions = [
    {
      items: [
        {
          content: 'Toggle dark mode',
          icon: LightbulbIcon,
          onAction: () => { props.toggleColorScheme(); trackEvent(`Topbar - Switch to ${props.colorScheme === 'dark' ? 'light' : 'dark'} mode`); }
        },
        {
          content: 'Log out',
          icon: ExitIcon,
          onAction: () => { props.logout(); trackEvent('Topbar - Log out'); }
        },
      ],
    }
  ];
  const { user, toggleFrameState } = props;

  let firstName = 'Loading...';
  let email = 'loading...';
  let lastName = '';
  let initials = '';

  if (user.data) {
    firstName = user.data?.first_name || '';
    lastName = user.data?.last_name || '';
    initials = `${firstName[0] || ''} ${lastName[0] || ''}`;
    email = user.data.email;
  }

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={`${firstName} ${lastName}`}
      detail={email}
      initials={initials}
      open={userMenuOpen}
      onToggle={toggleMenuOpen}
    />
  );

  const menuMarkup = (
    <TopBar.Menu
      activatorContent={
        <div className="hw-widget-container">
          <Icon source={NotificationIcon} />
        </div>
      }
      open={false}
    />
  );

  return (
    <TopBar
      showNavigationToggle={true}
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleFrameState('showMobileNavigation')}
      secondaryMenu={menuMarkup}
      contextControl={<StoreSwitcher colorScheme={props.colorScheme} />}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainTopBar);
