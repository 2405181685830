import React, { useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { logout } from 'redux/user';
import { showToast } from 'redux/toast';
import LoadingCover from 'components/LoadingCover';

function AcceptInvitation({ showToast, logout, history }) {
  const replace = history.replace;
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const email = params.get('email');
  const invitation_id = params.get('invitation_id');

  useEffect(() => {
    async function handleInvite() {
      try {
        const response = await axios.get('/api/v1/users/me');
        const user = response.data.user;

        if (user?.email === email) {
          // Try to verify the users email so they can accept the invite
          if (!user?.verified_email) {
            try {
              await axios.put(`/api/v1/users/${user.id}/verify_email`, { token });
            } catch(e) {
              // If the email verification fails, invalid token, take them home
              showToast('Invalid invitation link', true);
              replace('/home');
              return;
            }
          }

          try {
            // Accept the invite
            const acceptResponse = await axios.post(`/api/v1/invitations/${invitation_id}/accept`);
            showToast('Invitation accepted');

            // Scope to the organization
            await axios.post(`/api/v1/organizations/${acceptResponse.data?.invitation?.organization_id}/scope`);

            // Try to scope to the first store
            const storesResponse = await axios.get('/api/v1/stores');
            const stores = storesResponse?.data?.stores;
            if (stores?.length > 0) {
              const storeId = stores[0].id;
              await axios.post(`/api/v1/stores/${storeId}/scope`);
              replace('/home');
              return;
            } else {
              // If there are no stores, take them to the connect store page
              replace('/login/connect_store', { user });
              return;
            }
          } catch(e) {
            // If the accept fails, invalid token, take them home
            showToast('Invalid invitation link', true);
            replace('/home');
            return;
          }
        } else {
          // Wrong user, log them out
          await logout();
          replace(`/login/create${window.location.search}`);
          return;
        }
      } catch(e) {
        // Not logged in, redirect to login
        await logout();
        replace(`/login/create${window.location.search}`);
      }
    }

    if (!token || !email || !invitation_id) {
      showToast('Invalid invitation link', true);
      replace('/home');
      return;
    } else {
      handleInvite();
    }
  }, [email, token, replace, showToast, invitation_id, logout]);

  return (
    <LoadingCover loading={true} showSpinner={true} />
  );
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(AcceptInvitation);
