import React, { Fragment, useState } from 'react';
import {
  LegacyCard,
  Icon,
  OptionList,
  Text,
} from "@shopify/polaris";
import { CheckIcon } from "@shopify/polaris-icons";

import billingPlanDetails from 'utils/billingPlanDetails';
import 'styles/components/BillingPlansTable.scss';

const FEATURES = [
  {
    subtitle: 'Top features',
    features: [
      'Branding',
      'Analytics',
      'Advanced on-site configuration',
      'Incentives',
      'Media incentives',
      'Integrations',
      'Google Shopping',
      'Custom questions',
      'Product traits',
      'Product grouping',
      'Featured reviews',
      'Media galleries',
      'Custom HTML emails',
      'Review syndication',
      'User accounts',
      'Multi-store'
    ]
  },
  {
    subtitle: 'Support',
    features: [
      'Knowledge base',
      'Email & chat support',
      'White glove onboarding'
    ]
  }
];

export default function BillingPlansTable({billingPlans, organization}) {
  const [selected, setSelected] = useState(['standard']);

  function uniqBy(a) {
    let seen = new Set();
    return a?.filter(item => {
      let k = item?.billing_category?.name;
      return seen.has(k) ? false : seen.add(k);
    });
  }

  // Filter out plans in the same category
  billingPlans = uniqBy(billingPlans);

  let featureIndex = -1;
  if (!billingPlans || billingPlans.length <= 2) {
    return (null);
  }

  billingPlans.sort((a, b) => a.base_price?.cents - b.base_price?.cents);

  const options = billingPlans.map((billingPlan) => {
    return {
      value: billingPlan.name.toLowerCase(),
      label: billingPlan.name
    };
  });

  return (
    <LegacyCard title="Plan comparison" sectioned>
      <div className="billing-plan-selector mb-4">
        <OptionList
          options={options}
          onChange={setSelected}
          selected={selected}
        />
      </div>
      <div
        className={`billing-plan-table-container ${selected}`}
        style={{ gridTemplateColumns: `minmax(100px, auto) repeat(${billingPlans.length}, 1fr)`}}
      >
        {
          [null, ...billingPlans].map((billingPlan, index) => {
            if (!billingPlan) {
              return <div className="border-right" key={index}></div>;
            } else {
              const {name} = billingPlanDetails(billingPlan) || {};
              const showBorder = index < billingPlans.length ? true : false;
              return (
                <div key={index} className={`column-item column-header ${billingPlan.name.toLowerCase()} ${showBorder ? 'border-right' : ''}`}>
                  <Text variant="headingSm" as="h3">{name}</Text>
                </div>
              );
            }
          })
        }
        {
          FEATURES.map((feature, index) => {
            return (
              <Fragment key={index}>
                <div className="subtitle">
                  <Text variant="bodyMd" as="span" tone="subdued">
                    {feature.subtitle}
                  </Text>
                </div>
                {
                  feature.features.map((row, i) => {
                    featureIndex ++;
                    return (
                      <Fragment key={i}>
                        <div className="column-title border-right">{row}</div>
                        {
                          billingPlans.map((billingPlan, idx) => {
                            const { features } = billingPlanDetails(billingPlan) || {};
                            const showBorder = idx + 1 < billingPlans.length ? true : false;
                            return (
                              <div key={idx} className={`column-item ${billingPlan.name.toLowerCase()} ${showBorder ? 'border-right' : ''}`}>
                                { features[featureIndex] === true &&
                                  <Icon tone="primary" source={CheckIcon} />
                                }
                                { !features[featureIndex] &&
                                  <>-</>
                                }
                                { typeof features[featureIndex] === 'string' &&
                                  <>{features[featureIndex]}</>
                                }
                              </div>
                            );
                          })
                        }
                      </Fragment>
                    );
                  })
                }
              </Fragment>
            );
          })
        }
      </div>
    </LegacyCard>
  );


};
