import React from 'react';
import 'styles/components/StarRating.scss';

const DEFAULT_COLOR = '#FDB600';

const StarRating = (props) => {
  const { rating } = props;
  let { color, star_option } = props;
  if (!color) { color = DEFAULT_COLOR; }

  const outline = (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" role="img">
      {star_option ? 
        <use href="#junipOutlineStar" />
      :
        <path d="m3.2 16.0001081c-.1687782 0-.33328968-.0531313-.47-.1521081-.24051968-.174488-.36451194-.4679107-.322-.762l.743-5.203-2.917-2.917c-.21191095-.21196521-.28756133-.52460199-.196-.81.09130759-.2851682.3345701-.49522345.63-.544l4.398-.734 2.218-4.435c.13744692-.27145241.41573373-.4426761.72-.443.3045618.00166772.58175678.1761261.715.45l2.123 4.362 4.498.801c.2933158.05211246.5333796.26289081.623.547.0898959.28455453.0135125.59548872-.198.806l-2.917 2.917.744 5.203c.042199.2957461-.0839008.5903645-.327.764-.2421349.1739598-.5609835.1986349-.827.064l-4.382-2.22-4.502 2.223c-.10996656.0547452-.23116004.0831081-.354.0831081zm1.51-2.8841081 3.357-1.658.892.452 2.327 1.178-.56-3.912.708-.707 1.29-1.29-3.234-.576-.446-.915-1.06-2.176-1.584 3.171-1.005.168-2.098.35 1.975 1.975-.141.99z" fill={color} />
      }
    </svg>
  );

  const filled = (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" role="img">
      {star_option ?
        <use href="#junipFilledStar" />
      :
        <path d="m3.2 16.0001081c-.1687782 0-.33328968-.0531313-.47-.1521081-.24051968-.174488-.36451194-.4679107-.322-.762l.743-5.203-2.917-2.917c-.21191095-.21196521-.28756133-.52460199-.196-.81.09130759-.2851682.3345701-.49522345.63-.544l4.398-.734 2.218-4.435c.13744692-.27145241.41573373-.4426761.72-.443.3045618.00166772.58175678.1761261.715.45l2.123 4.362 4.498.801c.2933158.05211246.5333796.26289081.623.547.0898959.28455453.0135125.59548872-.198.806l-2.917 2.917.744 5.203c.042199.2957461-.0839008.5903645-.327.764-.2421349.1739598-.5609835.1986349-.827.064l-4.382-2.22-4.502 2.223c-.10996656.0547452-.23116004.0831081-.354.0831081z" fill={color} />
      }
    </svg>
  );

  const outlineMarkup = <div className="star star-outline">{outline}</div>;

  const filledMarkup = <div className="star star-filled">{filled}</div>

  return (
    <>
      {star_option &&
        <div style={{display: 'none'}} dangerouslySetInnerHTML={{__html: star_option?.template}} />
      }
      <div className={`star-ratings-container d-flex star-ratings--${props.size || 'sm'}`} style={{ "--junipStarColor": color }}>
        <div className="stars-container">
          <div className="stars-outline">
            {Array.from({ length: 5 }, (_, i) =>
              <React.Fragment key={i}>{outlineMarkup}</React.Fragment>
            )}
          </div>
          <div className="stars-filled" style={{ width: `${rating * 20}%` }}>
            {Array.from({ length: 5 }, (_, i) =>
              <React.Fragment key={i}>{filledMarkup}</React.Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StarRating;
