import { useLocation } from 'react-router-dom';
import { useLastLocation as lastLocation } from 'utils/ReactRouterLastLocation/index';

export default function useLastLocation() {
  const current = useLocation();
  const last = lastLocation();
  
  if (last?.pathname === current?.pathname) {
    return null;
  }

  if (last?.pathname.includes('login') || last?.pathname.includes('oauth')) {
    return null;
  }

  return last?.pathname || null;
}
