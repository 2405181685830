import React, { useState } from 'react';
import axios from 'axios';
import { LegacyCard, TextContainer } from '@shopify/polaris';

export default function Expired({ push }) {
  const [loading, setLoading] = useState(false);

  const home = async () => {
    setLoading(true);
    try {
      await axios.post('/api/v1/users/sign_out');
    } catch (e) {
      // No-op
    } finally {
      setLoading(false);
      push('/login');
    }
  };

  const support = () => {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      window.open('mailto:support@juniphq.com', '_blank');
    }
  }

  return (
    <LegacyCard
      sectioned
      title="Session expired"
      primaryFooterAction={{
        content: 'Contact support',
        onAction: support
      }}
      secondaryFooterActions={[{
        content: 'Take me home',
        onAction: home,
        loading: loading
      }]}
      footerActionAlignment="left">
      <TextContainer>
        <p>
          Your session has expired. Please try connecting to Junip again or contact support@juniphq.com
        </p>
      </TextContainer>
    </LegacyCard>
  );
}
