import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import {
  Page,
  LegacyCard,
  ChoiceList,
  Modal,
  TextField,
  Button,
  FormLayout,
  Select,
  Collapsible,
  TextContainer,
  Layout,
  Badge,
  ButtonGroup,
  Text,
} from "@shopify/polaris";
import LoadingPageWrapper from 'components/LoadingPageWrapper';
import SurveyOptions from 'components/SurveyOptions';
import NewSurveyOption from 'components/NewSurveyOption';
import NewSurveyRule from 'components/NewSurveyRule';
import SurveyRules from 'components/SurveyRules';
import MatchedProducts from 'components/MatchedProducts';
import QuestionPreviewModal from 'components/QuestionPreviewModal';

import ratingExample from 'assets/images/customQuestions/rating-example.svg';

import { EditIcon, XSmallIcon } from "@shopify/polaris-icons";

function Question({ history, match, showToast }) {
  const questionId = match.params.id;
  const replace = history.replace;
  const [question, setQuestion] = useState({});
  const [rules, setRules] = useState([]);
  const [rulesMeta, setRulesMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rulesLoading, setRulesLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [labelEditing, setLabelEditing] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [savingQuestion, setSavingQuestion] = useState(false);
  const [savingLabels, setSavingLabels] = useState(false);
  const [savingRules, setSavingRules] = useState(false);
  const [newName, setNewName] = useState('');
  const [newLabels, setNewLabels] = useState({label_left: '', label_center: '', label_right: ''});
  const [showExampleModal, setShowExampleModal] = useState(false);
  const [appliesToAllProducts, setAppliesToAllProducts] = useState([null]);
  const [ruleEditing, setRuleEditing] = useState(false);

  const fetchSurveyQuestion = useCallback(async () => {
    const response = await axios.get(`/api/v1/survey_questions/${questionId}`, {
      params: {
        include: 'options'
      }
    });

    return response;
  }, [questionId]);

  const fetchSurveyRules = useCallback(async ({ after = null }) => {
    const response = await axios.get('/api/v1/survey_rules', {
      params: {
        'filter[question_id]': questionId,
        'page[after]': after
      }
    });

    return response;
  }, [questionId]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await fetchSurveyQuestion();

        setQuestion(response.data.survey_question);
        setAppliesToAllProducts([response.data.survey_question.applies_to_all_products]);
        setLoading(false);
      } catch (e) {
        replace('/forms/questions');
      }
    }

    if (questionId) {
      fetchData();
    }
  }, [questionId, replace, fetchSurveyQuestion]);

  useEffect(() => {
    async function fetchRuleData() {
      try {
        setLoading(true);
        const response = await fetchSurveyRules({});

        setRulesMeta(response.data?.meta);
        setRules(response.data?.survey_rules)
        setLoading(false);
      } catch (e) {
        // No - op
      }
    }

    if (questionId) {
      fetchRuleData();
    }
  }, [questionId, replace, fetchSurveyRules]);

  const toggleEditing = () => {
    setNewQuestion(question?.question);
    setNewName(question?.name);
    toggleEditingState();
    setLabelEditing(false);
    cancelRuleEditing();
  };

  const toggleLabelEditing = () => {
    if (question?.value_type === 'rating') {
      setNewLabels({
        label_left: question?.meta?.label_left,
        label_center: question?.meta?.label_center,
        label_right: question?.meta?.label_right
      });
    } else {
      setNewLabels({ label_left: '', label_center: '', label_right: '' });
    }
    toggleLabelEditingState();
    setEditing(false);
    cancelRuleEditing();
  };

  const handleNewNameChange = useCallback((newName) => setNewName(newName), []);

  const toggleEditingState = useCallback(() => setEditing((editing) => !editing), []);
  const toggleLabelEditingState = useCallback(() => setLabelEditing((labelEditing) => !labelEditing), []);
  const handleNewQuestionChange = useCallback((newQuestion) => setNewQuestion(newQuestion), []);

  const handleAppliesToAllProductsChange = useCallback((newAppliesToAllProducts) => {
    setAppliesToAllProducts(newAppliesToAllProducts);
    setEditing(false);
    setLabelEditing(false);
    setRuleEditing(true);
  }, []);

  const cancelRuleEditing =  () => {
    setRuleEditing(false);
    setAppliesToAllProducts([question?.applies_to_all_products]);
  };

  const handleNewLabelsChange = (field) => {
    return (value) => {
      setNewLabels(labels => ({ ...labels, [field]: value }));
    };
  };

  const saveQuestion = async () => {
    setSavingQuestion(true);
    try {
      await axios.put(`/api/v1/survey_questions/${questionId}`, {
        survey_question: {
          name: newName || question?.name,
          question: newQuestion || question?.question,
        }
      });
      const response = await fetchSurveyQuestion();
      setQuestion(response.data.survey_question);
      showToast('Custom question updated');
      setEditing(false);
      trackEvent('Question - Edit question');
    } catch (e) {
      showToast('Error updating custom question, please try again', true);
      trackEvent('Error: Question - Edit question', { statusCode: e?.response?.status });
    } finally {
      setSavingQuestion(false);
    }
  };

  const saveLabels = async () => {
    setSavingLabels(true);
    try {
      await axios.put(`/api/v1/survey_questions/${questionId}`, {
        survey_question: {
          meta: question?.value_type === 'rating' ? newLabels : undefined,
        }
      });
      const response = await fetchSurveyQuestion();
      setQuestion(response.data.survey_question);
      showToast('Custom question updated');
      setLabelEditing(false);
      trackEvent('Question - Edit question labels');
    } catch (e) {
      showToast('Error updating custom question, please try again', true);
      trackEvent('Error: Question - Edit question labels', { statusCode: e?.response?.status });
    } finally {
      setSavingLabels(false);
    }
  };

  const saveRules = async () => {
    setSavingRules(true);
    try {
      await axios.put(`/api/v1/survey_questions/${questionId}`, {
        survey_question: {
          applies_to_all_products: appliesToAllProducts.includes(true),
        }
      });
      const response = await fetchSurveyQuestion();
      setQuestion(response.data.survey_question);
      showToast('Custom question updated');
      setRuleEditing(false);
      trackEvent('Question - Edit question rules');
    } catch (e) {
      showToast('Error updating custom question, please try again', true);
      trackEvent('Error: Question - Edit question rules', { statusCode: e?.response?.status });
    } finally {
      setSavingRules(false);
    }
  };

  const addOption = async (value, description) => {
    try {
      await axios.post('/api/v1/survey_options', {
        survey_option: {
          question_id: questionId,
          value: value,
          description: description || null
        }
      });
      const response = await fetchSurveyQuestion();
      setQuestion(response.data.survey_question);
      showToast('Option added');
      trackEvent('Question - Add option');
    } catch (e) {
      if (e?.response?.status === 400) {
        showToast('Option already exists', true);
      } else {
        showToast('Error adding option, please try again', true);
      }

      trackEvent('Error: Question - Add option', { statusCode: e?.response?.status });
    }
  };

  const removeOption = async (id) => {
    try {
      await axios.delete(`/api/v1/survey_options/${id}`);
      const response = await fetchSurveyQuestion();
      setQuestion(response.data.survey_question);
      showToast('Option deleted');
      trackEvent('Question - Delete option');
    } catch (e) {
      showToast('Error deleting option, please try again', true);
      trackEvent('Error: Question - Delete option', { statusCode: e?.response?.status });
    }
  };

  const addRule = async (newRule) => {
    try {
      const response = await axios.post('/api/v1/survey_rules', {
        survey_rule: { question_id: questionId, ...newRule}
      });
      setRules((r) => [...r, response.data?.survey_rule]);

      showToast('Match rule added');
      trackEvent('Question - Add rule');
    } catch (e) {
      showToast('Error adding matching rule, please try again', true);
      trackEvent('Error: Question - Add rule', { statusCode: e?.response?.status });
    }
  };

  const removeRule = async (id) => {
    try {
      await axios.delete(`/api/v1/survey_rules/${id}`);
      setRules((r) => r.filter(i => i.id !== id));

      showToast('Rule deleted');
      trackEvent('Question - Delete rule');
    } catch (e) {
      showToast('Error deleting matching rule, please try again', true);
      trackEvent('Error: Question - Delete rule', { statusCode: e?.response?.status });
    }
  };

  const deleteSurveyQuestion = async () => {
    setDeleting(true);
    try {
      await axios.delete(`/api/v1/survey_questions/${questionId}`);
      showToast(`${question.name} custom question deleted`);
      trackEvent('Question - Delete');
      setDeleting(false);
      setShowDeleteModal(false);
      history.replace(`/forms/questions`)
    } catch (e) {
      showToast(`Error deleting ${question.name} custom question`, true);
      trackEvent('Error: Question - Delete', { statusCode: e?.response?.status });
      setDeleting(false);
      setShowDeleteModal(false);
    }
  };

  const fetchMoreRules = async () => {
    setRulesLoading(true);
    try {
      const response = await fetchSurveyRules(rulesMeta?.page);
      setRulesMeta(response.data?.meta);
      setRules((r) => [...r, ...response.data?.survey_rules]);
    } catch(e) {
      showToast('Error fetching more rules, please try again', true);
    } finally {
      setRulesLoading(false);
    }
  };

  const renderTypeName = () => {
    if (question?.value_type === 'radio') {
      return 'Multiple Choice';
    } else if (question?.value_type === 'rating') {
      return 'Linear Scale';
    } else {
      return 'Written Answer';
    }
  };

  return (
    <LoadingPageWrapper loading={loading} cards={3}>
      <Page
        title={question.name}
        titleMetadata={<Badge tone={question?.private ? 'info' : 'success'}>{question?.private ? 'Private' : 'Public'}</Badge>}
        primaryAction={{
          content: 'Delete',
          destructive: true,
          onAction: () => setShowDeleteModal(true),
          loading: deleting
        }}
        backAction={{
          content: 'All customer questions',
          url: '/forms/questions'
        }}
      >
        <Layout>
          <Layout.Section>
            <LegacyCard
              title="Edit question"
              sectioned
              actions={[{
                content: editing ? 'Cancel' : 'Edit',
                onAction: toggleEditing,
                disabled: savingQuestion,
                icon: editing ? XSmallIcon : EditIcon,
              }]}>
              <TextContainer>
                <TextField
                  type="text"
                  label={question?.private ? 'Name' : 'Display name'}
                  placeholder={'ex. Skin type'}
                  value={editing ? newName : question?.name}
                  disabled={!editing}
                  onChange={handleNewNameChange}
                />
                <TextField
                  type="text"
                  label="Question"
                  value={editing ? newQuestion : question?.question}
                  onChange={handleNewQuestionChange}
                  disabled={!editing}
                  placeholder="ex. What is your skin type?"
                />
                <Collapsible
                  open={editing}
                >
                  <ButtonGroup>
                    <Button

                      loading={savingQuestion}
                      onClick={saveQuestion}
                      disabled={!newQuestion}
                      variant="primary">
                      Save
                    </Button>
                    <Button
                      disabled={savingQuestion}
                      onClick={toggleEditing}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Collapsible>
              </TextContainer>
            </LegacyCard>
            {question?.value_type === 'rating' && (
              <LegacyCard
                title="Scale"
                actions={[{
                  content: labelEditing ? 'Cancel' : 'Edit',
                  onAction: toggleLabelEditing,
                  disabled: savingLabels,
                  icon: labelEditing ? XSmallIcon : EditIcon
                }]}>
                <LegacyCard.Section>
                  <div className="text-center mx-auto">
                    <img src={ratingExample} alt="Rating example" className="w-100" style={{maxWidth: '575px'}} />
                  </div>
                </LegacyCard.Section>
                <LegacyCard.Section>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <TextField
                        label="Min label"
                        onChange={handleNewLabelsChange('label_left')}
                        value={labelEditing ? newLabels.label_left : question?.meta?.label_left}
                        disabled={!labelEditing}
                      />
                      <TextField
                        label="Middle label"
                        onChange={handleNewLabelsChange('label_center')}
                        value={labelEditing ? newLabels.label_center : question?.meta?.label_center}
                        disabled={!labelEditing}
                      />
                      <TextField
                        label="Max label"
                        onChange={handleNewLabelsChange('label_right')}
                        value={labelEditing ? newLabels.label_right : question?.meta?.label_right}
                        disabled={!labelEditing}
                      />
                    </FormLayout.Group>
                    <Collapsible
                      open={labelEditing}
                    >
                      <ButtonGroup>
                        <Button  loading={savingLabels} onClick={saveLabels} variant="primary">
                          Save
                        </Button>
                        <Button
                          disabled={savingLabels}
                          onClick={toggleLabelEditing}
                        >
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </Collapsible>
                  </FormLayout>
                </LegacyCard.Section>
              </LegacyCard>
            )}
            {question.value_type === 'radio' &&
              <LegacyCard title="Responses">
                <LegacyCard.Section>
                  <p>
                    <Text variant="bodyMd" as="span" tone="subdued">Add at least 2 options</Text>
                  </p>
                </LegacyCard.Section>
                <SurveyOptions
                  options={question.options.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))}
                  removeOption={removeOption}
                />
                <NewSurveyOption addOption={addOption} />
              </LegacyCard>
            }
            <LegacyCard title="Product assignment">
              <LegacyCard.Section>
                <TextContainer>
                  <p>Assign this question to:</p>
                  <ChoiceList
                    choices={[
                      {
                        label: 'All products',
                        value: true,
                        helpText: 'This question will be included on all product review forms.'
                      },
                      {
                        label: 'Some products',
                        value: false,
                        helpText: 'This question will be included on only some of your products.'
                      }
                    ]}
                    selected={appliesToAllProducts}
                    onChange={handleAppliesToAllProductsChange}
                  />
                  <Collapsible
                    open={ruleEditing}
                  >
                    <ButtonGroup>
                      <Button  loading={savingRules} onClick={saveRules} variant="primary">
                        Save selection
                      </Button>
                      <Button
                        disabled={savingRules}
                        onClick={cancelRuleEditing}
                      >
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Collapsible>
                </TextContainer>
              </LegacyCard.Section>
              {question?.applies_to_all_products === false && (
                <>
                  <SurveyRules
                    rules={rules}
                    removeRule={removeRule}
                  />
                  {rulesMeta?.page?.after &&
                    <LegacyCard.Section>
                      <div className="text-center">
                        <Button  loading={rulesLoading} onClick={fetchMoreRules} variant="plain">
                          See more
                        </Button>
                      </div>
                    </LegacyCard.Section>
                  }
                  <NewSurveyRule addRule={addRule} />
                </>
              )}
            </LegacyCard>
            <MatchedProducts questionId={questionId} />
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <LegacyCard title="Details">
              <LegacyCard.Section title="Question type">
                <p>{renderTypeName()}</p>
              </LegacyCard.Section>
              <LegacyCard.Section title="Response sharing">
                <TextContainer spacing="tight">
                  <Select
                    options={[
                      { label: 'Public', value: 'public' },
                      { label: 'Private', value: 'private' },
                    ]}
                    value={question?.private ? 'private' : 'public'}
                    disabled
                    helpText={question?.private ? <>Responses are <b>only visible to your team</b>.</> : 'Responses will be published with reviews'}
                  />
                  {!question?.private && (
                    <Button  onClick={() => setShowExampleModal(true)} variant="plain">View example</Button>
                  )}
                </TextContainer>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </Page>
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        primaryAction={{
          content: 'Delete',
          destructive: true,
          loading: deleting,
          onAction: () => { deleteSurveyQuestion(); trackEvent('Question delete modal - Delete') }
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => { setShowDeleteModal(false); trackEvent('Question delete modal - Cancel') },
            disabled: deleting
          }
        ]}
        title={`Delete the ${question.name} custom question?`}
      >
        <Modal.Section>
          <p>Are you sure you want to delete the {question.name} custom question?</p>
        </Modal.Section>
      </Modal>
      <QuestionPreviewModal open={showExampleModal} close={() => setShowExampleModal(false)} />
    </LoadingPageWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(Question);

