import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Frame
} from '@shopify/polaris';

import UserLoadingCover from 'components/UserLoadingCover';
import TopBar from 'components/TopBar';
import NavigationMenu from 'components/NavigationMenu';
import ContextualSaveBar from 'components/ContextualSaveBar';
import Loading from 'components/Loading';
import Toast from 'components/Toast';
import HelpBeacon from 'components/HelpBeacon';

const MainFrame = (props) => {
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const toggle = () => {
    return () => {
      setShowMobileNavigation(!showMobileNavigation)
    }
  };

  const closeMobileMenu = () => {
    setShowMobileNavigation(false);
  };

  const showNav = !props.location.pathname.includes('/login') &&
    !props.location.pathname.includes('/onboarding') &&
    !props.location.pathname.includes('/oauth') &&
    !props.location.pathname.includes('/accept_invitation');

    // Don't show loading cover on oauth or accept invitation page as we fetch user/me there
  const hideLoadingCover = props.location.pathname.includes('/oauth') === true || 
    props.location.pathname.includes('/accept_invitation') === true;

  const topBarMarkup = ( showNav ?
    <TopBar
      toggleFrameState={toggle}
      toggleColorScheme={props.toggleColorScheme}
      colorScheme={props.colorScheme}
    />
  : null );

  const navigationMarkup = ( showNav ?
    <NavigationMenu closeMobileMenu={closeMobileMenu} />
  : null );

  return (
    <Frame
      topBar={topBarMarkup}
      navigation={navigationMarkup}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={toggle()}
    >
      { !hideLoadingCover &&
        <UserLoadingCover />
      }
      <ContextualSaveBar />
      <Loading />
      { props.children }
      <Toast />
      <HelpBeacon />
    </Frame>
  )
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(
  mapStateToProps,
)(MainFrame));
