import aftership from 'assets/images/apps/aftership.jpg';
import attentive from 'assets/images/apps/attentive.png';
import klaviyo from 'assets/images/apps/klaviyo.svg';
import malomo from 'assets/images/apps/malomo.svg';
import omnisend from 'assets/images/apps/omnisend.svg';
import postscript from 'assets/images/apps/postscript.svg';
import drip from 'assets/images/apps/drip.svg';
import shopifyFlow from 'assets/images/apps/shopifyFlow.jpg';
import voyageSms from 'assets/images/apps/voyage-sms.png';
import gorgias from 'assets/images/apps/gorgias.svg';

export const appListingDetails = {
  'aftership': {
    icon: aftership,
    name: 'AfterShip',
    description: 'Proactive order tracking that delights your customers, reduces WISMO, and optimizes delivery.',
    websiteUrl: 'https://www.aftership.com/',
    adminUrl: 'https://admin.aftership.com/',
    helpGuide: null,
    flowsHelpGuide: null,
    features: [
      'Branded tracking pages with personalized product recommendations to boost sales',
      'Timely email and SMS notifications to reduce customer anxiety and WISMO calls',
      'Centralized shipment dashboard to track shipment status and identify exceptions',
      'AI-powered estimated delivery dates to reassure anxious customers',
      'Built-in analytics to get insights into shipment status and on-time performance'
    ],
    newEventFeatures: [
      'Branded tracking pages with personalized product recommendations to boost sales',
      'Timely email and SMS notifications to reduce customer anxiety and WISMO calls',
      'Centralized shipment dashboard to track shipment status and identify exceptions',
      'AI-powered estimated delivery dates to reassure anxious customers',
      'Built-in analytics to get insights into shipment status and on-time performance'
    ],
    affectsMessageSettings: false,
  },
  'attentive': {
    icon: attentive,
    name: 'Attentive',
    description: 'A comprehensive text message marketing solution',
    websiteUrl: 'https://www.attentivemobile.com/',
    adminUrl: 'https://ui.attentivemobile.com/',
    helpGuide: 'https://help.junip.co/en/articles/5604276-attentive-sms-guide',
    flowsHelpGuide: 'https://help.junip.co/en/articles/5907170-adding-integrations-to-your-flows',
    features: [
      'Send Order Fulfilled & Review Created events to Attentive',
      'Fully manage your review request flows',
      'Segment & build flows from new reviews created'
    ],
    newEventFeatures: [
      'Send Review Request & Review Submitted events to Attentive',
      'Fully manage your review request flows',
      'Segment & build flows from new reviews created'
    ],
    affectsMessageSettings: true,
  },
  'gorgias': {
    icon: gorgias,
    name: 'Gorgias',
    description: 'Unify all support conversations (and related orders) to accelerate growth through happier customers.',
    websiteUrl: 'https://www.gorgias.com/',
    shopifyAppUrl: 'https://apps.shopify.com/helpdesk',
    helpGuide: '',
    flowsHelpGuide: 'https://help.junip.co/en/articles/5907170-adding-integrations-to-your-flows',
    installUrl: 'https://www.gorgias.com/apps',
    newEventFeatures: [
      'Send Review Submitted events (with a specific star rating) to Gorgias',
      'Respond to reviews directly from Gorgias',
    ],
    affectsMessageSettings: true,
  },
  'klaviyo': {
    icon: klaviyo,
    name: 'Klaviyo',
    description: 'Email and SMS marketing platform for ecommerce',
    websiteUrl: 'https://www.klaviyo.com/',
    shopifyAppUrl: 'https://apps.shopify.com/klaviyo-email-marketing',
    adminUrl: 'https://www.klaviyo.com/login',
    helpGuide: 'https://help.junip.co/en/articles/4607183-klaviyo-guide',
    flowsHelpGuide: 'https://help.junip.co/en/articles/5907170-adding-integrations-to-your-flows',
    features: [
      'Send Order Fulfilled & Review Created events to Klaviyo',
      'Fully manage your review request flows',
      'Segment & build flows from new reviews created'
    ],
    newEventFeatures: [
      'Send Review Request & Review Submitted events to Klaviyo',
      'Fully manage your review request flows',
      'Segment & build flows from new reviews created'
    ],
    affectsMessageSettings: true,
  },
  'malomo': {
    icon: malomo,
    name: 'Malomo',
    description: 'Malomo reduces customer support tickets, automates branded shipping notifications, and generates additional revenue that keeps your customers happy and informed while they\'re waiting for their package to arrive.',
    websiteUrl: 'https://gomalomo.com/',
    adminUrl: 'https://dash.gomalomo.com/',
    helpGuide: null,
    flowsHelpGuide: null,
    features: [
      'Control the look and feel of the order tracking page to match your branding with a drag and drop experience',
      'Decrease your customer support tickets with up-to-the-minute shipment information.',
      '1 order, 2 packages? Provide multiple order numbers for both packages on one tracking page.',
      'Drive customer retention and customer loyalty by turning post-purchase into a memorable experience.'
    ],
    newEventFeatures: [
      'Control the look and feel of the order tracking page to match your branding with a drag and drop experience',
      'Decrease your customer support tickets with up-to-the-minute shipment information.',
      '1 order, 2 packages? Provide multiple order numbers for both packages on one tracking page.',
      'Drive customer retention and customer loyalty by turning post-purchase into a memorable experience.'
    ],
    apiKeyHelpSteps: [
      'Generate a new Secret Key to be used with Junip',
      'Copy your Secret API Key and paste it above',
    ],
    affectsMessageSettings: false,
  },
  'omnisend': {
    icon: omnisend,
    name: 'Omnisend',
    description: 'Ecommerce marketing automation platform',
    websiteUrl: 'https://www.omnisend.com/',
    shopifyAppUrl: 'https://apps.shopify.com/omnisend',
    adminUrl: 'https://app.omnisend.com/#/login/',
    helpGuide: 'https://help.junip.co/en/articles/4607193-omnisend-guide',
    flowsHelpGuide: 'https://help.junip.co/en/articles/5907170-adding-integrations-to-your-flows',
    features: [
      'Send Order Fulfilled & Review Created events to Omnisend',
      'Fully manage your review request flows',
      'Segment & build flows from new reviews created'
    ],
    newEventFeatures: [
      'Send Review Request & Review Submitted events to Omnisend',
      'Fully manage your review request flows',
      'Segment & build flows from new reviews created'
    ],
    affectsMessageSettings: true,
  },
  'postscript': {
    icon: postscript,
    name: 'Postscript',
    description: 'SMS marketing automation platform',
    websiteUrl: 'https://www.postscript.io/',
    shopifyAppUrl: 'https://apps.shopify.com/postscript-sms-marketing',
    helpGuide: 'https://help.junip.co/en/articles/4607209-postscript-guide',
    flowsHelpGuide: 'https://help.junip.co/en/articles/5907170-adding-integrations-to-your-flows',
    features: [
      'Send Order Fulfilled & Review Created events to Postscript',
      'Fully manage your review request flows',
      'Segment & build SMS flows from new reviews created'
    ],
    newEventFeatures: [
      'Send Review Request & Review Submitted events to Postscript',
      'Fully manage your review request flows',
      'Segment & build SMS flows from new reviews created'
    ],
    apiKeyHelpSteps: [
      'Create a new "Security Key Pair" to be used with Junip',
      'Click "Show" to reveal your private API key and enter it above',
    ],
    affectsMessageSettings: true,
  },
  'drip': {
    icon: drip,
    name: 'Drip',
    description: 'Email and SMS ecommerce marketing engine',
    websiteUrl: 'https://www.drip.com/',
    shopifyAppUrl: 'https://apps.shopify.com/drip',
    helpGuide: 'https://help.junip.co/en/articles/4622705-drip-guide',
    flowsHelpGuide: 'https://help.junip.co/en/articles/5907170-adding-integrations-to-your-flows',
    features: [
      'Send Order Fulfilled & Review Created events to Drip',
      'Fully manage your review request flows',
      'Segment & build flows from new reviews created'
    ],
    newEventFeatures: [
      'Send Review Request & Review Submitted events to Drip',
      'Fully manage your review request flows',
      'Segment & build flows from new reviews created'
    ],
    affectsMessageSettings: true,
  },
  'shopify': {
    icon: shopifyFlow,
    name: 'Shopify Flow',
    description: 'Automate everything and get back to business',
    shopifyAppUrl: 'https://apps.shopify.com/flow',  // They don't have one.,
    helpGuide: 'https://help.junip.co/en/articles/6405748-shopify-flow-guide',
    features: [
      'Send Review submitted events to Shopify Flow',
    ],
    newEventFeatures: [
      'Send Review Submitted events to Shopify Flow',
    ],
    affectsMessageSettings: false,
  },
  'voyage-sms': {
    icon: voyageSms,
    name: 'Voyage SMS',
    description: 'SMS marketing automation platform',
    websiteUrl: 'https://voyagesms.com/',
    shopifyAppUrl: null,  // They don't have one.
    helpGuide: 'https://help.junip.co/en/articles/5162383-voyage-sms-guide',
    features: [
      'Send Order Fulfilled & Review Created events to Voyage SMS',
      'Fully manage your review request flows',
      'Segment & build SMS flows from new reviews created'
    ],
    newEventFeatures: [
      'Send Review Created events to Voyage SMS',
      'Fully manage your review request flows',
      'Segment & build SMS flows from new reviews created'
    ],
    affectsMessageSettings: true,
  },
  'voyage_sms': {
    icon: voyageSms,
    name: 'Voyage SMS',
    description: 'SMS marketing automation platform',
    websiteUrl: 'https://voyagesms.com/',
    shopifyAppUrl: null,  // They don't have one.
    helpGuide: 'https://help.junip.co/en/articles/5162383-voyage-sms-guide',
    features: [
      'Send Order Fulfilled & Review Created events to Voyage SMS',
      'Fully manage your review request flows',
      'Segment & build SMS flows from new reviews created'
    ],
    newEventFeatures: [
      'Send Review Created events to Voyage SMS',
      'Fully manage your review request flows',
      'Segment & build SMS flows from new reviews created'
    ],
    affectsMessageSettings: true,
  }
};
