import React from 'react';
import {
  Modal,
} from '@shopify/polaris';

export default function BillingCancelModal({showCancelModal, setShowCancelModal, cancelBillingSubscription}) {
  return (
    <Modal
      open={showCancelModal}
      onClose={() => setShowCancelModal(false)}
      title="Are you sure you want to cancel your subscription?"
      primaryAction={{
        content: 'Yes, cancel my subscription',
        onAction: () => {cancelBillingSubscription(); setShowCancelModal(false)}
      }}
      secondaryActions={[
        {
          content: 'Go back',
          onAction: () => setShowCancelModal(false),
        },
      ]}
    >
      <Modal.Section>
        <p>Cancelling your subscription will disable your reviews program</p>
      </Modal.Section>
    </Modal>
  );
}
