import React from 'react';
import { LegacyCard, Button, Avatar, LegacyStack, TextContainer, Text } from "@shopify/polaris";

export default function Continue({ orNew, working, currentUser, selectUser }) {
  const queryParams = window.location.search || '';

  return <>
    <LegacyCard sectioned title="Continue as:">
      <LegacyStack spacing="loose" alignment="center">
        <LegacyStack.Item fill>
          <LegacyStack wrap={false} alignment="center">
            <Avatar
              initials={`${currentUser?.first_name?.[0] || ''}${currentUser?.last_name?.[0] || ''}`}
              customer={!currentUser?.first_name || !currentUser?.last_name}
              name={`${currentUser?.first_name} ${currentUser?.last_name}`}
              size="lg"
            />
            <div>
              <TextContainer>
                <p>{currentUser?.first_name || 'New'} {currentUser?.last_name || 'user'}</p>
                <Text variant="bodyMd" as="span" tone="subdued">{currentUser?.email || 'Add your information'}</Text>
              </TextContainer>
            </div>
          </LegacyStack>
        </LegacyStack.Item>
        <Button
          size="large"

          loading={working}
          onClick={() => selectUser(currentUser)}
          variant="primary">
          Continue
        </Button>
      </LegacyStack>
    </LegacyCard>
    <LegacyCard sectioned>
      <Button disabled={working}  url={`/oauth/sign_in${queryParams}`} variant="plain">Sign in</Button> as a different user{ orNew &&
        <>
          {' '}or{' '}
          <Button
            disabled={working}

            url={`/oauth/create_account${queryParams}`}
            variant="plain">create a new account</Button>
        </>
      }
    </LegacyCard>
  </>;
}
