import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { changesSaved } from 'redux/settings';

// Actions
const FETCH_MODERATION_SETTINGS_BEGIN = 'FETCH_MODERATION_SETTINGS_BEGIN';
const FETCH_MODERATION_SETTINGS_SUCCESS = 'FETCH_MODERATION_SETTINGS_SUCCESS';
const FETCH_MODERATION_SETTINGS_ERROR = 'FETCH_MODERATION_SETTINGS_ERROR';

// Action Creators
export function fetchModerationSettings() {
  return async dispatch => {
    dispatch(fetchModerationSettingsBegin());
    try {
      const response = await axios.get('/api/v1/moderation_settings');
      dispatch(fetchModerationSettingsSuccess(response.data.moderation_settings[0]));
    } catch (e) {
      dispatch(fetchModerationSettingsError(e));
    }
  }
}

export function saveModerationSettings(id, settings) {
  return async dispatch => {
    try {
      const response = await axios.put(
        `/api/v1/moderation_settings/${id}`,
        {
          moderation_setting: settings
        }
      );
      dispatch(fetchModerationSettingsSuccess(response.data.moderation_setting));
      dispatch(changesSaved());
    } catch (e) {
      dispatch(fetchModerationSettingsError(e));
      dispatch(changesSaved(true));
      trackEvent('Error: Moderation settings - Save changes');
    }
  }
}

export const fetchModerationSettingsBegin = () => ({
  type: FETCH_MODERATION_SETTINGS_BEGIN
});

export const fetchModerationSettingsSuccess = settings => ({
  type: FETCH_MODERATION_SETTINGS_SUCCESS,
  data: { settings }
});

export const fetchModerationSettingsError = error => ({
  type: FETCH_MODERATION_SETTINGS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_MODERATION_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_MODERATION_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.data.settings,
        loading: false,
        error: null
      };
    case FETCH_MODERATION_SETTINGS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
