// Actions
const SHOW_TOAST = 'SHOW_TOAST';
const DISMISS_TOAST = 'DISMISS_TOAST';

// Action Creators
export const showToast = (message, error) => ({
  type: SHOW_TOAST,
  message,
  error
});

export const dimissToast = () => ({
  type: DISMISS_TOAST
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        message: action.message,
        error: action.error
      }
    case DISMISS_TOAST:
      return {
        ...state,
        message: null,
        error: false
      }
    default:
      return state;
  }
}
