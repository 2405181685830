import { useEffect, useState, useMemo } from 'react';
import useLoadAnalyticsDb from 'utils/useLoadAnalyticsDb';
import { calcPercentage, whereFormatter } from 'utils/AnalyticsUtils';

export default function useSubmissionRate(setLoading, dateRange, timePeriod) {
  const { db } = useLoadAnalyticsDb(setLoading);

  const [tableData, setTableData] = useState([]);

  const limit = useMemo(() => {
    switch (dateRange) {
      case 'last-7-days':
        return 1;
      case 'last-30-days':
        return timePeriod === 'monthly' ? 1 : 4;
      case 'last-3-months':
        return timePeriod === 'monthly' ? 3 : 13;
      case 'last-12-months':
        return timePeriod === 'monthly' ? 12 : 52;
      default:
        break;
    }
  }, [dateRange, timePeriod]);

  useEffect(() => {
    if (db) {
      const today = new Date();
      const [grouping, max] = timePeriod === 'monthly' ? [today.getMonth() + 1, 12] : [getISOWeek(today), 52];
      const year = new Date().getFullYear();
      const lastMonday = new Date();
      lastMonday.setDate(today.getDate() - (today.getDay() + 6) % 7);
      const mondays = timePeriod === 'weekly'
        ? [...Array(limit)].map((_, i) => {
          const monday = new Date(lastMonday.getTime() - 7 * i * 24 * 60 * 60 * 1000);
          return monday.toISOString().split('T')[0];
        })
        : undefined;
      const rows = [...Array(limit)].map((_, i) => {
        const m = grouping - i + max - 1;
        const y = m < max ? year - 1 : year;
        const month = ((m % max) + 1).toString().padStart(2, '0');
        return `${month}-${y}`;
      });

      const flowActionIdQuery = `SELECT flow_action_id from analytics_flow_action_event_topic_maps WHERE event_topic_string='review/request'`;
      const flowActionId = db.exec(flowActionIdQuery)[0]?.values?.flat();
      const FLOW_ACTION = `flow_action_id IN (${flowActionId.join()})`;
      const isNotImported = 'imported = FALSE';

      const ordersRequestedQuery = `SELECT
        ${getDateSelect('date', timePeriod)},
        SUM(completed) as total_orders_requested
        FROM analytics_daily_flow_action_stats
        ${whereFormatter(FLOW_ACTION)}
        GROUP BY ${getGrouping('date', timePeriod)}
        ORDER BY date DESC;`;

      const reviewCountQuery = `SELECT
          ${getDateSelect('created_at', timePeriod)},
          COUNT(created_at) as total_reviews,
          COUNT(CASE when has_media = true THEN 1 END) as media_reviews
          FROM analytics_reviews
          ${whereFormatter(isNotImported)}
          GROUP BY ${getGrouping('created_at', timePeriod)}
          ORDER BY created_at DESC
          LIMIT ${limit}; `;

      const ordersRequestedByDate = db.exec(ordersRequestedQuery)[0]?.values;
      const reviewCounts = db.exec(reviewCountQuery)[0]?.values;

      let i = 0;
      let j = 0;
      const tableData = rows.map((date, index) => {
        let reviews = 0;
        let mediaReviews = 0;
        if (i < reviewCounts?.length && reviewCounts?.[i]?.[0] === date) {
          reviews = reviewCounts[i][1];
          mediaReviews = reviewCounts[i][2];
          i++;
        }

        let ordersRequested = 0;
        if (j < ordersRequestedByDate?.length && ordersRequestedByDate?.[j]?.[0] === date) {
          ordersRequested = ordersRequestedByDate[j][1];
          j++;
        }

        const submissionRate = calcPercentage(reviews, ordersRequested);
        const mediaSubmissionRate = calcPercentage(mediaReviews, reviews);

        return {
          date: mondays ? mondays[index] : date,
          submissionRate,
          ordersRequested,
          reviews,
          mediaReviews,
          mediaSubmissionRate,
        }
      });
      setTableData(tableData);
    }
  }, [db, limit, timePeriod]);

  return { tableData };
}

function getISOWeek(date) {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstThurs = new Date(date.getFullYear(), 0, 4);
  firstThurs.setDate(firstThurs.getDate() - ((firstThurs.getDay() + 6) % 7));
  const diffDays = Math.round((date - firstThurs) / oneDay);
  const weekNumber = Math.ceil((diffDays + firstThurs.getDay() + 1) / 7);
  return weekNumber;
}

function getDateSelect(dateColumn, timePeriod) {
  return timePeriod === 'monthly' ? ` strftime("%m-%Y", ${dateColumn}) as 'month-year'` : `strftime('%W-%Y', ${dateColumn}) as week`;
}

function getGrouping(dateColumn, timePeriod) {
  return timePeriod === 'monthly' ? `strftime('%m-%Y', ${dateColumn})` : `strftime('%W-%Y', ${dateColumn})`;
}
