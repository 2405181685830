import axios from 'axios';

// Actions
const FETCH_APP_INSTALLS_BEGIN = 'FETCH_APP_INSTALLS_BEGIN';
const FETCH_APP_INSTALLS_SUCCESS = 'FETCH_APP_INSTALLS_SUCCESS';
const FETCH_APP_INSTALLS_ERROR = 'FETCH_APP_INSTALLS_ERROR';

// Action Creators
export function fetchAppInstalls(data = {}) {
  return async dispatch => {
    dispatch(fetchAppInstallsBegin());
    try {
      const response = await axios.get('/api/v1/app_installs', {
        params: {
          include: 'app,event_subscriptions'
        }
      });
      dispatch(fetchAppInstallsSuccess(response.data.app_installs));
    } catch (e) {
      dispatch(fetchAppInstallsError(e));
    }
  }
}

export const fetchAppInstallsBegin = () => ({
  type: FETCH_APP_INSTALLS_BEGIN
});

export const fetchAppInstallsSuccess = data => ({
  type: FETCH_APP_INSTALLS_SUCCESS,
  data
});

export const fetchAppInstallsError = error => ({
  type: FETCH_APP_INSTALLS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_APP_INSTALLS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_APP_INSTALLS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_APP_INSTALLS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
