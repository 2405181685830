import React, { useState, useCallback } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { showToast } from 'redux/toast';
import axios from 'axios';
import { NoteIcon } from "@shopify/polaris-icons";
import {
  Button,
  LegacyStack,
  Spinner,
  DropZone,
  Banner,
  List,
  Modal,
  Icon
} from '@shopify/polaris';

import fileChecksum from 'utils/fileChecksum';

const FILE_SIZE_LIMIT = 100000000;

function ProductIdentifierImportDropZone({ setPendingImport, importing, showToast, cancelClicked, importFinished }) {
  const [loading, setLoading] = useState(false);
  const [startingImport, setStartingImport] = useState(false);
  const [csv, setCsv] = useState(null);
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;

  const uploadFile = useCallback(async (file) => {
    const { checksum } = await fileChecksum(file);
    const response = await axios.post('/api/active_storage/direct_uploads', {
      blob: {
        'filename': file.name,
        'content_type': file.type,
        'byte_size': file.size,
        'checksum': checksum
      }
    });

    await axios.put(response.data.direct_upload.url, file.slice(), {
      headers: response.data.direct_upload.headers,
      withCredentials: false
    });

    setCsv(response.data.signed_id);
    setLoading(false);
  }, []);

  const handleAcceptedDrop = useCallback(
    (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];
      if (acceptedFile.size > FILE_SIZE_LIMIT) {
        setRejectedFiles([acceptedFile]);
      } else {
        setFiles([acceptedFile]);
        setRejectedFiles([]);
        setLoading(true);
        uploadFile(acceptedFile);
      }
    },
    [uploadFile]
  );

  const importCsv = async () => {
    setStartingImport(true);
    trackEvent('Product catalog settings - Import');
    try {
      const response = await axios.post('/api/v1/product_identifier_imports', {
        product_identifier_import: {
          csv
        }
      });
      setPendingImport(response.data.product_identifier_import);
      showToast('Importing product catalog...');
      importFinished();
    } catch (e) {
      showToast('Error importing product catalog, please try again', true);
      trackEvent('Error: Product catalog settings - Import', { statusCode: e?.response?.status });
    } finally {
      setStartingImport(false);
    }
  };

  const cancelImport = () => {
    cancelClicked();
  };

  const handleRejectedDrop = useCallback(
    (rejectedFiles) => {
      setRejectedFiles([rejectedFiles[0]]);
      trackEvent('Product catalog settings - Import file rejected');
    },
    []
  );

  const handleReplace = () => {
    setFiles([]);
    setCsv(null);
  };

  const getDropZone = () => {
    if (loading || files.length !== 0) {
      return null;
    }
    return (
      <DropZone
        accept=".csv, text/csv"
        type="file"
        onDropAccepted={handleAcceptedDrop}
        onDropRejected={handleRejectedDrop}
        allowMultiple={false}
      >
        {fileUpload}
      </DropZone>
    );
  };

  const getUploadedFiles = () => {
    if (files.length === 0 || loading) {
      return null;
    }
    return (
      <Modal.Section>
        <LegacyStack distribution="equalSpacing" alignment="center">
          <LegacyStack.Item>
            <Icon
              source={NoteIcon}
              tone="base" />
          </LegacyStack.Item>
          <LegacyStack.Item fill={true}>
            {files[0].name}
          </LegacyStack.Item>
          <LegacyStack.Item>
            <Button onClick={handleReplace}>
              Replace
            </Button>
          </LegacyStack.Item>
        </LegacyStack>
      </Modal.Section>
    );
  };

  const getUploadSection = () => {
    if (errorMessage || loading || getDropZone()) {
      return (
        <Modal.Section>
          <LegacyStack vertical>
            {errorMessage}
            {loading &&
              <div className="dropzone-spinner">
                <Spinner accessibilityLabel="Uploading csv" size="large" color="primary" />
              </div>
            }
            { getDropZone() }
          </LegacyStack>
        </Modal.Section>
      );
    };
    return null;
  };

  const fileUpload = files.length === 0 && <DropZone.FileUpload />;

  const errorMessage = hasError && (
    <Banner
      title="The following files couldn’t be uploaded:"
      tone="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .csv (was ${file.type}) and less than 100MB.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return <>
    { getUploadSection() }
    { getUploadedFiles() }
    <Modal.Section>
      <LegacyStack distribution="trailing" spacing="tight">
        <Button
          onClick={cancelImport}
        >
          Cancel
        </Button>
        <Button

          disabled={importing || loading || !csv}
          loading={startingImport}
          onClick={importCsv}
          variant="primary">
          Import CSV
        </Button>
      </LegacyStack>
    </Modal.Section>
  </>;
};

const mapDispatchToProps = (dispatch) => ({
  showToast: (message, error) => dispatch(showToast(message, error))
});

export default connect(
  null,
  mapDispatchToProps
)(ProductIdentifierImportDropZone);
