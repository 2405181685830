import axios from 'axios';
import store from 'redux/store';
import { setLoggedOut } from 'redux/user';

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error?.response?.status !== 401) {
      return Promise.reject(error);
    } else if (error.request?.responseURL?.includes('/reset_password')) {
      return Promise.reject(error);
    } else {
      store.dispatch(setLoggedOut());
    }
  }
)
