import { useCallback, useEffect, useState } from "react";

export default function useHash() {
  const [hash, setHash] = useState(window.location.hash);

  const onHashChange = useCallback(() => {
    setHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange);
    return () => {
      window.removeEventListener('hashchange', onHashChange);
    }
  }, [onHashChange]);

  const updateHash = useCallback((newHash) => {
    window.location.hash = newHash;
  }, []);

  return [hash, updateHash];
}
