import React, { useState } from 'react';
import { Button, LegacyCard, Icon, Text } from "@shopify/polaris";

import { DeleteIcon } from "@shopify/polaris-icons";
import NewSurveyRule from './NewSurveyRule';

export default function SurveyRules({ rules, removeRule }) {
  return <>
    <LegacyCard.Section title="Conditions">
      <LegacyCard.Subsection>
        <p>
          <Text variant="bodyMd" as="span" tone="subdued">Products must match <b>at least one condition</b>.</Text>
        </p>
      </LegacyCard.Subsection>
      { rules?.map((rule, index) =>
        <SurveyRule
          key={rule?.id}
          rule={rule}
          index={index}
          removeRule={removeRule}
        />
      )}
    </LegacyCard.Section>
  </>;
}

function SurveyRule({rule, index, removeRule}) {
  const [loading, setLoading] = useState(false);
  const remove = () => {
    setLoading(true);
    removeRule(rule?.id, index);
  };

  return (
    <NewSurveyRule readOnly rule={rule} action={
      <Button
        onClick={() => { remove(rule?.id, index) }}
        loading={loading}
        
        size="slim"
      >
        <Icon accessibilityLabel="Delete rule" source={DeleteIcon} />
      </Button>
    }/>
  );
}
