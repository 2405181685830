import axios from 'axios';
import { changesSaved } from 'redux/settings';

// Actions
const FETCH_PRODUCT_VARIANTS = 'FETCH_PRODUCT_VARIANTS';
const FETCH_PRODUCT_VARIANTS_SUCCESS = 'FETCH_PRODUCT_VARIANTS_SUCCESS';
const FETCH_PRODUCT_VARIANTS_ERROR = 'FETCH_PRODUCT_VARIANTS_ERROR';

// Action Creators
export function fetchProductVariantsAggregate() {
  return async dispatch => {
    dispatch(fetchProductVariantsBegin());
    try {
      const response = await axios.get('/api/v1/product_variants/aggregate', {
        timeout: 5000
      });
      dispatch(fetchProductVariantsSuccess(response.data));
    } catch (e) {
      dispatch(fetchProductVariantsError(e));
    }
  }
}

export function saveProductVariant(id, data) {
  return async dispatch => {
    try {
      const response = await axios.put(
        `/api/v1/product_variants/${id}`,
        {
          product_variant: data
        }
      );
      dispatch(fetchProductVariantsSuccess(response.data.product_variant));
      dispatch(changesSaved());
    } catch (e) {
      dispatch(fetchProductVariantsError(e));
      dispatch(changesSaved(true));
    }
  }
}

export const fetchProductVariantsBegin = () => ({
  type: FETCH_PRODUCT_VARIANTS
});

export const fetchProductVariantsSuccess = (data) => ({
  type: FETCH_PRODUCT_VARIANTS_SUCCESS,
  data
});

export const fetchProductVariantsError = (error) => ({
  type: FETCH_PRODUCT_VARIANTS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_PRODUCT_VARIANTS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PRODUCT_VARIANTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_PRODUCT_VARIANTS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
