import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import ReviewListItem from "components/ReviewListItem";
import LoadingPageWrapper from "components/LoadingPageWrapper";
import fileChecksum from 'utils/fileChecksum';
import {
  Card,
  ResourceList,
  ResourceItem,
  Text,
  BlockStack,
  Divider,
  Page,
  PageActions
} from "@shopify/polaris";

export default function Confirm() {
  const location = useLocation();
  const { csvData, provider, files, questions } = location.state || {};
  const [previewData, setPreviewData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!csvData) {
      history.push('/imports');
    }
  }, [csvData, history]);

  useEffect(() => {
    if (csvData.data) {
      generatePreview(csvData.data.slice(0, 3));
    }
  }, [csvData, history]);

  // This useEffect function is used to map the questions to the answers in the previewData
  // we need this for preview since the types of questions are not part of the answer data
  // that the backend normalizes.
  useEffect(() => {
    if (!questions) return;
    if (!previewData.length) return;

    const traitAnswers = questions?.filter(q => q.value_type === 'rating');
    const answers = questions?.filter(q => q.value_type !== 'rating');

    for (const review of previewData) {
      for (const answer of review.survey_answers) {
        let name = answer.name.toLowerCase();

        let question = traitAnswers.find(q => q.name.toLowerCase() === name);
        if (!question) {
          question = answers.find(q => q.name.toLowerCase() === name);
        }

        if (question) {
          answer.meta = question.meta;
          answer.name = question.name;
          answer.value_type = question.value_type;
        }
      };
    };
  }, [questions, previewData]);

  const renderReview = (reviewData) => {
    return (
      <ResourceItem
        id={reviewData.id}
        accessibilityLabel={`Review preview`}
      >
        <ReviewListItem review={reviewData} customer={reviewData.customer} product={reviewData.product} showAnswers={true} />
      </ResourceItem>
    );
  };

  const generatePreview = async (rows) => {
    const previewData = await axios.post('/api/v1/review_import_previews', {
      provider,
      rows
    });

    setPreviewData(previewData.data.review_import_preview);
  }

  const transfer = async () => {
    try {
      const { checksum } = await fileChecksum(files[0]);
      const response = await axios.post('/api/active_storage/direct_uploads', {
        blob: {
          'filename': files[0].name,
          'content_type': files[0].type,
          'byte_size': files[0].size,
          'checksum': checksum
        }
      });

      await axios.put(response.data.direct_upload.url, files[0].slice(), {
        headers: response.data.direct_upload.headers,
        withCredentials: false
      });

      await axios.post('/api/v1/review_imports', {
        review_import: {
          csv: response.data.signed_id,
          provider
        }
      });
      history.push('/reviews');
    }
    catch (e) {
      // TODO: handle error
    }
  }

  return (
    <LoadingPageWrapper loading={!previewData.length}>
      <Page
        title={"Preview and transfer"}
        backAction={{ content: "Back", url: "/imports" }}
      >
        {previewData.length && (
          <Card roundedAbove="sm">
            <BlockStack gap="300">
              <Text variant="bodyMd" as="p">
                Here's how your first {previewData.length} review{previewData.length > 1 ? 's' : ''} will appear.
              </Text>
              <Divider />
              <ResourceList
                resourceName={{ singular: 'review', plural: 'reviews' }}
                items={previewData?.length ? previewData : []}
                renderItem={renderReview}
                selectable={false}
              />
            </BlockStack>
          </Card>
        )}
        <PageActions
          primaryAction={{
            content: "Transfer reviews",
            onAction: () => { transfer() }
          }}
          secondaryActions={[
            { content: "Cancel", url: "/reviews", destructive: true },
          ]}
        />
      </Page>
    </LoadingPageWrapper>
  );
}
