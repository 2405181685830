import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Initialize axios interceptor
import 'utils/axiosDefaults';

import store from 'redux/store';
import Root from 'Root';

// Import base styles
import 'styles/index.scss';
import '@shopify/polaris/build/esm/styles.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// Create root element
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <Root />
    </Elements>
  </Provider>
);
