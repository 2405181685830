import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchFlows } from 'redux/flows';
import {
  ButtonGroup,
  Button,
  LegacyCard,
  Page,
  LegacyStack,
  TextContainer,
  FooterHelp,
  Link,
  Badge,
  Text,
} from "@shopify/polaris";
import LoadingWrapper from 'components/LoadingWrapper';
import ToggleFlowStateModal from 'components/ToggleFlowStateModal';

import postPurchaseFlowIcon from 'assets/images/flows/postPurchaseFlowIcon.svg';
import campaignFlowIcon from 'assets/images/flows/campaignFlowIcon.svg';
import reviewActionFlowIcon from 'assets/images/flows/reviewActionFlowIcon.svg';
import MigrateEventsBanner from 'components/MigrateEventsBanner';

function Flows({ stores, flows, fetchFlows }) {
  const [requestFlow, setRequestFlow] = useState(null);
  const [incentiveFlow, setIncentiveFlow] = useState(null);
  const [reviewCreatedFlow, setReviewCreatedFlow] = useState(null);

  useEffect(() => {
    fetchFlows({ include: 'trigger' });
  }, [fetchFlows]);

  useEffect(() => {
    if (flows?.data) {
      const request = flows?.data?.find((f) => f.flow_type === 'request' && (f.event_topic?.action !== 'targeted-by-campaign' && f.event_topic?.action !== 'request-by-campaign'));
      const incentive = flows?.data?.find((f) => f.flow_type === 'notification' && f.event_topic?.subject === 'incentive');
      const reviewCreated = flows?.data?.find((f) => f.flow_type === 'notification' && f.event_topic?.subject === 'review' && f.event_topic?.action === 'submitted');

      setRequestFlow(request);

      if (incentive) {
        setIncentiveFlow(incentive);
      }

      if (reviewCreated) {
        setReviewCreatedFlow(reviewCreated);
      }
    }
  }, [flows?.data]);

  return (
    <Page
      title="Flows"
      subtitle="Send well-timed messages to your customers. Send communications with Junip, or connect a 3rd-party app."
    >
      <MigrateEventsBanner />
      <LegacyCard>
        <LegacyCard.Section>
          <Text variant="headingMd" as="h2">Automations</Text>
        </LegacyCard.Section>
        <MessageFlow
          flow={requestFlow}
          title="Post-purchase review request"
          description="Automatically ask customers for a review when they make a purchase."
          icon={postPurchaseFlowIcon}
          toggleState={() => setRequestFlow({ ...requestFlow, disabled: !requestFlow.disabled })}
        />
        {incentiveFlow && (
          <MessageFlow
            flow={incentiveFlow}
            title="Review incentive action"
            description="Send an incentive to customers who leave a review"
            icon={reviewActionFlowIcon}
            toggleState={() => setIncentiveFlow({ ...incentiveFlow, disabled: !incentiveFlow.disabled })}
          />
        )}
        {reviewCreatedFlow && (
          <MessageFlow
            flow={reviewCreatedFlow}
            title="Review submitted actions"
            description="Send incentives or take other actions after a review is submitted."
            icon={reviewActionFlowIcon}
            toggleState={() => setReviewCreatedFlow({ ...reviewCreatedFlow, disabled: !reviewCreatedFlow.disabled })}
          />
        )}
      </LegacyCard>
      <LegacyCard>
        <LegacyCard.Section>
          <Text variant="headingMd" as="h2">One-time campaigns</Text>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              <LegacyStack alignment="center">
                <img src={campaignFlowIcon} alt={"Campaign flow"} />
                <div>
                  <p className="mb-1">
                    <Text variant="bodyMd" as="span" fontWeight="medium">One-time campaign requests</Text>
                  </p>
                  <p>
                    <Text variant="bodyMd" as="span" tone="subdued">Send a review request to customers who made a purchase in the past.</Text>
                  </p>
                </div>
              </LegacyStack>
            </LegacyStack.Item>
            <ButtonGroup>
              <Button url="/flows/campaigns?tab=1" variant="plain">
                View history
              </Button>
              <Button url="/flows/campaigns">Run campaign</Button>
            </ButtonGroup>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <div className="divider my-6" />
      <LegacyCard sectioned subdued>
        <TextContainer>
          <p>Send messages through your preferred 3rd-party app.</p>
          <Button
            url="/integrations"
          >
            Visit integrations
          </Button>
        </TextContainer>
      </LegacyCard>
      <FooterHelp>
        <Link url="https://help.junip.co/en/articles/5902201-the-basics-of-flows" external>
          Welcome to flows! 👋
        </Link>
      </FooterHelp>
    </Page>
  );
}

function MessageFlow({ flow, title, description = null, icon, toggleState }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <LegacyCard.Section>
      <LoadingWrapper loading={!flow}>
        <LegacyStack alignment="center">
          <LegacyStack.Item fill>
            <LegacyStack alignment="center">
              <img src={icon} alt={title} />
              <div>
                <p className="mb-1">
                  <Text variant="bodyMd" as="span" fontWeight="medium">{title}</Text>
                  <span className="ml-2">
                    <Badge tone={flow?.disabled ? 'default' : 'success'}>{flow?.disabled ? 'Disabled' : 'Active'}</Badge>
                  </span>
                </p>
                {description && (
                  <p>
                    <Text variant="bodyMd" as="span" tone="subdued">
                      {description}
                    </Text>
                  </p>
                )}
              </div>
            </LegacyStack>
          </LegacyStack.Item>
          <ButtonGroup>
            <Button
              onClick={() => setShowModal(true)}
              variant={flow?.disabled ? 'primary' : 'plain'}
              tone={flow?.disabled ? 'success' : 'critical'}
            >
              {flow?.disabled ? 'Turn on' : 'Turn off'}
            </Button>
            <Button url={`/flows/${flow?.id}`}>Edit</Button>
          </ButtonGroup>
        </LegacyStack>
        <ToggleFlowStateModal open={showModal} close={() => setShowModal(false)} flow={flow} callback={toggleState} />
      </LoadingWrapper>
    </LegacyCard.Section>
  );
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  flows: state.flows,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFlows: (data) => dispatch(fetchFlows(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Flows);
