import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { fetchBillingSubscriptions } from 'redux/billingSubscriptions';
import {
  Banner,
  ProgressBar,
  TextContainer
} from '@shopify/polaris';
import UsageBanner from 'components/UsageBanner';

import 'styles/components/BillingBanner.scss';


const DEFAULT_TRIAL_LENGTH = 14;

function BillingBanner({ billingSubscriptions, fetchBillingSubscriptions, isHomePage, skipFetch, organizations}) {
  const [bannerActive, setBannerActive] = useState(false);
  const [status, setStatus] = useState('info');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState(null);
  const [progress, setProgress] = useState(0);
  const [activeBillingSubscription, setActiveBillingSubscription] = useState(null);

  const organization = organizations.data?.find(o => o.scoped === true) || {};

  useEffect(() => {
    if (!skipFetch) {
      if (!billingSubscriptions.data) {
        fetchBillingSubscriptions();
      }
    }
  }, [fetchBillingSubscriptions, billingSubscriptions.data, skipFetch]);

  useEffect(() => {
    if (billingSubscriptions.data) {
      const activeBillingSubscriptions = billingSubscriptions.data.filter(b => b.active === true);
      if (activeBillingSubscriptions.length > 0) {
        setActiveBillingSubscription(activeBillingSubscriptions[0]);
      } else {
        setBannerActive(false);
      }
    }
  }, [billingSubscriptions]);

  useEffect(() => {
    if (activeBillingSubscription) {
      const { auto_renew, credit_available, credit_issued, credit_used, provider, trial_end_date } = activeBillingSubscription;
      // Auto-renewing subscriptions provided by Junip are 'free' subscriptions.
      if (isHomePage && (provider !== 'junip' || auto_renew)) {
        setBannerActive(false);
      } else if (credit_available || trial_end_date) {
        const bannerType = credit_issued ? 'credit' : 'trial';
        let currentProgress = 0;
        if (bannerType === 'credit') {
          currentProgress = (credit_used / credit_issued) * 100;
          setProgress(currentProgress);
          setBannerActive(true);
          setTitle('Free orders remaining');
          setBody(
            <>
              <p>You've used {activeBillingSubscription.credit_used} of the {activeBillingSubscription.credit_issued} free orders on your account.</p>
              { isHomePage &&
                <p>To continue sending review requests after you've used up your order credits, set up a billing plan.</p>
              }
            </>
          );
        } else {
          const trialEnd = moment(trial_end_date);
          const now = moment.utc();
          const diff = trialEnd.diff(now, 'days');
          setTitle('Free trial time remaining');
          setBody(
            <>
              <p>You have {diff >= 0 ? diff : 0} {diff === 1 ? 'day' : 'days'} left in your {DEFAULT_TRIAL_LENGTH} day free trial.</p>
              { isHomePage &&
                <p>To continue sending review requests after your free trial has ended, set up a billing plan.</p>
              }
            </>
          );
          if (diff > 0) {
            currentProgress = ((DEFAULT_TRIAL_LENGTH - diff) / DEFAULT_TRIAL_LENGTH) * 100;
          } else {
            setTitle('Free trial ended');
            setBody(
              <>
                <p>You have {diff >= 0 ? diff : 0} {diff === 1 ? 'day' : 'days'} left in your {DEFAULT_TRIAL_LENGTH} day free trial.</p>
                { isHomePage &&
                  <p>To continue sending review requests, set up a billing plan.</p>
                }
              </>
            );
            currentProgress = 100;
          }
          setProgress(currentProgress);
          setBannerActive(true);
        }
        if (provider !== bannerType && currentProgress >= 100) {
          setBannerActive(false);
          return;
        }
        if (provider === 'junip' && !auto_renew) {
          if (currentProgress > 50) {
            setStatus('warning');
          }
        }
      }
    }
  }, [activeBillingSubscription, isHomePage]);

  let bannerAction = isHomePage ? {
    content: 'Select a billing plan',
    url: '/settings/billing',
    onAction: () => trackEvent('Billing banner - Select plan')
  } : null;

  if (isHomePage && activeBillingSubscription?.usage_limit) {
    return <UsageBanner billingSubscription={activeBillingSubscription} />
  }

  return <>
  { bannerActive && organization?.billable &&
    <div className="billing-banner-container mb-4">
      <Banner
        title={title}
        tone={status}
        onDismiss={() => setBannerActive(false)}
        action={bannerAction}
      >
        <TextContainer spacing="tight">
          {body}
        </TextContainer>
        { progress > 0 &&
          <div className="mt-4">
            <ProgressBar size="small" progress={progress} />
          </div>
        }
      </Banner>
    </div>
  }
  </>;
}

const mapStateToProps = (state) => ({
  organizations: state.organizations,
  billingSubscriptions: state.billingSubscriptions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBillingSubscriptions: () => dispatch(fetchBillingSubscriptions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingBanner);
