import { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import useLoadAnalyticsDb from 'utils/useLoadAnalyticsDb';
import { calcPercentChange, whereFormatter } from 'utils/AnalyticsUtils';
// eslint-enable import/no-webpack-loader-syntax

export default function useAnalytics({
  selectedDates,
  activeTab,
  comparison,
  setLoading,
  reviewType,
  productFilter,
}) {

  const { db, initialzedDb, previewMode } = useLoadAnalyticsDb(setLoading);

  // all time
  const [subRate, setSubRate] = useState(0);
  const [submissionRateTrend, setSubmissionRateTrend] = useState(0);
  const [allTimeSubmissionRate, setAllTimeSubmissionRate] = useState(0);
  const [orders, setOrders] = useState(0);
  const [ordersTrend, setOrdersTrend] = useState(0);
  const [allTimeOrders, setAllTimeOrders] = useState(0);

  const [avgRating, setAvgRating] = useState(0);
  const [mediaReviews, setMediaReviews] = useState(0);
  const [reviewSubs, setReviewSubs] = useState(0);

  // selected range
  const [pdReviewSubs, setPdReviewSubs] = useState(0);
  const [pdReviewSubTrend, setPdReviewSubTrend] = useState(0);
  const [pdMediaReviews, setPdMediaReviews] = useState(0);
  const [pdMediaReviewTrend, setPdMediaReviewTrend] = useState(0);
  const [pdAvgRating, setPdAvgRating] = useState(0);
  const [pdAvgRatingTrend, setPdAvgRatingTrend] = useState(0);

  // formatted chart data
  const [areaChartData, setAreaChartData] = useState(null);
  const [areaChartTooltipData, setAreaChartTooltipData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [mostReviewedProducts, setMostReviewedProducts] = useState(null);
  const [avgRatingChartData, setAvgRatingChartData] = useState(null);

  const period = useMemo(() => {
    return moment(selectedDates.end).utc().diff(moment(selectedDates.start).utc(), 'day') + 1;
  }, [selectedDates]);

  const showMonthly = period > 90;

  const CURRENT_PERIOD = `date >= '${moment(selectedDates.start).utc().format('YYYY-MM-DD')}' AND date <= '${moment(selectedDates.end).utc().format('YYYY-MM-DD')}'`;
  const PREVIOUS_PERIOD = `date >= '${moment(selectedDates.start).utc().subtract(period, 'day').format('YYYY-MM-DD')}' AND date <= '${moment(selectedDates.end).utc().subtract(period, 'day').format('YYYY-MM-DD')}'`;

  const CURRENT_PERIOD_CREATED_AT = `created_at >= '${moment(selectedDates.start).utc().format('YYYY-MM-DD')}' AND created_at <= '${moment(selectedDates.end).utc().format('YYYY-MM-DD')}'`;
  const PREVIOUS_PERIOD_CREATED_AT = `created_at >= '${moment(selectedDates.start).utc().subtract(period, 'day').format('YYYY-MM-DD')}' AND created_at <= '${moment(selectedDates.end).utc().subtract(period, 'day').format('YYYY-MM-DD')}'`;

  const BACKFILL_DATE = new Date('2022-08-31');
  const BACKFILL_PERIOD = `created_at >= '${moment(BACKFILL_DATE).utc().subtract(period, 'day').format('YYYY-MM-DD')}'`;

  const CURRENT_PERIOD_USING_BACKFILL_DATE_QUERY_STRING = `created_at >= '${moment(BACKFILL_DATE).utc().format('YYYY-MM-DD')}' AND created_at <= '${moment(selectedDates.end).utc().format('YYYY-MM-DD')}'`;
  const PREVIOUS_PERIOD_USING_BACKFILL_DATE_QUERY_STRING = `created_at >= '${moment(BACKFILL_DATE).utc().format('YYYY-MM-DD')}' AND created_at <= '${moment(selectedDates.end).utc().subtract(period, 'day')}'`;
  // if the selected end date is before the backfill date, we won't have any data to show for that period
  // if the selected start date is before the backfill date, we want to shift the query to use the backfill date as the start date
  const SUBMISSION_RATE_CURRENT_PERIOD = selectedDates.end < BACKFILL_DATE
    ? null
    : selectedDates.start < BACKFILL_DATE
      ? CURRENT_PERIOD_USING_BACKFILL_DATE_QUERY_STRING
      : CURRENT_PERIOD_CREATED_AT;
  const SUBMISSION_RATE_PREVIOUS_PERIOD = moment(selectedDates.end).utc().subtract(period, 'day') < BACKFILL_DATE
    ? null
    : moment(selectedDates.start).utc().subtract(period, 'day') < moment(BACKFILL_DATE).utc()
      ? PREVIOUS_PERIOD_USING_BACKFILL_DATE_QUERY_STRING
      : PREVIOUS_PERIOD_CREATED_AT;

  const parsedReviewType = reviewType === 'product' ? 'ProductReview' : reviewType === 'store' ? 'StoreReview' : null;
  const selectedProductId = productFilter?.id || null;

  // query building conditions
  const productFilterCondition = selectedProductId ? `product_id = ${selectedProductId}` : null;
  const reviewTypeFilter = parsedReviewType ? `type = '${parsedReviewType}'` : null;
  const hasMediaFilter = 'has_media = TRUE';
  const hasProductIdFilter = 'product_id NOT NULL';
  const isNotImported = 'imported = FALSE';

  const reviewsAllTime = `SELECT COUNT(created_at) as submitted FROM analytics_reviews${whereFormatter(reviewTypeFilter, productFilterCondition)};`;
  const reviewsCurrent = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date FROM analytics_reviews${whereFormatter(CURRENT_PERIOD, reviewTypeFilter, productFilterCondition)};`;
  const reviewsPrevious = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date FROM analytics_reviews${whereFormatter(PREVIOUS_PERIOD, reviewTypeFilter, productFilterCondition)};`;
  const reviewsByDayCurrent = `SELECT COUNT(created_at) as review_subs, DATE(created_at) as date FROM analytics_reviews${whereFormatter(CURRENT_PERIOD, reviewTypeFilter, productFilterCondition)} GROUP BY date;`;
  const reviewsByMonthCurrent = `SELECT COUNT(created_at) as review_subs, strftime("%m-%Y", created_at) as 'month-year' FROM analytics_reviews${whereFormatter(CURRENT_PERIOD_CREATED_AT, reviewTypeFilter, productFilterCondition)} GROUP BY strftime("%m-%Y", created_at);`;
  const ratingSumAllTime = `SELECT SUM(rating) as rating_sum FROM analytics_reviews${whereFormatter(reviewTypeFilter, productFilterCondition)};`;
  const ratingSumCurrent = `SELECT SUM(rating) as rating_sum, DATE(created_at) as date FROM analytics_reviews${whereFormatter(CURRENT_PERIOD, reviewTypeFilter, productFilterCondition)};`;
  const ratingSumPrevious = `SELECT SUM(rating) as rating_sum, DATE(created_at) as date FROM analytics_reviews${whereFormatter(PREVIOUS_PERIOD, reviewTypeFilter, productFilterCondition)};`;
  const mediaReviewsAllTime = `SELECT COUNT(created_at) as submitted, has_media FROM analytics_reviews${whereFormatter(hasMediaFilter, reviewTypeFilter, productFilterCondition)};`;
  const mediaReviewsCurrent = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date, has_media FROM analytics_reviews${whereFormatter(CURRENT_PERIOD, hasMediaFilter, reviewTypeFilter, productFilterCondition)};`;
  const mediaReviewsPrevious = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date, has_media FROM analytics_reviews${whereFormatter(PREVIOUS_PERIOD, hasMediaFilter, reviewTypeFilter, productFilterCondition)};`;

  const reviewsByRatingByDayCurrent = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date, rating FROM analytics_reviews${whereFormatter(CURRENT_PERIOD, reviewTypeFilter, productFilterCondition)} GROUP BY date, rating;`;
  const reviewsByRatingByMonthCurrent = `SELECT COUNT(created_at) as submitted, strftime("%m-%Y", created_at) as 'month-year', rating FROM analytics_reviews${whereFormatter(CURRENT_PERIOD_CREATED_AT, reviewTypeFilter, productFilterCondition)} GROUP BY strftime("%m-%Y", created_at), rating;`
  const reviewsByRatingCurrent = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date, rating FROM analytics_reviews${whereFormatter(CURRENT_PERIOD, reviewTypeFilter, productFilterCondition)} GROUP BY rating;`;
  const reviewsByRatingPrevious = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date, rating FROM analytics_reviews${whereFormatter(PREVIOUS_PERIOD, reviewTypeFilter, productFilterCondition)} GROUP BY rating;`;

  const productsByReviewsCurrent = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date, product_id, CAST(SUM(rating) as float) / COUNT(created_at) as rating_avg FROM analytics_reviews
    ${whereFormatter(CURRENT_PERIOD, hasProductIdFilter)}
    GROUP BY product_id ORDER BY submitted DESC LIMIT 5;`;

  const productsByReviewsAllTime = `SELECT COUNT(created_at) as submitted, DATE(created_at) as date, product_id, CAST(SUM(rating) as float) / COUNT(created_at) as rating_avg FROM analytics_reviews
    ${whereFormatter(hasProductIdFilter)}
    GROUP BY product_id ORDER BY submitted DESC LIMIT 5;`;

  const avgRatingByDayCurrent = `SELECT CAST(SUM(rating) as float) / COUNT(created_at) as rating_avg, DATE(created_at) as date FROM analytics_reviews
    ${whereFormatter(CURRENT_PERIOD, reviewTypeFilter, productFilterCondition)}
    GROUP BY date;`;
  const avgRatingByDayPrevious = `SELECT CAST(SUM(rating) as float) / COUNT(created_at) as rating_avg, DATE(created_at) as date FROM analytics_reviews
    ${whereFormatter(PREVIOUS_PERIOD, reviewTypeFilter, productFilterCondition)}
    GROUP BY date;`;

  const avgRatingByMonthCurrent = `SELECT CAST(SUM(rating) as float) / COUNT(created_at) as rating_avg, strftime("%m-%Y", created_at) as 'month-year' FROM analytics_reviews
    ${whereFormatter(CURRENT_PERIOD_CREATED_AT, reviewTypeFilter, productFilterCondition)}
    GROUP BY strftime("%m-%Y", created_at)
    ORDER BY created_at;`;
  const avgRatingByMonthPrevious = `SELECT CAST(SUM(rating) as float) / COUNT(created_at) as rating_avg, strftime("%m-%Y", created_at) as 'month-year' FROM analytics_reviews
    ${whereFormatter(PREVIOUS_PERIOD_CREATED_AT, reviewTypeFilter, productFilterCondition)}
    GROUP BY strftime("%m-%Y", created_at)
    ORDER BY created_at;`;

  const formatNumber = useCallback((number) => {
    return new Intl.NumberFormat().format(number || 0);
  }, []);

  const formatAreaChartData = useCallback(queryResult => {
    const [timeScale, timeFormat] = showMonthly ? ['month', 'MM-YYYY'] : ['day', 'MMM D'];

    let fillerData = [];

    const diff = Math.round(moment(selectedDates.end).utc().diff(moment(selectedDates.start).utc(), timeScale, true));
    for (let j = 0; j <= diff; j++) {
      fillerData.push({
        key: moment(selectedDates.start).utc().add(j, timeScale).format(timeFormat),
        value: 0
      });
    };

    let chartData = [];
    for (let i = 5; i >= 1; i--) {
      chartData.push({
        name: `${i} ${i === 1 ? 'star' : 'stars'}`,
        data: JSON.parse(JSON.stringify(fillerData))
      });
    };

    queryResult?.forEach((row) => {
      const date = showMonthly ? row[1] : moment(row[1]).utc().format(timeFormat);
      const entry = chartData[5 - row[2]].data.find((data) => data.key === date);
      if (entry) entry.value = row[0];
    });

    return chartData;
  }, [selectedDates, showMonthly]);

  const formatAreaChartTooltipData = useCallback((queryResult) => {
    const [timeScale, timeFormat] = showMonthly ? ['month', 'MM-YYYY'] : ['day', 'MMM D'];
    let tooltipData = [];

    const diff = Math.round(moment(selectedDates.end).utc().diff(moment(selectedDates.start).utc(), timeScale, true));
    for (let j = 0; j <= diff; j++) {
      let value = 0;
      const queryResultRow = queryResult?.find((row) => moment(row[1]).utc().format(timeFormat) === moment(selectedDates.start).utc().add(j, timeScale).format(timeFormat));

      if (queryResultRow) {
        value = queryResultRow[0];
      }

      tooltipData.push({
        key: moment(selectedDates.start).utc().add(j, timeScale).format(timeFormat),
        value: value
      });
    };

    return tooltipData;
  }, [selectedDates, showMonthly]);

  const formatBarChartData = useCallback((queryResult, total, prevQueryResult, prevTotal) => {
    if (!queryResult) return [];

    let chartData = [{
      name: 'Star ratings',
      data: [],
      color: [
        {
          color: '#75E4B6',
          offset: 0
        },
        {
          color: '#50DDDD',
          offset: 100
        }
      ]
    }];

    if (comparison === 'previous') {
      chartData[0].metadata = { trends: {} };
    }

    for (let i = 5; i > 0; i--) {
      chartData[0].data.push({
        key: `${i} ${i === 1 ? 'star' : 'stars'}`,
        value: 0
      });
    };

    queryResult?.forEach((row) => {
      const percent = (row[0] / total) * 100;
      const prev = prevQueryResult?.find((prevRow) => prevRow[2] === row[2]);
      const prevPercent = prev ? (prev[0] / prevTotal) * 100 : 0;
      const change = calcPercentChange(percent, prevPercent);

      chartData[0].data[5 - row[2]].value = percent;

      if (change !== 0 && comparison === 'previous') {
        chartData[0].metadata.trends[5 - row[2]] = {
          value: `${Math.abs(change.toPrecision(3))}%`,
          direction: change > 0 ? 'upward' : 'downward',
          trend: change > 0 ? 'positive' : 'negative'
        };
      }
    });

    return chartData;
  }, [comparison]);

  const formatDailyAvgRatingData = useCallback((queryResult, prevQueryResult) => {
    const [timeScale, timeFormat] = showMonthly ? ['month', 'MM-YYYY'] : ['day', 'MMM D, YYYY'];

    let chartData = [
      {
        name: `${moment(selectedDates?.start).format('MMM D, YYYY')} - ${moment(selectedDates?.end).format('MMM D, YYYY')}`,
        data: []
      }
    ];

    if (comparison === 'previous') {
      chartData.push({
        name: 'Previous period',
        data: [],
        isComparison: true
      });
    }

    const diff = Math.round(moment(selectedDates.end).utc().diff(moment(selectedDates.start).utc(), timeScale, true));
    for (let j = 0; j <= diff; j++) {
      chartData[0].data.push({
        key: moment(selectedDates.start).utc().add(j, timeScale).format(timeFormat),
        value: 0
      });
      if (comparison === 'previous') {
        const subtractAmount = showMonthly ? diff : diff + 1;
        chartData[1].data.push({
          key: moment(selectedDates.start).utc().subtract(subtractAmount, timeScale).add(j, timeScale).format(timeFormat),
          value: 0
        });
      }
    };

    queryResult?.forEach((row) => {
      const date = showMonthly ? row[1] : moment(row[1]).utc().format('MMM D, YYYY');
      const entry = chartData[0].data.find((data) => data.key === date);
      if (entry) entry.value = row[0];
    });

    if (comparison === 'previous') {
      prevQueryResult?.forEach((row) => {
        const date = showMonthly ? row[1] : moment(row[1]).utc().format('MMM D, YYYY');
        const entry = chartData[1].data.find((data) => data.key === date);
        if (entry) entry.value = row[0];
      });
    }

    return chartData;
  }, [selectedDates, comparison, showMonthly]);

  const fetchMostReviewedProductsData = useCallback(async (db, queryResult) => {
    if (!queryResult) {
      setMostReviewedProducts([]);
      return;
    }

    let products = [];
    const productNameQuery = 'SELECT id, title, deleted_at FROM analytics_products WHERE id IN (' + queryResult.map((product) => product[2]).join() + ')';
    const productNameResult = await db.exec(productNameQuery)[0]?.values;

    products = queryResult.map((product, index) => {
      const [id, title, deletedAt] = productNameResult.find((row) => row[0] === product[2]);
      return {
        id: id,
        name: previewMode ? `Product ${index + 1}` : title,
        deletedAt: deletedAt,
        reviews: product[0],
        ratingAvg: product[3],
      };
    });
    setMostReviewedProducts(products);
  }, [previewMode]);

  useEffect(() => {
    if (db) {
      // MEDIA REVIEW CARD
      const pdMediaReviews = db.exec(mediaReviewsCurrent)[0]?.values[0][0];
      setPdMediaReviews(formatNumber(pdMediaReviews));
      const prevMediaReviews = db.exec(mediaReviewsPrevious)[0]?.values[0][0];
      setPdMediaReviewTrend(calcPercentChange(pdMediaReviews, prevMediaReviews));

      setMediaReviews(formatNumber(db.exec(mediaReviewsAllTime)[0]?.values[0][0]));

      // AREA CHART
      const areaChartQuery = showMonthly ? reviewsByRatingByMonthCurrent : reviewsByRatingByDayCurrent;
      const tooltipQuery = showMonthly ? reviewsByMonthCurrent : reviewsByDayCurrent;
      setAreaChartData(formatAreaChartData(db.exec(areaChartQuery)[0]?.values));
      setAreaChartTooltipData(formatAreaChartTooltipData(db.exec(tooltipQuery)[0]?.values));

      const pdReviewSubs = db.exec(reviewsCurrent)[0]?.values[0][0];
      setPdReviewSubs(formatNumber(pdReviewSubs));
      const prevReviewSubs = db.exec(reviewsPrevious)[0]?.values[0][0];
      setPdReviewSubTrend(calcPercentChange(pdReviewSubs, prevReviewSubs));

      const allTimeReviews = db.exec(reviewsAllTime)[0]?.values[0][0];
      setReviewSubs(formatNumber(allTimeReviews));

      //AVG RATING CARD
      const currentRatingSum = db.exec(ratingSumCurrent)[0]?.values[0][0];
      const pdRatingAvg = currentRatingSum / pdReviewSubs;
      setPdAvgRating(currentRatingSum ? pdRatingAvg.toPrecision(2) : null);

      const prevRatingAvg = (db.exec(ratingSumPrevious)[0]?.values[0][0]) / prevReviewSubs;
      setPdAvgRatingTrend(calcPercentChange(pdRatingAvg, prevRatingAvg));

      const allTimeRatingSum = db.exec(ratingSumAllTime)[0]?.values[0][0];
      const allTimeRatingAvg = db.exec(ratingSumAllTime)[0]?.values[0][0] / allTimeReviews;
      setAvgRating(allTimeRatingSum ? allTimeRatingAvg.toPrecision(2) : null);

      // STAR RATING BREAKDOWN BAR CHART
      setBarChartData(formatBarChartData(db.exec(reviewsByRatingCurrent)[0]?.values, pdReviewSubs, db.exec(reviewsByRatingPrevious)[0]?.values, prevReviewSubs));

      // AVG RATING LINE CHART
      const avgRatingCurrentQuery = showMonthly ? avgRatingByMonthCurrent : avgRatingByDayCurrent;
      const avgRatingPreviousQuery = showMonthly ? avgRatingByMonthPrevious : avgRatingByDayPrevious;
      setAvgRatingChartData(formatDailyAvgRatingData(db.exec(avgRatingCurrentQuery)[0]?.values, db.exec(avgRatingPreviousQuery)[0]?.values));

      // ORDERS REQUESTED
      const flowActionIdQuery = `SELECT flow_action_id from analytics_flow_action_event_topic_maps WHERE event_topic_string='review/request'`;
      const flowActionId = db.exec(flowActionIdQuery)[0]?.values?.flat();
      const FLOW_ACTION = `flow_action_id IN (${flowActionId.join()})`;

      const ordersRequestedCurrentQuery = `SELECT SUM(completed) FROM analytics_daily_flow_action_stats ${whereFormatter(FLOW_ACTION, CURRENT_PERIOD)};`;
      const ordersRequestedPreviousQuery = `SELECT SUM(completed) FROM analytics_daily_flow_action_stats ${whereFormatter(FLOW_ACTION, PREVIOUS_PERIOD)};`;
      const ordersAllTimeQuery = `SELECT SUM(completed) FROM analytics_daily_flow_action_stats ${whereFormatter(FLOW_ACTION)};`;

      const currOrders = db.exec(ordersRequestedCurrentQuery)[0]?.values[0][0];
      const prevOrders = db.exec(ordersRequestedPreviousQuery)[0]?.values[0][0];
      const allTimeOrders = db.exec(ordersAllTimeQuery)[0]?.values[0][0];

      setOrders(formatNumber(currOrders));
      setOrdersTrend(calcPercentChange(currOrders, prevOrders));
      setAllTimeOrders(formatNumber(allTimeOrders));

      // SUBMISSION RATE
      let submissionRateCurrentReviews = 0;
      let submissionRatePrevReviews = 0;
      if (SUBMISSION_RATE_CURRENT_PERIOD) {
        const submissionRateCurrentReviewsQuery = `SELECT COUNT(created_at) as submitted FROM analytics_reviews${whereFormatter(SUBMISSION_RATE_CURRENT_PERIOD, reviewTypeFilter, productFilterCondition, isNotImported)};`;
        submissionRateCurrentReviews = db.exec(submissionRateCurrentReviewsQuery)[0]?.values[0][0];
      }
      if (SUBMISSION_RATE_PREVIOUS_PERIOD) {
        const submissionRatePrevReviewsQuery = `SELECT COUNT(created_at) as submitted FROM analytics_reviews${whereFormatter(SUBMISSION_RATE_PREVIOUS_PERIOD, reviewTypeFilter, productFilterCondition, isNotImported)};`;
        submissionRatePrevReviews = db.exec(submissionRatePrevReviewsQuery)[0]?.values[0][0];
      }

      const submissionRateAllTimeReviewsQuery = `SELECT COUNT(created_at) as submitted FROM analytics_reviews${whereFormatter(BACKFILL_PERIOD, reviewTypeFilter, productFilterCondition, isNotImported)};`;
      const submissionRateAllTimeReviews = db.exec(submissionRateAllTimeReviewsQuery)[0]?.values[0][0];

      const currentSubmissionRate = currOrders ? (submissionRateCurrentReviews / currOrders * 100) : 0;
      const prevSubmissionRate = prevOrders ? (submissionRatePrevReviews / prevOrders * 100) : 0;
      const submissionRateTrend = prevSubmissionRate > 0 ? (currentSubmissionRate - prevSubmissionRate) : 0;
      const allTimeSubmissionRate = allTimeOrders ? submissionRateAllTimeReviews / allTimeOrders : 0;

      setSubRate(formatNumber(+currentSubmissionRate.toFixed(2)));
      setSubmissionRateTrend(+submissionRateTrend.toFixed(2));
      setAllTimeSubmissionRate(formatNumber(+(allTimeSubmissionRate * 100).toFixed(2)));

      setLoading(false);
    } else if (initialzedDb) {
      setLoading(false);
    }
  },
    [db,
      initialzedDb,
      formatAreaChartData,
      formatBarChartData,
      formatDailyAvgRatingData,
      formatAreaChartTooltipData,
      fetchMostReviewedProductsData,
      reviewsByRatingByDayCurrent,
      reviewsByRatingCurrent,
      reviewsByRatingPrevious,
      reviewsCurrent,
      reviewsPrevious,
      reviewsAllTime,
      mediaReviewsAllTime,
      mediaReviewsCurrent,
      mediaReviewsPrevious,
      ratingSumCurrent,
      ratingSumPrevious,
      ratingSumAllTime,
      productsByReviewsCurrent,
      avgRatingByDayCurrent,
      avgRatingByDayPrevious,
      reviewsByDayCurrent,
      selectedDates,
      setLoading,
      reviewType,
      productFilter,
      orders,
      ordersTrend,
      formatNumber,
      CURRENT_PERIOD,
      PREVIOUS_PERIOD,
      SUBMISSION_RATE_CURRENT_PERIOD,
      SUBMISSION_RATE_PREVIOUS_PERIOD,
      BACKFILL_PERIOD,
      reviewTypeFilter,
      productFilterCondition,
      avgRatingByMonthCurrent,
      avgRatingByMonthPrevious,
      showMonthly,
      reviewsByMonthCurrent,
      reviewsByRatingByMonthCurrent,
    ]);

  useEffect(() => {
    if (db) {
      // MOST REVIEWED PRODUCTS LIST
      if (activeTab === 0) {
        fetchMostReviewedProductsData(db, db.exec(productsByReviewsCurrent)[0]?.values);
      } else {
        fetchMostReviewedProductsData(db, db.exec(productsByReviewsAllTime)[0]?.values);
      }
    }
  },
    [
      db,
      fetchMostReviewedProductsData,
      productsByReviewsCurrent,
      productsByReviewsAllTime,
      activeTab
    ]);

  return {
    db,
    areaChartData,
    areaChartTooltipData,
    barChartData,
    pdReviewSubs,
    pdReviewSubTrend,
    reviewSubs,
    pdMediaReviews,
    pdMediaReviewTrend,
    mediaReviews,
    pdAvgRating,
    pdAvgRatingTrend,
    avgRating,
    mostReviewedProducts,
    avgRatingChartData,
    orders,
    ordersTrend,
    allTimeOrders,
    subRate,
    submissionRateTrend,
    allTimeSubmissionRate,
  };
}
