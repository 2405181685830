import React from 'react';
import { LegacyCard } from '@shopify/polaris';

export default function Failure() {
  return (
    <LegacyCard
      sectioned
      title="Oauth error"
      primaryFooterAction={{
        content: 'Contact support',
        url: 'mailto:support@juniphq.com',
        external: true
      }}
      footerActionAlignment="left">
      <p>An error occurred will authenticating with Junip. Please try again or contact support@juniphq.com for further assistance</p>
    </LegacyCard>
  );
}
