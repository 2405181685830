import React from 'react';
import { Page, LegacyCard, Button, Thumbnail, Text } from "@shopify/polaris";

import text from 'assets/images/customQuestions/text.svg';
import radio from 'assets/images/customQuestions/radio.svg';
import trait from 'assets/images/customQuestions/trait.svg';

import styles from 'styles/routes/forms/questions/New.module.scss';

export default function NewQuestion() {
  const questionTypes = [
    {
      title: 'Linear Scale',
      description: 'Customer rates product attributes on a custom 5-point scale',
      icon: trait,
      url: '/forms/questions/create?type=rating'
    },
    {
      title: 'Multiple Choice',
      description: 'Customers can select one response from a list of options',
      icon: radio,
      url: '/forms/questions/create?type=radio'
    },
    {
      title: 'Written Answer',
      description: "The customer writes their own answer",
      icon: text,
      url: '/forms/questions/create?type=text'
    },
  ];

  return (
    <Page
      title="New custom question"
      subtitle="Start by selecting a question type"
      backAction={{
        content: 'All custom questions',
        url: '/forms/questions'
      }}
    >
      <div className={styles.grid}>
        {questionTypes.map(({ title, description, icon, url }) => (
          <div className={styles.gridItem} key={title}>
            <LegacyCard sectioned>
              <div className={styles.gridItemInner}>
                <div className="pb-3">
                  <Thumbnail source={icon} />
                </div>
                <div className="pb-3">
                  <Text variant="headingMd" as="h2">{title}</Text>
                </div>
                <div className="pb-3">
                  <Text variant="bodyMd" as="span" tone="subdued">{description}</Text>
                </div>
                <Button url={url} variant="primary">Create</Button>
              </div>
            </LegacyCard>
          </div>
        ))}
      </div>
    </Page>
  );
}
