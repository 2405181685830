import { useState } from 'react';
import axios from 'axios';
import useInterval from 'utils/useInterval';

const DEFAULT_POLLING_DELAY = 60 * 1000; // 1 minute

export default function CheckVersion() {
  const [currentVersion, setCurrentVersion] = useState(null);

  useInterval(() => {
    async function fetchVersion() {
      const response = await axios.get(`/meta.json?v=${Date.now()}`);
      const versionString = response?.data?.version;
      const version = versionString ? parseInt(versionString.replace(/\./g, ''), 10) : 0;

      if (!currentVersion) {
        setCurrentVersion(version);
      } else {
        if (currentVersion < version) {
          window.location.reload();
        }
      }
    }

    fetchVersion();
  }, DEFAULT_POLLING_DELAY);

  return ( 
    null
  );
};
