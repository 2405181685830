import React from 'react';
import { connect } from 'react-redux';

import {
  Loading
} from '@shopify/polaris';

const LoadingBar = (props) => {
  if (
    props.user.loading ||
    props.reviews.loading ||
    props.settings.saving ||
    props.messages.loading
  ) {
    return (
      <Loading />
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  user: state.user,
  reviews: state.reviews,
  settings: state.settings,
  messages: state.messages
});

export default connect(
  mapStateToProps
)(LoadingBar);
