import React, { useState, useCallback } from 'react';
import { trackEvent } from 'utils/Mixpanel';
import { Button, ButtonGroup, Page, LegacyCard, LegacyStack, ChoiceList, Text } from "@shopify/polaris";
import JunipLogo from 'components/JunipLogo';
import SkipOnboarding from 'components/SkipOnboarding';
import SolicitationCampaignModal from 'components/SolicitationCampaignModal';
import ImportReviewModal from 'components/ImportReviewModal';

import reviews from 'assets/images/onboarding/reviews.png';


export default function Campaign({ history, progress, stores }) {
  const NEXT_STEP = stores?.length > 1 ? '/onboarding/thanks' : '/onboarding/select_plan';
  const [selected, setSelected] = useState(['campaign']);
  const [showModal, setShowModal] = useState(false);
  const [showImportReviewModal, setShowImportReviewModal] = useState(false);

  const handleChoiceListChange = useCallback((value) => setSelected(value), []);

  const renderCampaignChildren = useCallback(
    (isSelected) =>
      isSelected ? (
        <div className="pt-4 pb-6">
          <LegacyStack vertical>
            <p>
              <Text variant="bodyMd" as="span" tone="subdued">Run a campaign requesting reviews from customers that have previously made a purchase! The email that will be sent will match the one shown in the previous step.</Text>
            </p>
            <Button

              size="large"
              onClick={() => { setShowModal(true); trackEvent('Onboarding campaign - Create Campaign') }}
              variant="primary">
              Create Campaign
            </Button>
          </LegacyStack>
        </div>
      ) : ( <div className="pb-4"></div> ),
    [],
  );

  const renderImportChildren = useCallback(
    (isSelected) =>
      isSelected && (
        <div className="pt-4">
          <LegacyStack vertical>
            <p>
              <Text variant="bodyMd" as="span" tone="subdued">Great! You can migrate reviews from other platforms by importing a simple CSV.</Text>
            </p>
            <Button

              size="large"
              onClick={() => { setShowImportReviewModal(true); trackEvent('Onboarding campaign - Import CSV') }}
              variant="primary">
              Import CSV
            </Button>
          </LegacyStack>
        </div>
      ),
    [],
  );

  const nextStep = () => {
    trackEvent('Onboarding campaign - Action created');
    history.push(NEXT_STEP);
  };

  return (
    <Page>
      <LegacyCard flush>
        <div className="onboarding-step o-divider">
          <div className="o-step o-step-subdued o-divider-right">
            <LegacyStack vertical spacing="extraLoose">
              <JunipLogo height={'24px'} />
              <Text variant="heading2xl" as="p">Collect reviews from past customers</Text>
              <ChoiceList
                choices={[
                  { label: 'I’m collecting my first reviews with Junip', value: 'campaign', renderChildren: renderCampaignChildren },
                  { label: 'I’ve already collected reviews on another platform', value: 'import', renderChildren: renderImportChildren },
                ]}
                selected={selected}
                onChange={handleChoiceListChange}
              />
            </LegacyStack>
          </div>
          <div className="o-step o-step-highlight">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <img src={reviews} alt="Junip reviews" className="w-100" />
            </div>
          </div>
        </div>
        <LegacyCard.Section subdued>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              {progress}
            </LegacyStack.Item>
            <LegacyStack.Item>
              <ButtonGroup gap="loose">
                <Button

                  url={NEXT_STEP}
                  onClick={() => trackEvent('Onboarding campaign - Skip')}
                  variant="plain">
                  Skip
                </Button>
                <Button
                  size="large"
                  url={NEXT_STEP}
                  onClick={() => trackEvent('Onboarding campaign - Continue')}
                >
                  Continue
                </Button>
              </ButtonGroup>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <SkipOnboarding disabled={false} />
      <SolicitationCampaignModal
        showSolicitationCampaignModal={showModal}
        setShowSolicitationCampaignModal={setShowModal}
        callback={nextStep}
      />
      <ImportReviewModal
        active={showImportReviewModal}
        close={() => { setShowImportReviewModal(false); }}
        success={nextStep}
      />
    </Page>
  );
}
