import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { Modal, Select, TextContainer, Link, Text } from "@shopify/polaris";

export default function ThemeSelectorModal({ data, setData, callback }) {
  const [loading, setLoading] = useState(true);
  const [themes, setThemes] = useState(null);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    async function fetchThemes() {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get('/api/v1/store_themes');
        setThemes(response.data.store_themes);
      } catch (e) {
        setError('Error fetching your store themes, please try again.');
      } finally {
        setLoading(false);
      }
    }
    if (data?.active && !themes) {
      fetchThemes();
    }
  }, [data, themes]);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const action = () => {
    callback({type: data?.type, themeId: selected});
    setSelected('');
    setData(null);
  };

  let title = 'Select a theme';
  let description = 'Select which theme you would like to add the Junip snippets to:';
  let altDescription = null;
  let content = 'Add snippets';
  let destructive = false;

  if (data?.type) {
    switch(data.type) {
      case 'uninstall':
        description = 'Select which theme you would like to remove the Junip snippets from:';
        content = 'Remove snippets';
        destructive = true;
        break;
      case 'preview':
        description = 'Select a theme you would like to preview Junip on:'
        content = 'Preview Junip';
        altDescription = (
          <>
            Note this will only work if you have added the Junip snippets to the selected theme. To add snippets to a particular theme, visit the{' '}
            <Link url="/on-site">on-site</Link> section.
          </>
        );
        break;
      default:
        break;
    }
  }

  let options = [{ label: '--Select a theme--', value: '', disabled: true }];
  if (themes?.length) {
    for (const theme of themes) {
      options.push({
        label: `${theme.name}${theme.active ? ' (active)' : ''}`,
        value: theme.remote_id
      });
    }
  }

  return (
    <Modal
      title={title}
      open={data?.active}
      onClose={() => !loading && setData(null)}
      loading={loading}
      primaryAction={{
        content,
        disabled: !selected,
        onAction: action,
        destructive
      }}
      secondaryActions={[{
        content: 'Cancel',
        onAction: () => { setData(null); trackEvent('Theme selector modal - Cancel') },
      }]}
    >
      <Modal.Section>
        {error ?
          <p>
            <Text variant="bodyMd" as="span" tone="critical">{error}</Text>
          </p>
        :
          <TextContainer>
            <p>{description}</p>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
            {altDescription &&
              <p>{altDescription}</p>
            }
          </TextContainer>
        }
      </Modal.Section>
    </Modal>
  );
}

