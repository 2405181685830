import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IndexTable,
  Thumbnail,
  IndexFilters,
  useSetIndexFiltersMode,
  InlineStack,
  Text,
  Select,
  Link,
  EmptySearchResult,
  Card,
  ChoiceList,
} from "@shopify/polaris";
import { fetchProducts } from "redux/products";
import { fetchProductCategories } from "redux/productCategories";
import useDebounce from "utils/useDebounce";
import { trackEvent } from "utils/Mixpanel";
import filledStar from "assets/images/filledStar.svg";
import noImage from "assets/images/noImage.jpg";
import 'styles/components/ProductsTable.scss';

export default function ProductsTable() {
  const stores = useSelector((state) => state.stores);
  const scopedStore = stores.data?.find((store) => store.scoped);

  const products = useSelector((state) => state.products.data);
  const meta = useSelector((state) => state.products.meta);
  const productsLoading = useSelector((state) => state.products.loading);
  const productCategories = useSelector(
    (state) => state.productCategories.data
  );
  const productCategoriesLoading = useSelector(
    (state) => state.productCategories.loading
  );
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [queryValue, setQueryValue] = useState("");
  const [sortSelected, setSortSelected] = useState(["id desc"]);

  const [productCategoryFilter, setProductCategoryFilter] = useState("all");
  const [hasGtinFilter, setHasGtinFilter] = useState([""]);

  const debouncedQueryValue = useDebounce(queryValue, 300);

  const { mode, setMode } = useSetIndexFiltersMode();

  useEffect(() => {
    dispatch(
      fetchProducts({
        sort: {
          "sort[field]": sortSelected[0].split(" ")[0],
          "sort[order]": sortSelected[0].split(" ")[1],
        },
        queryValue: debouncedQueryValue,
        filters: hasGtinFilter[0] === "has_gtin"
          ? { "filter[no_missing_identifiers]": true }
          : hasGtinFilter[0] === "missing_gtin"
            ? { "filter[missing_identifiers_only]": true }
            : {},
        categoryId:
          productCategoryFilter === "all" ? null : productCategoryFilter,
      })
    );
  }, [debouncedQueryValue, dispatch, hasGtinFilter, productCategoryFilter, sortSelected]);

  useEffect(() => {
    dispatch(fetchProductCategories());
  }, [dispatch]);

  useEffect(() => {
    if (productsLoading || productCategoriesLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [productCategoriesLoading, productsLoading]);

  const onHandleCancel = () => {
    setQueryValue("");
    setSortSelected(["id desc"]);
    setProductCategoryFilter("all");
    setHasGtinFilter([""]);
  };

  const handleQueryValueChange = (value) => setQueryValue(value);

  const handleProductCategoryFilterChange = (value) => {
    setProductCategoryFilter(value);
    trackEvent('Products - Filter by Product Type');
  };

  const handleHasGtinFilterChange = (value) => {
    setHasGtinFilter(value);
  };

  const fetchProductsParams = (page) => ({
    page,
    queryValue: debouncedQueryValue,
    sort: {
      "sort[field]": sortSelected[0].split(" ")[0],
      "sort[order]": sortSelected[0].split(" ")[1],
    },
    filters: hasGtinFilter[0] === "has_gtin"
      ? { "filter[no_missing_identifiers]": true }
      : hasGtinFilter[0] === "missing_gtin"
        ? { "filter[missing_identifiers_only]": true }
        : {},
    categoryId: productCategoryFilter === "all" ? null : productCategoryFilter,
  });

  const nextPage = (after) => {
    const params = fetchProductsParams({ after });
    dispatch(fetchProducts(params));
  };

  const previousPage = (before) => {
    const params = fetchProductsParams({ before });
    dispatch(fetchProducts(params));
  };

  const sortOptions = [
    {
      label: "Date created",
      value: "id asc",
      directionLabel: "Ascending",
    },
    {
      label: "Date created",
      value: "id desc",
      directionLabel: "Descending",
    },
    {
      label: "Review count",
      value: "rating_count asc",
      directionLabel: "Ascending",
    },
    {
      label: "Review count",
      value: "rating_count desc",
      directionLabel: "Descending",
    },
  ];

  const filters = [
    {
      key: "product_type",
      label: "Product type",
      filter: (
        <Select
          label=""
          options={[
            { label: "All", value: "all" },
            ...productCategories?.map((category) => {
              return { label: category.name, value: `${category.id}` };
            }) || [],
          ]}
          onChange={handleProductCategoryFilterChange}
          value={productCategoryFilter}
        />
      ),
    },
    {
      key: "gtin_status",
      label: "GTIN status",
      filter: (
        <ChoiceList
          title=""
          choices={[
            { label: 'Has GTINs', value: 'has_gtin' },
            { label: 'Does not have GTINs', value: 'missing_gtin' },
          ]}
          selected={hasGtinFilter}
          onChange={handleHasGtinFilterChange}
        />
      ),
    },
  ];

  const appliedFilters = [];

  if (productCategoryFilter !== "all") {
    appliedFilters.push({
      key: "product_type",
      label: productCategories.find(
        (category) => category.id === parseInt(productCategoryFilter)
      ).name,
      onRemove: () => setProductCategoryFilter("all"),
    });
  }

  if (hasGtinFilter[0] !== "") {
    appliedFilters.push({
      key: "gtin_status",
      label: hasGtinFilter[0] === "has_gtin" ? "Has GTINs" : "Does not have GTINs",
      onRemove: () => setHasGtinFilter([""]),
    });
  }

  const headings = [
    { title: "Image" },
    { title: "Product" },
    {
      title: "GTIN status",
      tooltipContent: scopedStore?.should_syndicate ? "Product variants must have a valid GTIN in order to syndicate reviews" : undefined,
    },
    { title: "Reviews", alignment: 'end' },
    { title: "Rating", alignment: 'end', },
  ];

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No products to display'}
      description={'If you just installed Junip, come back in a few minutes as your store might still be syncing with our servers.'}
      withIllustration
    />
  );

  const rowMarkup = products?.map(
    (
      {
        id,
        title,
        image,
        rating_average,
        rating_count,
        missing_identifiers_count,
      },
      index
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell className="row-width">
          <Link
            dataPrimaryLink
            monochrome
            removeUnderline
            url={`/products/${id}`}
          >
            <Thumbnail
              source={image?.url_200x200 || noImage}
              alt={title}
              size={"small"}
            />
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text variation="bodyMd" fontWeight="semibold">
            {title}
          </Text>
        </IndexTable.Cell>
        {
          <IndexTable.Cell>
            {missing_identifiers_count
              ? <Text tone="caution">
                {missing_identifiers_count} {missing_identifiers_count !== 1
                  ? "variants need"
                  : "variant needs"
                } a valid GTIN
              </Text>
              : <Text tone="subdued">Complete</Text>}
          </IndexTable.Cell>
        }
        <IndexTable.Cell>
          <Text alignment="end">
            {rating_count}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {
            <InlineStack gap="100" blockAlign="center" align="end">
              <Text>{rating_average?.toFixed(1) || 0}</Text>
              <img src={filledStar} alt="Star icon" height={13} />
            </InlineStack>
          }
        </IndexTable.Cell>
      </IndexTable.Row >
    )
  );

  return (
    <Card padding="0">
      <IndexFilters
        sortOptions={sortOptions}
        sortSelected={sortSelected}
        queryValue={queryValue}
        queryPlaceholder="Searching in all"
        onQueryChange={handleQueryValueChange}
        onQueryClear={() => setQueryValue("")}
        onSort={setSortSelected}
        filters={filters}
        appliedFilters={appliedFilters}
        cancelAction={{
          onAction: onHandleCancel,
          disabled: loading,
          loading: loading,
        }}
        mode={mode}
        setMode={setMode}
        tabs={[]}
        selected={selectedTab}
        onSelect={setSelectedTab}
        canCreateNewView={false}
        loading={loading}
        onClearAll={onHandleCancel}
      />
      <IndexTable
        resourceName={{ singular: "product", plural: "products" }}
        itemCount={products?.length || 0}
        selectable={false}
        headings={headings.filter((heading) => heading.title)}
        emptyState={emptyStateMarkup}
        pagination={{
          hasPrevious: meta?.page?.before !== null,
          hasNext: meta?.page?.after !== null,
          onPrevious: () => previousPage(meta?.page?.before),
          onNext: () => nextPage(meta?.page?.after),
        }}
      >
        {rowMarkup}
      </IndexTable>
    </Card>
  );
}