import axios from 'axios';
import moment from 'moment';
import { trackEvent } from 'utils/Mixpanel';
import { showToast } from 'redux/toast';
import {
  changesSaved,
  savingChanges,
  discardChanges
} from 'redux/settings';

// Actions
const FETCH_NOTIFICATION_SETTINGS_BEGIN = 'FETCH_NOTIFICATION_SETTINGS_BEGIN';
const FETCH_NOTIFICATION_SETTINGS_SUCCESS = 'FETCH_NOTIFICATION_SETTINGS_SUCCESS';
const FETCH_NOTIFICATION_SETTINGS_ERROR = 'FETCH_NOTIFICATION_SETTINGS_ERROR';

// Action Creators
export function fetchNotificationSettings() {
  return async dispatch => {
    dispatch(fetchNotificationSettingsBegin());
    try {
      const response = await axios.get('/api/v1/notification_settings');
      dispatch(fetchNotificationSettingsSuccess(response.data.notification_settings[0]));
    } catch (e) {
      dispatch(fetchNotificationSettingsError(e));
    }
  }
}

export function saveNotificationSettings(id, settings) {
  if (settings.new_review_email_dependent_state === 'all') {
    settings.new_review_email_dependent_state = null;
  }
  return async dispatch => {
    try {
      if (settings.periodic_digest_email_enabled) {
        let newValue = moment(settings.periodic_digest_email_anchor || Date.now());
        const newTime = moment(settings.time, moment.HTML5_FMT.TIME);
        newValue.minute(newTime.minute());
        newValue.hour(newTime.hour());
        settings.periodic_digest_email_anchor = newValue.format();
      }
      const response = await axios.put(
        `/api/v1/notification_settings/${id}`,
        {
          notification_setting: settings
        }
      );
      dispatch(fetchNotificationSettingsSuccess(response.data.notification_setting));
      dispatch(changesSaved());
    } catch (e) {
      if (e?.response?.status === 422) {
        if (!settings.new_review_email_dependent_ratings?.length) {
          dispatch(showToast('Please select at least one rating', true));
        } else {
          dispatch(showToast('Error saving changes, please check your inputs and try again', true));
        }

        dispatch(discardChanges());
      } else {
        dispatch(showToast('Error saving changes, please try again', true));
        dispatch(savingChanges(false));
      }
      trackEvent('Error: Notification settings - Save changes');
    }
  }
}

export const fetchNotificationSettingsBegin = () => ({
  type: FETCH_NOTIFICATION_SETTINGS_BEGIN
});

export const fetchNotificationSettingsSuccess = settings => ({
  type: FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  data: { settings }
});

export const fetchNotificationSettingsError = error => ({
  type: FETCH_NOTIFICATION_SETTINGS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_NOTIFICATION_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.data.settings,
        loading: false,
        error: null
      };
    case FETCH_NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
