import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IndexTable,
  Text,
  Button,
  InlineStack,
  Card,
  BlockStack,
  Box,
  TextField,
  Badge,
  Modal,
  Banner,
  ButtonGroup,
} from "@shopify/polaris";
import { ExternalIcon } from "@shopify/polaris-icons";
import { fetchProductVariantsForProduct } from "redux/productVariantsForProduct";
import { saveProductVariant } from 'redux/productVariants';

export default function ProductVariantsTable({ product }) {
  const stores = useSelector((state) => state.stores);
  const scopedStore = stores.data?.find((store) => store.scoped);
  const appInstall = scopedStore?.app_install;

  const productVariants = useSelector((state) => {
    return state.productVariantsForProduct?.data?.product_variants
  });
  const meta = useSelector((state) => state.productVariantsForProduct?.data?.meta);
  const variantsLoading = useSelector((state) => state.productVariantsForProduct.loading);

  const dispatch = useDispatch();

  const [editGtin, setEditGtin] = useState(false);
  const [gtinsToPush, setGtinsToPush] = useState({});
  const [saveModalActive, setSaveModalActive] = useState(false);
  const [showUpdatingBanner, setShowUpdatingBanner] = useState(false);

  useEffect(() => {
    if (product.id) {
      dispatch(
        fetchProductVariantsForProduct(product.id)
      )
    }
  }, [dispatch, product.id]);

  const nextPage = (after) => {
    dispatch(fetchProductVariantsForProduct(product.id, { after }));
  };

  const previousPage = (before) => {
    dispatch(fetchProductVariantsForProduct(product.id, { before }));
  };

  const clearEdits = () => {
    setGtinsToPush({});
  }

  const handleEditGtin = () => {
    if (editGtin) {
      clearEdits();
    }
    setEditGtin(!editGtin);
  }

  const handleGtinChange = (value, id) => {
    setGtinsToPush({ ...gtinsToPush, [id]: { local_gtin: value } });
  }

  const handleSave = async () => {
    for (const [key, value] of Object.entries(gtinsToPush)) {
      await dispatch(saveProductVariant(key, value));
    }

    await dispatch(fetchProductVariantsForProduct(product.id));
    setShowUpdatingBanner(true);

    setEditGtin(false);
    clearEdits();

    setSaveModalActive(false);
  }

  const saveButton = (
    <Button
      onClick={() => setSaveModalActive(true)}
      variant="primary"
      tone="success"
      disabled={Object.keys(gtinsToPush).length === 0}
    >
      Save
    </Button>
  );

  const infoUpdatingBanner = (
    <Banner>
      Product information is being sent to Shopify. Please check back shortly for changes to appear.
    </Banner>
  );

  const warningBanner = (
    <Box paddingBlockEnd="200" paddingInline="200">
      <Banner tone="critical">
        Any changes made in Junip will also change your Product Information in Shopify.
      </Banner>
    </Box>
  );

  const rowMarkup = productVariants?.map(
    (
      {
        id,
        title,
        remote_id,
        local_gtin,
        gtin,
        mpn,
        sku,
        deleted_at,
      },
      index
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <InlineStack gap="100">
            <Text fontWeight="medium">{title}</Text>
            {deleted_at ? <Badge tone="critical">Deleted</Badge> : null}
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack gap="100">
            <Text tone="subdued">{remote_id}</Text>
            {!deleted_at &&
              <Button
                icon={ExternalIcon}
                accessibilityLabel="Open product variant in Shopify"
                url={`https://${appInstall?.uid}/admin/products/${product?.remote_id}/variants/${remote_id}`}
                variant="monochromePlain"
              />
            }
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {editGtin && !deleted_at
            ? (
              <TextField
                accessibilityLabel="GTIN Input"
                value={gtinsToPush[id]?.local_gtin}
                onChange={(value) => {
                  handleGtinChange(value, id);
                }}
                placeholder={local_gtin || gtin}
              />
            )
            : <Text variant="subdued">{local_gtin || gtin || "None"}</Text>}
        </IndexTable.Cell>
        <IndexTable.Cell>{mpn}</IndexTable.Cell>
        <IndexTable.Cell>{sku}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <>
      {showUpdatingBanner ? infoUpdatingBanner : null}
      <Card padding="0">
        <BlockStack inlineAlign="end">
          <Box padding="200">
            <ButtonGroup>
              <Button onClick={handleEditGtin}>{editGtin ? "Cancel" : "Edit"}</Button>
              {editGtin && saveButton}
            </ButtonGroup>
          </Box>
        </BlockStack>
        {editGtin && warningBanner}
        <Box borderColor="border" borderBlockStartWidth="025">
          <IndexTable
            resourceName={{ singular: "product_variant", plural: "product_variants" }}
            itemCount={productVariants?.length || 0}
            selectable={false}
            headings={[{ title: "Product variants" }, { title: "Shopify ID" }, { title: "GTIN" }, { title: "MPN" }, { title: "SKU" }]}
            pagination={{
              hasPrevious: !!meta?.page?.before,
              hasNext: !!meta?.page?.after,
              onPrevious: () => previousPage(meta?.page?.before),
              onNext: () => nextPage(meta?.page?.after),
            }}
            loading={variantsLoading}
          >
            {rowMarkup}
          </IndexTable>
        </Box>
      </Card>
      <Modal
        open={saveModalActive}
        onClose={() => setSaveModalActive(false)}
        title="Save changes?"
        primaryAction={{
          content: 'Save',
          onAction: handleSave,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setSaveModalActive(false),
          },
        ]}
      >
        <Modal.Section>
          <Text>
            The updated product information will be pushed to your Shopify account.
          </Text>
        </Modal.Section>
      </Modal>
    </>
  );
}
