import React from 'react';
import 'styles/components/OnboardingWrapper.scss';

export default function OnboardinWrapper({ children }) {
  return (
    <div className="onboarding-wrapper">
      { children }
    </div>
  );
}
