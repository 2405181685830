import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { fetchProducts } from 'redux/products';
import { fetchProductVariantsForProduct } from 'redux/productVariantsForProduct';
import { fetchSurveyQuestions } from 'redux/surveyQuestions';
import { connect } from 'react-redux';
import {
  Page,
  Layout,
  Popover,
  OptionList,
  Button,
  DataTable,
  ChoiceList,
  LegacyStack,
  EmptyState,
  LegacyCard,
  LegacyFilters,
} from '@shopify/polaris';
import {
  StackedAreaChart,
  BarChart
} from '@shopify/polaris-viz';
import { ExportIcon } from "@shopify/polaris-icons";

import AnalyticsLoadingWrapper from 'components/AnalyticsLoadingWrapper';
import ProductFilter from 'components/ProductFilter';
import createCsv from 'utils/createCsv';
import ReportRatingsCollectedOverTimeData from 'utils/ReportsRatingsCollectedOverTimeData';

import '@shopify/polaris-viz/build/esm/styles.css';
import PolarisVizProviderWrapper from 'components/PolarisVizProviderWrapper';

function ReportsRatingsCollectedOverTime({ products, fetchProducts, productVariantsForProduct, fetchProductVariantsForProduct, surveyQuestions, fetchSurveyQuestions }) {
  const RANGE_PICKER_OPTS = {
    last_7: 'Last 7 days',
    last_30: 'Last 30 days',
    last_90: 'Last 90 days',
    last_12_months: 'Last 12 months'
  }

  const DATA_PICKER_OPTS = {
    totals: 'Totals',
    percentages: 'Percentages'
  }

  const [loading, setLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(8, 'day').toDate(),
    end: moment().subtract(1, 'day').toDate()
  });
  const [period, setPeriod] = useState('daily');
  const [showPercentages, setShowPercentages] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const [rangePickerActive, setRangePickerActive] = useState(false);
  const [rangePickerTarget, setRangePickerTarget] = useState(['last_7']);
  const [periodPickerActive, setPeriodPickerActive] = useState(false);
  const [periodPickerTarget, setPeroidPickerTarget] = useState(['daily'])
  const [periodPickerOptions, setPeriodPickerOptions] = useState({});
  const [dataPickerActive, setDataPickerActive] = useState(false);
  const [dataPickerTarget, setDataPickerTarget] = useState(['totals']);

  const [productFilter, setProductFilter] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedReviewType, setSelectedReviewType] = useState([]);
  const [variantChoices, setVariantChoices] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [selectedReviewMedia, setSelectedReviewMedia] = useState([]);

  const [variants, setVariants] = useState([]);

  let {
    areaChartData,
    barChartData,
    tableData
  } = ReportRatingsCollectedOverTimeData({
    setLoading,
    selectedDates,
    period,
    showPercentages,
    selectedFilters,
  });

  if (areaChartData.length === 0) {
    areaChartData = [
      {
        name: '5 stars',
        data: []
      },
      {
        name: '4 stars',
        data: []
      },
      {
        name: '3 stars',
        data: []
      },
      {
        name: '2 stars',
        data: []
      },
      {
        name: '1 stars',
        data: []
      }
    ]
  }

  const toggleRangePickerActive = useCallback(
    () => setRangePickerActive((popoverActive) => !popoverActive),
    [],
  );

  const togglePeriodPickerActive = useCallback(
    () => setPeriodPickerActive((popoverActive) => !popoverActive),
    [],
  );

  const toggleDataPickerActive = useCallback(
    () => setDataPickerActive((popoverActive) => !popoverActive),
    [],
  );

  useEffect(() => {
    setLoading(true);
  }, [selectedDates, period, showPercentages, selectedFilters]);

  useEffect(() => {
    setLoading(false);
  }, [areaChartData, barChartData, tableData]);

  useEffect(() => {
    let PERIOD_PICKER_OPTS = {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly'
    }

    const range = rangePickerTarget[0];
    let start = null;
    let end = moment().toDate();

    switch (range) {
      case 'last_7':
        start = moment().subtract(6, 'day').toDate();
        PERIOD_PICKER_OPTS = {
          daily: 'Daily'
        }
        setPeroidPickerTarget(['daily'])
        break;
      case 'last_30':
        start = moment().subtract(29, 'day').toDate();
        PERIOD_PICKER_OPTS = {
          weekly: 'Weekly'
        }
        setPeroidPickerTarget(['weekly'])
        break;
      case 'last_90':
        start = moment().subtract(89, 'day').toDate();
        PERIOD_PICKER_OPTS = {
          weekly: 'Weekly',
          monthly: 'Monthly'
        }
        setPeroidPickerTarget(['weekly']);
        break;
      case 'last_12_months':
        start = moment().subtract(364, 'day').toDate();
        PERIOD_PICKER_OPTS = {
          weekly: 'Weekly',
          monthly: 'Monthly'
        }
        setPeroidPickerTarget(['weekly']);
        break;
      default:
        break;
    }

    setPeriodPickerOptions(PERIOD_PICKER_OPTS);

    setSelectedDates({
      start: start,
      end: end
    });

    setRangePickerActive(false);
  }, [rangePickerTarget]);

  useEffect(() => {
    const updatedPeriod = periodPickerTarget[0];

    setPeriodPickerActive(false);
    setPeriod(updatedPeriod);
  }, [periodPickerTarget]);

  useEffect(() => {
    if (dataPickerTarget[0] === 'totals') {
      setShowPercentages(false);
    } else {
      setShowPercentages(true);
    }
  }, [dataPickerTarget]);

  useEffect(() => {
    if (!products.data) {
      fetchProducts({ categoryId: null });
    }
  }, [products.data, fetchProducts])

  useEffect(() => {
    setLoading(true);
    setSelectedFilters({
      selectedProduct,
      selectedReviewType,
      selectedVariant,
      selectedReviewMedia
    });

  }, [selectedProduct, selectedReviewType, selectedVariant, selectedReviewMedia]);

  useEffect(() => {
    if (selectedProduct.length > 0) {
      fetchProductVariantsForProduct(selectedProduct[0]);
    }
  }, [selectedProduct, fetchProductVariantsForProduct]);

  useEffect(() => {
    if (productVariantsForProduct.data?.product_variants) {
      setVariants(productVariantsForProduct.data?.product_variants);
    }
  }, [productVariantsForProduct.data?.product_variants]);

  useEffect(() => {
    if (variants.length > 1) {
      let choices = variants.map((variant, i) => {
        return {
          label: variant.title,
          value: variant.id
        }
      });

      setVariantChoices(
        choices
      );
    } else {
      setVariantChoices([]);
    }
  }, [variants]);

  useEffect(() => {
    setSelectedVariant([]);
    setVariantChoices([]);
  }, [productFilter]);

  const rangePickerActivator = (
    <Button onClick={toggleRangePickerActive} disclosure>
      {RANGE_PICKER_OPTS[rangePickerTarget[0]]}
    </Button>
  );

  const periodPickerActivator = (
    <Button onClick={togglePeriodPickerActive} disclosure>
      {periodPickerOptions[periodPickerTarget[0]]}
    </Button>
  );

  const dataPickerActivator = (
    <Button onClick={toggleDataPickerActive} disclosure>
      {DATA_PICKER_OPTS[dataPickerTarget[0]]}
    </Button>
  )

  const getChart = () => {
    if (!showPercentages) {
      return (
        <StackedAreaChart
          data={areaChartData}
          state={loading ? 'Loading' : 'Success'}
        />
      )
    } else {
      return (
        <BarChart
          data={barChartData}
          state={loading ? 'Loading' : 'Success'}
          type="stacked"
        />
      )
    }
  }

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const getLabelForKey = (key, val) => {
    switch (key) {
      case 'selectedProduct':
        return products.data.find((product) => product.id === val)?.title;
      case 'product':
        return 'Product Reviews';
      case 'store':
        return 'Store Reviews'
      case 'with_media':
        return 'Photo & video reviews';
      case 'selectedVariant':
        return variantChoices.find((variant) => variant.value === val)?.label;
      default:
        return '';
    }
  }

  const filters = [
    {
      key: 'review_type',
      label: 'Review type',
      filter: (
        <OptionList
          options={[
            { label: 'Store reviews', value: 'store' },
            { label: 'Product reviews', value: 'product' },
          ]}
          selected={selectedReviewType}
          onChange={setSelectedReviewType}
        />
      ),
      shortcut: true,
      hideClearButton: true
    },
    {
      key: 'media_reviews',
      label: 'Photo & video reviews',
      filter: (
        <ChoiceList
          title="Photo & video reviews"
          titleHidden
          choices={[
            { label: 'Photo and video reviews', value: 'with_media' }
          ]}
          selected={selectedReviewMedia}
          onChange={setSelectedReviewMedia}
        />
      ),
      shortcut: true,
      hideClearButton: true
    }
  ];

  if (variantChoices.length > 1) {
    const variantFilter = {
      key: 'variant',
      label: 'Variant',
      filter: (
        <ChoiceList
          title="Variants"
          titleHidden
          choices={variantChoices}
          selected={selectedVariant}
          onChange={setSelectedVariant}
          allowMultiple
        />
      ),
      shortcut: true,
      hideClearButton: true
    }

    filters.splice(2, 0, variantFilter)
  }

  const handleSelectedProductRemove = useCallback(
    () => {
      setSelectedProduct([]);
      setProductFilter(null);
    },
    [],
  );

  const handleSelectedReviewTypeRemove = useCallback(
    () => setSelectedReviewType([]),
    []
  );

  const handleSelectedReviewMediaRemove = useCallback(
    () => setSelectedReviewMedia([]),
    []
  );

  const handleSelectedVariantRemove = useCallback(
    () => setSelectedVariant([]),
    []
  );

  let appliedFilters = [];

  if (!isEmpty(selectedProduct)) {
    const key = 'selectedProduct';

    appliedFilters.push({
      key,
      label: getLabelForKey(key, selectedProduct[0]),
      onRemove: handleSelectedProductRemove,
    });
  }

  if (!isEmpty(selectedReviewType)) {
    const key = 'selectedReviewType';

    appliedFilters.push({
      key,
      label: getLabelForKey(selectedReviewType[0]),
      onRemove: handleSelectedReviewTypeRemove,
    });
  }

  if (!isEmpty(selectedReviewMedia)) {
    const key = 'selectedReviewMedia';

    appliedFilters.push({
      key,
      label: getLabelForKey(selectedReviewMedia[0]),
      onRemove: handleSelectedReviewMediaRemove,
    });
  }

  if (!isEmpty(selectedVariant)) {
    const key = 'selectedVariant';

    selectedVariant.forEach((variant) => {
      appliedFilters.push({
        key,
        label: getLabelForKey(key, variant),
        onRemove: handleSelectedVariantRemove,
      });
    });
  }

  const handleExport = () => {
    const headers = ['Period', 'Avg. Rating', '1-star', '2-star', '3-star', '4-star', '5-star', 'Total'];
    const csv = createCsv(headers, tableData);
    const encodedUri = encodeURI(csv);
    window.open(encodedUri);
  }

  const emptyStateMarkup = (
    <EmptyState heading={'No data for selected date range'}>
      <p>{'Select a different date range, or manage your review request flow to start collecting reviews.'}</p>
    </EmptyState >
  );

  return (
    <PolarisVizProviderWrapper>
      <div>
        <Page
          title='Reviews collected over time'
          fullWidth
          backAction={{ content: 'Back', url: '/analytics/reports' }}
          secondaryActions={[
            {
              content: 'Export as CSV',
              icon: ExportIcon,
              onAction: handleExport
            }
          ]}
        >
          <Layout>
            <Layout.Section>
              <LegacyStack spacing="tight">
                <LegacyStack.Item>
                  <Popover
                    active={rangePickerActive}
                    activator={rangePickerActivator}
                    autofocusTarget="first-node"
                    onClose={toggleRangePickerActive}
                  >
                    <OptionList
                      onChange={setRangePickerTarget}
                      options={
                        Object.entries(RANGE_PICKER_OPTS).map((arr) => {
                          return {
                            value: arr[0],
                            label: arr[1]
                          }
                        })
                      }
                      selected={rangePickerTarget}
                    />
                  </Popover>
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <Popover
                    active={periodPickerActive}
                    activator={periodPickerActivator}
                    autofocusTarget="first-node"
                    onClose={togglePeriodPickerActive}
                  >
                    <OptionList
                      onChange={setPeroidPickerTarget}
                      options={
                        Object.entries(periodPickerOptions).map((arr) => {
                          return {
                            value: arr[0],
                            label: arr[1]
                          }
                        })
                      }
                      selected={periodPickerTarget}
                    />
                  </Popover>
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <Popover
                    active={dataPickerActive}
                    activator={dataPickerActivator}
                    autofocusTarget="first-node"
                    onClose={toggleDataPickerActive}
                  >
                    <OptionList
                      onChange={setDataPickerTarget}
                      options={
                        Object.entries(DATA_PICKER_OPTS).map((arr) => {
                          return {
                            value: arr[0],
                            label: arr[1]
                          }
                        })
                      }
                      selected={dataPickerTarget}
                    />
                  </Popover>
                </LegacyStack.Item>
              </LegacyStack>
            </Layout.Section>
            <Layout.Section>
              <AnalyticsLoadingWrapper loading={loading}>
                <LegacyCard
                  title="Reviews collected"
                  sectioned
                >
                  <div className="mt-3">
                    {getChart()}
                  </div>
                </LegacyCard>
                <LegacyCard>
                  <LegacyCard.Section>
                    <LegacyStack spacing="tight">
                      {
                        (
                          <LegacyStack.Item>
                            <ProductFilter size="medium" productFilter={productFilter} setProductFilter={(val) => {
                              setSelectedProduct(val?.id ? [val.id] : []);
                              setProductFilter(val);
                            }} isFilter={false} />
                          </LegacyStack.Item>
                        )
                      }
                      <LegacyStack.Item fill>
                        <LegacyFilters
                          filters={filters}
                          hideQueryField
                          appliedFilters={appliedFilters}
                        />
                      </LegacyStack.Item>
                    </LegacyStack>
                    <DataTable
                      columnContentTypes={[
                        'text',
                        'numeric',
                        'numeric',
                        'numeric',
                        'numeric',
                        'numeric',
                        'numeric',
                        showPercentages ? null : 'numeric'
                      ]}
                      headings={[
                        'Period',
                        'Avg. Rating',
                        '1-star',
                        '2-star',
                        '3-star',
                        '4-star',
                        '5-star',
                        showPercentages ? null : 'Total'
                      ]}
                      rows={tableData}
                    />
                    {tableData.length === 0 && emptyStateMarkup}
                  </LegacyCard.Section>
                </LegacyCard>
              </AnalyticsLoadingWrapper>
            </Layout.Section>
          </Layout>
        </Page>
      </div>
    </PolarisVizProviderWrapper>
  );
}

const mapStateToProps = (state) => ({
  products: state.products,
  surveyQuestions: state.surveyQuestions,
  productVariantsForProduct: state.productVariantsForProduct,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: ({ categoryId, page, queryValue }) => dispatch(fetchProducts({ categoryId, page, queryValue })),
  fetchProductVariantsForProduct: (id, page) => dispatch(fetchProductVariantsForProduct(id, page)),
  fetchSurveyQuestions: (params) => dispatch(fetchSurveyQuestions(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsRatingsCollectedOverTime);
