import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import {
  changesSaved,
  savingChanges,
  discardChanges
} from 'redux/settings';
import { fetchMessageTemplates } from 'redux/messages';
import { logout } from 'redux/user';
import { showToast } from 'redux/toast';

// Actions
const FETCH_DISPLAY_SETTINGS_BEGIN = 'FETCH_DISPLAY_SETTINGS_BEGIN';
const FETCH_DISPLAY_SETTINGS_SUCCESS = 'FETCH_DISPLAY_SETTINGS_SUCCESS';
const FETCH_DISPLAY_SETTINGS_ERROR = 'FETCH_DISPLAY_SETTINGS_ERROR';

// Action Creators
export function fetchDisplaySettings() {
  return async dispatch => {
    dispatch(fetchDisplaySettingsBegin());
    try {
      const response = await axios.get('/api/v1/display_settings?include=store');
      let data = response.data.display_settings[0];
      if (!data) {
        dispatch(logout());
        return;
      }
      data.logo = '';
      dispatch(fetchDisplaySettingsSuccess(data));
    } catch (e) {
      dispatch(fetchDisplaySettingsError(e));
      if (e.response) {
        if (e.response?.status === 401) {
          dispatch(logout());
        }
      }
    }
  }
}

export function saveDisplaySettings(id, settings, displayToast = true) {
  if (settings.logo === '') {
    delete settings.logo;
  }
  return async dispatch => {
    try {
      const response = await axios.put(
        `/api/v1/display_settings/${id}?include=store`,
        {
          display_setting: settings
        }
      );
      let data = response.data.display_setting;
      data.logo = '';
      dispatch(fetchDisplaySettingsSuccess(data));
      if (displayToast) {
        dispatch(changesSaved());
      }
      // Refetch message templates
      dispatch(fetchMessageTemplates());
    } catch (e) {
      if (e?.response?.status === 422) {
        dispatch(showToast('Error saving changes, please check your inputs and try again', true));
        dispatch(discardChanges());
      } else {
        dispatch(showToast('Error saving changes, please try again', true));
        dispatch(savingChanges(false));
      }
      trackEvent('Error: Display settings - Save changes', { statusCode: e?.response?.status });
    }
  }
}

export const fetchDisplaySettingsBegin = () => ({
  type: FETCH_DISPLAY_SETTINGS_BEGIN
});

export const fetchDisplaySettingsSuccess = data => ({
  type: FETCH_DISPLAY_SETTINGS_SUCCESS,
  data
});

export const fetchDisplaySettingsError = error => ({
  type: FETCH_DISPLAY_SETTINGS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_DISPLAY_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_DISPLAY_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_DISPLAY_SETTINGS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
