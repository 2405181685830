import React, { useState } from 'react';
import { LegacyCard, LegacyStack, ButtonGroup, Button, Text } from "@shopify/polaris";

export default function Invitation({invitation, accept, decline, disabled}) {
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);

  const acceptInvite = async (id) => {
    setAccepting(true);
    try {
      await accept(id);
    } catch(e) {
      setAccepting(false);
    }
  };

  const declineInvite = async (id) => {
    setDeclining(true);
    try {
      await decline(id);
    } catch(e) {
      setDeclining(false);
    }
  };

  return (
    <LegacyCard.Section>
      <LegacyStack alignment="center">
        <LegacyStack.Item fill>
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            {invitation.organization.name}
          </Text>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <ButtonGroup>
            <Button

              disabled={accepting || declining || disabled}
              loading={declining}
              onClick={() => declineInvite(invitation.id)}
              variant="plain">
              Decline
            </Button>
            <Button

              disabled={accepting || declining || disabled}
              loading={accepting}
              onClick={() => acceptInvite(invitation.id)}
              variant="primary">
              Accept
            </Button>
          </ButtonGroup>
        </LegacyStack.Item>
      </LegacyStack>
    </LegacyCard.Section>
  );
};
