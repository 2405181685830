import axios from 'axios';
import { logout } from 'redux/user';

// Actions
const FETCH_STAR_OPTIONS_BEGIN = 'FETCH_STAR_OPTIONS_BEGIN';
const FETCH_STAR_OPTIONS_SUCCESS = 'FETCH_STAR_OPTIONS_SUCCESS';
const FETCH_STAR_OPTIONS_ERROR = 'FETCH_STAR_OPTIONS_ERROR';

// Action Creators
export function fetchStarOptions() {
  return async dispatch => {
    dispatch(fetchStarOptionsBegin());
    try {
      const response = await axios.get('/api/v1/star_options');
      const data = response.data.star_options;
      dispatch(fetchStarOptionsSuccess(data));
    } catch (e) {
      dispatch(fetchStarOptionsError(e));
      if (e.response) {
        if (e.response?.status === 401) {
          dispatch(logout());
        }
      }
    }
  }
}

export const fetchStarOptionsBegin = () => ({
  type: FETCH_STAR_OPTIONS_BEGIN
});

export const fetchStarOptionsSuccess = data => ({
  type: FETCH_STAR_OPTIONS_SUCCESS,
  data
});

export const fetchStarOptionsError = error => ({
  type: FETCH_STAR_OPTIONS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch(action.type) {
    case FETCH_STAR_OPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_STAR_OPTIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_STAR_OPTIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
